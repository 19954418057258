import {
    GET_CATALOGS_SUCCESS,
    GET_CATALOGS_FAIL,
    GET_CATALOGS_LISTING,
    GET_CATALOG_SUCCESS,
    GET_CATALOG_FAIL,
    GET_CATALOG_LISTING,
    SHOW_WELCOME_DIALOG,
    GET_CATALOG_DUPLICATE_LISTING,
    GET_CATALOG_DUPLICATE_SUCCESS,
    GET_CATALOG_DUPLICATE_FAIL,
    HIDE_WELCOME_DIALOG,
    GET_CONNECTOR_INVENTORY_CREDENTIALS_SUCCESS,
    GET_CONNECTOR_INVENTORY_CREDENTIALS_FAIL,
    GET_CONNECTOR_INVENTORY_CREDENTIALS_LISTING,
    GET_ACTIVE_INVENTORY_LISTING,
    GET_ACTIVE_INVENTORY_SUCCESS,
    GET_ACTIVE_INVENTORY_FAIL,
} from "../types";
import axios from "axios";

const PATH_URL = process.env.REACT_APP_BASE_URL;

export const getCatalogs = () => async (dispatch) => {
    dispatch({
        type: GET_CATALOGS_LISTING,
    });
    try {
        const res = await axios.get(PATH_URL + "/catalogs", {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        dispatch({
            type: GET_CATALOGS_SUCCESS,
            payload: res.data.data,
        });
        if (res.data.data?.length === 0) {
            dispatch({ type: SHOW_WELCOME_DIALOG });
        } else {
            dispatch({ type: HIDE_WELCOME_DIALOG });
        }
    } catch (e) {
        dispatch({
            type: GET_CATALOGS_FAIL,
            payload: console.log("Error in actions ", e),
        });
    }
};

export const getCatalog = (id) => async (dispatch) => {
    try {
        const res = await axios.get(PATH_URL + `/catalogs/${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        dispatch({
            type: GET_CATALOG_SUCCESS,
            payload: res.data.data,
        });
    } catch (e) {
        dispatch({
            type: GET_CATALOG_FAIL,
            payload: console.log("Error in actions ", e),
        });
    }
};
export const getActiveCatalog = () => async (dispatch) => {
    dispatch({
        type: GET_ACTIVE_INVENTORY_LISTING,
    });
    try {
        const res = await axios.get(
            PATH_URL + `/catalogs/get-active-inventory`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }
        );
        dispatch({
            type: GET_ACTIVE_INVENTORY_SUCCESS,
            payload: res.data.data,
        });
    } catch (e) {
        dispatch({
            type: GET_ACTIVE_INVENTORY_FAIL,
            payload: console.log("Error in actions ", e),
        });
    }
};
export const getActiveCatalogPeriodique = () => async (dispatch) => {
    try {
        const res = await axios.get(
            PATH_URL + `/catalogs/get-active-inventory`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }
        );
        dispatch({
            type: GET_ACTIVE_INVENTORY_SUCCESS,
            payload: res.data.data,
        });
    } catch (e) {
        dispatch({
            type: GET_ACTIVE_INVENTORY_FAIL,
            payload: console.log("Error in actions ", e),
        });
    }
};
export const getCatalogFirst = (id) => async (dispatch) => {
    dispatch({
        type: GET_CATALOG_LISTING,
    });
    try {
        const res = await axios.get(PATH_URL + `/catalogs/${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        dispatch({
            type: GET_CATALOG_SUCCESS,
            payload: res.data.data,
        });
    } catch (e) {
        dispatch({
            type: GET_CATALOG_FAIL,
            payload: console.log("Error in actions ", e),
        });
    }
};
export const getCatalogsDuplicate = (page) => async (dispatch) => {
    dispatch({
        type: GET_CATALOG_DUPLICATE_LISTING,
    });
    try {
        const res = await axios.get(PATH_URL + `/catalogs/dups`, {
            params: {
                page: page,
                rows_per_page: 10,
            },
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        dispatch({
            type: GET_CATALOG_DUPLICATE_SUCCESS,
            payload: res.data.data,
            count: res.data.count,
            totalDuplicates: res.data.total_duplicates,
        });
    } catch (e) {
        dispatch({
            type: GET_CATALOG_DUPLICATE_FAIL,
            payload: console.log("Error in actions ", e),
        });
    }
};
export const getInventoryConnectorCredentials =
    (connectorId) => async (dispatch) => {
        dispatch({
            type: GET_CONNECTOR_INVENTORY_CREDENTIALS_LISTING,
        });
        try {
            const res = await axios.get(
                PATH_URL +
                    `/catalogs/get-connector-inv-credentials/${connectorId}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem(
                            "token"
                        )}`,
                    },
                }
            );
            dispatch({
                type: GET_CONNECTOR_INVENTORY_CREDENTIALS_SUCCESS,
                payload: res.data.data,
            });
        } catch (e) {
            dispatch({
                type: GET_CONNECTOR_INVENTORY_CREDENTIALS_FAIL,
                payload: console.log("Error in actions ", e),
            });
        }
    };
