import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Table from "../../components/Table/Table";
import { separator, hasPermission } from "../../utilis/functions";
import { CREATE_CATALOG } from "../../utilis/const";
import PartnerinventoriesHeaderDetails from "./PartnerInventoriesHeaderDetails";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import {
    getPartnerInventoriesByIdList,
    getPartnerById,
} from "../../redux/actions/PartnerActions";
import { useParams, useHistory } from "react-router-dom";
import ChoiseConnectorCsv from "./AddPartnerInventory/index";
import PartnerInventoriesHistoryDetails from "./PartnerInventoriesHistoryModel";
import { Spinner } from "react-bootstrap";
import PartnerInventoryService from "../../services/PartnerInventoryService";
import fileDownload from "js-file-download";
import { toast } from "react-toastify";

const statusBadgeVariant = (status) => {
    switch (status) {
        case 1:
            return {
                color: "info",
                status: "Processing",
            };
        case 2:
            return {
                color: "success",
                status: "Done",
            };
        case 3:
            return {
                color: "danger",
                status: "Failed",
            };

        default:
            return {
                color: "",
                status: "",
            };
    }
};
const PartnerInventoriesDetails = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { id } = useParams();
    const [showMoreDetails, setShowMoreDetails] = useState(false);
    const [currentElement, setCurrentElement] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const {
        partnerInventories,
        loadingPartnerInventories,
        activePartnerInventory,
        partnerDetails,
        loadingPartner,
    } = useSelector((state) => ({
        partnerInventories: state.partnerReducer.partnersInventories ?? [],
        partnerDetails: state.partnerReducer.partner ?? {},
        loadingPartner: state.partnerReducer.loadingPartner,
        activePartnerInventory:
            state.partnerReducer.partnerInventoriesHeader ?? {},
        loadingPartnerInventories:
            state.partnerReducer.loadingPartnerInventories ?? false,
    }));

    const columns = React.useMemo(() => {
        const required = [
            {
                name: <div className="table-title">Total hotels</div>,
                sortable: true,
                selector: (e) => e.parent?.total_hotels,
                cell: (e) => {
                    return (
                        <>
                            {e?.parent?.total_hotels
                                ? separator(e?.parent?.total_hotels)
                                : 0}
                        </>
                    );
                },
            },
            {
                name: <div className="table-title">Valid</div>,
                sortable: true,
                selector: (e) => e?.parent?.valid_hotels,
                cell: (e) => {
                    return (
                        <>
                            {e?.parent?.valid_hotels
                                ? separator(e?.parent?.valid_hotels)
                                : 0}
                        </>
                    );
                },
            },
            {
                name: <div className="table-title">Invalid</div>,
                sortable: true,
                selector: (e) => e.parent?.invalid_hotels,
                cell: (e) => {
                    return (
                        <>
                            {e?.parent?.invalid_hotels
                                ? separator(e?.parent?.invalid_hotels)
                                : 0}
                        </>
                    );
                },
            },
            {
                name: <div className="table-title">Status</div>,
                sortable: true,
                selector: (e) => e.parent?.status,
                cell: (e) => {
                    return (
                        <span
                            text="white"
                            className={`badge rounded-pill text-white bg-${
                                statusBadgeVariant(e?.parent?.status)?.color
                            }`}
                            style={{
                                fontWeight: "700",
                            }}
                        >
                            {statusBadgeVariant(e.parent?.status)?.status}
                        </span>
                    );
                },
            },
        ];
        let extra = [];

        let afterExtra = [
            {
                name: <div className="table-title">Created</div>,
                selector: (e) => e.parent?.created_at,
                sortable: true,
                cell: (e) => {
                    return (
                        <>
                            {moment(new Date(e?.parent?.created_at)).format(
                                "DD/MM/YYYY HH:mm:ss"
                            )}
                        </>
                    );
                },
            },
            {
                name: "",
                sortable: true,
                selector: (e) => e?.parent?.active,
                cell: (e) => {
                    return (
                        <>
                            {e?.parent?.active ? (
                                <span
                                    className="badge badge-pill badge-light"
                                    style={{ backgroundColor: "#21ca7f47" }}
                                >
                                    <b
                                        style={{
                                            color: "#22ca80",
                                            marginLeft: "4px",
                                        }}
                                    >
                                        ●
                                        <span
                                            style={{
                                                color: "rgba(0,0,0,0.87)",
                                            }}
                                        >
                                            Active
                                        </span>
                                    </b>
                                </span>
                            ) : (
                                <span className="badge badge-pill badge-light">
                                    <b
                                        style={{
                                            color: "rgb(125, 126, 127)",
                                            marginLeft: "4px",
                                        }}
                                    >
                                        ●
                                        <span
                                            style={{
                                                color: "rgba(0,0,0,0.87)",
                                            }}
                                        >
                                            Inactive
                                        </span>
                                    </b>
                                </span>
                            )}
                        </>
                    );
                },
            },
            {
                name: <div className="table-title">History</div>,
                sortable: false,
                cell: (e) => {
                    return (
                        <>
                            <button
                                className="btn click-btn-0p"
                                onClick={() => {
                                    setShowMoreDetails(true);
                                    setCurrentElement(e);
                                }}
                            >
                                View history
                            </button>
                        </>
                    );
                },
            },
            {
                name: <div className="table-title">Download</div>,
                sortable: false,
                cell: (e) => {
                    return (
                        <>
                            {e?.parent?.status === 2 ? (
                                <DownloadCatalog dataCell={e?.parent} />
                            ) : (
                                e.parent?.status !== 2 &&
                                e.parent?.status !== 3 && (
                                    <Spinner
                                        animation="border"
                                        variant="primary"
                                        size="sm"
                                    />
                                )
                            )}
                        </>
                    );
                },
            },
        ];
        return [...required, ...extra, ...afterExtra];
    }, []);

    useEffect(() => {
        dispatch(getPartnerInventoriesByIdList(id));
        dispatch(getPartnerById(id));
    }, []);

    const handleOpenDialog = () => {
        setOpenDialog(true);
        window.analytics.track("Add new partner inventory (click)", {
            userID: localStorage.getItem("userId"),
            companyID: localStorage.getItem("companyId"),
            companyName: localStorage.getItem("companyName"),
            firstName: localStorage.getItem("firstName"),
            lastName: localStorage.getItem("lastName"),
            email: localStorage.getItem("email"),
        });
    };
    return (
        <>
            <Helmet>
                <title>Partners content</title>
            </Helmet>
            <div className="page-wrapper" style={{ display: "block" }}>
                <div className="page-breadcrumb">
                    <div className="row">
                        <div className="col-12 align-self-center">
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
                                    <button
                                        className="btn"
                                        onClick={() => {
                                            history.push(
                                                "/partners-inventories"
                                            );
                                        }}
                                        style={{ paddingLeft: "0px" }}
                                    >
                                        <i className="fas fa-arrow-left"></i>
                                    </button>
                                    {`${activePartnerInventory?.partner_name} Details`}
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    {loadingPartnerInventories || loadingPartner ? (
                        <div className="text-center">
                            <div className="lds-ripple">
                                <div className="lds-pos"></div>
                                <div className="lds-pos"></div>
                            </div>
                        </div>
                    ) : (
                        <div className="row">
                            <PartnerinventoriesHeaderDetails
                                activePartnerInventory={activePartnerInventory}
                            />

                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="d-flex align-items-center mb-4">
                                            <h4 className="card-title">
                                                {/* {`Upload ${partner?.name} catalogs and
                                            map them with yours`} */}
                                            </h4>
                                            <div className="ml-auto">
                                                {hasPermission(
                                                    CREATE_CATALOG
                                                ) && (
                                                    <div>
                                                        <button
                                                            className="btn btn-info"
                                                            onClick={
                                                                handleOpenDialog
                                                            }

                                                            // onClick={
                                                            //     handleOpenDialog
                                                            // }
                                                            // ref={target}
                                                        >
                                                            Add new partner
                                                            inventory
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="table-responsive ">
                                            <Table
                                                data={partnerInventories}
                                                paginationPerPage={3}
                                                columns={columns}
                                                loading={false}
                                                //   conditionalRowStyles={conditionalRowStyles}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {openDialog && (
                <ChoiseConnectorCsv
                    open={openDialog}
                    handleClose={() => {
                        setOpenDialog(false);

                        //  dispatch({ type: HIDE_DIALOG_ADD_NEW_CATALOG });
                        // dispatch({ type: CLEAR_PARTNER_HAS_CATALOG });
                    }}
                    partner={
                        partnerDetails ?? {
                            id: id,
                            name: activePartnerInventory?.name,
                        }
                    }
                />
            )}
            {showMoreDetails && (
                <PartnerInventoriesHistoryDetails
                    show={showMoreDetails}
                    handleClose={() => setShowMoreDetails(false)}
                    data={currentElement?.child}
                />
            )}
        </>
    );
};

export default PartnerInventoriesDetails;

function DownloadCatalog({ dataCell }) {
    const [pending, setPending] = useState(false);

    const handleDowload = (id) => async () => {
        setPending(true);
        try {
            let res = await PartnerInventoryService.dowloadPartnerInventory(id);
            if (res.status === 200) {
                fileDownload(res.data, res?.fileName);
                setPending(false);
            } else {
                toast.error("Something went wrong while downloading this file");
                setPending(false);
            }
        } catch (err) {
            toast.error("Something went wrong while downloading this file");
            setPending(false);
        }
    };

    return (
        <>
            <button className="btn" onClick={handleDowload(dataCell?.id)}>
                {pending ? (
                    <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                ) : (
                    <i className="fas fa-download"></i>
                )}
            </button>
        </>
    );
}
