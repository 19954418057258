import { ROLES } from "./const";
import moment from "moment";

export const resolveNestedPath = (path, obj, separator = ".") => {
    const properties = Array.isArray(path) ? path : path.split(separator);
    return properties.reduce((prev, curr) => {
        if (curr instanceof Function) return prev && prev.find(curr);
        return prev && prev[curr];
    }, obj);
};

export const downloadBlobFile = (blob, fileName) => {
    // Create blob link to download
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
};

export const separator = (numb) => {
    var str = numb?.toString()?.split(".");
    str[0] = str[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return str.join(".");
};

export const groupByCriteria = (arr, criteria) => {
    const newObj = arr.reduce(function (acc, currentValue) {
        if (!acc[currentValue[criteria]]) {
            acc[currentValue[criteria]] = [];
        }

        acc[currentValue[criteria]].push(currentValue);
        return acc;
    }, {});
    return newObj;
};

export const firstDayOfMonth = () => {
    var d = new Date();
    var currDay = d.getDate();
    var currMonth = d.getMonth();
    var currYear = d.getFullYear();
    var startDate = new Date(currYear, currMonth - 1, currDay);
    return startDate.toLocaleDateString("fr-CA");
};
export const firstDayOfSecondMonth = () => {
    var d = new Date();
    var currDay = d.getDate();
    var currMonth = d.getMonth();
    var currYear = d.getFullYear();
    var startDate = new Date(currYear, currMonth - 3, currDay);
    return startDate.toLocaleDateString("fr-CA");
};
export const beforeMonthsOfCurrentTime = (months) => {
    var d = new Date();
    var currMonth = d.getMonth();
    var currYear = d.getFullYear();
    var startDate = new Date(currYear, currMonth - months, 1);
    return startDate.toLocaleDateString("fr-CA");
};
export const getFirstOfYear = (months) => {
    var d = new Date();
    var currYear = d.getFullYear();
    var startDate = new Date(currYear, 0, 1);
    return startDate.toLocaleDateString("fr-CA");
};

export const hasWhiteSpace = (s) => {
    return /\s/g.test(s);
};

export const hasPermission = (permission) => {
    const role = localStorage.getItem("role");
    const hasRolePermission = ROLES?.[role]?.find((e) => e === permission);
    return hasRolePermission;
};

export const getDays = (sDate, eDate) => {
    var daysList = [];
    let dateStart = moment(sDate, "MM-DD-YYYY");
    let dateEnd = moment(eDate, "MM-DD-YYYY");
    var now = dateStart.clone();
    while (now.isSameOrBefore(dateEnd)) {
        daysList.push(now.format("YYYY-MM-DD"));
        now.add(1, "days");
    }
    return daysList;
};

export const calculatePourcentage = (total, subTotal) => {
    var perc = 0;
    if (isNaN(total) || isNaN(subTotal)) {
        perc = 0;
    } else {
        perc = ((subTotal / total) * 100).toFixed(2, 10);
    }
    return perc;
};

export const findDuplicates = (arr) => {
    return arr.filter((item, index) => arr.indexOf(item) != index) ?? [];
};

export const allAreEqual = (array) => {
    if (array.length > 0) {
        const result = array.every((element) => {
            if (element === array[0]) {
                return true;
            }
        });

        return result;
    }

    return false;
};

export const intToString = (num) => {
    num = num.toString().replace(/[^0-9.]/g, "");
    if (num < 1000) {
        return num;
    }
    let si = [
        { v: 1e3, s: "K" },
        { v: 1e6, s: "M" },
        { v: 1e9, s: "B" },
        { v: 1e12, s: "T" },
        { v: 1e15, s: "P" },
        { v: 1e18, s: "E" },
    ];
    let index;
    for (index = si.length - 1; index > 0; index--) {
        if (num >= si[index].v) {
            break;
        }
    }
    return (
        (num / si[index].v)
            .toFixed(2)
            .replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + si[index].s
    );
};
