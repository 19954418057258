import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useSelector } from "react-redux";

const CountryChart = () => {
    const { countryReportList, totalMapped } = useSelector((state) => ({
        countryReportList: state.partnerReportReducer.countryReportList ?? [],
        totalMapped:
            state.partnerReportReducer.countryReportList?.reduce(
                (accumulator, currentValue) =>
                    accumulator + currentValue?.mapped_hotels,
                0
            ) ?? 0,
    }));

    const sumPer = () => {
        let others = countryReportList
            ?.slice(9)
            ?.reduce(
                (accumulator, currentValue) =>
                    accumulator + currentValue?.mapped_hotels,
                0
            );

        let perOthers =
            totalMapped !== 0 ? +((others / totalMapped) * 100).toFixed(2) : 0;

        let result =
            countryReportList?.slice(0, 9)?.map((e) => {
                let per =
                    totalMapped !== 0
                        ? +((e?.mapped_hotels / totalMapped) * 100).toFixed(2)
                        : 0;

                return {
                    name: e?.catalog_country,
                    y: per ?? 0,
                };
            }) ?? [];

        return perOthers > 0
            ? [...result, { name: "Others", y: perOthers }]
            : result;
    };

    const options = {
        // colors: ["#01BAF2", "#71BF45", "#FAA74B", "#B37CD2"],
        chart: {
            type: "pie",
        },
        title: {
            text: "",
        },
        tooltip: {
            valueSuffix: "%",
        },
        legend: {
            align: "right",
            verticalAlign: "top",
            layout: "vertical",
            x: 0,
            y: 100,
        },

        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                    enabled: false,
                    format: "{point.name}: {y} %",
                },
                showInLegend: true,
            },
        },
        series: [
            {
                name: "Percentage",
                colorByPoint: true,
                innerSize: "50%",
                data: sumPer(),
            },
        ],
        credits: {
            enabled: false,
        },
    };
    return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default CountryChart;
