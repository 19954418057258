import React, { useState } from "react";
import TotalUnmappedList from "./TotalUnmappedList";
import TotalMappedList from "./TotalMappedList";
import SearchForm from "./SearchForm";
import { useSelector } from "react-redux";
import MappingsComparable from "./SelectMappingsComparable";
import MappingDetails from "./MappingDetails";
import MappingDetailsChart from "./MappingDetailsChart";
import RankedMappingChart from "./RankedMappingChart";
import OldHotels from "./OldHotels";

const Comparison = (props) => {
    const { token, selectedMapping, setSelectedMapping } = props;
    const { loadingSearchFields, loadingCompareMappingTotal } = useSelector(
        (state) => ({
            loadingCompareMappingTotal:
                state.partnerReportReducer.loadingCompareMappingTotal,
            loadingSearchFields: state.partnerReportReducer.loadingSearchFields,
        })
    );
    const [filters, setFilters] = useState({
        chain: [],
        city: [],
        country: [],
    });

    return (
        <div className="row" style={{ width: "100%" }}>
            <div className="col-lg-12 col-md-12">
                <MappingsComparable
                    selectedMapping={selectedMapping}
                    setSelectedMapping={setSelectedMapping}
                    token={token}
                    filters={filters}
                />
            </div>
            {loadingSearchFields && selectedMapping ? (
                <div className="text-center">
                    <div className="lds-ripple">
                        <div className="lds-pos"></div>
                        <div className="lds-pos"></div>
                    </div>
                </div>
            ) : selectedMapping ? (
                <>
                    <div className="col-lg-12 col-md-12">
                        <SearchForm
                            filters={filters}
                            setFilters={setFilters}
                            token={token}
                            selectedMapping={selectedMapping}
                        />
                    </div>
                    {loadingCompareMappingTotal ? (
                        <div className="col-md-12">
                            <div
                                className="card"
                                style={{
                                    width: "100%",
                                    marginTop: "15px",
                                }}
                            >
                                <div className="card-body">
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            fontSize: "24px",
                                            fontWeight: 700,
                                            padding: "24px",
                                        }}
                                    >
                                        Loading...
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="col-md-6">
                                <MappingDetails />
                            </div>
                            <div className="col-md-6">
                                <MappingDetailsChart />
                                <RankedMappingChart />
                            </div>
                        </>
                    )}
                    <div className="col-md-12">
                        <div
                            className="card"
                            style={{
                                width: "100%",
                                marginTop: "15px",
                            }}
                        >
                            <div className="card-header">
                                <h4
                                    className="card-title"
                                    style={{
                                        marginBottom: "0px",
                                        paddingLeft: "15px",
                                    }}
                                >
                                    <i className="fas fa-list mr-3"></i>
                                    New mapped properties (Mapped in the current
                                    and Unmaped selected report)
                                </h4>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                <TotalMappedList
                                    token={token}
                                    selectedMapping={selectedMapping}
                                    filters={filters}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div
                            className="card"
                            style={{
                                width: "100%",
                                marginTop: "15px",
                            }}
                        >
                            <div className="card-header">
                                <h4
                                    className="card-title"
                                    style={{
                                        marginBottom: "0px",
                                        paddingLeft: "15px",
                                    }}
                                >
                                    <i className="fas fa-list mr-3"></i>
                                    New opportunities (Opportunities in the
                                    current mapping and not in the Selected one)
                                </h4>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                <TotalUnmappedList
                                    token={token}
                                    selectedMapping={selectedMapping}
                                    filters={filters}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div
                            className="card"
                            style={{
                                width: "100%",
                                marginTop: "15px",
                            }}
                        >
                            <div className="card-header">
                                <h4
                                    className="card-title"
                                    style={{
                                        marginBottom: "0px",
                                        paddingLeft: "15px",
                                    }}
                                >
                                    <i className="fas fa-list mr-3"></i>
                                    Hotels that exist in the current but not in
                                    selected
                                </h4>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                <OldHotels
                                    token={token}
                                    selectedMapping={selectedMapping}
                                    filters={filters}
                                />
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <></>
            )}
        </div>
    );
};
export default Comparison;
