import moment from "moment";
import axios from "axios";
const url = process.env.REACT_APP_BASE_URL_AUTH;

const LoginService = {
    signIn: async (cridentials) => {
        const result = await axios
            .post(url + "auth/login", cridentials)
            .then(async (res) => {
                var now = moment(new Date(), "DD/MM/YYYY HH:mm:ss");
                var expire = moment(
                    new Date(res.data.expire),
                    "DD/MM/YYYY HH:mm:ss"
                );
                var diffTime = moment.duration(expire.diff(now));
                localStorage.setItem("token", res.data.token);
                localStorage.setItem(
                    "expireDuration",
                    diffTime.asMilliseconds()
                );
                localStorage.setItem("expire", res.data.expire);
                await localStorage.setItem("email", cridentials.login);

                return {
                    data: res.data,
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: error?.response?.data?.message,
                    status: error?.response?.data?.code,
                };
            });
        return result;
    },

    signUp: async (user) => {
        const result = await axios
            .post(url + "auth/signup", user)
            .then((res) => {
                return {
                    data: res.data,
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: error.response.data.msg,
                    status: error.response.data.code,
                };
            });
        return result;
    },

    verifyEmail: async (email) => {
        const result = await axios
            .post(url + "auth/send-verification-email", email)
            .then((res) => {
                return {
                    data: res.data,
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: error.response.data.msg,
                    status: error.response.data.code,
                };
            });
        return result;
    },

    verifyCodeEmail: async (code) => {
        const result = await axios
            .get(url + "auth/verify", { params: { code: code } })
            .then((res) => {
                return {
                    data: res.data,
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: error.response.data.msg,
                    status: error.response.data.code,
                };
            });
        return result;
    },

    refreshToken: async () => {
        const result = await axios
            .get(url + "auth/refresh_token", {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                return {
                    data: res?.data,
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: error?.response?.data?.msg,
                    status: error?.response?.data?.code,
                };
            });
        return result;
    },

    forgotPassword: async (values) => {
        const result = await axios
            .get(url + `auth/forget-password/${values?.email}`)
            .then((res) => {
                return {
                    data: res.data,
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: error.response.data.msg,
                    status: error.response.data.code,
                };
            });
        return result;
    },
    resetPasswordConfirm: async (token, values) => {
        const result = await axios
            .post(url + `auth/reset-password/${token}`, values)
            .then((res) => {
                return {
                    data: res.data,
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: error.response.data.msg,
                    status: error.response.data.code,
                };
            });
        return result;
    },
    signUpTeamMember: async (values) => {
        const result = await axios
            .post(url + `auth/CompleteSignup`, values)
            .then((res) => {
                return {
                    data: res.data,
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: error.response.data.msg,
                    status: error.response.data.code,
                };
            });
        return result;
    },
};

export default LoginService;
