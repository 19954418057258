import React, { useState, useEffect } from "react";
import CredentialsForm from "./CredentialsForm";
import GiataMappingList from "./MappingList";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { hasGiataCredentials } from "../../../redux/actions/ModelsActions";
import { getCompletedSteps } from "../../../redux/actions/AnalyticsActions";
import { useHistory } from "react-router-dom";
import { CREATE_INVENTORY } from "../../../utilis/const";
import { hasPermission } from "../../../utilis/functions";
import CatalogNoDataModal from "../../Mapping/CatalogNoDataModal";

const GiataPage = () => {
    const history = useHistory();
    const {
        hasGiataAccount,
        loadingHasGiataAccount,
        loadingPartnerSteps,
        partnerSteps,
    } = useSelector((state) => ({
        hasGiataAccount: state.modelsReducer.hasGiataAccount?.has_account,
        loadingHasGiataAccount: state.modelsReducer.loadingHasGiataAccount,
        loadingPartnerSteps: state.analyticsReducer.loadingPartnerSteps,
        partnerSteps: state.analyticsReducer.partnerSteps,
    }));
    const [showCredentialsForm, setShowCredentialsForm] = useState(
        !hasGiataAccount
    );
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(hasGiataCredentials());
        dispatch(getCompletedSteps());
    }, []);

    useEffect(() => {
        setShowCredentialsForm(!hasGiataAccount);
    }, [hasGiataAccount]);

    return (
        <>
            <Helmet>
                <title> GIATA</title>
            </Helmet>
            <div className="page-wrapper" style={{ display: "block" }}>
                <div className="page-breadcrumb">
                    <div className="row">
                        <div className="col-7 align-self-center">
                            <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
                                GIATA
                            </h3>
                        </div>
                        <div className="col-12">
                            <p className="card-text">
                                <span className="sub-title-info ">
                                    <i className="fa  fa-info-circle"></i>
                                    &nbsp;Giata.
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    {loadingHasGiataAccount || loadingPartnerSteps ? (
                        <div className="text-center">
                            <div className="lds-ripple">
                                <div className="lds-pos"></div>
                                <div className="lds-pos"></div>
                            </div>
                        </div>
                    ) : partnerSteps?.has_inventory === false ? (
                        <CatalogNoDataModal
                            title="Ready!"
                            text2="Your dedicated workspace is almost ready to go !"
                            text3="First, you’ll need to upload your hotel inventory."
                            buttonText="Upload inventory"
                            handleClick={async () => {
                                history.push("/catalogs-manager");
                            }}
                            hideButton={
                                hasPermission(CREATE_INVENTORY) ? false : true
                            }
                        />
                    ) : showCredentialsForm ? (
                        <CredentialsForm
                            setShowCredentialsForm={setShowCredentialsForm}
                        />
                    ) : (
                        <GiataMappingList
                            setShowCredentialsForm={setShowCredentialsForm}
                        />
                    )}
                </div>
            </div>
        </>
    );
};
export default GiataPage;
