import React, { useState } from "react";
import { Container, Navbar, Nav } from "react-bootstrap";
import { useLocation } from 'react-router-dom';
import { Link } from "react-router-dom";

function Header(props) {
    const { currentTab, setCurrentTab} = props;
    const params = useLocation();
    const queryParams = new URLSearchParams(params.search);
    const [tokens,setTokens]=useState({
        external_manual_mapping: queryParams.get('token_external_manual_mapping'),
        mapping_report: queryParams.get('token_mapping_report')
    })
    
    return (
        <header className="header">
            <div className="container report-container-header">
                <div
                    style={{ justifyContent: "space-between", display: "flex" }}
                >
                    {/* external_manual_mapping = {tokens.external_manual_mapping}
================================================================
                    mapping_report =  {tokens.mapping_report} */}
                    <nav className="navbar-expand-lg navbar-light">
                        <div className="navbar-brand">
                            <Navbar
                                expand="lg"
                                style={{
                                    padding: "0px",
                                }}
                            >
                                <Container>
                                    <Navbar.Brand>
                                        <span className="logo-text">
                                            Cup
                                            <span className="purple-text">
                                                id
                                            </span>
                                            &nbsp;
                                        </span>
                                    </Navbar.Brand>
                                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                    <Nav
                                        defaultActiveKey={currentTab}
                                        onSelect={(selectedKey) => {
                                            setCurrentTab(selectedKey);
                                        }}
                                        style={{display: "flex",gap:"16px"}}
                                    >
                                       <Nav.Item>
                                            <Link className={`${params.pathname.includes("/get-started") ? "ActiveNav":"NoActiveNav"}`} to={"/get-started?token_mapping_report="+tokens.mapping_report+"&token_external_manual_mapping="+tokens.external_manual_mapping}>
                                                Getting started
                                            </Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Link className={`${params.pathname.includes("/partner-reports") ? "ActiveNav":"NoActiveNav"}`}   to={"/partner-reports?token_mapping_report="+tokens.mapping_report+"&token_external_manual_mapping="+tokens.external_manual_mapping}>
                                                Mapping Report
                                            </Link>
                                        </Nav.Item>
                                        {tokens.external_manual_mapping?
                                        <Nav.Item>
                                            <Link className={`${params.pathname.includes("/external-manual-mapping") ? "ActiveNav":"NoActiveNav"}`}  to={"/external-manual-mapping?token_external_manual_mapping="+tokens.external_manual_mapping+"&token_mapping_report="+tokens.mapping_report}>
                                                Manual mapping 
                                            </Link>
                                        </Nav.Item>:null}
                                        
                                        {/* {!isAffiliate && (
                                            <Nav.Item>
                                                <Nav.Link eventKey="activity">
                                                    Activity
                                                </Nav.Link>
                                            </Nav.Item>
                                        )}
                                        <Nav.Item>
                                            <Nav.Link eventKey="comparison">
                                                Comparison
                                            </Nav.Link>
                                        </Nav.Item> */}
                                    </Nav>
                                </Container>
                            </Navbar>
                        </div>
                    </nav>
                </div>
            </div>
        </header>
    );
}
export default Header;
