import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Modal, Spinner } from "react-bootstrap";
import ConnectorService from "../../services/ConnectorService";
import Cron, { HEADER } from "react-cron-generator";
import { getCredentials } from "../../redux/actions/ConnectorActions";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { FILE_FORMAT, DATABASE, WEBHOOK } from "../../utilis/const";
import { getDestinations } from "../../redux/actions/DestinationActions";
import { Link } from "react-router-dom";
import Launching from "../../lotties/launching.gif";
import Stepper from "react-stepper-horizontal";

function SchedulerModal(props) {
    const { show, onClose, currentElement } = props;
    const dispatch = useDispatch();
    const [cronValue, setCronValue] = useState({});
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedItems, setSelectedItems] = useState([]);

    const options = {
        headers: [HEADER.DAILY, HEADER.WEEKLY, HEADER.MONTHLY],
    };

    React.useEffect(() => {
        dispatch(getDestinations());
    }, []);
    const handleSubmit = async () => {
        setLoading(true);
        const res = await ConnectorService.scheduler(
            currentElement?.id,
            cronValue?.value,
            selectedItems?.filter((e) => e?.checked === true)
        );
        if (res.status === 200) {
            dispatch(getCredentials());
            window.analytics.track("Run scheduler", {
                userId: localStorage.getItem("userId"),
                companyId: localStorage.getItem("companyId"),
                cridentialsId: currentElement.id,
            });
            onClose();
        } else {
            toast.error(res.message);
        }
        setLoading(false);
    };

    const sections = [
        {
            title: "Scheduler",
        },
        {
            title: "Data Export",
        },
    ];

    const renderElement = () => {
        switch (currentPage) {
            case 1:
                return (
                    <>
                        <Cron
                            onChange={(e) => {
                                setCronValue({ value: e });
                            }}
                            value={cronValue?.value}
                            options={options}
                            showResultText={true}
                            timezone={"America/New_York"}
                            // showResultCron={true}
                        />
                        <Modal.Title
                            style={{
                                marginTop: "14px",
                                marginBottom: "14px",
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#6076e8",
                                textAlign: "center",
                            }}
                        >
                            Timezone (UTC)
                        </Modal.Title>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            <button
                                className="btn btn-light"
                                style={{ marginRight: "5px" }}
                                onClick={() => {
                                    onClose();
                                }}
                            >
                                Cancel
                            </button>
                            <button
                                className="btn  btn-warning"
                                onClick={() => {
                                    setCurrentPage(2);
                                }}
                                disabled={loading}
                            >
                                Next &nbsp;&nbsp;
                                <i className="fas fa-caret-right"></i>
                            </button>
                        </div>
                    </>
                );
            case 2:
                return (
                    <>
                        <MappingDestinationModal
                            selectedItems={selectedItems}
                            setSelectedItems={setSelectedItems}
                        />
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            <button
                                className="btn btn-warning"
                                style={{ marginRight: "5px" }}
                                onClick={() => {
                                    setCurrentPage(1);
                                }}
                            >
                                <i className="fas fa-caret-left"></i>{" "}
                                &nbsp;&nbsp; Back
                            </button>
                            <button
                                className="btn  btn-light"
                                style={{ marginRight: "5px" }}
                                onClick={() => {
                                    onClose();
                                }}
                            >
                                Cancel
                            </button>
                            <button
                                className="btn btn-primary"
                                onClick={() => {
                                    handleSubmit();
                                }}
                                disabled={loading}
                            >
                                Confirm
                                {loading && (
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                )}
                            </button>
                        </div>
                    </>
                );
            default:
                return <></>;
        }
    };

    return (
        <Modal
            show={show}
            onHide={onClose}
            dialogClassName="modal-40w modal-dialog modal-dialog-centered"
        >
            <div className="card-header p-0 pb-2">
                <div className="stepper">
                    <Stepper
                        steps={sections}
                        activeStep={currentPage}
                        activeColor="#D3D7DB"
                        defaultBarColor="#D3D7DB"
                        completeColor="#6076E8"
                        completeBarColor="#6076E8"
                        fontFamily="Roboto"
                        textColor="#fff"
                        defaultBorderStyle="solid 6px"
                        defaultBorderWidth={10}
                    />
                </div>
            </div>
            <Modal.Body>{renderElement()}</Modal.Body>
        </Modal>
    );
}

export default SchedulerModal;

function MappingDestinationModal({
    dataCell,
    addOriginPath,
    selectedItems,
    setSelectedItems,
}) {
    const { destinationsList, loading } = useSelector((state) => ({
        destinationsList:
            state.destinationReducer?.destinationList?.data?.filter(
                (e) => e?.configured === true
            ) ?? [],
        loading: state.destinationReducer?.loading,
    }));
    const [displayedData, setDisplayedData] = React.useState(destinationsList);

    React.useEffect(() => {
        setDisplayedData(destinationsList);
    }, [loading]);

    const renderElement = (name, type) => {
        return (
            <fieldset className="choose-destination">
                <legend
                    className="scheduler-border"
                    style={{ fontSize: "1rem" }}
                >
                    {name}
                </legend>
                <div className="row">
                    {displayedData
                        ?.filter((e) => e?.data_type === type)
                        ?.map((e, index) => {
                            return (
                                <Destination
                                    element={e}
                                    addOriginPath={addOriginPath}
                                    selectedItems={selectedItems}
                                    setSelectedItems={setSelectedItems}
                                    index={index}
                                />
                            );
                        })}
                </div>
            </fieldset>
        );
    };

    return (
        <>
            {displayedData?.length > 0 ? (
                <>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            // marginBottom: "10px",
                        }}
                    >
                        <span className="sub-title-info">
                            You can configure more data export in
                            <Link
                                to="/export-destinations"
                                style={{ marginLeft: "4px" }}
                            >
                                Export data{" "}
                            </Link>
                        </span>
                    </div>
                    {displayedData?.filter((e) => e?.data_type === FILE_FORMAT)
                        ?.length > 0 &&
                        renderElement("File format", FILE_FORMAT)}
                    {displayedData?.filter((e) => e?.data_type === WEBHOOK)
                        ?.length > 0 && renderElement("Webhook", WEBHOOK)}
                    {displayedData?.filter((e) => e?.data_type === DATABASE)
                        ?.length > 0 && renderElement("Database", DATABASE)}
                </>
            ) : (
                <div style={{ textAlign: "center" }}>
                    <img
                        src={Launching}
                        height="200"
                        width="200"
                        alt="destinations"
                    />
                    <h3 style={{ fontWeight: "bold", marginBottom: "10px" }}>
                        You can configure your data export in
                        <Link
                            to="/export-destinations"
                            style={{ marginLeft: "4px" }}
                        >
                            Export data
                        </Link>
                    </h3>
                </div>
            )}
        </>
    );
}

const Destination = (props) => {
    const { element, addOriginPath, selectedItems, setSelectedItems, index } =
        props;
    const handleClick = () => {
        var elt = selectedItems?.find((e) => e?.id === element?.id);
        if (elt) {
            var newList = selectedItems?.map((e) => {
                if (element.id === e?.id) {
                    const updatedElement = {
                        ...e,
                        checked: !e?.checked,
                    };
                    return updatedElement;
                }

                return e;
            });
            setSelectedItems(newList);
        } else {
            setSelectedItems((current) => [
                ...current,
                { ...element, checked: true },
            ]);
        }
    };
    return (
        <div className="col-6 col-md-4 col-lg-3">
            <div
                className="integration-entity mx-auto"
                style={{ marginBottom: "1rem" }}
            >
                <div onClick={handleClick}>
                    <div
                        className={`integration-entity-img-connector-destination `}
                        style={{
                            border: selectedItems?.find(
                                (e) =>
                                    e?.id === element?.id && e?.checked === true
                            )
                                ? "1px solid #5f76e8"
                                : "",
                            cursor: "pointer",
                            margin: "0px",
                        }}
                    >
                        <img
                            id={element?.id}
                            className="cloudinary-image ls-is-cached lazyloaded"
                            alt={element?.name}
                            src={
                                addOriginPath
                                    ? "../" + element?.logo
                                    : element?.logo
                            }
                            height="50"
                            width="50"
                        />
                    </div>
                </div>

                <div
                    className="integration-entity-name"
                    style={{ fontSize: "1rem" }}
                >
                    <div
                        className="custom-control custom-checkbox"
                        style={{
                            paddingLeft: "0px",
                        }}
                    >
                        <input
                            id={`scheduler-destination${element.id}`}
                            checked={selectedItems?.find(
                                (e) =>
                                    e?.id === element?.id && e?.checked === true
                            )}
                            onClick={handleClick}
                            type="checkbox"
                            className="custom-control-input"
                            style={{
                                cursor: "pointer",
                            }}
                        />
                        <label
                            className="custom-control-label"
                            for={`scheduler-destination${element.id}`}
                        >
                            {element?.name}
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
};
