import React from "react";
import Lottie from "react-lottie";
import animationData from "../../lotties/rocket.json";

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};

function CatalogNoDataModal(props) {
    const { title, text, buttonText, handleClick, hideButton, text2, text3 } =
        props;
    return (
        <div style={{ textAlign: "center" }}>
            <Lottie options={defaultOptions} width={300} />
            <h2 className="modalTitle">{title}</h2>
            <p className="modalP">{text}</p>
            {text2 && (
                <p
                    className="modalP"
                    style={{ fontSize: "21px", fontWeight: "600" }}
                >
                    {text2}
                </p>
            )}
            {text3 && (
                <p
                    className="modalP"
                    style={{ fontSize: "21px", fontWeight: "600" }}
                >
                    {text3}
                </p>
            )}
            {hideButton ? (
                <button className="btn" style={{ visibility: "hidden" }} />
            ) : (
                <button
                    className="modalBtn btn waves-effect waves-light btn-rounded btn-primary"
                    onClick={handleClick}
                >
                    {buttonText}
                </button>
            )}
        </div>
    );
}

export default CatalogNoDataModal;
