import React, { useEffect, useState } from "react";
import Table from "../../components/Table/TablePaginationFromApi";
import { useSelector, useDispatch } from "react-redux";
import { getInvalidHotelListReport } from "../../redux/actions/PartnerReportAction";

const InvalidHotelList = (props) => {
    const { token, filters } = props;
    const dispatch = useDispatch();
    const [perPage, setPerPage] = useState(10);
    const [filterText, setFilterText] = useState("");

    const [column, setColumn] = useState();
    const [sortDirection, setSortDirection] = useState();
    const { loadingInvalidHotels, InvalidHotelReportList, countHotelInvalid } =
        useSelector((state) => ({
            InvalidHotelReportList:
                state.partnerReportReducer.InvalidHotelReportList ?? [],
            loadingInvalidHotels:
                state.partnerReportReducer.loadingInvalidHotels,
            countHotelInvalid: state.partnerReportReducer.countHotelInvalid,
        }));
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    const columns = [
        {
            id: "hotel_code",
            name: <div className="table-title">Partner Hotel Code</div>,
            //  selector: (e) => e?.hotel_code,
            cell: (e) => {
                return <>{e?.hotel_code}</>;
            },
            sortable: true,
        },
        {
            id: "name",
            name: <div className="table-title">Name</div>,
            // selector: (e) => e?.name,
            cell: (e) => {
                return <span>{e?.name}</span>;
            },
            sortable: true,
        },

        {
            id: "city",
            name: <div className="table-title">City</div>,
            //  selector: (e) => e?.city,
            cell: (e) => {
                return <>{e?.city}</>;
            },
            sortable: true,
        },
        {
            id: "country",
            name: <div className="table-title">Country</div>,
            // selector: (e) => e?.country,
            cell: (e) => {
                return <>{e?.country}</>;
            },
            sortable: true,
        },
        {
            id: "address",
            name: <div className="table-title">Address</div>,
            //selector: (e) => e?.address,
            cell: (e) => {
                return <>{e?.address}</>;
            },
            sortable: true,
        },

        {
            id: "latitude",
            name: <div className="table-title">Latitude</div>,
            // selector: (e) => e?.latitude,
            cell: (e) => {
                return <>{e?.latitude}</>;
            },
            sortable: true,
        },
        {
            id: "longitude",
            name: <div className="table-title">Longitude</div>,
            //  selector: (e) => e?.longitude,
            cell: (e) => {
                return <>{e?.longitude}</>;
            },
            sortable: true,
        },
        {
            id: "invalid_reason",
            name: <div className="table-title">Invalid Reason</div>,
            //selector: (e) => e?.invalid_reason,
            cell: (e) => {
                return <>{e?.invalid_reason}</>;
            },
            sortable: true,
        },
    ];

    useEffect(() => {
        dispatch(
            getInvalidHotelListReport(token, 1, perPage, filterText, {
                hotel_id: filters?.hotel_id?.map((e) => e?.label) ?? [],
                cities: filters?.cities?.map((e) => e?.label) ?? [],
                countries: filters?.countries?.map((e) => e?.label) ?? [],
                hotel_name: filters?.hotel_name?.map((e) => e?.label) ?? [],
                partner_hotel_id:
                    filters?.partner_hotel_id?.map((e) => e?.label) ?? [],
                chains: filters?.chains?.map((e) => e?.label) ?? [],
                propertyType: filters?.propertyType?.map((e) => e?.label) ?? [],
                stars: filters?.stars?.map((e) => e?.label) ?? [],
            })
        );
    }, []);

    const handlePageChange = (page) => {
        dispatch(
            getInvalidHotelListReport(
                token,
                page,
                perPage,
                filterText,
                {
                    hotel_id: filters?.hotel_id?.map((e) => e?.label) ?? [],
                    cities: filters?.cities?.map((e) => e?.label) ?? [],
                    countries: filters?.countries?.map((e) => e?.label) ?? [],
                    hotel_name: filters?.hotel_name?.map((e) => e?.label) ?? [],
                    partner_hotel_id:
                        filters?.partner_hotel_id?.map((e) => e?.label) ?? [],
                    chains: filters?.chains?.map((e) => e?.label) ?? [],
                    propertyType:
                        filters?.propertyType?.map((e) => e?.label) ?? [],
                    stars: filters?.stars?.map((e) => e?.label) ?? [],
                },
                column,
                sortDirection
            )
        );
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        dispatch(
            getInvalidHotelListReport(
                token,
                page,
                newPerPage,
                filterText,
                {
                    hotel_id: filters?.hotel_id?.map((e) => e?.label) ?? [],
                    cities: filters?.cities?.map((e) => e?.label) ?? [],
                    countries: filters?.countries?.map((e) => e?.label) ?? [],
                    hotel_name: filters?.hotel_name?.map((e) => e?.label) ?? [],
                    partner_hotel_id:
                        filters?.partner_hotel_id?.map((e) => e?.label) ?? [],
                    chains: filters?.chains?.map((e) => e?.label) ?? [],
                    propertyType:
                        filters?.propertyType?.map((e) => e?.label) ?? [],
                    stars: filters?.stars?.map((e) => e?.label) ?? [],
                },
                column,
                sortDirection
            )
        );
        setPerPage(newPerPage);
    };

    const handleSort = async (c, sortD) => {
        dispatch(
            getInvalidHotelListReport(
                token,
                1,
                perPage,
                filterText,
                {
                    hotel_id: filters?.hotel_id?.map((e) => e?.label) ?? [],
                    cities: filters?.cities?.map((e) => e?.label) ?? [],
                    countries: filters?.countries?.map((e) => e?.label) ?? [],
                    hotel_name: filters?.hotel_name?.map((e) => e?.label) ?? [],
                    partner_hotel_id:
                        filters?.partner_hotel_id?.map((e) => e?.label) ?? [],
                    chains: filters?.chains?.map((e) => e?.label) ?? [],
                    propertyType:
                        filters?.propertyType?.map((e) => e?.label) ?? [],
                    stars: filters?.stars?.map((e) => e?.label) ?? [],
                },
                c?.id,
                sortD
            )
        );
        setColumn(c?.id);
        setSortDirection(sortD);
    };
    return (
        <div style={{ marginTop: "15px" }}>
            <Table
                data={InvalidHotelReportList ?? []}
                columns={columns}
                totalRows={countHotelInvalid}
                handlePageChange={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                progressPending={loadingInvalidHotels}
                onFilter={() => {
                    setResetPaginationToggle(true);

                    dispatch(
                        getInvalidHotelListReport(
                            token,
                            1,
                            perPage,
                            filterText,
                            {
                                hotel_id:
                                    filters?.hotel_id?.map((e) => e?.label) ??
                                    [],
                                cities:
                                    filters?.cities?.map((e) => e?.label) ?? [],
                                countries:
                                    filters?.countries?.map((e) => e?.label) ??
                                    [],
                                hotel_name:
                                    filters?.hotel_name?.map((e) => e?.label) ??
                                    [],
                                partner_hotel_id:
                                    filters?.partner_hotel_id?.map(
                                        (e) => e?.label
                                    ) ?? [],
                                chains:
                                    filters?.chains?.map((e) => e?.label) ?? [],
                                propertyType:
                                    filters?.propertyType?.map(
                                        (e) => e?.label
                                    ) ?? [],
                                stars:
                                    filters?.stars?.map((e) => e?.label) ?? [],
                            }
                        )
                    );
                }}
                filterValue={filterText}
                handleClearFilter={() => {
                    setFilterText("");
                    setResetPaginationToggle(true);
                    dispatch(
                        getInvalidHotelListReport(token, 1, perPage, "", {
                            hotel_id:
                                filters?.hotel_id?.map((e) => e?.label) ?? [],
                            cities: filters?.cities?.map((e) => e?.label) ?? [],
                            countries:
                                filters?.countries?.map((e) => e?.label) ?? [],
                            hotel_name:
                                filters?.hotel_name?.map((e) => e?.label) ?? [],
                            partner_hotel_id:
                                filters?.partner_hotel_id?.map(
                                    (e) => e?.label
                                ) ?? [],
                            chains: filters?.chains?.map((e) => e?.label) ?? [],
                            propertyType:
                                filters?.propertyType?.map((e) => e?.label) ??
                                [],
                            stars: filters?.stars?.map((e) => e?.label) ?? [],
                        })
                    );
                }}
                onChange={(e) => {
                    setFilterText(e.target.value);
                    if (e.target.value === "") {
                        setResetPaginationToggle(true);
                        dispatch(
                            getInvalidHotelListReport(token, 1, perPage, "", {
                                hotel_id:
                                    filters?.hotel_id?.map((e) => e?.label) ??
                                    [],
                                cities:
                                    filters?.cities?.map((e) => e?.label) ?? [],
                                countries:
                                    filters?.countries?.map((e) => e?.label) ??
                                    [],
                                hotel_name:
                                    filters?.hotel_name?.map((e) => e?.label) ??
                                    [],
                                partner_hotel_id:
                                    filters?.partner_hotel_id?.map(
                                        (e) => e?.label
                                    ) ?? [],
                                chains:
                                    filters?.chains?.map((e) => e?.label) ?? [],
                                propertyType:
                                    filters?.propertyType?.map(
                                        (e) => e?.label
                                    ) ?? [],
                                stars:
                                    filters?.stars?.map((e) => e?.label) ?? [],
                            })
                        );
                    }
                }}
                filter={true}
                resetPaginationToggle={resetPaginationToggle}
                onSort={handleSort}
                sortServer={true}
            />
        </div>
    );
};
export default InvalidHotelList;
