import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { VERSIONS } from "../../utilis/const";

const liStyle = {
    marginTop: "10px",
};
const VersionBadge = {
    backgroundColor: "grey",
    fontSize: "14px",
};
const versionComponent = (version, i) => {
    return (
        <div className="card-body">
            <div className="d-flex align-items-center">
                <div key={i}>
                    <h4 className="card-title">
                        {version?.version}
                        {version?.latest && (
                            <span
                                className={`badge rounded-pill text-black bg-primary`}
                                style={{
                                    color: "white",
                                    fontWeight: "600",
                                    marginLeft: "10px",
                                }}
                            >
                                LATEST
                            </span>
                        )}
                        <span
                            className={`badge rounded-pill text-black bg-warning`}
                            style={{
                                color: "black",
                                fontWeight: "600",
                                marginLeft: "10px",
                            }}
                        >
                            Released: {version?.released}
                        </span>
                    </h4>
                    <ul
                        style={{
                            marginTop: "20px",
                        }}
                    >
                        {version?.new?.length > 0 && (
                            <li style={{ ...liStyle }}>
                                <span
                                    className={`badge rounded-pill text-black`}
                                    style={{
                                        ...VersionBadge,
                                        color: "white",
                                        fontWeight: "600",
                                        marginLeft: "10px",
                                    }}
                                >
                                    New
                                </span>
                                <ul>
                                    {version?.new?.map((e, index) => {
                                        return (
                                            <li
                                                key={index}
                                                style={{
                                                    ...liStyle,
                                                }}
                                            >
                                                {e.name}
                                                {e?.subNew?.length > 0 && (
                                                    <ol>
                                                        {e?.subNew?.map(
                                                            (s, i) => {
                                                                return (
                                                                    <li>
                                                                        <span
                                                                            style={{
                                                                                fontWeight:
                                                                                    "500",
                                                                            }}
                                                                        >
                                                                            {s}
                                                                        </span>
                                                                    </li>
                                                                );
                                                            }
                                                        )}
                                                    </ol>
                                                )}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </li>
                        )}

                        {version?.improvement?.length > 0 && (
                            <li style={{ ...liStyle }}>
                                <span
                                    className={`badge rounded-pill text-black`}
                                    style={{
                                        ...VersionBadge,
                                        color: "white",
                                        fontWeight: "600",
                                        marginLeft: "10px",
                                    }}
                                >
                                    Improvement
                                </span>
                                <ul>
                                    {version?.improvement?.map((e, index) => {
                                        return (
                                            <li
                                                key={index}
                                                style={{
                                                    ...liStyle,
                                                }}
                                            >
                                                {e}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </li>
                        )}

                        {version?.bugFix?.length > 0 && (
                            <li style={{ ...liStyle }}>
                                <span
                                    className={`badge rounded-pill text-black`}
                                    style={{
                                        ...VersionBadge,
                                        color: "white",
                                        fontWeight: "600",
                                        marginLeft: "10px",
                                    }}
                                >
                                    Bug Fix
                                </span>
                                <ul>
                                    {version?.bugFix?.map((e, index) => {
                                        return (
                                            <li
                                                key={index}
                                                style={{
                                                    ...liStyle,
                                                }}
                                            >
                                                {e}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
};
function VersionLogs() {
    const [showOldVersion, setShowOldVersion] = useState(false);

    return (
        <>
            <Helmet>
                <title>Changelog</title>
            </Helmet>
            <div className="page-wrapper" style={{ display: "block" }}>
                <div className="page-breadcrumb">
                    <div className="row">
                        <div className="col-7 align-self-center">
                            <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
                                Changelog
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div
                                className="card"
                                style={{ marginBottom: "0px" }}
                            >
                                {versionComponent(
                                    VERSIONS?.find((e) => e?.latest === true)
                                )}
                                {showOldVersion ? (
                                    <div className="col-12 hideOldBlocs">
                                        <h4>
                                            <button
                                                className="btn text-link-button2 "
                                                onClick={() =>
                                                    setShowOldVersion(false)
                                                }
                                                style={{
                                                    fontSize: "1.125rem",
                                                    fontWeight: "600",
                                                    marginBottom: "5px",
                                                }}
                                            >
                                                Hide old versions
                                            </button>
                                        </h4>
                                    </div>
                                ) : (
                                    <div className="col-12 showOldBlocs">
                                        <h4>
                                            <button
                                                className="btn text-link-button2 "
                                                onClick={() =>
                                                    setShowOldVersion(true)
                                                }
                                                style={{
                                                    fontSize: "1.125rem",
                                                    fontWeight: "600",
                                                    marginBottom: "5px",
                                                }}
                                            >
                                                Show old versions
                                            </button>
                                        </h4>
                                    </div>
                                )}
                                {showOldVersion &&
                                    VERSIONS?.filter(
                                        (e) => e?.latest === false
                                    )?.map((e, index) => {
                                        return (
                                            <>
                                                {versionComponent(
                                                    e,
                                                    index,
                                                    showOldVersion,
                                                    setShowOldVersion
                                                )}
                                            </>
                                        );
                                    })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default VersionLogs;
