import axios from "axios";
const url = process.env.REACT_APP_BASE_URL;

const HotelRankingService = {
    uploadCatalog: async (data, opt) => {
        var path_url = "";
        path_url = "/hotels-rankings";
        const result = await axios
            .post(url + path_url, data, opt)
            .then((res) => {
                console.log(res, "resss");
                return {
                    data: res.data,
                    status: 200,
                };
            })
            .catch((error) => {
                console.log("error", error?.response);
                return {
                    message: error?.response?.data?.message,
                    status: error?.response?.status,
                };
            });
        return result;
    },
};

export default HotelRankingService;
