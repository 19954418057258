import {
    GET_DESTINATION_LIST_DATA_FAIL,
    GET_DESTINATION_LIST_DATA_LISTING,
    GET_DESTINATION_LIST_DATA_SUCCESS,
    GET_DESTINATION_CREDENTIALS_DATA_FAIL,
    GET_DESTINATION_CREDENTIALS_DATA_LISTING,
    GET_DESTINATION_CREDENTIALS_DATA_SUCCESS,
} from "../types";
import axios from "axios";

const PATH_URL = process.env.REACT_APP_BASE_URL;

export const getDestinations = () => async (dispatch) => {
    dispatch({
        type: GET_DESTINATION_LIST_DATA_LISTING,
    });
    try {
        const res = await axios.get(PATH_URL + "/destinations/", {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        dispatch({
            type: GET_DESTINATION_LIST_DATA_SUCCESS,
            payload: res.data,
        });
    } catch (e) {
        dispatch({
            type: GET_DESTINATION_LIST_DATA_FAIL,
            payload: console.log("Error in actions ", e),
        });
    }
};
export const getDestinationCredentials = (id) => async (dispatch) => {
    dispatch({
        type: GET_DESTINATION_CREDENTIALS_DATA_LISTING,
    });
    try {
        const res = await axios.get(
            PATH_URL + `/destinations/credentials/${id}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }
        );
        dispatch({
            type: GET_DESTINATION_CREDENTIALS_DATA_SUCCESS,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: GET_DESTINATION_CREDENTIALS_DATA_FAIL,
            payload: console.log("Error in actions ", e),
        });
    }
};
