/* eslint-disable import/no-anonymous-default-export */
import {
    GET_ALL_PARTNER_KEYS_LISTING,
    GET_ALL_PARTNER_KEYS_SUCCESS,
    GET_ALL_PARTNER_KEYS_FAIL,
    GET_GIATA_PARTNERS_LIST_LISTING,
    GET_GIATA_PARTNERS_LIST_SUCCESS,
    GET_GIATA_PARTNERS_LIST_FAIL,
    GET_GIATA_PARTNERS_LIST_PERIODIQUE_SUCCESS,
    HAS_GIATA_ACCOUNT_LISTING,
    HAS_GIATA_ACCOUNT_SUCCESS,
    HAS_GIATA_ACCOUNT_FAIL,
    REFRESH_GIATA_DATA_LISTING,
    REFRESH_GIATA_DATA_SUCCESS,
    REFRESH_GIATA_DATA_FAIL,
    GET_CUPID_MODELS_LISTING,
    GET_CUPID_MODELS_SUCCESS,
    GET_CUPID_MODELS_FAIL,
} from "../types";

const initialState = {
    partnersKeys: [],
    loadingPartnerKeys: false,
    giataPartnerList: [],
    loadingGiataPartnerList: false,
    hasGiataAccount: false,
    loadingHasGiataAccount: false,
    loadingRefreshData: false,
    cupidModelsList: [],
    loadingCupidModelsList: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ALL_PARTNER_KEYS_LISTING:
            return {
                ...state,
                loadingPartnerKeys: true,
            };
        case GET_ALL_PARTNER_KEYS_SUCCESS:
            return {
                ...state,
                partnersKeys: action.payload,
                loadingPartnerKeys: false,
            };
        case GET_ALL_PARTNER_KEYS_FAIL:
            return {
                ...state,
                loadingPartnerKeys: false,
            };
        case GET_GIATA_PARTNERS_LIST_LISTING:
            return {
                ...state,
                loadingGiataPartnerList: true,
            };
        case GET_GIATA_PARTNERS_LIST_SUCCESS:
            return {
                ...state,
                giataPartnerList: action.payload,
                loadingGiataPartnerList: false,
            };
        case GET_GIATA_PARTNERS_LIST_FAIL:
            return {
                ...state,
                loadingGiataPartnerList: false,
            };

        case GET_GIATA_PARTNERS_LIST_PERIODIQUE_SUCCESS:
            return {
                ...state,
                giataPartnerList: action.payload,
            };
        case HAS_GIATA_ACCOUNT_LISTING:
            return {
                ...state,
                loadingHasGiataAccount: true,
            };
        case HAS_GIATA_ACCOUNT_SUCCESS:
            return {
                ...state,
                hasGiataAccount: action.payload,
                loadingHasGiataAccount: false,
            };
        case HAS_GIATA_ACCOUNT_FAIL:
            return {
                ...state,
                loadingHasGiataAccount: false,
            };
        case REFRESH_GIATA_DATA_LISTING:
            return {
                ...state,
                loadingRefreshData: true,
            };
        case REFRESH_GIATA_DATA_SUCCESS:
            return {
                ...state,
                loadingRefreshData: false,
            };
        case REFRESH_GIATA_DATA_FAIL:
            return {
                ...state,
                loadingRefreshData: false,
            };
        case GET_CUPID_MODELS_LISTING:
            return {
                ...state,
                loadingCupidModelsList: true,
            };
        case GET_CUPID_MODELS_SUCCESS:
            return {
                ...state,
                cupidModelsList: action.payload,
                loadingCupidModelsList: false,
            };
        case GET_CUPID_MODELS_FAIL:
            return {
                ...state,
                loadingCupidModelsList: false,
            };

        default:
            return state;
    }
}
