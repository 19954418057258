/* eslint-disable import/no-anonymous-default-export */
import {
    GET_CATALOGS_SUCCESS,
    GET_CATALOGS_FAIL,
    GET_CATALOGS_LISTING,
    GET_PROGRESS_CATALOG_SUCCESS,
    GET_PROGRESS_CATALOG_FAIL,
    GET_PROGRESS_CATALOG_LISTING,
    GET_CATALOG_SUCCESS,
    GET_CATALOG_FAIL,
    GET_CATALOG_LISTING,
    SHOW_WELCOME_DIALOG,
    HIDE_WELCOME_DIALOG,
    OPEN_DIALOG_ADD_NEW_CATALOG,
    HIDE_DIALOG_ADD_NEW_CATALOG,
    GET_CATALOG_DUPLICATE_LISTING,
    GET_CATALOG_DUPLICATE_SUCCESS,
    GET_CATALOG_DUPLICATE_FAIL,
    GET_CONNECTOR_INVENTORY_CREDENTIALS_SUCCESS,
    GET_CONNECTOR_INVENTORY_CREDENTIALS_FAIL,
    GET_CONNECTOR_INVENTORY_CREDENTIALS_LISTING,
    GET_ACTIVE_INVENTORY_LISTING,
    GET_ACTIVE_INVENTORY_SUCCESS,
    GET_ACTIVE_INVENTORY_FAIL,
} from "../types";

const initialState = {
    catalogs: [],
    loading: false,
    loadingCatalog: false,
    catalog: {},
    progress: 0,
    loadingProgress: false,
    showWelcomeDialog: false,
    openDialogCatalog: false,
    duplicateData: {},
    loadingDuplicate: false,
    inventoryConnectorCredentials: {},
    loadingInventoryConnectorCredentails: false,
    activeInventory: {},
    loadingActiveInventory: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_CATALOGS_LISTING:
            return {
                ...state,
                loading: true,
            };
        case GET_CATALOGS_SUCCESS:
            return {
                ...state,
                catalogs: action.payload,
                loading: false,
            };
        case GET_CATALOGS_FAIL:
            return {
                ...state,
                loading: false,
            };
        case GET_PROGRESS_CATALOG_LISTING:
            return {
                ...state,
                loadingProgress: true,
            };
        case GET_PROGRESS_CATALOG_SUCCESS:
            return {
                ...state,
                progress: action.payload,
                loadingProgress: false,
            };
        case GET_PROGRESS_CATALOG_FAIL:
            return {
                ...state,
                loadingProgress: false,
            };
        case GET_CATALOG_LISTING:
            return {
                ...state,
                loadingCatalog: true,
            };
        case GET_CATALOG_SUCCESS:
            return {
                ...state,
                catalog: action.payload,
                loadingCatalog: false,
            };
        case GET_ACTIVE_INVENTORY_LISTING:
            return {
                ...state,
                loadingActiveInventory: true,
            };
        case GET_ACTIVE_INVENTORY_SUCCESS:
            return {
                ...state,
                activeInventory: action.payload,
                loadingActiveInventory: false,
            };
        case GET_ACTIVE_INVENTORY_FAIL:
            return {
                ...state,
                activeInventory: action.payload,
                loadingActiveInventory: false,
            };
        case SHOW_WELCOME_DIALOG:
            return {
                ...state,
                showWelcomeDialog: true,
            };
        case HIDE_WELCOME_DIALOG:
            return {
                ...state,
                showWelcomeDialog: false,
            };
        case OPEN_DIALOG_ADD_NEW_CATALOG:
            return {
                ...state,
                openDialogCatalog: true,
            };
        case HIDE_DIALOG_ADD_NEW_CATALOG:
            return {
                ...state,
                openDialogCatalog: false,
            };
        case GET_CATALOG_FAIL:
            return {
                ...state,
                loadingCatalog: false,
            };
        case GET_CONNECTOR_INVENTORY_CREDENTIALS_LISTING:
            return {
                ...state,
                loadingInventoryConnectorCredentails: true,
            };
        case GET_CONNECTOR_INVENTORY_CREDENTIALS_SUCCESS:
            return {
                ...state,
                loadingInventoryConnectorCredentails: false,
                inventoryConnectorCredentials: action.payload,
            };
        case GET_CONNECTOR_INVENTORY_CREDENTIALS_FAIL:
            return {
                ...state,
                loadingInventoryConnectorCredentails: false,
            };
        case GET_CATALOG_DUPLICATE_LISTING:
            return {
                ...state,
                loadingDuplicate: true,
                duplicateData: {},
            };
        case GET_CATALOG_DUPLICATE_SUCCESS:
            return {
                ...state,
                duplicateData: action.payload,
                duplicateCount: action.count,
                totalDuplicates: action.totalDuplicates,
                loadingDuplicate: false,
            };
        case GET_CATALOG_DUPLICATE_FAIL:
            return {
                ...state,
                loadingDuplicate: false,
                duplicateData: {},
            };
        default:
            return state;
    }
}
