import axios from "axios";
const url = process.env.REACT_APP_BASE_URL;

const DestinationService = {
    deleteConfiguration: async (id) => {
        const result = await axios
            .delete(url + `/destinations/credentials/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                return {
                    data: res.data,
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: error.response.data.msg,
                    status: error.response.data.code,
                };
            });
        return result;
    },
    addDestination: async (id, credentials) => {
        const formData = new FormData();
        formData.append("credentials", JSON.stringify(credentials));
        const result = await axios
            .put(url + `/destinations/credentials/add/${id}`, formData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                return {
                    data: res.data,
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: error.response.data.msg,
                    status: error.response.data.code,
                };
            });
        return result;
    },
    updateDestination: async (id, credentials) => {
        const formData = new FormData();
        formData.append("credentials", JSON.stringify(credentials));
        const result = await axios
            .patch(url + `/destinations/credentials/${id}`, formData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                return {
                    data: res.data,
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: error.response.data.msg,
                    status: error.response.data.code,
                };
            });
        return result;
    },
    testConnection: async (id, credentials) => {
        const formData = new FormData();
        formData.append("credentials", JSON.stringify(credentials));
        const result = await axios
            .put(url + `/destinations/credentials/test/${id}`, formData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                return {
                    data: res.data,
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: error.response.data.msg,
                    status: error.response.data.code,
                };
            });
        return result;
    },
};

export default DestinationService;
