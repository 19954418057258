import axios from "axios";
const url = process.env.REACT_APP_BASE_URL;

const InvalideHotelsService = {
    getInvalideHotels: async (id,offsetPage,limitPage,keyword,CountrySelected,reason) => {
        let countrySearch = ""
        CountrySelected && Array.isArray(CountrySelected) && CountrySelected.map((item,index)=>{
            if(index == 0)
                countrySearch += `&country=${item.value}`
            else 
                countrySearch += `,${item.value}`
        })

        let params= `${id}/${offsetPage}/${limitPage}?keyword=${keyword}${countrySearch}&reason=${reason}`
        const result = await axios
            .get(`${url}/catalogs/invalid-hotels/${params}`, {
                headers: {Authorization: `Bearer ${localStorage.getItem("token")}`},
            })
            .then((res) => {
                if(res.data){
                    return {
                        data: res.data.data,
                        status: res.data.code,
                    };
                }
            })
            .catch((error) => {
                return {
                    message: error.response.data.message,
                    status: error.response.data.code,
                };
            });
        return result;
    },
};
export default InvalideHotelsService;
