import {
  GET_COMPANY_DATA,
  GET_COMPANY_DATA_SUCCESS,
  GET_COMPANY_DATA_FAIL,
  UPDATE_COMPANY_DATA,
  UPDATE_COMPANY_DATA_SUCCESS,
  UPDATE_COMPANY_DATA_FAIL,
} from "../types";
import CompanyService from "../../services/CompanyService";

export const getCompanyData = () => async (dispatch) => {
  dispatch({
    type: GET_COMPANY_DATA,
  });
  try {
    const res = await CompanyService.get();
    dispatch({
      type: GET_COMPANY_DATA_SUCCESS,
      payload: res.data.company,
    });
  } catch (e) {
    dispatch({
      type: GET_COMPANY_DATA_FAIL,
    });
  }
};

export const updateCompanyData = (data) => async (dispatch) => {
  dispatch({
    type: UPDATE_COMPANY_DATA,
    payload: data,
  });
  try {
    const res = await CompanyService.update(data);
    dispatch({
      type: UPDATE_COMPANY_DATA_SUCCESS,
    });
  } catch (e) {
    dispatch({
      type: UPDATE_COMPANY_DATA_FAIL,
    });
  }
};
