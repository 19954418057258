import axios from "axios";
const PATH_URL = process.env.REACT_APP_BASE_URL;

var CancelToken = axios.CancelToken;
var cancel;
const ManualMappingService = {
    autocompleteSearch: async (mapping_id, filter, serachValue,cancelToken,token) => {
        if (cancel !== undefined) {
            cancel();
        }
        const result = await axios
            .get(
                !token ? `${PATH_URL}/manual-mapping/${mapping_id}/filters/${filter}` : `${PATH_URL.replace("dashboard","")}external-manual-mapping/filters/${filter}?token=${token}`
                ,{
                    cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancel = c;
                }),
                params: {
                    search: serachValue,
                },
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                return {
                    data: res.data?.data,
                    status: 200,
                };
            })
            .catch((error) => {
                console.log("error", error);
                return {
                    message: error?.response?.data?.message,
                    status: error?.response?.data?.code,
                };
            });
        return result;
    },
    mapCondidate: async (mapping_id, data,token) => {
        const result = await axios
            .post(
                !token ? `${PATH_URL}/manual-mapping/${mapping_id}/map` : `${PATH_URL.replace("dashboard","")}external-manual-mapping/map?token=${token}`
                //  + `/manual-mapping/${mapping_id}/map`
                , data
                , {
                    headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                return {
                    data: res.data?.data,
                    status: 200,
                };
            })
            .catch((error) => {
                console.log("error", error);
                return {
                    message: error?.response?.data?.message,
                    status: error?.response?.data?.code,
                };
            });
        return result;
    },
};
export default ManualMappingService;
