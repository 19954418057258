import React from "react";
import Lottie from "react-lottie";
import animationData from "../lotties/info.json";

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};
function MatchingFieldsInfo(props) {
    return (
        <div
            className="alert custom-alert d-flex align-items-start justify-content-start"
            style={{
                color: "rgb(8 7 7)",
                border: "3px solid #6076e8b0",
                borderRadius: "5px",
                marginBottom: "30px",
                backgroundColor: "rgb(96 118 232 / 0%)",
            }}
        >
            <i className="mr-1">
                <Lottie options={defaultOptions} height={70} width={70} />
            </i>
            <div>
                <h4
                    style={{
                        color: "#5f76e8",
                        fontWeight: "bold",
                    }}
                >
                    Instructions :
                </h4>
                <p
                    style={{
                        marginTop: "0px",
                        marginbottom: "0px",
                        fontSize: "18px",
                        fontWeight: "600",
                        color: "#11101075",
                        marginBottom: "0px",
                    }}
                >
                    {" "}
                    Match your file's columns names with the fields below.
                </p>
            </div>
        </div>
    );
}
export default MatchingFieldsInfo;
