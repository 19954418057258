import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Header from "../Partner-Report/Header";
import { useHistory } from 'react-router-dom';

import Accordion from 'react-bootstrap/Accordion';
import { Card } from "react-bootstrap";



const ArrowDown =()=>{
  return(
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
    </svg>
  )
}
const ArrowUp =()=>{
  return(
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
    <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
  </svg>
  )
}

const GetStarted = () => {
  const [activeKey, setActiveKey] = useState(null);
  const toggleAccordion = (eventKey) => {
    setActiveKey(activeKey === eventKey ? null : eventKey);
  };
    const [currentTab, setCurrentTab] = useState("Getting started");



  return (
    <>
      <Helmet>
        <title>Getting started</title>
      </Helmet>

      <Header
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        isAffiliate={false}
      />
      <div
        style={{
          display: "block",
          marginLeft: "0px",
          padding:"0px"

        }}
      >
        <div className="page-breadcrumb" style={{maxWidth:"1200px",margin:"0px auto",display:"flex",flexDirection:"column",gap:"40px"}}>
              <div style={{display:"flex",gap:"16px",flexDirection:"column"}}>
                            <h3 style={{fontSize:"30px" ,fontWeight:"bold"}}> Welcome to Cupid!</h3>
                              <p className="page-title  text-dark font-weight-normal mb-1"  style={{fontWeight:"normal",fontSize:"17px"}}>  Here, we’ll guide you through the key features and functionalities available on Cupid, starting with an introduction to the mapping performance of your inventory with Nuitee and the additional products offered by Cupid.</p>
                              <h3  style={{fontWeight:"bold",fontSize:"17px"}}>Interested in Cupid ? </h3>
                              <button
                                        className="btn btn-primary"

                                        style={{
                                            borderRadius: "9px",
                                            width:"fit-content"
                                        }}
                                        onClick={() => {
                                           window.open('https://calendly.com/mani-cru/cupid-mapping-product-intro?month=2024-08', '_blank');
                                        }}
                                    >
                                        Schedule a Demo
                              </button>
                </div>              
                <div>     
              </div>
            <Accordion >
              <Card>
                <Card.Header  onClick={() => toggleAccordion('0')}>
                  <Accordion.Toggle  variant="link" eventKey="0" style={{width:"100%",display:"flex",flexDirection:"row",justifyContent:"space-between",border:"none",background:"none",outline:"none"}}>
                    <h2 className="page-title  text-dark mb-1" style={{fontWeight:"bold",fontSize:"24px"}}>Mapping Report</h2>
                    {activeKey === '0' ? <ArrowUp /> : <ArrowDown />}
                   </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <div className="" style={{display:"flex",flexDirection:"column",gap:"6px",padding:"20px"}}>
                    <p style={{fontWeight:"normal",fontSize:"17px"}}>This tab is your go-to resource for understanding the mapping details between Nuitee and your inventory. It includes comprehensive data on:</p>
                    <ul style={{display:"flex",flexDirection:"column",gap:"8px"}}>
                      <li  style={{fontWeight:"normal",fontSize:"17px"}}><span style={{fontWeight:"bold",}}>Overall Coverage:</span> Get a snapshot of the overall mapping coverage between Nuitee and your suppliers. This section provides insights into how well your inventory is represented across different platforms.</li>
                      <li  style={{fontWeight:"normal",fontSize:"17px"}}><span style={{fontWeight:"bold",}}>Mapping by Country: </span>See how your properties are distributed across various countries, helping you identify key markets and potential gaps.</li>
                      <li  style={{fontWeight:"normal",fontSize:"17px"}}><span style={{fontWeight:"bold",}}>Mapping by City:</span> Dive deeper into city-level mapping details to understand which urban areas have the strongest representation.</li>
                      <li  style={{fontWeight:"normal",fontSize:"17px"}}><span style={{fontWeight:"bold",}}>Chain and Property Type Mapping:</span>f your data includes specific hotel chains or property types, this section will show how these are mapped, ensuring consistency and coverage.</li>
                    </ul>
                    <p style={{fontWeight:"normal",fontSize:"17px"}}>To help you navigate this section, we've included a video tutorial that demonstrates how to access and interpret the mapping details.</p>
                    <iframe
                      style={{width:"800px",height:"600px",borderRadius:"16px"}}
                      src="https://get-started-video-dashboard.s3.us-west-2.amazonaws.com/Cupid-mapping-report.mp4"
                    ></iframe>
                  </div>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header  onClick={() => toggleAccordion('1')}>
                  <Accordion.Toggle  variant="link" eventKey="1" style={{width:"100%",display:"flex",flexDirection:"row",justifyContent:"space-between",border:"none",background:"none",outline:"none"}}>
                   <h2 className="page-title  text-dark mb-1" style={{fontWeight:"bold",fontSize:"24px"}}>Manual Mapping</h2>
                  {activeKey === '1' ? <ArrowUp /> : <ArrowDown />}
                   </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <div className="" style={{display:"flex",flexDirection:"column",gap:"6px",padding:"20px"}}>
                    <p style={{fontWeight:"normal",fontSize:"17px"}}>The second tab is designed to help you manually map properties to increase your overall coverage. In this section:</p>
                    <ul  style={{display:"flex",flexDirection:"column",gap:"8px"}}>
                      <li  style={{fontWeight:"normal",fontSize:"17px"}}><span style={{fontWeight:"bold",}}>Unmapped Properties:</span>We display a list of properties that Cupid could not automatically map, allowing you to identify potential gaps in your inventory’s coverage..</li>
                      <li  style={{fontWeight:"normal",fontSize:"17px"}}><span style={{fontWeight:"bold",}}>Potential Matches: </span>For each unmapped property, we suggest potential matches from Nuitee’s inventory, based on location, name, and other relevant data points.</li>
                      <li  style={{fontWeight:"normal",fontSize:"17px"}}><span style={{fontWeight:"bold",}}>Review and Map:</span>Using an intuitive interface, complete with a map view, you can easily review the suggested matches and manually map them if they seem accurate. This tool is particularly useful for fine-tuning your mappings and ensuring that all relevant properties are included in your listings.</li>
                    </ul>
                    <p style={{fontWeight:"normal",fontSize:"17px"}}>To assist you with this process, a video tutorial is provided below. The video will guide you through the steps involved in performing a manual mapping, ensuring that you can effectively use this tool to enhance your property coverage..</p>
                  <iframe
                      style={{width:"800px",height:"600px",borderRadius:"16px"}}
                      src="https://get-started-video-dashboard.s3.us-west-2.amazonaws.com/Cupid-manual-mapping_2.mp4"
                    ></iframe>
                    <p style={{fontWeight:"normal",fontSize:"17px"}}>In addition to the core mapping functionalities, Cupid offers two powerful products designed to enhance your business operations:</p>

                  </div>
                </Accordion.Collapse>
              </Card>
               <Card>
                <Card.Header  onClick={() => toggleAccordion('2')}>
                  <Accordion.Toggle  variant="link" eventKey="2" style={{width:"100%",display:"flex",flexDirection:"row",justifyContent:"space-between",border:"none",background:"none",outline:"none"}}>
                <h2 className="page-title  text-dark mb-1" style={{fontWeight:"bold",fontSize:"24px"}}>Room Mapping API's</h2>
                {activeKey === '2' ? <ArrowUp /> : <ArrowDown />}
                   </Accordion.Toggle>

                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <div className="" style={{display:"flex",flexDirection:"column",gap:"6px",padding:"20px"}}>
                    <p style={{fontWeight:"normal",fontSize:"17px"}}>Cupid's Room Mapping API is engineered using advanced Natural Language Processing (NLP) and custom-tuned language models to maximize the overall coverage of room mapping while maintaining a very low error rate. This API suite includes three distinct APIs to meet any room mapping need:</p>
                    <ul  style={{display:"flex",flexDirection:"column",gap:"8px"}}>
                      <li  style={{fontWeight:"normal",fontSize:"17px"}}><span style={{fontWeight:"bold",}}>Room Map:</span>Provides aggregation-based mapping to combine multiple room listings from different suppliers under a common group code and group name.</li>
                      <li  style={{fontWeight:"normal",fontSize:"17px"}}><span style={{fontWeight:"bold",}}>Room Match: </span>Matches rooms to a pre-defined reference room list, ensuring consistency across platforms.</li>
                      <li  style={{fontWeight:"normal",fontSize:"17px"}}><span style={{fontWeight:"bold",}}>Reference Room Match:</span> Matches rooms to Nuitee's hotel content rooms, utilizing our extensive property database.</li>
                    </ul>
                    <p style={{fontWeight:"normal",fontSize:"17px"}}>These APIs are swift, with response times of less than 20ms, ensuring seamless integration and user experience.</p>
                    <ul  style={{display:"flex",flexDirection:"column",gap:"8px"}}>
                      <li  style={{fontWeight:"normal",fontSize:"17px"}}><span style={{fontWeight:"bold",}}>Typical Coverage:</span>Achieves approximately 70% aggregation of rooms, offering broad coverage across different property types.</li>
                    </ul>
                      <p style={{fontWeight:"normal",fontSize:"17px"}}>Here is a short video of the room_map API with a sample request and response.</p>
                  <iframe
                      style={{width:"800px",height:"600px",borderRadius:"16px"}}
                      src="https://get-started-video-dashboard.s3.us-west-2.amazonaws.com/Cupid-room-map.mp4"
                    ></iframe>
                  </div>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header  onClick={() => toggleAccordion('3')}>
                  <Accordion.Toggle  variant="link" eventKey="3" style={{width:"100%",display:"flex",flexDirection:"row",justifyContent:"space-between",border:"none",background:"none",outline:"none"}}>
                      <h2 className="page-title  text-dark mb-1" style={{fontWeight:"bold",fontSize:"24px"}}>Hotel Content</h2>
                      {activeKey === '3' ? <ArrowUp /> : <ArrowDown />}
                   </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                  <div className="" style={{display:"flex",flexDirection:"column",gap:"6px",padding:"20px"}}>
                    <p style={{fontWeight:"normal",fontSize:"17px"}}>Cupid’s Hotel Content product offers enhanced and standardized content for over 2.7 million properties, both at the property and room level. Key features include:</p>
                    <ul  style={{display:"flex",flexDirection:"column",gap:"8px"}}>
                      <li  style={{fontWeight:"normal",fontSize:"17px"}}><span style={{fontWeight:"bold",}}>Image Enhancements:</span>: All images are aesthetically enhanced, classified, and sorted to elevate the user experience and increase customer satisfaction.</li>
                      <li  style={{fontWeight:"normal",fontSize:"17px"}}><span style={{fontWeight:"bold",}}>Operational Efficiency: </span>Leverage the synergy of mapping and instant access to content, including property reviews, all within one platform.</li>
                      <li  style={{fontWeight:"normal",fontSize:"17px"}}><span style={{fontWeight:"bold",}}>Integrated Content and Mapping:</span> Matches rooms to Nuitee's hotel content rooms, utilizing our extensive property database.</li>
                      <li  style={{fontWeight:"normal",fontSize:"17px"}}><span style={{fontWeight:"bold",}}>Multilingual Support:</span>Content is available in multiple languages, including French, Spanish, Italian, and German, with additional languages coming soon.</li>
                    </ul>
                    <p style={{fontWeight:"normal",fontSize:"17px"}}>Here is a short video showing the value proposition of Cupid’s content..</p>
                  <iframe
                      style={{width:"800px",height:"600px",borderRadius:"16px"}}
                      src="https://get-started-video-dashboard.s3.us-west-2.amazonaws.com/cupid-hotel-content.mp4"
                    ></iframe>
                    <p style={{fontWeight:"normal",fontSize:"17px"}}>Should you require any additional support or if you're interested in exploring any of Cupid's solutions further, please reach out to your contact at Nuitee. Alternatively, you can schedule a call for a demo with the Cupid team using the link below:</p>
                    <button
                                            className="btn btn-primary"

                                            style={{
                                                borderRadius: "9px",
                                                width:"fit-content"
                                            }}
                                            onClick={() => {
                                           window.open('https://calendly.com/mani-cru/cupid-mapping-product-intro?month=2024-08', '_blank');
                                            }}
                                        >
                                            Schedule a Demo
                    </button>
                    <p style={{fontWeight:"normal",fontSize:"17px"}}>We're here to help you make the most of Cupid's powerful tools and ensure a seamless experience.</p>
                  </div>
                </Accordion.Collapse>
              </Card>
            </Accordion>
        </div>
    </div>
    </>
  );
};
export default GetStarted;
