import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
function NavLink(props) {
    const isActive = props.pathname === props.to || props.isactive;
    const className = isActive ? "sidebar-item selected" : "sidebar-item";
    return (
        <li className={className}>
            <Link {...props}>{props.children}</Link>
        </li>
    );
}

NavLink.contextTypes = {
    router: PropTypes.object,
};

export default NavLink;
