import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { partnerGrouppdListByAction } from "../../redux/actions/PartnerActions";
import PartnerCatalogService from "../../services/PartnerCatalogService";

function ToggleStatus({ dataCell, partner }) {
    const [status, setStatus] = useState(dataCell?.parent?.active);
    const [openDialog, setOpenDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const handleChangeStatus = async () => {
        setOpenDialog(true);
    };
    const handleSubmit = async () => {
        setLoading(true);
        let res = await PartnerCatalogService.ChangeStatus({
            catalog_id: dataCell?.parent?.id,
            partner_id: partner.id,
            status: !dataCell?.parent?.active === true ? "active" : "inactive",
        });

        if (res.status === 200) {
            await dispatch(
                partnerGrouppdListByAction(
                    partner?.id,
                    partner?.affiliate_id ?? 0
                )
            );

            setStatus(!status);
            setLoading(false);
        } else {
            toast.error(res.message);
            setLoading(false);
        }
    };
    return (
        <>
            <div className="custom-control custom-switch">
                <input
                    type="checkbox"
                    className="custom-control-input"
                    id={dataCell?.parent?.id}
                    checked={dataCell?.parent?.active}
                    onChange={handleChangeStatus}
                    readOnly
                />
                <label
                    className="custom-control-label"
                    htmlFor={dataCell?.parent?.id}
                ></label>
            </div>
            {openDialog && (
                <ConfirmationDialog
                    show={openDialog}
                    onClose={() => setOpenDialog(false)}
                    handleSubmit={handleSubmit}
                    status={status}
                    pending={loading}
                />
            )}
        </>
    );
}
export default ToggleStatus;

function ConfirmationDialog(props) {
    const { show, onClose, handleSubmit, status, pending } = props;
    return (
        <Modal
            show={show}
            onHide={onClose}
            dialogClassName="modal-40w modal-dialog modal-dialog-centered"
        >
            <Modal.Body style={{ textAlign: "center" }}>
                <h2
                    style={{
                        color: "#ff4f70",
                        fontWeight: "500",
                        fontSize: "30px",
                    }}
                >
                    {status ? `Warning!` : `Action required!`}
                </h2>
                <p style={{ fontWeight: "400", fontSize: "18px" }}>
                    {status
                        ? `Are you sure you want to deactivate this catalog.`
                        : `Are you sure you want to activate this catalog.`}
                </p>
                <p style={{ fontWeight: "400", fontSize: "18px" }}>
                    Would you like to proceed ?{" "}
                </p>

                <button
                    className="btn waves-effect waves-light btn-rounded btn-warning"
                    style={{ margin: "6px" }}
                    onClick={() => {
                        onClose();
                    }}
                >
                    Cancel
                </button>
                <button
                    className="btn waves-effect waves-light btn-rounded btn-success"
                    style={{ margin: "6px" }}
                    onClick={async () => {
                        await handleSubmit();
                        onClose();
                    }}
                    disabled={pending}
                >
                    Confirm
                    {pending && (
                        <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    )}
                </button>
            </Modal.Body>
        </Modal>
    );
}
