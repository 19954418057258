import React, { useCallback, useMemo, useState, useEffect } from "react";
import {
    Modal,
    OverlayTrigger,
    Tooltip,
    Spinner,
    ProgressBar,
} from "react-bootstrap";
import { hasPermission } from "../../utilis/functions";
import { MANAGE_HOTELS_RANKING } from "../../utilis/const";
import { useDropzone } from "react-dropzone";
import { separator } from "../../utilis/functions";
import LogoCSV from "../../lotties/csv-logo.png";
import Papa from "papaparse";
import Stepper from "react-stepper-horizontal";
import Select from "../../components/Select";
import Table from "../../components/Table/TablePaginationFromApi";
import { useSelector, useDispatch } from "react-redux";
import { getHotelRanking } from "../../redux/actions/HotelRankingActions";
import HotelRankingService from "../../services/HotelRankingService";
import { toast } from "react-toastify";
import Lottie from "react-lottie";
import animationData from "../../lotties/upload_file.json";

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};
const HotelRankingList = () => {
    const dispatch = useDispatch();
    const [showAddModal, setShowAddModal] = useState();
    const [perPage, setPerPage] = useState(10);
    const [filterText, setFilterText] = useState("");
    const { loadingHotelRanking, hotelRanking, totalHotelRanking } =
        useSelector((state) => ({
            hotelRanking: state.hotelRankingReducer.hotelRanking?.hotels ?? [],
            totalHotelRanking:
                state.hotelRankingReducer.hotelRanking?.total ?? 0,
            loadingHotelRanking: state.hotelRankingReducer.loadingHotelRanking,
        }));
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [column, setColumn] = useState();
    const [sortDirection, setSortDirection] = useState();

    const renderTooltip = (text) => (props) =>
        (
            <Tooltip id="button-tooltip" {...props}>
                {text}
            </Tooltip>
        );

    const columns = [
        {
            id: "hotel_code",
            name: <div className="table-title">Hotel code</div>,
            cell: (e) => {
                return <>{e?.hotel_code}</>;
            },
            sortable: true,
        },
        {
            id: "hotel_name",
            name: <div className="table-title">Hotel Name</div>,
            cell: (e) => {
                return <>{e?.hotel_name}</>;
            },
            sortable: true,
        },
        {
            id: "rank",
            name: <div className="table-title">Rank</div>,
            cell: (e) => {
                return <>{e?.rank}</>;
            },
            sortable: true,
        },
    ];

    useEffect(() => {
        dispatch(getHotelRanking(1, perPage, filterText));
    }, []);

    const handlePageChange = (page) => {
        dispatch(
            getHotelRanking(page, perPage, filterText, column, sortDirection)
        );
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        dispatch(
            getHotelRanking(page, newPerPage, filterText, column, sortDirection)
        );
        setPerPage(newPerPage);
    };

    const handleSort = async (c, sortD) => {
        dispatch(getHotelRanking(1, perPage, filterText, c?.id, sortD));
        setSortDirection(sortD);
        setColumn(c?.id);
    };
    return (
        <>
            <div className="card">
                <div className="card-body">
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "16px",
                        }}
                    >
                        <h4 className="card-title">
                            Manage Rankings
                            <OverlayTrigger
                                placement="top"
                                delay={{
                                    show: 250,
                                    hide: 400,
                                }}
                                overlay={renderTooltip(
                                    " Do you have a list of best selling or top searched hotels or a custom ranking for your inventory? If so, adding them will help us provide more insights after the mapping is performed"
                                )}
                            >
                                <i
                                    className="fas fa-info-circle"
                                    style={{ marginLeft: "4px" }}
                                ></i>
                            </OverlayTrigger>
                        </h4>
                        {hasPermission(MANAGE_HOTELS_RANKING) && (
                            <button
                                className="btn btn-info craeteSegmentBtn"
                                style={{ marginRight: "12px" }}
                                onClick={async () => { setShowAddModal(true)}}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M4 16.2422C2.79401 15.435 2 14.0602 2 12.5C2 10.1564 3.79151 8.23129 6.07974 8.01937C6.54781 5.17213 9.02024 3 12 3C14.9798 3 17.4522 5.17213 17.9203 8.01937C20.2085 8.23129 22 10.1564 22 12.5C22 14.0602 21.206 15.435 20 16.2422M8 16L12 12M12 12L16 16M12 12V21" stroke="#6275E7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                Upload Rankings
                            </button>
                        )}
                    </div>
                    <div className="table-responsive">
                        <Table
                            data={hotelRanking ?? []}
                            columns={columns}
                            totalRows={totalHotelRanking}
                            handlePageChange={handlePageChange}
                            onChangeRowsPerPage={handlePerRowsChange}
                            progressPending={loadingHotelRanking}
                            onFilter={() => {
                                setResetPaginationToggle(true);

                                dispatch(
                                    getHotelRanking(1, perPage, filterText)
                                );
                            }}
                            filterValue={filterText}
                            handleClearFilter={() => {
                                setFilterText("");
                                setResetPaginationToggle(true);
                                dispatch(getHotelRanking(1, perPage, ""));
                            }}
                            onChange={(e) => {
                                setFilterText(e.target.value);
                                if (e.target.value === "") {
                                    setResetPaginationToggle(true);
                                    dispatch(getHotelRanking(1, perPage, ""));
                                }
                            }}
                            filter={true}
                            resetPaginationToggle={resetPaginationToggle}
                            onSort={handleSort}
                            sortServer={true}
                        />
                    </div>
                </div>
            </div>

            {showAddModal && (
                <AddHotelRankingCsv
                    open={showAddModal}
                    handleClose={() => {
                        setShowAddModal(false);
                    }}
                    perPage={perPage}
                />
            )}
        </>
    );
};

export default HotelRankingList;

const AddHotelRankingCsv = (props) => {
    const { open, handleClose, perPage } = props;
    const dispatch = useDispatch();
    const [files, setFiles] = useState([]);
    const [selectedValues, setSelectedValues] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [firstLine, setFirstLine] = useState();
    const [loading, setLoading] = useState(false);
    const [hideBackButton, setHideBackButton] = useState(false);
    const [progress, setProgress] = useState();

    const sections = [
        {
            title: "Upload catalog",
        },
        {
            title: "Field matching",
        },
    ];

    async function handleStartProcessing(e) {
        const values = Object.values(selectedValues)
            ?.filter((e) => e !== null)
            ?.map((e) => e.value);
        setLoading(true);
        if (new Set(values).size !== values.length) {
            setLoading(false);
            toast.warn("List contains duplicate values", {
                toastId: "list-contains-duplicate-values",
            });
        } else if (
            selectedValues.header_id?.value === undefined ||
            selectedValues.header_rank?.value === undefined
        ) {
            setLoading(false);
            toast.error("Please complete all required fields", {
                toastId: "catalog-required-fields",
            });
        } else {
            setHideBackButton(true);
            const formData = new FormData();
            formData.append("file", files?.[0]);
            formData.append("header_id", selectedValues.header_id?.value);
            formData.append("header_rank", selectedValues.header_rank?.value);
            const option = {
                onUploadProgress: (ProgressEvent) => {
                    const { loaded, total } = ProgressEvent;
                    let percent = Math.floor((loaded * 100) / total);
                    setProgress(percent);
                },
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };

            let res = await HotelRankingService.uploadCatalog(formData, option);
            if (res.status === 200) {
                setLoading(false);
                await dispatch(getHotelRanking(1, perPage, ""));
                toast.success("Created!");
                handleClose();
            } else {
                setLoading(false);
                toast.error(res.message);
            }
        }
        setHideBackButton(false);
    }

    const renderSections = () => {
        switch (currentPage) {
            case 1:
                return (
                    <>
                        <span
                            className="sub-title-info "
                            style={{
                                marginBottom: "10px",
                            }}
                        >
                            Upload a CSV file containing your high ranked
                            properties.Make sure your file contains the
                            following columns :
                        </span>
                        <div
                            style={{
                                marginBottom: "10px",
                                textAlign: "center",
                            }}
                        >
                            <div className="requiredFileProperties">
                                <div className="statsRow">
                                    <div className="col-6 d-flex flex-column">
                                        <span className="title">
                                            Hotel ID
                                            <span
                                                className="text-danger"
                                                style={{ marginLeft: "4px" }}
                                            >
                                                *
                                            </span>
                                        </span>
                                    </div>
                                    <div className="col-6 d-flex flex-column bordered">
                                        <span className="title">
                                            Hotel Ranking
                                            <span
                                                className="text-danger"
                                                style={{ marginLeft: "4px" }}
                                            >
                                                *
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <DropzonePreview
                            files={files}
                            setFiles={setFiles}
                            setFirstLine={setFirstLine}
                            setData={setData}
                        />
                    </>
                );
            case 2:
                return (
                    <MatchStep
                        selectedValues={selectedValues}
                        setSelectedValues={setSelectedValues}
                        data={data}
                        firstLine={firstLine}
                        progress={progress}
                        loading={loading}
                    />
                );
            default:
                return <></>;
        }
    };

    const renderFooter = () => {
        switch (currentPage) {
            case 1:
                return (
                    <>
                        <button
                            type="button"
                            className="btn btn-light"
                            onClick={handleClose}
                        >
                            Close
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={async () => {
                                if (currentPage === 1 && files?.length <= 0) {
                                    toast.error(
                                        "Upload your csv file to pass next step",
                                        {
                                            toastId: "hotel-ranking-csv-step2",
                                        }
                                    );
                                } else {
                                    setCurrentPage((cur) => cur + 1);
                                }
                            }}
                        >
                            Next &nbsp;&nbsp;
                            <i className="fas fa-caret-right"></i>
                        </button>
                    </>
                );
            case 2:
                return (
                    <>
                        <div className="modal-footer">
                            {!hideBackButton && (
                                <>
                                    <button
                                        type="button"
                                        className="btn btn-warning"
                                        onClick={async () => {
                                            setCurrentPage((cur) => cur - 1);
                                        }}
                                    >
                                        <i className="fas fa-caret-left"></i>
                                        &nbsp;&nbsp; Back
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-light"
                                        onClick={handleClose}
                                    >
                                        Close
                                    </button>
                                </>
                            )}
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={handleStartProcessing}
                                disabled={loading}
                            >
                                <i className="fas fa-upload"></i>
                                &nbsp;&nbsp; Start processing
                                {loading && (
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                )}
                            </button>
                        </div>
                    </>
                );
            default:
                return <></>;
        }
    };

    return (
        <Modal
            show={open}
            onHide={handleClose}
            dialogClassName={"modal-50w modal-dialog modal-dialog-centered"}
        >
            <Modal.Header
                style={{
                    color: "#212529",
                    fontSize: "18px",
                    fontWeight: "700",
                    left: "15px",
                    top: "15px",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                Hotels Ranking
            </Modal.Header>
            <Modal.Body>
                <Modal.Body style={{ paddingBottom: "0px" }}>
                    <div className="card-header p-0 pb-2">
                        <div className="stepper">
                            <Stepper
                                steps={sections}
                                activeStep={currentPage}
                                activeColor="#D3D7DB"
                                defaultBarColor="#D3D7DB"
                                completeColor="#6076E8"
                                completeBarColor="#6076E8"
                                fontFamily="Roboto"
                                textColor="#fff"
                                defaultBorderStyle="solid 6px"
                                defaultBorderWidth={10}
                            />
                        </div>
                    </div>
                    <div style={{ paddingTop: "10px" }}>{renderSections()}</div>
                </Modal.Body>
            </Modal.Body>
            <Modal.Footer>{renderFooter()}</Modal.Footer>
        </Modal>
    );
};

const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 6,
    borderRadius: 5,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
};

const focusedStyle = {
    borderColor: "#7f88ba",
};

const acceptStyle = {
    borderColor: "#7f88ba",
};

const rejectStyle = {
    borderColor: "#ff1744",
};

function DropzonePreview({ files, setFiles, setData, setFirstLine }) {
    const [count, setCount] = useState();

    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
        open,
    } = useDropzone({
        acceptedFiles:
            ".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values",
        onDrop: useCallback(async (acceptedFiles) => {
            var reader = new FileReader();
            reader.onload = function (e) {
                const text = e.target.result;
                let headers = [];
                let firstLine = [];
                Papa.parse(acceptedFiles[0], {
                    skipEmptyLines: true,
                    preview: 1,
                    header: true,
                    complete: (result) => {
                        headers = Object.keys(result.data?.[0]);
                        firstLine = Object.values(result?.data?.[0]);
                        setFiles(
                            acceptedFiles.map((file) => {
                                return Object.assign(file, {
                                    preview: URL.createObjectURL(file),
                                });
                            })
                        );
                        setData(
                            headers?.map((e, i) => ({
                                index: i,
                                name: e,
                            }))
                        );

                        setFirstLine(
                            firstLine?.map((e, i) => ({
                                index: i,
                                name: e,
                            }))
                        );
                    },
                });
                var lineCount = text.split("\n").length;
                setCount(lineCount - 1);
            };
            reader.readAsText(acceptedFiles[0]);
        }),
    });
    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isFocused, isDragAccept, isDragReject]
    );
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Remove
        </Tooltip>
    );
    const fileList = (
        <>
            {files.map((file) => (
                <div className="text-center ">
                    <img
                        src={LogoCSV}
                        alt="CSV"
                        style={{ width: "64px", marginBottom: "20px" }}
                    />
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip}
                    >
                        <button
                            className="btn"
                            onClick={(e) => {
                                setFiles([]);
                            }}
                            style={{
                                paddingLeft: "0px",
                            }}
                        >
                            <i
                                className="far fa-times-circle"
                                style={{
                                    color: "#776b6b",
                                    fontSize: "17px",
                                    marginBottom: "60px",
                                }}
                            ></i>
                        </button>
                    </OverlayTrigger>
                    <h3 style={{ fontWeight: "500" }}>
                        <i
                            className="fas fa-check"
                            style={{ color: "green", padding: "7px" }}
                        ></i>
                        {file.path}
                    </h3>
                    <h5
                        style={{
                            fontWeight: "800",
                            color: "rgb(74 76 80 / 74%)",
                        }}
                    >
                        {count && `${separator(count)} properties`}
                    </h5>
                    <h5
                        style={{
                            fontWeight: "800",
                            color: "rgb(74 76 80 / 74%)",
                        }}
                    >
                        {file?.size &&
                            (file?.size / (1024 * 1024)).toFixed(2) + "MB"}
                    </h5>
                </div>
            ))}
        </>
    );

    let inputProps = getInputProps();
    inputProps.name = "files";
    return (
        <>
            {files?.length == 0 && (
                <section>
                    <div className="container" style={{ padding: "0px" }}>
                        <div
                            {...getRootProps({ style })}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <input {...getInputProps()} />
                            <p>
                                Drag and drop file here or click to select files
                            </p>
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={(e) => {
                                    e?.preventDefault();
                                    open();
                                }}
                            >
                                Open File Dialog
                            </button>
                        </div>
                    </div>
                </section>
            )}

            {fileList}
        </>
    );
}

const MatchStep = (props) => {
    const {
        selectedValues,
        setSelectedValues,
        data,
        firstLine,
        progress,
        loading,
    } = props;
    return (
        <>
            {loading ? (
                <div>
                    <Lottie options={defaultOptions} height={200} width={200} />
                    <div
                        style={{
                            textAlign: "center",
                            paddingBottom: "15px",
                        }}
                    >
                        <span
                            style={{
                                fontSize: "18px",
                                fontWeight: "600",
                            }}
                        >
                            Uploading data to the server. Please do not refresh
                            this page.
                        </span>
                    </div>
                </div>
            ) : (
                <form className="row">
                    <div className="col-6">
                        <div className="form-group">
                            <label className="mr-sm-2" htmlFor="csv_hotel_code">
                                Hotel ID
                                <span className="text-danger"> * </span>
                            </label>
                            <Select
                                options={data?.map((e) => ({
                                    value: e?.index,
                                    label: e?.name,
                                }))}
                                name="header_id"
                                isClearable={true}
                                value={selectedValues.header_id}
                                styles={{
                                    control: (base) => ({
                                        ...base,
                                        borderColor:
                                            selectedValues.header_id !=
                                                undefined ||
                                            selectedValues.header_id != null
                                                ? "#89c995fc"
                                                : "#ff00009e",
                                        "&:hover": {
                                            borderColor:
                                                selectedValues.header_id !=
                                                    undefined ||
                                                selectedValues.header_id != null
                                                    ? "#89c995fc"
                                                    : "#ff00009e",
                                        },
                                    }),
                                }}
                                onChange={(e) =>
                                    setSelectedValues({
                                        ...selectedValues,
                                        header_id: e,
                                    })
                                }
                            />
                            <span className="preview-column">
                                {firstLine?.find(
                                    (e) =>
                                        e?.index ===
                                        selectedValues.header_id?.value
                                ) &&
                                    `Preview : ${
                                        firstLine?.find(
                                            (e) =>
                                                e?.index ===
                                                selectedValues.header_id?.value
                                        )?.name
                                    }`}
                            </span>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-group">
                            <label className="mr-sm-2" htmlFor="csv_hotel_name">
                                Hotel Ranking
                                <span className="text-danger"> * </span>
                            </label>
                            <Select
                                options={data?.map((e) => ({
                                    value: e?.index,
                                    label: e?.name,
                                }))}
                                isClearable={true}
                                name="header_rank"
                                value={selectedValues.header_rank}
                                styles={{
                                    control: (base) => ({
                                        ...base,
                                        borderColor: selectedValues.header_rank
                                            ? "#89c995fc"
                                            : "#ff00009e",
                                        "&:hover": {
                                            borderColor:
                                                selectedValues.header_rank
                                                    ? "#89c995fc"
                                                    : "#ff00009e",
                                        },
                                    }),
                                }}
                                onChange={(e) =>
                                    setSelectedValues({
                                        ...selectedValues,
                                        header_rank: e,
                                    })
                                }
                            />
                            <span className="preview-column">
                                {firstLine?.find(
                                    (e) =>
                                        e?.index ===
                                        selectedValues.header_rank?.value
                                ) &&
                                    `Preview : ${
                                        firstLine?.find(
                                            (e) =>
                                                e?.index ===
                                                selectedValues.header_rank
                                                    ?.value
                                        )?.name
                                    }`}
                            </span>
                        </div>
                    </div>
                </form>
            )}
            {progress && loading && (
                <ProgressBar
                    now={progress}
                    label={`${progress}%`}
                    style={{
                        width: "100%",
                        height: "30px",
                        fontSize: "22px",
                    }}
                />
            )}
        </>
    );
};
