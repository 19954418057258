import React from 'react';
import { Modal } from 'react-bootstrap';
import Lottie from 'react-lottie';
import animationData from '../../lotties/success.json';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
function SuccessUploadPartner (props){
    const { show,handleClose } =props;
    
    return (
    <Modal show={show} onHide={handleClose} dialogClassName="modal-50w modal-dialog modal-dialog-centered">
        <Modal.Body style={{ textAlign: "center" }} >
          <Lottie
            options={defaultOptions}
            width={300}
          />
          <h2 className="modalTitle"> Congratulations !</h2>
          <p className="modalP">Your workspace is all set and ready.</p>
          <button className="modalBtn btn waves-effect waves-light btn-rounded btn-dark" onClick={handleClose}>
             close
          </button>
        </Modal.Body>
    </Modal>
    )

}
export default SuccessUploadPartner;