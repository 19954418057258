import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "./style.css";
import { Helmet } from "react-helmet";
import useQuery from "../../hooks/useQuery";
import LoginService from "../../services/LoginService";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

function TeamSignUp() {
    let query = useQuery();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const handleSignUp = async (data) => {
        if (data?.password2 !== data?.password) {
            toast.error("Passwords must match");
        } else {
            setLoading(true);
            const res = await LoginService.signUpTeamMember({
                ...data,
                token: query.get("token"),
            });
            try {
                if (res.status === 200) {
                    setLoading(false);
                    setTimeout(() => {
                        history.push("/sign-in");
                    }, 3000);
                    return toast.success("Created!", {
                        toastId: "team-sign-up",
                    });
                } else {
                    setLoading(false);
                    toast.error(res?.message);
                }
            } catch (err) {
                setLoading(false);
                toast.error("Server error");
            }
        }
    };
    return (
        <>
            <Helmet>
                <title> Team member sign up</title>
            </Helmet>
            <div
                data-aos-easing="ease-out-quad"
                data-aos-duration="700"
                data-aos-delay="0"
            >
                <section className="bg-gray-200">
                    <div className="container d-flex flex-column">
                        <div className="row align-items-center justify-content-center gx-0 min-vh-100">
                            <div className="col-12-sign-in col-md-5 col-lg-5 py-8 py-md-11">
                                <h1 className="mb-6 fw-bold text-center">
                                    Team member sign up
                                </h1>
                                <form
                                    className="mb-6"
                                    onSubmit={handleSubmit(handleSignUp)}
                                >
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label
                                                    className="form-label"
                                                    htmlFor="firstName"
                                                >
                                                    First Name
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control2"
                                                    id="firstName"
                                                    placeholder="First Name"
                                                    autoComplete="off"
                                                    {...register("first_name")}
                                                />
                                                {errors.first_name && (
                                                    <small
                                                        id="emailHelp"
                                                        className="text-danger form-text"
                                                    >
                                                        {
                                                            errors.first_name
                                                                .message
                                                        }
                                                    </small>
                                                )}
                                            </div>
                                        </div>

                                        <div className="col-6">
                                            <div className="form-group">
                                                <label
                                                    className="form-label"
                                                    htmlFor="LastName"
                                                >
                                                    Last Name
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control2"
                                                    id="LastName"
                                                    placeholder="Last Name"
                                                    autoComplete="off"
                                                    {...register("last_name")}
                                                />
                                                {errors.last_name && (
                                                    <small
                                                        id="emailHelp"
                                                        className="text-danger form-text"
                                                    >
                                                        {
                                                            errors.last_name
                                                                .message
                                                        }
                                                    </small>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label
                                            className="form-label"
                                            htmlFor="password"
                                        >
                                            Password
                                        </label>
                                        <input
                                            type="password"
                                            className="form-control2"
                                            id="password"
                                            placeholder="Password"
                                            {...register("password")}
                                        />
                                        {errors.password && (
                                            <small
                                                id="emailHelp"
                                                className="text-danger form-text"
                                            >
                                                {errors.password.message}
                                            </small>
                                        )}
                                    </div>{" "}
                                    <div className="form-group mb-5">
                                        <label
                                            className="form-label"
                                            htmlFor="confirmPassword"
                                        >
                                            Confirm password
                                        </label>
                                        <input
                                            type="password"
                                            className="form-control2"
                                            id="confirmPassword"
                                            placeholder="Confirm password"
                                            {...register("password2")}
                                        />
                                        {errors.password2 && (
                                            <small
                                                id="emailHelp"
                                                className="text-danger form-text"
                                            >
                                                {errors.password2.message}
                                            </small>
                                        )}
                                    </div>
                                    <button
                                        className="btn2 w-100 btn-primary2"
                                        type="submit"
                                        disabled={loading}
                                    >
                                        Complete sign up{" "}
                                        {loading && (
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        )}
                                    </button>
                                </form>
                                <p className="mb-0 fs-sm text-center text-muted">
                                    Already have an account? &nbsp;{" "}
                                    <a href="/sign-in">Sign in</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}
export default TeamSignUp;

const schema = yup.object().shape({
    first_name: yup.string().required("First name  is required"),
    last_name: yup.string().required("Last name  is required"),
    password2: yup.string().required("Please confirm your password"),
    password: yup.string().required("Password is required"),
});
