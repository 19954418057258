import React, { useState, useEffect } from "react";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Stepper from "react-stepper-horizontal";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import PartnerService from "../../../../services/PartnerService";
import { getPartnerCatalogs } from "../../../../redux/actions/PartnerCataogActions";
import { Modal } from "react-bootstrap";
import stringSimilarity from "string-similarity";
import { getPartnerList } from "../../../../redux/actions/PartnerActions";
import { UPDATE_EXISTING_CATALOG } from "../../../../utilis/const";
import { getCompletedSteps } from "../../../../redux/actions/AnalyticsActions";
import {
    getPartnersInventoriesList,
    getPartnerInventoriesByIdList,
} from "../../../../redux/actions/PartnerActions";

function AddPartnerCatalogDialog(props) {
    const {
        handleClose,
        setOpenSuccesFirstUpload,
        partnerGroupedCatalogs,
        partner,
    } = props;
    const [data, setData] = useState([]);
    const [hideBackButton, setHideBackButton] = useState(false);
    const [selectedValues, setSelectedValues] = useState({});
    const [progressTest, setProgressTest] = useState();
    const [loadingTest, setLoadingTest] = useState(false);
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const [files, setFiles] = useState([]);
    const [selectedPartner, setSelectedPartner] = useState();
    const [showAddNewPartner, setShowAddNewPartner] = useState(false);
    const [partnerName, setPartnerName] = useState("");
    const [loadingPartnerCreation, setLoadingPartnerCreation] = useState(false);
    const [listAttributes, setListAttributes] = React.useState([]);
    const [loadingSaveAttributes, setLoadingSaveAttributes] =
        React.useState(false);
    const { partnerAttributes, loadingAttributes, partnerHasCatalog } =
        useSelector((state) => ({
            loadingAttributes: state.partnerReducer.loadingAttributes,
            partnerAttributes: state.partnerReducer.partnerAttributes ?? [],
            partnerHasCatalog:
                state.partnerReducer.partnerHasCatalog?.exist ?? partner,
            cupidModelsList: state.modelsReducer.cupidModelsList?.models ?? [],
        }));
    const [showPartnerAttributes, setShowPartnerAttributes] = useState(false);
    const [selectedOption, setSelectedOption] = useState();
    const [firstLine, setFirstLine] = useState([]);

    useEffect(() => {
        setListAttributes(partnerAttributes);
    }, [loadingAttributes]);

    async function handleStartProcessing(e) {
        const values = Object.values(selectedValues)
            ?.filter((e) => e !== null)
            ?.map((e) => e.value);
        setLoadingTest(true);
        if (!partner) {
            handleSaveAttributes(selectedPartner?.value);
        }
        if (new Set(values).size !== values.length) {
            setLoadingTest(false);
            toast.warn("List contains duplicate values");
        } else if (
            selectedValues.header_id?.value === undefined ||
            selectedValues.header_name?.value === undefined ||
            selectedValues.header_address?.value === undefined ||
            selectedValues.header_country_code?.value === undefined
        ) {
            setLoadingTest(false);
            toast.error("Please complete all required fields");
        } else {
            setHideBackButton(true);
            const formData = new FormData();
            formData.append("file", files?.[0]);
            formData.append(
                "action",
                selectedOption ?? UPDATE_EXISTING_CATALOG
            );
            formData.append(
                "partner_id",
                partner ? partner?.id : selectedPartner?.value
            );
            formData.append("source", "csv");
            formData.append("header_id", selectedValues.header_id?.value);
            formData.append("header_name", selectedValues.header_name?.value);
            formData.append(
                "header_address",
                selectedValues.header_address?.value
            );
            formData.append(
                "header_address_2",
                selectedValues.header_address_2?.value
            );
            formData.append(
                "header_address_3",
                selectedValues.header_address_3?.value
            );
            formData.append("header_city", selectedValues.header_city?.value);
            formData.append(
                "header_country_code",
                selectedValues.header_country_code?.value
            );
            formData.append(
                "header_latitude",
                selectedValues.header_latitude?.value
            );
            formData.append(
                "header_longitude",
                selectedValues.header_longitude?.value
            );
            formData.append(
                "header_city_id",
                selectedValues.header_city_id?.value
            );
            formData.append("header_chain", selectedValues.header_chain?.value);
            formData.append("header_type", selectedValues.header_type?.value);
            formData.append(
                "header_zip_code",
                selectedValues.header_zip_code?.value
            );
            formData.append("header_stars", selectedValues.header_stars?.value);

            const option = {
                onUploadProgress: (ProgressEvent) => {
                    const { loaded, total } = ProgressEvent;
                    let percent = Math.floor((loaded * 100) / total);
                    setProgressTest(percent);
                },
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            let res = await PartnerService.uploadPartnerCatalog(
                formData,
                option
            );
            if (res.status === 200) {
                setLoadingTest(false);
                await dispatch(getPartnerCatalogs(partner?.id));
                if (partnerGroupedCatalogs?.length == 0 && !partner) {
                    setOpenSuccesFirstUpload(true);
                } else {
                    toast.success("Created!");
                }
                window.analytics.track("Add new partner inventory", {
                    userID: localStorage.getItem("userId"),
                    companyID: localStorage.getItem("companyId"),
                    companyName: localStorage.getItem("companyName"),
                    firstName: localStorage.getItem("firstName"),
                    lastName: localStorage.getItem("lastName"),
                    email: localStorage.getItem("email"),
                    selectedPartnerID: selectedPartner?.value,
                    selectedPartnerName: selectedPartner?.label,
                    Type: "csv",
                    totalHotels: localStorage.getItem("fileSize"),
                });
                if (partner) {
                    dispatch(getPartnerInventoriesByIdList(partner?.id));
                } else {
                    dispatch(getPartnersInventoriesList());
                }
                dispatch(getCompletedSteps());

                handleClose();
            } else {
                setLoadingTest(false);
                toast.error(res.message);
            }
        }
        setHideBackButton(false);
    }

    useEffect(() => {
        if (data?.length > 0) {
            let defaultHotelCode = stringSimilarity.findBestMatch(
                "hotel id",
                data?.map((e) => e.name.toLowerCase())
            );
            let defaultHotelName = stringSimilarity.findBestMatch(
                "name",
                data?.map((e) => e.name.toLowerCase())
            );
            let defaultAddress = stringSimilarity.findBestMatch(
                "address",
                data?.map((e) => e.name.toLowerCase())
            );
            let defaultCity = stringSimilarity.findBestMatch(
                "city",
                data?.map((e) => e.name.toLowerCase())
            );
            let defaultCountry = stringSimilarity.findBestMatch(
                "country iso code",
                data?.map((e) => e.name.toLowerCase())
            );
            let defaultLatitude = stringSimilarity.findBestMatch(
                "latitude",
                data?.map((e) => e.name.toLowerCase())
            );
            let defaultLongitude = stringSimilarity.findBestMatch(
                "longitude",
                data?.map((e) => e.name.toLowerCase())
            );
            let defaultCityId = stringSimilarity.findBestMatch(
                "cityId",
                data?.map((e) => e.name.toLowerCase())
            );
            let defaultChain = stringSimilarity.findBestMatch(
                "chain",
                data?.map((e) => e.name.toLowerCase())
            );
            let defaultType = stringSimilarity.findBestMatch(
                "type",
                data?.map((e) => e.name.toLowerCase())
            );
            let defaultRating = stringSimilarity.findBestMatch(
                "rating",
                data?.map((e) => e.name.toLowerCase())
            );
            let defaultzipCode = stringSimilarity.findBestMatch(
                "zipCode",
                data?.map((e) => e.name.toLowerCase())
            );
            let selectedValuesArray = {};
            if (defaultHotelCode?.bestMatch?.rating >= 0.8) {
                selectedValuesArray["header_id"] = {
                    value: defaultHotelCode?.bestMatchIndex,
                    label: defaultHotelCode?.bestMatch?.target,
                };
            }
            if (defaultHotelName?.bestMatch?.rating >= 0.8) {
                selectedValuesArray["header_name"] = {
                    value: defaultHotelName?.bestMatchIndex,
                    label: defaultHotelName?.bestMatch?.target,
                };
            }
            if (defaultAddress?.bestMatch?.rating >= 0.8) {
                selectedValuesArray["header_address"] = {
                    value: defaultAddress?.bestMatchIndex,
                    label: defaultAddress?.bestMatch?.target,
                };
            }
            if (defaultCity?.bestMatch?.rating >= 0.8) {
                selectedValuesArray["header_city"] = {
                    value: defaultCity?.bestMatchIndex,
                    label: defaultCity?.bestMatch?.target,
                };
            }
            if (defaultCountry?.bestMatch?.rating >= 0.8) {
                selectedValuesArray["header_country_code"] = {
                    value: defaultCountry?.bestMatchIndex,
                    label: defaultCountry?.bestMatch?.target,
                };
            }
            if (defaultLatitude?.bestMatch?.rating >= 0.8) {
                selectedValuesArray["header_latitude"] = {
                    value: defaultLatitude?.bestMatchIndex,
                    label: defaultLatitude?.bestMatch?.target,
                };
            }
            if (defaultLongitude?.bestMatch?.rating >= 0.8) {
                selectedValuesArray["header_longitude"] = {
                    value: defaultLongitude?.bestMatchIndex,
                    label: defaultLongitude?.bestMatch?.target,
                };
            }
            if (defaultCityId?.bestMatch?.rating >= 0.8) {
                selectedValuesArray["header_city_id"] = {
                    value: defaultCityId?.bestMatchIndex,
                    label: defaultCityId?.bestMatch?.target,
                };
            }
            if (defaultChain?.bestMatch?.rating >= 0.8) {
                selectedValuesArray["header_chain"] = {
                    value: defaultChain?.bestMatchIndex,
                    label: defaultChain?.bestMatch?.target,
                };
            }
            if (defaultType?.bestMatch?.rating >= 0.8) {
                selectedValuesArray["header_type"] = {
                    value: defaultType?.bestMatchIndex,
                    label: defaultType?.bestMatch?.target,
                };
            }
            if (defaultRating?.bestMatch?.rating >= 0.8) {
                selectedValuesArray["header_stars"] = {
                    value: defaultRating?.bestMatchIndex,
                    label: defaultRating?.bestMatch?.target,
                };
            }
            if (defaultzipCode?.bestMatch?.rating >= 0.8) {
                selectedValuesArray["header_zip_code"] = {
                    value: defaultzipCode?.bestMatchIndex,
                    label: defaultzipCode?.bestMatch?.target,
                };
            }
            setSelectedValues(selectedValuesArray);
        }
    }, [data]);

    const sections = React.useMemo(() => {
        const required = [
            {
                title: "Upload catalog",
            },
            {
                title: "Field matching",
            },
        ];
        let extra = [];
        if (partner === undefined) {
            extra.push({
                title: "Select partner",
            });
        } else {
            extra.push({
                title: "Select Method",
            });
        }

        return [...extra, ...required];
    }, [partner]);

    const next = async () => {
        if (currentPage === 1) {
            handleSelectPartnerStep();
        } else if (currentPage === 2 && files?.length <= 0) {
            toast.error("Upload your catalog file  to pass next step", {
                toastId: "partner-catalog-csv-step3",
            });
        } else {
            setCurrentPage((prev) => prev + 1);
        }
    };

    const handleSelectPartnerStep = async () => {
        if (
            selectedPartner?.value === undefined &&
            !partner &&
            !showAddNewPartner
        ) {
            toast.error("Partner is required to pass", {
                toastId: "partner-catalog-csv-step1",
            });
        } else if (!showAddNewPartner && !selectedOption && partnerHasCatalog) {
            toast.error("Please choose an option to upload your catalog", {
                toastId: "option-catalog-csv-step1",
            });
        } else if (showAddNewPartner && partnerName !== "") {
            const option = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            setLoadingPartnerCreation(true);
            const res = await PartnerService.addNewPartner(partnerName, option);
            if (res?.status === 200) {
                setLoadingPartnerCreation(false);
                setShowAddNewPartner(false);
                dispatch(getPartnerList());
                setSelectedPartner({
                    value: res?.data?.partnerId,
                    label: partnerName,
                });
                setPartnerName("");
            } else {
                setLoadingPartnerCreation(false);
                toast.error(res?.message);
            }
        } else {
            setCurrentPage((prev) => prev + 1);
        }
    };

    const prev = () => setCurrentPage((prev) => prev - 1);

    const handleSaveAttributes = async (partner_id) => {
        setLoadingSaveAttributes(true);
        const res = await PartnerService.addPartnerAttributes({
            partner_id: partner_id,
            attributes: listAttributes,
        });
        if (res.status === 200) {
        } else {
            toast.error(res.message, {
                toastId: "partner-attributes",
            });
        }
        setLoadingSaveAttributes(false);
    };

    const renderElements = () => {
        switch (currentPage) {
            case 1:
                return (
                    <Step1
                        setSelectedPartner={setSelectedPartner}
                        selectedPartner={selectedPartner}
                        next={next}
                        showAddNewPartner={showAddNewPartner}
                        setShowAddNewPartner={setShowAddNewPartner}
                        partnerName={partnerName}
                        setPartnerName={setPartnerName}
                        loadingPartnerCreation={loadingPartnerCreation}
                        showPartnerAttributes={showPartnerAttributes}
                        setShowPartnerAttributes={setShowPartnerAttributes}
                        listAttributes={listAttributes}
                        setListAttributes={setListAttributes}
                        loadingSaveAttributes={loadingSaveAttributes}
                        selectedOption={selectedOption}
                        setSelectedOption={setSelectedOption}
                        handleClose={handleClose}
                        partner={partner}
                    />
                );
            case 2:
                return (
                    <Step2
                        setFiles={setFiles}
                        files={files}
                        setData={setData}
                        next={next}
                        prev={prev}
                        setFirstLine={setFirstLine}
                        handleClose={handleClose}
                    />
                );
            case 3:
                return (
                    <Step3
                        selectedValues={selectedValues}
                        setSelectedValues={setSelectedValues}
                        data={data}
                        loadingTest={loadingTest}
                        progressTest={progressTest}
                        handleClose={handleClose}
                        handleStartProcessing={handleStartProcessing}
                        prev={prev}
                        hideBackButton={hideBackButton}
                        firstLine={firstLine}
                    />
                );
            default:
                return <></>;
        }
    };

    return (
        <Modal.Body style={{ paddingBottom: "0px" }}>
            <div className="card-header p-0 pb-2">
                <div className="stepper">
                    <Stepper
                        steps={sections}
                        activeStep={currentPage}
                        activeColor="#D3D7DB"
                        defaultBarColor="#D3D7DB"
                        completeColor="#6076E8"
                        completeBarColor="#6076E8"
                        fontFamily="Roboto"
                        textColor="#fff"
                        defaultBorderStyle="solid 6px"
                        defaultBorderWidth={10}
                    />
                </div>
            </div>
            <div className="card-body">{renderElements()}</div>
        </Modal.Body>
    );
}

export default AddPartnerCatalogDialog;
