import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import ConfirmAction from "../../components/ConfirmActionsModal";
import BuildService from "../../services/BuildService";
import { Helmet } from "react-helmet";

function SettingsScreen(props) {
    const [generateNewProductionKey, setGenerateNewProductionKey] =
        useState(false);
    const [loadingGenerateNewKey, setLoadingGenerateNewKey] = useState(false);

    const handleGenerateNewKey = async () => {
        setLoadingGenerateNewKey(true);
        try {
            const res = await BuildService.generateNewApiKeys("production");
            if (res.status === 200) {
                toast.success("API key changed successfully !");
                window.analytics.track("Generate API key", {
                    companyId: localStorage.getItem("companyId"),
                    userId: localStorage.getItem("userId"),
                });
                setLoadingGenerateNewKey(false);
                setGenerateNewProductionKey(false);
            } else {
                toast.error(res.message);
                setLoadingGenerateNewKey(false);
                setGenerateNewProductionKey(false);
            }
        } catch (e) {
            toast.error("Error");
            setLoadingGenerateNewKey(false);
            setGenerateNewProductionKey(false);
        }
    };
    return (
        <>
            <Helmet>
                <title> API Keys</title>
            </Helmet>
            <div className="page-wrapper" style={{ display: "block" }}>
                <div className="page-breadcrumb">
                    <div className="row">
                        <div className="col-7 align-self-center">
                            <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
                                API Keys
                            </h3>
                        </div>
                        <div className="col-12">
                            <p className="card-text">
                                <span className="sub-title-info ">
                                    <i className="fa  fa-info-circle"></i> These
                                    keys will allow you to authenticate API
                                    requests.
                                </span>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex align-items-center mb-4">
                                        <div>
                                            <h4 className="card-title">
                                                Get your API key
                                            </h4>
                                        </div>

                                        <div className="ml-auto">
                                            <div className="">
                                                <button
                                                    className="btn btn-info"
                                                    onClick={() => {
                                                        setGenerateNewProductionKey(
                                                            true
                                                        );
                                                    }}
                                                >
                                                    Generate your API key
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="api-key-bloc">
                                        <div
                                            className="api-key-content"
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <h3
                                                style={{
                                                    padding: "0.375rem 0.75rem",
                                                    marginBottom: "0px",
                                                }}
                                            >
                                                {localStorage.getItem(
                                                    "apiKeys"
                                                ) ?? ""}
                                            </h3>
                                            <CopyToClipboard
                                                text={localStorage.getItem(
                                                    "apiKeys"
                                                )}
                                                onCopy={() =>
                                                    toast.success(
                                                        "Api keys copied to clipboard"
                                                    )
                                                }
                                            >
                                                <button className="btn">
                                                    <i
                                                        className="fas fa-copy"
                                                        style={{
                                                            fontSize: " 23px",
                                                        }}
                                                    ></i>
                                                </button>
                                            </CopyToClipboard>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {generateNewProductionKey && (
                <ConfirmAction
                    show={generateNewProductionKey}
                    onClose={() => setGenerateNewProductionKey(false)}
                    titleMessage="Warning !"
                    subTitleMessage="
                    Generating a new API key will disable the previous one."
                    subTitleMessage1=" Trying to use your old API key after generating a new one will result in errors."
                    subTitleMessage2=" Are you sure you want to generate a new key ?"
                    colorCode="danger"
                    handleClick={handleGenerateNewKey}
                    buttonText="Confirm"
                    disabled={loadingGenerateNewKey}
                />
            )}
        </>
    );
}
export default SettingsScreen;
