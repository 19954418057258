import axios from "axios";
import moment from "moment";

const FormData = require("form-data");

const connectorsUrl = process.env.REACT_APP_BASE_URL + "/connectors";
const connectorsInventoryUrl = process.env.REACT_APP_BASE_URL + "/catalogs";
const credentialsUrl = connectorsUrl + "/credentials";
const findCredentialsUrl = connectorsUrl + "/credentials/find";
const runMappingUrl =
    process.env.REACT_APP_BASE_URL + "/partner-catalogs/connector";
const publicConnectorUrl =
    process.env.REACT_APP_BASE_URL + "/partner-catalogs/connector/public/";

const ConnectorService = {
    add: async (
        connectorId,
        partner_id,
        selectedFrequency,
        credentials,
        connectorPublic,
        selectedModel,
        giataPartnerKey,
        useManualMappings
    ) => {
        const bodyFormData = new FormData();
        bodyFormData.append("partner_id", partner_id);
        bodyFormData.append("credentials", JSON.stringify(credentials));
        bodyFormData.append("frequency", selectedFrequency?.value);
        bodyFormData.append("public", connectorPublic);
        bodyFormData.append("model", selectedModel);
        bodyFormData.append("giata_partner_key", giataPartnerKey);
        bodyFormData.append(
            "use_manual_mappings",
            useManualMappings === true ? 1 : 0
        );
        const result = await axios
            .put(credentialsUrl + "/add/" + connectorId, bodyFormData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                return {
                    data: res.data,
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: error.response.data.msg,
                    status: error.response.data.code,
                };
            });
        return result;
    },
    addInventoryConnector: async (connectorId, credentials) => {
        const bodyFormData = new FormData();
        bodyFormData.append("credentials", JSON.stringify(credentials));
        const result = await axios
            .post(
                connectorsInventoryUrl +
                    "/connector-credentials/create/" +
                    connectorId,
                bodyFormData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${localStorage.getItem(
                            "token"
                        )}`,
                    },
                }
            )
            .then((res) => {
                return {
                    data: res.data,
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: error.response.data.msg,
                    status: error.response.data.code,
                };
            });
        return result;
    },
    runPublicMapping: async (connectorId, bodyFormData) => {
        const result = await axios
            .post(publicConnectorUrl + connectorId, bodyFormData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                return {
                    data: res.data,
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: error.response.data.message,
                    status: error.response.data.code,
                };
            });
        return result;
    },
    scheduler: async (credentialId, cronTab, destinations) => {
        const bodyFormData = new FormData();
        bodyFormData.append("crontab", cronTab);
        if (destinations?.length > 0) {
            bodyFormData.append(
                "destinationIds",
                destinations?.map((e) => e?.id)?.join(",")
            );
        }
        const result = await axios
            .patch(credentialsUrl + "/" + credentialId, bodyFormData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                return {
                    data: res.data,
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: error.response.data.msg,
                    status: error.response.data.code,
                };
            });
        return result;
    },
    update: async (
        credentialsId,
        credentials,
        giataPartnerKey,
        connectorModel,
        useManualMappings
    ) => {
        const bodyFormData = new FormData();
        bodyFormData.append("credentials", credentials);
        bodyFormData.append("giata_partner_key", giataPartnerKey);
        bodyFormData.append("model", connectorModel);
        bodyFormData.append(
            "use_manual_mappings",
            useManualMappings === true ? 1 : 0
        );
        const result = await axios
            .patch(credentialsUrl + "/" + credentialsId, bodyFormData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                return {
                    data: res.data,
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: error.response.data.message,
                    status: error.response.data.code,
                };
            });
        return result;
    },
    delete: async (credentialsId) => {
        const result = await axios
            .delete(credentialsUrl + "/" + credentialsId, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                return {
                    data: res.data,
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: error.response.data.message,
                    status: error.response.data.code,
                };
            });
        return result;
    },

    getCredentials: async () => {
        const result = await axios
            .get(credentialsUrl, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                let result = {
                    users: res.data?.data,
                    status: 200,
                };
                return result;
            })
            .catch((error) => {
                return {
                    message: error.response.data.msg,
                    status: error.response.data.code,
                };
            });
        return result;
    },
    getCredentialsById: async (credentialsId) => {
        const result = await axios
            .get(credentialsUrl + "/" + credentialsId, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                let result = {
                    data: res.data,
                    credentials: res.data.credentials,
                    status: 200,
                };
                return result;
            })
            .catch((error) => {
                return {
                    message: error.response.data.msg,
                    status: error.response.data.code,
                };
            });
        return result;
    },
    getConnectors: async () => {
        const result = await axios
            .get(connectorsUrl, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                let result = {
                    users: res.data?.data,
                    status: 200,
                };
                return result;
            })
            .catch((error) => {
                return {
                    message: error.response.data.msg,
                    status: error.response.data.code,
                };
            });
        return result;
    },
    createPartnerInventoryFromConnector: async (
        credentialsId,
        bodyFormData
    ) => {
        const result = await axios
            .post(runMappingUrl + "/" + credentialsId, bodyFormData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                let result = {
                    users: res.data?.data,
                    status: 200,
                };
                return result;
            })
            .catch((error) => {
                //console.log("Error", JSON.parse(error.response.data));
                return {
                    message: error.response.data.msg,
                    status: error.response.data.code,
                };
            });
        return result;
    },
    runInventoryByConnector: async (credentialsId, bodyFormData) => {
        const result = await axios
            .post(
                connectorsInventoryUrl + "/get-inventory/" + credentialsId,
                bodyFormData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${localStorage.getItem(
                            "token"
                        )}`,
                    },
                }
            )
            .then((res) => {
                let result = {
                    data: res.data,
                    status: 200,
                };
                return result;
            })
            .catch((error) => {
                //console.log("Error", JSON.parse(error.response.data));
                return {
                    message: error.response.data.msg,
                    status: error.response.data.code,
                };
            });
        return result;
    },

    checkCridentials: async (credentialsId, partnerId) => {
        const result = await axios
            .get(findCredentialsUrl + "/" + credentialsId + "/" + partnerId, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                let result = {
                    data: res.data,
                    status: 200,
                };
                return result;
            })
            .catch((error) => {
                //console.log("Error", JSON.parse(error.response.data));
                return {
                    message: error.response.data.msg,
                    status: error.response.data.code,
                };
            });
        return result;
    },
    checkInventoryCridentials: async (connectorId) => {
        const result = await axios
            .get(
                connectorsInventoryUrl +
                    "/connector-credentials/" +
                    connectorId,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${localStorage.getItem(
                            "token"
                        )}`,
                    },
                }
            )
            .then((res) => {
                let result = {
                    data: res.data,
                    status: 200,
                };
                return result;
            })
            .catch((error) => {
                //console.log("Error", JSON.parse(error.response.data));
                return {
                    message: error.response.data.msg,
                    status: error.response.data.code,
                };
            });
        return result;
    },
    runMappingWithPublicConnector: async (connectorId, bodyFormData) => {
        const result = await axios
            .post(runMappingUrl + "/public/" + connectorId, bodyFormData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                let result = {
                    data: res.data,
                    status: 200,
                };
                return result;
            })
            .catch((error) => {
                return {
                    message: error.response.data.msg,
                    status: error.response.data.code,
                };
            });
        return result;
    },
    updateModel: async (credentialsId, model) => {
        const result = await axios
            .post(credentialsUrl + "/model/" + credentialsId, model, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                return {
                    data: res.data,
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: error.response.data.message,
                    status: error.response.data.code,
                };
            });
        return result;
    },
    addTravelgateCredentials: async (credentials) => {
        const result = await axios
            .post(connectorsUrl + "/travelgate-credentials", credentials, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                let result = {
                    data: res.data,
                    status: 200,
                };
                return result;
            })
            .catch((error) => {
                return {
                    message: error.response.data.msg,
                    status: error.response.data.code,
                };
            });
        return result;
    },

    connectorTestConnection: async (connectorId, credentials) => {
        const bodyFormData = new FormData();
        bodyFormData.append("credentials", JSON.stringify(credentials));
        const result = await axios
            .post(
                connectorsUrl + "/test-connection/" + connectorId,
                bodyFormData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${localStorage.getItem(
                            "token"
                        )}`,
                    },
                }
            )
            .then((res) => {
                return {
                    data: res.data,
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: error.response.data.msg,
                    status: error.response.data.code,
                };
            });
        return result;
    },
    rerunInventory: async () => {
        const result = await axios
            .post(
                connectorsInventoryUrl + "/rerun-inventory",
                {},
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${localStorage.getItem(
                            "token"
                        )}`,
                    },
                }
            )
            .then((res) => {
                return {
                    data: res.data,
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: error.response.data.msg,
                    status: error.response.data.code,
                };
            });
        return result;
    },
    runMapping: async (credentialsId, bodyFormData) => {
        const result = await axios
            .get(connectorsUrl + "/run-mapping-by-connector/" + credentialsId, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                let result = {
                    data: res.data,
                    status: 200,
                };
                return result;
            })
            .catch((error) => {
                //console.log("Error", JSON.parse(error.response.data));
                return {
                    message: error.response.data.msg,
                    status: error.response.data.code,
                };
            });
        return result;
    },
};

export default ConnectorService;
