/* eslint-disable import/no-anonymous-default-export */

import {
    GET_HOTEL_RANKING_SUCCESS,
    GET_HOTEL_RANKING_LISTING,
    GET_HOTEL_RANKING_FAIL,
} from "../types";

const initialState = {
    hotelRanking: [],
    loadingHotelRanking: false,
    totalHotelRanking: 0,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_HOTEL_RANKING_LISTING:
            return {
                ...state,
                loadingHotelRanking: true,
                hotelRanking: [],
            };
        case GET_HOTEL_RANKING_SUCCESS:
            return {
                ...state,
                hotelRanking: action.payload,
                loadingHotelRanking: false,
            };
        case GET_HOTEL_RANKING_FAIL:
            return {
                ...state,
                loadingHotelRanking: false,
                hotelRanking: [],
            };
        default:
            return state;
    }
}
