import React from "react";
import GoogleMapReact from "google-map-react";

const key = "AIzaSyCgmAQ6vLFMsm7hOggHAdO0ILR1CXZA7x8";

const getMapOptions = (maps) => {
    return {
        streetViewControl: true,
        scaleControl: true,
        fullscreenControl: true,
        styles: [
            {
                featureType: "poi.business",
                elementType: "labels",
                stylers: [
                    {
                        visibility: "off",
                    },
                ],
            },
        ],
        gestureHandling: "cooperative", // Set gestureHandling to "cooperative"
        disableDoubleClickZoom: true,
        mapTypeControl: true,
        mapTypeControlOptions: {
            style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: maps.ControlPosition.BOTTOM_CENTER,
            mapTypeIds: [
                maps.MapTypeId.ROADMAP,
                maps.MapTypeId.SATELLITE,
                maps.MapTypeId.HYBRID,
            ],
        },
        zoomControl: true,
        clickableIcons: false,
    };
};

function GoogleMap({ latitude, longitude }) {
    const center = {
        lat: latitude,
        lng: longitude,
    };

    const renderMarkers = (map, maps) => {
        return new maps.Marker({
            position: center,
            title: "Hotel Location",
            map,
            icon: {
                url: "../assets/images/map-marker.png",
                scaledSize: new maps.Size(90, 101),
                labelOrigin: new maps.Point(25, 35),
            },
        });
    };

    const zoom = 15;

    return (
        <div
            style={{
                height: "600px",
                width: "100%",
                border: "2px solid #edf2f9",
                borderRadius: "16px",
                overflow: "hidden",
            }}
        >
            <GoogleMapReact
                bootstrapURLKeys={{ key }}
                defaultCenter={center}
                defaultZoom={zoom}
                options={getMapOptions}
                onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
            ></GoogleMapReact>
        </div>
    );
}

export default GoogleMap;
