import React from "react";
import Table from "../Table/Table";
import { useSelector } from "react-redux";

const ChainList = () => {
    const { chainReportList, loadingChains } = useSelector((state) => ({
        chainReportList: state.partnerReportReducer.chainReportList ?? [],
        loadingChains: state.partnerReportReducer.loadingChains,
    }));
    const columns = [
        {
            id: "catalog_chain",
            name: <div className="table-title">Chain</div>,
            cell: (row) => <div>{row.catalog_chain}</div>,
            sortable: true,
        },
        {
            id: "mapped_hotels",
            name: <div className="table-title">Mapped Hotels</div>,
            cell: (row) => <div>{row.mapped_hotels}</div>,
            sortable: true,
        },
    ];
    return (
        <div className="responsive-table-container">
            <Table
                columns={columns}
                data={chainReportList}
                paginationPerPage={4}
                progressPending={loadingChains}
            />
        </div>
    );
};

export default ChainList;
