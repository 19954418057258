import {
    SEARCH_HOTELS_SUCCESS,
    SEARCH_HOTELS_LISTING,
    SEARCH_HOTELS_FAIL,
    HOTEL_DETAILS_SUCCESS,
    HOTEL_DETAILS_FAIL,
    HOTEL_DETAILS_LISTING,
    GET_AUTO_COMPLETE_HOTEL_NAME_SUCCESS,
    GET_AUTO_COMPLETE_HOTEL_NAME_LISTING,
    GET_AUTO_COMPLETE_HOTEL_NAME_FAIL,
} from "../types";
import axios from "axios";

const PATH_URL = process.env.REACT_APP_BASE_URL;

export const searchHotels = (filters) => async (dispatch) => {
    dispatch({
        type: SEARCH_HOTELS_LISTING,
    });
    try {
        const res = await axios.post(
            PATH_URL + "/hotel-catalogs/search",
            filters,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }
        );

        dispatch({
            type: SEARCH_HOTELS_SUCCESS,
            payload: res.data.hotels,
        });
    } catch (e) {
        dispatch({
            type: SEARCH_HOTELS_FAIL,
            payload: console.log("Error in actions ", e),
        });
    }
};

export const getHotelDetails = (id) => async (dispatch) => {
    dispatch({
        type: HOTEL_DETAILS_LISTING,
    });
    try {
        const res = await axios.get(
            PATH_URL + `/hotel-catalogs/details/${id}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }
        );
        dispatch({
            type: HOTEL_DETAILS_SUCCESS,
            payload: res.data,
        });
    } catch (e) {
        dispatch({
            type: HOTEL_DETAILS_FAIL,
            payload: console.log("Error in actions ", e),
        });
    }
};

export const autoCompleteHotelName = (name) => async (dispatch) => {
    dispatch({
        type: GET_AUTO_COMPLETE_HOTEL_NAME_LISTING,
    });
    try {
        const res = await axios.get(PATH_URL + `/hotels/autocomplete`, {
            params: {
                q: name,
            },
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        dispatch({
            type: GET_AUTO_COMPLETE_HOTEL_NAME_SUCCESS,
            payload: res.data,
        });
    } catch (e) {
        dispatch({
            type: GET_AUTO_COMPLETE_HOTEL_NAME_FAIL,
            payload: console.log("Error in actions ", e),
        });
    }
};
