import React from "react";
import Table from "../Table/Table";
import { useSelector } from "react-redux";

const CountryList = () => {
    const { countryReportList } = useSelector((state) => ({
        countryReportList: state.partnerReportReducer.countryReportList ?? [],
    }));

    const columns = [
        {
            id: "catalog_country",
            name: <div className="table-title">Country</div>,
            selector: (row) => row.catalog_country,
            sortable: true,
            cell: (row) => <div>{row.catalog_country}</div>,
        },
        {
            id: "mapped_hotels",
            name: <div className="table-title">Mapped Hotels</div>,
            selector: (row) => row.mapped_hotels,
            sortable: true,
            cell: (row) => <div>{row.mapped_hotels}</div>,
        },
    ];

    return (
        <div className="responsive-table-container">
            <Table
                columns={columns}
                data={countryReportList}
                paginationPerPage={4}
            />
        </div>
    );
};

export default CountryList;
