import {
    COUNTRY_REPORT_SUCCESS,
    COUNTRY_REPORT_LISTING,
    COUNTRY_REPORT_FAIL,
    CITY_REPORT_SUCCESS,
    CITY_REPORT_LISTING,
    CITY_REPORT_FAIL,
    GET_PROPERTIES_UNMAPPED_SUCCESS,
    GET_PROPERTIES_UNMAPPED_FAIL,
    GET_PROPERTIES_UNMAPPED_LISTING,
    CHAIN_REPORT_LISTING,
    CHAIN_REPORT_SUCCESS,
    CHAIN_REPORT_FAIL,
    PROPERTY_TYPE_REPORT_LISTING,
    PROPERTY_TYPE_REPORT_SUCCESS,
    PROPERTY_TYPE_REPORT_FAIL,
    GET_MAPPED_HOTEL_LIST_LISTING,
    GET_MAPPED_HOTEL_LIST_SUCCESS,
    GET_MAPPED_HOTEL_LIST_FAIL,
    GET_OPPORTUNITIES_HOTEL_LIST_LISTING,
    GET_OPPORTUNITIES_HOTEL_LIST_SUCCESS,
    GET_OPPORTUNITIES_HOTEL_LIST_FAIL,
    GET_INVALID_HOTEL_LIST_LISTING,
    GET_INVALID_HOTEL_LIST_SUCCESS,
    GET_INVALID_HOTEL_LIST_FAIL,
    GET_INVENTORY_HOTEL_CODE_NAME_LISTING,
    GET_INVENTORY_HOTEL_CODE_NAME_SUCCESS,
    GET_INVENTORY_HOTEL_CODE_NAME_FAIL,
    GET_INVENTORY_CITIES_LISTING,
    GET_INVENTORY_CITIES_SUCCESS,
    GET_INVENTORY_CITIES_FAIL,
    GET_INVENTORY_COUNTRIES_LISTING,
    GET_INVENTORY_COUNTRIES_SUCCESS,
    GET_INVENTORY_COUNTRIES_FAIL,
    GET_HEADER_DETAILS_LISTING,
    GET_HEADER_DETAILS_SUCCESS,
    GET_HEADER_DETAILS_FAIL,
    GET_MAPPING_RATE_OVER_TIME_SUCCESS,
    GET_MAPPING_RATE_OVER_TIME_FAIL,
    GET_MAPPING_RATE_OVER_TIME_LISTING,
    GET_NUMBER_MAPPING_PERFORMED_SUCCESS,
    GET_NUMBER_MAPPING_PERFORMED_FAIL,
    GET_NUMBER_MAPPING_PERFORMED_LISTING,
    CHAIN_REPORT_LIST_SUCCESS,
    PROPERTY_TYPE_REPORT_LIST_SUCCESS,
    GET_REPORT_INFO_LISTING,
    GET_REPORT_INFO_SUCCESS,
    GET_REPORT_INFO_FAIL,
    GET_COMPARABLE_MAPPINGS_SUCCESS,
    GET_COMPARABLE_MAPPINGS_LISTING,
    GET_COMPARABLE_MAPPINGS_FAIL,
    GET_COMPARE_MAPPIING_TOTAL_LISTING,
    GET_COMPARE_MAPPIING_TOTAL_SUCCESS,
    GET_COMPARE_MAPPIING_TOTAL_FAIL,
    GET_COMPARE_MAPPED_LISTING,
    GET_COMPARE_MAPPED_SUCCESS,
    GET_COMPARE_MAPPED_FAIL,
    GET_COMPARE_UNMAPPED_LISTING,
    GET_COMPARE_UNMAPPED_SUCCESS,
    GET_COMPARE_UNMAPPED_FAIL,
    GET_SEARCH_FIELDS_LISTING,
    GET_SEARCH_FIELDS_SUCCESS,
    GET_SEARCH_FIELDS_FAIL,
    GET_OLD_HOTELS_LISTING,
    GET_OLD_HOTELS_FAIL,
    GET_OLD_HOTELS_SUCCESS,
} from "../types";
import axios from "axios";

const PATH_URL = process.env.REACT_APP_BASE_URL_AUTH;

export const getCountryReport = (token, filters) => async (dispatch) => {
    dispatch({
        type: COUNTRY_REPORT_LISTING,
    });
    try {
        const res = await axios.post(
            PATH_URL + `reports/${token}/country-report`,
            filters,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }
        );
        dispatch({
            type: COUNTRY_REPORT_SUCCESS,
            payload: res.data?.data,
        });
    } catch (e) {
        dispatch({
            type: COUNTRY_REPORT_FAIL,
            payload: console.log("Error in actions ", e),
        });
    }
};

export const getCityReport = (token, filters) => async (dispatch) => {
    dispatch({
        type: CITY_REPORT_LISTING,
    });
    try {
        const res = await axios.post(
            PATH_URL + `reports/${token}/city-report`,
            filters,

            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }
        );
        dispatch({
            type: CITY_REPORT_SUCCESS,
            payload: res.data?.data,
        });
    } catch (e) {
        dispatch({
            type: CITY_REPORT_FAIL,
            payload: console.log("Error in actions ", e),
        });
    }
};

export const getChainReport = (token, filters) => async (dispatch) => {
    dispatch({
        type: CHAIN_REPORT_LISTING,
    });
    try {
        const res = await axios.post(
            PATH_URL + `reports/${token}/chain-report`,
            filters,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }
        );
        dispatch({
            type: CHAIN_REPORT_SUCCESS,
            payload: res.data?.data,
        });
    } catch (e) {
        dispatch({
            type: CHAIN_REPORT_FAIL,
            payload: console.log("Error in actions ", e),
        });
    }
};
export const getChainReportList = (token) => async (dispatch) => {
    try {
        const res = await axios.post(
            PATH_URL + `reports/${token}/chain-report`,
            {},
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }
        );
        dispatch({
            type: CHAIN_REPORT_LIST_SUCCESS,
            payload: res.data?.data,
        });
    } catch (e) {
        dispatch({
            type: CHAIN_REPORT_FAIL,
            payload: console.log("Error in actions ", e),
        });
    }
};
export const getPropertyTypeReport = (token, filters) => async (dispatch) => {
    dispatch({
        type: PROPERTY_TYPE_REPORT_LISTING,
    });
    try {
        const res = await axios.post(
            PATH_URL + `reports/${token}/property-type-report`,
            filters,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }
        );
        dispatch({
            type: PROPERTY_TYPE_REPORT_SUCCESS,
            payload: res.data?.data,
        });
    } catch (e) {
        dispatch({
            type: PROPERTY_TYPE_REPORT_FAIL,
            payload: console.log("Error in actions ", e),
        });
    }
};
export const getPropertyTypeReportList = (token) => async (dispatch) => {
    try {
        const res = await axios.post(
            PATH_URL + `reports/${token}/property-type-report`,
            {},
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }
        );
        dispatch({
            type: PROPERTY_TYPE_REPORT_LIST_SUCCESS,
            payload: res.data?.data,
        });
    } catch (e) {
        dispatch({
            type: PROPERTY_TYPE_REPORT_FAIL,
            payload: console.log("Error in actions ", e),
        });
    }
};

export const geMappedHotelListReport =
    (token, page, perPage, filterText, filters, sort_by, sort_order) =>
    async (dispatch) => {
        var params = new URLSearchParams();
        params.append("offset", (page - 1) * perPage);
        params.append("limit", perPage);
        params.append("filter", filterText);
        if (sort_by) {
            params.append("sort_by", sort_by);
        }
        if (sort_order) {
            params.append("sort_order", sort_order);
        }
        dispatch({
            type: GET_MAPPED_HOTEL_LIST_LISTING,
        });
        try {
            const res = await axios.post(
                PATH_URL + `reports/${token}/mapped-hotel-report`,
                filters,
                {
                    params: params,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem(
                            "token"
                        )}`,
                    },
                }
            );
            dispatch({
                type: GET_MAPPED_HOTEL_LIST_SUCCESS,
                payload: res.data.data,
                total: res.data.count,
            });
        } catch (e) {
            dispatch({
                type: GET_MAPPED_HOTEL_LIST_FAIL,
                payload: console.log("Error in actions ", e),
            });
        }
    };
export const getOpportunitiesHotelListReport =
    (token, page, perPage, filter, filters, sort_by, sort_order) =>
    async (dispatch) => {
        dispatch({
            type: GET_OPPORTUNITIES_HOTEL_LIST_LISTING,
        });
        try {
            var params = new URLSearchParams();
            params.append("offset", (page - 1) * perPage);
            params.append("limit", perPage);
            params.append("filter", filter);
            if (sort_by) {
                params.append("sort_by", sort_by);
            }
            if (sort_order) {
                params.append("sort_order", sort_order);
            }
            const res = await axios.post(
                PATH_URL + `reports/${token}/opportunities-hotel-report`,
                filters,
                {
                    params: params,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem(
                            "token"
                        )}`,
                    },
                }
            );
            dispatch({
                type: GET_OPPORTUNITIES_HOTEL_LIST_SUCCESS,
                payload: res.data.data,
                total: res.data.count,
            });
        } catch (e) {
            dispatch({
                type: GET_OPPORTUNITIES_HOTEL_LIST_FAIL,
                payload: console.log("Error in actions ", e),
            });
        }
    };
export const getInvalidHotelListReport =
    (token, page, perPage, filter, filters, sort_by, sort_order) =>
    async (dispatch) => {
        dispatch({
            type: GET_INVALID_HOTEL_LIST_LISTING,
        });
        try {
            var params = new URLSearchParams();
            params.append("offset", (page - 1) * perPage);
            params.append("limit", perPage);
            params.append("filter", filter);
            if (sort_by) {
                params.append("sort_by", sort_by);
            }
            if (sort_order) {
                params.append("sort_order", sort_order);
            }
            const res = await axios.post(
                PATH_URL + `reports/${token}/invalid-hotel-report`,
                filters,
                {
                    params: params,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem(
                            "token"
                        )}`,
                    },
                }
            );
            dispatch({
                type: GET_INVALID_HOTEL_LIST_SUCCESS,
                payload: res.data.data,
                total: res.data.count,
            });
        } catch (e) {
            dispatch({
                type: GET_INVALID_HOTEL_LIST_FAIL,
                payload: console.log("Error in actions ", e),
            });
        }
    };

export const getInventoryHotelCodeName = (token) => async (dispatch) => {
    dispatch({
        type: GET_INVENTORY_HOTEL_CODE_NAME_LISTING,
    });
    try {
        const res = await axios.get(
            PATH_URL + `reports/${token}/inventory-hotel-code-name`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }
        );
        dispatch({
            type: GET_INVENTORY_HOTEL_CODE_NAME_SUCCESS,
            payload: res.data.data,
        });
    } catch (e) {
        dispatch({
            type: GET_INVENTORY_HOTEL_CODE_NAME_FAIL,
            payload: console.log("Error in actions ", e),
        });
    }
};

export const getInventoryCities = (token) => async (dispatch) => {
    dispatch({
        type: GET_INVENTORY_CITIES_LISTING,
    });
    try {
        const res = await axios.get(
            PATH_URL + `reports/${token}/inventory-cities`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }
        );
        dispatch({
            type: GET_INVENTORY_CITIES_SUCCESS,
            payload: res.data.data,
        });
    } catch (e) {
        dispatch({
            type: GET_INVENTORY_CITIES_FAIL,
            payload: console.log("Error in actions ", e),
        });
    }
};

export const getInventoryCountries = (token) => async (dispatch) => {
    dispatch({
        type: GET_INVENTORY_COUNTRIES_LISTING,
    });
    try {
        const res = await axios.get(
            PATH_URL + `reports/${token}/inventory-countries`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }
        );
        dispatch({
            type: GET_INVENTORY_COUNTRIES_SUCCESS,
            payload: res.data.data,
        });
    } catch (e) {
        dispatch({
            type: GET_INVENTORY_COUNTRIES_FAIL,
            payload: console.log("Error in actions ", e),
        });
    }
};

export const getHeaderDetails = (token) => async (dispatch) => {
    dispatch({
        type: GET_HEADER_DETAILS_LISTING,
    });
    try {
        const res = await axios.get(
            PATH_URL + `reports/${token}/header-details`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }
        );
        dispatch({
            type: GET_HEADER_DETAILS_SUCCESS,
            payload: res.data,
        });
    } catch (e) {
        dispatch({
            type: GET_HEADER_DETAILS_FAIL,
            payload: console.log("Error in actions ", e),
        });
    }
};

export const getPropertiesUnmapped =
    (token, page, perPage, filter) => async (dispatch) => {
        dispatch({
            type: GET_PROPERTIES_UNMAPPED_LISTING,
        });
        try {
            const res = await axios.get(
                PATH_URL + `reports/${token}/properties-unmapped`,
                {
                    params: {
                        limit: perPage,
                        offset: (page - 1) * perPage,
                        filter: filter,
                    },

                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem(
                            "token"
                        )}`,
                    },
                }
            );
            dispatch({
                type: GET_PROPERTIES_UNMAPPED_SUCCESS,
                payload: res.data.data,
                total: res.data.count,
            });
        } catch (e) {
            dispatch({
                type: GET_PROPERTIES_UNMAPPED_FAIL,
                payload: console.log("Error in actions ", e),
            });
        }
    };

export const getMappingRateOverTime = (filter, token) => async (dispatch) => {
    dispatch({
        type: GET_MAPPING_RATE_OVER_TIME_LISTING,
    });
    try {
        const res = await axios.post(
            PATH_URL + `reports/${token}/mapping-rate-over-time`,
            filter,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }
        );
        dispatch({
            type: GET_MAPPING_RATE_OVER_TIME_SUCCESS,
            payload: res.data.data,
        });
    } catch (e) {
        dispatch({
            type: GET_MAPPING_RATE_OVER_TIME_FAIL,
            payload: console.log("Error in actions ", e),
        });
    }
};

export const getNumberMappingPerformed =
    (filter, token) => async (dispatch) => {
        dispatch({
            type: GET_NUMBER_MAPPING_PERFORMED_LISTING,
        });
        try {
            const res = await axios.post(
                PATH_URL + `reports/${token}/number-mapping-performed`,
                filter,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem(
                            "token"
                        )}`,
                    },
                }
            );
            dispatch({
                type: GET_NUMBER_MAPPING_PERFORMED_SUCCESS,
                payload: res.data?.data,
            });
        } catch (e) {
            dispatch({
                type: GET_NUMBER_MAPPING_PERFORMED_FAIL,
                payload: console.log("Error in actions ", e),
            });
        }
    };

export const getReportInfo = (token) => async (dispatch) => {
    dispatch({
        type: GET_REPORT_INFO_LISTING,
    });
    try {
        const res = await axios.get(PATH_URL + `reports/${token}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        dispatch({
            type: GET_REPORT_INFO_SUCCESS,
            payload: res.data?.data,
        });
    } catch (e) {
        dispatch({
            type: GET_REPORT_INFO_FAIL,
            payload: console.log("Error in actions ", e),
        });
    }
};

export const getComparableMappings = (token) => async (dispatch) => {
    dispatch({
        type: GET_COMPARABLE_MAPPINGS_LISTING,
    });
    try {
        const res = await axios.get(
            PATH_URL + `reports/${token}/comparable-mappings`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }
        );
        dispatch({
            type: GET_COMPARABLE_MAPPINGS_SUCCESS,
            payload: res.data?.data,
        });
    } catch (e) {
        dispatch({
            type: GET_COMPARABLE_MAPPINGS_FAIL,
            payload: console.log("Error in actions ", e),
        });
    }
};
export const getCompareMappingTotal =
    (token, selectedMappinId, filters) => async (dispatch) => {
        dispatch({
            type: GET_COMPARE_MAPPIING_TOTAL_LISTING,
        });
        try {
            var params = new URLSearchParams();
            if (filters) {
                Object.keys(filters)
                    ?.filter((f) => f !== "rank")
                    ?.forEach((e) => {
                        filters[e]?.forEach((c) => {
                            params.append(e, c?.label);
                        });
                    });
            }
            const res = await axios.get(
                PATH_URL +
                    `reports/${token}/compare/${selectedMappinId}/totals`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem(
                            "token"
                        )}`,
                    },
                    params: params,
                }
            );
            dispatch({
                type: GET_COMPARE_MAPPIING_TOTAL_SUCCESS,
                payload: res.data?.data,
            });
        } catch (e) {
            dispatch({
                type: GET_COMPARE_MAPPIING_TOTAL_FAIL,
                payload: console.log("Error in actions ", e),
            });
        }
    };
export const getComapareMapped =
    (
        token,
        selectedMappinId,
        page,
        perPage,
        filterText,
        filters,
        sort_by,
        sort_order
    ) =>
    async (dispatch) => {
        var params = new URLSearchParams();
        Object.keys(filters)
            ?.filter((f) => f !== "rank")
            ?.forEach((e) => {
                filters[e]?.forEach((c) => {
                    params.append(e, c?.label);
                });
            });
        params.append("offset", (page - 1) * perPage);
        params.append("limit", perPage);
        params.append("search", filterText);

        if (sort_by) {
            params.append("sort_by", sort_by);
        }
        if (sort_order) {
            params.append("sort_order", sort_order);
        }
        dispatch({
            type: GET_COMPARE_MAPPED_LISTING,
        });
        try {
            const res = await axios.get(
                PATH_URL +
                    `reports/${token}/compare/${selectedMappinId}/mapped_hotels`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem(
                            "token"
                        )}`,
                    },
                    params: params,
                }
            );
            dispatch({
                type: GET_COMPARE_MAPPED_SUCCESS,
                payload: res.data?.data,
            });
        } catch (e) {
            dispatch({
                type: GET_COMPARE_MAPPED_FAIL,
                payload: console.log("Error in actions ", e),
            });
        }
    };
export const getOldHotels =
    (
        token,
        selectedMappinId,
        page,
        perPage,
        filterText,
        filters,
        sort_by,
        sort_order
    ) =>
    async (dispatch) => {
        var params = new URLSearchParams();
        Object.keys(filters)
            ?.filter((f) => f !== "rank")
            ?.forEach((e) => {
                filters[e]?.forEach((c) => {
                    params.append(e, c?.label);
                });
            });
        params.append("offset", (page - 1) * perPage);
        params.append("limit", perPage);
        if (sort_by) {
            params.append("sort_by", sort_by);
        }
        if (sort_order) {
            params.append("sort_order", sort_order);
        }

        dispatch({
            type: GET_OLD_HOTELS_LISTING,
        });
        try {
            const res = await axios.get(
                PATH_URL +
                    `reports/${token}/compare/${selectedMappinId}/old_hotels`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem(
                            "token"
                        )}`,
                    },
                    params: params,
                }
            );
            dispatch({
                type: GET_OLD_HOTELS_SUCCESS,
                payload: res.data?.data,
            });
        } catch (e) {
            dispatch({
                type: GET_OLD_HOTELS_FAIL,
                payload: console.log("Error in actions ", e),
            });
        }
    };

export const getComapareUnmapped =
    (
        token,
        selectedMappinId,
        page,
        perPage,
        filterText,
        filters,
        sort_by,
        sort_order
    ) =>
    async (dispatch) => {
        var params = new URLSearchParams();

        Object.keys(filters)
            ?.filter((f) => f !== "rank")
            ?.forEach((e) => {
                filters[e]?.forEach((c) => {
                    params.append(e, c?.label);
                });
            });
        params.append("offset", (page - 1) * perPage);
        params.append("limit", perPage);
        params.append("search", filterText);
        if (sort_by) {
            params.append("sort_by", sort_by);
        }
        if (sort_order) {
            params.append("sort_order", sort_order);
        }

        dispatch({
            type: GET_COMPARE_UNMAPPED_LISTING,
        });
        try {
            const res = await axios.get(
                PATH_URL +
                    `reports/${token}/compare/${selectedMappinId}/unmapped_hotels`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem(
                            "token"
                        )}`,
                    },
                    params: params,
                }
            );
            dispatch({
                type: GET_COMPARE_UNMAPPED_SUCCESS,
                payload: res.data?.data,
            });
        } catch (e) {
            dispatch({
                type: GET_COMPARE_UNMAPPED_FAIL,
                payload: console.log("Error in actions ", e),
            });
        }
    };

export const getSearchFields =
    (token, selectedMappinId) => async (dispatch) => {
        dispatch({
            type: GET_SEARCH_FIELDS_LISTING,
        });
        try {
            const res = await axios.get(
                PATH_URL +
                    `reports/${token}/compare/${selectedMappinId}/filters`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem(
                            "token"
                        )}`,
                    },
                }
            );
            dispatch({
                type: GET_SEARCH_FIELDS_SUCCESS,
                payload: res.data?.data,
            });
        } catch (e) {
            dispatch({
                type: GET_SEARCH_FIELDS_FAIL,
                payload: console.log("Error in actions ", e),
            });
        }
    };
