import React, { useState } from "react";
import Stepper from "react-stepper-horizontal";
import { toast } from "react-toastify";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import { Modal } from "react-bootstrap";
import TravelgateCredentials from "./TravelgateCredentials";
import { TRAVEL_GATE_FORM } from "../../../../utilis/const";

function ConnectorChoise(props) {
    const { handleClose, setProgressPourcentage, setFirstProccessing } = props;
    const [hideBackButton, setHideBackButton] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedConnector, setSelectedConnector] = useState(null);
    const [cridentialsId, setCridentialsId] = useState(null);
    const [name, setName] = useState("");
    const [formData, setFormData] = React.useState({});
    const [selectedMethod, setSelectedMethod] = useState("");
    const [isTravelgateHub, setIsTravelgateHub] = useState(false);
    const [travelgateCredValues, setTravelgateCredValues] = useState({});
    const [connectorForm, setConnectorForm] = useState({});
    const [connectorUiSchema, setConnectorUiSchema] = useState({});
    const [files, setFiles] = useState([]);
    const [selectTravelgateConfig, setSelectTravelgateConfig] =
        useState(TRAVEL_GATE_FORM);
    const [updateInventoryDaily, setUpdateInventoryDaily] = useState(false);
    const [showForm, setShowForm] = useState(false);

    const sections = React.useMemo(() => {
        const required = [
            {
                title: "Catalog name ",
            },

            {
                title: "Select type",
            },
        ];
        let extra = [];
        if (isTravelgateHub === true) {
            extra.push({
                title: "Travelgate Credentials",
            });
        }

        return [
            ...required,
            ...extra,
            {
                title: "Credentials",
            },
        ];
    }, [isTravelgateHub]);

    const next = async () => {
        if (currentPage === 1 && name === "") {
            toast.error("Catalog name is required to pass", {
                toastId: "name-catalog-csv-step1",
            });
        } else if (currentPage === 2 && selectedConnector === null) {
            toast.error("Please select a connector", {
                toastId: "inventory-catalog-connector-step2",
            });
        } else {
            setCurrentPage((prev) => prev + 1);
        }
    };

    const prev = () => setCurrentPage((prev) => prev - 1);
    const renderSteps = () => {
        switch (currentPage) {
            case 1:
                return (
                    <Step1
                        next={next}
                        name={name}
                        setName={setName}
                        handleClose={handleClose}
                    />
                );

            case 2:
                return (
                    <Step2
                        selectedConnector={selectedConnector}
                        setSelectedConnector={setSelectedConnector}
                        next={next}
                        prev={prev}
                        setCridentialsId={setCridentialsId}
                        setSelectedMethod={setSelectedMethod}
                        setIsTravelgateHub={setIsTravelgateHub}
                        handleClose={handleClose}
                        setConnectorForm={setConnectorForm}
                        setConnectorUiSchema={setConnectorUiSchema}
                        setFormData={setFormData}
                        setSelectTravelgateConfig={setSelectTravelgateConfig}
                        setShowForm={setShowForm}
                    />
                );

            case 3:
                return (
                    <Step3
                        selectedConnector={selectedConnector}
                        prev={prev}
                        handleClose={handleClose}
                        name={name}
                        formData={formData}
                        setFormData={setFormData}
                        setHideBackButton={setHideBackButton}
                        hideBackButton={hideBackButton}
                        uiSchema={connectorUiSchema}
                        form={connectorForm}
                        setProgressPourcentage={setProgressPourcentage}
                        setFirstProccessing={setFirstProccessing}
                        isTravelgateHub={isTravelgateHub}
                        files={files}
                        setFiles={setFiles}
                        setSelectTravelgateConfig={setSelectTravelgateConfig}
                        selectTravelgateConfig={selectTravelgateConfig}
                        updateInventoryDaily={updateInventoryDaily}
                        setUpdateInventoryDaily={setUpdateInventoryDaily}
                        showForm={showForm}
                    />
                );
            default:
                return <></>;
        }
    };
    const renderStepsWithTravelGateCredentials = () => {
        switch (currentPage) {
            case 1:
                return (
                    <Step1
                        next={next}
                        name={name}
                        setName={setName}
                        handleClose={handleClose}
                    />
                );

            case 2:
                return (
                    <Step2
                        selectedConnector={selectedConnector}
                        setSelectedConnector={setSelectedConnector}
                        next={next}
                        prev={prev}
                        setCridentialsId={setCridentialsId}
                        setSelectedMethod={setSelectedMethod}
                        setIsTravelgateHub={setIsTravelgateHub}
                        handleClose={handleClose}
                        setConnectorForm={setConnectorForm}
                        setConnectorUiSchema={setConnectorUiSchema}
                        setFormData={setFormData}
                        setSelectTravelgateConfig={setSelectTravelgateConfig}
                        setShowForm={setShowForm}
                    />
                );
            case 3:
                return (
                    <TravelgateCredentials
                        next={next}
                        prev={prev}
                        travelgateCredValues={travelgateCredValues}
                        setTravelgateCredValues={setTravelgateCredValues}
                        handleClose={handleClose}
                    />
                );
            case 4:
                return (
                    <Step3
                        selectedConnector={selectedConnector}
                        prev={prev}
                        handleClose={handleClose}
                        name={name}
                        formData={formData}
                        setFormData={setFormData}
                        setHideBackButton={setHideBackButton}
                        hideBackButton={hideBackButton}
                        uiSchema={connectorUiSchema}
                        form={connectorForm}
                        setProgressPourcentage={setProgressPourcentage}
                        setFirstProccessing={setFirstProccessing}
                        isTravelgateHub={isTravelgateHub}
                        files={files}
                        setFiles={setFiles}
                        setSelectTravelgateConfig={setSelectTravelgateConfig}
                        selectTravelgateConfig={selectTravelgateConfig}
                        updateInventoryDaily={updateInventoryDaily}
                        setUpdateInventoryDaily={setUpdateInventoryDaily}
                        showForm={showForm}
                    />
                );
            default:
                return <></>;
        }
    };

    return (
        <Modal.Body style={{ paddingBottom: "0px" }}>
            <div className="card-header p-0 pb-2">
                <Stepper
                    steps={sections}
                    activeStep={currentPage}
                    activeColor="#D3D7DB"
                    defaultBarColor="#D3D7DB"
                    completeColor="#6076E8"
                    completeBarColor="#6076E8"
                    fontFamily="Roboto"
                    textColor="#fff"
                    defaultBorderStyle="solid 6px"
                    defaultBorderWidth={10}
                />
            </div>
            <div className="card-body">
                {isTravelgateHub
                    ? renderStepsWithTravelGateCredentials()
                    : renderSteps()}
            </div>
        </Modal.Body>
    );
}
export default ConnectorChoise;
