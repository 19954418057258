import {
    GET_SUPPLIER_OUTDATED_SUCCESS,
    GET_SUPPLIER_OUTDATED_FAIL,
    GET_SUPPLIER_OUTDATED_LISTING,
    GET_MAPPING_NUMBER_SUCCESS,
    GET_MAPPING_NUMBER_FAIL,
    GET_MAPPING_NUMBER_LISTING,
    GET_SUPPLIER_EXCLUSIVE_CONTENT_SUCCESS,
    GET_SUPPLIER_EXCLUSIVE_CONTENT_LISTING,
    GET_SUPPLIER_EXCLUSIVE_CONTENT_FAIL,
    GET_TOP_SUPPLIER_SUCCESS,
    GET_TOP_SUPPLIER_LISTING,
    GET_TOP_SUPPLIER_FAIL,
    GET_INVENTORY_COUNT_SUCCESS,
    GET_INVENTORY_COUNT_LISTING,
    GET_INVENTORY_COUNT_FAIL,
    GET_INVENTORY_UNMAPPED_SUCCESS,
    GET_INVENTORY_UNMAPPED_LISTING,
    GET_INVENTORY_UNMAPPED_FAIL,
    TIME_SERIE_MAPPED_RATE_LISTING,
    TIME_SERIE_MAPPED_RATE_SUCCESS,
    TIME_SERIE_MAPPED_RATE_FAIL,
    TIME_SERIE_INVENTORY_MAPPED_RATE_LISTING,
    TIME_SERIE_INVENTORY_MAPPED_RATE_SUCCESS,
    TIME_SERIE_INVENTORY_MAPPED_RATE_FAIL,
    GET_COMPLETED_STEPS_LISTING,
    GET_COMPLETED_STEPS_SUCCESS,
    GET_COMPLETED_STEPS_FAIL,
} from "../types";
import axios from "axios";

const PATH_URL = process.env.REACT_APP_BASE_URL;

export const getMappingNumber = (filter) => async (dispatch) => {
    dispatch({
        type: GET_MAPPING_NUMBER_LISTING,
    });
    try {
        const res = await axios.post(
            PATH_URL + "/partner-catalogs/mapping-number",
            filter,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }
        );
        dispatch({
            type: GET_MAPPING_NUMBER_SUCCESS,
            payload: res.data?.data,
        });
    } catch (e) {
        dispatch({
            type: GET_MAPPING_NUMBER_FAIL,
            payload: console.log("Error in actions ", e),
        });
    }
};

export const getSupplierOutdated = (days) => async (dispatch) => {
    dispatch({
        type: GET_SUPPLIER_OUTDATED_LISTING,
    });
    try {
        const res = await axios.get(
            PATH_URL + "/partner-catalogs/suppliers-outdated",
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                params: {
                    days: days,
                },
            }
        );
        dispatch({
            type: GET_SUPPLIER_OUTDATED_SUCCESS,
            payload: res.data.data,
        });
    } catch (e) {
        dispatch({
            type: GET_SUPPLIER_OUTDATED_FAIL,
            payload: console.log("Error in actions ", e),
        });
    }
};
export const getExclusiveContent = (days) => async (dispatch) => {
    dispatch({
        type: GET_SUPPLIER_EXCLUSIVE_CONTENT_LISTING,
    });
    try {
        const res = await axios.get(
            PATH_URL + "/partner-catalogs/suppliers-exclusive-content",
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }
        );
        dispatch({
            type: GET_SUPPLIER_EXCLUSIVE_CONTENT_SUCCESS,
            payload: res.data.data,
        });
    } catch (e) {
        dispatch({
            type: GET_SUPPLIER_EXCLUSIVE_CONTENT_FAIL,
            payload: console.log("Error in actions ", e),
        });
    }
};
export const getTopSupplier = () => async (dispatch) => {
    dispatch({
        type: GET_TOP_SUPPLIER_LISTING,
    });
    try {
        const res = await axios.get(
            PATH_URL + "/partner-catalogs/top-suppliers",
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }
        );
        dispatch({
            type: GET_TOP_SUPPLIER_SUCCESS,
            payload: res.data.data,
        });
    } catch (e) {
        dispatch({
            type: GET_TOP_SUPPLIER_FAIL,
            payload: console.log("Error in actions ", e),
        });
    }
};

export const getListOfInventoryWithCount =
    (page, perPage, filter, sort_by, sort_order) => async (dispatch) => {
        dispatch({
            type: GET_INVENTORY_COUNT_LISTING,
        });
        try {
            var params = new URLSearchParams();
            params.append("limit", perPage);
            params.append("offset", (page - 1) * perPage);
            params.append("filter", filter);
            if (sort_by) {
                params.append("sort_by", sort_by);
            }
            if (sort_order) {
                params.append("sort_order", sort_order);
            }

            const res = await axios.get(
                PATH_URL + "/hotel-catalogs/mapped-suppliers-count",
                {
                    params: params,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem(
                            "token"
                        )}`,
                    },
                }
            );
            dispatch({
                type: GET_INVENTORY_COUNT_SUCCESS,
                payload: res.data.data,
                total: res.data.total,
            });
        } catch (e) {
            dispatch({
                type: GET_INVENTORY_COUNT_FAIL,
                payload: console.log("Error in actions ", e),
            });
        }
    };

export const getInventoryUnmapped =
    (page, perPage, filter, sort_by, sort_order) => async (dispatch) => {
        dispatch({
            type: GET_INVENTORY_UNMAPPED_LISTING,
        });
        try {
            var params = new URLSearchParams();
            params.append("limit", perPage);
            params.append("offset", (page - 1) * perPage);
            params.append("filter", filter);
            if (sort_by) {
                params.append("sort_by", sort_by);
            }
            if (sort_order) {
                params.append("sort_order", sort_order);
            }
            const res = await axios.get(
                PATH_URL + "/hotel-catalogs/inventory-unmapped",
                {
                    params: params,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem(
                            "token"
                        )}`,
                    },
                }
            );
            dispatch({
                type: GET_INVENTORY_UNMAPPED_SUCCESS,
                payload: res.data.data,
                total: res.data.total,
            });
        } catch (e) {
            dispatch({
                type: GET_INVENTORY_UNMAPPED_FAIL,
                payload: console.log("Error in actions ", e),
            });
        }
    };
export const getTimeserieMappedRate = (filter) => async (dispatch) => {
    dispatch({
        type: TIME_SERIE_MAPPED_RATE_LISTING,
    });
    try {
        const res = await axios.post(
            PATH_URL + "/partner-catalogs/timeserie-of-mappedRate",
            filter,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }
        );
        dispatch({
            type: TIME_SERIE_MAPPED_RATE_SUCCESS,
            payload: res.data.data,
        });
    } catch (e) {
        dispatch({
            type: TIME_SERIE_MAPPED_RATE_FAIL,
            payload: console.log("Error in actions ", e),
        });
    }
};
export const getTimeserieInventoryMappedRate = (filter) => async (dispatch) => {
    dispatch({
        type: TIME_SERIE_INVENTORY_MAPPED_RATE_LISTING,
    });
    try {
        const res = await axios.post(
            PATH_URL + "/hotel-catalogs/timeseries-ofInventory-mappedRate",
            filter,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }
        );
        dispatch({
            type: TIME_SERIE_INVENTORY_MAPPED_RATE_SUCCESS,
            payload: res.data.data,
        });
    } catch (e) {
        dispatch({
            type: TIME_SERIE_INVENTORY_MAPPED_RATE_FAIL,
            payload: console.log("Error in actions ", e),
        });
    }
};
export const getCompletedSteps = () => async (dispatch) => {
    dispatch({ type: GET_COMPLETED_STEPS_LISTING });
    try {
        const res = await axios.get(PATH_URL + "/companies/completed-steps", {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        dispatch({ type: GET_COMPLETED_STEPS_SUCCESS, payload: res.data.data });
    } catch (e) {
        dispatch({ type: GET_COMPLETED_STEPS_FAIL });
    }
};
