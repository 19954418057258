import React, { useState } from "react";
import Table from "../../../components/Table/TablePaginationFromApi";
import { useSelector, useDispatch } from "react-redux";
import { getComapareUnmapped } from "../../../redux/actions/PartnerReportAction";

const TotalUnmappedList = (props) => {
    const dispatch = useDispatch();
    const { token, selectedMapping, filters } = props;
    const [perPage, setPerPage] = useState(10);
    const [filterText, setFilterText] = useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [column, setColumn] = useState();
    const [sortDirection, setSortDirection] = useState();
    const { compareUnmapped, loadingCompareUnmapped, total } = useSelector(
        (state) => ({
            compareUnmapped:
                state.partnerReportReducer.compareUnmapped?.hotels ?? [],
            total: state.partnerReportReducer.compareUnmapped?.total ?? 0,
            loadingCompareUnmapped:
                state.partnerReportReducer.loadingCompareUnmapped,
        })
    );

    const columns = [
        {
            id: "hotel_code",
            name: <div className="table-title">Partner Hotel Code</div>,
            cell: (e) => {
                return <>{e?.hotel_code}</>;
            },
            sortable: true,
        },
        {
            id: "hotel_name",
            name: <div className="table-title">Name</div>,
            cell: (e) => {
                return <>{e?.hotel_name}</>;
            },
            sortable: true,
        },

        {
            id: "city",
            name: <div className="table-title">City</div>,
            cell: (e) => {
                return <>{e?.city}</>;
            },
            sortable: true,
        },
        {
            id: "country",
            name: <div className="table-title">Country</div>,
            cell: (e) => {
                return <>{e?.country}</>;
            },
            sortable: true,
        },
        {
            id: "address",
            name: <div className="table-title">Address</div>,
            cell: (e) => {
                return <>{e?.address}</>;
            },
            sortable: true,
        },
    ];

    const handlePageChange = (page) => {
        dispatch(
            getComapareUnmapped(
                token,
                selectedMapping?.value,
                page,
                perPage,
                filterText,
                filters,
                column,
                sortDirection
            )
        );
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        dispatch(
            getComapareUnmapped(
                token,
                selectedMapping?.value,
                page,
                newPerPage,
                filterText,
                filters,
                column,
                sortDirection
            )
        );
        setPerPage(newPerPage);
    };

    const handleSort = async (c, sortD) => {
        dispatch(
            getComapareUnmapped(
                token,
                selectedMapping?.value,
                1,
                10,
                filterText,
                filters,
                c?.id,
                sortD
            )
        );
        setColumn(c?.id);
        setSortDirection(sortD);
    };

    return (
        <div style={{ marginTop: "15px" }}>
            <Table
                data={compareUnmapped}
                columns={columns}
                totalRows={total}
                handlePageChange={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                progressPending={loadingCompareUnmapped}
                onFilter={() => {
                    setResetPaginationToggle(true);
                    dispatch(
                        getComapareUnmapped(
                            token,
                            selectedMapping?.value,
                            1,
                            perPage,
                            filterText,
                            filters
                        )
                    );
                }}
                filterValue={filterText}
                handleClearFilter={() => {
                    setFilterText("");
                    setResetPaginationToggle(true);
                    dispatch(
                        getComapareUnmapped(token, 1, perPage, "", filters)
                    );
                }}
                onChange={(e) => {
                    setFilterText(e.target.value);
                    if (e.target.value === "") {
                        setResetPaginationToggle(true);
                        dispatch(
                            getComapareUnmapped(
                                token,
                                selectedMapping?.value,
                                1,
                                perPage,
                                "",
                                filters
                            )
                        );
                    }
                }}
                filter={true}
                resetPaginationToggle={resetPaginationToggle}
                onSort={handleSort}
                sortServer={true}
            />
        </div>
    );
};

export default TotalUnmappedList;
