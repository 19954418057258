import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import SearchForm from "../../components/PartnerReports/SearchForm";
import CountryList from "../../components/PartnerReports/CountryList";
import ChainList from "../../components/PartnerReports/ChainList";
import MappedHotelList from "../../components/PartnerReports/MappedHotelList";
import PropertyTypeList from "../../components/PartnerReports/PropertyTypeList";
import OpportunitiesHotelList from "../../components/PartnerReports/OpportunitiesHotelList";
import InvalidHotelList from "../../components/PartnerReports/InvalidHotelList";
import CityChart from "../../components/PartnerReports/CityChart";
import CityList from "../../components/PartnerReports/CityList";
import CountryChart from "../../components/PartnerReports/CountryChart";
import Header from "./Header";
import useQuery from "../../hooks/useQuery";
import { useDispatch, useSelector } from "react-redux";
import {
    getCountryReport,
    getCityReport,
    getChainReport,
    getPropertyTypeReport,
    getInventoryCountries,
    getHeaderDetails,
    getChainReportList,
    getPropertyTypeReportList,
    getReportInfo,
    getComparableMappings,
} from "../../redux/actions/PartnerReportAction";
import SyncLoader from "react-spinners/SyncLoader";
import GlobalData from "../../components/PartnerReports/GlobalData";

import MappingRateOverTime from "../../components/PartnerReports/MappingRateOverTime";
import NumberMappingPerformed from "../../components/PartnerReports/NumberMappingPerformed";
import Comparison from "../../components/PartnerReports/Comparison/index";
import ShareMappingReport from "../Mapping/ShareMappingReportModal";

function PartnerReport() {
    const [currentTab, setCurrentTab] = useState("Mapping Report");
    const [showShareReport, setShowShareReport] = useState();
    const [selectedMapping, setSelectedMapping] = useState();
    const [filters, setFilters] = useState({
        hotel_id: [],
        cities: [],
        countries: [],
        hotel_name: [],
        partner_hotel_id: [],
        chains: [],
        propertyType: [],
        stars: [],
    });
    const dispatch = useDispatch();
    const query = useQuery();
    const token = query.get("token_mapping_report");
    const {
        loadingCities,
        loadingCountries,
        loadingHeaderData,
        reportInfo,
        loadingReportInfo,
    } = useSelector((state) => ({
        loadingCities: state.partnerReportReducer.loadingCities,
        loadingCountries: state.partnerReportReducer.loadingCountries,
        loadingHeaderData: state.partnerReportReducer.loadingHeaderData,
        reportInfo: state.partnerReportReducer.reportInfo,
        loadingReportInfo: state.partnerReportReducer.loadingReportInfo,
    }));
    useEffect(() => {
        dispatch(getReportInfo(token));
        dispatch(getCountryReport(token, filters));
        dispatch(getCityReport(token, filters));
        dispatch(getChainReportList(token));
        dispatch(getChainReport(token, filters));
        dispatch(getPropertyTypeReport(token, filters));
        dispatch(getPropertyTypeReportList(token));
        dispatch(getInventoryCountries(token));
        dispatch(getHeaderDetails(token));
        dispatch(getComparableMappings(token));
    }, []);

    return (
        <>
                   <Header
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        isAffiliate={reportInfo?.is_affiliate}
                        reportInfo={reportInfo}
                    />
                    <Helmet>
                        <title>{`Mapping Report ${reportInfo?.company_name} and ${reportInfo?.partner_name}`}</title>
                    </Helmet>

            {loadingReportInfo ? (
                <div className="text-center">
                    <div className="lds-ripple">
                        <div className="lds-pos"></div>
                        <div className="lds-pos"></div>
                    </div>
                </div>
            ) : (
                <>
                    <div className="page-breadcrumb ">
                        <div className="container-fluid">
                            <div className="row partner-report">
                                <div
                                    className="col-12 align-self-center"
                                    style={{
                                        marginBottom: "30px",
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
                                        {`Mapping Report ${reportInfo?.company_name} and ${reportInfo?.partner_name}`}
                                    </h3>
                                    <button
                                        className="btn btn-primary"
                                        style={{
                                            borderRadius: "9px",
                                        }}
                                        onClick={() => {
                                            setShowShareReport(true);
                                        }}
                                    >
                                        <i
                                            className="fas fa-share-alt"
                                            style={{
                                                marginRight: "4px",
                                            }}
                                        ></i>
                                        Share mapping report
                                    </button>
                                </div>
                                {currentTab === "Mapping Report" ? (
                                    <>
                                        {loadingHeaderData ? (
                                            <div className="text-center">
                                                <div className="lds-ripple">
                                                    <div className="lds-pos"></div>
                                                    <div className="lds-pos"></div>
                                                </div>
                                            </div>
                                        ) : (
                                            <>
                                                <div className="col-lg-12 col-md-12">
                                                    <GlobalData token={token} />
                                                </div>
                                                <div className="col-lg-12 col-md-12">
                                                    <SearchForm
                                                        token={token}
                                                        filters={filters}
                                                        setFilters={setFilters}
                                                    />
                                                </div>
                                                <div className="col-lg-6">
                                                    <div
                                                        className="card"
                                                        style={{
                                                            marginTop: "15px",
                                                        }}
                                                    >
                                                        <div className="card-header">
                                                            <h4
                                                                className="card-title"
                                                                style={{
                                                                    marginBottom:
                                                                        "0px",
                                                                    paddingLeft:
                                                                        "15px",
                                                                }}
                                                            >
                                                                <i className="fas fa-globe mr-3"></i>
                                                                Country
                                                            </h4>
                                                        </div>
                                                        <div className="col-lg-12 col-md-12 row">
                                                            {loadingCountries ? (
                                                                <div className="col-lg-12 col-md-12">
                                                                    <div
                                                                        style={{
                                                                            display:
                                                                                "flex",
                                                                            justifyContent:
                                                                                "center",
                                                                            padding:
                                                                                "20px",
                                                                        }}
                                                                    >
                                                                        <SyncLoader
                                                                            color="#5856d694"
                                                                            loading={
                                                                                true
                                                                            }
                                                                            size={
                                                                                10
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    <div className="col-lg-7 col-md-12">
                                                                        <CountryList
                                                                            token={
                                                                                token
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="col-lg-5 col-md-12">
                                                                        <CountryChart
                                                                            token={
                                                                                token
                                                                            }
                                                                        />
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div
                                                        className="card"
                                                        style={{
                                                            marginTop: "15px",
                                                        }}
                                                    >
                                                        <div className="card-header">
                                                            <h4
                                                                className="card-title"
                                                                style={{
                                                                    marginBottom:
                                                                        "0px",
                                                                    paddingLeft:
                                                                        "15px",
                                                                }}
                                                            >
                                                                <i className="fas fa-globe mr-3"></i>
                                                                City
                                                            </h4>
                                                        </div>
                                                        <div className="col-lg-12 col-md-12 row">
                                                            {loadingCities ? (
                                                                <div className="col-lg-12 col-md-12">
                                                                    <div
                                                                        style={{
                                                                            display:
                                                                                "flex",
                                                                            justifyContent:
                                                                                "center",
                                                                            padding:
                                                                                "20px",
                                                                        }}
                                                                    >
                                                                        <SyncLoader
                                                                            color="#5856d694"
                                                                            loading={
                                                                                true
                                                                            }
                                                                            size={
                                                                                10
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    <div className="col-lg-7 col-md-12">
                                                                        <CityList
                                                                            token={
                                                                                token
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="col-lg-5 col-md-12">
                                                                        <CityChart
                                                                            token={
                                                                                token
                                                                            }
                                                                        />
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div
                                                        className="card"
                                                        style={{
                                                            marginTop: "15px",
                                                            minHeight: "408px",
                                                        }}
                                                    >
                                                        <div className="card-header">
                                                            <h4
                                                                className="card-title"
                                                                style={{
                                                                    marginBottom:
                                                                        "0px",
                                                                    paddingLeft:
                                                                        "15px",
                                                                }}
                                                            >
                                                                <i className="fas fa-list mr-3"></i>
                                                                Chain
                                                            </h4>
                                                        </div>
                                                        <div className="col-lg-12 col-md-12">
                                                            <ChainList
                                                                token={token}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div
                                                        className="card"
                                                        style={{
                                                            marginTop: "15px",
                                                            minHeight: "408px",
                                                        }}
                                                    >
                                                        <div className="card-header">
                                                            <h4
                                                                className="card-title"
                                                                style={{
                                                                    marginBottom:
                                                                        "0px",
                                                                    paddingLeft:
                                                                        "15px",
                                                                }}
                                                            >
                                                                <i className="fas fa-list mr-3"></i>
                                                                Property Type
                                                            </h4>
                                                        </div>
                                                        <div className="col-lg-12 col-md-12">
                                                            <PropertyTypeList
                                                                token={token}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="card"
                                                    style={{
                                                        width: "100%",
                                                        marginTop: "15px",
                                                    }}
                                                >
                                                    <div className="card-header">
                                                        <h4
                                                            className="card-title"
                                                            style={{
                                                                marginBottom:
                                                                    "0px",
                                                                paddingLeft:
                                                                    "15px",
                                                            }}
                                                        >
                                                            <i className="fas fa-list mr-3"></i>
                                                            Mapped Hotel List
                                                        </h4>
                                                    </div>
                                                    <div className="col-lg-12 col-md-12">
                                                        <MappedHotelList
                                                            token={token}
                                                            filters={filters}
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    className="card"
                                                    style={{
                                                        width: "100%",
                                                        marginTop: "15px",
                                                    }}
                                                >
                                                    <div className="card-header">
                                                        <h4
                                                            className="card-title"
                                                            style={{
                                                                marginBottom:
                                                                    "0px",
                                                                paddingLeft:
                                                                    "15px",
                                                            }}
                                                        >
                                                            <i className="fas fa-list mr-3"></i>
                                                            Opportunities Hotel
                                                            List
                                                        </h4>
                                                    </div>
                                                    <div className="col-lg-12 col-md-12">
                                                        <OpportunitiesHotelList
                                                            token={token}
                                                            filters={filters}
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    className="card"
                                                    style={{
                                                        width: "100%",
                                                        marginTop: "15px",
                                                    }}
                                                >
                                                    <div className="card-header">
                                                        <h4
                                                            className="card-title"
                                                            style={{
                                                                marginBottom:
                                                                    "0px",
                                                                paddingLeft:
                                                                    "15px",
                                                            }}
                                                        >
                                                            <i className="fas fa-list mr-3"></i>
                                                            Invalid Hotel List
                                                        </h4>
                                                    </div>
                                                    <div className="col-lg-12 col-md-12">
                                                        <InvalidHotelList
                                                            token={token}
                                                            filters={filters}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </>
                                ) : currentTab === "activity" ? (
                                    <>
                                        <div className="col-lg-6 col-md-6">
                                            <NumberMappingPerformed
                                                token={token}
                                            />
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <MappingRateOverTime
                                                token={token}
                                            />
                                        </div>
                                    </>
                                ) : currentTab === "comparison" ? (
                                    <>
                                        {/* {selectedMapping ? ( */}
                                        <>
                                            <Comparison
                                                selectedMapping={
                                                    selectedMapping
                                                }
                                                token={token}
                                                setSelectedMapping={
                                                    setSelectedMapping
                                                }
                                            />
                                        </>
                                        {/* ) : (
                                            <div
                                                className="col-lg-12 col-md-12"
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <MappingsComparable
                                                    selectedMapping={
                                                        selectedMapping
                                                    }
                                                    setSelectedMapping={
                                                        setSelectedMapping
                                                    }
                                                />
                                            </div>
                                        )} */}
                                    </>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            )}

            {showShareReport && (
                <ShareMappingReport
                    show={showShareReport}
                    handleClose={() => {
                        setShowShareReport(false);
                    }}
                    token={token}
                />
            )}
        </>
    );
}
export default PartnerReport;
