import React from "react";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import {
    PUBLIC_CONNECTOR,
    DIRECT_CONNECTOR,
    HUB_CONNECTOR,
    TRAVEL_GATE_ID,
    TRAVEL_GATE_FORM,
} from "../../../utilis/const";

function Step2(props) {
    const {
        selectedPartner,
        selectedConnector,
        setSelectedConnector,
        handleClick,
        setCurrentPage,
        setConnectorType,
        prev,
        setIsTravelgateHub,
        handleClose,
        setSelectTravelgateConfig,
        setFormData,
        setFiles,
    } = props;
    const { directConnector, hubConnectors, connectors } = useSelector(
        (state) => ({
            connectors: state?.connectorReducer?.listConnectors ?? [],
            directConnector: state.connectorReducer.listConnectors?.find(
                (e) =>
                    parseInt(e?.id, 10) ===
                    parseInt(selectedPartner?.connector_id, 10)
            ),
            hubConnectors: state.connectorReducer.listConnectors?.filter(
                (e) => e?.is_direct === false
            ),
        })
    );

    const hubConnectorsElements = hubConnectors?.map((e) => {
        return (
            <div className="col-6 col-md-4 col-lg-3">
                <div
                    className="integration-entity mx-auto"
                    style={{ marginBottom: "0px" }}
                >
                    <div
                        className="integration-entity-img-wrapper-connector"
                        style={{
                            transform:
                                selectedConnector?.id === e?.id
                                    ? "scale(1.2)"
                                    : "",
                        }}
                    >
                        <img
                            id={e.id}
                            className="cloudinary-image ls-is-cached lazyloaded round-img"
                            alt={e?.name}
                            src={e.logo}
                            width="100%"
                            height="100"
                            onClick={() => {
                                setSelectedConnector(e);
                                setConnectorType(HUB_CONNECTOR);
                                setFormData({});
                                setSelectTravelgateConfig(TRAVEL_GATE_FORM);
                                setFiles([]);
                                if (e?.id === TRAVEL_GATE_ID) {
                                    setIsTravelgateHub(true);
                                } else {
                                    setIsTravelgateHub(false);
                                }
                            }}
                            style={{
                                border:
                                    selectedConnector?.id === e?.id
                                        ? "2px solid rgb(96, 118, 232)"
                                        : "",
                            }}
                        />
                    </div>
                    <div className="integration-entity-name">{e?.name}</div>
                </div>
            </div>
        );
    });
    const directConnectorsElements = () => {
        return (
            <div className="col-12 col-md-12 col-lg-12">
                <div
                    className="integration-entity mx-auto"
                    style={{ marginBottom: "0px" }}
                >
                    <div
                        className="integration-entity-img-wrapper-connector"
                        style={{
                            transform:
                                selectedConnector?.id === directConnector?.id
                                    ? "scale(1.2)"
                                    : "",
                        }}
                    >
                        <img
                            id={directConnector?.id}
                            className="cloudinary-image ls-is-cached lazyloaded round-img"
                            alt={directConnector?.name}
                            src={directConnector?.logo}
                            width="100%"
                            height="100"
                            style={{
                                border:
                                    selectedConnector?.id ===
                                    directConnector?.id
                                        ? "2px solid rgb(96, 118, 232)"
                                        : "",
                            }}
                            onClick={() => {
                                setSelectedConnector(directConnector);
                                setConnectorType(DIRECT_CONNECTOR);
                                setIsTravelgateHub(false);
                                setFormData({});
                                setSelectTravelgateConfig(TRAVEL_GATE_FORM);
                                setFiles([]);
                            }}
                        />
                    </div>
                    <div className="integration-entity-name">
                        {directConnector?.name}
                    </div>
                </div>
            </div>
        );
    };
    const publicConnectorsElements = () => {
        return (
            <div className="col-12 col-md-12 col-lg-12">
                <div
                    className="integration-entity mx-auto"
                    style={{ marginBottom: "0px" }}
                >
                    <div
                        className="integration-entity-img-wrapper-connector"
                        style={{
                            transform:
                                selectedConnector?.id === selectedPartner?.id
                                    ? "scale(1.2)"
                                    : "",
                        }}
                    >
                        <img
                            id={selectedPartner.connector_id}
                            className="cloudinary-image ls-is-cached lazyloaded round-img"
                            alt={selectedPartner?.name}
                            src={selectedPartner?.public_content_logo}
                            width="100%"
                            height="100"
                            onClick={() => {
                                setSelectedConnector({
                                    ...selectedPartner,
                                    id: selectedPartner.connector_id,
                                });
                                setConnectorType(PUBLIC_CONNECTOR);
                                setIsTravelgateHub(false);
                                setFormData({});
                                setSelectTravelgateConfig(TRAVEL_GATE_FORM);
                                setFiles([]);
                            }}
                            style={{
                                border:
                                    selectedConnector?.id ===
                                    selectedPartner?.connector_id
                                        ? "2px solid rgb(96, 118, 232)"
                                        : "",
                            }}
                        />
                    </div>
                    <div className="integration-entity-name">
                        {selectedPartner?.name}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="mt-3">
            <div className="row">
                {selectedPartner?.is_direct === 1 && (
                    <div className="col-md-6">
                        <fieldset className="scheduler-border">
                            <legend className="scheduler-border">Direct</legend>
                            <div className="row">
                                {directConnectorsElements()}
                            </div>
                        </fieldset>
                    </div>
                )}

                {selectedPartner?.has_public_content === 1 && (
                    <div className="col-md-6">
                        <fieldset className="scheduler-border">
                            <legend className="scheduler-border">Public</legend>
                            <div className="row">
                                {publicConnectorsElements()}
                            </div>
                        </fieldset>
                    </div>
                )}
                <div className="col-md-12">
                    <fieldset className="scheduler-border">
                        <legend className="scheduler-border">HUB</legend>
                        <div className="row">{hubConnectorsElements}</div>
                    </fieldset>
                </div>
            </div>
            <Modal.Footer>
                <button
                    type="button"
                    className="btn btn-warning"
                    onClick={prev}
                >
                    <i className="fas fa-caret-left"></i> &nbsp;&nbsp; Back
                </button>
                <button
                    type="button"
                    className="btn btn-light"
                    onClick={handleClose}
                >
                    Close
                </button>
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => handleClick()}
                >
                    Next &nbsp;&nbsp;
                    <i className="fas fa-caret-right"></i>
                </button>
            </Modal.Footer>
        </div>
    );
}
export default Step2;
