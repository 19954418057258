import React, { useEffect, useState } from "react";
import Table from "../../components/Table/TablePaginationFromApi";
import { useSelector, useDispatch } from "react-redux";
import { getListOfInventoryWithCount } from "../../redux/actions/AnalyticsActions";
import { Modal, ListGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import { separator } from "../../utilis/functions";

const InventoryCount = () => {
    const dispatch = useDispatch();
    const { inventoryCount, loadingInventoryCount, totalInventoryCount } =
        useSelector((state) => ({
            inventoryCount: state.analyticsReducer?.inventoryCount ?? [],
            loadingInventoryCount:
                state.analyticsReducer?.loadingInventoryCount,
            totalInventoryCount: state.analyticsReducer?.totalInventoryCount,
        }));
    const [showSupplierName, setShowSupplierName] = useState(false);
    const [items, setItems] = useState([]);
    const [perPage, setPerPage] = useState(10);
    const [filterText, setFilterText] = useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [column, setColumn] = useState();
    const [sortDirection, setSortDirection] = useState();
    const renderTooltip = (name) => (props) =>
        (
            <Tooltip id="button-tooltip" {...props}>
                {name}
            </Tooltip>
        );

    const columns = [
        {
            id: "hotel_name",
            name: <div className="table-title">Hotel name</div>,
            cell: (e) => {
                return <>{e?.hotel_name}</>;
            },
            sortable: true,
        },
        {
            id: "mapping_count",
            name: <div className="table-title">Partner count</div>,
            cell: (e) => {
                return (
                    <>{e?.mapping_count ? separator(e?.mapping_count) : 0}</>
                );
            },
            sortable: true,
        },
        {
            id: "rank",
            name: <div className="table-title">Rank</div>,
            cell: (e) => {
                return <>{e?.rank}</>;
            },
            sortable: true,
        },
        {
            name: <div className="table-title">View partners</div>,
            cell: (e) => {
                return (
                    <button
                        className="btn"
                        onClick={() => {
                            var array = e?.partners?.split(",");

                            setShowSupplierName(true);
                            setItems(array);
                        }}
                    >
                        <i className="fas fa-eye"></i>{" "}
                    </button>
                );
            },
            sortable: false,
        },
    ];

    useEffect(() => {
        dispatch(getListOfInventoryWithCount(1, perPage, filterText));
    }, []);

    const handlePageChange = (page) => {
        dispatch(
            getListOfInventoryWithCount(
                page,
                perPage,
                filterText,
                column,
                sortDirection
            )
        );
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        dispatch(
            getListOfInventoryWithCount(
                page,
                newPerPage,
                filterText,
                column,
                sortDirection
            )
        );
        setPerPage(newPerPage);
    };

    const handleSort = async (c, sortD) => {
        dispatch(
            getListOfInventoryWithCount(1, perPage, filterText, c?.id, sortD)
        );
        setSortDirection(sortD);
        setColumn(c?.id);
    };

    return (
        <div className="card">
            <div className="card-body">
                <div>
                    <h4 className="card-title">
                        Inventory - Mapped{" "}
                        <OverlayTrigger
                            placement="top"
                            delay={{
                                show: 250,
                                hide: 400,
                            }}
                            overlay={renderTooltip(
                                "These are the properties from your inventory and the partners they’re mapped with. "
                            )}
                        >
                            <i
                                className="fas fa-info-circle"
                                style={{ marginLeft: "4px" }}
                            ></i>
                        </OverlayTrigger>
                    </h4>
                </div>

                {inventoryCount?.length === 0 &&
                !loadingInventoryCount &&
                filterText == "" ? (
                    <div className="home-img-container">
                        <img
                            src="../../assets/images/InventoryCountBlur.png"
                            alt="Partners Mapped by Property"
                            style={{ width: "100%" }}
                        />
                        <div className="home-img-text-container-centered ">
                            <span>
                                This table will be available when you have
                                enough data to display.
                            </span>
                        </div>
                    </div>
                ) : (
                    <Table
                        data={inventoryCount ?? []}
                        columns={columns}
                        totalRows={totalInventoryCount}
                        handlePageChange={handlePageChange}
                        onChangeRowsPerPage={handlePerRowsChange}
                        progressPending={loadingInventoryCount}
                        onFilter={() => {
                            setResetPaginationToggle(true);
                            dispatch(
                                getListOfInventoryWithCount(
                                    1,
                                    perPage,
                                    filterText
                                )
                            );
                        }}
                        filterValue={filterText}
                        handleClearFilter={() => {
                            setFilterText("");
                            setResetPaginationToggle(!resetPaginationToggle);
                            dispatch(
                                getListOfInventoryWithCount(1, perPage, "")
                            );
                        }}
                        onChange={(e) => {
                            setFilterText(e.target.value);
                            if (e.target.value === "") {
                                setResetPaginationToggle(
                                    !resetPaginationToggle
                                );
                                dispatch(
                                    getListOfInventoryWithCount(1, perPage, "")
                                );
                            }
                        }}
                        filter={true}
                        resetPaginationToggle={resetPaginationToggle}
                        onSort={handleSort}
                        sortServer={true}
                    />
                )}

                {showSupplierName && (
                    <DisplaySupplierNameModel
                        show={showSupplierName}
                        handleClose={() => setShowSupplierName(false)}
                        supplierNames={items}
                    />
                )}
            </div>
        </div>
    );
};

export default InventoryCount;

const DisplaySupplierNameModel = (props) => {
    const { show, handleClose, supplierNames } = props;

    function CustomComponent({ children, key }) {
        return (
            <li
                className="list-group-item"
                key={key}
                style={{
                    fontSize: "18px",
                }}
            >
                {children}
            </li>
        );
    }
    return (
        <Modal
            show={show}
            onHide={handleClose}
            dialogClassName={"modal-20w modal-dialog modal-dialog-centered"}
        >
            <Modal.Header
                style={{
                    color: " #959595",
                    fontSize: "18px",
                    fontWeight: "700",
                    left: "15px",
                    top: "15px",
                }}
            >
                Suppliers
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <ListGroup
                        componentClass="ul"
                        style={{
                            width: "100%",
                            marginLeft: "12px",
                            marginRight: "12px",
                        }}
                    >
                        {supplierNames?.map((item, id) => {
                            return (
                                <CustomComponent key={id}>
                                    {item}
                                </CustomComponent>
                            );
                        })}
                    </ListGroup>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleClose}
                >
                    Close
                </button>
            </Modal.Footer>
        </Modal>
    );
};
