import { Modal } from "react-bootstrap";
import React from "react";
import { useHistory } from "react-router-dom";
import Lottie from "react-lottie";
import animationData from "../../lotties/success.json";

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};
function FinishProcessingDialog(props) {
    const { show, handleClose, setFirstProccessing } = props;
    const history = useHistory();
    const onClose = () => {
        handleClose();
        localStorage.setItem("catalog_finish_Proccessing", false);
        setFirstProccessing(false);
        history.push("/partners-inventories");
    };
    return (
        <Modal
            show={show}
            onHide={onClose}
            dialogClassName="modal-50w modal-dialog modal-dialog-centered"
        >
            <Modal.Body style={{ textAlign: "center" }}>
                <Lottie options={defaultOptions} width={300} />
                <h2 className="modalTitle">Almost there !</h2>
                <p className="modalP">
                    Before we start mapping your content, you just need to add
                    your partner’s inventory.
                </p>
                <button
                    className="modalBtn btn waves-effect waves-light btn-rounded btn-primary"
                    onClick={onClose}
                >
                    Upload partner inventory
                </button>
            </Modal.Body>
        </Modal>
    );
}

export default FinishProcessingDialog;
