import React from "react";
import { Route, Switch, Redirect ,useLocation} from "react-router-dom";
import getRoutes from "../routes";
import Header from "../components/Header";
import Leftside from "../components/Leftside";
import SignIn from "../pages/Login/SignIn";
import ConfirmPassword from "../pages/Login/ConfirmPassword";
import SignUp from "../pages/Login/Sign-up/index";
import TeamSignUp from "../pages/Login/TeamSign-up";
import PartnerReport from "../pages/Partner-Report/index";
import NotFoundPage from "../pages/notFound";

export default function DashboardRouter(props) {
    const isAuthenticated = localStorage.getItem("isAuthenticated");
    const routes = getRoutes();
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search);
    
    return (
        <>
            {/* <Route path="/partner-access/sign-in" component={PartnerSignIn} /> */}
            <Route path="/sign-in" render={() => <SignIn {...props} />} />
            <Route
                path="/team-signup"
                render={() => <TeamSignUp {...props} />}
            />
            <Route
                path="/resetPassword/cofirm/:token"
                render={() => <ConfirmPassword {...props} />}
            />
            <Route
                path="/partner-reports"
                render={() => <PartnerReport {...props} />}
            />
            <Route exact path="/">
                {isAuthenticated ? (
                    <Redirect to="/home" />
                ) : (
                    <Redirect to="/sign-in" />
                )}
            </Route>
            <Switch>
                <Route path="/sign-up" render={() => <SignUp {...props} />} />
                {routes.map((route, key) => {
                    const token_mapping_report = queryParams.get('token_mapping_report');
                    const token_external_manual_mapping = queryParams.get('token_external_manual_mapping');
                    if((location.pathname.includes('external-manual-mapping') ||location.pathname.includes('get-started') ) && (token_mapping_report || token_external_manual_mapping)){
                        return(
                            <Route
                                path={route.path}
                                render={() =><route.component {...props} />}
                                key={key}
                            ></Route>
                        )
                    }
                    return (
                        <Route
                            path={route.path}
                            render={() =>
                                isAuthenticated ? (
                                    <>
                                        <Header />
                                        <Leftside {...props} />
                                        <route.component {...props} />
                                    </>
                                ) : (
                                    <Redirect to="/sign-in" />
                                )
                            }
                            key={key}
                        ></Route>
                    );
                })}
                <Route component={NotFoundPage} />
            </Switch>
        </>
    );
}
