import React, { useState, useEffect } from "react";
import { getTimeserieMappedRate } from "../../redux/actions/AnalyticsActions";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { DateRangePicker } from "react-date-range";
import "react-datepicker/dist/react-datepicker.css";
import { firstDayOfMonth, getDays } from "../../utilis/functions";
import { useDispatch, useSelector } from "react-redux";
import SyncLoader from "react-spinners/SyncLoader";
import moment from "moment";
import Select from "../../components/Select";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { GIATA_MODEL } from "../../utilis/const";

const TimeSerieMappedRate = () => {
    const [startDate, setStartDate] = useState(new Date(firstDayOfMonth()));
    const [endDate, setEndDate] = useState(new Date());
    const [showRangeModel, setShowRangeModel] = useState(false);
    const dispatch = useDispatch();
    const { timeSerieMapppedRate, loadingTimeSerieMappedRate, partnerList } =
        useSelector((state) => ({
            timeSerieMapppedRate:
                state.analyticsReducer.timeSerieMapppedRate ?? {},
            loadingTimeSerieMappedRate:
                state.analyticsReducer.loadingTimeSerieMappedRate,
            partnerList:
                state.partnerReducer.partners?.map((e) => ({
                    value: e?.id,
                    label: e?.name,
                })) ?? [],
        }));
    const [partnersSelected, setPartnersSelected] = useState(
        partnerList?.slice(0, 5) ?? []
    );

    useEffect(() => {
        if (startDate && endDate) {
            dispatch(
                getTimeserieMappedRate({
                    from: dateFormat(startDate),
                    to: dateFormat(endDate),
                    partner_ids: partnersSelected?.map((e) => e?.value),
                })
            );
        }
    }, [startDate, endDate, partnersSelected]);

    const dateFormat = (datte) => {
        var currentDate = new Date(datte);
        var date = currentDate?.getDate();
        var month = currentDate?.getMonth();
        var year = currentDate?.getFullYear();

        var dateString = year + "-" + (month + 1) + "-" + date;
        return dateString;
    };

    const renderTooltip = (name) => (props) =>
        (
            <Tooltip id="button-tooltip" {...props}>
                {name}
            </Tooltip>
        );

    const defaultOptionsDataSet1 = {
        chart: {
            type: "line",
        },
        title: {
            text: "",
        },
        subtitle: {
            text: "",
        },
        xAxis: {
            categories: getDays(startDate, endDate),
            crosshair: true,
        },
        yAxis: {
            min: 0,
            title: {
                text: "",
            },
        },

        // tooltip: {
        //     headerFormat:
        //         '<span style="font-size:10px">{point.key}</span><table>',
        //     pointFormat:
        //         '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        //         '<td style="padding:0"><b>{point.y}</b></td></tr>',
        //     footerFormat: "</table>",
        //     shared: true,
        //     useHTML: true,
        // },
        tooltip: {
            formatter: function () {
                return (
                    '<span style="font-size:10px"></span><table> ' +
                    this.x +
                    "<br/>" +
                    '<tr><td style="color:{series.color};padding:0">' +
                    this.series.name +
                    "</td> " +
                    '<td style="padding:0"><b>: ' +
                    this.y +
                    "% " +
                    "<br/> " +
                    "</b></td></tr>" +
                    '<tr><td style="color:{series.color};padding:0">Model </td> ' +
                    '<td style="padding:0"><b>: ' +
                    this.point.z +
                    "</b></td></tr>"
                );
            },
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0,
            },
        },

        series:
            Object.keys(timeSerieMapppedRate ?? {})?.map((e) => {
                let result = [];
                getDays(startDate, endDate)?.forEach((tt, index) => {
                    let dataPoint = timeSerieMapppedRate[e]
                        ?.map((s) => ({
                            x: s?.created_at,
                            y: parseInt(s?.mapping_rate),
                            z: s?.model === GIATA_MODEL ? "GIATA" : "Cupid",
                        }))
                        ?.filter((data) => data.x === tt);
                    if (dataPoint?.length) {
                        result?.push({
                            label: tt,
                            y: dataPoint[0]?.y,
                            z: dataPoint[0]?.z,
                        });
                    } else if (result?.length > 0) {
                        result?.push({
                            label: tt,
                            y: result[index - 1].y,
                            z: result[index - 1].z,
                        });
                    } else {
                        result?.push({ label: tt, y: 0, z: "--" });
                    }
                });
                return {
                    name: timeSerieMapppedRate[e][0]?.partner_name,
                    data: result,
                };
            }) ?? [],

        //resize: true,
        credits: {
            enabled: false,
        },
    };
    return (
        <div className="card" style={{ minHeight: "639px" }}>
            <h4 className="chart-title">
                <i
                    className="fas fa-chart-line"
                    style={{ marginRight: "4px" }}
                ></i>
                Mapping Rate by Partner
                <OverlayTrigger
                    placement="top"
                    delay={{
                        show: 250,
                        hide: 400,
                    }}
                    overlay={renderTooltip(
                        " This is the evolution of your partner’s mapping rates over time. It is the percentage of your partners catalog that was mapped with your inventory."
                    )}
                >
                    <i
                        className="fas fa-info-circle"
                        style={{ marginLeft: "4px" }}
                    ></i>
                </OverlayTrigger>
            </h4>
            <div
                className="card-header"
                style={{
                    backgroundColor: "white",
                    // display: "flex",
                    // justifyContent: "space-between",
                }}
            >
                <div className="row">
                    <div className="col-6">
                        <button
                            className="btn"
                            onClick={() => {
                                setShowRangeModel(true);
                            }}
                            style={{ padding: "0px", width: "100%" }}
                        >
                            <input
                                style={{ border: "2px solid hsl(0, 0%, 80%)" }}
                                className="form-control"
                                name="password"
                                id="password"
                                placeholder="Enter your password"
                                autoComplete="off"
                                value={`${moment(new Date(startDate)).format(
                                    "DD/MM/YYYY"
                                )} - ${moment(new Date(endDate)).format(
                                    "DD/MM/YYYY"
                                )}`}
                            />
                        </button>
                    </div>
                    <div className="col-6 partner-mapped-rate">
                        <Select
                            options={partnerList}
                            name="header_id"
                            isClearable={true}
                            value={partnersSelected}
                            isMulti
                            onChange={(e) => {
                                setPartnersSelected(e);
                            }}
                            isOptionDisabled={() =>
                                partnersSelected?.length >= 5
                            }
                            //  defaultValue={partnerList?.slice}
                        />
                    </div>
                </div>
            </div>
            <div className="card-body">
                {loadingTimeSerieMappedRate ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            padding: "20px",
                        }}
                    >
                        <SyncLoader
                            color="#5856d694"
                            loading={true}
                            size={10}
                        />
                    </div>
                ) : (
                    <>
                        {Object.keys(timeSerieMapppedRate)?.length === 0 ? (
                            <div className="home-img-container">
                                <img
                                    src="../../assets/images/partnerMappingBlur.png"
                                    alt="Mappings Performed Over Time no Data"
                                    style={{ width: "100%" }}
                                />
                                <div className="home-img-text-container-centered ">
                                    <span>
                                        This graph will be available when you
                                        have enough data to display.
                                    </span>
                                </div>
                            </div>
                        ) : (
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={defaultOptionsDataSet1}
                            />
                        )}
                    </>
                )}
            </div>
            {showRangeModel && (
                <DateRangePickerModel
                    show={showRangeModel}
                    handleClose={() => setShowRangeModel(false)}
                    startDate={startDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    setStartDate={setStartDate}
                />
            )}
        </div>
    );
};

export default TimeSerieMappedRate;

const DateRangePickerModel = (props) => {
    const { show, handleClose, startDate, endDate, setStartDate, setEndDate } =
        props;
    const [state, setState] = useState([
        {
            startDate: startDate,
            endDate: endDate,
            key: "selection",
        },
    ]);
    return (
        <Modal
            show={show}
            onHide={handleClose}
            dialogClassName="modal-dialog modal-dialog-centered"
        >
            <DateRangePicker
                onChange={(item) => {
                    setState([item.selection]);
                    setStartDate(item.selection?.startDate);
                    setEndDate(item.selection?.endDate);
                    if (item.selection?.startDate !== item.selection?.endDate) {
                        handleClose();
                    }
                }}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={2}
                ranges={state}
                direction="horizontal"
                rangeColors={["#5f76e8c7"]}
            />
        </Modal>
    );
};
