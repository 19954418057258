import React, { useEffect, useState } from "react";
import {
    Card,
    Row,
    Col,
    Spinner,
    Tooltip,
    OverlayTrigger,
} from "react-bootstrap";
import Avatar from "react-avatar";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
    getGiataPartnersList,
    getGiataPartnersListPeriodique,
    refreshGiataData,
} from "../../../redux/actions/ModelsActions";
import ReactPaginate from "react-paginate";
import {
    GIATA_PROVIDER_ERROR,
    GIATA_PROVIDER_IN_QUEUE,
    GIATA_PROVIDER_PROCESSING,
    GIATA_PROVIDER_DONE,
    STATUS_PROCESSING,
} from "../../../utilis/const";
import { separator, calculatePourcentage } from "../../../utilis/functions";
import ModelsService from "../../../services/ModelsService";
import { toast } from "react-toastify";
import { MenuItem, ControlledMenu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import ConfirmAction from "../../../components/ConfirmActionsModal";

const renderTooltip = (name) => (props) =>
    (
        <Tooltip id="button-tooltip" {...props}>
            {name}
        </Tooltip>
    );

const statusBadgeVariant = (status) => {
    switch (status) {
        case GIATA_PROVIDER_ERROR:
            return {
                color: "danger",
                status: "Faild",
            };
        case GIATA_PROVIDER_IN_QUEUE:
            return {
                color: "white",
                status: "In queue",
            };
        case GIATA_PROVIDER_PROCESSING:
            return {
                color: "info",
                status: "Proccessing",
            };
        case GIATA_PROVIDER_DONE:
            return {
                color: "success",
                status: "Success",
            };
        default:
            return {
                color: "",
                status: "",
            };
    }
};
const GiataMappingList = (props) => {
    const { setShowCredentialsForm } = props;
    const dispatch = useDispatch();
    const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);
    const [pendingLogout, setPendingLogout] = useState(false);
    const {
        loadingGiataPartnerList,
        giataPartnerList,
        inventory_count,
        firstRow,
        totalGiataIds,
        createdAt,
    } = useSelector((state) => ({
        giataPartnerList: state.modelsReducer.giataPartnerList?.data,
        firstRow: state.modelsReducer.giataPartnerList?.data?.[0],
        totalGiataIds: state.modelsReducer.giataPartnerList?.total_giata_ids,
        createdAt: state.modelsReducer.giataPartnerList?.created_at,
        inventory_count: state.modelsReducer.giataPartnerList?.inventory_count,
        loadingGiataPartnerList: state.modelsReducer.loadingGiataPartnerList,
    }));

    useEffect(() => {
        dispatch(getGiataPartnersList());
    }, []);

    useEffect(() => {
        const refreshIntervalId = setInterval(() => {
            if (
                window.location.pathname === "/models/giata" &&
                giataPartnerList?.find((e) => e?.status === 2)
            ) {
                dispatch(getGiataPartnersListPeriodique());
            }
        }, 5000);
        return () => clearInterval(refreshIntervalId);
    }, [giataPartnerList]);

    const [itemsPerPage, setItemsPerPage] = useState(12);

    const [currentItems, setCurrentItems] = useState();

    const [pageCount, setPageCount] = useState(0);

    const [itemOffset, setItemOffset] = useState(0);

    const [filter, setFilter] = useState("");

    const [displayedData, setDisplayedData] = useState(giataPartnerList);

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        setCurrentItems(displayedData?.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(displayedData?.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, loadingGiataPartnerList, displayedData]);

    const handlePageClick = (event) => {
        const newOffset =
            (event.selected * itemsPerPage) % giataPartnerList.length;
        setItemOffset(newOffset);
    };

    useEffect(() => {
        if (filter !== "") {
            const newData = giataPartnerList.filter((element) => {
                let match = false;
                let stringValue = element?.name?.toString() ?? "";
                match =
                    match ||
                    stringValue
                        ?.toLowerCase()
                        ?.search(filter?.toLowerCase()) !== -1;
                return match;
            });
            setDisplayedData(newData);
            setItemOffset(0);
        } else {
            setDisplayedData(giataPartnerList);
        }
    }, [filter, loadingGiataPartnerList, giataPartnerList]);

    return (
        <Row>
            {loadingGiataPartnerList ? (
                <div className="text-center">
                    <div className="lds-ripple">
                        <div className="lds-pos"></div>
                        <div className="lds-pos"></div>
                    </div>
                </div>
            ) : (
                <>
                    <Col md={8} lg={8}>
                        <Card className="card-partner-details">
                            <Row className="no-gutters">
                                <Col>
                                    <Card.Body style={{ minHeight: "174px" }}>
                                        <Card.Title
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                marginBottom: "0px",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    color: "#444",
                                                }}
                                            >
                                                <Avatar
                                                    name={"GS"}
                                                    round={true}
                                                    size={30}
                                                    color="#222"
                                                    style={{
                                                        marginLeft: "11px",
                                                        marginRight: "11px",
                                                    }}
                                                />
                                                Giata summary
                                                <span
                                                    style={{
                                                        marginLeft: "11px",
                                                        color: "rgb(128, 134, 152)",
                                                        font: '500 1rem / 16px Roboto, "Helvetica Neue", sans-serif',
                                                        letterSpacing: "0.3px",
                                                    }}
                                                >
                                                    ( Last update :&nbsp;
                                                    {firstRow?.updated_at
                                                        ? moment(
                                                              new Date(
                                                                  firstRow?.updated_at
                                                              )
                                                          ).format(
                                                              "DD/MM/YYYY HH:mm"
                                                          )
                                                        : createdAt
                                                        ? moment(
                                                              new Date(
                                                                  createdAt
                                                              )
                                                          ).format(
                                                              "DD/MM/YYYY HH:mm"
                                                          )
                                                        : "--"}
                                                    )
                                                </span>
                                            </div>
                                            <div>
                                                <SummaryMenu
                                                    setShowLogoutConfirm={
                                                        setShowLogoutConfirm
                                                    }
                                                />
                                            </div>
                                        </Card.Title>
                                        <Card.Text
                                            style={{
                                                marginTop: "0px",
                                                color: "#808698",
                                                fontWeight: "500",
                                                fontSize: "1rem",
                                                // font: "400 12px/16px Roboto,Helvetica Neue,sans-serif",
                                                letterSpacing: ".3px",
                                                position: "relative",
                                                bottom: "0",
                                                paddingLeft: "0px",
                                                paddingRight: "0px",
                                            }}
                                        >
                                            <Row className="no-gutters">
                                                <Col
                                                    md={12}
                                                    lg={12}
                                                    style={{
                                                        paddingLeft: "0px",
                                                        paddingRight: "0px",
                                                    }}
                                                >
                                                    <div
                                                        className="row"
                                                        style={{
                                                            margin: "11px",
                                                            border: "1px solid rgb(128 134 152 / 18%)",
                                                            borderRadius:
                                                                "12px",
                                                            padding: "7px",
                                                            backgroundColor:
                                                                "rgb(128 134 152 / 9%)",
                                                        }}
                                                    >
                                                        <div className="col-6 col-md-12 col-lg-6">
                                                            <div
                                                                style={{
                                                                    justifyContent:
                                                                        "space-between",
                                                                    display:
                                                                        "flex",
                                                                }}
                                                            >
                                                                <div>
                                                                    <span
                                                                        style={{
                                                                            fontWeight:
                                                                                "600",
                                                                            color: "rgb(68, 68, 68)",
                                                                        }}
                                                                    >
                                                                        <i
                                                                            className="fas fa-building"
                                                                            style={{
                                                                                marginRight:
                                                                                    "2px",
                                                                            }}
                                                                        ></i>
                                                                        Total
                                                                        Giata
                                                                        Ids
                                                                    </span>
                                                                    <br />
                                                                    <span
                                                                        style={{
                                                                            color: "rgb(128, 134, 152)",
                                                                            font: '500 1rem / 16px Roboto, "Helvetica Neue", sans-serif',
                                                                            letterSpacing:
                                                                                "0.3px",
                                                                        }}
                                                                    >
                                                                        {totalGiataIds
                                                                            ? separator(
                                                                                  totalGiataIds
                                                                              )
                                                                            : "--"}
                                                                    </span>
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        borderRight:
                                                                            "2px solid rgb(128 134 152 / 18%)",
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-6 col-md-12 col-lg-6">
                                                            <div
                                                                style={{
                                                                    justifyContent:
                                                                        "space-between",
                                                                    display:
                                                                        "flex",
                                                                    paddingLeft:
                                                                        "0px",
                                                                }}
                                                            >
                                                                <div>
                                                                    <span
                                                                        style={{
                                                                            fontWeight:
                                                                                "600",
                                                                            color: "rgb(68, 68, 68)",
                                                                        }}
                                                                    >
                                                                        <i
                                                                            className="fas fa-building"
                                                                            style={{
                                                                                marginRight:
                                                                                    "2px",
                                                                            }}
                                                                        ></i>
                                                                        Number
                                                                        Partners
                                                                        Keys
                                                                    </span>
                                                                    <br />
                                                                    <span
                                                                        style={{
                                                                            color: "rgb(128, 134, 152)",
                                                                            font: '500 1rem / 16px Roboto, "Helvetica Neue", sans-serif',
                                                                            letterSpacing:
                                                                                "0.3px",
                                                                        }}
                                                                    >
                                                                        {giataPartnerList?.length
                                                                            ? separator(
                                                                                  giataPartnerList?.length
                                                                              )
                                                                            : "--"}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card.Text>
                                    </Card.Body>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col md={12} lg={12}>
                        <div className="search-input">
                            <span className="hevo-web-icon search-icon hevo-search">
                                <i className="fas fa-search"></i>
                            </span>
                            <input
                                style={{ width: "90%" }}
                                type="text"
                                name="filter"
                                value={filter}
                                onChange={(e) => setFilter(e?.target?.value)}
                                placeholder="Search partner"
                                autoComplete="off"
                                id="inte_input"
                            />
                        </div>
                    </Col>
                    <Col
                        md={12}
                        lg={12}
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            padding: "0px",
                        }}
                    >
                        <Col md={4} lg={4}>
                            <Card
                                className="card-partner-details"
                                style={{
                                    marginBottom: "17px",
                                    padding: "8px",
                                }}
                            >
                                <Col md={12} lg={12}>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <div>
                                            <span
                                                className="dot"
                                                style={{
                                                    backgroundColor:
                                                        "rgb(21, 191, 174)",
                                                }}
                                            ></span>
                                            <span
                                                style={{
                                                    fontSize: "small",
                                                }}
                                            >
                                                &nbsp; Total Matched&nbsp;
                                            </span>
                                        </div>
                                        <div>
                                            <span
                                                className="dot"
                                                style={{
                                                    backgroundColor:
                                                        "rgb(242, 95, 41)",
                                                }}
                                            ></span>
                                            <span
                                                style={{
                                                    fontSize: "small",
                                                }}
                                            >
                                                &nbsp; Total Unmatched &nbsp;
                                            </span>
                                        </div>
                                    </div>
                                </Col>
                            </Card>
                        </Col>
                    </Col>

                    {currentItems?.map((e, index) => {
                        return (
                            <Col md={4} lg={4}>
                                <Card className="card-without-hover">
                                    <Row className="no-gutters">
                                        <Col>
                                            <Card.Body>
                                                <Card.Title
                                                    style={{
                                                        display: "flex",

                                                        justifyContent:
                                                            "space-between",
                                                        marginBottom: "25px",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            color: "#444",
                                                        }}
                                                    >
                                                        {e?.name}
                                                        {e?.status === 2 && (
                                                            <Spinner
                                                                animation="border"
                                                                variant="primary"
                                                                size="sm"
                                                                style={{
                                                                    marginLeft:
                                                                        "4px",
                                                                }}
                                                            />
                                                        )}
                                                        <div
                                                            style={{
                                                                color: "rgb(128, 134, 152)",
                                                                font: '500 1rem / 16px Roboto, "Helvetica Neue", sans-serif',
                                                                letterSpacing:
                                                                    "0.3px",
                                                                fontWeight:
                                                                    "500",
                                                                fontSize:
                                                                    "1rem",
                                                                paddingTop:
                                                                    "5px",
                                                            }}
                                                        >
                                                            Total Ids :{" "}
                                                            {e?.total_provider_giata_ids
                                                                ? separator(
                                                                      e?.total_provider_giata_ids
                                                                  )
                                                                : "--"}
                                                        </div>
                                                        <div
                                                            style={{
                                                                color: "rgb(128, 134, 152)",
                                                                font: '500 1rem / 16px Roboto, "Helvetica Neue", sans-serif',
                                                                letterSpacing:
                                                                    "0.3px",
                                                                fontWeight:
                                                                    "500",
                                                                fontSize:
                                                                    "1rem",
                                                                paddingTop:
                                                                    "5px",
                                                            }}
                                                        >
                                                            Last update :&nbsp;
                                                            {e?.created_at
                                                                ? moment(
                                                                      new Date(
                                                                          e?.created_at
                                                                      )
                                                                  ).format(
                                                                      "DD/MM/YYYY HH:mm"
                                                                  )
                                                                : "--"}
                                                        </div>
                                                    </div>

                                                    <div
                                                        onClick={(event) =>
                                                            event.stopPropagation()
                                                        }
                                                    >
                                                        <Download
                                                            dataCell={e}
                                                        />
                                                    </div>
                                                </Card.Title>
                                                <Card.Text className="card-text-mapping-details">
                                                    {e?.total_provider_giata_ids &&
                                                    e?.total_matched_giata_ids ? (
                                                        <div
                                                            className="graph-details"
                                                            style={{
                                                                height: "20px",
                                                                marginBottom:
                                                                    "5px",
                                                            }}
                                                        >
                                                            <OverlayTrigger
                                                                placement="top"
                                                                delay={{
                                                                    show: 100,
                                                                    hide: 100,
                                                                }}
                                                                overlay={renderTooltip(
                                                                    `Mapped Matched : ${
                                                                        e?.total_matched_giata_ids
                                                                            ? separator(
                                                                                  e?.total_matched_giata_ids
                                                                              )
                                                                            : 0
                                                                    } (${
                                                                        calculatePourcentage(
                                                                            e?.total_provider_giata_ids,
                                                                            e?.total_matched_giata_ids
                                                                        ) ?? 0
                                                                    }%)`
                                                                )}
                                                            >
                                                                <div
                                                                    className="graph-item-details"
                                                                    style={{
                                                                        backgroundColor:
                                                                            "#15BFAE",
                                                                        width: `${calculatePourcentage(
                                                                            e?.total_provider_giata_ids,
                                                                            e?.total_matched_giata_ids
                                                                        )}%`,
                                                                        height: "20px",
                                                                    }}
                                                                ></div>
                                                            </OverlayTrigger>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                delay={{
                                                                    show: 100,
                                                                    hide: 100,
                                                                }}
                                                                overlay={renderTooltip(
                                                                    `Total unmatched : ${
                                                                        e?.total_provider_giata_ids -
                                                                        e?.total_matched_giata_ids
                                                                            ? separator(
                                                                                  e?.total_provider_giata_ids -
                                                                                      e?.total_matched_giata_ids
                                                                              )
                                                                            : 0
                                                                    } (${calculatePourcentage(
                                                                        e?.total_provider_giata_ids,
                                                                        e?.total_provider_giata_ids -
                                                                            e?.total_matched_giata_ids
                                                                    )}%)`
                                                                )}
                                                            >
                                                                <div
                                                                    className="graph-item-details"
                                                                    style={{
                                                                        backgroundColor:
                                                                            "#F25F29",
                                                                        width: `${
                                                                            calculatePourcentage(
                                                                                e?.total_provider_giata_ids,
                                                                                e?.total_provider_giata_ids -
                                                                                    e?.total_matched_giata_ids
                                                                            ) ??
                                                                            0
                                                                        }%`,
                                                                        height: "20px",
                                                                    }}
                                                                ></div>
                                                            </OverlayTrigger>
                                                        </div>
                                                    ) : (
                                                        <span
                                                            text="white"
                                                            className={`badge rounded-pill text-white bg-${
                                                                statusBadgeVariant(
                                                                    e?.status
                                                                ).color
                                                            }`}
                                                            style={{
                                                                width: "100%",
                                                                fontWeight: 800,
                                                                fontSize:
                                                                    "13px",
                                                                height: "20px",
                                                            }}
                                                        >
                                                            {e?.status === 2
                                                                ? e?.steps
                                                                : statusBadgeVariant(
                                                                      e?.status
                                                                  ).status}
                                                        </span>
                                                    )}
                                                </Card.Text>
                                            </Card.Body>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        );
                    })}
                    {currentItems?.length > 0 ? (
                        <div
                            className="row"
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <ReactPaginate
                                className="react-pagination-div"
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                containerClassName={"pagination"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                activeClassName={"active"}
                                breakLabel="..."
                                nextLabel=" >"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={5}
                                pageCount={pageCount}
                                previousLabel="<"
                                renderOnZeroPageCount={null}
                            />
                        </div>
                    ) : (
                        <Col md={12} lg={12}>
                            <div className="card" style={{ padding: "2rem" }}>
                                <div className="empty text-center">
                                    <i className="zoom-icon"></i>
                                    <p>
                                        Sorry, we couldn&apos;t find any partner
                                        for your search criteria. Please check
                                        your information and try again.
                                    </p>
                                </div>
                            </div>
                        </Col>
                    )}
                </>
            )}
            {showLogoutConfirm && (
                <ConfirmAction
                    show={true}
                    onClose={() => setShowLogoutConfirm(false)}
                    titleMessage="Warning !"
                    subTitleMessage="Are you sure you want to logout from GIATA?"
                    colorCode="danger"
                    handleClick={async () => {
                        setPendingLogout(true);
                        try {
                            const res = await ModelsService.logoutFromGiata();
                            if (res.status === 200) {
                                toast.success("Succes");
                                setShowCredentialsForm(true);
                                setPendingLogout(false);
                            } else {
                                toast.error(res.message);
                                setPendingLogout(false);
                            }
                        } catch (error) {
                            setPendingLogout(false);
                            toast.error("Error");
                        }
                    }}
                    buttonText="Confirm"
                    disabled={pendingLogout}
                />
            )}
        </Row>
    );
};
export default GiataMappingList;

const Download = (props) => {
    const [pending, setPending] = React.useState(false);
    const { dataCell } = props;
    const [isOpen, setOpen] = useState();
    const ref = React.useRef(null);
    const dispatch = useDispatch();

    const handleDowload = () => async () => {
        setPending(true);
        dispatch(getGiataPartnersListPeriodique());
        try {
            let res = await ModelsService.runGiataMappingByPartner(
                dataCell?.key
            );
            if (res.status === 200) {
                setPending(false);
                toast.success("Mapping started successfully");
                dispatch(getGiataPartnersListPeriodique());
            } else {
                toast.error(res.message);
                setPending(false);
            }
        } catch (err) {
            toast.error("Error");
            setPending(false);
        }
    };
    return (
        <>
            <div
                ref={ref}
                className="btn"
                onClick={() => setOpen(true)}
                style={{ cursor: "pointer" }}
            >
                <i className="fas fa-ellipsis-v"></i>{" "}
            </div>
            <ControlledMenu
                state={isOpen ? "open" : pending ? "open" : "closed"}
                anchorRef={ref}
                onClose={() => setOpen(false)}
            >
                {dataCell?.mapping_url && (
                    <MenuItem
                        onClick={() => {
                            window.open(dataCell?.mapping_url, "_blank");
                        }}
                    >
                        Download CSV
                    </MenuItem>
                )}
                <MenuItem onClick={handleDowload()} disbaled={pending}>
                    Run mapping
                    {pending && (
                        <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    )}
                </MenuItem>
            </ControlledMenu>
        </>
    );
};
const SummaryMenu = (props) => {
    const { setShowLogoutConfirm } = props;
    const [isOpen, setOpen] = useState();
    const ref = React.useRef(null);
    const dispatch = useDispatch();
    const { loadingRefreshData } = useSelector((state) => ({
        loadingRefreshData: state.modelsReducer.loadingRefreshData,
    }));

    return (
        <>
            <div
                ref={ref}
                className="btn"
                onClick={() => setOpen(true)}
                style={{ cursor: "pointer" }}
            >
                <i className="fas fa-ellipsis-v"></i>{" "}
            </div>
            <ControlledMenu
                state={isOpen ? "open" : loadingRefreshData ? "open" : "closed"}
                anchorRef={ref}
                onClose={() => setOpen(false)}
            >
                <MenuItem
                    onClick={async () => {
                        setShowLogoutConfirm(true);
                    }}
                >
                    <i
                        className="fas fa-sign-out-alt"
                        style={{ marginRight: "7px" }}
                    />
                    Logout from GIATA
                </MenuItem>
                <MenuItem
                    onClick={async () => {
                        await dispatch(refreshGiataData());
                        await dispatch(getGiataPartnersList());
                    }}
                    disbaled={loadingRefreshData}
                >
                    <i
                        className="fas fa-sync-alt"
                        style={{ marginRight: "7px" }}
                    />
                    Refresh
                    {loadingRefreshData && (
                        <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    )}
                </MenuItem>
            </ControlledMenu>
        </>
    );
};
