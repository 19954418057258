import React from "react";
import { Modal } from "react-bootstrap";

function Step1(props) {
    const { setName, name, next, activeCatalog, prev, handleClose } = props;
    return (
        <>
            <Modal.Body>
                <fieldset className="scheduler-border">
                    <legend className="scheduler-border">
                        <h3 className="card-title">Type your inventory name</h3>
                    </legend>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="form-group form-group-header ">
                                <label>Inventory name </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Inventory name"
                                    name="name"
                                    value={name ?? ""}
                                    onChange={(e) => {
                                        setName(e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </fieldset>
            </Modal.Body>
            <Modal.Footer>
                {activeCatalog && (
                    <button
                        type="button"
                        className="btn btn-warning"
                        onClick={prev}
                    >
                        <i className="fas fa-caret-left"></i> &nbsp;&nbsp; Back
                    </button>
                )}
                <button
                    type="button"
                    className="btn btn-light"
                    onClick={handleClose}
                >
                    Close
                </button>
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={next}
                >
                    Next &nbsp;&nbsp;
                    <i className="fas fa-caret-right"></i>
                </button>
            </Modal.Footer>
        </>
    );
}

export default Step1;
