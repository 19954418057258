import React from "react";
import { separator } from "../../utilis/functions";
import moment from "moment";
import { PieChart } from "react-minimal-pie-chart";

const PartnerHeaderDetails = (props) => {
    const { activeCatalog, calculHeaderPourcentage } = props;
    return (
        <>
            <div
                className="col-3 col-md-12 col-lg-3"
                style={{ paddingRight: "0px" }}
            >
                <div
                    className={`card boxShadow card-info card-partner-details`}
                    style={{
                        borderRadius: "0.75rem",
                    }}
                >
                    <div
                        className="card-body"
                        style={{
                            padding: "28px",
                        }}
                    >
                        <div className="d-flex d-lg-flex d-md-block align-items-center">
                            <div>
                                <h2
                                    className="text-dark mb-1 w-100 text-truncate font-weight-medium"
                                    style={{
                                        fontSize: "20px",
                                    }}
                                >
                                    Mapping Rate
                                </h2>
                                <h6 className="text-muted font-weight-bold mb-0 w-100 text-truncate">
                                    (
                                    {`Last update :  ${moment(
                                        activeCatalog?.created_at,
                                        "YYYYMMDD HH:mm:ss Z"
                                    ).fromNow()}`}
                                    )
                                </h6>
                            </div>
                            <div className="ml-auto mt-md-3 mt-lg-0">
                                <span className="opacity-7 text-muted">
                                    <PieChart
                                        style={{
                                            height: "58px",
                                        }}
                                        data={[
                                            {
                                                value:
                                                    calculHeaderPourcentage(
                                                        activeCatalog
                                                    ).lastMappedPourcentage?.toFixed(
                                                        0
                                                    ) ?? 0,

                                                title: `${
                                                    calculHeaderPourcentage(
                                                        activeCatalog
                                                    ).lastMappedPourcentage?.toFixed(
                                                        0
                                                    ) ?? 0
                                                }%`,
                                                color: "rgb(21, 191, 174)",
                                            },
                                        ]}
                                        totalValue={100}
                                        lineWidth={20}
                                        label={({ dataEntry }) =>
                                            dataEntry.title
                                        }
                                        labelStyle={{
                                            fontSize: "25px",
                                            fontFamily: "sans-serif",
                                            fill: "rgb(21, 191, 174)",
                                        }}
                                        labelPosition={0}
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-3 home" style={{ paddingRight: "0px" }}>
                <div
                    className={`card boxShadow card-info card-partner-details`}
                    style={{
                        borderRadius: "0.75rem",
                    }}
                >
                    <div
                        className="card-body"
                        style={{
                            padding: "28px",
                        }}
                    >
                        <div className="d-flex d-lg-flex d-md-block align-items-center">
                            <div>
                                <div className="d-inline-flex align-items-center">
                                    <h2 className="text-dark mb-1 w-100 text-truncate font-weight-medium">
                                        {activeCatalog?.total_hotels
                                            ? separator(
                                                  activeCatalog?.total_hotels
                                              )
                                            : 0}
                                    </h2>
                                </div>
                                <h6 className="text-muted font-weight-bold mb-0 w-100 text-truncate">
                                    Total hotels
                                </h6>
                            </div>
                            <div className="ml-auto mt-md-3 mt-lg-0">
                                <span className="opacity-7 text-muted">
                                    <i
                                        className="fas fa-building"
                                        style={{
                                            color: "rgb(95 117 232)",
                                        }}
                                    ></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-3 home" style={{ paddingRight: "0px" }}>
                <div
                    className={`card boxShadow card-info card-partner-details`}
                    style={{
                        borderRadius: "0.75rem",
                    }}
                >
                    <div className="card-body" style={{ padding: "28px" }}>
                        <div className="d-flex d-lg-flex d-md-block align-items-center">
                            <div>
                                <div className="d-inline-flex align-items-center">
                                    <h2 className="text-dark mb-1 w-100 text-truncate font-weight-medium">
                                        <sup className="set-doller">
                                            <i className="fas fa-hotel"></i>
                                        </sup>
                                        {activeCatalog?.mapped_hotels
                                            ? separator(
                                                  activeCatalog?.mapped_hotels
                                              )
                                            : 0}
                                    </h2>
                                    <span className="badge bg-primary font-12 text-white font-weight-medium badge-pill ml-2 d-md-none d-lg-block">
                                        {`${calculHeaderPourcentage(
                                            activeCatalog
                                        ).mappedPourcentage?.toFixed(0)} %`}
                                    </span>
                                </div>
                                <h6 className="text-muted font-weight-bold mb-0 w-100 text-truncate">
                                    Mapped
                                </h6>
                            </div>
                            <div className="ml-auto mt-md-3 mt-lg-0">
                                <span className="opacity-7 text-muted">
                                    <i
                                        className="fas fa-building"
                                        style={{
                                            color: "rgb(95 117 232)",
                                        }}
                                    ></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-3 home">
                <div
                    className={`card boxShadow card-info card-partner-details`}
                    style={{ borderRadius: "0.75rem" }}
                >
                    <div
                        className="card-body"
                        style={{
                            padding: "28px",
                        }}
                    >
                        <div className="d-flex d-lg-flex d-md-block align-items-center">
                            <div>
                                <div className="d-inline-flex align-items-center">
                                    <h2 className="text-dark mb-1 w-100 text-truncate font-weight-medium">
                                        <sup className="set-doller">
                                            <i className="fas fa-hotel"></i>
                                        </sup>
                                        {activeCatalog?.unmapped_hotels
                                            ? separator(
                                                  activeCatalog?.unmapped_hotels
                                              )
                                            : 0}
                                    </h2>
                                    <span className="badge bg-primary font-12 text-white font-weight-medium badge-pill ml-2 d-md-none d-lg-block">
                                        {`${calculHeaderPourcentage(
                                            activeCatalog
                                        ).unmappedPourcentage?.toFixed(0)} %`}
                                    </span>
                                </div>
                                <h6 className="text-muted font-weight-bold mb-0 w-100 text-truncate">
                                    Opportunities
                                </h6>
                            </div>
                            <div className="ml-auto mt-md-3 mt-lg-0">
                                <span className="opacity-7 text-muted">
                                    <i
                                        className="fas fa-building"
                                        style={{
                                            color: "rgb(95 117 232)",
                                        }}
                                    ></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PartnerHeaderDetails;
