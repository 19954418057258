/* eslint-disable import/no-anonymous-default-export */
import {
    GET_CONNECTORS_SUCCESS,
    GET_CONNECTORS_LISTING,
    GET_CONNECTORS_FAIL,
    GET_CRIDENTIALS_SUCCESS,
    GET_CRIDENTIALS_LISTING,
    GET_CRIDENTIALS_FAIL,
    GET_CRIDENTIALS_BY_ID_LISTING,
    GET_CRIDENTIALS_BY_ID_SUCCESS,
    GET_CRIDENTIALS_BY_ID_FAIL,
    GET_TRAVELGATE_CRIDENTIALS_LISTING,
    GET_TRAVELGATE_CRIDENTIALS_SUCCESS,
    GET_TRAVELGATE_CRIDENTIALS_FAIL,
} from "../types";

const initialState = {
    listConnectors: [],
    listCridentials: [],
    ttest: {},
    loadingConnectors: false,
    loadingCridentials: false,
    loadingCridentialsById: false,
    loadingTravelgateCredentials: false,
    travelgateCredentials: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_CONNECTORS_LISTING:
            return {
                ...state,
                loadingConnectors: true,
            };
        case GET_CONNECTORS_SUCCESS:
            return {
                ...state,
                listConnectors: action.payload,
                loadingConnectors: false,
            };
        case GET_CONNECTORS_FAIL:
            return {
                ...state,
                loadingConnectors: false,
            };
        case GET_CRIDENTIALS_LISTING:
            return {
                ...state,
                loadingCridentials: true,
            };
        case GET_CRIDENTIALS_SUCCESS:
            return {
                ...state,
                listCridentials: action.payload,
                loadingCridentials: false,
            };
        case GET_CRIDENTIALS_FAIL:
            return {
                ...state,
                loadingCridentials: false,
            };
        case GET_CRIDENTIALS_BY_ID_LISTING:
            return {
                ...state,
                loadingCridentialsById: true,
                ttest: {},
            };
        case GET_CRIDENTIALS_BY_ID_SUCCESS: {
            return {
                ...state,
                ttest: action.payload.credentials,
                loadingCridentialsById: false,
            };
        }
        case GET_CRIDENTIALS_BY_ID_FAIL:
            return {
                ...state,
                loadingCridentialsById: false,
                credentials: {},
            };

        case GET_TRAVELGATE_CRIDENTIALS_LISTING:
            return {
                ...state,
                loadingTravelgateCredentials: true,
                travelgateCredentials: {},
            };
        case GET_TRAVELGATE_CRIDENTIALS_SUCCESS: {
            return {
                ...state,
                travelgateCredentials: action.payload,
                loadingTravelgateCredentials: false,
            };
        }
        case GET_TRAVELGATE_CRIDENTIALS_FAIL:
            return {
                ...state,
                loadingTravelgateCredentials: false,
                travelgateCredentials: {},
            };
        default:
            return state;
    }
}
