import React, { useEffect, useState, useLayoutEffect, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { DateRangePicker } from "react-date-range";
import "react-datepicker/dist/react-datepicker.css";
import { DAILY, WEEKLY, MONTHLY } from "../../utilis/const";
import {
    firstDayOfSecondMonth,
    getFirstOfYear,
    beforeMonthsOfCurrentTime,
} from "../../utilis/functions";
import { useDispatch, useSelector } from "react-redux";
import { getNumberMappingPerformed } from "../../redux/actions/PartnerReportAction";
import SyncLoader from "react-spinners/SyncLoader";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment";

//import { DateRange } from "react-date-range";
const NumberMappingPerformed = (props) => {
    const { token } = props;
    const [startDate, setStartDate] = useState(new Date(getFirstOfYear()));
    const [endDate, setEndDate] = useState(new Date());
    const [showRangeModel, setShowRangeModel] = useState(false);
    const dispatch = useDispatch();
    const [type, setType] = useState(MONTHLY);
    const { numberMappingPerformed, loadingNumberMappingPerformed } =
        useSelector((state) => ({
            numberMappingPerformed:
                state.partnerReportReducer.numberMappingPerformed ?? [],
            loadingNumberMappingPerformed:
                state.partnerReportReducer.loadingNumberMappingPerformed,
        }));

    useEffect(() => {
        dispatch(
            getNumberMappingPerformed(
                {
                    from: dateFormat(startDate),
                    to: dateFormat(endDate),
                    type: type,
                },
                token
            )
        );
    }, []);

    const firstUpdate = useRef(true);
    useLayoutEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        dispatch(
            getNumberMappingPerformed(
                {
                    from: dateFormat(startDate),
                    to: dateFormat(endDate),
                    type: type,
                },
                token
            )
        );
    }, [startDate, endDate, type]);

    const dateFormat = (datte) => {
        var currentDate = new Date(datte);
        var date = currentDate?.getDate();
        var month = currentDate?.getMonth();
        var year = currentDate?.getFullYear();
        var dateString = year + "-" + (month + 1) + "-" + date;
        return dateString;
    };

    const defaultOptionsDataSet1 = {
        chart: {
            type: "line",
        },
        title: {
            text: "",
        },
        subtitle: {
            text: "",
        },
        xAxis: {
            categories: numberMappingPerformed?.map((e) => e?.mapping_date),
            crosshair: true,
            tickInterval:
                numberMappingPerformed?.map((e) => e?.mapping_date)?.length < 10
                    ? 1
                    : 10,
            labels: {
                formatter: function () {
                    //  const tickInterval = this.axis.tickInterval;
                    return this.value;
                },
            },
            tickWidth: 1,
            tickmarkPlacement: "on",
        },
        yAxis: {
            min: 0,
            title: {
                text: "",
            },
        },
        tooltip: {
            headerFormat:
                '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat:
                '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y}</b></td></tr>',
            footerFormat: "</table>",
            shared: true,
            useHTML: true,
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0,
            },
        },
        series: [
            {
                name: "Mappings",
                data: numberMappingPerformed?.map((e) => [
                    e?.mapping_date,
                    e?.mapping_count,
                ]),

                color: "#5f76e8c7",
                // cursor: "pointer",
                // point: {
                //     events: {
                //         click: (e) => {
                //             console.log(this);
                //             console.log(e.point.category);
                //             console.log(e.point.y);
                //         },
                //     },
                // },
            },
        ],
        //resize: true,
        credits: {
            enabled: false,
        },
    };

    const renderTooltip = (name) => (props) =>
        (
            <Tooltip id="button-tooltip" {...props}>
                {name}
            </Tooltip>
        );
    return (
        <div className="card" style={{ minHeight: "608px" }}>
            <div className="card-header">
                <h4
                    className="card-title"
                    style={{
                        marginBottom: "0px",
                        paddingLeft: "15px",
                    }}
                >
                    <i
                        className="fas fa-chart-bar"
                        style={{ marginRight: "4px" }}
                    ></i>
                    Mappings Performed Over Time
                    <OverlayTrigger
                        placement="top"
                        delay={{
                            show: 250,
                            hide: 400,
                        }}
                        overlay={renderTooltip(
                            "This is the number of mappings performed by the frequency of your choice (daily, weekly or monthly)"
                        )}
                    >
                        <i
                            className="fas fa-info-circle"
                            style={{ marginLeft: "4px" }}
                        ></i>
                    </OverlayTrigger>
                </h4>
            </div>
            <div
                className="card-header"
                style={{
                    backgroundColor: "white",
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <div style={{ width: "100%" }}>
                    <button
                        className="btn"
                        onClick={() => {
                            setShowRangeModel(true);
                        }}
                        style={{ padding: "0px", width: "50%" }}
                    >
                        <input
                            className="form-control"
                            name="dateRange"
                            id="dateRange"
                            autoComplete="off"
                            value={`${moment(new Date(startDate)).format(
                                "DD/MM/YYYY"
                            )} - ${moment(new Date(endDate)).format(
                                "DD/MM/YYYY"
                            )}`}
                        />
                    </button>
                </div>
                <div>
                    <div
                        role="group"
                        className="btn-group btn-group-sm"
                        style={{ height: "40px" }}
                    >
                        <button
                            type="button"
                            className={`btn btn-${
                                type === DAILY ? "primary" : "light"
                            } btn-start-group`}
                            onClick={() => {
                                if (
                                    (dateFormat(new Date(startDate)) ===
                                        dateFormat(
                                            new Date(
                                                beforeMonthsOfCurrentTime(3)
                                            )
                                        ) &&
                                        dateFormat(new Date(endDate)) ===
                                            dateFormat(new Date()) &&
                                        type === WEEKLY) ||
                                    (dateFormat(new Date(startDate)) ===
                                        dateFormat(
                                            new Date(getFirstOfYear())
                                        ) &&
                                        dateFormat(new Date(endDate)) ===
                                            dateFormat(new Date()) &&
                                        type === MONTHLY)
                                ) {
                                    setStartDate(
                                        new Date(firstDayOfSecondMonth())
                                    );
                                    setEndDate(new Date());
                                }
                                setType(DAILY);
                            }}
                        >
                            Daily
                        </button>
                        <button
                            type="button"
                            className={`btn btn-${
                                type === WEEKLY ? "primary" : "light"
                            }`}
                            onClick={() => {
                                if (
                                    (dateFormat(new Date(startDate)) ===
                                        dateFormat(
                                            new Date(firstDayOfSecondMonth())
                                        ) &&
                                        dateFormat(new Date(endDate)) ===
                                            dateFormat(new Date()) &&
                                        type === DAILY) ||
                                    (dateFormat(new Date(startDate)) ===
                                        dateFormat(
                                            new Date(getFirstOfYear())
                                        ) &&
                                        dateFormat(new Date(endDate)) ===
                                            dateFormat(new Date()) &&
                                        type === MONTHLY)
                                ) {
                                    setStartDate(
                                        new Date(beforeMonthsOfCurrentTime(3))
                                    );
                                    setEndDate(new Date());
                                }
                                setType(WEEKLY);
                            }}
                        >
                            Weekly
                        </button>
                        <button
                            type="button"
                            className={`btn btn-${
                                type === MONTHLY ? "primary" : "light"
                            } btn-end-group`}
                            onClick={() => {
                                if (
                                    (dateFormat(new Date(startDate)) ===
                                        dateFormat(
                                            new Date(firstDayOfSecondMonth())
                                        ) &&
                                        dateFormat(new Date(endDate)) ===
                                            dateFormat(new Date()) &&
                                        type === DAILY) ||
                                    (dateFormat(new Date(startDate)) ===
                                        dateFormat(
                                            new Date(
                                                beforeMonthsOfCurrentTime(3)
                                            )
                                        ) &&
                                        dateFormat(new Date(endDate)) ===
                                            dateFormat(new Date()) &&
                                        type === WEEKLY)
                                ) {
                                    setStartDate(new Date(getFirstOfYear()));
                                    setEndDate(new Date());
                                }

                                setType(MONTHLY);
                            }}
                        >
                            Monthly
                        </button>
                    </div>
                </div>
            </div>
            <div className="card-body">
                {loadingNumberMappingPerformed ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            padding: "20px",
                        }}
                    >
                        <SyncLoader
                            color="#5856d694"
                            loading={true}
                            size={10}
                        />
                    </div>
                ) : (
                    <>
                        {numberMappingPerformed?.length === 0 ? (
                            <div className="home-img-container">
                                <img
                                    src="../../assets/images/mappingNumberBlur.png"
                                    alt="Mappings Performed Over Time no Data"
                                    style={{ width: "85%" }}
                                />
                                <div className="home-img-text-container-centered ">
                                    <span>
                                        This graph will be available when you
                                        have enough data to display.
                                    </span>
                                </div>
                            </div>
                        ) : (
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={defaultOptionsDataSet1}
                            />
                        )}
                    </>
                )}
            </div>
            {showRangeModel && (
                <DateRangePickerModel
                    show={showRangeModel}
                    handleClose={() => setShowRangeModel(false)}
                    startDate={startDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    setStartDate={setStartDate}
                />
            )}
        </div>
    );
};

export default NumberMappingPerformed;

const DateRangePickerModel = (props) => {
    const { show, handleClose, startDate, endDate, setStartDate, setEndDate } =
        props;
    const [state, setState] = useState([
        {
            startDate: startDate,
            endDate: endDate,
            key: "selection",
        },
    ]);
    return (
        <Modal
            show={show}
            onHide={handleClose}
            dialogClassName="modal-dialog modal-dialog-centered"
        >
            <DateRangePicker
                onChange={(item) => {
                    setState([item.selection]);
                    setStartDate(item.selection?.startDate);
                    setEndDate(item.selection?.endDate);
                    if (item.selection?.startDate !== item.selection?.endDate) {
                        handleClose();
                    }
                }}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={2}
                ranges={state}
                direction="horizontal"
                rangeColors={["#5f76e8c7"]}
                // maxDate={new Date()}
            />
        </Modal>
    );
};
