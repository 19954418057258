import {
    GET_AFFILIATE_PARTNER_SUCCESS,
    GET_AFFILIATE_PARTNER_LISTING,
    GET_AFFILIATE_PARTNER_FAIL,
} from "../types";
import axios from "axios";

const PATH_URL = process.env.REACT_APP_BASE_URL;

export const getAffiliatePartnerList = () => async (dispatch) => {
    const company_id = localStorage.getItem("companyId");
    dispatch({
        type: GET_AFFILIATE_PARTNER_LISTING,
    });
    try {
        const res = await axios.get(
            PATH_URL + `/company/${company_id}/affiliates/`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }
        );
        dispatch({
            type: GET_AFFILIATE_PARTNER_SUCCESS,
            payload: res.data.data,
        });
    } catch (e) {
        dispatch({
            type: GET_AFFILIATE_PARTNER_FAIL,
            payload: console.log("Error in actions ", e),
        });
    }
};
