import React from "react";
import { Spinner } from "react-bootstrap";
import DataTable from "react-data-table-component";
import "react-data-table-component-extensions/dist/index.css";

const TablePaginationFromApi = ({
    data,
    columns,
    handlePageChange,
    onChangeRowsPerPage,
    totalRows,
    progressPending,
    onFilter,
    filterValue,
    handleClearFilter,
    onChange,
    filter,
    resetPaginationToggle,
    onSort,
    sortServer,
    noDataMessage,
}) => {
    const subHeaderComponentMemo = React.useMemo(() => {
        return (
            <FilterComponent
                onFilter={onFilter}
                onClear={handleClearFilter}
                filterText={filterValue}
                onChange={onChange}
            />
        );
    }, [filterValue]);

    const CustomLoader = () => {
        return (
            <div
                style={{
                    width: "100%",
                    height: "526px",
                    padding: "16px",
                    background: "white",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "inline-flex",
                }}
            >
                {" "}
                <Spinner
                    animation="border"
                    style={{
                        color: "#6275E7",
                        width: "48px",
                        height: "48px",
                    }}
                />
            </div>
        );
    };

    return (
        <div style={{ marginBottom: "10px" }}>
            <DataTable
                columns={columns}
                data={data}
                pagination
                paginationServer
                paginationResetDefaultPage={resetPaginationToggle}
                paginationDefaultPage={1}
                paginationTotalRows={totalRows}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={onChangeRowsPerPage}
                progressPending={progressPending}
                subHeaderComponent={subHeaderComponentMemo}
                subHeader={filter ?? false}
                paginationRowsPerPageOptions={[10, 20, 30]}
                noDataComponent={
                    noDataMessage ?? "This attribute was not found"
                } //or your component
                onSort={onSort}
                sortServer={sortServer ?? false}
                progressComponent={<CustomLoader />}
            />
        </div>
    );
};

export default TablePaginationFromApi;

const FilterComponent = (props) => {
    const { onFilter, onClear, filterText, onChange } = props;
    return (
        <div
            className="input-group"
            style={{ display: "flex", justifyContent: "flex-end" }}
        >
            <div id="search-autocomplete" className="form-outline">
                <input
                    type="text"
                    id="form1"
                    className="form-control"
                    placeholder="Search"
                    value={filterText}
                    onChange={onChange}
                    style={{ borderRadius: "5px 0px 0px 5px", height: "41px" }}
                />
            </div>
            <button
                type="button"
                className="btn btn-primary"
                onClick={onFilter}
                style={{
                    paddingLeft: "15px",
                    paddingRight: "15px",
                    borderRadius: "0px",
                }}
            >
                <i className="fas fa-search"></i>
            </button>
            <button
                type="button"
                className="btn btn-warning"
                onClick={onClear}
                style={{ borderRadius: "0px 5px 5px 0px" }}
            >
                <img
                    src="../../assets/images/reset.png"
                    alt="wrapkit"
                    style={{
                        width: " 20px",
                        marginBottom: "3px",
                        marginRight: "3px",
                    }}
                />
            </button>
        </div>
    );
};
