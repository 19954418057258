import axios from "axios";
const url = process.env.REACT_APP_BASE_URL;

const SegmentService = {
    createSegment: async (data) => {
        const opt = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        const result = await axios
            .post(url + "/catalogs/segments", data, opt)
            .then((res) => {
                return {
                    data: res.data,
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: error.response.data.msg,
                    status: error.response.data.code,
                };
            });
        return result;
    },
    downloadSegment: async (id) => {
        const result = await axios
            .get(url + `/catalogs/segments/download/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                responseType: "blob",
            })
            .then((res) => {
                const re = new RegExp('filename=\\"([a-zA-Z0-9.\\-_]+)');
                return {
                    data: res.data,
                    fileName:
                        res.headers?.["content-disposition"].match(re)?.[1],
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: error.response.data.message,
                    status: error.response.data.code,
                };
            });
        return result;
    },

    searchHotelCities: async (city_name) => {
        const result = await axios
            .get(url + `/list-cities`, {
                params: {
                    q: city_name,
                },
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                return {
                    data: res.data?.data,
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: error.response.data.message,
                    status: error.response.data.code,
                };
            });
        return result;
    },
};
export default SegmentService;
