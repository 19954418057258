import React, { useEffect } from "react";
import SearchForm from "./SearchForm";
import SearchHotelResult from "./SearchHotelResult";
import { useDispatch, useSelector } from "react-redux";
import { searchHotels } from "../../redux/actions/ExploreMappingActions";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import CatalogNoDataModal from "../../pages/Mapping/CatalogNoDataModal";
import { getCatalogs } from "../../redux/actions/CatalogActions";
import { CREATE_INVENTORY } from "../../utilis/const";
import { hasPermission } from "../../utilis/functions";

function ExploreMapping() {
    const dispatch = useDispatch();
    const history = useHistory();
    const [filters, setFilters] = React.useState({});
    const [showResults, setShowResults] = React.useState();
    const { activeCatalog, loading } = useSelector((state) => ({
        activeCatalog: state.catalogReducer?.catalogs?.find(
            (e) => e.active === true
        ),
        loading: state.catalogReducer?.loading,
    }));

    useEffect(() => {
        dispatch(getCatalogs());
    }, []);
    const handleSearch = () => {
        dispatch(
            searchHotels({
                ...filters,
                country: filters?.country?.iso2,
                hotel_name: filters?.hotel_name?.label,
            })
        );
        setShowResults(true);
    };
    return (
        <>
            <Helmet>
                <title>Explore mapping</title>
            </Helmet>
            <div className="page-wrapper" style={{ display: "block" }}>
                <div className="page-breadcrumb">
                    <div className="row">
                        <div className="col-7 align-self-center">
                            <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
                                Explore mapping
                            </h3>
                        </div>
                        <div className="col-12">
                            <p className="card-text">
                                <span className="sub-title-info ">
                                    <i className="fa  fa-info-circle"></i>
                                    &nbsp;You can search for a property from
                                    your inventory and get the details of the
                                    partner properties it has been mapped with.
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    {loading ? (
                        <div className="text-center">
                            <div className="lds-ripple">
                                <div className="lds-pos"></div>
                                <div className="lds-pos"></div>
                            </div>
                        </div>
                    ) : !activeCatalog ? (
                        <CatalogNoDataModal
                            title="Ready!"
                            text2="Your dedicated workspace is almost ready to go !"
                            text3="First, you’ll need to upload your hotel inventory."
                            buttonText="Upload inventory"
                            handleClick={async () => {
                                history.push("/catalogs-manager");
                            }}
                            hideButton={
                                hasPermission(CREATE_INVENTORY) ? false : true
                            }
                        />
                    ) : (
                        <>
                            <SearchForm
                                handleSearch={handleSearch}
                                setFilters={setFilters}
                                filters={filters}
                            />
                            <SearchHotelResult showResults={showResults} />
                        </>
                    )}
                </div>
            </div>
        </>
    );
}
export default ExploreMapping;
