import React from "react";
import { Modal, Spinner } from "react-bootstrap";

function ConfirmAction(props) {
    const {
        show,
        onClose,
        titleMessage,
        subTitleMessage,
        colorCode,
        handleClick,
        buttonText,
        disabled,
        subTitleMessage1,
        subTitleMessage2,
        showButton,
    } = props;

    return (
        <Modal
            show={show}
            onHide={onClose}
            dialogClassName="modal-40w modal-dialog modal-dialog-centered"
        >
            <Modal.Body style={{ textAlign: "center" }}>
                <h2
                    style={{
                        color: "#ff4f70",
                        fontWeight: "500",
                        fontSize: "30px",
                    }}
                >
                    {titleMessage}
                </h2>
                <p
                    style={{
                        fontWeight: "500",
                        fontSize: "18px",
                        marginTop: "20px",
                        marginBottom: "4px",
                    }}
                >
                    {subTitleMessage}
                </p>
                {subTitleMessage1 && (
                    <p
                        style={{
                            fontWeight: "500",
                            fontSize: "18px",
                            marginTop: "2px",
                        }}
                    >
                        {subTitleMessage1}
                    </p>
                )}
                {subTitleMessage2 && (
                    <p style={{ fontWeight: "bold", fontSize: "18px" }}>
                        {subTitleMessage2}
                    </p>
                )}
                {!showButton && (
                    <button
                        className="btn waves-effect waves-light btn-rounded btn-warning"
                        style={{
                            margin: "6px",
                            marginTop: "10px",
                            color: "white",
                        }}
                        onClick={() => {
                            onClose();
                        }}
                    >
                        Cancel
                    </button>
                )}
                <button
                    className={`btn waves-effect waves-light btn-rounded btn-${colorCode}`}
                    style={{ margin: "6px", color: "white", marginTop: "10px" }}
                    onClick={handleClick}
                    disabled={disabled}
                >
                    {buttonText}
                    {disabled && (
                        <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    )}
                </button>
            </Modal.Body>
        </Modal>
    );
}

export default ConfirmAction;
