import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import LoginService from "../../../services/LoginService";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step4 from "./Step4";
import Step3 from "./Step3";
import BuildService from "../../../services/BuildService";
import { getUserConnected } from "../../../redux/actions/DashboardActions";
import { useDispatch } from "react-redux";
import "../style.css";
import { Helmet } from "react-helmet";

function SignUp(props) {
    // const history = useHistory();
    // const [loading, setLoading] = useState(false);
    // const [loadingLogin, setLoadingLogin] = useState(false);
    // const [step, setStep] = useState(1);
    // const [selectedOption, setSelectedOption] = useState();
    // const dispatch = useDispatch();
    // const {
    //     register,
    //     handleSubmit,
    //     formState: { errors },
    //     getValues,
    //     setValue,
    // } = useForm({
    //     mode: "onChange",
    //     resolver: yupResolver(schema),
    // });
    // const handleSignUp = async (data) => {
    //     //  if (selectedOption) {
    //     setLoading(true);
    //     let res = await LoginService.signUp(data);
    //     try {
    //         if (res.status === 200) {
    //             toast.success("Created !");
    //             setLoadingLogin(true);
    //             let res = await LoginService.signIn({
    //                 login: data?.email,
    //                 password: data?.password,
    //             });
    //             try {
    //                 if (res.status === 200) {
    //                     localStorage.setItem("isAuthenticated", "true");
    //                     localStorage.setItem(
    //                         "userConnected",
    //                         data.first_name + " " + data.last_name
    //                     );
    //                     localStorage.setItem(`welcome_${data?.email}`, true);
    //                     localStorage.setItem(`email`, data?.email);

    //                     await BuildService.getApiKeys();
    //                     await dispatch(getUserConnected());
    //                     //history.push("/home");
    //                     window.location =
    //                         window.location.protocol +
    //                         "//" +
    //                         window.location.host +
    //                         "/home";

    //                     window.analytics.track("Sign up", {
    //                         firstName: data.first_name,
    //                         lastName: data.last_name,
    //                         email: data?.email,
    //                     });
    //                     window.analytics.identify(
    //                         localStorage.getItem("userId"),
    //                         {
    //                             firstName: data.first_name,
    //                             lastName: data.last_name,
    //                             email: data?.email,
    //                         }
    //                     );
    //                     window.analytics.page();
    //                     // setLoadingLogin(false);
    //                     // setLoading(false);
    //                 } else {
    //                     setLoadingLogin(false);
    //                     toast.error(res.message);
    //                 }
    //             } catch (err) {
    //                 console.log("Error", err);
    //                 setLoadingLogin(false);
    //                 toast.error("Server error");
    //             }
    //         } else {
    //             setLoading(false);
    //             toast.error(res.message);
    //         }
    //     } catch (err) {
    //         console.log("Error", err);

    //         setLoading(false);
    //         toast.error("Server error");
    //     }
    //     //  }
    // };
    // const handleStep = () => {
    //     switch (step) {
    //         case 1:
    //             return (
    //                 <Step1
    //                     register={register}
    //                     errors={errors}
    //                     setStep={setStep}
    //                     getValues={getValues}
    //                     setValue={setValue}
    //                 />
    //             );
    //         case 2:
    //             return (
    //                 <Step2
    //                     register={register}
    //                     errors={errors}
    //                     setStep={setStep}
    //                     getValues={getValues}
    //                     setValue={setValue}
    //                 />
    //             );
    //         case 3:
    //             return (
    //                 <Step3
    //                     register={register}
    //                     errors={errors}
    //                     loading={loading}
    //                     getValues={getValues}
    //                     setStep={setStep}
    //                 />
    //             );
    //         case 4:
    //             return (
    //                 <Step4
    //                     register={register}
    //                     errors={errors}
    //                     loading={loading}
    //                     selectedOption={selectedOption}
    //                     setSelectedOption={setSelectedOption}
    //                 />
    //             );
    //         default:
    //             return <></>;
    //     }
    // };

    return (
        <>
            <Helmet>
                <title> Sign up</title>
            </Helmet>
             <section className="NotfoundPage w-100 py-5 d-flex flex-column justify-content-center align-items-center position-relative overflow-hidden">
                <div className="d-flex flex-column justify-content-center align-items-center gap-3 mx-auto" style={{maxWidth: "500px",height:"70vh"}}>
                    <img src="/assets/images/calendar.svg" alt="not found" style={{width:"200px", height:"auto"}} />
                    <h4 className="text-center text-dark font-weight-bold my-4 " style={{lineHeight:"1.5"}}>
                        To set up an account, please schedule a call with us, and we'll be happy
                        to assist you personally.
                    </h4>
                    <p className="text-center text-muted small mb-3" style={{color:"#475467",fontSize:"16px"}}>
                        Just click the link below to find a time that works for you.
                    </p>
                    <a
                        href="https://calendly.com/mani-cru/cupid-mapping-product-intro"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-center text-primary font-gilmer-medium text-decoration-underline mb-3"
                        style={{fontSize:"17px", textDecoration:"underline"}}
                    >
                        https://calendly.com/mani-cru/cupid-mapping-product-intro
                    </a>
                         <button
                            onClick={() => window.location.href = "https://calendly.com/mani-cru/cupid-mapping-product-intro"}
                            type="button"
                            className="btn btn-primary rounded-pill shadow d-flex justify-content-center align-items-center gap-2"
                        >
                        <span className="text-white">Schedule Demo</span>
                        </button>
                </div>
            </section>
            {/* {loadingLogin ? (
                <div className="text-center">
                    <div className="lds-ripple">
                        <div className="lds-pos"></div>
                        <div className="lds-pos"></div>
                    </div>
                </div>
            ) : (
                <div
                    data-aos-easing="ease-out-quad"
                    data-aos-duration="700"
                    data-aos-delay="0"
                >
                    <section className="bg-gray-200">
                        <div className="container d-flex flex-column">
                            <div className="row align-items-center justify-content-center gx-0 min-vh-100">
                                <div className="col-12-sign-in col-md-5 col-lg-5 py-8 py-md-11">
                                    {step === 4 ? (
                                        <>
                                            <h1 className="mb-0 fw-bold text-center">
                                                Almost done!
                                            </h1>

                                            <p className="mb-6 text-center text-muted">
                                                Before we dive in, help us
                                                tailor your workspace for you.
                                            </p>
                                        </>
                                    ) : step === 2 ? (
                                        <>
                                            <h1 className="mb-0 fw-bold text-center">
                                                Confirm your email
                                            </h1>

                                            <p className="mb-6 text-center text-muted">
                                                We just sent you a temporary
                                                confirmation code. <br />
                                                Please check your email inbox.
                                            </p>
                                        </>
                                    ) : (
                                        <>
                                            <h1 className="mb-0 fw-bold text-center">
                                                Create your free account
                                            </h1>

                                            <p className="mb-6 text-center text-muted"></p>
                                        </>
                                    )}

                                    <form
                                        className="mb-6"
                                        onSubmit={handleSubmit(handleSignUp)}
                                    >
                                        {handleStep()}
                                        {step !== 4 && step !== 2 && (
                                            <p className="mb-6 text-center text-muted fs-sm mt-6">
                                                By Continuing, you are agreeing
                                                to our
                                                <a
                                                    href="https://cupid.travel/terms-and-conditions-of-use-cupid-travel/"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    style={{
                                                        marginLeft: "4px",
                                                        marginRight: "4px",
                                                    }}
                                                >
                                                    Customer Terms of Service
                                                </a>
                                                and
                                                <a
                                                    href="https://cupid.travel/privacy-policy/"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    style={{
                                                        marginLeft: "4px",
                                                    }}
                                                >
                                                    Privacy Policy
                                                </a>
                                            </p>
                                        )}
                                    </form>

                                    {step !== 2 && (
                                        <p className="mb-0 fs-sm text-center text-muted">
                                            Already have an account?{" "}
                                            <a href="/sign-in">Log in</a>.
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            )} */}
        </>
    );
}
export default SignUp;

const schema = yup.object().shape({
    first_name: yup.string().required("First name is required"),
    brand_name: yup.string().required("Company name is required"),
    last_name: yup.string().required("Last name is required"),
    email: yup
        .string()
        .email("Must be a valid email")
        .required("Email is required"),
    password: yup
        .string()
        .min(8)
        .required("Password is required and must be 8 characters length"),
    verification_code: yup.string().required("Verification code is required"),
});
