import React, { useState, useEffect } from "react";
import { getTimeserieInventoryMappedRate } from "../../redux/actions/AnalyticsActions";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { DateRangePicker } from "react-date-range";
import "react-datepicker/dist/react-datepicker.css";
import { firstDayOfMonth } from "../../utilis/functions";
import { useDispatch, useSelector } from "react-redux";
import SyncLoader from "react-spinners/SyncLoader";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment";

const TimeSerieInventoryMappedRate = () => {
    const [startDate, setStartDate] = useState(new Date(firstDayOfMonth()));
    const [endDate, setEndDate] = useState(new Date());
    const [showRangeModel, setShowRangeModel] = useState(false);
    const dispatch = useDispatch();
    const {
        timeSerieInventoryMapppedRate,
        loadingTimeSerieInventoryMappedRate,
    } = useSelector((state) => ({
        timeSerieInventoryMapppedRate:
            state.analyticsReducer.timeSerieInventoryMapppedRate ?? {},
        loadingTimeSerieInventoryMappedRate:
            state.analyticsReducer.loadingTimeSerieInventoryMappedRate,
    }));

    useEffect(() => {
        if (startDate && endDate) {
            dispatch(
                getTimeserieInventoryMappedRate({
                    from: dateFormat(startDate),
                    to: dateFormat(endDate),
                })
            );
        }
    }, [startDate, endDate]);

    const dateFormat = (datte) => {
        var currentDate = new Date(datte);
        var date = currentDate?.getDate();
        var month = currentDate?.getMonth();
        var year = currentDate?.getFullYear();

        var dateString = year + "-" + (month + 1) + "-" + date;
        return dateString;
    };

    const renderTooltip = (name) => (props) =>
        (
            <Tooltip id="button-tooltip" {...props}>
                {name}
            </Tooltip>
        );

    function ParseFloat(str, val) {
        str = str?.toString();
        str = str?.slice(0, str.indexOf(".") + val + 1);
        return Number(str);
    }
    const defaultOptionsDataSet1 = {
        chart: {
            zoomType: "xy",
        },
        title: {
            text: "",
        },

        xAxis: [
            {
                categories: Object.keys(timeSerieInventoryMapppedRate),
                crosshair: true,
            },
        ],
        yAxis: [
            {
                // Secondary yAxis

                labels: {
                    format: "{value} %",
                    style: {
                        color: Highcharts.getOptions().colors[0],
                    },
                },
                title: {
                    text: "",
                    style: {
                        color: Highcharts.getOptions().colors[0],
                    },
                },
                opposite: true,
            },
            {
                // Primary yAxis
                labels: {
                    format: "",
                    style: {
                        color: Highcharts.getOptions().colors[1],
                    },
                },
                title: {
                    text: "",
                    style: {
                        color: Highcharts.getOptions().colors[1],
                    },
                },
            },
        ],
        tooltip: {
            shared: false,
        },
        legend: {
            //  align: "center",
            //  x: 80,
            // verticalAlign: "bottom",
            //  y: 80,
            //   floating: true,
            backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || // theme
                "rgba(255,255,255,0.25)",
        },
        plotOptions: {
            column: {
                stacking: "normal",
                dataLabels: {
                    enabled: false,
                },
            },
        },
        series: [
            {
                name: "Total invalid",
                type: "column",
                yAxis: 1,
                data: Object.keys(timeSerieInventoryMapppedRate ?? {})?.map(
                    (e) => [
                        timeSerieInventoryMapppedRate?.[e]?.created_at,
                        timeSerieInventoryMapppedRate?.[e]
                            ?.inventory_total_count -
                            timeSerieInventoryMapppedRate?.[e]?.valid_count,
                    ]
                ),
                color: "rgba(18, 46, 64, 0.14)",
            },
            {
                name: "Total Unmapped",
                type: "column",
                yAxis: 1,
                data: Object.keys(timeSerieInventoryMapppedRate ?? {})?.map(
                    (e) => [
                        timeSerieInventoryMapppedRate?.[e]?.created_at,

                        timeSerieInventoryMapppedRate?.[e]?.valid_count -
                            timeSerieInventoryMapppedRate?.[e]?.mapped_count,
                    ]
                ),
                color: "rgb(242, 95, 41)",
            },
            {
                name: "Total mapped",
                type: "column",
                yAxis: 1,
                data: Object.keys(timeSerieInventoryMapppedRate ?? {})?.map(
                    (e) => [
                        timeSerieInventoryMapppedRate?.[e]?.created_at,
                        timeSerieInventoryMapppedRate?.[e]?.mapped_count,
                    ]
                ),
                color: "rgb(21, 191, 174)",
            },
            {
                name: "Mapped rate ",
                type: "spline",
                data: Object.keys(timeSerieInventoryMapppedRate ?? {})?.map(
                    (e) => [
                        timeSerieInventoryMapppedRate?.[e]?.created_at,
                        ParseFloat(
                            timeSerieInventoryMapppedRate?.[e]?.mapping_rate,
                            3
                        ),
                    ]
                ),
                tooltip: {
                    valueSuffix: "%",
                },
                color: "rgba(95, 118, 232, 0.78)",
            },
        ],

        credits: {
            enabled: false,
        },
    };
    return (
        <div className="card" style={{ minHeight: "608px" }}>
            <h4 className="chart-title">
                <i
                    className="fas fa-chart-line"
                    style={{ marginRight: "4px" }}
                ></i>
                Inventory Mapping Rate
                <OverlayTrigger
                    placement="top"
                    delay={{
                        show: 250,
                        hide: 400,
                    }}
                    overlay={renderTooltip(
                        "This is a time series of the proportion of your inventory that is mapped with at least one partner."
                    )}
                >
                    <i
                        className="fas fa-info-circle"
                        style={{ marginLeft: "4px" }}
                    ></i>
                </OverlayTrigger>
            </h4>
            <div
                className="card-header"
                style={{
                    backgroundColor: "white",
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <div style={{ width: "100%" }}>
                    <button
                        className="btn"
                        onClick={() => {
                            setShowRangeModel(true);
                        }}
                        style={{ padding: "0px", width: "50%" }}
                    >
                        <input
                            style={{ border: "1px solid hsl(0, 0%, 80%)" }}
                            className="form-control"
                            name="password"
                            id="password"
                            placeholder="Enter your password"
                            autoComplete="off"
                            value={`${moment(new Date(startDate)).format(
                                "DD/MM/YYYY"
                            )} - ${moment(new Date(endDate)).format(
                                "DD/MM/YYYY"
                            )}`}
                        />
                    </button>
                </div>
            </div>
            <div className="card-body">
                {loadingTimeSerieInventoryMappedRate ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            padding: "20px",
                        }}
                    >
                        <SyncLoader
                            color="#5856d694"
                            loading={true}
                            size={10}
                        />
                    </div>
                ) : (
                    <>
                        {Object.keys(timeSerieInventoryMapppedRate)?.length ===
                        0 ? (
                            <div className="home-img-container">
                                <img
                                    src="../../assets/images/inventoryMappedRate.png"
                                    alt="Mappings Performed Over Time no Data"
                                    style={{ width: "100%" }}
                                />
                                <div className="home-img-text-container-centered ">
                                    <span>
                                        This graph will be available when you
                                        have enough data to display.
                                    </span>
                                </div>
                            </div>
                        ) : (
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={defaultOptionsDataSet1}
                            />
                        )}
                    </>
                )}
            </div>
            {showRangeModel && (
                <DateRangePickerModel
                    show={showRangeModel}
                    handleClose={() => setShowRangeModel(false)}
                    startDate={startDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    setStartDate={setStartDate}
                />
            )}
        </div>
    );
};

export default TimeSerieInventoryMappedRate;

const DateRangePickerModel = (props) => {
    const { show, handleClose, startDate, endDate, setStartDate, setEndDate } =
        props;
    const [state, setState] = useState([
        {
            startDate: startDate,
            endDate: endDate,
            key: "selection",
        },
    ]);
    return (
        <Modal
            show={show}
            onHide={handleClose}
            dialogClassName="modal-dialog modal-dialog-centered"
        >
            <DateRangePicker
                onChange={(item) => {
                    setState([item.selection]);
                    setStartDate(item.selection?.startDate);
                    setEndDate(item.selection?.endDate);
                    if (item.selection?.startDate !== item.selection?.endDate) {
                        handleClose();
                    }
                }}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={2}
                ranges={state}
                direction="horizontal"
                rangeColors={["#5f76e8c7"]}
            />
        </Modal>
    );
};
