import {
    GET_HOTELS_INVENTORY_LIST_LISTING,
    GET_HOTELS_INVENTORY_LIST_SUCCESS,
    GET_HOTELS_INVENTORY_LIST_FAIL,
    GET_REFERENCE_HOTEL_SUCCESS,
    GET_REFERENCE_HOTEL_FAIL,
    GET_REFERENCE_HOTEL_LISTING,
} from "../types";
import axios from "axios";

const PATH_URL = process.env.REACT_APP_BASE_URL;

export const getHotelList =
    (page, perPage, filters, sort_by, sort_order) => async (dispatch) => {
        console.log("page", page, perPage);
        dispatch({
            type: GET_HOTELS_INVENTORY_LIST_LISTING,
        });
        try {
            var params = new URLSearchParams();
            params.append("offset", (page - 1) * perPage);
            params.append("limit", perPage);
            if (sort_by) {
                params.append("sort_by", sort_by);
            }
            if (sort_order) {
                params.append("sort_order", sort_order);
            }
            const res = await axios.post(
                PATH_URL + "/hotel-catalogs/list",
                filters,
                {
                    params: params,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem(
                            "token"
                        )}`,
                    },
                }
            );
            dispatch({
                type: GET_HOTELS_INVENTORY_LIST_SUCCESS,
                payload: res?.data?.data,
                total: res.data.total,
            });
        } catch (e) {
            dispatch({
                type: GET_HOTELS_INVENTORY_LIST_FAIL,
                payload: console.log("Error in actions ", e),
            });
        }
    };

export const getHotelsDetails = (id) => async (dispatch) => {
    dispatch({
        type: GET_REFERENCE_HOTEL_LISTING,
    });
    try {
        const res = await axios.get(
            PATH_URL + `/hotel-catalogs/get-reference-hotel-details/${id}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }
        );
        dispatch({
            type: GET_REFERENCE_HOTEL_SUCCESS,
            payload: res?.data?.data,
        });
    } catch (e) {
        dispatch({
            type: GET_REFERENCE_HOTEL_FAIL,
            payload: console.log("Error in actions ", e),
        });
    }
};
