import React, { useState, useEffect } from "react";
import { getMappingRateOverTime } from "../../redux/actions/PartnerReportAction";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { DateRangePicker } from "react-date-range";
import "react-datepicker/dist/react-datepicker.css";
import { firstDayOfSecondMonth, getDays } from "../../utilis/functions";
import { useDispatch, useSelector } from "react-redux";
import SyncLoader from "react-spinners/SyncLoader";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment";

const MappingRateOverTime = (props) => {
    const { token } = props;
    const [startDate, setStartDate] = useState(
        new Date(firstDayOfSecondMonth())
    );
    const [endDate, setEndDate] = useState(new Date());
    const [showRangeModel, setShowRangeModel] = useState(false);
    const dispatch = useDispatch();
    const { loadingMappingRateOverTime, mappingRateOverTime } = useSelector(
        (state) => ({
            mappingRateOverTime:
                state.partnerReportReducer.mappingRateOverTime ?? [],
            loadingMappingRateOverTime:
                state.partnerReportReducer.loadingMappingRateOverTime,
        })
    );

    useEffect(() => {
        dispatch(
            getMappingRateOverTime(
                {
                    from: dateFormat(startDate),
                    to: dateFormat(endDate),
                },
                token
            )
        );
    }, [startDate, endDate]);

    const dateFormat = (datte) => {
        var currentDate = new Date(datte);
        var date = currentDate?.getDate();
        var month = currentDate?.getMonth();
        var year = currentDate?.getFullYear();

        var dateString = year + "-" + (month + 1) + "-" + date;
        return dateString;
    };

    const getSerieData = () => {
        let result = [];
        getDays(startDate, endDate).forEach((tt, index) => {
            let dataPoint = mappingRateOverTime?.find(
                (e) => e?.created_at === tt
            );
            if (dataPoint) {
                result?.push([tt, ParseFloat(dataPoint?.mapping_rate, 2)]);
            } else if (result?.length > 0) {
                result?.push([tt, result[index - 1]?.[1]]);
            } else {
                result?.push([tt, 0]);
            }
        });

        return result;
    };

    const renderTooltip = (name) => (props) =>
        (
            <Tooltip id="button-tooltip" {...props}>
                {name}
            </Tooltip>
        );

    function ParseFloat(str, val) {
        str = str.toString();
        str = str.slice(0, str.indexOf(".") + val + 1);
        return Number(str);
    }
    const defaultOptionsDataSet1 = {
        chart: {
            type: "line",
        },
        title: {
            text: "",
        },
        subtitle: {
            text: "",
        },
        xAxis: {
            categories: getDays(startDate, endDate),
            crosshair: true,
            tickInterval: getDays(startDate, endDate)?.length < 10 ? 1 : 10,
            labels: {
                formatter: function () {
                    //  const tickInterval = this.axis.tickInterval;
                    return this.value;
                },
            },
            tickWidth: 1,
            tickmarkPlacement: "on",

            // labels: {
            //     step: 15,
            //     allowOverlap: true,
            // },
            // tickWidth: 1,
            // tickmarkPlacement: "on",
            // tickPositions: [0, 15, 30, 45,],
        },
        yAxis: {
            min: 0,
            title: {
                text: "",
            },
        },
        tooltip: {
            headerFormat:
                '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat:
                '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y}%</b></td></tr>',
            footerFormat: "</table>",
            shared: true,
            useHTML: true,
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0,
            },

            // series: {
            //     compare: "percent",
            //     showInNavigator: true,
            //     dataGrouping: {
            //         enabled: true,
            //     },
            // },
        },
        series: [
            {
                name: "Mapped",
                data: getSerieData(),
                color: "#5f76e8c7",
            },
        ],
        //resize: true,
        credits: {
            enabled: false,
        },
    };
    return (
        <div className="card" style={{ minHeight: "608px" }}>
            <div className="card-header">
                <h4
                    className="card-title"
                    style={{
                        marginBottom: "0px",
                        paddingLeft: "15px",
                    }}
                >
                    {" "}
                    <i
                        className="fas fa-chart-line"
                        style={{ marginRight: "4px" }}
                    ></i>
                    Mapping rate
                    <OverlayTrigger
                        placement="top"
                        delay={{
                            show: 250,
                            hide: 400,
                        }}
                        overlay={renderTooltip(
                            "This is the evolution of your partner’s mapping rate over time. It is the percentage of your partners catalog that was mapped with your inventory."
                        )}
                    >
                        <i
                            className="fas fa-info-circle"
                            style={{ marginLeft: "4px" }}
                        ></i>
                    </OverlayTrigger>
                </h4>
            </div>
            <div
                className="card-header"
                style={{
                    backgroundColor: "white",
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <div style={{ width: "100%" }}>
                    <button
                        className="btn"
                        onClick={() => {
                            setShowRangeModel(true);
                        }}
                        style={{ padding: "0px", width: "50%" }}
                    >
                        <input
                            style={{ border: "1px solid hsl(0, 0%, 80%)" }}
                            className="form-control"
                            name="password"
                            id="password"
                            placeholder="Enter your password"
                            autoComplete="off"
                            value={`${moment(new Date(startDate)).format(
                                "DD/MM/YYYY"
                            )} - ${moment(new Date(endDate)).format(
                                "DD/MM/YYYY"
                            )}`}
                        />
                    </button>
                </div>
            </div>
            <div className="card-body">
                {loadingMappingRateOverTime ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            padding: "20px",
                        }}
                    >
                        <SyncLoader
                            color="#5856d694"
                            loading={true}
                            size={10}
                        />
                    </div>
                ) : (
                    <>
                        {mappingRateOverTime?.length === 0 ? (
                            <div className="home-img-container">
                                <img
                                    src="../../assets/images/inventoryBlur.png"
                                    alt="Mappings Performed Over Time no Data"
                                    style={{ width: "85%" }}
                                />
                                <div className="home-img-text-container-centered ">
                                    <span>
                                        This graph will be available when you
                                        have enough data to display.
                                    </span>
                                </div>
                            </div>
                        ) : (
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={defaultOptionsDataSet1}
                            />
                        )}
                    </>
                )}
            </div>
            {showRangeModel && (
                <DateRangePickerModel
                    show={showRangeModel}
                    handleClose={() => setShowRangeModel(false)}
                    startDate={startDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    setStartDate={setStartDate}
                />
            )}
        </div>
    );
};

export default MappingRateOverTime;

const DateRangePickerModel = (props) => {
    const { show, handleClose, startDate, endDate, setStartDate, setEndDate } =
        props;
    const [state, setState] = useState([
        {
            startDate: startDate,
            endDate: endDate,
            key: "selection",
        },
    ]);
    return (
        <Modal
            show={show}
            onHide={handleClose}
            dialogClassName="modal-dialog modal-dialog-centered"
        >
            <DateRangePicker
                onChange={(item) => {
                    setState([item.selection]);
                    setStartDate(item.selection?.startDate);
                    setEndDate(item.selection?.endDate);
                    if (item.selection?.startDate !== item.selection?.endDate) {
                        handleClose();
                    }
                }}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={2}
                ranges={state}
                direction="horizontal"
                rangeColors={["#5f76e8c7"]}
            />
        </Modal>
    );
};
