/* eslint-disable import/no-anonymous-default-export */
import {
    GET_MAPPING_CATALOGS_SUCCESS,
    GET_MAPPING_CATALOGS_FAIL,
    GET_MAPPING_CATALOGS_LISTING,
    GET_CATALOG_HOTEL_TO_REVIEW_SUCCESS,
    GET_CATALOG_HOTEL_TO_REVIEW_LISTING,
    GET_CATALOG_HOTEL_TO_REVIEW_FAIL,
    CLEAR_CATALOG_HOTEL_TO_REVIEW,
    DISPLAY_FINISH_MESSAGE,
    GET_CATALOGS_FIRST_FAIL,
    GET_CATALOGS_FIRST_LISTING,
    GET_CATALOGS_FIRST_SUCCESS,
    GET_PARTNER_CATALOGS_BY_ID_FAIL,
    GET_PARTNER_CATALOGS_BY_ID_LISTING,
    GET_PARTNER_CATALOGS_BY_ID_SUCCESS,
    GET_OUTDATED_CATALOGS_FAIL,
    GET_OUTDATED_CATALOGS_SUCCESS,
    GET_OUTDATED_CATALOGS_LISTING,
    GET_MAPPING_CATALOGS_SUCCESS_PERIODIQUE,
} from "../types";

const initialState = {
    partnerCatalogsById: [],
    loadingPartnerCatalogs: false,
    partnerCatalogs: [],
    partnerCatalogsFirstList: [],
    loading: false,
    loadingFirstList: false,
    reviewHotels: [],
    loadingReviewHotel: false,
    catalog: {},
    company: {},
    candidates: 0,
    finish: false,
    loadingOutdatedCatalogs: true,
    outdatedCatalogs: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_MAPPING_CATALOGS_LISTING:
            return {
                ...state,
                loading: true,
            };
        case GET_MAPPING_CATALOGS_SUCCESS:
            return {
                ...state,
                partnerCatalogs: action.payload?.catalogs,
                average: action.payload?.average,
                loading: false,
            };
        case GET_MAPPING_CATALOGS_SUCCESS_PERIODIQUE:
            return {
                ...state,
                partnerCatalogs: action.payload?.catalogs,
                average: action.payload?.average,
            };
        case GET_MAPPING_CATALOGS_FAIL:
            return {
                ...state,
                loading: false,
            };
        case GET_PARTNER_CATALOGS_BY_ID_LISTING:
            return {
                ...state,
                loadingPartnerCatalogs: true,
            };
        case GET_PARTNER_CATALOGS_BY_ID_SUCCESS:
            return {
                ...state,
                partnerCatalogsById: action.payload?.catalogs,
                average: action.payload?.average,
                loadingPartnerCatalogs: false,
            };
        case GET_PARTNER_CATALOGS_BY_ID_FAIL:
            return {
                ...state,
                loadingPartnerCatalogs: false,
            };
        case GET_CATALOGS_FIRST_FAIL:
            return {
                ...state,
                loadingFirstList: true,
            };
        case GET_CATALOGS_FIRST_SUCCESS:
            return {
                ...state,
                partnerCatalogsFirstList: action.payload,
                loadingFirstList: false,
            };

        case GET_CATALOGS_FIRST_LISTING:
            return {
                ...state,
                loadingFirstList: false,
            };
        case GET_CATALOG_HOTEL_TO_REVIEW_LISTING:
            return {
                ...state,
                reviewHotels: [],
                catalog: {},
                company: {},
                candidates: 0,
                loadingReviewHotel: true,
                finish: false,
            };
        case CLEAR_CATALOG_HOTEL_TO_REVIEW:
            return {
                ...state,
                candidates: 0,
                reviewHotels: [],
                catalog: {},
                company: {},
                finish: false,
            };
        case GET_CATALOG_HOTEL_TO_REVIEW_SUCCESS:
            return {
                ...state,
                reviewHotels: action.payload.data,
                catalog: action.payload.catalog,
                company: action.payload.company,
                candidates: action.payload.candidates,
                loadingReviewHotel: false,
            };
        case GET_CATALOG_HOTEL_TO_REVIEW_FAIL:
            return {
                ...state,
                candidates: 0,
                reviewHotels: [],
                catalog: {},
                company: {},
                loadingReviewHotel: false,
                finish: false,
            };
        case DISPLAY_FINISH_MESSAGE:
            return {
                ...state,
                finish: true,
            };
        case GET_OUTDATED_CATALOGS_LISTING:
            return {
                ...state,
                loadingOutdatedCatalogs: true,
                outdatedCatalogs: [],
            };
        case GET_OUTDATED_CATALOGS_SUCCESS:
            return {
                ...state,
                loadingOutdatedCatalogs: false,
                outdatedCatalogs: action.payload,
            };
        case GET_OUTDATED_CATALOGS_FAIL:
            return {
                ...state,
                loadingOutdatedCatalogs: false,
                outdatedCatalogs: [],
            };
        default:
            return state;
    }
}
