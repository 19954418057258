import axios from "axios";
import {
    GET_ALL_PARTNER_KEYS_LISTING,
    GET_ALL_PARTNER_KEYS_SUCCESS,
    GET_ALL_PARTNER_KEYS_FAIL,
    GET_GIATA_PARTNERS_LIST_LISTING,
    GET_GIATA_PARTNERS_LIST_SUCCESS,
    GET_GIATA_PARTNERS_LIST_FAIL,
    GET_GIATA_PARTNERS_LIST_PERIODIQUE_SUCCESS,
    HAS_GIATA_ACCOUNT_LISTING,
    HAS_GIATA_ACCOUNT_SUCCESS,
    HAS_GIATA_ACCOUNT_FAIL,
    REFRESH_GIATA_DATA_LISTING,
    REFRESH_GIATA_DATA_SUCCESS,
    REFRESH_GIATA_DATA_FAIL,
    GET_CUPID_MODELS_LISTING,
    GET_CUPID_MODELS_SUCCESS,
    GET_CUPID_MODELS_FAIL,
} from "../types";

const PATH_URL = process.env.REACT_APP_BASE_URL;
const GIATA_URL = "/giata";

export const getAllPartnerKeys = () => async (dispatch) => {
    dispatch({
        type: GET_ALL_PARTNER_KEYS_LISTING,
    });
    try {
        const res = await axios.get(PATH_URL + GIATA_URL + "/all-partners", {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        dispatch({
            type: GET_ALL_PARTNER_KEYS_SUCCESS,
            payload: res.data,
        });
    } catch (e) {
        dispatch({
            type: GET_ALL_PARTNER_KEYS_FAIL,
            payload: console.log("Error in actions ", e),
        });
    }
};

export const getGiataPartnersList = () => async (dispatch) => {
    dispatch({
        type: GET_GIATA_PARTNERS_LIST_LISTING,
    });
    try {
        const res = await axios.get(PATH_URL + GIATA_URL + "/partners", {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        dispatch({
            type: GET_GIATA_PARTNERS_LIST_SUCCESS,
            payload: res.data,
        });
    } catch (e) {
        dispatch({
            type: GET_GIATA_PARTNERS_LIST_FAIL,
            payload: console.log("Error in actions ", e),
        });
    }
};

export const getGiataPartnersListPeriodique = () => async (dispatch) => {
    try {
        const res = await axios.get(PATH_URL + GIATA_URL + "/partners", {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        dispatch({
            type: GET_GIATA_PARTNERS_LIST_PERIODIQUE_SUCCESS,
            payload: res.data,
        });
    } catch (e) {}
};
export const hasGiataCredentials = () => async (dispatch) => {
    dispatch({
        type: HAS_GIATA_ACCOUNT_LISTING,
    });
    try {
        const res = await axios.get(PATH_URL + GIATA_URL + "/has-account", {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        dispatch({
            type: HAS_GIATA_ACCOUNT_SUCCESS,
            payload: res.data,
        });
    } catch (e) {
        dispatch({
            type: HAS_GIATA_ACCOUNT_FAIL,
            payload: console.log("Error in actions ", e),
        });
    }
};
export const refreshGiataData = () => async (dispatch) => {
    dispatch({
        type: REFRESH_GIATA_DATA_LISTING,
    });
    try {
        const res = await axios.get(PATH_URL + GIATA_URL + "/refresh", {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        dispatch({
            type: REFRESH_GIATA_DATA_SUCCESS,
            payload: res.data,
        });
    } catch (e) {
        dispatch({
            type: REFRESH_GIATA_DATA_FAIL,
            payload: console.log("Error in actions ", e),
        });
    }
};
export const getCupidModels = () => async (dispatch) => {
    dispatch({
        type: GET_CUPID_MODELS_LISTING,
    });
    try {
        const res = await axios.get(PATH_URL + "/cupid_models", {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        dispatch({
            type: GET_CUPID_MODELS_SUCCESS,
            payload: res.data,
        });
    } catch (e) {
        dispatch({
            type: GET_CUPID_MODELS_FAIL,
            payload: console.log("Error in actions ", e),
        });
    }
};
