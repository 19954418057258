import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

function Card1() {
    const history = useHistory();
    const { partnerSteps } = useSelector((state) => ({
        partnerSteps: state.analyticsReducer.partnerSteps,
    }));

    return (
        <div
            className={`card boxShadow ${
                partnerSteps?.has_catalog ? `card-info-complete` : `card-info`
            }`}
        >
            <div
                className={`IconBadge card-home  ${
                    partnerSteps?.has_catalog
                        ? `card-home-icon-complete`
                        : `purple`
                }`}
                style={{ padding: "6px" }}
            >
                <span
                    style={{
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "31px",
                        marginLeft: "20px",
                    }}
                >
                    2
                </span>
            </div>
            <div
                className="card-body card-body-home"
                style={{ minHeight: "135px" }}
            >
                <h4
                    className="card-title cardTitle"
                    style={{ maxHeight: "48px" }}
                >
                    Upload your Partner's Inventory
                </h4>
                <p className="card-paragraph">
                    Upload their inventory to the partners area.
                    <br />
                    {!partnerSteps?.has_catalog && (
                        <button
                            className="btn text-link-button"
                            onClick={() => {
                                window.open(
                                    "https://public-cupid.s3.us-east-2.amazonaws.com/samples/sample_partner_catalog.csv",
                                    "_blank"
                                );
                            }}
                        >
                            Get sample partner inventory here
                        </button>
                    )}
                </p>
            </div>
            <div className="card-footer card-info-footer">
                {partnerSteps?.has_catalog ? (
                    <button
                        type="button"
                        disabled={true}
                        className="button-step-complete"
                    >
                        <i className="fas fa-check mr-6"></i>
                        This step is complete!
                    </button>
                ) : (
                    <Link
                        className="btn waves-effect waves-light btn-rounded btn-outline-primary"
                        onClick={() => {
                            history.push("/partners-inventories");
                        }}
                    >
                        Upload partner inventory
                    </Link>
                )}
            </div>
        </div>
    );
}
export default Card1;
