import axios from "axios";
const url = process.env.REACT_APP_BASE_URL;

const PaymentService = {
    StripeTokenService: async (token) => {
        const result = await axios
            .post(url + "/stripe/token", token, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                return {
                    data: res.data,
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: error.response.data.message,
                    status: error.response.data.code,
                };
            });
        return result;
    },
};

export default PaymentService;
