import axios from "axios";
const url = process.env.REACT_APP_BASE_URL;

const BuildService = {
    getApiKeys: async () => {
        const result = await axios
            .get(url + "/companies/keys/production", {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                localStorage.setItem("apiKeys", res.data?.key);
                return {
                    data: res.data,
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: error.response.data.msg,
                    status: error.response.data.code,
                };
            });
        return result;
    },
    generateNewApiKeys: async (platform) => {
        const result = await axios
            .put(url + `/companies/newkey/${platform}`, null, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                localStorage.setItem("apiKeys", res.data?.key);
                return {
                    data: res.data,
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: error.response.data.message,
                    status: error.response.data.code,
                };
            });
        return result;
    },
};
export default BuildService;
