import React from "react";
import { Modal } from "react-bootstrap";
import {
    UPDATE_EXISTING_INVENTORY,
    ADD_TO_INVENTORY,
} from "../../../../utilis/const";

function StepBeta(props) {
    const { next, selectedOption, setSelectedOption, setName, handleClose } =
        props;
    return (
        <>
            <Modal.Body>
                <div className="col-md-12">
                    <fieldset
                        className="scheduler-border"
                        style={{ marginBottom: "0px" }}
                    >
                        <legend className="scheduler-border">
                            <div className="form-check">
                                <input
                                    className="form-radio-input"
                                    type="radio"
                                    id="UpdateExistingInventory"
                                    name="selectedOption"
                                    value={UPDATE_EXISTING_INVENTORY}
                                    checked={
                                        selectedOption ===
                                        UPDATE_EXISTING_INVENTORY
                                    }
                                    onChange={(e) => {
                                        setSelectedOption(
                                            e.currentTarget.value
                                        );
                                        setName("");
                                    }}
                                />
                                <label
                                    style={{ fontWeight: "400" }}
                                    for="UpdateExistingInventory"
                                >
                                    &nbsp;Update inventory
                                </label>
                            </div>
                        </legend>
                        <p style={{ margin: "0px" }}>
                            <span style={{ fontWeight: "800" }}>
                                Upload a new inventory to replace the existing
                                one.
                            </span>
                            <br />
                            The previous inventory will be deactivated and
                            replaced by the new one.
                        </p>
                    </fieldset>
                    <fieldset
                        className="scheduler-border"
                        style={{ marginBottom: "1rem" }}
                    >
                        <legend className="scheduler-border">
                            <div className="form-check">
                                <input
                                    className="form-radio-input"
                                    type="radio"
                                    id="addToInventory"
                                    name="selectedOption"
                                    value={ADD_TO_INVENTORY}
                                    checked={
                                        selectedOption === ADD_TO_INVENTORY
                                    }
                                    onChange={(e) => {
                                        setSelectedOption(
                                            e.currentTarget.value
                                        );
                                        setName("");
                                    }}
                                />
                                <label
                                    style={{ fontWeight: "400" }}
                                    for="addToInventory"
                                >
                                    &nbsp;Add to existing inventory
                                </label>
                            </div>
                        </legend>
                        <p style={{ margin: "0px" }}>
                            <span style={{ fontWeight: "800" }}>
                                Add properties to this existing inventory.
                            </span>
                            <br />
                            Note that any property who’s ID is already present
                            in the inventory will be replaced by the new one
                        </p>
                    </fieldset>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    className="btn btn-light"
                    onClick={handleClose}
                >
                    Close
                </button>
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={next}
                >
                    Next &nbsp;&nbsp;
                    <i className="fas fa-caret-right"></i>
                </button>
            </Modal.Footer>
        </>
    );
}

export default StepBeta;
