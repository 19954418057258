/* eslint-disable import/no-anonymous-default-export */
import {
    GET_COUNTRIES_LIST_SUCCESS,
    GET_COUNTRIES_LIST_LISTING,
    GET_COUNTRIES_LIST_FAIL,
} from "../types";

const initialState = {
    countries: [],
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_COUNTRIES_LIST_LISTING:
            return {
                ...state,
                loading: true,
            };
        case GET_COUNTRIES_LIST_SUCCESS:
            return {
                ...state,
                countries: action.payload,
                loading: false,
            };
        case GET_COUNTRIES_LIST_FAIL:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
}
