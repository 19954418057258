import React from "react";
import { Modal, Spinner } from "react-bootstrap";
import Select from "../../../../components/Select";
import { useDispatch, useSelector } from "react-redux";
import {
    ADD_TO_CATALOG,
    UPDATE_EXISTING_CATALOG,
} from "../../../../utilis/const";
import SyncLoader from "react-spinners/SyncLoader";
import { Link } from "react-router-dom";
import AddPartnerAttribute from "../../../../components/AddPartnerAttribute";
import {
    getPartnerAttributes,
    checkPartnerCatalog,
} from "../../../../redux/actions/PartnerActions";

const Step1 = (props) => {
    const {
        showAddNewPartner,
        partnerName,
        setPartnerName,
        setListAttributes,
        setShowAddNewPartner,
        selectedPartner,
        setSelectedPartner,
        selectedOption,
        setSelectedOption,
        showPartnerAttributes,
        setShowPartnerAttributes,
        handleClose,
        next,
        listAttributes,
        loadingPartnerCreation,
        partner,
    } = props;
    const dispatch = useDispatch();
    const { partnerList, partnerHasInventory, loadingPartnerHasCatalog } =
        useSelector((state) => ({
            partnerList: state.partnerReducer.partners ?? [],
            partnerHasInventory:
                partner ?? state.partnerReducer.partnerHasCatalog?.exist,
            loadingPartnerHasCatalog:
                state.partnerReducer.loadingPartnerHasCatalog,
        }));
    return (
        <>
            <Modal.Body>
                <div className="row row-eq-height">
                    {!partner && (
                        <>
                            {showAddNewPartner ? (
                                <div className="col-md-6">
                                    <fieldset
                                        className="scheduler-border"
                                        style={{
                                            height: "100%",
                                            marginBottom: "0px",
                                        }}
                                    >
                                        <legend className="scheduler-border">
                                            <h3 className="card-title">
                                                Add new partner
                                            </h3>
                                        </legend>

                                        <div className="form-group form-group-header">
                                            <label className="mr-sm-2">
                                                Add new partner
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Partner name"
                                                name="partner_name"
                                                value={partnerName ?? ""}
                                                onChange={(e) => {
                                                    setPartnerName(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                            }}
                                        >
                                            Already exists ? &nbsp;
                                            <Link
                                                onClick={() => {
                                                    setShowAddNewPartner(false);
                                                    setPartnerName("");
                                                    setListAttributes([]);
                                                }}
                                            >
                                                Select partner
                                            </Link>
                                        </div>
                                    </fieldset>
                                </div>
                            ) : (
                                <div className="col-md-6">
                                    <fieldset
                                        className="scheduler-border"
                                        style={{
                                            height: "100%",
                                            marginBottom: "0px",
                                        }}
                                    >
                                        <legend className="scheduler-border">
                                            <h3 className="card-title">
                                                Select existing partner
                                            </h3>
                                        </legend>

                                        <div className="form-group form-group-header">
                                            <label>Partner name </label>
                                            <Select
                                                options={partnerList?.map(
                                                    (e) => ({
                                                        ...e,
                                                        value: e?.id,
                                                        label: e?.name,
                                                    })
                                                )}
                                                name="selectedPartner"
                                                value={selectedPartner}
                                                onChange={(e) => {
                                                    setSelectedPartner(e);
                                                    setSelectedOption();
                                                    dispatch(
                                                        getPartnerAttributes(
                                                            e?.value
                                                        )
                                                    );
                                                    dispatch(
                                                        checkPartnerCatalog(
                                                            e?.value
                                                        )
                                                    );
                                                }}
                                                isClearable={true}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                            }}
                                        >
                                            Don't find it ?&nbsp;
                                            <Link
                                                onClick={() => {
                                                    setShowAddNewPartner(true);
                                                    setSelectedPartner(null);
                                                    setListAttributes([]);
                                                }}
                                            >
                                                Create new partner
                                            </Link>
                                        </div>
                                    </fieldset>
                                </div>
                            )}
                        </>
                    )}

                    {loadingPartnerHasCatalog ? (
                        <div className="col-md-6">
                            <div
                                style={{ marginLeft: "50%", marginTop: "20%" }}
                            >
                                <SyncLoader
                                    color="#5856d694"
                                    loading={true}
                                    size={10}
                                />
                            </div>
                        </div>
                    ) : partnerHasInventory ? (
                        <div className="col-md-6">
                            <fieldset
                                className="scheduler-border"
                                style={{ marginBottom: "1rem" }}
                            >
                                <legend className="scheduler-border">
                                    <div className="form-check">
                                        <input
                                            className="form-radio-input"
                                            type="radio"
                                            id="partnerRadioButton"
                                            name="selectedOption"
                                            value={ADD_TO_CATALOG}
                                            checked={
                                                selectedOption ===
                                                ADD_TO_CATALOG
                                            }
                                            onChange={(e) => {
                                                setSelectedOption(
                                                    e.currentTarget.value
                                                );
                                            }}
                                        />
                                        <label
                                            style={{ fontWeight: "400" }}
                                            for="partnerRadioButton"
                                        >
                                            &nbsp;Add to existing catalog
                                        </label>
                                    </div>
                                </legend>
                                <p style={{ margin: "0px" }}>
                                    <span style={{ fontWeight: "800" }}>
                                        Add properties to this partner's
                                        existing catalog.
                                    </span>
                                    <br />
                                    Note that any property who’s ID is already
                                    present in the catalog will be replaced by
                                    the new one
                                </p>
                            </fieldset>
                            <fieldset
                                className="scheduler-border"
                                style={{ marginBottom: "0px" }}
                            >
                                <legend className="scheduler-border">
                                    <div className="form-check">
                                        <input
                                            className="form-radio-input"
                                            type="radio"
                                            id="CreateNewPartnerRadioButton"
                                            name="selectedOption"
                                            value={UPDATE_EXISTING_CATALOG}
                                            checked={
                                                selectedOption ===
                                                UPDATE_EXISTING_CATALOG
                                            }
                                            onChange={(e) => {
                                                setSelectedOption(
                                                    e.currentTarget.value
                                                );
                                            }}
                                        />
                                        <label
                                            style={{ fontWeight: "400" }}
                                            for="CreateNewPartnerRadioButton"
                                        >
                                            &nbsp; Update catalog
                                        </label>
                                    </div>
                                </legend>
                                <p style={{ margin: "0px" }}>
                                    <span style={{ fontWeight: "800" }}>
                                        Upload a new catalog to replace the
                                        existing one.
                                    </span>
                                    <br />
                                    The previous catalog’s mapping will be
                                    deactivated and replaced by the new one.
                                </p>
                            </fieldset>
                        </div>
                    ) : (
                        <></>
                    )}
                    <div
                        className="col-lg-12"
                        style={{ textAlign: "center", marginTop: "1rem" }}
                    >
                        <Link
                            className="text-middle"
                            onClick={() =>
                                setShowPartnerAttributes(!showPartnerAttributes)
                            }
                        >
                            {showPartnerAttributes
                                ? "Hide  advanced Settings"
                                : "Show advanced Settings"}
                        </Link>
                    </div>
                    {showPartnerAttributes && (
                        <div className="col-lg-12">
                            <AddPartnerAttribute
                                next={next}
                                listAttributes={listAttributes}
                                setListAttributes={setListAttributes}
                                hideButton={true}
                            />
                        </div>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    className="btn btn-light"
                    onClick={handleClose}
                >
                    Close
                </button>
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={next}
                    disabled={
                        loadingPartnerCreation || loadingPartnerHasCatalog
                    }
                >
                    Next &nbsp;&nbsp;
                    <i className="fas fa-caret-right"></i>
                    {loadingPartnerCreation && (
                        <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    )}
                </button>
            </Modal.Footer>
        </>
    );
};

export default Step1;
