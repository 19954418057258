import {
    GET_DASHBOARD_DATA_FAIL,
    GET_DASHBOARD_DATA_SUCCESS,
    GET_DASHBOARD_DATA_LISTING,
    GET_USER_CONNECTED_FAIL,
    GET_USER_CONNECTED_SUCCESS,
    GET_USER_CONNECTED_LISTING,
} from "../types";
import axios from "axios";

const PATH_URL = process.env.REACT_APP_BASE_URL;

export const getDashboardData = () => async (dispatch) => {
    dispatch({
        type: GET_DASHBOARD_DATA_LISTING,
    });
    try {
        const res = await axios.get(PATH_URL + "/catalogs/stats", {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        dispatch({
            type: GET_DASHBOARD_DATA_SUCCESS,
            payload: res.data,
        });
    } catch (e) {
        dispatch({
            type: GET_DASHBOARD_DATA_FAIL,
            payload: console.log("Error in actions ", e),
        });
    }
};

export const getUserConnected = () => async (dispatch) => {
    dispatch({
        type: GET_USER_CONNECTED_LISTING,
    });
    try {
        const res = await axios.get(PATH_URL + "/companies/me", {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        localStorage.setItem(
            "userConnected",
            res.data.first_name + " " + res.data.last_name
        );
        localStorage.setItem("userId", res.data.id);
        localStorage.setItem("companyId", res.data.company.id);
        localStorage.setItem("companyName", res.data.company.brand_name);
        localStorage.setItem("firstName", res.data.first_name);
        localStorage.setItem("lastName", res.data.last_name);
        localStorage.setItem("role", res.data.role);
        dispatch({
            type: GET_USER_CONNECTED_SUCCESS,
            payload: res.data,
        });
    } catch (e) {
        dispatch({
            type: GET_USER_CONNECTED_FAIL,
            payload: console.log("Error in actions ", e),
        });
    }
};
