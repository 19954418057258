import React, { useState, useEffect } from "react";

import Card1 from "../../components/Home/card1";
import Card2 from "../../components/Home/card2";
import Card3 from "../../components/Home/card3";
import MappingNumber from "../../components/Analytics/MappingNumber";
import TimeSerieMappedRate from "../../components/Analytics/TimeSerieMappedRate";
import TimeSerieInventoryMappedRate from "../../components/Analytics/TimeSerieInventoryMappedRate";
import WelcomeDialog from "./WelcomeDialog";
import { Helmet } from "react-helmet";
import "react-datepicker/dist/react-datepicker.css";
import SupplierOutdated from "../../components/Analytics/SupplierOutadated";
import SupplierExclusiveContent from "../../components/Analytics/SupplierExclusiveContent";
import TopSupplier from "../../components/Analytics/TopSupplier";
import { getCompletedSteps } from "../../redux/actions/AnalyticsActions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CREATE_INVENTORY, CREATE_CATALOG } from "../../utilis/const";
import { hasPermission } from "../../utilis/functions";
import CatalogNoDataModal from "../../pages/Mapping/CatalogNoDataModal";
import { getPartnerList } from "../../redux/actions/PartnerActions";

//import SuccessRate from '../../components/Home/SuccessRate';

function HomeScreen() {
    const history = useHistory();
    const [showWelcomeMessage, setShowWelcomeMessage] = useState(false);
    const { partnerSteps, loadingPartnerSteps, loading } = useSelector(
        (state) => ({
            loadingPartnerSteps: state.analyticsReducer.loadingPartnerSteps,
            partnerSteps: state.analyticsReducer.partnerSteps,
            loading: state.partnerReducer.loading,
        })
    );

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCompletedSteps());
        dispatch(getPartnerList());
    }, []);

    useEffect(() => {
        let email = localStorage.getItem("email");
        if (
            localStorage.getItem(`welcome_${email}`) === true ||
            localStorage.getItem(`welcome_${email}`) == "true"
        ) {
            setShowWelcomeMessage(true);
            localStorage.setItem(`welcome_${email}`, false);
            window.analytics.track("Sign up", {
                userID: localStorage.getItem("userId"),
                companyID: localStorage.getItem("companyId"),
                companyName: localStorage.getItem("companyName"),
                firstName: localStorage.getItem("firstName"),
                lastName: localStorage.getItem("lastName"),
                email: email,
            });
        } else {
            setShowWelcomeMessage(false);
        }
    }, [localStorage.getItem("email")]);

    return (
        <>
            <Helmet>
                <title> Home</title>
            </Helmet>
            <div className="page-wrapper home " style={{ display: "block" }}>
                <div className="page-breadcrumb">
                    <div className="row">
                        <div className="col-7 align-self-center">
                            <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
                                Welcome to your dashboard
                            </h3>
                            <div className="d-flex align-items-center">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb m-0 p-0">
                                        <li className="breadcrumb-item">
                                            Dashboard
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                {loadingPartnerSteps || loading ? (
                    <div className="text-center">
                        <div className="lds-ripple">
                            <div className="lds-pos"></div>
                            <div className="lds-pos"></div>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-4">
                                    <Card1 />
                                </div>
                                <div className="col-lg-4">
                                    <Card2 />
                                </div>
                                <div className="col-lg-4">
                                    <Card3 />
                                </div>
                            </div>
                            {showWelcomeMessage ? (
                                <WelcomeDialog
                                    onClose={() => setShowWelcomeMessage(false)}
                                />
                            ) : partnerSteps?.has_inventory === false ? (
                                <CatalogNoDataModal
                                    title="Ready!"
                                    text2="Your dedicated workspace is almost ready to go !"
                                    text3="First, you’ll need to upload your hotel inventory."
                                    buttonText="Upload inventory"
                                    handleClick={async () => {
                                        history.push("/catalogs-manager");
                                    }}
                                    hideButton={
                                        hasPermission(CREATE_INVENTORY)
                                            ? false
                                            : true
                                    }
                                />
                            ) : partnerSteps?.has_catalog === false ? (
                                <CatalogNoDataModal
                                    title="Ready!"
                                    text="Your dedicated workspace is almost ready to go !"
                                    text2="Before we start mapping your content, you just need to add your partner’s inventory."
                                    buttonText=" Add new partner Inventory"
                                    handleClick={async () => {
                                        history.push("/partners-inventories");
                                    }}
                                    hideButton={
                                        hasPermission(CREATE_CATALOG)
                                            ? false
                                            : true
                                    }
                                />
                            ) : (
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <MappingNumber />
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <TimeSerieInventoryMappedRate />
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <TimeSerieMappedRate />
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <TopSupplier />
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <SupplierOutdated />
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <SupplierExclusiveContent />
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>
        </>
    );
}
export default HomeScreen;
