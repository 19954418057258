import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import CatalogService from "../../../../services/CatalogService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import {
    getCatalog,
    getCatalogs,
} from "../../../../redux/actions/CatalogActions";
import { useHistory } from "react-router-dom";
import Stepper from "react-stepper-horizontal";
import stringSimilarity from "string-similarity";
import StepBeta from "./Stepbeta";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import {
    ADD_TO_INVENTORY,
    UPDATE_EXISTING_INVENTORY,
} from "../../../../utilis/const";

function AddCatalogDialog(props) {
    const {
        open,
        handleClose,
        setProgressPourcentage,
        setFirstProccessing,
        activeCatalog,
    } = props;
    const [data, setData] = useState([]);
    const [hideBackButton, setHideBackButton] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedValues, setSelectedValues] = useState({});
    const [name, setName] = useState("");
    const [progress, setProgress] = useState();
    const history = useHistory();
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const [files, setFiles] = useState([]);
    const [selectedOption, setSelectedOption] = useState(
        UPDATE_EXISTING_INVENTORY
    );
    const [firstLine, setFirstLine] = useState([]);

    async function handleStartProcessing(e) {
        const values = Object.values(selectedValues)
            ?.filter((e) => e !== null)
            ?.map((e) => e.value);
        setLoading(true);
        if (new Set(values).size !== values.length) {
            setLoading(false);
            toast.warn("List contains duplicate values", {
                toastId: "list-contains-duplicate-values",
            });
        } else if (
            selectedValues.header_id?.value === undefined ||
            selectedValues.header_name?.value === undefined ||
            selectedValues.header_address?.value === undefined ||
            selectedValues.header_latitude?.value === undefined ||
            selectedValues.header_longitude?.value === undefined ||
            selectedValues.header_country_code?.value === undefined
        ) {
            setLoading(false);
            toast.error("Please complete all required fields", {
                toastId: "catalog-required-fields",
            });
        } else {
            setHideBackButton(true);
            const formData = new FormData();
            formData.append("file", files?.[0]);
            formData.append("name", name);
            formData.append("source", "csv");
            formData.append("header_id", selectedValues.header_id?.value);
            formData.append("header_name", selectedValues.header_name?.value);
            formData.append(
                "header_address",
                selectedValues.header_address?.value
            );
            // formData.append(
            //     "header_address_2",
            //     selectedValues.header_address_2?.value
            // );
            // formData.append(
            //     "header_address_3",
            //     selectedValues.header_address_3?.value
            // );
            formData.append("header_city", selectedValues.header_city?.value);
            formData.append(
                "header_country_code",
                selectedValues.header_country_code?.value
            );
            formData.append(
                "header_latitude",
                selectedValues.header_latitude?.value
            );
            formData.append(
                "header_longitude",
                selectedValues.header_longitude?.value
            );
            formData.append("header_chain", selectedValues.header_chain?.value);
            formData.append("header_type", selectedValues.header_type?.value);
            formData.append(
                "header_zip_code",
                selectedValues.header_zip_code?.value
            );
            formData.append(
                "header_city_id",
                selectedValues.header_city_id?.value
            );
            formData.append("header_stars", selectedValues.header_stars?.value);
            const option = {
                onUploadProgress: (ProgressEvent) => {
                    const { loaded, total } = ProgressEvent;
                    let percent = Math.floor((loaded * 100) / total);
                    setProgress(percent);
                },
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            formData.append(
                "action",
                selectedOption === ADD_TO_INVENTORY ? "add" : "update"
            );
            let res = await CatalogService.uploadCatalog(formData, option);
            if (res.status === 200) {
                setLoading(false);
                await dispatch(getCatalog(res?.data?.catalog_id));
                await dispatch(getCatalogs());
                toast.success("Created!");
                handleClose();
                setProgressPourcentage(0);
                setFirstProccessing(true);
                window.analytics.track("Upload inventory", {
                    totalHotels: localStorage.getItem("fileSize"),
                    userID: localStorage.getItem("userId"),
                    companyID: localStorage.getItem("companyId"),
                    companyName: localStorage.getItem("companyName"),
                    firstName: localStorage.getItem("firstName"),
                    lastName: localStorage.getItem("lastName"),
                    email: localStorage.getItem("email"),
                    inventoryName: name,
                });
            } else if (res.status === 401) {
                localStorage.clear();
                history.push("/sign-in");
            } else {
                setLoading(false);
                toast.error(res.message);
            }
        }
        setHideBackButton(false);
    }

    const sections = React.useMemo(() => {
        const required = [
            {
                title: "Upload inventory",
            },
            {
                title: "Field matching",
            },
        ];
        let extra = [];
        if (activeCatalog)
            extra.push({
                title: "Choose method",
            });
        if (selectedOption === UPDATE_EXISTING_INVENTORY) {
            extra.push({
                title: "Inventory name",
            });
        }
        return [...extra, ...required];
    }, [selectedOption]);

    const nextWithChooseOptionStep = () => {
        if (currentPage === 2 && name === "") {
            toast.error("Name is required to pass next step", {
                toastId: "catalog-csv-step1",
            });
        } else if (currentPage === 3 && files?.length <= 0) {
            toast.error("Upload your inventory file  to pass next step", {
                toastId: "catalog-csv-step2",
            });
        } else {
            setCurrentPage((prev) => prev + 1);
        }
    };
    const nextWithChooseOptionStepWithoutName = () => {
        if (currentPage === 2 && files?.length <= 0) {
            toast.error("Upload your inventory file  to pass next step", {
                toastId: "catalog-csv-step1",
            });
        } else {
            setCurrentPage((prev) => prev + 1);
        }
    };
    const nextWithoutChooseOptionStep = () => {
        if (currentPage === 1 && name === "") {
            toast.error("Name is required to pass next step", {
                toastId: "catalog-csv-step1",
            });
        } else if (currentPage === 2 && files?.length <= 0) {
            toast.error("Upload your inventory file  to pass next step", {
                toastId: "catalog-csv-step2",
            });
        } else {
            setCurrentPage((prev) => prev + 1);
        }
    };
    const prev = () => {
        setCurrentPage((prev) => prev - 1);
    };

    useEffect(() => {
        if (data?.length > 0) {
            let defaultHotelCode = stringSimilarity?.findBestMatch(
                "hotel id",
                data?.map((e) => e.name.toLowerCase())
            );
            let defaultHotelName = stringSimilarity?.findBestMatch(
                "name",
                data?.map((e) => e.name.toLowerCase())
            );
            let defaultAddress = stringSimilarity?.findBestMatch(
                "address",
                data?.map((e) => e.name.toLowerCase())
            );
            let defaultCity = stringSimilarity?.findBestMatch(
                "city",
                data?.map((e) => e.name.toLowerCase())
            );
            let defaultCountry = stringSimilarity?.findBestMatch(
                "country",
                data?.map((e) => e.name.toLowerCase())
            );
            let defaultLatitude = stringSimilarity?.findBestMatch(
                "latitude",
                data?.map((e) => e.name.toLowerCase())
            );
            let defaultLongitude = stringSimilarity?.findBestMatch(
                "longitude",
                data?.map((e) => e.name.toLowerCase())
            );
            let defaultChain = stringSimilarity?.findBestMatch(
                "chain",
                data?.map((e) => e.name.toLowerCase())
            );
            let defaultType = stringSimilarity?.findBestMatch(
                "type",
                data?.map((e) => e.name.toLowerCase())
            );
            let defaultRating = stringSimilarity?.findBestMatch(
                "rating",
                data?.map((e) => e.name.toLowerCase())
            );
            let defaultzipCode = stringSimilarity?.findBestMatch(
                "zipCode",
                data?.map((e) => e.name.toLowerCase())
            );
            let defaultCityId = stringSimilarity.findBestMatch(
                "cityId",
                data?.map((e) => e.name.toLowerCase())
            );
            let selectedValuesArray = {};
            if (defaultHotelCode?.bestMatch?.rating >= 0.8) {
                selectedValuesArray["header_id"] = {
                    value: defaultHotelCode?.bestMatchIndex,
                    label: defaultHotelCode?.bestMatch?.target,
                };
            }
            if (defaultHotelName?.bestMatch?.rating >= 0.8) {
                selectedValuesArray["header_name"] = {
                    value: defaultHotelName?.bestMatchIndex,
                    label: defaultHotelName?.bestMatch?.target,
                };
            }
            if (defaultAddress?.bestMatch?.rating >= 0.8) {
                selectedValuesArray["header_address"] = {
                    value: defaultAddress?.bestMatchIndex,
                    label: defaultAddress?.bestMatch?.target,
                };
            }
            if (defaultCity?.bestMatch?.rating >= 0.8) {
                selectedValuesArray["header_city"] = {
                    value: defaultCity?.bestMatchIndex,
                    label: defaultCity?.bestMatch?.target,
                };
            }
            if (defaultCountry?.bestMatch?.rating >= 0.8) {
                selectedValuesArray["header_country_code"] = {
                    value: defaultCountry?.bestMatchIndex,
                    label: defaultCountry?.bestMatch?.target,
                };
            }
            if (defaultLatitude?.bestMatch?.rating >= 0.8) {
                selectedValuesArray["header_latitude"] = {
                    value: defaultLatitude?.bestMatchIndex,
                    label: defaultLatitude?.bestMatch?.target,
                };
            }
            if (defaultLongitude?.bestMatch?.rating >= 0.8) {
                selectedValuesArray["header_longitude"] = {
                    value: defaultLongitude?.bestMatchIndex,
                    label: defaultLongitude?.bestMatch?.target,
                };
            }
            if (defaultChain?.bestMatch?.rating >= 0.8) {
                selectedValuesArray["header_chain"] = {
                    value: defaultChain?.bestMatchIndex,
                    label: defaultChain?.bestMatch?.target,
                };
            }
            if (defaultType?.bestMatch?.rating >= 0.8) {
                selectedValuesArray["header_type"] = {
                    value: defaultType?.bestMatchIndex,
                    label: defaultType?.bestMatch?.target,
                };
            }
            if (defaultRating?.bestMatch?.rating >= 0.8) {
                selectedValuesArray["header_stars"] = {
                    value: defaultRating?.bestMatchIndex,
                    label: defaultRating?.bestMatch?.target,
                };
            }
            if (defaultzipCode?.bestMatch?.rating >= 0.8) {
                selectedValuesArray["header_zip_code"] = {
                    value: defaultzipCode?.bestMatchIndex,
                    label: defaultzipCode?.bestMatch?.target,
                };
            }

            if (defaultCityId?.bestMatch?.rating >= 0.8) {
                selectedValuesArray["header_city_id"] = {
                    value: defaultCityId?.bestMatchIndex,
                    label: defaultCityId?.bestMatch?.target,
                };
            }
            setSelectedValues(selectedValuesArray);
        }
    }, [data]);

    const renderElements = () => {
        switch (currentPage) {
            case 1:
                return (
                    <StepBeta
                        selectedOption={selectedOption}
                        setSelectedOption={setSelectedOption}
                        next={nextWithChooseOptionStep}
                        handleClose={handleClose}
                        setName={setName}
                    />
                );
            case 2:
                return (
                    <Step1
                        setName={setName}
                        name={name}
                        next={nextWithChooseOptionStep}
                        activeCatalog={activeCatalog}
                        prev={prev}
                        handleClose={handleClose}
                    />
                );
            case 3:
                return (
                    <Step2
                        files={files}
                        setFiles={setFiles}
                        setData={setData}
                        prev={prev}
                        next={nextWithChooseOptionStep}
                        setFirstLine={setFirstLine}
                        handleClose={handleClose}
                    />
                );
            case 4:
                return (
                    <Step3
                        loading={loading}
                        selectedValues={selectedValues}
                        setSelectedValues={setSelectedValues}
                        data={data}
                        progress={progress}
                        prev={prev}
                        handleClose={handleClose}
                        handleStartProcessing={handleStartProcessing}
                        hideBackButton={hideBackButton}
                        firstLine={firstLine}
                    />
                );

            default:
                return <></>;
        }
    };
    const renderElementsWithoutChooseStep = () => {
        switch (currentPage) {
            case 1:
                return (
                    <Step1
                        setName={setName}
                        name={name}
                        next={nextWithoutChooseOptionStep}
                        activeCatalog={activeCatalog}
                        prev={prev}
                        handleClose={handleClose}
                    />
                );
            case 2:
                return (
                    <Step2
                        files={files}
                        setFiles={setFiles}
                        setData={setData}
                        prev={prev}
                        next={nextWithoutChooseOptionStep}
                        setFirstLine={setFirstLine}
                        handleClose={handleClose}
                    />
                );
            case 3:
                return (
                    <Step3
                        loading={loading}
                        selectedValues={selectedValues}
                        setSelectedValues={setSelectedValues}
                        data={data}
                        progress={progress}
                        prev={prev}
                        handleClose={handleClose}
                        handleStartProcessing={handleStartProcessing}
                        hideBackButton={hideBackButton}
                        firstLine={firstLine}
                    />
                );

            default:
                return <></>;
        }
    };
    const renderElementsWithChooseStepAndCatalogName = () => {
        switch (currentPage) {
            case 1:
                return (
                    <StepBeta
                        selectedOption={selectedOption}
                        setSelectedOption={setSelectedOption}
                        next={nextWithChooseOptionStepWithoutName}
                        setName={setName}
                        handleClose={handleClose}
                    />
                );
            case 2:
                return (
                    <Step2
                        files={files}
                        setFiles={setFiles}
                        setData={setData}
                        prev={prev}
                        next={nextWithChooseOptionStepWithoutName}
                        setFirstLine={setFirstLine}
                        handleClose={handleClose}
                    />
                );
            case 3:
                return (
                    <Step3
                        loading={loading}
                        selectedValues={selectedValues}
                        setSelectedValues={setSelectedValues}
                        data={data}
                        progress={progress}
                        prev={prev}
                        handleClose={handleClose}
                        handleStartProcessing={handleStartProcessing}
                        hideBackButton={hideBackButton}
                        firstLine={firstLine}
                    />
                );

            default:
                return <></>;
        }
    };
    return (
        <>
            <Modal.Body style={{ paddingBottom: "0px" }}>
                <div className="card-header p-0 pb-2">
                    <Stepper
                        steps={sections}
                        activeStep={currentPage}
                        activeColor="#D3D7DB"
                        defaultBarColor="#D3D7DB"
                        completeColor="#6076E8"
                        completeBarColor="#6076E8"
                    />
                </div>
                <div className="card-body">
                    {activeCatalog
                        ? selectedOption === ADD_TO_INVENTORY
                            ? renderElementsWithChooseStepAndCatalogName()
                            : renderElements()
                        : renderElementsWithoutChooseStep()}
                </div>
            </Modal.Body>
        </>
    );
}

export default AddCatalogDialog;
