import {
    GET_PARTNERS_LIST_SUCCESS,
    GET_PARTNERS_LIST_FAIL,
    GET_PARTNERS_LIST_LISTING,
    GET_PARTNER_ATTRIBUTES_LISTING,
    GET_PARTNER_ATTRIBUTES_FAIL,
    GET_PARTNER_ATTRIBUTES_SUCCESS,
    GET_PARTNER_CATALOGS_LAST_UPDATE_FAIL,
    GET_PARTNER_CATALOGS_LAST_UPDATE_LISTING,
    GET_PARTNER_CATALOGS_LAST_UPDATE_SUCCESS,
    CHECK_PARTNER_CATALOGS_LISTING,
    CHECK_PARTNER_CATALOGS_SUCCESS,
    CHECK_PARTNER_CATALOGS_FAIL,
    GET_PARTNER_GROUPED_LIST_FAILD,
    GET_PARTNER_GROUPED_LIST_SUCCESS,
    GET_PARTNER_GROUPED_LIST_LISTING,
    GET_PARTNER_BY_ID_LISTING,
    GET_PARTNER_BY_ID_SUCCESS,
    GET_PARTNER_BY_ID_FAIL,
    GET_AFFILIATE_HEADER_DETAILS_FAIL,
    GET_AFFILIATE_HEADER_DETAILS_SUCCESS,
    GET_AFFILIATE_HEADER_DETAILS_LISTING,
    GET_PARTNERS_INVENTORIES_LIST_LISTING,
    GET_PARTNERS_INVENTORIES_LIST_SUCCESS,
    GET_PARTNERS_INVENTORIES_LIST_FAIL,
    GET_PARTNER_INVENTORIES_LIST_LISTING,
    GET_PARTNER_INVENTORIES_LIST_SUCCESS,
    GET_PARTNER_INVENTORIES_LIST_FAIL,
    GET_PARTNER_CATALOGS_LAST_UPDATE_LISTING_PERIODIQUE,
    GET_PARTNERS_INVENTORIES_LIST_LISTING_PERIODIQUE,
} from "../types";
import axios from "axios";

const PATH_URL = process.env.REACT_APP_BASE_URL;

export const getPartnerList = () => async (dispatch) => {
    dispatch({
        type: GET_PARTNERS_LIST_LISTING,
    });
    try {
        const res = await axios.get(PATH_URL + "/partner/list", {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        dispatch({
            type: GET_PARTNERS_LIST_SUCCESS,
            payload: res.data.data,
        });
    } catch (e) {
        dispatch({
            type: GET_PARTNERS_LIST_FAIL,
        });
    }
};

export const getPartnersInventoriesList = () => async (dispatch) => {
    dispatch({
        type: GET_PARTNERS_INVENTORIES_LIST_LISTING,
    });
    try {
        const res = await axios.get(
            PATH_URL + "/partner/list-partners-inventories",
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }
        );
        dispatch({
            type: GET_PARTNERS_INVENTORIES_LIST_SUCCESS,
            payload: res.data.data,
        });
    } catch (e) {
        dispatch({
            type: GET_PARTNERS_INVENTORIES_LIST_FAIL,
        });
    }
};
export const getPartnersInventoriesListPeriodique = () => async (dispatch) => {
    dispatch({
        type: GET_PARTNERS_INVENTORIES_LIST_LISTING_PERIODIQUE,
    });
    try {
        const res = await axios.get(
            PATH_URL + "/partner/list-partners-inventories",
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }
        );
        dispatch({
            type: GET_PARTNERS_INVENTORIES_LIST_SUCCESS,
            payload: res.data.data,
        });
    } catch (e) {
        dispatch({
            type: GET_PARTNERS_INVENTORIES_LIST_FAIL,
        });
    }
};
export const getPartnerInventoriesByIdList =
    (partner_id) => async (dispatch) => {
        dispatch({
            type: GET_PARTNER_INVENTORIES_LIST_LISTING,
        });
        try {
            const res = await axios.get(
                PATH_URL + `/partner/list-partner-inventories/${partner_id}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem(
                            "token"
                        )}`,
                    },
                }
            );
            console.log("res", res);
            dispatch({
                type: GET_PARTNER_INVENTORIES_LIST_SUCCESS,
                payload: res.data.data,
                header: res.data.header,
            });
        } catch (e) {
            dispatch({
                type: GET_PARTNER_INVENTORIES_LIST_FAIL,
            });
        }
    };

export const getPartnerById = (id) => async (dispatch) => {
    console.log("id", id);
    dispatch({
        type: GET_PARTNER_BY_ID_LISTING,
    });
    try {
        const res = await axios.get(PATH_URL + `/partner/${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        dispatch({
            type: GET_PARTNER_BY_ID_SUCCESS,
            payload: res.data.data,
        });
    } catch (e) {
        dispatch({
            type: GET_PARTNER_BY_ID_FAIL,
        });
    }
};
export const getPartnerAttributes = (partner_id) => async (dispatch) => {
    dispatch({
        type: GET_PARTNER_ATTRIBUTES_LISTING,
    });
    try {
        const res = await axios.get(
            PATH_URL + `/partners/attributes/${partner_id}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }
        );
        dispatch({
            type: GET_PARTNER_ATTRIBUTES_SUCCESS,
            payload: res.data.data,
        });
    } catch (e) {
        dispatch({
            type: GET_PARTNER_ATTRIBUTES_FAIL,
        });
    }
};

export const getPartnerCatalogsLastUpdate = () => async (dispatch) => {
    dispatch({
        type: GET_PARTNER_CATALOGS_LAST_UPDATE_LISTING,
    });
    try {
        const res = await axios.get(
            PATH_URL + `/partner-catalogs-last-update`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }
        );
        dispatch({
            type: GET_PARTNER_CATALOGS_LAST_UPDATE_SUCCESS,
            payload: res.data.data,
        });
    } catch (e) {
        dispatch({
            type: GET_PARTNER_CATALOGS_LAST_UPDATE_FAIL,
        });
    }
};
export const getPartnerCatalogsLastUpdatePeriodique =
    () => async (dispatch) => {
        dispatch({
            type: GET_PARTNER_CATALOGS_LAST_UPDATE_LISTING_PERIODIQUE,
        });
        try {
            const res = await axios.get(
                PATH_URL + `/partner-catalogs-last-update`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem(
                            "token"
                        )}`,
                    },
                }
            );
            dispatch({
                type: GET_PARTNER_CATALOGS_LAST_UPDATE_SUCCESS,
                payload: res.data.data,
            });
        } catch (e) {
            dispatch({
                type: GET_PARTNER_CATALOGS_LAST_UPDATE_FAIL,
            });
        }
    };

export const checkPartnerCatalog = (partner_id) => async (dispatch) => {
    dispatch({
        type: CHECK_PARTNER_CATALOGS_LISTING,
    });
    try {
        const res = await axios.get(
            PATH_URL + `/partner-catalogs/exist/${partner_id}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }
        );
        dispatch({
            type: CHECK_PARTNER_CATALOGS_SUCCESS,
            payload: res.data,
        });
    } catch (e) {
        dispatch({
            type: CHECK_PARTNER_CATALOGS_FAIL,
        });
    }
};

export const partnerGrouppdListByAction =
    (partner_id, affiliate_id) => async (dispatch) => {
        try {
            const res = await axios.get(
                PATH_URL + `/partner-catalogs/parent-catalog/${partner_id}`,
                {
                    params: { affiliate_id: affiliate_id },
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem(
                            "token"
                        )}`,
                    },
                }
            );
            dispatch({
                type: GET_PARTNER_GROUPED_LIST_SUCCESS,
                payload: res.data,
            });
        } catch (e) {
            dispatch({
                type: GET_PARTNER_GROUPED_LIST_FAILD,
            });
        }
    };
export const getAffiliateHeaderDetailsPeriodique =
    (partner_id, affiliate_id) => async (dispatch) => {
        try {
            const res = await axios.get(
                PATH_URL +
                    `/partner-catalogs/affiliate-header-details/${partner_id}`,
                {
                    params: { affiliate_id: affiliate_id },
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem(
                            "token"
                        )}`,
                    },
                }
            );
            dispatch({
                type: GET_AFFILIATE_HEADER_DETAILS_SUCCESS,
                payload: res.data,
            });
        } catch (e) {
            dispatch({
                type: GET_AFFILIATE_HEADER_DETAILS_FAIL,
            });
        }
    };
export const getAffiliateHeaderDetails =
    (partner_id, affiliate_id) => async (dispatch) => {
        try {
            dispatch({
                type: GET_AFFILIATE_HEADER_DETAILS_LISTING,
            });
            const res = await axios.get(
                PATH_URL +
                    `/partner-catalogs/affiliate-header-details/${partner_id}`,
                {
                    params: { affiliate_id: affiliate_id },
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem(
                            "token"
                        )}`,
                    },
                }
            );
            dispatch({
                type: GET_AFFILIATE_HEADER_DETAILS_SUCCESS,
                payload: res.data,
            });
        } catch (e) {
            dispatch({
                type: GET_AFFILIATE_HEADER_DETAILS_FAIL,
            });
        }
    };
export const partnerGrouppdListByActionFirst =
    (partner_id, affiliate_id) => async (dispatch) => {
        dispatch({
            type: GET_PARTNER_GROUPED_LIST_LISTING,
        });
        try {
            const res = await axios.get(
                PATH_URL + `/partner-catalogs/parent-catalog/${partner_id}`,
                {
                    params: { affiliate_id: affiliate_id },
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem(
                            "token"
                        )}`,
                    },
                }
            );
            dispatch({
                type: GET_PARTNER_GROUPED_LIST_SUCCESS,
                payload: res.data,
            });
        } catch (e) {
            dispatch({
                type: GET_PARTNER_GROUPED_LIST_FAILD,
            });
        }
    };
