import React, { useState } from "react";
import CatalogService from "../../services/CatalogService";
import fileDownload from "js-file-download";
import { toast } from "react-toastify";
import { Spinner, OverlayTrigger, Tooltip } from "react-bootstrap";

function DownloadCatalog(props) {
    const [pending, setPending] = useState(false);
    const { dataCell,styleInventory } = props;
    const renderTooltip = (props = (text) => {
        return (
            <Tooltip id={`button-tooltip ${text}`} {...props}>
                {text}
            </Tooltip>
        );
    });
    const handleDowload = (id) => async () => {
        setPending(true);
        let res = await CatalogService.dowloadCatalog(id);
        try {
            if (res.status === 200) {
                fileDownload(res.data, res?.fileName);
                setPending(false);
                window.analytics.track("Download inventory", {
                    userID: localStorage.getItem("userId"),
                    companyID: localStorage.getItem("companyId"),
                    companyName: localStorage.getItem("companyName"),
                    firstName: localStorage.getItem("firstName"),
                    lastName: localStorage.getItem("lastName"),
                    email: localStorage.getItem("email"),
                    catalogID: dataCell?.id,
                    invalidHotels: dataCell?.invalid_hotels,
                    validHotels: dataCell?.valid_hotels,
                });
            } else {
                setPending(false);
                toast.error(res.message);
            }
        } catch (err) {
            setPending(false);
            toast.error("Something went wrong while downloading this file");
        }
    };

    return (
        <OverlayTrigger
            placement="top"
            delay={{
                show: 250,
                hide: 400,
            }}
            overlay={renderTooltip("Download inventory")}
        >
            <button
                type="button"
                className={`btn btn-sm waves-effect waves-light btn-rounded btn-outline-secondary ${styleInventory ? 'downloadInventory' : ''}`}
                onClick={handleDowload(dataCell.id)}
                disabled={pending}
            >
                {pending ? (
                    <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                ) : (
                    <i className="fas fa-download"></i>
                )}
            </button>
        </OverlayTrigger>
    );
}
export default DownloadCatalog;
