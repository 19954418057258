import React, { useEffect, useState } from "react";
import Table from "../../components/Table/Table";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Spinner, Tooltip, OverlayTrigger } from "react-bootstrap";
import { getCountries } from "../../redux/actions/CountryActions";
import Select from "../../components/Select";
import SegmentService from "../../services/SegmentService";
import { toast } from "react-toastify";
import {
    getChainsList,
    getSegmentsList,
    getStarsList,
    getSegmentTypeList,
} from "../../redux/actions/SegmentActions";
import moment from "moment";
import fileDownload from "js-file-download";
import { MANAGE_INVENTORY_SEGMENTS } from "../../utilis/const";
import { hasPermission } from "../../utilis/functions";
import CitiesAutoComplete from "../../components/AutoComplete/Segment/CitiesAutoComplete";

const HOTEL_IDS = "hotel ids";
const FILTER = "filter";

const renderTooltip = (name) => (props) =>
    (
        <Tooltip id="button-tooltip" {...props}>
            {name}
        </Tooltip>
    );

const SegmentList = (props) => {
    const { activeCatalog } = props;
    const [showCreateModal, setShowCreateModal] = useState(false);
    const dispatch = useDispatch();
    const { segments, loadingSegments } = useSelector((state) => ({
        segments: state.segmentReducer?.segments?.data,
        loadingSegments: state.segmentReducer?.loadingSegments,
    }));

    useEffect(() => {
        dispatch(getCountries());
        dispatch(getSegmentsList());
        dispatch(getChainsList());
        dispatch(getStarsList());
        dispatch(getSegmentTypeList());
    }, []);

    const columns = [
        {
            name: <div className="table-title">Name</div>,
            selector: (e) => e?.name,
            cell: (e) => {
                return <>{e?.name}</>;
            },
            sortable: true,
        },
        {
            name: <div className="table-title">Total hotels</div>,
            selector: (e) => e?.total_hotels,
            cell: (e) => {
                return <>{e?.total_hotels}</>;
            },
            sortable: true,
        },
        {
            name: <div className="table-title">Creation date</div>,
            selector: (e) => e?.created_at,
            cell: (e) => {
                return (
                    <>
                        {moment(new Date(e?.created_at)).format(
                            "DD/MM/YYYY HH:mm"
                        )}
                    </>
                );
            },
            sortable: true,
        },
        {
            name: <div className="table-title">Download</div>,
            cell: (e) => {
                return <DownloadSegment dataCell={e} />;
            },
            sortable: false,
        },
    ];

    return (
        <div className="card">
            <div className="card-body">
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "16px",
                    }}
                >
                    <h4 className="card-title">
                        Manage segments
                        <OverlayTrigger
                            placement="top"
                            delay={{
                                show: 250,
                                hide: 400,
                            }}
                            overlay={renderTooltip(
                                "Create subsets from your inventory based on a set of conditions or by using a list of your property IDs."
                            )}
                        >
                            <i
                                className="fas fa-info-circle"
                                style={{ marginLeft: "4px" }}
                            ></i>
                        </OverlayTrigger>
                    </h4>
                    {hasPermission(MANAGE_INVENTORY_SEGMENTS) &&
                        segments?.length > 0 &&
                        activeCatalog && (
                            <button
                                className="btn btn-info"
                                style={{ marginRight: "12px" }}
                                onClick={async () => {
                                    setShowCreateModal(true);
                                }}
                            >
                                Create new segment
                            </button>
                        )}
                </div>

                {segments?.length > 0 ? (
                    <Table
                        data={segments}
                        rowsPerPage={5}
                        columns={columns}
                        progressPending={loadingSegments}
                        
                    />
                ) : (
                    <div style={{ textAlign: "center", fontWeight: "500" }}>
                        <p>
                            Create subsets from your inventory based on a set of
                            conditions 
                            or by using a list of your property IDs.
                        </p>
                        {activeCatalog && (
                            <button
                                className="btn btn-info craeteSegmentBtn"
                                onClick={async () => {
                                    setShowCreateModal(true);
                                }}
                                style={{ marginTop: "15px" }}
                            >
                                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.5 5V19M5.5 12H19.5" stroke="#6275E7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                Create  segment
                            </button>
                        )}
                    </div>
                )}
            </div>
            {showCreateModal && (
                <CreateNewSegment
                    show={showCreateModal}
                    handleClose={() => setShowCreateModal(false)}
                />
            )}
        </div>
    );
};

export default SegmentList;

const CreateNewSegment = (props) => {
    const { show, handleClose } = props;
    const dispatch = useDispatch();
    const [selectedOption, setSelectedOption] = useState(FILTER);
    const [data, setData] = useState({});
    const [pending, setPending] = useState(false);

    const {
        countries,
        chains,
        stars,
        segmentTypes,
        cities,
        loadingSegments,
        loadingChains,
        loadingStars,
        loadingSegmentTypes,
        loadingCities,
        loadingCountries,
    } = useSelector((state) => ({
        countries: state?.countriesReducer?.countries ?? [],
        chains: state?.segmentReducer?.chains?.data ?? [],
        stars: state?.segmentReducer?.stars?.data ?? [],
        segmentTypes: state?.segmentReducer?.segmentTypes?.data ?? [],
        cities: state?.segmentReducer?.cities?.data ?? [],
        loadingSegments: state?.segmentReducer?.loadingSegments,
        loadingChains: state?.segmentReducer?.loadingChains,
        loadingStars: state?.segmentReducer?.loadingStars,
        loadingSegmentTypes: state?.segmentReducer?.loadingSegmentTypes,
        loadingCities: state?.segmentReducer?.loadingCities,
        loadingCountries: state?.countriesReducer?.loading,
    }));
    const inputValue = (name) => (e) => {
        if (e.target.value !== "") {
            data[name] = e.target.value;
        } else {
            data[name] = "";
        }
        setData({
            name: data.name,
            content: {
                chains: data?.content?.chains ?? [],
                hotel_ids: data?.hotel_ids ?? "",
                countries: data?.content?.countries ?? [],
                stars: data?.content?.stars ?? [],
                types: data?.content?.types ?? [],
                cities: data?.content?.cities ?? [],
            },
        });
    };

    useEffect(() => {
        dispatch(getCountries());
        dispatch(getSegmentsList());
        dispatch(getChainsList());
        dispatch(getStarsList());
        dispatch(getSegmentTypeList());
    }, []);

    return (
        <Modal
            show={show}
            onHide={handleClose}
            dialogClassName={"modal-40w modal-dialog modal-dialog-centered"}
        >
            <Modal.Header
                style={{
                    color: "#212529",
                    fontSize: "18px",
                    fontWeight: "700",
                    left: "15px",
                    top: "15px",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                Create new segment
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-12">
                        <h4 className="card-title">Type your segment name :</h4>
                    </div>
                    <div className="col-6" style={{ marginBottom: "30px" }}>
                        <input
                            value={data?.name}
                            type="text"
                            className="form-control"
                            name="name"
                            placeholder="Segment name"
                            autoComplete="off"
                            onChange={inputValue("name")}
                        />
                    </div>
                    <div className="col-12">
                        <h4 className="card-title">
                            Choose your segmentation method:
                        </h4>
                    </div>

                    <div className="col-6" style={{ marginBottom: "10px" }}>
                        <div className="form-check">
                            <input
                                className="form-radio-input"
                                type="radio"
                                id="filterOption"
                                name="selectedOption"
                                value={FILTER}
                                checked={selectedOption === FILTER}
                                onChange={(e) => {
                                    setSelectedOption(e.currentTarget.value);
                                    setData({
                                        name: data?.name,
                                        content: {
                                            hotel_ids: [],
                                            chains: [],
                                            stars: [],
                                            countries: [],
                                            types: [],
                                            cities: [],
                                        },
                                    });
                                }}
                            />
                            <label
                                style={{ fontWeight: "400" }}
                                for="filterOption"
                            >
                                &nbsp;By Filter
                            </label>
                        </div>
                    </div>
                    <div className="col-6" style={{ marginBottom: "10px" }}>
                        <div className="form-check">
                            <input
                                className="form-radio-input"
                                type="radio"
                                id="hotelIdsOption"
                                name="selectedOption"
                                value={HOTEL_IDS}
                                checked={selectedOption === HOTEL_IDS}
                                onChange={(e) => {
                                    setSelectedOption(e.currentTarget.value);
                                    setData({
                                        name: data?.name,
                                        content: {
                                            hotel_ids: [],
                                            chains: [],
                                            stars: [],
                                            countries: [],
                                            types: [],
                                            cities: [],
                                        },
                                    });
                                }}
                            />
                            <label
                                style={{ fontWeight: "400" }}
                                for="hotelIdsOption"
                            >
                                &nbsp;By Hotel Ids
                            </label>
                        </div>
                    </div>
                    {selectedOption === FILTER ? (
                        <>
                            <div
                                className="col-12"
                                style={{ marginBottom: "10px" }}
                            >
                                <label for="countries">Countries </label>
                                <Select
                                    options={countries?.map((e) => ({
                                        iso2: e?.iso2,
                                        value: e?.id,
                                        label: e?.name,
                                    }))}
                                    name="countries"
                                    value={data?.countries}
                                    placeholder="Countries"
                                    isClearable={true}
                                    isMulti={true}
                                    onChange={(e) => {
                                        setData({
                                            ...data,
                                            content: {
                                                ...data?.content,
                                                countries: e,
                                            },
                                        });
                                    }}
                                />
                            </div>
                            <div
                                className="col-12"
                                style={{ marginBottom: "10px" }}
                            >
                                <label for="cities">Cities </label>

                                <CitiesAutoComplete
                                    isClearable={true}
                                    name="cities"
                                    value={data?.cities}
                                    placeholder="Start Typing"
                                    onChange={(value) => {
                                        setData({
                                            ...data,
                                            content: {
                                                ...data?.content,
                                                cities: value,
                                            },
                                        });
                                    }}
                                    styles={{
                                        control: (base) => ({
                                            ...base,
                                            height: 49,
                                            minHeight: 49,
                                        }),
                                    }}
                                />
                            </div>
                            <div
                                className="col-12"
                                style={{ marginBottom: "10px" }}
                            >
                                <label for="stars">Star rating </label>
                                <Select
                                    options={stars?.map((e, index) => ({
                                        value: index,
                                        label: e,
                                    }))}
                                    name="stars"
                                    value={data?.stars}
                                    placeholder="Stars"
                                    isClearable={true}
                                    isMulti={true}
                                    onChange={(e) => {
                                        setData({
                                            ...data,
                                            content: {
                                                ...data?.content,
                                                stars: e,
                                            },
                                        });
                                    }}
                                    isLoading={loadingStars}
                                />
                            </div>
                            <div
                                className="col-12"
                                style={{ marginBottom: "10px" }}
                            >
                                <label for="chains">Chains </label>
                                <Select
                                    id="chains"
                                    options={chains?.map((e, index) => ({
                                        value: index,
                                        label: e,
                                    }))}
                                    name="chains"
                                    value={data?.chains}
                                    placeholder="Chains"
                                    isClearable={true}
                                    isMulti={true}
                                    onChange={(e) => {
                                        setData({
                                            ...data,
                                            content: {
                                                ...data?.content,
                                                chains: e,
                                            },
                                        });
                                    }}
                                    isLoading={loadingChains}
                                />
                            </div>
                            <div
                                className="col-12"
                                style={{ marginBottom: "10px" }}
                            >
                                <label for="types">Types </label>
                                <Select
                                    id="types"
                                    options={segmentTypes?.map((e, index) => ({
                                        value: index,
                                        label: e,
                                    }))}
                                    name="types"
                                    value={data?.types}
                                    placeholder="Types"
                                    isClearable={true}
                                    isMulti={true}
                                    onChange={(e) => {
                                        setData({
                                            ...data,
                                            content: {
                                                ...data?.content,
                                                types: e,
                                            },
                                        });
                                    }}
                                    isLoading={loadingSegmentTypes}
                                />
                            </div>
                        </>
                    ) : (
                        <div
                            className="col-12"
                            style={{ marginBottom: "10px" }}
                        >
                            <textarea
                                className="form-control"
                                rows="2"
                                placeholder="Hotel ID, Hotel ID2, Hotel ID3, Hotel ID4..."
                                name="hotel_ids"
                                onChange={inputValue("hotel_ids")}
                            />
                        </div>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    className="btn btn-primary"
                    disabled={pending}
                    onClick={async () => {
                        if (!data?.name || data?.name === "") {
                            toast.error("Segment name is required");
                        } else {
                            setPending(true);
                            let res = await SegmentService.createSegment({
                                ...data,
                                content: {
                                    hotel_ids:
                                        data?.content?.hotel_ids?.length > 0
                                            ? data?.content?.hotel_ids?.split(
                                                  ","
                                              )
                                            : [],
                                    chains: data?.content?.chains?.map(
                                        (e) => e.label
                                    ),
                                    countries: data?.content?.countries?.map(
                                        (e) => e?.iso2
                                    ),
                                    stars: data?.content?.stars?.map(
                                        (e) => e?.label
                                    ),
                                    types: data?.content?.types?.map(
                                        (e) => e?.label
                                    ),
                                    cities: data?.content?.cities?.map(
                                        (e) => e?.label
                                    ),
                                },
                            });
                            if (res.status === 200) {
                                setPending(false);
                                toast.success("Segment saved successfully");
                                dispatch(getSegmentsList());
                                handleClose();
                            } else {
                                toast.error(res.message);
                                setPending(false);
                            }
                        }
                    }}
                >
                    Save
                    {pending && (
                        <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    )}
                </button>
                <button
                    type="button"
                    className="btn btn-warning"
                    onClick={handleClose}
                >
                    Close
                </button>
            </Modal.Footer>
        </Modal>
    );
};

function DownloadSegment({ dataCell }) {
    const [pending, setPending] = useState(false);

    const handleDowload = (id) => async () => {
        setPending(true);
        let res = await SegmentService.downloadSegment(id);
        try {
            if (res.status === 200) {
                fileDownload(res.data, res?.fileName);
                setPending(false);
            } else {
                setPending(false);
                toast.error(res.message);
            }
        } catch (err) {
            setPending(false);
            toast.error("Something went wrong while downloading this file");
        }
    };

    return (
        <button
            type="button"
            className="btn btn-sm waves-effect waves-light btn-rounded btn-outline-secondary"
            onClick={handleDowload(dataCell.id)}
            disabled={pending}
        >
            {pending ? (
                <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                />
            ) : (
                <i className="fas fa-download"></i>
            )}
        </button>
    );
}
