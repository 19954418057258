import React, {useEffect, useMemo, useRef, useState } from 'react'
import {  Modal, OverlayTrigger, Table, Tooltip} from 'react-bootstrap';
import { Helmet } from 'react-helmet'
import { useHistory ,useLocation} from "react-router-dom";
import DuplicateService from "../../services/DuplicateService"
import { toast } from 'react-toastify';
import ConfirmAction from '../../components/ConfirmActionsModal';
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux';
import { getCountries } from '../../redux/actions/CountryActions';
import fileDownload from 'js-file-download';

export default function ManageDuplicate() {
    const history = useHistory();
    const location = useLocation();
    const [data,setData] = useState(null)
    const [isLoading,setLoading] = useState(false)
    const [isLoadingDownalod,setLoadingDownload] = useState(false)
    const [isLoadingUpdate,setisLoadingUpdate] = useState(false)
    const [groupSeelected ,setGroupSeelected] = useState(null)
    const [keywordSearsh,setKeywordSearch] = useState("")
    const [CountrySelected,setCountrySelected] = useState([])
    const queryParams = new URLSearchParams(location.search);
    const dispatch = useDispatch();
    const timeoutId = useRef(null);
    const { countries} = useSelector((state) => ({
        countries: state.countriesReducer.countries,
    }));


    const getGroupHotels = async(keyword=keywordSearsh,country=CountrySelected) =>{
            setLoading(true)
            setData(null)
            const getGroupDuplicate = await DuplicateService.getduplicationGroup(queryParams.get('id'),keyword,country)
            if(getGroupDuplicate){
                setLoading(false)
                if(getGroupDuplicate.status == 200)
                    setData(getGroupDuplicate.data)
                else {
                    toast.error("error getting duplicate",{toastId: "error getting duplicate"});
                }
            }
    }
    const searchByKeyword = () =>{
        console.log("keywordSearsh => " , keywordSearsh)
        keywordSearsh && getGroupHotels()
    }
    const changeDropdown = (value) =>{
        queryParams.set('properties', value)
        history.replace(`${location.pathname}?${queryParams.toString()}`);
    }
    const changeAllProprieties = async() =>{
        setisLoadingUpdate(true)
        const markDuplicateActive = await DuplicateService.markDuplicateActive(queryParams.get('id'),queryParams.get('properties'))
        if(markDuplicateActive){
            setisLoadingUpdate(false)
            if(markDuplicateActive.status == 200){
                toast.success(markDuplicateActive.data,{toastId: markDuplicateActive.data});
                queryParams.delete('properties');
                history.replace(`${location.pathname}?${queryParams.toString()}`);
                getGroupHotels()
            }
        }
    }
    const handleFilterCountry = async(e) =>{
        setCountrySelected(e)
        if (timeoutId.current) {
            clearTimeout(timeoutId.current);
        }
        timeoutId.current = setTimeout(() => {
            getGroupHotels(keywordSearsh, e);
        }, 500);
            
    }
    const DonwloadDuplicate = async() =>{
        setLoadingDownload(true)
        const downloadFunction =  await DuplicateService.downloadDuplicate(queryParams.get('id'))
        if(downloadFunction){
            setLoadingDownload(false)
        }
        try {
            if (downloadFunction.status === 200) {
                fileDownload(downloadFunction.data, downloadFunction?.fileName);
            } 
           else if(downloadFunction.status == 401){
                    setTimeout(() => {
                        window.location =
                            window.location.protocol +
                            "//" +
                            window.location.host +
                            "/sign-in";
                    }, 3000);
                    localStorage.clear();
                    return toast.error(" Session expired", {
                        toastId: "session-expired-id",
                    });
            }
            else {
                toast.error(downloadFunction.message);
            }
        } catch (err) {
            toast.error("Something went wrong while downloading this file");
        }
    }


    useEffect(()=>{
        dispatch(getCountries());
        getGroupHotels()
    },[])

    const GetValueVountry = useMemo(()=>{
        if(Array.isArray(countries)){
            const newState = countries.map(item=>{
                return{
                    value: item.iso2, 
                    label: item.name
                }
            })
            return newState
        }
        
        return []
    },[countries])

  return (
    <>
            {
                queryParams.get('properties') ?
                <ConfirmAction
                    show={true}
                    onClose={() => {
                        queryParams.delete('properties');
                        history.replace(`${location.pathname}?${queryParams.toString()}`);
                    }}
                    titleMessage="Warning !"
                    subTitleMessage={`Are you sure you want chanage all properties to ${queryParams.get('properties')} ?`}
                    colorCode="danger"
                    handleClick={()=>{changeAllProprieties()}}
                    buttonText="Confirm"
                    disabled={isLoadingUpdate}
                />
                :
                null
            }
            {
                groupSeelected ?
                    <ModelDuplicate 
                        groupSeelected={groupSeelected}
                        setGroupSeelected={setGroupSeelected} 
                        queryParams={queryParams} 
                        getGroupHotels={getGroupHotels}
                    /> : null
                }
            <Helmet>
                <title> Manage duplicates </title>
            </Helmet>
            <div className="page-wrapper " style={{ display: "block" }}>
                <div  className="duplicateInventory">
                    <div className='duplicateInventory-back' onClick={()=>{history.push("/catalogs-manager")}}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19 12H5M5 12L12 19M5 12L12 5" stroke="#6275E7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <p class="text-purple fs-6 fw-bold font-family-Lato m-0">Back</p>
                    </div>
                    <div >
                        <h3 className="page-title text-truncate text-dark font-weight-medium">
                            Manage duplicates
                        </h3>
                    </div>
                            {/* <div className='duplicateInventoryTabs'>
                                <p onClick={()=>{chnageTabs('automatic')}} class={`text-center  fs-6 fw-bold font-family-Lato m-0 px-3 py-2  ${queryParams.get('type') == 'automatic' ? 'ActiveTab' : '' }`} >Automatic</p>
                                <p onClick={()=>{chnageTabs('manual')}} class={`text-center  fs-6 fw-bold font-family-Lato m-0 px-3 py-2  ${queryParams.get('type') == 'manual' ? 'ActiveTab' : '' }`}>Manual</p>
                                <p onClick={()=>{chnageTabs('vacationrentals')}} class={`text-center  fs-6 fw-bold font-family-Lato m-0 px-3 py-2  ${queryParams.get('type') == 'vacationrentals' ? 'ActiveTab' : '' }`}>Vacation Rentals</p>
                            </div> */}
                                    <div className='searchAcionduplicateInventory'>
                                        <div className='searchFilter'>
                                            <div className='search'>
                                                    <div className='inputSvg'>
                                                        <input type="text" name="" id="" placeholder='search' value={keywordSearsh}onChange={(e)=>{setKeywordSearch(e.target.value)}}/>
                                                        {
                                                            keywordSearsh ? 
                                                            <svg onClick={()=>{setKeywordSearch("");getGroupHotels(null)}} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                                                <path d="M12.5 4.5L4.5 12.5M4.5 4.5L12.5 12.5" stroke=" #a3b0e4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                            </svg>
                                                            :
                                                            null
                                                        }                                                        
                                                    </div>
                                                    <div className='SearchBtn' onClick={()=>{searchByKeyword()}}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="transparent">
                                                            <path stroke="white" d="M21 21L15.0001 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                        </svg>
                                                    </div>
                                            </div>
                                            <div className='MultiSEelect'>
                                                <Select    
                                                    options={GetValueVountry} 
                                                    closeMenuOnSelect={false}
                                                    isMulti
                                                    placeholder="Filter by country"
                                                    onChange={(e)=>{handleFilterCountry(e) }}
                                                />
                                            </div>
                                        </div>
                                        <div className='searchAcionduplicateInventory-actions'>
                                            <div class="dropdown ">
                                                <button class="btn btn-secondary dropdown-toggle dropdownACtionDuplicate" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                        <path d="M4.66667 2.66675V13.3334M4.66667 13.3334L2 10.6667M4.66667 13.3334L7.33333 10.6667M11.3333 13.3334V2.66675M11.3333 2.66675L8.66667 5.33341M11.3333 2.66675L14 5.33341" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                    Keep properties with
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                        <path d="M4.66663 9.99984L7.99996 13.3332L11.3333 9.99984M4.66663 5.99984L7.99996 2.6665L11.3333 5.99984" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                                                        </svg>
                                                </button>
                                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    <span  onClick={()=>{data &&  Array.isArray(data.groups) && changeDropdown('highest')}}>Highest id</span>
                                                    <span  onClick={()=>{data &&  Array.isArray(data.groups) && changeDropdown('lowest')}}>Lowest id</span>
                                                </div>
                                            </div>
                                            <div className="BTnDetect" onClick={()=>{!isLoadingDownalod && DonwloadDuplicate()}}>
                                                {
                                                    !isLoadingDownalod ?
                                                    <>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path d="M21 21H3M18 11L12 17M12 17L6 11M12 17V3" stroke="#6275E7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                        </svg>
                                                        Download
                                                    </>
                                                    :
                                                     <div class="spinner-border " role="status"></div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className='w-100'>

                                        {
                                            data && data.count ?
                                            <p class="text-secondary fs-6 fw-normal font-family-Lato  m-0 pb-3">Showing {data.count > 10 ? 10 : data.count} out of {data.count} properties</p>
                                            :
                                            null
                                        }
                                        
                                        <div className='duplicateInventoryTable'>
                                            <Table striped bordered hover size="sm">
                                                <thead >
                                                    <tr className='p-1 bg-light border border--bottom1 border-success-subtle px-2'>
                                                        <th className='py-2'>Code</th>
                                                        <th className='py-2'>Name</th>
                                                        <th className='py-2'>Country</th>
                                                        <th className='py-2'>City</th>
                                                        <th className='py-2'>Address</th>
                                                        <th className='py-2'></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                            data &&  Array.isArray(data.groups) ? data.groups.map(item =>{
                                                            const getActive = item.hotel_details.find(item => item.is_active)
                                                            const getDeleted = item.hotel_details.filter(item => item.is_deleted)
                                                            return(
                                                                <tr>
                                                                    <td style={{width:"10%"}}>{getActive ? getActive.inventory_hotel_code : item.hotel_details[0].inventory_hotel_code}</td>
                                                                    <td style={{width:"30%"}}>
                                                                        <div className='duplicateInventoryTable-name'>
                                                                            <p style={{width:getDeleted.length == item.hotel_details.length ? "70%":"100%"}}>{getActive ? getActive.inventory_hotel_name : item.hotel_details[0].inventory_hotel_name}</p>
                                                                            {
                                                                                getDeleted.length == item.hotel_details.length
                                                                                ?
                                                                                <OverlayTrigger
                                                                                        placement="top"
                                                                                        delay={{ show: 250, hide: 400 }}
                                                                                        overlay={<Tooltip id={`button-tooltip All properties belonging to this group have been removed`} >All properties belonging to this group have been removed</Tooltip>}
                                                                                    >
                                                                                    <span>Removed</span>
                                                                                    </OverlayTrigger>
                                                                                
                                                                                :
                                                                                null
                                                                            } 
                                                                        </div>
                                                                        
                                                                    </td>
                                                                    <td style={{width:"5%"}}>{getActive ? getActive.inventory_hotel_country : item.hotel_details[0].inventory_hotel_country}</td>
                                                                    <td style={{width:"15%"}}>la {getActive ? getActive.inventory_hotel_city : item.hotel_details[0].inventory_hotel_city}</td>
                                                                    <td style={{width:"30%"}}>{getActive ? getActive.inventory_hotel_address : item.hotel_details[0].inventory_hotel_address}</td>
                                                                    <td style={{width:"5%"}}>
                                                                        <span className="BTnDetect" onClick={()=>{setGroupSeelected(item)}}>
                                                                            Review
                                                                        </span>
                                                                    </td>
                                                                </tr>    
                                                            )
                                                        })
                                                        : null
                                                    }
                                                </tbody>
                                            </Table>
                                            {
                                                    (!data || !Array.isArray(data.groups)) && !isLoading
                                                    ?
                                                    <div className='w-100' style={{backgroundColor:"white", padding:"100px 0px",textAlign:"center"}} >
                                                        <img src="/assets/images/notfoundTbale.svg" alt="" style={{width:"300px"}} />
                                                        <h4 style={{fontWeight:"700"}}><strong>No properties</strong></h4>
                                                        <p className="h5">No properties meet your filters criteria</p>
                                                    </div>
                                                    : 
                                                    null    
                                            }
                                            {
                                                    isLoading ?
                                                    <div style={{textAlign:"center"}}>
                                                        <div class="spinner-border spinnerDuplicatePgae" role="status"></div>
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>

                </div>
            </div>
    </>

  )
}
const ModelDuplicate = ({groupSeelected,setGroupSeelected,queryParams,getGroupHotels}) =>{
    const [loadingUpdate,setisLoadingUpdate] = useState(false)
    const [ActiveSWitch,setActiveSwitch] = useState(true)
    const [data,setData] = useState(JSON.parse(JSON.stringify(groupSeelected.hotel_details)))
    const dataRef = useRef(JSON.parse(JSON.stringify(groupSeelected.hotel_details)))
    const anyItemActive = data.filter(item => item.is_active);
    const anyItemdeleted = data.filter(item => item.is_deleted);
    let stopLoop = false
    useEffect(()=>{
        const newState = [...groupSeelected.hotel_details]
        if (anyItemActive.length == 0 && anyItemdeleted.length < data.length){
            newState.map(item=>{
                if(!item.is_active  && !item.is_deleted && !stopLoop){
                    item.is_active = true
                    stopLoop=true
                }
            })
            setData([...newState])
        }
    },[])
    const seletctItem = (id) =>{
        setData(prevArray => prevArray.map(item => {
            if (item.inventory_hotel_code == id) 
                return {...item, is_active: true}
            else 
                return {...item, is_active: false}
        }));
    }
    const deleteItem = (id) =>{
        const newState = [...data]
        const getDeleted = newState.find(item =>item.is_deleted)
        newState.map((item,index) =>{
            if (item.inventory_hotel_code == id){
                item.is_deleted= true
                item.is_active = false
            }
            if(getDeleted || newState.length == 2){
                item.is_deleted= true
                item.is_active = false
            }
        })
        const getACtive = newState.find(item =>item.is_active)
        if(!getACtive && !getDeleted){
            let keepLoop = true
            newState.map((item,index) =>{
                if(!item.is_deleted && keepLoop){
                    item.is_active = true
                    keepLoop = false
                }
            })
        }
         setData([...newState])
    }
    const Undo = (id) =>{
        const newState = [...data]
        const getDeleted = newState.filter(item =>item.is_deleted)
        if(getDeleted.length == newState.length){
            newState.map((item,index) =>{
                if (item.inventory_hotel_code == id){
                    item.is_deleted = false
                    item.is_active = true
                    if(newState.length > index+1)
                        newState[index+1].is_deleted = false
                    else
                        newState[0].is_deleted = false
                }
            })

             setData([...newState])
        }else{
            setData(prevArray => prevArray.map(item => {
                if (item.inventory_hotel_code == id)
                    return {...item,is_deleted: false}
                return item;
            }));
        }
    }
    const submmitData = async() =>{
        const datamark = {
            inventoriesID : parseInt(queryParams.get('id')),
            groupID: groupSeelected.group_id,
            ActiveHotelCode:null,
            DeleteHoteslCode: [],
            UndoDeleteHoteslCode: []
        }
        data.map((item,index) =>{
            if(item.is_active)
                datamark.ActiveHotelCode= item.inventory_hotel_code
            if(item.is_deleted)
                datamark.DeleteHoteslCode.push(item.inventory_hotel_code)
            if(!item.is_deleted && dataRef.current[index].is_deleted)
                datamark.UndoDeleteHoteslCode.push(item.inventory_hotel_code)
        })
        setisLoadingUpdate(true)
        const markActiveDelete = 
        await DuplicateService.makeDuplicateActiveDelete(queryParams.get('id'),groupSeelected.group_id,datamark.ActiveHotelCode,datamark)
        if(markActiveDelete){
            setisLoadingUpdate(false)
            if(markActiveDelete.status == 200){
                toast.success(markActiveDelete.data,{toastId: markActiveDelete.data});
                getGroupHotels()
                setGroupSeelected(null)
            }
        }
    }
    return(
        <>
            <Modal show={true} onHide={()=>{setGroupSeelected(null)}} className='ModelDuplicate'>
                <Modal.Header closeButton>
                    <Modal.Title>Review duplicates</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='swtichDuplicateModel'>
                        <span onClick={()=>{setActiveSwitch(true)}} className={`${ActiveSWitch ? "Active" : ""}`}>
                            Switch
                        </span>
                        <span  onClick={()=>{setActiveSwitch(false)}} className={`${!ActiveSWitch ? "Active" : ""}`}>
                            Report Error
                        </span>
                    </div>
                    <div className='ContentDuplicate'>
                        <p className='Description'>
                            {
                                ActiveSWitch ?
                                "Choose the property you want to keep in your inventory"
                                :
                                data.filter(item =>item.is_deleted).length != data.length
                                ?
                                "Choose the properties you want to remove from this group"
                                :
                                <span className='warningDuplicateRemove'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <g clip-path="url(#clip0_787_624)">
                                            <path d="M9.99996 13.3332V9.99984M9.99996 6.6665H10.0083M18.3333 9.99984C18.3333 14.6022 14.6023 18.3332 9.99996 18.3332C5.39759 18.3332 1.66663 14.6022 1.66663 9.99984C1.66663 5.39746 5.39759 1.6665 9.99996 1.6665C14.6023 1.6665 18.3333 5.39746 18.3333 9.99984Z" stroke="#DC6803" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_787_624">
                                            <rect width="20" height="20" fill="white"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    Removing this property will deprecate this duplicate group
                                </span>
                            }
                        </p>
                        {
                            ActiveSWitch ? 
                            data.map(item =>{
                                return(
                                    <div 
                                        onClick={()=>{!item.is_deleted && seletctItem(item.inventory_hotel_code)}}
                                        className={`DuplicateItem ${item.is_active ? "activeItem" : ""} 
                                        ${item.is_deleted ? "desactivateItem" : ""}`}>
                                        <div>
                                            <p>{item.inventory_hotel_name} . {item.inventory_hotel_code}</p>
                                            <span>{item.inventory_hotel_address}</span>
                                        </div>
                                        {
                                          item.is_active ?
                                           <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                               <rect x="0.75" y="0.75" width="14.5" height="14.5" rx="7.25" stroke="#6275E7" stroke-width="1.5"/>
                                               <rect x="3.5" y="3.5" width="9" height="9" rx="4.5" fill="#6275E7"/>
                                           </svg>
                                           :
                                           item.is_deleted ?
                                           <span className='textRemoved'>Removed from this group</span>
                                           :
                                           <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                               <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke="#DADEF2"/>
                                           </svg>
                                        }
                                    </div>
                                )
                            })
                            :
                            data.map(item =>{
                                const newState = data.filter(item => !item.is_deleted)
                                return(
                                    <div 
                                        className={`DuplicateItem ${item.is_deleted ? "desactivateItem" : ""}`}
                                    >
                                       
                                        <div>
                                            <p>{item.inventory_hotel_name} . {item.inventory_hotel_code}</p>
                                            <span>{item.inventory_hotel_address}</span>
                                        </div>
                                        {
                                            item.is_deleted ?
                                            <div>
                                                <span className='textRemoved'>Removed from this group</span>
                                                <div className='undo' onClick={()=>{Undo(item.inventory_hotel_code)}}>
                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M2 5.99992H11C12.6569 5.99992 14 7.34306 14 8.99992C14 10.6568 12.6569 11.9999 11 11.9999H8M2 5.99992L4.66667 3.33325M2 5.99992L4.66667 8.66659" stroke="#6275E7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                    <p class="text-center text-purple fs-6 fw-semibold font-family-Lato  m-0 p-0">undo</p>
                                                </div>
                                            </div>
                                            :
                                            <svg onClick={()=>{ newState.length !== 1  && deleteItem(item.inventory_hotel_code)}} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_735_386)">
                                                <path d="M6.66663 9.99984H13.3333M18.3333 9.99984C18.3333 14.6022 14.6023 18.3332 9.99996 18.3332C5.39759 18.3332 1.66663 14.6022 1.66663 9.99984C1.66663 5.39746 5.39759 1.6665 9.99996 1.6665C14.6023 1.6665 18.3333 5.39746 18.3333 9.99984Z" stroke="#F97066" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                </g>
                                                <defs>
                                                <clipPath id="clip0_735_386">
                                                <rect width="20" height="20" fill="white"/>
                                                </clipPath>
                                                </defs>
                                            </svg>
                                        }
                                    </div>
                                )
                            })        
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer className='Btns'>
                    <button className='DoneBtn' onClick={()=>{!loadingUpdate && submmitData()}}>
                        {
                            !loadingUpdate ?
                            "Done"
                            :
                             <div class="spinner-border spinnerMoadalDuplicateActive" role="status"></div>
                        }
                        
                    </button>
                    <button className='CancelBtn' onClick={()=>{! loadingUpdate && setGroupSeelected(null)}}>Cancel</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

