import React from "react";
import { useSelector } from "react-redux";

const styleBR = { borderRight: "1px solid rgba(108, 117, 125, 0.36)" };
const styleBB = { borderBottom: "1px solid rgba(108, 117, 125, 0.36)" };
const styleBTL = { borderTopLeftRadius: "8px" };
const styleBTR = {
    borderTopRightRadius: "8px",
};
const headerBackground = { backgroundColor: "rgb(225 228 252)" };
const dataBackground = { backgroundColor: "rgba(225, 228, 252, 0.32)" };
const styleHeader = {
    color: "rgba(0,0,0,0.87)",
    fontSize: "16px",
    fontWeight: "600",
};

const MappingDetails = (props) => {
    const [showDetails, setShowDetails] = React.useState(true);
    const { compareMappingTotal } = useSelector((state) => ({
        compareMappingTotal: {
            ...state.partnerReportReducer.compareMappingTotal,
            current_pourcentage:
                (state.partnerReportReducer.compareMappingTotal
                    ?.current_mapped /
                    state.partnerReportReducer.compareMappingTotal
                        ?.current_partner_total -
                    state.partnerReportReducer.compareMappingTotal
                        ?.target_mapped /
                        state.partnerReportReducer.compareMappingTotal
                            ?.target_partner_total) *
                100,
            target_pourcentage:
                ((state.partnerReportReducer.compareMappingTotal
                    ?.target_mapped -
                    state.partnerReportReducer.compareMappingTotal
                        ?.current_mapped) /
                    state.partnerReportReducer.compareMappingTotal
                        ?.target_mapped) *
                100,
        },
    }));

    const renderPourcentage = (selectedValue, currentValue) => {
        let data = 0;
        if (currentValue > 0 && selectedValue > 0) {
            data = ((currentValue - selectedValue) / selectedValue) * 100;
        }
        return (
            <>
                {data > 0 ? (
                    <div className="bg-light-danger badge success-badge-report">
                        <p
                            className="fs-3 text-success  fw-semibold mb-0"
                            style={{
                                marginTop: "0px",
                            }}
                        >
                            +{parseFloat(data).toFixed(2) ?? 0}%
                        </p>
                    </div>
                ) : data < 0 ? (
                    <div className="bg-light-danger badge danger-badge-report">
                        <p
                            className="fs-3 text-danger  fw-semibold mb-0"
                            style={{
                                marginTop: "0px",
                            }}
                        >
                            {parseFloat(data).toFixed(2) ?? 0}%
                        </p>
                    </div>
                ) : (
                    <></>
                )}
            </>
        );
    };

    return (
        <div className="card" style={{ minHeight: "612px" }}>
            <div className="col-lg-12 col-md-12">
                <div className="table-responsive mt-4">
                    <table className="table table-borderless text-nowrap align-middle mb-0">
                        <thead>
                            <tr
                                style={{
                                    ...headerBackground,
                                    ...styleBB,
                                }}
                            >
                                <th style={{ ...styleBTL }}></th>
                                <th
                                    style={{
                                        ...styleBR,
                                    }}
                                ></th>
                                <th
                                    style={{
                                        ...styleHeader,
                                        ...styleBR,
                                    }}
                                >
                                    Selected mapping
                                </th>
                                <th
                                    style={{
                                        ...styleHeader,
                                        ...styleBTR,
                                    }}
                                >
                                    Current mapping
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                style={{
                                    ...dataBackground,
                                    ...styleBB,
                                }}
                            >
                                <td className="bg-transparent"></td>
                                <td
                                    className="bg-transparent"
                                    style={{ ...styleBR }}
                                >
                                    <div className="d-flex align-items-center gap-3">
                                        <span style={{ fontWeight: "600" }}>
                                            Mapped properties
                                        </span>
                                    </div>
                                </td>
                                <td
                                    className="bg-transparent"
                                    style={{ ...styleBR }}
                                >
                                    <div className="d-flex align-items-center gap-3">
                                        <span>
                                            {compareMappingTotal?.target_mapped}
                                        </span>
                                    </div>
                                </td>

                                <td className=" bg-transparent">
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <span>
                                            {
                                                compareMappingTotal?.current_mapped
                                            }
                                        </span>
                                        <span>
                                            {renderPourcentage(
                                                compareMappingTotal?.target_mapped,
                                                compareMappingTotal?.current_mapped
                                            )}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr
                                style={{
                                    ...dataBackground,
                                    ...styleBB,
                                }}
                            >
                                <td className="bg-transparent"></td>
                                <td
                                    className="bg-transparent"
                                    style={{ ...styleBR }}
                                >
                                    <div className="d-flex align-items-center gap-3">
                                        <span style={{ fontWeight: "600" }}>
                                            Mapped ranked hotels
                                        </span>
                                    </div>
                                </td>
                                <td
                                    className="bg-transparent"
                                    style={{ ...styleBR }}
                                >
                                    <div className="d-flex align-items-center gap-3">
                                        <span>
                                            {compareMappingTotal?.target_ranked_mapped_total ??
                                                "--"}
                                        </span>
                                    </div>
                                </td>
                                <td className=" bg-transparent">
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <span>
                                            {compareMappingTotal?.current_ranked_mapped_total ??
                                                "--"}
                                        </span>
                                        <span>
                                            {renderPourcentage(
                                                compareMappingTotal?.target_ranked_mapped_total,
                                                compareMappingTotal?.current_ranked_mapped_total
                                            )}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr
                                style={{
                                    ...dataBackground,
                                    ...styleBB,
                                }}
                            >
                                <td className="bg-transparent"></td>
                                <td
                                    className="bg-transparent"
                                    style={{ ...styleBR }}
                                >
                                    <div className="d-flex align-items-center gap-3">
                                        <span style={{ fontWeight: "600" }}>
                                            Opportunities
                                        </span>
                                    </div>
                                </td>
                                <td
                                    className="bg-transparent"
                                    style={{ ...styleBR }}
                                >
                                    <div className="d-flex align-items-center gap-3">
                                        {compareMappingTotal?.target_unmapped ??
                                            "--"}
                                    </div>
                                </td>

                                <td className=" bg-transparent">
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <span>
                                            {compareMappingTotal?.current_unmapped ??
                                                "--"}
                                        </span>
                                        <span>
                                            {renderPourcentage(
                                                compareMappingTotal?.target_unmapped,
                                                compareMappingTotal?.current_unmapped
                                            )}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr
                                style={{
                                    ...dataBackground,
                                    ...styleBB,
                                }}
                            >
                                <td className="bg-transparent"></td>
                                <td
                                    className="bg-transparent"
                                    style={{ ...styleBR }}
                                >
                                    <div className="d-flex align-items-center gap-3">
                                        <span style={{ fontWeight: "600" }}>
                                            Invalid
                                        </span>
                                    </div>
                                </td>
                                <td
                                    className="bg-transparent"
                                    style={{ ...styleBR }}
                                >
                                    <div className="d-flex align-items-center gap-3">
                                        <span>
                                            {compareMappingTotal?.target_invalid_partner ??
                                                "--"}
                                        </span>
                                    </div>
                                </td>

                                <td className=" bg-transparent">
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <span>
                                            {compareMappingTotal?.current_invalid_partner ??
                                                "--"}
                                        </span>
                                        <span>
                                            {renderPourcentage(
                                                compareMappingTotal?.target_invalid_partner,
                                                compareMappingTotal?.current_invalid_partner
                                            )}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr
                                style={{
                                    borderBottom: showDetails
                                        ? "1px solid #6c757d5c"
                                        : "0px",
                                    borderTop: "1px solid #6c757d5c",
                                    cursor: "pointer",
                                    ...headerBackground,
                                }}
                                onClick={() => {
                                    setShowDetails(!showDetails);
                                }}
                            >
                                <td
                                    className="bg-transparent"
                                    style={{
                                        //  borderTopLeftRadius: "8px",
                                        borderBottomLeftRadius: showDetails
                                            ? "0px"
                                            : "8px",
                                        width: "7px",
                                        paddingRight: "0px",
                                    }}
                                >
                                    <button
                                        className="btn"
                                        style={{
                                            padding: "0px",
                                        }}
                                        onClick={() => {
                                            setShowDetails(!showDetails);
                                        }}
                                    >
                                        {showDetails ? (
                                            <i className="fas fa-chevron-down"></i>
                                        ) : (
                                            <i className="fas fa-chevron-right"></i>
                                        )}
                                    </button>
                                </td>
                                <td className="bg-transparent">
                                    <div className="d-flex align-items-center gap-3">
                                        <span style={{ fontWeight: "600" }}>
                                            Details on each mapping report
                                        </span>
                                    </div>
                                </td>
                                <td className="bg-transparent"></td>

                                <td
                                    className=" bg-transparent"
                                    style={{
                                        // borderTopRightRadius: "8px",
                                        borderBottomRightRadius: showDetails
                                            ? "0px"
                                            : "8px",
                                    }}
                                ></td>
                            </tr>
                            {showDetails && (
                                <>
                                    <tr
                                        style={{
                                            ...dataBackground,
                                            ...styleBB,
                                        }}
                                    >
                                        <td
                                            className="bg-transparent"
                                            style={{
                                                width: "7px",
                                            }}
                                        ></td>
                                        <td
                                            className="bg-transparent"
                                            style={{ ...styleBR }}
                                        >
                                            <div className="d-flex align-items-center gap-3">
                                                <span
                                                    style={{
                                                        fontWeight: "600",
                                                    }}
                                                >
                                                    Model used for the mapping
                                                </span>
                                            </div>
                                        </td>
                                        <td
                                            className="bg-transparent"
                                            style={{ ...styleBR }}
                                        >
                                            <div className="d-flex align-items-center gap-3">
                                                <span>
                                                    {compareMappingTotal?.target_model ??
                                                        "--"}
                                                </span>
                                            </div>
                                        </td>

                                        <td className=" bg-transparent">
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent:
                                                        "space-between",
                                                }}
                                            >
                                                <span>
                                                    {compareMappingTotal?.current_model ??
                                                        "--"}
                                                </span>
                                                <span>
                                                    {renderPourcentage(
                                                        compareMappingTotal?.target_model,
                                                        compareMappingTotal?.current_model
                                                    )}
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr
                                        style={{
                                            ...dataBackground,
                                            ...styleBB,
                                        }}
                                    >
                                        <td className="bg-transparent"></td>
                                        <td
                                            className="bg-transparent"
                                            style={{ ...styleBR }}
                                        >
                                            <div className="d-flex align-items-center gap-3">
                                                <span
                                                    style={{
                                                        fontWeight: "600",
                                                    }}
                                                >
                                                    Total inventory properties
                                                </span>
                                            </div>
                                        </td>
                                        <td
                                            className="bg-transparent"
                                            style={{ ...styleBR }}
                                        >
                                            <div className="d-flex align-items-center gap-3">
                                                <span>
                                                    {compareMappingTotal?.target_inventory_total ===
                                                    -1
                                                        ? "N/A"
                                                        : compareMappingTotal?.target_inventory_total}
                                                </span>
                                            </div>
                                        </td>
                                        <td className=" bg-transparent">
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent:
                                                        "space-between",
                                                }}
                                            >
                                                <span>
                                                    {compareMappingTotal?.current_inventory_total ===
                                                    -1
                                                        ? "N/A"
                                                        : compareMappingTotal?.current_inventory_total}
                                                </span>
                                                <span>
                                                    {compareMappingTotal?.current_inventory_total >
                                                        -1 &&
                                                    compareMappingTotal?.current_inventory_total >
                                                        -1
                                                        ? renderPourcentage(
                                                              compareMappingTotal?.target_inventory_total,
                                                              compareMappingTotal?.current_inventory_total
                                                          )
                                                        : ""}
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr
                                        style={{
                                            backgroundColor: "#e1e4fc52",
                                            ...styleBB,
                                            //  marginBottom: "20px",
                                        }}
                                    >
                                        <td className="bg-transparent"></td>
                                        <td
                                            className="bg-transparent"
                                            style={{ ...styleBR }}
                                        >
                                            <div className="d-flex align-items-center gap-3">
                                                <span
                                                    style={{
                                                        fontWeight: "600",
                                                    }}
                                                >
                                                    Total partner properties
                                                </span>
                                            </div>
                                        </td>
                                        <td
                                            className="bg-transparent"
                                            style={{ ...styleBR }}
                                        >
                                            <div className="d-flex align-items-center gap-3">
                                                <span>
                                                    {compareMappingTotal?.target_partner_total ??
                                                        "--"}
                                                </span>
                                            </div>
                                        </td>
                                        <td className=" bg-transparent">
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent:
                                                        "space-between",
                                                }}
                                            >
                                                <span>
                                                    {compareMappingTotal?.current_partner_total ??
                                                        "--"}
                                                </span>
                                                <span>
                                                    {renderPourcentage(
                                                        compareMappingTotal?.target_partner_total,
                                                        compareMappingTotal?.current_partner_total
                                                    )}
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr
                                        style={{
                                            backgroundColor: "#e1e4fc52",
                                            marginBottom: "20px",
                                        }}
                                    >
                                        <td
                                            className="bg-transparent"
                                            style={{
                                                width: "7px",
                                                borderBottomLeftRadius: "8px",
                                            }}
                                        >
                                            <button
                                                className="btn"
                                                style={{
                                                    padding: "0px",
                                                }}
                                            ></button>
                                        </td>
                                        <td
                                            className="bg-transparent"
                                            style={{ ...styleBR }}
                                        >
                                            <div className="d-flex align-items-center gap-3">
                                                <span
                                                    style={{
                                                        fontWeight: "600",
                                                    }}
                                                >
                                                    Total ranked hotels
                                                </span>
                                            </div>
                                        </td>
                                        <td
                                            className="bg-transparent"
                                            style={{ ...styleBR }}
                                        >
                                            <div className="d-flex align-items-center gap-3">
                                                <span>
                                                    {compareMappingTotal?.total_ranked_hotels ??
                                                        "--"}
                                                </span>
                                            </div>
                                        </td>
                                        <td
                                            className=" bg-transparent"
                                            style={{
                                                borderBottomRightRadius: "8px",
                                            }}
                                        >
                                            <span>
                                                {compareMappingTotal?.total_ranked_hotels ??
                                                    "--"}
                                            </span>
                                        </td>
                                    </tr>
                                </>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default MappingDetails;
