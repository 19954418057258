import {
    GET_HOTELS_INVENTORY_LIST_LISTING,
    GET_HOTELS_INVENTORY_LIST_SUCCESS,
    GET_HOTELS_INVENTORY_LIST_FAIL,
    GET_REFERENCE_HOTEL_SUCCESS,
    GET_REFERENCE_HOTEL_FAIL,
    GET_REFERENCE_HOTEL_LISTING,
} from "../types";

const initialState = {
    hotelList: [],
    loadingHotelList: false,
    totalHotels: 0,
    hotelDetails: {},
    loadingHotelDetails: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case GET_HOTELS_INVENTORY_LIST_LISTING:
            return {
                ...state,
                loadingHotelList: true,
                hotelList: [],
            };
        case GET_HOTELS_INVENTORY_LIST_SUCCESS:
            return {
                ...state,
                hotelList: action.payload,
                loadingHotelList: false,
                totalHotels: action.total,
            };
        case GET_HOTELS_INVENTORY_LIST_FAIL:
            return {
                ...state,
                hotelList: [],
                loadingHotelList: false,
                totalHotels: 0,
            };
        case GET_REFERENCE_HOTEL_LISTING:
            return {
                ...state,
                hotelDetails: true,
                loadingHotelDetails: [],
            };
        case GET_REFERENCE_HOTEL_SUCCESS:
            return {
                ...state,
                hotelDetails: action.payload,
                loadingHotelDetails: false,
            };
        case GET_REFERENCE_HOTEL_FAIL:
            return {
                ...state,
                hotelDetails: {},
                loadingHotelDetails: false,
            };
        default:
            return state;
    }
}
