import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import Table from "../../components/Table/Table";
import DownloadCatalog from "./DownloadCatalog";
import { getPartnerCatalogsByStatus } from "../../redux/actions/PartnerCataogActions";
import { useDispatch, useSelector } from "react-redux";
import { Spinner, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import CatalogNoDataModal from "./CatalogNoDataModal";
import { useHistory } from "react-router-dom";
import SuccessUploadPartner from "./SucessUploadPartnerDialog";
import { Overlay as OverlayTooltip } from "react-bootstrap";
import useCollapse from "react-collapsed";
import { ListGroup, Modal, Row, Col, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import MappingDestinationModal from "./MappingDestinationModal";
import { getCatalogs } from "../../redux/actions/CatalogActions";
import MappingDetailsModel from "./MappingDetailsModel";
import {
    checkPartnerCatalog,
    partnerGrouppdListByAction,
    partnerGrouppdListByActionFirst,
} from "../../redux/actions/PartnerActions";
import {
    STATUS_SUCCESS_INVENTORY,
    STATUS_SUCCESS_CATALOG,
    STATUS_PROCESSING,
    STATUS_PENDING,
    CREATE_CATALOG,
    CREATE_INVENTORY,
    GIATA_MODEL,
    CUPID_MODEL,
    SHOW_ALL,
    SHOW_ACTIVE,
    SHOW_INACTIVE,
    MAP_MANUALLY,
    AFFILIATE,
    ACTIVATE_CATALOG,
    LIST_OPPORTUNITIES,
} from "../../utilis/const";
import { hasPermission } from "../../utilis/functions";
import { separator } from "../../utilis/functions";
import ShareMappingReport from "./ShareMappingReportModal";
import {
    getGiataPartnersList,
    getCupidModels,
    hasGiataCredentials,
} from "../../redux/actions/ModelsActions";
import SyncLoader from "react-spinners/SyncLoader";
import Select from "../../components/Select";

import GiataLogo from "../../lotties/Giata-logo.svg";
import CupidLogo from "../../lotties/cupid-logo.svg";
import GiataLogoGreyed from "../../lotties/Giata_logo_greyed.svg";
import PartnerService from "../../services/PartnerService";
import ToggleStatus from "./ToggleStatus";
import PartnerReportService from "../../services/PartnerReportService";
import ConfirmAction from "../../components/ConfirmActionsModal";
import PartnerCatalogService from "../../services/PartnerCatalogService";

const statusBadgeVariant = (status) => {
    switch (status) {
        case 0:
            return {
                color: "warning",
                status: "Loading",
            };
        case 1:
            return {
                color: "info",
                status: "Processing",
            };
        case 2:
            return {
                color: "success",
                status: "Mapped",
            };
        case 3:
            return {
                color: "danger",
                status: "Failed",
            };
        case 4:
            return {
                color: "cyan",
                status: "In queue",
            };
        case 5:
            return {
                color: "cyan",
                status: "Stopped",
            };
        default:
            return {
                color: "",
                status: "",
            };
    }
};

function CatalogPartnersTableDetails(props) {
    const { partner, setOpenDialog, pageType } = props;
    const [openSuccesFirstUpload, setOpenSuccesFirstUpload] = useState(false);
    const [currentElement, setCurrentElement] = useState({});
    const [openMappingDestinationDialog, setOpenMappingDestinationDialog] =
        useState(false);
    const [proccessingList, setProccessingList] = useState([]);
    const [showMoreDetails, setShowMoreDetails] = useState(false);
    const [showShareReport, setShowShareReport] = useState(false);
    const [showRerunMapping, setShowRerunMapping] = useState(false);
    const [showType, setShowType] = useState(SHOW_ACTIVE);
    const [showConfirmRemoveModal, setShowConfirmRemoveModal] =
        React.useState(false);
    const [showConfirmStopMapping, setShowConfirmStopMapping] =
        React.useState(false);
    const [loadingRemove, setLoadingRemove] = React.useState(false);
    const [loadingStopMapping, setLoadingStopMapping] = React.useState(false);
    const [idToRemove, setIdToRemove] = React.useState();

    const history = useHistory();
    const dispatch = useDispatch();
    const config = {
        defaultExpanded: true,
    };

    const { getCollapseProps, getToggleProps, isExpanded } =
        useCollapse(config);

    const token = localStorage.getItem("token");
    const {
        data,
        loading,
        catalogs,
        partnerGroupedCatalogs,
        loadingPartnerGroupedCatalog,
        hascatalog,
    } = useSelector((state) => ({
        data: state.partnerCatalogReducer?.partnerCatalogs ?? [],
        loading: state.partnerCatalogReducer?.loadingPartnerCatalogs,
        catalogs: state.catalogReducer?.catalogs ?? [],
        partnerGroupedCatalogs:
            state?.partnerReducer.partnerGroupedCatalogs?.data?.filter((e) =>
                showType === SHOW_INACTIVE
                    ? e?.parent?.active === false
                    : showType === SHOW_ACTIVE
                    ? e?.parent?.active === true
                    : true
            ) ?? [],
        hascatalog: state?.partnerReducer?.hascatalog?.data,
        loadingPartnerGroupedCatalog:
            state?.partnerReducer.loadingPartnerGroupedCatalog,
    }));

    useEffect(() => {
        if (proccessingList?.length > 0 && data?.length <= 0) {
            setProccessingList([]);
            dispatch(
                partnerGrouppdListByAction(
                    partner?.id,
                    partner?.affiliate_id ?? 0
                )
            );
        }
    }, [data, proccessingList]);

    useEffect(() => {
        dispatch(getCatalogs());
        // dispatch(getPartnerListFirst());
        //dispatch(getPartnerCatalogs(partner?.id));
        dispatch(checkPartnerCatalog(partner?.id));
        dispatch(
            partnerGrouppdListByActionFirst(
                partner?.id,
                partner?.affiliate_id ?? 0
            )
        );
        dispatch(
            getPartnerCatalogsByStatus(partner?.id, [
                STATUS_PENDING,
                STATUS_PROCESSING,
            ])
        );
    }, []);

    useEffect(() => {
        var interval = setInterval(() => {
            if (
                token &&
                window.location?.pathname !== "/sign-in" &&
                window.location?.pathname !== "/sign-up" &&
                window.location.pathname ===
                    `/provider-content/details/${partner.id}`
            ) {
                dispatch(
                    getPartnerCatalogsByStatus(partner?.id, [
                        STATUS_PENDING,
                        STATUS_PROCESSING,
                    ])
                );
            }
        }, 10000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (data?.length > 0) {
            dispatch(
                partnerGrouppdListByAction(
                    partner?.id,
                    partner?.affiliate_id ?? 0
                )
            );

            setProccessingList(
                data
                    ?.filter(
                        (e) =>
                            e?.mapping_process_status_id ===
                                STATUS_PROCESSING ||
                            e?.mapping_process_status_id === STATUS_PENDING
                    )
                    ?.map((e) => {
                        var rate =
                            e?.mapping_process_status_id === STATUS_PROCESSING
                                ? (
                                      ((e?.valid_hotels -
                                          e?.mapped_hotels -
                                          e?.unmapped_hotels) *
                                          1.6) /
                                      1000 /
                                      60
                                  ).toFixed(0, 10)
                                : 0;

                        return {
                            ...e,
                            rate: rate,
                        };
                    })
            );
            var list = proccessingList?.filter(
                (e) =>
                    e?.mapping_process_status_id === STATUS_PROCESSING ||
                    e?.mapping_process_status_id === STATUS_PENDING
            );
            list?.forEach((e) => {
                partnerGroupedCatalogs?.forEach((s) => {
                    if (
                        s?.child?.find((c) => c.id === e.id)
                            ?.mapping_process_status_id ===
                        STATUS_SUCCESS_CATALOG
                    ) {
                        toast.success(`${e.name} is mapped `);
                        dispatch(
                            partnerGrouppdListByAction(
                                partner?.id,
                                partner?.affiliate_id ?? 0
                            )
                        );
                    }
                });
            });
        }
    }, [data]);

    const conditionalRowStyles = [
        {
            when: (row) =>
                row.parent?.mapping_process_status_id === STATUS_PROCESSING ||
                row.parent?.mapping_process_status_id === STATUS_PENDING,
            style: {
                backgroundColor: "rgb(174 161 251 / 14%)",
                color: "black",
                "&:hover": {
                    cursor: "pointer",
                },
            },
        },
    ];
    const renderTooltip = (props = (text) => (
        <Tooltip id="button-tooltip" {...props}>
            {text}
        </Tooltip>
    ));

    const columns = React.useMemo(() => {
        const required = [
            {
                name: <div className="table-title">Catalog</div>,
                sortable: true,
                selector: (e) => e.parent?.name,
                cell: (e) => {
                    return (
                        <>
                            {e?.parent?.has_mapping_error &&
                                e?.parent?.active && (
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{
                                            show: 250,
                                            hide: 400,
                                        }}
                                        overlay={renderTooltip(
                                            " Warning ! A mapping error was removed from this catalog's mapping. Take necessary action"
                                        )}
                                    >
                                        <i
                                            className="fas fa-exclamation-triangle"
                                            style={{
                                                color: "rgb(254, 192, 106)",
                                                marginRight: "5px",
                                            }}
                                        ></i>
                                    </OverlayTrigger>
                                )}

                            {e?.parent?.name}
                        </>
                    );
                },
            },
            {
                name: "",
                sortable: true,
                selector: (e) => e.parent?.active,
                cell: (e) => {
                    return (
                        <>
                            {e?.parent?.active ? (
                                <span
                                    className="badge badge-pill badge-light"
                                    style={{ backgroundColor: "#21ca7f47" }}
                                >
                                    <b
                                        style={{
                                            color: "#22ca80",
                                            marginLeft: "4px",
                                        }}
                                    >
                                        ●
                                        <span
                                            style={{
                                                color: "rgba(0,0,0,0.87)",
                                            }}
                                        >
                                            Active
                                        </span>
                                    </b>
                                </span>
                            ) : (
                                <span className="badge badge-pill badge-light">
                                    <b
                                        style={{
                                            color: "rgb(125, 126, 127)",
                                            marginLeft: "4px",
                                        }}
                                    >
                                        ●
                                        <span
                                            style={{
                                                color: "rgba(0,0,0,0.87)",
                                            }}
                                        >
                                            Inactive
                                        </span>
                                    </b>
                                </span>
                            )}
                        </>
                    );
                },
            },
            {
                name: <div className="table-title">Total hotels</div>,
                sortable: true,
                selector: (e) => e.parent?.total_hotels,
                cell: (e) => {
                    return (
                        <>
                            {e?.parent?.total_hotels
                                ? separator(e?.parent?.total_hotels)
                                : 0}
                            <br />
                            {e?.parent?.hotels_rate !== 0 &&
                                e?.parent?.hotels_rate !== null &&
                                e?.parent?.mapping_process_status_id ===
                                    STATUS_PENDING && (
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip("Rate/Sec")}
                                    >
                                        <small
                                            style={{
                                                color: "#21CA7F",
                                                fontWeight: "900",
                                                marginLeft: "5px",
                                            }}
                                        >
                                            {e?.parent?.hotels_rate}/Sec
                                        </small>
                                    </OverlayTrigger>
                                )}
                        </>
                    );
                },
            },
            {
                name: <div className="table-title">Mapped</div>,
                sortable: true,
                selector: (e) => e.parent?.mapped_hotels,
                cell: (e) => {
                    const mapped_hotels = e?.parent?.mapped_hotels ?? 0;
                    const total_hotels = e?.parent?.total_hotels ?? 0;
                    const pourcentage =
                        total_hotels !== 0
                            ? (mapped_hotels / total_hotels) * 100
                            : 0;
                    return (
                        <>
                            {`${pourcentage.toFixed(1)}% `}
                            <br />
                            {`(${
                                e?.parent?.mapped_hotels
                                    ? separator(e?.parent?.mapped_hotels)
                                    : 0
                            })`}
                        </>
                    );
                },
            },
            {
                name: <div className="table-title">Opport-unities</div>,
                sortable: true,
                selector: (e) => e.parent?.unmapped_hotels,
                cell: (e) => {
                    const unmapped_hotels = e?.parent?.unmapped_hotels ?? 0;
                    const total_hotels = e?.parent?.total_hotels ?? 0;
                    const pourcentage =
                        total_hotels !== 0
                            ? (unmapped_hotels / total_hotels) * 100
                            : 0;
                    return (
                        <div className="row">
                            <div
                                className="col-md-12"
                                style={{
                                    display: "flex",
                                }}
                            >
                                {e?.parent?.unmapped_hotels > 0 &&
                                e?.parent?.active === true &&
                                e?.parent?.affiliate_id === 0 &&
                                hasPermission(LIST_OPPORTUNITIES)  &&
                                e?.parent?.model !== GIATA_MODEL ? (
                                    <button
                                        className="btn click-btn-0p"
                                        onClick={() => {
                                            history.push(
                                                `/manual-mapping/${e?.parent?.id}`,
                                                {
                                                    params: {
                                                        partner: partner,
                                                        pageType: pageType,
                                                    },
                                                }
                                            );
                                        }}
                                    >
                                        <OverlayTrigger
                                            placement="top"
                                            delay={{
                                                show: 250,
                                                hide: 400,
                                            }}
                                            overlay={renderTooltip(
                                                "We found some potential matches that you can manually review and either map them."
                                            )}
                                        >
                                            <span style={{ marginTop: "5px" }}>
                                                {`${pourcentage.toFixed(1)}% `}
                                                <div
                                                    className="col-md-12"
                                                    style={{ padding: "0px" }}
                                                >
                                                    {`(${
                                                        e?.parent
                                                            ?.unmapped_hotels
                                                            ? separator(
                                                                  e?.parent
                                                                      ?.unmapped_hotels
                                                              )
                                                            : 0
                                                    })`}
                                                </div>
                                            </span>
                                        </OverlayTrigger>
                                    </button>
                                ) : (
                                    <>
                                        {`${pourcentage.toFixed(1)}% `}
                                        <br />
                                        {`(${
                                            e?.parent?.unmapped_hotels
                                                ? separator(
                                                      e?.parent?.unmapped_hotels
                                                  )
                                                : 0
                                        })`}
                                    </>
                                )}
                            </div>
                        </div>
                    );
                },
            },
            {
                name: <div className="table-title">Valid</div>,
                sortable: true,
                selector: (e) => e.parent?.valid_hotels,
                cell: (e) => {
                    return (
                        <>
                            {e?.parent?.valid_hotels
                                ? separator(e?.parent?.valid_hotels)
                                : 0}
                        </>
                    );
                },
            },
            {
                name: <div className="table-title">Invalid</div>,
                sortable: true,
                selector: (e) => e.parent?.invalid_hotels,
                cell: (e) => {
                    return (
                        <>
                            {e?.parent?.invalid_hotels
                                ? separator(e?.parent?.invalid_hotels)
                                : 0}
                        </>
                    );
                },
            },
            {
                name: <div className="table-title">Model</div>,
                sortable: true,
                selector: (e) => e?.parent?.model,
                cell: (e) => {
                    return (
                        <span
                            className="logo-text"
                            style={{ fontSize: "18px" }}
                        >
                            {e?.parent?.model}
                        </span>
                    );
                },
            },
            {
                name: <div className="table-title">Status</div>,
                sortable: true,
                selector: (e) => e.parent?.mapping_process_status_id,
                cell: (e) => {
                    return (
                        <span
                            text="white"
                            className={`badge rounded-pill text-white bg-${
                                statusBadgeVariant(
                                    e.parent?.mapping_process_status_id
                                )?.color
                            }`}
                        >
                            {
                                statusBadgeVariant(
                                    e.parent?.mapping_process_status_id
                                )?.status
                            }
                        </span>
                    );
                },
            },
        ];
        let extra = [];
        if (pageType !== AFFILIATE && hasPermission(ACTIVATE_CATALOG)) {
            extra.push({
                name: "",
                sortable: true,
                cell: (e) => {
                    return (
                        <>
                            {e?.parent?.mapping_process_status_id ===
                                STATUS_SUCCESS_CATALOG && (
                                <ToggleStatus dataCell={e} partner={partner} />
                            )}
                        </>
                    );
                },
            });
        }
        let afterExtra = [
            {
                name: <div className="table-title">Created</div>,
                selector: (e) => e.parent?.created_at,
                sortable: true,
                cell: (e) => {
                    return (
                        <>
                            {moment(new Date(e?.parent?.created_at)).format(
                                "DD/MM/YYYY HH:mm:ss"
                            )}
                        </>
                    );
                },
            },
            {
                name: <div className="table-title">History</div>,
                sortable: false,
                cell: (e) => {
                    return (
                        <>
                            <button
                                className="btn click-btn-0p"
                                onClick={() => {
                                    setShowMoreDetails(true);
                                    setCurrentElement(e);
                                }}
                            >
                                View history
                            </button>
                        </>
                    );
                },
            },
            {
                name: <div className="table-title">Mapping report</div>,
                sortable: false,
                cell: (e) => {
                    return <ShareMappingReportButton currentMapping={e} />;
                },
            },
            {
                name: "",
                sortable: false,
                cell: (e) => {
                    var rateProccessing =
                        e?.parent?.mapping_process_status_id ===
                        STATUS_PROCESSING
                            ? (
                                  ((e?.parent?.valid_hotels -
                                      e?.parent?.mapped_hotels -
                                      e?.parent?.unmapped_hotels) *
                                      1.6) /
                                  1000 /
                                  60
                              ).toFixed(0, 10)
                            : 0;
                    return (
                        <>
                            {e?.parent?.mapping_process_status_id ===
                                STATUS_PROCESSING && (
                                <small
                                    style={{
                                        color: "#21CA7F",
                                        fontWeight: "900",
                                        marginLeft: "5px",
                                    }}
                                >
                                    {e?.parent?.model !== GIATA_MODEL ? (
                                        <>
                                            {`Estimated time ~ ${rateProccessing} min`}
                                        </>
                                    ) : (
                                        <>{e?.parent?.giata_step}</>
                                    )}
                                </small>
                            )}
                            {e.parent?.mapping_process_status_id === 2 ||
                            ((e.parent?.mapping_process_status_id === 3 ||
                                e.parent?.mapping_process_status_id === 5) &&
                                partner?.affiliate_id === 0) ? (
                                <>
                                    <DownloadCatalog
                                        dataCell={{
                                            ...e?.parent,
                                            partner_catalog_id: e?.parent?.id,
                                        }}
                                        setOpenMappingDestinationDialog={
                                            setOpenMappingDestinationDialog
                                        }
                                        setCurrentElement={setCurrentElement}
                                        setShowShareReport={setShowShareReport}
                                        id={e?.parent?.id}
                                        setShowRerunMapping={
                                            setShowRerunMapping
                                        }
                                        status={
                                            e.parent?.mapping_process_status_id
                                        }
                                        affiliateId={partner?.affiliate_id}
                                        setIdToRemove={setIdToRemove}
                                        setShowConfirmRemoveModal={
                                            setShowConfirmRemoveModal
                                        }
                                    />
                                </>
                            ) : (
                                e.parent?.mapping_process_status_id !== 2 &&
                                e.parent?.mapping_process_status_id !== 3 &&
                                e.parent?.mapping_process_status_id !== 5 && (
                                    <Spinner
                                        animation="border"
                                        variant="primary"
                                        size="sm"
                                    />
                                )
                            )}
                        </>
                    );
                },
            },
        ];
        return [...required, ...extra, ...afterExtra];
    }, [pageType]);

    const handleDelete = async () => {
        setLoadingRemove(true);
        try {
            let res = await PartnerService.deletePartnerCatalog(
                idToRemove,
                partner?.id
            );
            if (res.status === 200) {
                setLoadingRemove(false);
                setShowConfirmRemoveModal(false);
                dispatch(
                    partnerGrouppdListByActionFirst(
                        partner?.id,
                        partner?.affiliate_id ?? 0
                    )
                );
            } else {
                toast.error("Something went wrong while deleting this catalog");
                setLoadingRemove(false);
                setShowConfirmRemoveModal(false);
            }
        } catch (err) {
            toast.error("Something went wrong while deleting this catalog");
            setLoadingRemove(false);
            setShowConfirmRemoveModal(false);
        }
    };
    const handleStopMapping = async () => {
        setLoadingStopMapping(true);
        try {
            let res = await PartnerCatalogService.StopMapping(
                currentElement?.id,
                currentElement?.model
            );

            if (res.status === 200) {
                setLoadingStopMapping(false);
                setShowConfirmStopMapping(false);
                dispatch(
                    partnerGrouppdListByActionFirst(
                        partner?.id,
                        partner?.affiliate_id ?? 0
                    )
                );
            } else {
                toast.error(res.message);
                setLoadingStopMapping(false);
                setShowConfirmStopMapping(false);
            }
        } catch (err) {
            toast.error("Something went wrong while stopping this mapping");
            setLoadingStopMapping(false);
            setShowConfirmStopMapping(false);
        }
    };

    return (
        <>
            <div className="row">
                <div className="col-12">
                    {loadingPartnerGroupedCatalog ? (
                        <div className="text-center">
                            <div className="lds-ripple">
                                <div className="lds-pos"></div>
                                <div className="lds-pos"></div>
                            </div>
                        </div>
                    ) : catalogs?.find(
                          (e) =>
                              e?.mapping_process_status_id ===
                                  STATUS_PROCESSING && e?.active === true
                      ) && hascatalog?.length === 0 ? (
                        <CatalogNoDataModal
                            title="Almost ready!"
                            text="Your inventory is still processing."
                            hideButton={true}
                        />
                    ) : !catalogs?.find(
                          (e) =>
                              e?.mapping_process_status_id ==
                                  STATUS_SUCCESS_INVENTORY && e?.active == true
                      ) && hascatalog?.length == 0 ? (
                        <CatalogNoDataModal
                            title="Your dedicated workspace is almost ready to go !"
                            text="First, you’ll need to upload your hotel inventory."
                            buttonText="Upload inventory"
                            handleClick={async () => {
                                history.push("/catalogs-manager");
                            }}
                            hideButton={
                                hasPermission(CREATE_INVENTORY) ? false : true
                            }
                        />
                    ) : hascatalog?.length === 0 &&
                      !loadingPartnerGroupedCatalog ? (
                        <CatalogNoDataModal
                            title="Your dedicated workspace is almost ready to go !"
                            text="Before we start mapping your content, you just need to add your partner’s inventory."
                            buttonText=" Add new partner catalog"
                            handleClick={() => {
                                setOpenDialog(true);
                                window.analytics.track(
                                    "Add new partner catalog (click)",
                                    {
                                        userID: localStorage.getItem("userId"),
                                        companyID:
                                            localStorage.getItem("companyId"),
                                        companyName:
                                            localStorage.getItem("companyName"),
                                        firstName:
                                            localStorage.getItem("firstName"),
                                        lastName:
                                            localStorage.getItem("lastName"),
                                        email: localStorage.getItem("email"),
                                    }
                                );
                            }}
                            hideButton={
                                hasPermission(CREATE_CATALOG) ? false : true
                            }
                        />
                    ) : (
                        <>
                            {proccessingList?.length > 0 && (
                                <div
                                    className="collapsible "
                                    style={{
                                        marginBottom: "20px",
                                        border: "2px solid rgb(99 117 231 / 77%)",
                                        borderRadius: "8px",
                                    }}
                                >
                                    <div
                                        className="header"
                                        {...getToggleProps()}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <div>
                                                <i
                                                    className="fas fa-info-circle"
                                                    style={{
                                                        color: "rgb(254 192 106)",
                                                        fontSize: "20px",
                                                    }}
                                                ></i>
                                                &nbsp;{" "}
                                                {`You have ${
                                                    proccessingList?.filter(
                                                        (e) =>
                                                            e?.mapping_process_status_id ===
                                                                STATUS_PROCESSING ||
                                                            e?.mapping_process_status_id ===
                                                                STATUS_PENDING
                                                    )?.length
                                                } mapping currently processing. `}
                                            </div>
                                            <div>
                                                <>
                                                    {isExpanded ? (
                                                        <div
                                                            className="hideOldBlocs"
                                                            style={{
                                                                margin: "0px",
                                                            }}
                                                        >
                                                            <h4>
                                                                <Link
                                                                    style={{
                                                                        fontSize:
                                                                            "17px",
                                                                    }}
                                                                >
                                                                    Hide details
                                                                </Link>
                                                            </h4>
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className="showOldBlocs"
                                                            style={{
                                                                margin: "0px",
                                                            }}
                                                        >
                                                            <h4>
                                                                <Link
                                                                    style={{
                                                                        fontSize:
                                                                            "17px",
                                                                    }}
                                                                >
                                                                    More details
                                                                </Link>
                                                            </h4>
                                                        </div>
                                                    )}
                                                </>
                                            </div>
                                        </div>
                                    </div>
                                    <div {...getCollapseProps()}>
                                        <div className="content">
                                            <ListGroup variant="flush">
                                                {proccessingList.map((e) => {
                                                    return (
                                                        <ListGroup.Item
                                                            style={{
                                                                backgroundColor:
                                                                    "#eeeffd",
                                                            }}
                                                        >
                                                            <div className="row">
                                                                <div className="col-lg-4">
                                                                    {e?.mapping_process_status_id ===
                                                                        STATUS_PROCESSING ||
                                                                    e?.mapping_process_status_id ===
                                                                        STATUS_PENDING ? (
                                                                        <Spinner
                                                                            animation="border"
                                                                            variant="primary"
                                                                            size="sm"
                                                                        />
                                                                    ) : (
                                                                        <i
                                                                            className="fas fa-check"
                                                                            style={{
                                                                                color: "green",
                                                                            }}
                                                                        ></i>
                                                                    )}
                                                                    &nbsp;&nbsp;
                                                                    {e?.name}
                                                                    {e?.mapping_process_status_id ===
                                                                        STATUS_PROCESSING && (
                                                                        <small
                                                                            style={{
                                                                                fontSize:
                                                                                    "13px",
                                                                                color: "#21CA7F",
                                                                                fontWeight:
                                                                                    "900",
                                                                                margin: "0px",
                                                                            }}
                                                                        >
                                                                            &nbsp;{" "}
                                                                            {e?.model ===
                                                                            GIATA_MODEL ? (
                                                                                <>
                                                                                    {
                                                                                        e?.giata_step
                                                                                    }
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    {`Estimated time ~ ${
                                                                                        e?.rate ??
                                                                                        0
                                                                                    } min`}
                                                                                </>
                                                                            )}
                                                                        </small>
                                                                    )}
                                                                </div>
                                                                <div className="col-lg-2 expanded-div">
                                                                    {`${
                                                                        e?.total_hotels
                                                                            ? separator(
                                                                                  e?.total_hotels
                                                                              )
                                                                            : 0
                                                                    } Total `}
                                                                    {e?.hotels_rate !==
                                                                        0 &&
                                                                        e?.hotels_rate !==
                                                                            null &&
                                                                        e?.mapping_process_status_id ===
                                                                            STATUS_PENDING && (
                                                                            <>
                                                                                &nbsp;
                                                                                <OverlayTrigger
                                                                                    placement="top"
                                                                                    delay={{
                                                                                        show: 250,
                                                                                        hide: 400,
                                                                                    }}
                                                                                    overlay={renderTooltip(
                                                                                        "Rate/Sec"
                                                                                    )}
                                                                                >
                                                                                    <small
                                                                                        style={{
                                                                                            fontSize:
                                                                                                "13px",
                                                                                            color: "#21CA7F",
                                                                                            fontWeight:
                                                                                                "900",
                                                                                            margin: "0px",
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            e?.hotels_rate
                                                                                        }
                                                                                        /Sec
                                                                                    </small>
                                                                                </OverlayTrigger>
                                                                            </>
                                                                        )}
                                                                </div>
                                                                <div className="col-lg-2 expanded-div">
                                                                    {`${
                                                                        e?.mapped_hotels
                                                                            ? separator(
                                                                                  e?.mapped_hotels
                                                                              )
                                                                            : 0
                                                                    } Mapped `}
                                                                </div>
                                                                <div className="col-lg-3 expanded-div">
                                                                    {`${
                                                                        e?.unmapped_hotels
                                                                            ? separator(
                                                                                  e?.unmapped_hotels
                                                                              )
                                                                            : 0
                                                                    } Opportunities `}
                                                                </div>
                                                                <div className="col-lg-1 expanded-div">
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        delay={{
                                                                            show: 250,
                                                                            hide: 400,
                                                                        }}
                                                                        overlay={renderTooltip(
                                                                            "Stop Mapping"
                                                                        )}
                                                                    >
                                                                        <button
                                                                            type="button"
                                                                            className="btn"
                                                                            onClick={async () => {
                                                                                setCurrentElement(
                                                                                    e
                                                                                );
                                                                                setShowConfirmStopMapping(
                                                                                    true
                                                                                );
                                                                            }}
                                                                        >
                                                                            <i
                                                                                className="fas fa-stop-circle"
                                                                                style={{
                                                                                    color: "#FF2851",
                                                                                    fontSize:
                                                                                        "20px",
                                                                                }}
                                                                            ></i>
                                                                        </button>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            </div>
                                                        </ListGroup.Item>
                                                    );
                                                })}
                                            </ListGroup>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="card">
                                <div className="card-body">
                                    <div className="table-responsive div-tooltip-catalog-partner">
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                marginTop: "5px",
                                                marginLeft: "2px",
                                            }}
                                        >
                                            <div
                                                role="group"
                                                className="btn-group btn-group-sm"
                                                style={{
                                                    height: "40px",
                                                    //   width: "100%",
                                                }}
                                            >
                                                <button
                                                    type="button"
                                                    className={`btn btn-${
                                                        showType === SHOW_ACTIVE
                                                            ? "success"
                                                            : "light"
                                                    }`}
                                                    style={{
                                                        borderRadius:
                                                            "8px 0px  0px  8px",
                                                    }}
                                                    onClick={() => {
                                                        setShowType(
                                                            SHOW_ACTIVE
                                                        );
                                                    }}
                                                >
                                                    Active
                                                </button>
                                                <button
                                                    type="button"
                                                    className={`btn btn-${
                                                        showType ===
                                                        SHOW_INACTIVE
                                                            ? "light"
                                                            : "light"
                                                    }`}
                                                    style={{
                                                        backgroundColor:
                                                            showType ===
                                                            SHOW_INACTIVE
                                                                ? "rgb(125, 126, 127)"
                                                                : "#e8eaec",
                                                        color:
                                                            showType ===
                                                            SHOW_INACTIVE
                                                                ? "white"
                                                                : "black",
                                                    }}
                                                    onClick={() => {
                                                        setShowType(
                                                            SHOW_INACTIVE
                                                        );
                                                    }}
                                                >
                                                    Inactive
                                                </button>
                                                <button
                                                    type="button"
                                                    className={`btn btn-${
                                                        showType === SHOW_ALL
                                                            ? "primary"
                                                            : "light"
                                                    }`}
                                                    style={{
                                                        borderRadius:
                                                            "0px 8px  8px  0px",
                                                    }}
                                                    onClick={() => {
                                                        setShowType(SHOW_ALL);
                                                    }}
                                                >
                                                    All
                                                </button>
                                            </div>
                                        </div>
                                        <Table
                                            data={partnerGroupedCatalogs}
                                            paginationPerPage={3}
                                            columns={columns}
                                            loading={loading}
                                            conditionalRowStyles={
                                                conditionalRowStyles
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>

            {openSuccesFirstUpload && (
                <SuccessUploadPartner
                    show={openSuccesFirstUpload}
                    handleClose={() => {
                        setOpenSuccesFirstUpload(false);
                    }}
                />
            )}
            {openMappingDestinationDialog && (
                <MappingDestinationModal
                    show={openMappingDestinationDialog}
                    dataCell={currentElement}
                    addOriginPath={true}
                    handleClose={() => {
                        setOpenMappingDestinationDialog(false);
                    }}
                />
            )}
            {showMoreDetails && (
                <MappingDetailsModel
                    show={showMoreDetails}
                    handleClose={() => setShowMoreDetails(false)}
                    data={currentElement?.child}
                />
            )}
            {showShareReport && (
                <ShareMappingReport
                    show={showShareReport}
                    handleClose={() => {
                        setShowShareReport(false);
                    }}
                    partner={partner}
                    id={partner?.id}
                    activeCatalogId={currentElement?.id}
                    pageType={pageType}
                    affiliateId={partner?.affiliate_id}
                />
            )}
            {showRerunMapping && (
                <RerunMappingModal
                    show={showRerunMapping}
                    handleClose={() => {
                        setShowRerunMapping(false);
                    }}
                    currentElement={currentElement}
                    partner={partner}
                />
            )}
            {showConfirmRemoveModal && (
                <ConfirmAction
                    show={showConfirmRemoveModal}
                    onClose={() => setShowConfirmRemoveModal(false)}
                    currentElement={currentElement}
                    titleMessage="Warning !"
                    subTitleMessage="Are you sure you want to delete this mapping?"
                    colorCode="danger"
                    handleClick={handleDelete}
                    buttonText="Confirm"
                    disabled={loadingRemove}
                />
            )}
            {showConfirmStopMapping && (
                <ConfirmAction
                    show={showConfirmStopMapping}
                    onClose={() => setShowConfirmStopMapping(false)}
                    currentElement={currentElement}
                    titleMessage="Warning !"
                    subTitleMessage="Are you sure you want to stop this mapping?"
                    colorCode="danger"
                    handleClick={handleStopMapping}
                    buttonText="Confirm"
                    disabled={loadingStopMapping}
                />
            )}
        </>
    );
}
export default CatalogPartnersTableDetails;

const RerunMappingModal = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { handleClose, show, currentElement, partner } = props;
    const [selectedModel, setSelectedModel] = useState();
    const [giataPartnerKey, setGiataPartnerKey] = useState();
    const [selectedCupidModel, setSelectedCupidModel] = useState();
    const [loading, setLoading] = useState(false);
    const [useManualMappings, setUseManualMappings] = useState(true);
    const {
        partnerKeys,
        hasGiataAccount,
        loadingHasGiataAccount,
        cupidModelsList,
        loadingCupidModelsList,
    } = useSelector((state) => ({
        partnerKeys: state.modelsReducer.giataPartnerList?.data,
        hasGiataAccount: state.modelsReducer.hasGiataAccount?.has_account,
        loadingHasGiataAccount: state.modelsReducer.loadingHasGiataAccount,
        cupidModelsList: state.modelsReducer.cupidModelsList?.models ?? [],
        loadingCupidModelsList: state.modelsReducer.loadingCupidModelsList,
    }));

    const renderTooltip = (name) => (props) =>
        (
            <Tooltip id="button-tooltip" {...props}>
                {name}
            </Tooltip>
        );
    useEffect(() => {
        dispatch(getGiataPartnersList());
        dispatch(getCupidModels());
        dispatch(hasGiataCredentials());
    }, []);

    useEffect(() => {
        if (cupidModelsList?.length > 0) {
            setSelectedCupidModel(cupidModelsList[0]);
        }
    }, [cupidModelsList]);

    return (
        <Modal
            show={show}
            onHide={handleClose}
            dialogClassName={"modal-60w modal-dialog modal-dialog-centered"}
        >
            <Modal.Header style={{ fontSize: "18px", fontWeight: "bold" }}>
                Run Mapping
            </Modal.Header>
            <Modal.Body style={{ textAlign: "center" }}>
                <div className="row">
                    {loadingHasGiataAccount ? (
                        <div className="col-md-12">
                            <div>
                                <SyncLoader
                                    color="#5856d694"
                                    loading={true}
                                    size={10}
                                />
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="col-7">
                                <fieldset
                                    className="scheduler-border"
                                    style={{
                                        height: "100%",
                                        marginBottom: "0px",
                                        display: "flex",
                                        justifyContent: "center",
                                        padding: "0 0.75em 0.75em 0.75em",
                                    }}
                                >
                                    <legend className="scheduler-border">
                                        <h3 className="card-title">
                                            Select model
                                        </h3>
                                    </legend>
                                    <div style={{ paddingRight: "20px" }}>
                                        <div
                                            style={{
                                                padding: "10px",
                                                border:
                                                    selectedModel ===
                                                    CUPID_MODEL
                                                        ? "1px solid rgb(96, 118, 232)"
                                                        : "1px solid rgb(0 0 0 / 23%)",
                                                borderRadius: "1rem",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => {
                                                setSelectedModel(CUPID_MODEL);
                                                setGiataPartnerKey(null);
                                            }}
                                            disabled={true}
                                        >
                                            <div
                                                className="card-body"
                                                style={{
                                                    padding:
                                                        "17px 13px 17px 13px",
                                                }}
                                            >
                                                <img
                                                    id={CUPID_MODEL}
                                                    alt={CUPID_MODEL}
                                                    src={CupidLogo}
                                                    width="150"
                                                    height="70"
                                                    onClick={() => {
                                                        setSelectedModel(
                                                            CUPID_MODEL
                                                        );
                                                        setGiataPartnerKey(
                                                            null
                                                        );
                                                    }}
                                                    style={{
                                                        padding: "10px",
                                                    }}
                                                />
                                            </div>
                                            <div
                                                className="card-footer"
                                                style={{
                                                    borderRadius: "1rem",
                                                    backgroundColor:
                                                        selectedModel ===
                                                        CUPID_MODEL
                                                            ? "rgb(96, 118, 232)"
                                                            : "rgb(0 0 0 / 23%)",
                                                    fontWeight: "bold",
                                                    color: "white",
                                                }}
                                            >
                                                Cupid
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            borderRadius: "1rem",
                                        }}
                                    >
                                        <div
                                            className=""
                                            style={{
                                                padding: "10px",
                                                border:
                                                    selectedModel ===
                                                    GIATA_MODEL
                                                        ? "1px solid rgb(96, 118, 232)"
                                                        : "1px solid rgb(0 0 0 / 23%)",
                                                borderRadius: "1rem",
                                                cursor: "pointer",
                                                pointerEvents: hasGiataAccount
                                                    ? ""
                                                    : "none",
                                            }}
                                            onClick={() => {
                                                setSelectedModel(GIATA_MODEL);
                                                setSelectedCupidModel();
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                }}
                                            >
                                                <OverlayTrigger
                                                    placement="top"
                                                    delay={{
                                                        show: 100,
                                                        hide: 100,
                                                    }}
                                                    overlay={renderTooltip(
                                                        "Beta Feature"
                                                    )}
                                                >
                                                    <span
                                                        className={`badge rounded-pill text-black bg-primary`}
                                                        style={{
                                                            color: "black",
                                                            fontWeight: "600",
                                                        }}
                                                    >
                                                        BETA
                                                    </span>
                                                </OverlayTrigger>
                                            </div>
                                            <div
                                                className="card-body"
                                                style={{
                                                    padding:
                                                        "0px 13px 13px 13px",
                                                }}
                                            >
                                                <img
                                                    id={GIATA_MODEL}
                                                    alt={GIATA_MODEL}
                                                    src={
                                                        hasGiataAccount
                                                            ? GiataLogo
                                                            : GiataLogoGreyed
                                                    }
                                                    width="150"
                                                    height="70"
                                                    style={{
                                                        padding: "10px",
                                                    }}
                                                />
                                            </div>
                                            <div
                                                className="card-footer"
                                                style={{
                                                    borderRadius: "1rem",
                                                    backgroundColor:
                                                        selectedModel ===
                                                        GIATA_MODEL
                                                            ? "rgb(96, 118, 232)"
                                                            : hasGiataAccount
                                                            ? "rgb(0 0 0 / 23%)"
                                                            : "#787878",
                                                    fontWeight: "bold",
                                                    color: "white",
                                                }}
                                            >
                                                {hasGiataAccount
                                                    ? "Giata"
                                                    : "Giata not configured"}
                                            </div>
                                        </div>
                                        {!hasGiataAccount && (
                                            <div
                                                style={{ alignItems: "center" }}
                                            >
                                                <button
                                                    className="btn text-link-button2"
                                                    onClick={() => {
                                                        history.push(
                                                            "/models/giata"
                                                        );
                                                    }}
                                                >
                                                    Configure here
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </fieldset>
                            </div>
                            {selectedModel === GIATA_MODEL && (
                                <div className="col-5">
                                    <fieldset
                                        className="scheduler-border"
                                        style={{
                                            height: "100%",
                                            marginBottom: "0px",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            padding: "0 0.75em 0.75em 0.75em",
                                        }}
                                    >
                                        <legend className="scheduler-border">
                                            <h3 className="card-title">
                                                Select giata key
                                            </h3>
                                        </legend>
                                        <div
                                            className="form-group"
                                            style={{ width: "100%" }}
                                        >
                                            <Select
                                                options={partnerKeys?.map(
                                                    (e) => ({
                                                        value: e?.key,
                                                        label: e?.name,
                                                    })
                                                )}
                                                name="selectedPartner"
                                                value={giataPartnerKey}
                                                onChange={(e) => {
                                                    setGiataPartnerKey(e);
                                                }}
                                                isClearable={true}
                                                placeholder="Partner key"
                                            />
                                        </div>
                                    </fieldset>
                                </div>
                            )}
                            {selectedModel === CUPID_MODEL && (
                                <div className="col-5">
                                    <fieldset
                                        className="scheduler-border"
                                        style={{
                                            height: "100%",
                                            marginBottom: "0px",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            padding: "0 0.75em 0.75em 0.75em",
                                        }}
                                    >
                                        <legend className="scheduler-border">
                                            <h3 className="card-title">
                                                Select model
                                            </h3>
                                        </legend>
                                        <Row
                                            style={{
                                                overflow: "auto",
                                                maxHeight: "200px",
                                                width: "100%",
                                            }}
                                        >
                                            {cupidModelsList?.map(
                                                (e, index) => {
                                                    return (
                                                        <Col
                                                            md={12}
                                                            lg={12}
                                                            style={{
                                                                paddingRight:
                                                                    "0px",
                                                            }}
                                                        >
                                                            <Card
                                                                className="card-without-hover"
                                                                for={e?.id}
                                                                style={{
                                                                    border:
                                                                        e.id ===
                                                                        selectedCupidModel?.id
                                                                            ? "2px solid rgb(96, 118, 232)"
                                                                            : "",
                                                                    marginBottom:
                                                                        "10px",
                                                                    cursor: "pointer",
                                                                }}
                                                                onClick={(
                                                                    event
                                                                ) => {
                                                                    setSelectedCupidModel(
                                                                        e
                                                                    );
                                                                }}
                                                            >
                                                                <Row className="no-gutters">
                                                                    <Col>
                                                                        <Card.Body
                                                                            style={{
                                                                                padding:
                                                                                    "10px",
                                                                            }}
                                                                        >
                                                                            <Card.Title
                                                                                style={{
                                                                                    display:
                                                                                        "flex",

                                                                                    justifyContent:
                                                                                        "space-between",
                                                                                }}
                                                                            >
                                                                                <label
                                                                                    style={{
                                                                                        fontWeight:
                                                                                            "800",
                                                                                        fontSize:
                                                                                            "18px",
                                                                                        marginBottom:
                                                                                            "0px",
                                                                                    }}
                                                                                    for={
                                                                                        e?.id
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        e?.name
                                                                                    }
                                                                                </label>
                                                                            </Card.Title>
                                                                            <Card.Text
                                                                                className="card-text-mapping-details"
                                                                                style={{
                                                                                    textAlign:
                                                                                        "left",
                                                                                    marginTop:
                                                                                        "0px",
                                                                                }}
                                                                            >
                                                                                {e?.description ??
                                                                                    ""}
                                                                            </Card.Text>
                                                                        </Card.Body>
                                                                    </Col>
                                                                </Row>
                                                            </Card>
                                                        </Col>
                                                    );
                                                }
                                            )}
                                        </Row>
                                    </fieldset>
                                </div>
                            )}
                            {selectedModel === CUPID_MODEL &&
                                selectedCupidModel?.run_giata === true && (
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            width: "100%",
                                        }}
                                    >
                                        <div className="col-6">
                                            <fieldset
                                                className="scheduler-border"
                                                style={{
                                                    height: "100%",
                                                    marginBottom: "0px",
                                                    display: "flex",
                                                    justifyContent:
                                                        "space-between",
                                                    padding:
                                                        "0 0.75em 0.75em 0.75em",
                                                }}
                                            >
                                                <legend className="scheduler-border">
                                                    <h3 className="card-title">
                                                        Select giata key
                                                    </h3>
                                                </legend>
                                                <div
                                                    className="form-group"
                                                    style={{ width: "100%" }}
                                                >
                                                    <Select
                                                        options={partnerKeys?.map(
                                                            (e) => ({
                                                                value: e?.key,
                                                                label: e?.name,
                                                            })
                                                        )}
                                                        name="selectedPartner"
                                                        value={giataPartnerKey}
                                                        onChange={(e) => {
                                                            setGiataPartnerKey(
                                                                e
                                                            );
                                                        }}
                                                        isClearable={true}
                                                        placeholder="Partner key"
                                                    />
                                                </div>
                                            </fieldset>
                                        </div>
                                    </div>
                                )}
                        </>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <div
                    className="custom-control custom-checkbox"
                    style={{
                        marginRight: "35px",
                    }}
                >
                    <input
                        id={"usePreviousMapping"}
                        checked={useManualMappings}
                        onClick={() => {
                            setUseManualMappings(!useManualMappings);
                        }}
                        type="checkbox"
                        className="custom-control-input"
                        style={{
                            cursor: "pointer",
                        }}
                        // id="customCheck1"
                    />
                    <label
                        className="custom-control-label"
                        for="usePreviousMapping"
                    >
                        Use previous manual mapping
                    </label>
                </div>
                <div>
                    <button
                        type="button"
                        className="btn btn-light"
                        onClick={handleClose}
                    >
                        Close
                    </button>
                    <button
                        style={{
                            marginLeft: "10px",
                        }}
                        type="button"
                        className="btn btn-primary"
                        onClick={async () => {
                            if (!selectedModel) {
                                toast.error("Please select a model", {
                                    toastId: "model-partner-catalog-step2",
                                });
                            } else if (
                                (selectedModel === GIATA_MODEL ||
                                    selectedCupidModel?.run_giata === true) &&
                                !giataPartnerKey
                            ) {
                                toast.error(
                                    "Please select a giata partner key",
                                    {
                                        toastId:
                                            "giata-key-partner-catalog-connector-step2",
                                    }
                                );
                            } else if (
                                selectedModel === CUPID_MODEL &&
                                !selectedCupidModel
                            ) {
                                toast.error("Please select a cupid model", {
                                    toastId: "cupid-key-model-connector-step2",
                                });
                            } else {
                                setLoading(true);
                                try {
                                    const res =
                                        await PartnerCatalogService.runPartnerInventoryMapping(
                                            {
                                                model:
                                                    selectedModel ===
                                                    CUPID_MODEL
                                                        ? selectedCupidModel?.name
                                                        : GIATA_MODEL,
                                                giata_partner_key:
                                                    giataPartnerKey?.value,
                                                partner_inventory_id:
                                                    currentElement?.partner_inventory_id,
                                                use_manual_mappings:
                                                    useManualMappings,
                                                name: currentElement?.name,
                                                partner_id:
                                                    currentElement.partner_id,
                                            }
                                        );
                                    if (res.status === 200) {
                                        toast.success("Mapping started");
                                        handleClose();
                                        dispatch(
                                            getPartnerCatalogsByStatus(
                                                partner?.id,
                                                [
                                                    STATUS_PENDING,
                                                    STATUS_PROCESSING,
                                                ]
                                            )
                                        );
                                    } else {
                                        setLoading(false);
                                        toast.error(res.message);
                                    }
                                } catch {
                                    setLoading(false);
                                    toast.error("Something went wrong");
                                }
                            }
                        }}
                        disabled={loading}
                    >
                        Run Mapping &nbsp;&nbsp;
                        {loading && (
                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        )}
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

const ShareMappingReportButton = (props) => {
    const { currentMapping } = props;
    const [pending, setPending] = useState(false);
    return (
        <>
            <button
                className="btn click-btn-0p"
                disabled={pending}
                onClick={async () => {
                    setPending(true);
                    try {
                        const res = await PartnerReportService.getReportToken(
                            currentMapping?.parent?.id
                        );
                        if (res.status === 200) {
                            window.open(
                                `${window.location.origin}/partner-reports?token_mapping_report=${res?.data?.token}&token_external_manual_mapping=${res?.data?.manual_mapping_token}`
                            );
                            setPending(false);
                        } else {
                            toast.error(res?.message ?? "An error has occured");
                            setPending(false);
                        }
                    } catch (e) {
                        toast.error("An error has occured");
                        setPending(false);
                    }
                }}
            >
                {pending ? (
                    <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                ) : (
                    "Access Report"
                )}
            </button>
        </>
    );
};
