import React, { useState } from "react";
import CatalogTableDetails from "./CatalogTableDetails";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { hasPermission } from "../../utilis/functions";
import {
    STATUS_PROCESSING,
    CREATE_INVENTORY,
    STATUS_PENDING
} from "../../utilis/const";
import { useSelector } from "react-redux";

function CatalogsManagerScreen(props) {
    const location = useLocation();
    const [openDialog, setOpenDialog] = useState(false);
    const {
        activeCatalog,
        catalog,
        partnerCatalog,
        catalogs
    } = useSelector((state) => ({
        activeCatalog: state.catalogReducer?.catalogs?.filter((e) => e.active === true)?.[0],
        catalog: state.catalogReducer?.catalog ?? {},
        catalogs: state.catalogReducer?.catalogs ?? [],
        partnerCatalog: state.partnerCatalogReducer?.partnerCatalogs?.find(
            (e) =>
                e?.mapping_process_status_id === STATUS_PROCESSING ||
                e?.mapping_process_status_id === STATUS_PENDING
        ),
    }));
    const handleOpenDialog = () => {
        setOpenDialog(true);
        window.analytics.track("Add new inventory (click)", {
            userID: localStorage.getItem("userId"),
            companyID: localStorage.getItem("companyId"),
            companyName: localStorage.getItem("companyName"),
            firstName: localStorage.getItem("firstName"),
            lastName: localStorage.getItem("lastName"),
            email: localStorage.getItem("email"),
        });
    };
    
    return (
        <>
            <Helmet>
                <title> Inventory</title>
            </Helmet>
            <div className="page-wrapper" style={{ display: "block" }}>
                <div className="page-breadcrumb">
                    <div className="row">
                        <div className="col-6 align-self-center">
                            <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
                                Overview
                            </h3>
                        </div>
                        {
                            catalogs && catalogs.length > 0 ?
                                (
                                    activeCatalog &&
                                                catalog?.valid_hotels === 0 &&
                                                catalog.mapping_process_status_id !==
                                                    2) ||
                                            (catalog.mapping_process_status_id ==
                                                STATUS_PROCESSING &&
                                                catalog?.valid_hotels != 0) ||
                                            !hasPermission(CREATE_INVENTORY) ? (
                                                <></>
                                            ) : (
                                                <div className="col-6">
                                                    {false ? (
                                                            <buton className="updateInventory updateInventoryproccessing" >
                                                                <div class="spinner-border" role="status"></div>
                                                            <p className="text-purple fs-6 fw-medium font-family-Lato  m-0 ">your mapping still proccessing</p>
                                                            </buton>
                                                    ) : (
                                                            <buton className="updateInventory" onClick={handleOpenDialog}>
                                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M17 5.1255C19.1213 6.67103 20.5 9.17456 20.5 12.0001C20.5 16.6945 16.6944 20.5001 12 20.5001H11.5M7 18.8747C4.87867 17.3292 3.5 14.8256 3.5 12.0001C3.5 7.30568 7.30558 3.5001 12 3.5001H12.5M13 22.4001L11 20.4001L13 18.4001M11 5.6001L13 3.6001L11 1.6001" stroke="#6275E7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        </svg>
                                                                    <p className="text-purple fs-6 fw-medium font-family-Lato  m-0 ">Update Inventory</p>
                                                            </buton>
                                                    )}
                                                </div>
                                            )
                            :
                            null
                        }

                    </div>
                </div>
                <div className="container-fluid">
                    <CatalogTableDetails open={location.state?.openDialog} openDialog={openDialog} setOpenDialog={setOpenDialog} />
                </div>
            </div>
        </>
    );
}
export default CatalogsManagerScreen;
