import axios from "axios";
const url = process.env.REACT_APP_BASE_URL;

const AutoPilotService = {
    addAffiliatePartner: async (element) => {
        const company_id = localStorage.getItem("companyId");
        const result = await axios
            .post(url + `/company/${company_id}/affiliates/`, element, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                return {
                    data: res.data,
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: error.response.data?.message,
                    status: error.response.status,
                };
            });
        return result;
    },

    updateAffiliatePartner: async (element) => {
        const result = await axios
            .put(url + `/partners/access/${element?.id}`, element, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                return {
                    data: res.data,
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: error.response.data.msg,
                    status: error.response.data.code,
                };
            });
        return result;
    },

    updateAffiliate: async (affiliate_id, data) => {
        const opt = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        const company_id = localStorage.getItem("companyId");
        const result = await axios
            .put(
                url + `/company/${company_id}/affiliates/${affiliate_id}`,
                data,
                opt
            )
            .then((res) => {
                return {
                    data: res.data,
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: error.response.data.msg,
                    status: error.response.data.code,
                };
            });
        return result;
    },

    checkAffiliateExists: async (data) => {
        const opt = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        const company_id = localStorage.getItem("companyId");
        const result = await axios
            .post(url + `/company/${company_id}/affiliates/exists`, data, opt)
            .then((res) => {
                return {
                    data: res.data,
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: error.response.data.msg,
                    status: error.response.data.code,
                };
            });
        return result;
    },
};

export default AutoPilotService;
