import React, { useEffect, useState } from "react";
import { ListGroup, Spinner, Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { getOutdatedCatalogs } from "../../redux/actions/PartnerCataogActions";
import PartnerCatalogService from "../../services/PartnerCatalogService";
import { toast } from "react-toastify";
import { MAP } from "../../utilis/const";
import { hasPermission } from "../../utilis/functions";

const PartnerOutdatedCatalog = (props) => {
    const { hideRunMappingButton } = props;

    const dispatch = useDispatch();
    const { outadatedCatalogs } = useSelector((state) => ({
        outadatedCatalogs: state.partnerCatalogReducer.outdatedCatalogs ?? [],
    }));
    const [showPartnerModal, setShowPartnerModal] = useState(false);
    const [outadatedPartners, setOutadatedPartners] = useState();

    useEffect(() => {
        dispatch(getOutdatedCatalogs());
    }, []);
    useEffect(() => {
        if (outadatedCatalogs?.length > 0) {
            setOutadatedPartners(
                outadatedCatalogs?.map((e) => ({
                    ...e,
                    checked: true,
                }))
            );
        }
    }, [outadatedCatalogs]);

    return (
        <>
            <div className="col-lg-12 col-md-12">
                {outadatedPartners?.length > 0 && (
                    <div
                        className="collapsible "
                        style={{
                            marginBottom: "20px",
                            border: "2px solid rgb(243 228 203 / 93%)",
                            borderRadius: "8px",
                            backgroundColor: "#fff3e1",
                        }}
                    >
                        <div
                            className="header"
                            style={{
                                backgroundColor: "#fff3e1",
                            }}
                        >
                            <div
                                style={{
                                    // display: "flex",
                                    // justifyContent: "space-between",
                                    color: "#846337",
                                }}
                            >
                                <div style={{ marginTop: "9px" }}>
                                    <i
                                        className="fas fa-exclamation-triangle"
                                        style={{
                                            color: "#846337",
                                            fontSize: "20px",
                                        }}
                                    ></i>
                                    &nbsp;
                                    {hideRunMappingButton && hasPermission(MAP)
                                        ? `Your inventory has been updated,
                                    select the mappings you want to re-run :`
                                        : hideRunMappingButton &&
                                          !hasPermission(MAP)
                                        ? `Your inventory has been updated`
                                        : `Your inventory is still processing`}
                                    {hideRunMappingButton &&
                                        hasPermission(MAP) && (
                                            <>
                                                &nbsp;
                                                <button
                                                    className="btn text-link-button2"
                                                    style={{
                                                        marginBottom: "4px",
                                                    }}
                                                    onClick={async (e) => {
                                                        e.stopPropagation();
                                                        setShowPartnerModal(
                                                            true
                                                        );
                                                    }}
                                                >
                                                    Select mappings to re-run
                                                </button>
                                            </>
                                        )}
                                </div>
                            </div>
                        </div>
                        {showPartnerModal && (
                            <ListOutdatedPartner
                                outadatedPartners={outadatedPartners}
                                setOutadatedPartners={setOutadatedPartners}
                                show={showPartnerModal}
                                handleClose={() => setShowPartnerModal(false)}
                            />
                        )}
                    </div>
                )}
            </div>
        </>
    );
};
export default PartnerOutdatedCatalog;

const ListOutdatedPartner = (props) => {
    const { outadatedPartners, setOutadatedPartners, show, handleClose } =
        props;
    const [loading, setLoading] = useState(false);
    const founds = (partnerMapping) => {
        let ids = outadatedPartners
            ?.filter((e) => e.checked === true)
            ?.map((e) => e.partner_id);
        let isFound = true;
        ids.forEach((e) => {
            if (e === partnerMapping?.partner_id) {
                isFound = false;
                return isFound;
            }
        });
        return isFound;
    };
    return (
        <Modal
            show={show}
            onHide={handleClose}
            dialogClassName="modal-40w modal-dialog modal-dialog-centered"
        >
            <Modal.Header>
                Your inventory has been updated, select the mappings you want to
                re-run
            </Modal.Header>
            <Modal.Body style={{ overflow: "auto", maxHeight: "600px" }}>
                <div
                    className="content"
                    style={{
                        backgroundColor: "rgb(255 243 225)",
                    }}
                >
                    <ListGroup variant="flush">
                        <ListGroup.Item
                            key={"slectAll"}
                            style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                backgroundColor: "rgb(238, 239, 253)",
                            }}
                        >
                            <span
                                style={{
                                    fontWeight: "600",
                                    fontSize: "16px",
                                    color: "#333",
                                    flexGrow: 1,
                                }}
                            >
                                {outadatedPartners?.filter(
                                    (e) => e.checked === true
                                )?.length > 0
                                    ? `${
                                          outadatedPartners?.filter(
                                              (e) => e.checked === true
                                          )?.length
                                      } item(s) selected`
                                    : " Select All"}
                            </span>
                            <div
                                className="custom-control custom-checkbox"
                                style={{
                                    width: 250,
                                }}
                            >
                                <input
                                    id="selectAllManualMApping"
                                    checked={outadatedPartners?.every(
                                        (e) => e.use_manual_mappings === true
                                    )}
                                    disabled={outadatedPartners?.find(
                                        (e) => e.checked === false
                                    )}
                                    onClick={() => {
                                        var newList = [];
                                        if (
                                            outadatedPartners?.every(
                                                (e) =>
                                                    e.use_manual_mappings ===
                                                    true
                                            )
                                        ) {
                                            newList = outadatedPartners?.map(
                                                (e) => {
                                                    const updatedElement = {
                                                        ...e,
                                                        use_manual_mappings: false,
                                                    };
                                                    return updatedElement;
                                                }
                                            );
                                        } else {
                                            newList = outadatedPartners?.map(
                                                (e) => {
                                                    const updatedElement = {
                                                        ...e,
                                                        use_manual_mappings: true,
                                                    };
                                                    return updatedElement;
                                                }
                                            );
                                        }
                                        setOutadatedPartners(newList);
                                    }}
                                    type="checkbox"
                                    className="custom-control-input"
                                    style={{
                                        cursor: "pointer",
                                    }}
                                />
                                <label
                                    className="custom-control-label"
                                    for="selectAllManualMApping"
                                >
                                    Use manual mappings
                                </label>
                            </div>
                            <div
                                className="custom-control custom-checkbox"
                                style={{
                                    width: 100,
                                }}
                            >
                                <input
                                    id="selectAll"
                                    checked={outadatedPartners?.every(
                                        (e) => e.checked === true
                                    )}
                                    onClick={() => {
                                        var newList = [];
                                        if (
                                            outadatedPartners?.every(
                                                (e) => e.checked === true
                                            )
                                        ) {
                                            newList = outadatedPartners?.map(
                                                (e) => {
                                                    const updatedElement = {
                                                        ...e,
                                                        checked: false,
                                                        use_manual_mappings: false,
                                                    };
                                                    return updatedElement;
                                                }
                                            );
                                        } else {
                                            newList = outadatedPartners?.map(
                                                (e) => {
                                                    const updatedElement = {
                                                        ...e,
                                                        checked: true,
                                                    };
                                                    return updatedElement;
                                                }
                                            );
                                        }
                                        setOutadatedPartners(newList);
                                    }}
                                    type="checkbox"
                                    className="custom-control-input"
                                    style={{
                                        cursor: "pointer",
                                    }}
                                />
                                <label
                                    className="custom-control-label"
                                    for="selectAll"
                                >
                                    Run
                                </label>
                            </div>
                        </ListGroup.Item>
                        {outadatedPartners?.map((row, index) => {
                            return (
                                <ListGroup.Item
                                    key={index}
                                    style={{
                                        // backgroundColor: "rgb(255 243 225)",
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        // cursor: "pointer",
                                    }}
                                >
                                    <div
                                        style={{
                                            flexGrow: 1,
                                        }}
                                    >
                                        {row?.partner_name}
                                    </div>
                                    <div
                                        className="custom-control custom-checkbox"
                                        style={{
                                            width: 250,
                                        }}
                                    >
                                        <input
                                            id={`manual-mapping${row.partner_id}`}
                                            checked={
                                                row?.use_manual_mappings ===
                                                true
                                            }
                                            disabled={row?.checked === false}
                                            onClick={() => {
                                                var element =
                                                    outadatedPartners?.find(
                                                        (e) =>
                                                            e?.partner_id ===
                                                            row?.partner_id
                                                    );
                                                if (element) {
                                                    var newList =
                                                        outadatedPartners?.map(
                                                            (e) => {
                                                                if (
                                                                    element.partner_id ===
                                                                    e?.partner_id
                                                                ) {
                                                                    const updatedElement =
                                                                        {
                                                                            ...e,
                                                                            use_manual_mappings:
                                                                                !e?.use_manual_mappings,
                                                                        };
                                                                    return updatedElement;
                                                                }

                                                                return e;
                                                            }
                                                        );
                                                    setOutadatedPartners(
                                                        newList
                                                    );
                                                }
                                            }}
                                            type="checkbox"
                                            className="custom-control-input"
                                            style={{
                                                cursor: "pointer",
                                            }}
                                            // id="customCheck1"
                                        />
                                        <label
                                            className="custom-control-label"
                                            for={`manual-mapping${row.partner_id}`}
                                        >
                                            {/* {row?.partner_name} */}
                                        </label>
                                    </div>
                                    <div
                                        className="custom-control custom-checkbox"
                                        style={{
                                            width: 100,
                                        }}
                                    >
                                        <input
                                            id={row.partner_id}
                                            checked={row?.checked === true}
                                            onClick={() => {
                                                var element =
                                                    outadatedPartners?.find(
                                                        (e) =>
                                                            e?.partner_id ===
                                                            row?.partner_id
                                                    );
                                                if (element) {
                                                    var newList =
                                                        outadatedPartners?.map(
                                                            (e) => {
                                                                if (
                                                                    element.partner_id ===
                                                                    e?.partner_id
                                                                ) {
                                                                    const updatedElement =
                                                                        {
                                                                            ...e,
                                                                            checked:
                                                                                !e?.checked,
                                                                        };
                                                                    return updatedElement;
                                                                }

                                                                return e;
                                                            }
                                                        );
                                                    setOutadatedPartners(
                                                        newList
                                                    );
                                                }
                                            }}
                                            type="checkbox"
                                            className="custom-control-input"
                                            style={{
                                                cursor: "pointer",
                                            }}
                                            // id="customCheck1"
                                        />
                                        <label
                                            className="custom-control-label"
                                            for={row.partner_id}
                                        >
                                            {/* {row?.partner_name} */}
                                        </label>
                                    </div>
                                </ListGroup.Item>
                            );
                        })}
                    </ListGroup>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    className="btn btn-warning"
                    type="button"
                    onClick={handleClose}
                >
                    Close
                </button>
                <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={loading}
                    onClick={async (e) => {
                        e.stopPropagation();
                        setLoading(true);
                        try {
                            const res =
                                await PartnerCatalogService.runOutdatedMapping(
                                    outadatedPartners
                                        ?.filter((e) => e.checked === true)
                                        ?.map((e) => ({
                                            id: e.partner_id,
                                            use_manual_mappings:
                                                e?.use_manual_mappings,
                                        }))
                                );
                            setLoading(false);

                            if (res.status === 200) {
                                toast.success("Success");
                                setOutadatedPartners(
                                    outadatedPartners?.filter((e) => founds(e))
                                );
                                handleClose();
                            } else {
                                toast.error(res.message);
                            }
                        } catch {
                            setLoading(false);
                        }
                    }}
                >
                    Run mapping
                    {loading && (
                        <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    )}
                </button>
            </Modal.Footer>
        </Modal>
    );
};
