/* eslint-disable import/no-anonymous-default-export */
import {
    GET_AFFILIATE_PARTNER_SUCCESS,
    GET_AFFILIATE_PARTNER_LISTING,
    GET_AFFILIATE_PARTNER_FAIL
} from '../types';

const initialState = {
    list:[],
    loading:false,   
}

export default function(state = initialState, action){
    switch(action.type){

        case GET_AFFILIATE_PARTNER_LISTING:
            return {
                ...state,
                loading:true
            }   
        case GET_AFFILIATE_PARTNER_SUCCESS:
            return {
                ...state,
                list : action.payload,
                loading:false
            }   
        case GET_AFFILIATE_PARTNER_FAIL:
            return {
                ...state,
                loading:false
            }      
        default: return state
    }

}