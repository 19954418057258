/* eslint-disable import/no-anonymous-default-export */
import {
  GET_COMPANY_DATA,
  GET_COMPANY_DATA_FAIL,
  GET_COMPANY_DATA_SUCCESS,
  UPDATE_COMPANY_DATA,
  UPDATE_COMPANY_DATA_SUCCESS,
  UPDATE_COMPANY_DATA_FAIL,
} from "../types";

const initialState = {
  company: [],
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_COMPANY_DATA:
      return {
        ...state,
        loading: true,
      };
    case GET_COMPANY_DATA_SUCCESS:
      return {
        ...state,
        company: action.payload,
        loading: false,
      };
    case GET_COMPANY_DATA_FAIL:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_COMPANY_DATA:
      return {
        ...state,
        company: action.payload,
        company_backup: state.company,
        updating: true,
      };
    case UPDATE_COMPANY_DATA_SUCCESS:
      return {
        ...state,
        updating: false,
        company_backup: null,
      };
    case UPDATE_COMPANY_DATA_FAIL:
      return {
        ...state,
        updating: false,
        company: state.company_backup,
        company_backup: null,
      };
    default:
      return state;
  }
}
