import React from "react";
import { Modal, Tooltip, OverlayTrigger } from "react-bootstrap";
import { toast } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard";

function ShareMappingReport(props) {
    const { show, handleClose, token, hide } = props;
    const renderTooltip = (name) => (props) =>
        (
            <Tooltip id="button-tooltip" {...props}>
                {name}
            </Tooltip>
        );

    return (
        <Modal
            show={show}
            onHide={handleClose}
            dialogClassName="modal-30w modal-dialog modal-dialog-centered"
        >
            <Modal.Header style={{ fontWeight: "bold" }}>
                Share mapping report
            </Modal.Header>
            <Modal.Body>
                <div style={{ marginBottom: "20px", fontSize: "17px" }}>
                    Share real-time mapping insights with your partner.
                    <br /> This link doesn't expire and any changes you make to
                    this partner's mapping will be impacted.
                </div>
                <div
                    className="input-group"
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                    }}
                >
                    <OverlayTrigger
                        placement="top"
                        delay={{
                            show: 250,
                            hide: 400,
                        }}
                        overlay={renderTooltip("Share Mapping Reports")}
                    >
                        <input
                            readOnly
                            type="text"
                            id="form1"
                            className="form-control"
                            placeholder="Search"
                            value={`${window.location.origin}/partner-reports?token=${token}`}
                            //   onChange={onChange}
                            style={{
                                borderRadius: "5px 0px 0px 5px",
                                height: "41px",
                            }}
                        />
                    </OverlayTrigger>

                    <OverlayTrigger
                        placement="top"
                        delay={{
                            show: 250,
                            hide: 400,
                        }}
                        overlay={renderTooltip("Share Mapping Reports")}
                    >
                        <CopyToClipboard
                            text={`${window.location.origin}/partner-reports?token=${token}`}
                            onCopy={() =>
                                toast.success("Url copied to clipboard")
                            }
                        >
                            <button
                                type="button"
                                className="btn btn-light"
                                style={{
                                    borderRadius: "0px 5px 5px 0px",
                                }}
                            >
                                <i
                                    className="fas fa-copy"
                                    style={{
                                        fontSize: " 23px",
                                    }}
                                ></i>
                            </button>
                        </CopyToClipboard>
                    </OverlayTrigger>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    className="btn btn-rounded btn-warning"
                    onClick={handleClose}
                >
                    close
                </button>
                <button
                    className="btn btn-rounded btn-primary"
                    style={{
                        marginLeft: "5px",
                    }}
                    onClick={() => {
                        window.open(
                            `${window.location.origin}/partner-reports?token=${token}`,
                            "_blank"
                        );
                    }}
                >
                    Preview
                </button>
            </Modal.Footer>
        </Modal>
    );
}

export default ShareMappingReport;
