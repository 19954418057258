import React, { useState } from "react";
import { useSelector } from "react-redux";
import Table from "../../../components/Table/TablePaginationFromApi";
import { useDispatch } from "react-redux";
import { getHotelsCandidates } from "../../../redux/actions/ManualMappingActions";
import HotelsCandidatesListModal from "./HotelsCandidatesListModal";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { hasPermission } from "../../../utilis/functions";
import { LIST_OPPORTUNITIES } from "../../../utilis/const";

const HotelsList = (props) => {
    const { filters, catalog_id ,token} = props;
    const dispatch = useDispatch();
    const [showCandidateList, setShowCanidateList] = useState(false);
    const [currentData, setCurrentData] = useState([]);
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [column, setColumn] = useState();
    const [sortDirection, setSortDirection] = useState();
    const { data, totalCount, loadingHotelsCandidates } = useSelector(
        (state) => ({
            data:
                state.manualMappingReducer?.hotelsCandidates?.data?.hotels ??
                [],
            totalCount: state.manualMappingReducer?.hotelsCandidates?.data
                ?.total
                ? state.manualMappingReducer?.hotelsCandidates?.data?.total
                : 0,
            loadingHotelsCandidates:
                state.manualMappingReducer?.loadingHotelsCandidates,
        })
    );

    const handlePageChange = (newPage) => {
        dispatch(
            getHotelsCandidates(
                catalog_id,
                filters,
                newPage,
                perPage,
                column,
                sortDirection,
                token
            )
        );
        setPage(newPage);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        dispatch(
            getHotelsCandidates(
                catalog_id,
                filters,
                page,
                newPerPage,
                column,
                sortDirection,
                token
            )
        );
        setPerPage(newPerPage);
    };
    const renderTooltip = (name) => (props) =>
        (
            <Tooltip id="button-tooltip" {...props}>
                {name}
            </Tooltip>
        );
    const columns = [
        {
            id: "hotel_code",
            name: <div className="table-title">Partner Hotel Code</div>,
            cell: (row) => <>{row?.hotel_code}</>,
            grow: 1,
            minWidth: "25%",
            sortable: true,
        },
        {
            id: "name",
            name: <div className="table-title">Hotel Name</div>,
            cell: (e) => {
                return <>{e?.name}</>;
            },
            grow: 1,
            minWidth: "30%",
            sortable: true,
        },
        {
            id: "address",
            name: <div className="table-title">Address</div>,
            cell: (row) => <>{row?.address}</>,
            grow: 1,
            minWidth: "30%",
            sortable: true,
        },
        // {
        //     id: "city",
        //     name: <div className="table-title">City</div>,
        //     cell: (row) => <>{row?.city}</>,
        //     grow: 1,
        //     minWidth: "8%",
        //     sortable: true,
        // },
        // {
        //     id: "country",
        //     name: <div className="table-title">Country</div>,
        //     cell: (row) => <>{row?.country}</>,
        //     grow: 1,
        //     minWidth: "7%",
        //     sortable: true,
        // },
        // {
        //     id: "chain",
        //     name: <div className="table-title">Chain</div>,
        //     cell: (row) => <>{row?.chain}</>,
        //     grow: 1,
        //     minWidth: "7%",
        //     sortable: true,
        // },
        // {
        //     id: "type",
        //     name: <div className="table-title">Type</div>,
        //     cell: (row) => <>{row?.type}</>,
        //     grow: 1,
        //     minWidth: "5%",
        //     sortable: true,
        // },

        {
            name: (
                <OverlayTrigger
                    placement="top"
                    delay={{
                        show: 250,
                        hide: 400,
                    }}
                    overlay={renderTooltip(
                        "Match button is displayed for hotels with suggested candidates for manual mapping"
                    )}
                >
                    <div className="table-title">
                        Suggested Mapping Candidates
                    </div>
                </OverlayTrigger>
            ),

            selector: (row) =>
                row?.candidates?.length > 0  ? (
                    <button
                        className="btn btn-primary"
                        style={{ fontSize: "15px" }}
                        onClick={() => {
                            setCurrentData(row);
                            setShowCanidateList(true);
                        }}
                    >
                        Match
                    </button>
                ) : null,
            grow: 1,
            minWidth: "15%",
        },
    ];

    const handleSort = async (c, sortD) => {
        dispatch(
            getHotelsCandidates(
                catalog_id,
                filters,
                page,
                perPage,
                c?.id,
                sortD,
                token
            )
        );
        setColumn(c?.id);
        setSortDirection(sortD);
    };

    return (
        <>
            <div className="card">
                <div className="card-body">
                    <Table
                        data={data ?? []}
                        columns={columns}
                        totalRows={totalCount}
                        handlePageChange={handlePageChange}
                        onChangeRowsPerPage={handlePerRowsChange}
                        progressPending={loadingHotelsCandidates}
                        // resetPaginationToggle={resetPaginationToggle}
                        onSort={handleSort}
                        sortServer={true}
                    />
                </div>
            </div>
            {showCandidateList && (
                <HotelsCandidatesListModal
                    data={currentData}
                    show={showCandidateList}
                    handleClose={() => setShowCanidateList(false)}
                    catalog_id={catalog_id}
                    perPage={perPage}
                    page={page}
                    filters={filters}
                    mappedHotel={currentData?.candidates?.find(
                        (e) => e?.mapped_manually === true
                    )}
                    token={token}
                />
            )}
        </>
    );
};
export default HotelsList;
