import React, { useEffect, useState } from "react";
//import KPIs from '../../components/Home/KPIs';
import AddCatalogDialog from "./AddCatalogDialog/index";
import moment from "moment";
import Table from "../../components/Table/Table";
import DownloadCatalog from "./DownloadCatalog";
import { useDispatch, useSelector } from "react-redux";
import {
    getCatalogs,
    getCatalog,
    getCatalogFirst,
} from "../../redux/actions/CatalogActions";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import WelcomeDialog from "./WelcomeDialog";
import FinishProcessingDialog from "./FinishProccessingDialog";
import { HIDE_DIALOG_ADD_NEW_CATALOG } from "../../redux/types";
import WaitingMessage from "../../components/WaitingMessage";
import Launching from "../../lotties/launching.gif";
import InventoryCount from "./InventoryCount";
import UnmappedCount from "./UnmappedCount";
import { getPartnerCatalogsByStatus } from "../../redux/actions/PartnerCataogActions";
import { separator, hasPermission } from "../../utilis/functions";
import { allAreEqual } from "../../utilis/functions";
import {
    STATUS_PROCESSING,
    STATUS_PENDING,
    CREATE_INVENTORY,
    STATUS_FINISH_PROCESSING,
} from "../../utilis/const";
import SegmentList from "./SegmentList";
import PendingCatalog from "./PendingCatalog";
import ProcessingCatalog from "./ProcessingCatalog";
import FinishProcessingCatalog from "./FinishProcessingCatalog";
import HotelRankingList from "./HotelsRanking";

const statusBadgeVariant = (status) => {
    switch (status) {
        case 1:
            return {
                color: "info",
                status: "Validating your content",
            };
        default:
            return {
                color: "",
                status: "",
            };
    }
};

function CatalogTableDetails(props) {
    const { openDialog, setOpenDialog } = props;
    const [openWelcomeDialog, setOpenWelcomeDialog] = useState(false);
    const [openFinishProccessing, setOpenFinishProccessing] = useState(false);
    const [showOldCatalog, setShowOldCatalog] = useState(false);
    const [firstProccessing, setFirstProccessing] = useState(false);
    const [lastkeys, setLastKeys] = useState(0);
    const [time, setTime] = useState(0);
    const [refresh, setRefresh] = useState(false);
    let [progressPourcentage, setProgressPourcentage] = useState(0);
    const [listOfKeys, setListOfKeys] = useState([]);
    const dispatch = useDispatch();
    const {
        activeCatalog,
        oldCatalogs,
        catalog,
        progress,
        catalogs,
        showWelcomeDialog,
        openDialogCatalog,
        loading,
        partnerCatalog,
        loadingCatalog,
    } = useSelector((state) => ({
        catalogs: state.catalogReducer?.catalogs ?? [],
        loading: state.catalogReducer?.loading,
        activeCatalog: state.catalogReducer?.catalogs?.filter(
            (e) => e.active === true
        )?.[0],
        oldCatalogs:
            state.catalogReducer?.catalogs?.filter((e) => e.active === false) ??
            [],
        catalog: state.catalogReducer?.catalog ?? {},
        loadingCatalog: state.catalogReducer?.loadingCatalog,
        progress: state.catalogReducer?.catalog?.total_hotels,
        showWelcomeDialog: state.catalogReducer?.showWelcomeDialog,
        openDialogCatalog: state.catalogReducer?.openDialogCatalog,
        partnerCatalog: state.partnerCatalogReducer?.partnerCatalogs?.find(
            (e) =>
                e?.mapping_process_status_id === STATUS_PROCESSING ||
                e?.mapping_process_status_id === STATUS_PENDING
        ),
    }));
    const finishProcessing = lastkeys === progress ? true : false;

    const handleOpenDialog = () => {
        setOpenDialog(true);
        window.analytics.track("Add new inventory (click)", {
            userID: localStorage.getItem("userId"),
            companyID: localStorage.getItem("companyId"),
            companyName: localStorage.getItem("companyName"),
            firstName: localStorage.getItem("firstName"),
            lastName: localStorage.getItem("lastName"),
            email: localStorage.getItem("email"),
        });
    };

    useEffect(() => {
        dispatch(
            getPartnerCatalogsByStatus(undefined, [
                STATUS_PENDING,
                STATUS_PROCESSING,
            ])
        );
        dispatch(getCatalogs());
    }, []);

    useEffect(() => {
        if (activeCatalog) {
            dispatch(getCatalogFirst(activeCatalog?.id));
        }
    }, [activeCatalog]);

    useEffect(() => {
        if (openDialogCatalog) {
            handleOpenDialog();
        }
    }, [openDialogCatalog]);

    useEffect(() => {
        const refreshIntervalId = setInterval(() => {
            if (window.location.pathname === "/catalogs-manager") {
                if (activeCatalog?.id && progressPourcentage.toFixed(0) < 100) {
                    dispatch(getCatalog(activeCatalog?.id));
                }
            }
        }, 5000);
        return () => clearInterval(refreshIntervalId);
    }, [activeCatalog, catalog]);

    useEffect(() => {
        const refreshIntervalId = setInterval(() => {
            if (
                window.location.pathname === "/catalogs-manager" &&
                (catalog?.detecting_dups ||
                    catalog?.mapping_process_status_id === STATUS_PROCESSING)
            ) {
                dispatch(getCatalog(activeCatalog?.id));
            }
        }, 15000);
        return () => clearInterval(refreshIntervalId);
    }, [activeCatalog, catalog]);

    useEffect(() => {
        if (listOfKeys?.length >= 3 && allAreEqual(listOfKeys) && catalog?.id) {
            dispatch(getCatalog(catalog?.id));
            setListOfKeys([]);
        }
    }, [listOfKeys]);

    useEffect(() => {
        if (
            (progressPourcentage.toFixed(0) >= 100 || finishProcessing) &&
            catalog?.id
        ) {
            dispatch(getCatalog(catalog?.id));
        }
    }, [progressPourcentage, finishProcessing]);

    useEffect(() => {
        if (catalog.total_hotels !== 0) {
            let progressP;
            let estimatedTime;
            progressP = catalog?.total_hotels
                ? (progress / catalog?.total_hotels) * 100
                : 0;
            estimatedTime = catalog?.total_hotels
                ? (catalog?.total_hotels - progress) / 800
                : 0;

            setProgressPourcentage(progressP);
            setTime((estimatedTime / 60).toFixed(0));
            setLastKeys(progress);
            setListOfKeys([...listOfKeys, progressPourcentage]);
        }
    }, [progress, catalog]);

    useEffect(() => {
        if (
            catalogs?.length === 1 &&
            catalog?.mapping_process_status_id === STATUS_FINISH_PROCESSING &&
            !localStorage.getItem("catalog_finish_Proccessing") &&
            firstProccessing
        ) {
            localStorage.setItem("catalog_finish_Proccessing", true);
            setRefresh(true);
        }
    }, [catalog, firstProccessing]);

    useEffect(() => {
        if (
            localStorage.getItem("catalog_finish_Proccessing") === "true" ||
            localStorage.getItem("catalog_finish_Proccessing") === true
        ) {
            setOpenFinishProccessing(true);
        }
    }, [
        localStorage.getItem("catalog_finish_Proccessing"),
        firstProccessing,
        refresh,
    ]);

    useEffect(() => {
        if (showWelcomeDialog) {
            setOpenWelcomeDialog(true);
        }
    }, [showWelcomeDialog]);

    const columns = [
        {
            name: <div className="table-title">Inventory</div>,
            sortable: true,
            selector: (e) => e?.name,
            cell: (e) => {
                return (
                    <>
                        {e.mapping_process_status_id === STATUS_PROCESSING && (
                            <Spinner
                                animation="border"
                                variant="primary"
                                size="sm"
                                style={{ marginRight: "10px" }}
                            />
                        )}
                        {e?.name ?? ""}
                    </>
                );
            },
        },
        {
            name: <div className="table-title">Total hotels</div>,
            selector: (e) => e?.total_hotels,
            cell: (e) => {
                return <>{e?.total_hotels ? separator(e?.total_hotels) : 0}</>;
            },
            sortable: true,
        },
        {
            name: <div className="table-title">Valid</div>,
            selector: (e) => e?.valid_hotels,
            cell: (e) => {
                return <>{e?.valid_hotels ? separator(e?.valid_hotels) : 0}</>;
            },
            sortable: true,
        },
        {
            name: <div className="table-title">Invalid</div>,
            selector: (e) => e?.invalid_hotels,
            cell: (e) => {
                return (
                    <>{e?.invalid_hotels ? separator(e?.invalid_hotels) : 0}</>
                );
            },
            sortable: true,
        },
        {
            name: <div className="table-title">Status</div>,
            sortable: true,
            selector: (e) => e?.active,
            cell: (e) => {
                return (
                    <>
                        {e?.active ? (
                            <div>
                                <span className="badge badge-pill badge-success">
                                    <b>●</b> Active
                                </span>
                            </div>
                        ) : (
                            <div>
                                <span className="badge badge-pill badge-light">
                                    <b>●</b> Inactive
                                </span>
                            </div>
                        )}
                    </>
                );
            },
        },
        {
            name: <div className="table-title">Creation date</div>,
            sortable: true,
            selector: (e) => e?.created_at,
            cell: (e) => {
                return (
                    <>
                        {moment(new Date(e?.created_at)).format(
                            "DD/MM/YYYY HH:mm:ss"
                        )}
                    </>
                );
            },
        },
        {
            name: <div className="table-title">Download</div>,
            cell: (e) => {
                return (
                    <>
                        {e?.mapping_process_status_id === STATUS_PROCESSING ? (
                            <span className="font-weight-medium">
                                <span
                                    text="white"
                                    className={`badge rounded-pill text-white bg-${
                                        statusBadgeVariant(
                                            e.mapping_process_status_id
                                        )?.color
                                    }`}
                                    style={{ fontWeight: 800 }}
                                >
                                    {
                                        statusBadgeVariant(
                                            e.mapping_process_status_id
                                        )?.status
                                    }
                                </span>
                            </span>
                        ) : (
                            <DownloadCatalog dataCell={e} />
                        )}
                    </>
                );
            },
        },
    ];

    return (
        <>
            <div className="row">
                <div className="col-12">
                    {/*<KPIs />*/}
                    {(loading && !activeCatalog?.detecting_dups) ||
                    loadingCatalog ? (
                        <div className="text-center">
                            <div className="lds-ripple">
                                <div className="lds-pos"></div>
                                <div className="lds-pos"></div>
                            </div>
                        </div>
                    ) : showWelcomeDialog ? (
                        <WelcomeDialog
                            show={openWelcomeDialog}
                            handleClose={() => setOpenWelcomeDialog(false)}
                            uploadCatalog={() => setOpenDialog(true)}
                        />
                    ) : (
                        <>
                            {activeCatalog ||
                            (oldCatalogs && oldCatalogs.length > 0) ? (
                                <div className="card">
                                    <div className="card-body">
                                        {(activeCatalog &&
                                            catalog?.valid_hotels == 0) ||
                                            (catalog.mapping_process_status_id ==
                                                STATUS_PROCESSING &&
                                                catalog?.valid_hotels != 0 && (
                                                    <div
                                                        className="col-12"
                                                        style={{
                                                            paddingLeft: "0px",
                                                        }}
                                                    >
                                                        <WaitingMessage
                                                            number={
                                                                localStorage.getItem(
                                                                    "fileSize"
                                                                ) ??
                                                                catalog?.total_hotels
                                                            }
                                                        />
                                                    </div>
                                                ))}
                                        <div className="row">
                                            {activeCatalog ? (
                                                <div className="col-12">
                                                    <div>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="align-items-center">
                                                                    <div className="row">
                                                                        {catalog.mapping_process_status_id ===
                                                                            STATUS_PENDING && (
                                                                            <PendingCatalog
                                                                                activeCatalog={
                                                                                    activeCatalog
                                                                                }
                                                                                catalog={
                                                                                    catalog
                                                                                }
                                                                            />
                                                                        )}
                                                                        {catalog.mapping_process_status_id ===
                                                                            STATUS_PROCESSING && (
                                                                            <ProcessingCatalog
                                                                                catalog={
                                                                                    catalog
                                                                                }
                                                                                statusBadgeVariant={
                                                                                    statusBadgeVariant
                                                                                }
                                                                                activeCatalog={
                                                                                    activeCatalog
                                                                                }
                                                                                progress={
                                                                                    progress
                                                                                }
                                                                                progressPourcentage={
                                                                                    progressPourcentage
                                                                                }
                                                                                time={
                                                                                    time
                                                                                }
                                                                            />
                                                                        )}

                                                                        {catalog?.mapping_process_status_id ===
                                                                            STATUS_FINISH_PROCESSING && (
                                                                            <FinishProcessingCatalog
                                                                                catalog={
                                                                                    catalog
                                                                                }
                                                                                activeCatalog={
                                                                                    activeCatalog
                                                                                }
                                                                            />
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                "no one active"
                                            )}

                                            {/* {(activeCatalog &&
                                                catalog?.valid_hotels === 0 &&
                                                catalog.mapping_process_status_id !==
                                                    2) ||
                                            (catalog.mapping_process_status_id ==
                                                STATUS_PROCESSING &&
                                                catalog?.valid_hotels != 0) ||
                                            !hasPermission(CREATE_INVENTORY) ? (
                                                <></>
                                            ) : (
                                                <div className="col-5">
                                                    {partnerCatalog ? (
                                                        <div
                                                            className="addNewCatalogBloc"
                                                            style={{
                                                                border: "2px solid #e8eaed",
                                                            }}
                                                        >
                                                            <img
                                                                alt="Still processing"
                                                                src={Launching}
                                                                height="150"
                                                                width="150"
                                                            />
                                                            <p>
                                                                Your partner catalog
                                                                is still proccessing
                                                            </p>
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className="addNewCatalogBloc"
                                                            onClick={
                                                                handleOpenDialog
                                                            }
                                                            style={{
                                                                border: "2px solid #e8eaed",
                                                            }}
                                                        >
                                                            <h4>
                                                                Add or update
                                                                inventory
                                                            </h4>
                                                            <p>
                                                                Upload your
                                                                inventory and start
                                                                mapping
                                                            </p>
                                                        </div>
                                                    )}
                                                </div>
                                            )} */}

                                            {oldCatalogs?.length > 0 &&
                                                oldCatalogs && (
                                                    <>
                                                        {showOldCatalog ? (
                                                            <div className="col-12 hideOldBlocs">
                                                                <p
                                                                    onClick={() => {
                                                                        setShowOldCatalog(
                                                                            false
                                                                        );
                                                                    }}
                                                                    class="text-purple fs-6 fw-medium font-family-Lato text-decoration-underline  m-0 px-3 py-2 ShowHideInventories"
                                                                >
                                                                    Hide old
                                                                    inventories
                                                                </p>
                                                            </div>
                                                        ) : (
                                                            <div className="col-12 showOldBlocs">
                                                                <p
                                                                    onClick={() => {
                                                                        setShowOldCatalog(
                                                                            true
                                                                        );
                                                                    }}
                                                                    class="text-purple fs-6 fw-medium font-family-Lato text-decoration-underline  m-0 px-3 py-2 ShowHideInventories"
                                                                >
                                                                    Show old
                                                                    inventories{" "}
                                                                </p>
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                        </div>
                                        {showOldCatalog && (
                                            <div className="table-responsive div-tooltip-catalog  ">
                                                <Table
                                                    data={oldCatalogs}
                                                    rowsPerPage={5}
                                                    columns={columns}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ) : null}
                            <UnmappedCount />
                            <InventoryCount />
                            <HotelRankingList />
                            <SegmentList
                                activeCatalog={
                                    activeCatalog &&
                                    catalog?.mapping_process_status_id !=
                                        STATUS_PROCESSING
                                }
                            />
                        </>
                    )}
                </div>
            </div>
            {openDialog && (
                <AddCatalogDialog
                    open={openDialog}
                    handleClose={() => {
                        dispatch({ type: HIDE_DIALOG_ADD_NEW_CATALOG });
                        setOpenDialog(false);
                    }}
                    setProgressPourcentage={setProgressPourcentage}
                    setFirstProccessing={setFirstProccessing}
                    activeCatalog={activeCatalog}
                />
            )}
            {openFinishProccessing && (
                <FinishProcessingDialog
                    show={openFinishProccessing}
                    handleClose={() => setOpenFinishProccessing(false)}
                    setFirstProccessing={setFirstProccessing}
                />
            )}
        </>
    );
}
export default CatalogTableDetails;
