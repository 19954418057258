import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Form from "@rjsf/core";
import { Helmet } from "react-helmet";
import DestinationService from "../../services/DestinationService";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

function DestinationForm(props) {
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = React.useState({});
    const [formm, setFormm] = React.useState({});
    const [loadingTestConnection, setLoadingTestConnection] = useState(false);
    const params = useLocation();
    const [testConnection, setTestConnection] = useState(false);
    const { loadingCredentials, credentials, credentialsData } = useSelector(
        (state) => ({
            credentials: state.destinationReducer?.credentials?.data ?? {},
            credentialsData:
                state.destinationReducer?.credentials?.credentials ?? {},
            loadingCredentials: state.destinationReducer?.loadingCredentials,
        })
    );
    const { form, id, updateCredentials, uiForm } = {
        id: params?.state?.params?.id,
        form: params?.state?.params?.form,
        uiForm: params?.state?.params?.uiForm,
        updateCredentials: params?.state?.params?.updateCredentials,
    };

    const history = useHistory();
    const handleSubmit = async (values) => {
        setLoading(true);
        if (updateCredentials) {
            const res = await DestinationService.updateDestination(
                credentials?.id,
                values?.formData
            );
            if (res?.status === 200) {
                toast.success("Updated !");
                setLoading(false);
                history.push("/export-destinations");
            } else {
                toast.error(res?.message);
                setLoading(false);
            }
        } else {
            const res = await DestinationService.addDestination(
                id,
                values?.formData
            );
            if (res?.status === 200) {
                toast.success("Created !");
                setLoading(false);
                history.push("/export-destinations");
            } else {
                toast.error(res?.message);
                setLoading(false);
            }
        }
    };

    const handleTestConnection = async () => {
        setLoadingTestConnection(true);
        try {
            const res = await DestinationService.testConnection(id, formData);
            if (res?.status === 200) {
                toast.success("Test successed");
                setTestConnection(true);
            } else {
                toast.error(res?.message);
            }
            setLoadingTestConnection(false);
        } catch (e) {
            toast.error("Test failed");
            setLoadingTestConnection(false);
        }
    };

    const onChange = React.useCallback(({ formData }) => {
        setFormData(formData);
    }, []);

    useEffect(() => {
        if (updateCredentials) {
            var parsedData = form ? JSON.parse(form) : {};
            var keys = Object.keys(parsedData["properties"]);
            keys?.forEach((e) => {
                const tt = Object.keys(parsedData["properties"])?.find(
                    (d) => d === e
                );
                parsedData["properties"][e] = {
                    ...parsedData["properties"][e],
                    default: credentialsData[tt],
                };
            });
            setFormm(parsedData);
        }
    }, []);

    return (
        <>
            <Helmet>
                <title> Data export</title>
            </Helmet>
            <div className="page-wrapper" style={{ display: "block" }}>
                <div className="page-breadcrumb">
                    <div className="row">
                        <div className="col-7 align-self-center">
                            <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
                                <button
                                    className="btn"
                                    onClick={() => {
                                        history.push("/export-destinations");
                                    }}
                                    style={{ paddingLeft: "0px" }}
                                >
                                    <i className="fas fa-arrow-left"></i>
                                </button>
                                {updateCredentials
                                    ? "Edit configuration"
                                    : "Configure destination"}
                            </h3>
                        </div>
                    </div>
                </div>

                {loadingCredentials ? (
                    <div className="text-center">
                        <div className="lds-ripple">
                            <div className="lds-pos"></div>
                            <div className="lds-pos"></div>
                        </div>
                    </div>
                ) : (
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-6">
                                <div className="card">
                                    <div className="card-body">
                                        <Form
                                            schema={
                                                updateCredentials
                                                    ? formm
                                                    : JSON.parse(form)
                                            }
                                            onChange={onChange}
                                            uiSchema={
                                                uiForm && uiForm !== ""
                                                    ? JSON.parse(uiForm)
                                                    : {}
                                            }
                                            formData={formData}
                                            onSubmit={handleSubmit}
                                            onError={() => console.log("Error")}
                                            autoComplete="off"
                                        >
                                            <div
                                                style={{
                                                    marginTop: 10,
                                                    backgroundColor: "white",
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                }}
                                            >
                                                <button
                                                    type="button"
                                                    disabled={
                                                        loadingTestConnection
                                                    }
                                                    className={
                                                        testConnection
                                                            ? `btn waves-effect waves-light btn-rounded btn-success`
                                                            : `btn waves-effect waves-light btn-rounded btn-warning`
                                                    }
                                                    onClick={
                                                        handleTestConnection
                                                    }
                                                >
                                                    {testConnection && (
                                                        <i
                                                            className="fas fa-check"
                                                            style={{
                                                                marginRight:
                                                                    "6px",
                                                            }}
                                                        ></i>
                                                    )}
                                                    Test Connection
                                                    {loadingTestConnection && (
                                                        <Spinner
                                                            as="span"
                                                            animation="grow"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />
                                                    )}
                                                </button>
                                                <button
                                                    type="submit"
                                                    disabled={loading}
                                                    className="btn waves-effect waves-light btn-rounded btn-primary"
                                                    style={{
                                                        marginLeft: "10px",
                                                    }}
                                                >
                                                    Submit
                                                    {loading && (
                                                        <Spinner
                                                            as="span"
                                                            animation="grow"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />
                                                    )}
                                                </button>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
export default DestinationForm;
