import React, { useEffect } from "react";
import {
    Modal,
    OverlayTrigger,
    Tooltip,
    Card,
    Row,
    Col,
} from "react-bootstrap";
import { CUPID_MODEL, GIATA_MODEL } from "../../../utilis/const";
import GiataLogo from "../../../lotties/Giata-logo.svg";
import CupidLogo from "../../../lotties/cupid-logo.svg";
import GiataLogoGreyed from "../../../lotties/Giata_logo_greyed.svg";
import Select from "../../../components/Select";
import { useSelector, useDispatch } from "react-redux";
import SyncLoader from "react-spinners/SyncLoader";
import { useHistory } from "react-router-dom";
import { getGiataPartnersList } from "../../../redux/actions/ModelsActions";

const SelectModel = (props) => {
    const {
        selectedModel,
        setSelectedModel,
        setGiataPartnerKey,
        giataPartnerKey,
        selectedPartner,
        selectedCupidModel,
        setSelectedCupidModel,
        prev,
        next,
        setCurrentPage,
        handleClose,
    } = props;

    const history = useHistory();
    const dispatch = useDispatch();
    const renderTooltip = (name) => (props) =>
        (
            <Tooltip id="button-tooltip" {...props}>
                {name}
            </Tooltip>
        );
    const {
        partnerById,
        partnerKeys,
        hasGiataAccount,
        loadingHasGiataAccount,
        cupidModelsList,
        loadingCupidModelsList,
    } = useSelector((state) => ({
        partnerById: state.partnerReducer.partner,
        partnerKeys: state.modelsReducer.giataPartnerList?.data,
        hasGiataAccount: state.modelsReducer.hasGiataAccount?.has_account,
        loadingHasGiataAccount: state.modelsReducer.loadingHasGiataAccount,
        cupidModelsList: state.modelsReducer.cupidModelsList?.models ?? [],
        loadingCupidModelsList: state.modelsReducer.loadingCupidModelsList,
    }));

    useEffect(() => {
        dispatch(getGiataPartnersList());
    }, []);

    return (
        <>
            <Modal.Body style={{ textAlign: "center" }}>
                <div className="row">
                    {loadingHasGiataAccount ? (
                        <div className="col-md-12">
                            <div>
                                <SyncLoader
                                    color="#5856d694"
                                    loading={true}
                                    size={10}
                                />
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="col-6">
                                <fieldset
                                    className="scheduler-border"
                                    style={{
                                        height: "100%",
                                        marginBottom: "0px",
                                        display: "flex",
                                        justifyContent: "center",
                                        padding: "0 0.75em 0.75em 0.75em",
                                    }}
                                >
                                    <legend className="scheduler-border">
                                        <h3 className="card-title">
                                            Select model
                                        </h3>
                                    </legend>
                                    <div style={{ paddingRight: "20px" }}>
                                        <div
                                            style={{
                                                padding: "10px",
                                                border:
                                                    selectedModel ===
                                                    CUPID_MODEL
                                                        ? "1px solid rgb(96, 118, 232)"
                                                        : "1px solid rgb(0 0 0 / 23%)",
                                                borderRadius: "1rem",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => {
                                                setSelectedModel(CUPID_MODEL);
                                                setGiataPartnerKey(null);
                                            }}
                                            disabled={true}
                                        >
                                            <div
                                                className="card-body"
                                                style={{
                                                    padding:
                                                        "17px 13px 17px 13px",
                                                }}
                                            >
                                                <img
                                                    id={CUPID_MODEL}
                                                    alt={CUPID_MODEL}
                                                    src={CupidLogo}
                                                    width="150"
                                                    height="70"
                                                    onClick={() => {
                                                        setSelectedModel(
                                                            CUPID_MODEL
                                                        );
                                                        setGiataPartnerKey(
                                                            null
                                                        );
                                                    }}
                                                    style={{
                                                        padding: "10px",
                                                    }}
                                                />
                                            </div>
                                            <div
                                                className="card-footer"
                                                style={{
                                                    borderRadius: "1rem",
                                                    backgroundColor:
                                                        selectedModel ===
                                                        CUPID_MODEL
                                                            ? "rgb(96, 118, 232)"
                                                            : "rgb(0 0 0 / 23%)",
                                                    fontWeight: "bold",
                                                    color: "white",
                                                }}
                                            >
                                                Cupid
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            borderRadius: "1rem",
                                        }}
                                    >
                                        <div
                                            className=""
                                            style={{
                                                padding: "10px",
                                                border:
                                                    selectedModel ===
                                                    GIATA_MODEL
                                                        ? "1px solid rgb(96, 118, 232)"
                                                        : "1px solid rgb(0 0 0 / 23%)",
                                                borderRadius: "1rem",
                                                cursor: "pointer",
                                                pointerEvents: hasGiataAccount
                                                    ? ""
                                                    : "none",
                                            }}
                                            onClick={() => {
                                                setSelectedModel(GIATA_MODEL);
                                                setSelectedCupidModel();
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                }}
                                            >
                                                <OverlayTrigger
                                                    placement="top"
                                                    delay={{
                                                        show: 100,
                                                        hide: 100,
                                                    }}
                                                    overlay={renderTooltip(
                                                        "Beta Feature"
                                                    )}
                                                >
                                                    <span
                                                        className={`badge rounded-pill text-black bg-primary`}
                                                        style={{
                                                            color: "black",
                                                            fontWeight: "600",
                                                        }}
                                                    >
                                                        BETA
                                                    </span>
                                                </OverlayTrigger>
                                            </div>
                                            <div
                                                className="card-body"
                                                style={{
                                                    padding:
                                                        "0px 13px 13px 13px",
                                                }}
                                            >
                                                <img
                                                    id={GIATA_MODEL}
                                                    alt={GIATA_MODEL}
                                                    src={
                                                        hasGiataAccount
                                                            ? GiataLogo
                                                            : GiataLogoGreyed
                                                    }
                                                    width="150"
                                                    height="70"
                                                    style={{
                                                        padding: "10px",
                                                    }}
                                                />
                                            </div>
                                            <div
                                                className="card-footer"
                                                style={{
                                                    borderRadius: "1rem",
                                                    backgroundColor:
                                                        selectedModel ===
                                                        GIATA_MODEL
                                                            ? "rgb(96, 118, 232)"
                                                            : hasGiataAccount
                                                            ? "rgb(0 0 0 / 23%)"
                                                            : "#787878",
                                                    fontWeight: "bold",
                                                    color: "white",
                                                }}
                                            >
                                                {hasGiataAccount
                                                    ? "Giata"
                                                    : "Giata not configured"}
                                            </div>
                                        </div>
                                        {!hasGiataAccount && (
                                            <div
                                                style={{ alignItems: "center" }}
                                            >
                                                <button
                                                    className="btn text-link-button2"
                                                    onClick={() => {
                                                        history.push(
                                                            "/models/giata"
                                                        );
                                                    }}
                                                >
                                                    Configure here
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </fieldset>
                            </div>
                            {selectedModel === GIATA_MODEL && (
                                <div className="col-6">
                                    <fieldset
                                        className="scheduler-border"
                                        style={{
                                            height: "100%",
                                            marginBottom: "0px",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            padding: "0 0.75em 0.75em 0.75em",
                                        }}
                                    >
                                        <legend className="scheduler-border">
                                            <h3 className="card-title">
                                                Select giata key
                                            </h3>
                                        </legend>
                                        <div
                                            className="form-group"
                                            style={{ width: "100%" }}
                                        >
                                            <Select
                                                options={partnerKeys?.map(
                                                    (e) => ({
                                                        value: e?.key,
                                                        label: e?.name,
                                                    })
                                                )}
                                                name="selectedPartner"
                                                value={giataPartnerKey}
                                                onChange={(e) => {
                                                    setGiataPartnerKey(e);
                                                }}
                                                isClearable={true}
                                                placeholder="Partner key"
                                            />
                                        </div>
                                    </fieldset>
                                </div>
                            )}
                            {selectedModel === CUPID_MODEL && (
                                <div className="col-6">
                                    <fieldset
                                        className="scheduler-border"
                                        style={{
                                            height: "100%",
                                            marginBottom: "0px",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            padding: "0 0.75em 0.75em 0.75em",
                                        }}
                                    >
                                        <legend className="scheduler-border">
                                            <h3 className="card-title">
                                                Select model
                                            </h3>
                                        </legend>
                                        <Row
                                            style={{
                                                overflow: "auto",
                                                maxHeight: "200px",
                                                width: "100%",
                                            }}
                                        >
                                            {cupidModelsList?.map(
                                                (e, index) => {
                                                    return (
                                                        <Col md={12} lg={12}>
                                                            <Card
                                                                className="card-without-hover"
                                                                for={e?.id}
                                                                style={{
                                                                    border:
                                                                        e.id ===
                                                                        selectedCupidModel?.id
                                                                            ? "2px solid rgb(96, 118, 232)"
                                                                            : "",
                                                                    marginBottom:
                                                                        "10px",
                                                                    cursor: "pointer",
                                                                }}
                                                                onClick={(
                                                                    event
                                                                ) => {
                                                                    setSelectedCupidModel(
                                                                        e
                                                                    );
                                                                }}
                                                            >
                                                                <Row className="no-gutters">
                                                                    <Col>
                                                                        <Card.Body
                                                                            style={{
                                                                                padding:
                                                                                    "10px",
                                                                            }}
                                                                        >
                                                                            <Card.Title
                                                                                style={{
                                                                                    display:
                                                                                        "flex",

                                                                                    justifyContent:
                                                                                        "space-between",
                                                                                }}
                                                                            >
                                                                                <label
                                                                                    style={{
                                                                                        fontWeight:
                                                                                            "800",
                                                                                        fontSize:
                                                                                            "18px",
                                                                                        marginBottom:
                                                                                            "0px",
                                                                                    }}
                                                                                    for={
                                                                                        e?.id
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        e?.name
                                                                                    }
                                                                                </label>
                                                                            </Card.Title>
                                                                        </Card.Body>
                                                                    </Col>
                                                                </Row>
                                                            </Card>
                                                        </Col>
                                                    );
                                                }
                                            )}
                                        </Row>
                                    </fieldset>
                                </div>
                            )}
                            {selectedModel === CUPID_MODEL &&
                                selectedCupidModel?.run_giata === true && (
                                    <div
                                        style={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <div className="col-6">
                                            <fieldset
                                                className="scheduler-border"
                                                style={{
                                                    height: "100%",
                                                    marginBottom: "0px",
                                                    display: "flex",
                                                    justifyContent:
                                                        "space-between",
                                                    padding:
                                                        "0 0.75em 0.75em 0.75em",
                                                }}
                                            >
                                                <legend className="scheduler-border">
                                                    <h3 className="card-title">
                                                        Select giata key
                                                    </h3>
                                                </legend>
                                                <div
                                                    className="form-group"
                                                    style={{ width: "100%" }}
                                                >
                                                    <Select
                                                        options={partnerKeys?.map(
                                                            (e) => ({
                                                                value: e?.key,
                                                                label: e?.name,
                                                            })
                                                        )}
                                                        name="selectedPartner"
                                                        value={giataPartnerKey}
                                                        onChange={(e) => {
                                                            setGiataPartnerKey(
                                                                e
                                                            );
                                                        }}
                                                        isClearable={true}
                                                        placeholder="Partner key"
                                                    />
                                                </div>
                                            </fieldset>
                                        </div>
                                    </div>
                                )}
                        </>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    className="btn btn-warning"
                    onClick={prev}
                >
                    <i className="fas fa-caret-left"></i> &nbsp;&nbsp; Back
                </button>
                <button
                    type="button"
                    className="btn btn-light"
                    onClick={handleClose}
                >
                    Close
                </button>
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={setCurrentPage}
                >
                    Next &nbsp;&nbsp;
                    <i className="fas fa-caret-right"></i>
                </button>
            </Modal.Footer>
        </>
    );
};

export default SelectModel;
