import React from "react";
import { TRAVEL_GATE_JSON, TRAVEL_GATE_FORM } from "../../utilis/const";

const SelectTravelgateConfig = (props) => {
    const { selectTravelgateConfig, setSelectTravelgateConfig } = props;
    return (
        <div className="row" style={{ marginTop: "20px" }}>
            <div
                className="col-md-12"
                style={{ display: "flex", justifyContent: "flex-end" }}
            >
                {selectTravelgateConfig === TRAVEL_GATE_FORM ? (
                    <button
                        className="btn btn-dark"
                        onClick={() => {
                            setSelectTravelgateConfig(TRAVEL_GATE_JSON);
                        }}
                    >
                        Add travelgate config by json upload
                    </button>
                ) : (
                    <div
                        className="col-md-12"
                        style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                        <button
                            className="btn btn-dark"
                            onClick={() => {
                                setSelectTravelgateConfig(TRAVEL_GATE_FORM);
                            }}
                        >
                            Add travelgate config by form
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};
export default SelectTravelgateConfig;
