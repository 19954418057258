import React, { useState } from "react";
import AutoPilotService from "../../services/AutoPilotService";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { getAffiliatePartnerList } from "../../redux/actions/AutoPilotActions";

function ToggleStatus({ dataCell }) {
    const [status, setStatus] = useState(dataCell?.active);
    const [openDialog, setOpenDialog] = useState(false);
    const dispatch = useDispatch();

    const handleChangeStatus = async () => {
        setOpenDialog(true);
    };
    const handleSubmit = async () => {
        let res = await AutoPilotService.updateAffiliate(dataCell?.id, {
            active: !status,
        });
        if (res.status === 200) {
            toast.success(res?.data?.message);
            setStatus(!status);
            dispatch(getAffiliatePartnerList());
        } else {
            toast.error(res.message);
        }
    };
    return (
        <>
            <div className="custom-control custom-switch">
                <input
                    type="checkbox"
                    className="custom-control-input"
                    id={dataCell?.id}
                    checked={status}
                    onChange={handleChangeStatus}
                    readOnly
                />
                <label
                    className="custom-control-label"
                    htmlFor={dataCell?.id}
                ></label>
            </div>
            {openDialog && (
                <ConfirmationDialog
                    show={openDialog}
                    onClose={() => setOpenDialog(false)}
                    handleSubmit={handleSubmit}
                    status={status}
                    element={dataCell}
                />
            )}
        </>
    );
}
export default ToggleStatus;

function ConfirmationDialog(props) {
    const { show, onClose, handleSubmit, element, status } = props;

    return (
        <Modal
            show={show}
            onHide={onClose}
            dialogClassName="modal-40w modal-dialog modal-dialog-centered"
        >
            <Modal.Body style={{ textAlign: "center" }}>
                <h2
                    style={{
                        color: "#ff4f70",
                        fontWeight: "500",
                        fontSize: "30px",
                    }}
                >
                    {status ? `Warning!` : `Action required!`}
                </h2>
                <p style={{ fontWeight: "400", fontSize: "18px" }}>
                    {status
                        ? `You are about to revoke access to cupid's affiliate plaform for ${
                              element?.partner_name ?? ""
                          }.`
                        : `You are about to grant access to cupid's affiliate plaform for ${
                              element?.partner_name ?? ""
                          }. Any existing affiliate account for this partner will be deactivated.
                          `}
                </p>
                <p style={{ fontWeight: "400", fontSize: "18px" }}>
                    Would you like to proceed ?{" "}
                </p>

                <button
                    className="btn waves-effect waves-light btn-rounded btn-warning"
                    style={{ margin: "6px" }}
                    onClick={() => {
                        onClose();
                    }}
                >
                    Cancel
                </button>
                <button
                    className="btn waves-effect waves-light btn-rounded btn-danger"
                    style={{ margin: "6px" }}
                    onClick={() => {
                        handleSubmit();
                        onClose();
                    }}
                >
                    Confirm
                </button>
            </Modal.Body>
        </Modal>
    );
}
