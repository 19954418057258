import React, { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getAllPartnerKeys } from "../../../redux/actions/ModelsActions";
import Select from "../../../components/Select";
import ModelsService from "../../../services/ModelsService";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";

const schema = yup.object().shape({
    username: yup.string().required("Username is required").min(2),
    password: yup.string().required("Password is required").min(2),
    key: yup.string().required("Partner key is required"),
});

const CredentialsForm = (props) => {
    const { setShowCredentialsForm } = props;
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm({
        resolver: yupResolver(schema),
    });
    const [pending, setPending] = useState(false);
    const [showPassword, setShowPassword] = useState("password");
    const dispatch = useDispatch();
    const { partnerKeys } = useSelector((state) => ({
        partnerKeys: state.modelsReducer.partnersKeys?.data,
    }));

    useEffect(() => {
        dispatch(getAllPartnerKeys());
    }, []);
    const saveCredentials = async (data) => {
        try {
            setPending(true);
            const res = await ModelsService.addGiataCredentials(data);
            if (res.status === 200) {
                toast.success("GIATA credentials saved successfully");
                setShowCredentialsForm(false);
            } else {
                toast.error(res.message);
            }
            setPending(false);
        } catch (e) {
            toast.error("An error occurred while saving credentials");
            setPending(false);
        }
    };
    return (
        <div className="row">
            <div className="col-6">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex align-items-center mb-4">
                            <h4 className="card-title">Credentials</h4>
                        </div>

                        <form
                            className="mt-3"
                            onSubmit={handleSubmit(saveCredentials)}
                        >
                            <div className="form-group">
                                <label for="partner_key">Your giata key </label>
                                <span
                                    style={{
                                        color: "#5f76e8",
                                        fontWeight: "600",
                                    }}
                                >
                                    &nbsp; (This is used to fetch your GIATA
                                    codes from their lookup table)
                                </span>
                                <Select
                                    options={partnerKeys?.map((e) => ({
                                        value: e?.key,
                                        label: e?.name,
                                    }))}
                                    isClearable={true}
                                    name="key"
                                    // value={getValue("partner_key")}
                                    onChange={(e) => {
                                        setValue("key", e.value);
                                    }}
                                />
                                {errors.partner_key && (
                                    <small className="text-danger form-text">
                                        {errors.partner_key.message}
                                    </small>
                                )}
                            </div>
                            <div className="form-group">
                                <label for="username">Username</label>
                                <input
                                    {...register("username")}
                                    type="text"
                                    className="form-control"
                                    aria-describedby="usernamehelper"
                                    placeholder="Enter your username"
                                />
                                {errors.username && (
                                    <small className="text-danger form-text">
                                        {errors.username.message}
                                    </small>
                                )}
                            </div>

                            <div className="form-group mb-5">
                                <label
                                    className="form-label"
                                    htmlFor="password"
                                >
                                    Password
                                </label>
                                <div className="input-group">
                                    <input
                                        className="form-control"
                                        type={showPassword}
                                        aria-describedby="passwordHelp"
                                        placeholder="Enter your password"
                                        id="password"
                                        {...register("password")}
                                    />
                                    <span className="input-group-text">
                                        {showPassword === "password" ? (
                                            <i
                                                className="fa fa-eye"
                                                id="togglePassword2"
                                                style={{
                                                    cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                    setShowPassword("text")
                                                }
                                            ></i>
                                        ) : (
                                            <i
                                                className="fas fa-eye-slash"
                                                id="togglehidePassword2"
                                                style={{
                                                    cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                    setShowPassword("password")
                                                }
                                            ></i>
                                        )}
                                    </span>
                                </div>
                                {errors.password2 && (
                                    <small
                                        id="emailHelp"
                                        className="text-danger form-text"
                                    >
                                        {errors?.password2?.message}
                                    </small>
                                )}
                            </div>

                            <div
                                style={{
                                    marginTop: 10,
                                    backgroundColor: "white",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <button
                                    type="submit"
                                    className="btn waves-effect waves-light btn-rounded btn-primary"
                                    disabled={pending}
                                >
                                    Save credentials
                                    {pending && (
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    )}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default CredentialsForm;
