import React, { useState } from "react";
import GoogleMap from "../../components/GoogleMap";
import { toast } from "react-toastify";
import PartnerService from "../../services/PartnerService";
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";

const CARD_STYLE = "card card-margin";
const CARD_STYLE_REMOVED = "card-margin-remove flip-card-back";
const CARD_STYLE_ACCEPTED = "card-margin-accepted flip-card-back";
const MATCH_ACCEPTED = 1;
const MATCH_CANCELLED = 0;

function HotelViewCard({
    hotel,
    matchHotels,
    setMatchHotels,
    catalog,
    company,
    hotelsList,
    setHotelList,
    countCandidates,
    setCountCandidates,
    countMatched,
    setCountMatched,
    partner,
    catalogName,
}) {
    const { id } = useParams();
    const [style, setStyle] = useState(CARD_STYLE);
    const [loading, setLoading] = useState(false);
    const [match, setMatch] = useState();
    const handleReview = (m) => async (e) => {
        setMatch(m);
        setLoading(true);
        if (m === MATCH_CANCELLED) {
            setStyle(CARD_STYLE_REMOVED);
        }
        if (m === MATCH_ACCEPTED) {
            setStyle(CARD_STYLE_ACCEPTED);
        }
        const formData = new FormData();
        formData.append("partner_hotel_code", hotel?.hotel_code);
        formData.append(
            "company_hotel_code",
            hotel?.condidat?.company_hotel_code
        );
        formData.append("catalog_name", catalogName);
        formData.append("partner_id", partner.id);
        formData.append("partner_name", partner.name);
        formData.append(
            "company_hotel_code",
            hotel?.condidat?.company_hotel_code
        );
        formData.append("matched", m);
        const res = await PartnerService.reviewHotel(id, formData);
        if (res?.status === 200) {
            var array = [...hotelsList];
            var index = array.indexOf(hotel);
            if (index !== -1) {
                array.splice(index, 1);
                setHotelList(array);
            }
            setStyle("card-margin-transition");
            setCountCandidates(countCandidates - 1);
            setCountMatched(countMatched + 1);
            setMatchHotels([
                ...matchHotels,
                {
                    hotel_code: hotel?.hotel_code,
                    company_hotel_code: hotel?.company_hotel_code,
                    match: match,
                },
            ]);
        } else {
            toast.error(res?.message);
        }
        setLoading(false);
    };

    return (
        <div className={style}>
            <div className="row ">
                <div className="col-md-6 border-right">
                    <div className="card-body toreview-card">
                        <h5 className="card-title">
                            <div className="text-center">
                                <img
                                    src="../../assets/images/hotel.png"
                                    alt="wrapkit"
                                    style={{ width: "50px" }}
                                />
                                <h4> {company?.brand_name ?? ""}</h4>
                                <h3 style={{ fontWeight: "500" }}>
                                    {" "}
                                    {hotel?.condidat?.hotel_name}
                                </h3>
                            </div>
                        </h5>
                        <div style={{ marginLeft: "10px", marginTop: "20px" }}>
                            <p
                                className="card-text"
                                style={{ textAlign: "justify" }}
                            >
                                <img
                                    src="../../assets/images/location.png"
                                    alt="wrapkit"
                                    style={{ width: "18px", marginTop: "2px" }}
                                />
                                &nbsp; {hotel?.condidat?.address}
                            </p>
                            <p
                                className="card-text"
                                style={{ textAlign: "justify" }}
                            >
                                <img
                                    src="../../assets/images/city.png"
                                    alt="wrapkit"
                                    style={{ width: "18px", marginTop: "2px" }}
                                />
                                &nbsp; {hotel?.condidat?.city}
                            </p>
                            <p
                                className="card-text"
                                style={{ textAlign: "justify" }}
                            >
                                <img
                                    src="../../assets/images/country.png"
                                    alt="wrapkit"
                                    style={{ width: "18px", marginTop: "2px" }}
                                />
                                &nbsp; {hotel?.condidat?.country}
                            </p>
                        </div>
                        <div className="mt-3 mb-3">
                            <GoogleMap
                                latitude={hotel?.condidat?.latitude}
                                longitude={hotel?.condidat?.longitude}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card-body toreview-card">
                        <h5 className="card-title">
                            <div className="text-center">
                                <img
                                    src="../../assets/images/hotel.png"
                                    alt="wrapkit"
                                    style={{ width: "50px" }}
                                />
                                <h4> {catalog?.partner_name ?? ""}</h4>
                                <h3 style={{ fontWeight: "500" }}>
                                    {hotel?.hotel_name}
                                </h3>
                            </div>
                        </h5>
                        <div style={{ marginLeft: "10px", marginTop: "20px" }}>
                            <p
                                className="card-text"
                                style={{ textAlign: "justify" }}
                            >
                                <img
                                    src="../../assets/images/location.png"
                                    alt="wrapkit"
                                    style={{ width: "18px", marginTop: "2px" }}
                                />
                                &nbsp; {hotel?.address}
                            </p>
                            <p
                                className="card-text"
                                style={{ textAlign: "justify" }}
                            >
                                <img
                                    src="../../assets/images/city.png"
                                    alt="wrapkit"
                                    style={{ width: "18px", marginTop: "2px" }}
                                />
                                &nbsp; {hotel?.city}
                            </p>
                            <p
                                className="card-text"
                                style={{ textAlign: "justify" }}
                            >
                                <img
                                    src="../../assets/images/country.png"
                                    alt="wrapkit"
                                    style={{ width: "18px", marginTop: "2px" }}
                                />
                                &nbsp; {hotel?.country}
                            </p>
                        </div>
                        <div className="mt-3 mb-3">
                            <GoogleMap
                                latitude={hotel?.latitude}
                                longitude={hotel?.longitude}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-1 "></div>
            </div>
            <div className="card-footer text-muted">
                <div className="group_button" style={{ marginTop: "-40px" }}>
                    <button
                        type="button"
                        className="btn waves-effect waves-light btn-circle btn-success"
                        onClick={handleReview(MATCH_ACCEPTED)}
                        disabled={loading}
                    >
                        {loading && match == MATCH_ACCEPTED ? (
                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        ) : (
                            "Yes"
                        )}
                    </button>
                    &nbsp;&nbsp;
                    <button
                        type="button"
                        className="btn waves-effect waves-light btn-circle btn-danger"
                        onClick={handleReview(MATCH_CANCELLED)}
                        disabled={loading}
                    >
                        {loading && match == MATCH_CANCELLED ? (
                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        ) : (
                            " No"
                        )}
                    </button>
                    <button
                        disabled={loading}
                        type="button"
                        className="btn waves-effect waves-light btn-circle btn-primary passBtn"
                        onClick={() => {
                            var array = [...hotelsList];
                            var index = array.indexOf(hotel);
                            if (index !== -1) {
                                array.splice(index, 1);
                                setHotelList(array);
                            }
                            setMatchHotels([
                                ...matchHotels,
                                {
                                    hotel_code: hotel?.hotel_code,
                                    company_hotel_code:
                                        hotel?.company_hotel_code,
                                    match: 0,
                                },
                            ]);
                            setStyle("card-margin-transition");
                        }}
                    >
                        <i className="fa fa-arrow-right"></i>
                    </button>
                </div>
            </div>
        </div>
    );
}
export default HotelViewCard;
