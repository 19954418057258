import React from "react";
import { Modal } from "react-bootstrap";
import DropzonePreview from "../../../../components/DropzonePreview";

const DropZoneDiv = {
    paddingLeft: "20px",
    paddingRight: "20px",
    marginBottom: "30px",
    textAlign: "center",
};

const TitleText = {
    display: "flex",
    textAlign: "center",
    marginBottom: "10px",
};
const Step2 = (props) => {
    const { setFiles, files, setData, next, prev, setFirstLine, handleClose } =
        props;
    return (
        <>
            <Modal.Body>
                {files?.length === 0 && (
                    <h3 className="card-title">Upload catalog</h3>
                )}
                <span
                    className="sub-title-info "
                    style={{
                        ...TitleText,
                    }}
                >
                    Upload a CSV file containing your partner's properties. Make
                    sure your file contains the following columns :
                </span>
                <div
                    style={{
                        ...DropZoneDiv,
                    }}
                >
                    <div className="requiredFileProperties">
                        <div className="statsRow">
                            <div className="col-1 d-flex flex-column">
                                <span className="title">
                                    Hotel ID
                                    <span className="text-danger"> * </span>
                                </span>
                            </div>
                            <div className="col-1 d-flex flex-column bordered">
                                <span className="title">
                                    Hotel Name
                                    <span className="text-danger"> * </span>
                                </span>
                            </div>
                            <div className="col-2 d-flex flex-column bordered">
                                <span className="title">
                                    Hotel Address
                                    <span className="text-danger"> * </span>
                                </span>
                            </div>
                            <div className="col-4 d-flex flex-column bordered">
                                <span className="title">
                                    Country ISO Code
                                    <span className="text-danger"> * </span>
                                </span>
                            </div>
                            <div className="col-2 d-flex flex-column bordered">
                                <span className="title">
                                    Latitude (Recommended)
                                </span>
                            </div>
                            <div className="col-2 d-flex flex-column bordered">
                                <span className="title">
                                    Longitude (Recommended)
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="form-group">
                            <DropzonePreview
                                files={files}
                                setFiles={setFiles}
                                setData={setData}
                                setFirstLine={setFirstLine}
                            />
                        </div>
                        <div style={{ textAlign: "left" }}>
                            <span style={{ color: "#ff4f70" }}>
                                * Required fields
                            </span>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    className="btn btn-warning"
                    onClick={prev}
                >
                    <i className="fas fa-caret-left"></i> &nbsp;&nbsp; Back
                </button>
                <button
                    type="button"
                    className="btn btn-light"
                    onClick={handleClose}
                >
                    Close
                </button>
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={next}
                >
                    Next &nbsp;&nbsp;
                    <i className="fas fa-caret-right"></i>
                </button>
            </Modal.Footer>
        </>
    );
};

export default Step2;
