import { Modal } from "react-bootstrap";
import React from "react";
import { useHistory } from "react-router-dom";
import welcome from "../../lotties/welcome2.gif";

function WelcomeDialog(props) {
    const { onClose } = props;
    const history = useHistory();
    return (
        <Modal.Body style={{ textAlign: "center" }}>
            <img
                src={welcome}
                alt="Welcome"
                style={{ height: "300px", width: "300px" }}
            />
            <h2 className="modalTitle" style={{ fontSize: "45px" }}>
                Welcome to Cup<span style={{ color: "#5f76e8" }}>id</span>!
            </h2>
            <p className="modalP">We’re glad you’re here.</p>
            <p className="modalP">Ready to jump in ?</p>
            <button
                className="modalBtn btn waves-effect waves-light btn-rounded btn-primary"
                onClick={() => {
                    onClose();
                    history.push("/catalogs-manager");
                }}
                style={{ fontSize: "17px", width: "28%" }}
            >
                Get started
            </button>
        </Modal.Body>
    );
}

export default WelcomeDialog;
