/* eslint-disable import/no-anonymous-default-export */
import {
    GET_SEGMENT_LIST_SUCCESS,
    GET_SEGMENT_LIST_FAIL,
    GET_SEGMENT_LIST_LISTING,
    GET_CHAINS_LIST_SUCCESS,
    GET_CHAINS_LIST_FAIL,
    GET_CHAINS_LIST_LISTING,
    GET_STARS_LIST_SUCCESS,
    GET_STARS_LIST_FAIL,
    GET_STARS_LIST_LISTING,
    GET_SEGMENT_TYPES_LIST_SUCCESS,
    GET_SEGMENT_TYPES_LIST_FAIL,
    GET_SEGMENT_TYPES_LIST_LISTING,
    GET_CITIES_LIST_SUCCESS,
    GET_CITIES_LIST_FAIL,
    GET_CITIES_LIST_LISTING,
} from "../types";

const initialState = {
    loadingSegments: false,
    segments: [],
    chains: [],
    loadingChains: false,
    loadingStars: false,
    stars: [],
    segmentTypes: [],
    loadingSegmentTypes: false,
    cities: [],
    loadingCities: false,
    loadingCountries: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_SEGMENT_LIST_LISTING:
            return {
                ...state,
                loadingSegments: true,
            };
        case GET_SEGMENT_LIST_SUCCESS:
            return {
                ...state,
                segments: action.payload,
                loadingSegments: false,
            };
        case GET_SEGMENT_LIST_FAIL:
            return {
                ...state,
                loadingSegments: false,
            };
        case GET_CHAINS_LIST_LISTING:
            return {
                ...state,
                loadingChains: true,
            };
        case GET_CHAINS_LIST_SUCCESS:
            return {
                ...state,
                chains: action.payload,
                loadingChains: false,
            };
        case GET_CHAINS_LIST_FAIL:
            return {
                ...state,
                loadingChains: false,
            };
        case GET_STARS_LIST_LISTING:
            return {
                ...state,
                loadingStars: true,
            };
        case GET_STARS_LIST_SUCCESS:
            return {
                ...state,
                stars: action.payload,
                loadingStars: false,
            };
        case GET_STARS_LIST_FAIL:
            return {
                ...state,
                loadingStars: false,
            };
        case GET_SEGMENT_TYPES_LIST_LISTING:
            return {
                ...state,
                loadingSegmentTypes: true,
            };
        case GET_SEGMENT_TYPES_LIST_SUCCESS:
            return {
                ...state,
                segmentTypes: action.payload,
                loadingSegmentTypes: false,
            };
        case GET_SEGMENT_TYPES_LIST_FAIL:
            return {
                ...state,
                loadingSegmentTypes: false,
            };
        case GET_CITIES_LIST_LISTING:
            return {
                ...state,
                loadingCities: true,
            };
        case GET_CITIES_LIST_SUCCESS:
            return {
                ...state,
                cities: action.payload,
                loadingCities: false,
            };
        case GET_CITIES_LIST_FAIL:
            return {
                ...state,
                loadingCities: false,
            };

        default:
            return state;
    }
}
