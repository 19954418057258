import React, { useState } from "react";
import Form from "@rjsf/core";
import ConnectorService from "../../../../services/ConnectorService";
import { Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
    getCatalogs,
    getCatalog,
} from "../../../../redux/actions/CatalogActions";
import TravelgateConnectorCredentials from "../../../../components/TravelgateConfig/TravelgateConncetorCredentails";

function Step3(props) {
    const {
        selectedConnector,
        handleClose,
        prev,
        name,
        formData,
        setFormData,
        setHideBackButton,
        uiSchema,
        form,
        setProgressPourcentage,
        setFirstProccessing,
        isTravelgateHub,
        files,
        setFiles,
        setSelectTravelgateConfig,
        selectTravelgateConfig,
        updateInventoryDaily,
        setUpdateInventoryDaily,
        showForm,
    } = props;
    const [loading, setLoading] = useState(false);
    const [loadingTestConnection, setLoadingTestConnection] = useState(false);
    const dispatch = useDispatch();
    const handleSubmit = async (values) => {
        setLoading(true);
        setHideBackButton(true);

        const bodyFormData = new FormData();
        bodyFormData.append("name", name);
        bodyFormData.append("action", "update");
        bodyFormData.append("is_scheduled", updateInventoryDaily);

        const res = await ConnectorService.addInventoryConnector(
            selectedConnector?.id,
            values?.formData
        );
        if (res.status === 200) {
            const resInventory = await ConnectorService.runInventoryByConnector(
                res?.data?.credentialID,
                bodyFormData
            );

            if (resInventory?.status === 200) {
                setLoading(false);
                toast.success(resInventory?.data?.msg);
                await dispatch(getCatalog(resInventory?.data?.catalog_id));
                await dispatch(getCatalogs());
                setProgressPourcentage(0);
                setFirstProccessing(true);
            } else {
                toast.success(resInventory?.message);
            }
            handleClose();
        } else {
            toast.error(res?.message);
            setLoading(false);
        }
    };
    const onChange = React.useCallback(({ formData }) => {
        setFormData(formData);
    }, []);

    const handleTestConnection = async () => {
        setLoadingTestConnection(true);
        try {
            const res = await ConnectorService.connectorTestConnection(
                selectedConnector?.id,
                formData
            );
            if (res.status === 200) {
                setLoadingTestConnection(false);
                toast.success(res?.data?.msg);
            } else {
                setLoadingTestConnection(false);
                toast.error(res?.message);
            }
        } catch {
            setLoadingTestConnection(false);
            toast.error("Error establishing connection");
        }
    };
    return (
        <>
            {isTravelgateHub ? (
                <TravelgateConnectorCredentials
                    selectTravelgateConfig={selectTravelgateConfig}
                    setSelectTravelgateConfig={setSelectTravelgateConfig}
                    selectedConnector={selectedConnector}
                    files={files}
                    setFiles={setFiles}
                    prev={prev}
                    handleClose={handleClose}
                    onChange={onChange}
                    formData={formData}
                    handleSubmit={handleSubmit}
                    loading={loading}
                    loadingSaveAttributes={false}
                    hideTestButton={false}
                    setFormData={setFormData}
                    inventoryPage={true}
                    loadingTestConnection={loadingTestConnection}
                    setLoadingTestConnection={setLoadingTestConnection}
                    handleTestConnection={handleTestConnection}
                    updateInventoryDaily={updateInventoryDaily}
                    setUpdateInventoryDaily={setUpdateInventoryDaily}
                />
            ) : (
                <Form
                    schema={form ? form : {}}
                    uiSchema={uiSchema ? JSON.parse(uiSchema) : {}}
                    onChange={onChange}
                    formData={formData}
                    onSubmit={handleSubmit}
                    onError={() => console.log("Error")}
                    autoComplete="off"
                >
                    <Modal.Footer
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            paddingLeft: "0px",
                            paddingRight: "0px",
                        }}
                    >
                        <div
                            className="custom-control custom-checkbox"
                            style={{
                                marginRight: "35px",
                            }}
                        >
                            <>
                                <input
                                    id={"updateInventoryDaily"}
                                    checked={updateInventoryDaily}
                                    onClick={() => {
                                        setUpdateInventoryDaily(
                                            !updateInventoryDaily
                                        );
                                    }}
                                    type="checkbox"
                                    className="custom-control-input"
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    // id="customCheck1"
                                />
                                <label
                                    className="custom-control-label"
                                    for="updateInventoryDaily"
                                >
                                    Update my inventory daily
                                </label>
                            </>
                        </div>
                        <div>
                            <button
                                type="button"
                                className="btn btn-warning"
                                onClick={prev}
                                style={{
                                    marginLeft: "10px",
                                }}
                            >
                                <i className="fas fa-caret-left"></i>
                                &nbsp;&nbsp; Back
                            </button>
                            <button
                                type="button"
                                className="btn btn-light"
                                onClick={handleClose}
                                style={{
                                    marginLeft: "10px",
                                }}
                            >
                                Close
                            </button>
                            <button
                                onClick={handleTestConnection}
                                className="btn btn-success"
                                style={{
                                    marginLeft: "10px",
                                }}
                                disabled={loadingTestConnection}
                            >
                                Test connection
                                {loadingTestConnection && (
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                )}
                            </button>
                            <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={loading}
                                style={{
                                    marginLeft: "10px",
                                }}
                            >
                                Start processing{" "}
                                {loading && (
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                )}
                            </button>
                        </div>
                    </Modal.Footer>
                </Form>
            )}
        </>
    );
}
export default Step3;
