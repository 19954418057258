import axios from "axios";
const url = process.env.REACT_APP_BASE_URL;

const PartnerInventoryService = {
    deletePartnerInventories: async (id) => {
        const opt = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        const result = await axios
            .delete(url + `/partner-catalogs/delete-all-inventories/${id}`, opt)
            .then((res) => {
                return {
                    data: res.data,
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: error.response.data.msg,
                    status: error.response.data.code,
                };
            });
        return result;
    },
    dowloadPartnerInventory: async (
        id
        //  includeOriginal
    ) => {
        const result = await axios
            .get(url + `/partner-catalogs/download-partner-inventory/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                // params: {
                //     "include-original": includeOriginal,
                // },
                responseType: "blob",
            })
            .then((res) => {
                const re = new RegExp('filename=\\"([a-zA-Z0-9.\\-_]+)');
                return {
                    data: res.data,
                    fileName:
                        res.headers?.["content-disposition"]?.match(re)?.[1],
                    status: 200,
                };
            })
            .catch((error) => {
                console.log("Error", error);
                return {
                    message: error.response.data.msg,
                    status: error.response.data.code,
                };
            });
        return result;
    },
};
export default PartnerInventoryService;
