import React from "react";
import { useLocation } from "react-router-dom";
import NavLink from "./NavLink";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { hasPermission } from "../utilis/functions";
import {
    // MANAGE_API_KEYS,
    MANAGE_PAYMENTS,
    MANAGE_DESTINATIONS,
    MANAGE_COMPANY,
    MANAGE_TEAM,
    MANAGE_MODELS,
    MANAGE_CONNECTORS,
} from "../utilis/const";

function Leftside(props) {
    const location = useLocation()?.pathname;
    console.log("location => " , location)
    React.useEffect(() => {
        window.analytics.identify(localStorage.getItem("userId"), {
            name: localStorage.getItem("userConnected"),
            email: localStorage.getItem("email"),
            company: {
                id: localStorage.getItem("companyId"),
                name: localStorage.getItem("companyName"),
            },
            userID: localStorage.getItem("userId"),
            companyID: localStorage.getItem("companyId"),
            companyName: localStorage.getItem("companyName"),
            firstName: localStorage.getItem("firstName"),
            lastName: localStorage.getItem("lastName"),
            createdAt: new Date(),
            traits: {
                name: localStorage.getItem("userConnected"),
                email: localStorage.getItem("email"),
                company: {
                    id: localStorage.getItem("companyId"),
                    name: localStorage.getItem("companyName"),
                },
                userID: localStorage.getItem("userId"),
                companyID: localStorage.getItem("companyId"),
                companyName: localStorage.getItem("companyName"),
                firstName: localStorage.getItem("firstName"),
                lastName: localStorage.getItem("lastName"),
                createdAt: new Date(),
            },
        });
    }, []);
    const renderTooltip = (name) => (props) =>
        (
            <Tooltip id="button-tooltip" {...props}>
                {name}
            </Tooltip>
        );
    return (
        <aside
            className="left-sidebar"
            data-sidebarbg="skin6"
            style={{ overflow: "scroll" }}
        >
            {/* Sidebar scroll*/}
            <div className="scroll-sidebar" data-sidebarbg="skin6">
                {/* Sidebar navigation*/}
                <nav className="sidebar-nav">
                    <ul id="sidebarnav">
                        <NavLink
                            to="/home"
                            pathname={location}
                            aria-expanded="false"
                            className="sidebar-link sidebar-link"
                        >
                            <i className="fas fa-home"></i>
                            <span className="hide-menu">Home</span>
                        </NavLink>
                        <li className="list-divider"></li>
                        <li className="nav-small-cap">
                            <span className="hide-menu"> Your inventory</span>
                        </li>
                        {console.log("window.location.href => " , window.location.href.indexOf("/catalogs-manager/duplicate"))}
                        <NavLink
                            to="/catalogs-manager"
                            pathname={location}
                            aria-expanded="false"
                            className="sidebar-link sidebar-link"
                            isactive={
                                window.location.href.indexOf(
                                    "/catalogs-manager/duplicate"
                                ) > -1 || (location == '/catalogs-manager/invalide')
                                    ? true
                                    : false
                            }
                        >
                            <i className="fas fa-upload"></i>
                            <span className="hide-menu">Overview</span>
                        </NavLink>
                        <li className="list-divider"></li>
                        <li className="nav-small-cap">
                            <span className="hide-menu">Your Partners</span>
                        </li>
                        <NavLink
                            to="/partners-inventories"
                            pathname={location}
                            aria-expanded="false"
                            className="sidebar-link sidebar-link"
                        >
                            <i className="fas fa-handshake"></i>{" "}
                            <span className="hide-menu">Overview</span>
                        </NavLink>
                        <NavLink
                            to="/affiliate"
                            pathname={location}
                            aria-expanded="false"
                            className="sidebar-link sidebar-link"
                            isactive={
                                window.location.href.indexOf(
                                    "/affiliate/details/"
                                ) > -1
                                    ? true
                                    : false
                            }
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                }}
                            >
                                <div>
                                    <i className="fas fa-users"></i>{" "}
                                    <span className="hide-menu">
                                        Affiliates
                                    </span>
                                </div>
                                <div>
                                    <div>
                                        <OverlayTrigger
                                            placement="top"
                                            delay={{
                                                show: 100,
                                                hide: 100,
                                            }}
                                            overlay={renderTooltip(
                                                "Beta Feature"
                                            )}
                                        >
                                            <span
                                                className={`badge rounded-pill text-black bg-${
                                                    window.location.href.indexOf(
                                                        "/affiliate/details/"
                                                    ) > -1
                                                        ? "light"
                                                        : window.location.href.indexOf(
                                                              "/affiliate"
                                                          ) > -1
                                                        ? "light"
                                                        : "primary"
                                                }`}
                                                style={{
                                                    color: "black",
                                                    fontWeight: "600",
                                                    padding: "2px 7px",
                                                }}
                                            >
                                                BETA
                                            </span>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                            </div>
                        </NavLink>

                        {hasPermission(MANAGE_CONNECTORS) && (
                            <NavLink
                                to="/connectors"
                                pathname={location}
                                aria-expanded="false"
                                className="sidebar-link sidebar-link"
                            >
                                <i className="fas fa-plug"></i>{" "}
                                <span className="hide-menu">Connectors</span>
                            </NavLink>
                        )}
                        <li className="list-divider"></li>
                        <li className="nav-small-cap">
                            <span className="hide-menu">Mapping</span>
                        </li>
                        <NavLink
                            to="/provider-content"
                            pathname={location}
                            aria-expanded="false"
                            className="sidebar-link sidebar-link"
                            isactive={
                                window.location.href.indexOf(
                                    "/provider-content/details/"
                                ) > -1 ||
                                window.location.href.indexOf("/to-review/") > -1
                                    ? true
                                    : false
                            }
                        >
                            <i className="far fa-file"></i>
                            <span className="hide-menu">Mappings</span>
                        </NavLink>
                        <NavLink
                            to="/explore-mapping"
                            pathname={location}
                            aria-expanded="false"
                            className="sidebar-link sidebar-link"
                            isactive={
                                window.location.href.indexOf(
                                    "/explore-mapping/details/"
                                ) > -1
                                    ? true
                                    : false
                            }
                        >
                            <i className="fas fa-search"></i>
                            <span className="hide-menu">Explore mapping</span>
                        </NavLink>
                        {hasPermission(MANAGE_DESTINATIONS) && (
                            <NavLink
                                to="/export-destinations"
                                pathname={location}
                                aria-expanded="false"
                                className="sidebar-link sidebar-link"
                                isactive={
                                    window.location.href.indexOf(
                                        "/export-destinations/"
                                    ) > -1
                                        ? true
                                        : false
                                }
                            >
                                <i className="fas fa-cog"></i>
                                <span className="hide-menu">Data Export</span>
                            </NavLink>
                        )}
                        {hasPermission(MANAGE_MODELS) && (
                            <>
                                <li className="list-divider"></li>
                                <li className="nav-small-cap">
                                    <span className="hide-menu">
                                        External models
                                    </span>
                                </li>
                                <NavLink
                                    to="/models/giata"
                                    pathname={location}
                                    aria-expanded="false"
                                    className="sidebar-link sidebar-link"
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            width: "100%",
                                        }}
                                    >
                                        <div>
                                            <i
                                                className="fab fa-gofore"
                                                style={{ marginTop: "4px" }}
                                            ></i>
                                            <span className="hide-menu">
                                                Giata
                                            </span>
                                        </div>
                                    </div>
                                </NavLink>
                            </>
                        )}

                        {(hasPermission(MANAGE_COMPANY) ||
                            hasPermission(MANAGE_TEAM)) && (
                            <>
                                <li className="list-divider"></li>
                                <li className="nav-small-cap">
                                    <span className="hide-menu">Settings</span>
                                </li>
                            </>
                        )}
                        {hasPermission(MANAGE_COMPANY) && (
                            <NavLink
                                to="/basic-info"
                                pathname={location}
                                aria-expanded="false"
                                className="sidebar-link sidebar-link"
                            >
                                <i className="fas fa-info-circle"></i>
                                <span className="hide-menu">Your company</span>
                            </NavLink>
                        )}
                        {hasPermission(MANAGE_TEAM) && (
                            <NavLink
                                to="/manage-team"
                                pathname={location}
                                aria-expanded="false"
                                className="sidebar-link sidebar-link"
                            >
                                <i className="fas fa-users"></i>
                                <span className="hide-menu">Manage team</span>
                            </NavLink>
                        )}
                        {/*  {hasPermission(MANAGE_PAYMENTS) && (
                            <NavLink
                                to="/payment"
                                pathname={location}
                                aria-expanded="false"
                                className="sidebar-link sidebar-link"
                            >
                                <i className="fas fa-credit-card"></i>
                                <span className="hide-menu">Payment</span>
                            </NavLink>
                      )} */}
                        {/* <NavLink
                            onClick={() =>
                                window.open(
                                    "https://nuitee2.stoplight.io/docs/cupid/ZG9jOjQ1NDQ3OTU0-overview",
                                    "_blank"
                                )
                            }
                            externalLink={true}
                            aria-expanded="false"
                            className="sidebar-link sidebar-link"
                        >
                            <i className="far fa-window-maximize"></i>
                            <span className="hide-menu">
                                Docs&nbsp;&nbsp;
                                <i className="fas fa-external-link-square-alt"></i>{" "}
                            </span>
                        </NavLink> */}

                        <li className="list-divider"></li>
                        <li className="nav-small-cap">
                            <span className="hide-menu">Changelog</span>
                        </li>
                        <NavLink
                            to="/changelog"
                            pathname={location}
                            aria-expanded="false"
                            className="sidebar-link sidebar-link"
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                }}
                            >
                                <div>
                                    <i className="fa fa-file"></i>
                                    <span className="hide-menu">Versions</span>
                                </div>
                                <div>
                                    <div>
                                        <OverlayTrigger
                                            placement="top"
                                            delay={{
                                                show: 100,
                                                hide: 100,
                                            }}
                                            overlay={renderTooltip(
                                                "New Release"
                                            )}
                                        >
                                            <span
                                                className={`badge rounded-pill text-black bg-${
                                                    window.location.href.indexOf(
                                                        "/changelog"
                                                    ) > -1
                                                        ? "light"
                                                        : "primary"
                                                }`}
                                                style={{
                                                    color: "black",
                                                    fontWeight: "600",
                                                }}
                                            >
                                                New
                                            </span>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                            </div>
                        </NavLink>
                        {/* <NavLink
                            to="/changelog"
                            pathname={location}
                            aria-expanded="false"
                            className="sidebar-link sidebar-link"
                        >
                            <i className="fa fa-file"></i>
                            <span className="hide-menu">Versions</span>
                        </NavLink> */}

                        {/*
                        <li className="list-divider"></li>
                        <li className="nav-small-cap">
                            <span className="hide-menu">Build</span>
                        </li>
                        {hasPermission(MANAGE_API_KEYS) && (
                            <NavLink
                                to="/api-keys"
                                pathname={location}
                                aria-expanded="false"
                                className="sidebar-link sidebar-link"
                            >
                                <i className="fas fa-key"></i>
                                <span className="hide-menu">API Keys</span>
                            </NavLink>
                        )}

                        <li className="sidebar-item">
                            <button
                                onClick={() => {
                                    window.open(
                                        process.env.REACT_APP_DOCS_URL,
                                        "_blank"
                                    );
                                    window.analytics.track("Docs", {
                                        companyId:
                                            localStorage.getItem("companyId"),
                                        userId: localStorage.getItem("userId"),
                                    });
                                }}
                                aria-expanded="false"
                                className="btn sidebar-link sidebar-link"
                            >
                                <i className="far fa-window-maximize"></i>
                                <span className="hide-menu">
                                    Docs&nbsp;&nbsp;
                                    <i className="fas fa-external-link-square-alt"></i>{" "}
                                </span>
                            </button>
                        </li>
                    
                    */}
                    </ul>
                </nav>
                {/* End Sidebar navigation */}
            </div>
            {/* End Sidebar scroll*/}
        </aside>
    );
}

const Dashboard = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M17.5 17H6.5M17.5 13H6.5M3 9H21M7.8 3H16.2C17.8802 3 18.7202 3 19.362 3.32698C19.9265 3.6146 20.3854 4.07354 20.673 4.63803C21 5.27976 21 6.11984 21 7.8V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V7.8C3 6.11984 3 5.27976 3.32698 4.63803C3.6146 4.07354 4.07354 3.6146 4.63803 3.32698C5.27976 3 6.11984 3 7.8 3Z"
                stroke="#505573"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};
export default Leftside;
