import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTravelgateCredentials } from "../../../../redux/actions/ConnectorActions";
import ConnectorService from "../../../../services/ConnectorService";
import { Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import SyncLoader from "react-spinners/SyncLoader";

function TravelgateCredentails(props) {
    const {
        next,
        prev,
        travelgateCredValues,
        setTravelgateCredValues,
        handleClose,
    } = props;
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState("password");
    const { loadingTravelgateCredentials, travelgateCredentials } = useSelector(
        (state) => ({
            loadingTravelgateCredentials:
                state.connectorReducer.loadingTravelgateCredentials,
            travelgateCredentials: state.connectorReducer.travelgateCredentials,
        })
    );

    useEffect(() => {
        if (Object.values(travelgateCredentials)) {
            setTravelgateCredValues({
                username: travelgateCredentials.username,
                password: travelgateCredentials.password,
            });
        }
    }, [loadingTravelgateCredentials]);

    const handleAddCridentals = async () => {
        setLoading(true);
        const res = await ConnectorService.addTravelgateCredentials(
            travelgateCredValues
        );
        if (res?.status === 200) {
            next();
        } else {
            toast.error(res?.message);
        }
        setLoading(false);
    };

    useEffect(() => {
        dispatch(getTravelgateCredentials());
    }, []);

    const inputValue = (name) => (e) => {
        if (e.target.value != "") {
            travelgateCredValues[name] = e.target.value;
        } else {
            travelgateCredValues[name] = null;
        }
        setTravelgateCredValues({ ...travelgateCredValues });
    };

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <h3
                        style={{
                            width: "100%",
                            maxWidth: "100%",
                            padding: "0",
                            marginBottom: " 1rem",
                            whiteSpace: "normal",
                        }}
                    >
                        Set your travelgate hub credentials
                    </h3>
                </div>
                {loadingTravelgateCredentials ? (
                    <div
                        className="col-md-12"
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        <div>
                            <SyncLoader
                                color="#5856d694"
                                loading={true}
                                size={10}
                            />
                        </div>
                    </div>
                ) : (
                    <div className="col-md-12">
                        <>
                            <div className="form-group">
                                <label
                                    className="form-label"
                                    htmlFor="usernameTravelgate"
                                >
                                    Travelgate Username
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="username"
                                    id="usernameTravelgate"
                                    placeholder="Travelgate Username"
                                    autoComplete="off"
                                    value={travelgateCredValues?.username}
                                    onChange={inputValue("username")}
                                />
                            </div>

                            <div className="form-group mb-5">
                                <label
                                    className="form-label"
                                    htmlFor="passwordTravelGate"
                                >
                                    Travelgate Password
                                </label>
                                <div className="input-group">
                                    <input
                                        className="form-control"
                                        type={showPassword}
                                        name="password"
                                        id="passwordTravelGate"
                                        placeholder="Travelgate password"
                                        autoComplete="off"
                                        value={travelgateCredValues?.password}
                                        onChange={inputValue("password")}
                                    />
                                    <span className="input-group-text">
                                        {showPassword === "password" ? (
                                            <i
                                                className="fa fa-eye"
                                                id="togglePassword2"
                                                style={{
                                                    cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                    setShowPassword("text")
                                                }
                                            ></i>
                                        ) : (
                                            <i
                                                className="fas fa-eye-slash"
                                                id="togglehidePassword2"
                                                style={{
                                                    cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                    setShowPassword("password")
                                                }
                                            ></i>
                                        )}
                                    </span>
                                </div>
                            </div>
                        </>
                    </div>
                )}
            </div>

            <Modal.Footer>
                <button className="btn btn-warning" onClick={prev}>
                    <i className="fas fa-caret-left"></i> &nbsp;&nbsp; Back
                </button>
                <button
                    type="button"
                    className="btn btn-light"
                    onClick={handleClose}
                >
                    Close
                </button>
                <button
                    className="btn btn-primary"
                    onClick={handleAddCridentals}
                    disabled={loading}
                >
                    Next &nbsp;&nbsp;
                    <i className="fas fa-caret-right"></i>
                    {loading && (
                        <>
                            &nbsp;
                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        </>
                    )}
                </button>
            </Modal.Footer>
        </>
    );
}
export default TravelgateCredentails;
