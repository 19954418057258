import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getExclusiveContent } from "../../redux/actions/AnalyticsActions";
import Table from "../Table/Table";
import SyncLoader from "react-spinners/SyncLoader";
import AnalyticsService from "../../services/AnalyticsService";
import { toast } from "react-toastify";
import { Spinner, OverlayTrigger, Tooltip } from "react-bootstrap";
import { separator } from "../../utilis/functions";

const SupplierExclusiveContent = () => {
    const dispatch = useDispatch();
    const {
        supplierExclusiveContent,
        loadingSupplierExclusiveContent,
        partnerSteps,
    } = useSelector((state) => ({
        supplierExclusiveContent:
            state.analyticsReducer.supplierExclusiveContent ?? {},
        loadingSupplierExclusiveContent:
            state.analyticsReducer.loadingSupplierExclusiveContent,
        partnerSteps: state.analyticsReducer.partnerSteps,
    }));

    useEffect(() => {
        dispatch(getExclusiveContent());
    }, []);

    const renderTooltip = (name) => (props) =>
        (
            <Tooltip id="button-tooltip" {...props}>
                {name}
            </Tooltip>
        );
    const columns = [
        {
            name: <div className="table-title">Partner name</div>,
            sortable: true,
            selector: (e) => e?.partner_name,
            cell: (e) => {
                return <>{e?.partner_name}</>;
            },
        },
        {
            name: <div className="table-title">Hotels count</div>,
            sortable: true,
            selector: (e) => e?.hotel_count,
            cell: (e) => {
                return (
                    <span>
                        {e?.hotel_count ? separator(e?.hotel_count) : 0}
                    </span>
                );
            },
        },
        {
            name: <div className="table-title">Download hotels</div>,
            sortable: false,
            cell: (e) => {
                return <DownloadButton element={e} />;
            },
        },
    ];
    return (
        <div className="card" style={{ minHeight: "524px" }}>
            <div className="card-body">
                <h4 className="card-title" style={{ marginBottom: "10px" }}>
                    <img
                        src="../../assets/images/certified.png"
                        alt="wrapkit"
                        style={{
                            width: " 20px",
                            marginBottom: "6px",
                            marginRight: "3px",
                        }}
                    />
                    Partners with Exclusive Content
                    <OverlayTrigger
                        placement="top"
                        delay={{
                            show: 250,
                            hide: 400,
                        }}
                        overlay={renderTooltip(
                            "This is the number of properties from your inventory that were mapped with a single partner "
                        )}
                    >
                        <i
                            className="fas fa-info-circle"
                            style={{ marginLeft: "4px" }}
                        ></i>
                    </OverlayTrigger>
                </h4>
                {loadingSupplierExclusiveContent ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            padding: "20px",
                        }}
                    >
                        <SyncLoader
                            color="#5856d694"
                            loading={true}
                            size={10}
                        />
                    </div>
                ) : (
                    <>
                        {Object.keys(supplierExclusiveContent)?.length === 0 &&
                        partnerSteps?.has_catalog ? (
                            <div
                                className="home-img-container"
                                style={{ marginTop: "18%" }}
                            >
                                <img
                                    src="../../assets/images/search.png"
                                    alt="Partners with Exclusive Content"
                                    style={{ width: "17%" }}
                                />
                                <div className="img-title-unmapped-count">
                                    <span>
                                        It looks like you don't have any
                                        partners with exclusive content.{" "}
                                    </span>
                                </div>
                            </div>
                        ) : Object.keys(supplierExclusiveContent)?.length ===
                          0 ? (
                            <div className="home-img-container">
                                <img
                                    src="../../assets/images/exclusiveContentBlur.png"
                                    alt="Mappings Performed Over Time no Data"
                                    style={{ width: "100%" }}
                                />
                                <div className="home-img-text-container-centered ">
                                    <span>
                                        This table will be available when you
                                        have enough data to display.
                                    </span>
                                </div>
                            </div>
                        ) : (
                            <Table
                                data={
                                    Object.keys(supplierExclusiveContent)?.map(
                                        (e) => ({
                                            ...supplierExclusiveContent[e],
                                        })
                                    ) ?? []
                                }
                                columns={columns}
                                paginationPerPage={5}
                            />
                        )}
                    </>
                )}
            </div>
        </div>
    );
};
export default SupplierExclusiveContent;

const DownloadButton = ({ element }) => {
    const [loading, setLoading] = useState(false);
    return (
        <button
            type="button"
            className="btn btn-sm waves-effect waves-light btn-rounded btn-outline-secondary"
            onClick={async () => {
                setLoading(true);
                const res =
                    await AnalyticsService.downloadPartnerExclusiveContent(
                        element?.partner_id
                    );
                if (res.status === 200) {
                    const link = document.createElement("a");
                    link.href = res?.data;
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                } else {
                    toast.error(res?.message, {
                        toastId: "partner_exclusive_content",
                    });
                }
                setLoading(false);
            }}
        >
            {loading ? (
                <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                />
            ) : (
                <i className="fas fa-download"></i>
            )}
        </button>
    );
};
