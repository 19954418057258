/* eslint-disable import/no-anonymous-default-export */
import {
    GET_DESTINATION_LIST_DATA_FAIL,
    GET_DESTINATION_LIST_DATA_LISTING,
    GET_DESTINATION_LIST_DATA_SUCCESS,
    GET_DESTINATION_CREDENTIALS_DATA_FAIL,
    GET_DESTINATION_CREDENTIALS_DATA_LISTING,
    GET_DESTINATION_CREDENTIALS_DATA_SUCCESS,
} from "../types";

const initialState = {
    destinationList: [],
    loading: false,
    loadingCredentials: false,
    credentials: {},
    credentialsData: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_DESTINATION_LIST_DATA_LISTING:
            return {
                ...state,
                loading: true,
            };
        case GET_DESTINATION_LIST_DATA_SUCCESS:
            return {
                ...state,
                destinationList: action.payload,
                loading: false,
            };
        case GET_DESTINATION_LIST_DATA_FAIL:
            return {
                ...state,
                loading: false,
            };
        case GET_DESTINATION_CREDENTIALS_DATA_LISTING:
            return {
                ...state,
                loadingCredentials: true,
            };
        case GET_DESTINATION_CREDENTIALS_DATA_SUCCESS:
            return {
                ...state,
                credentials: action.payload?.data,
                credentialsData: action.payload?.credentials,
                loadingCredentials: false,
            };
        case GET_DESTINATION_CREDENTIALS_DATA_FAIL:
            return {
                ...state,
                loadingCredentials: false,
            };
        default:
            return state;
    }
}
