import React from "react";
import Select from "../../../../components/Select";
import Lottie from "react-lottie";
import animationData from "../../../../lotties/upload_file.json";
import MatchingFieldsInfo from "../../../../components/MatchingFieldsInfo";
import { Modal, ProgressBar, Spinner } from "react-bootstrap";

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};
function Step3(props) {
    const {
        selectedValues,
        setSelectedValues,
        data,
        loadingTest,
        progressTest,
        handleClose,
        handleStartProcessing,
        prev,
        hideBackButton,
        loadingSaveAttributes,
        firstLine,
    } = props;
    return (
        <>
            <Modal.Body>
                {loadingTest ? (
                    <div>
                        <Lottie
                            options={defaultOptions}
                            height={400}
                            width={400}
                        />
                        <div
                            style={{
                                textAlign: "center",
                                paddingBottom: "15px",
                            }}
                        >
                            <span
                                style={{
                                    fontSize: "21px",
                                    fontWeight: "600",
                                }}
                            >
                                Uploading data to the server. Please do not
                                refresh this page.
                            </span>
                        </div>
                    </div>
                ) : (
                    <>
                        <MatchingFieldsInfo />
                        <form className="row">
                            <div className="col-4">
                                <div className="form-group">
                                    <label
                                        className="mr-sm-2"
                                        for="csv_hotel_code"
                                    >
                                        Hotel ID
                                        <span className="text-danger"> * </span>
                                    </label>
                                    <Select
                                        options={data?.map((e) => ({
                                            value: e?.index,
                                            label: e?.name,
                                        }))}
                                        isClearable={true}
                                        name="header_id"
                                        value={selectedValues.header_id}
                                        styles={{
                                            control: (base) => ({
                                                ...base,
                                                borderColor:
                                                    selectedValues.header_id !=
                                                        undefined ||
                                                    selectedValues.header_id !=
                                                        null
                                                        ? "#89c995fc"
                                                        : "#ff00009e",
                                                "&:hover": {
                                                    borderColor:
                                                        selectedValues.header_id !=
                                                            undefined ||
                                                        selectedValues.header_id !=
                                                            null
                                                            ? "#89c995fc"
                                                            : "#ff00009e",
                                                },
                                            }),
                                        }}
                                        onChange={(e) =>
                                            setSelectedValues({
                                                ...selectedValues,
                                                header_id: e,
                                            })
                                        }
                                    />
                                    <PreviewFirstColumn
                                        data={firstLine?.find(
                                            (e) =>
                                                e?.index ===
                                                selectedValues.header_id?.value
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label
                                        className="mr-sm-2"
                                        for="csv_hotel_name"
                                    >
                                        Hotel Name
                                        <span className="text-danger"> * </span>
                                    </label>
                                    <Select
                                        options={data?.map((e) => ({
                                            value: e?.index,
                                            label: e?.name,
                                        }))}
                                        isClearable={true}
                                        name="header_name"
                                        value={selectedValues.header_name}
                                        styles={{
                                            control: (base) => ({
                                                ...base,
                                                borderColor:
                                                    selectedValues.header_name
                                                        ? "#89c995fc"
                                                        : "#ff00009e",
                                                "&:hover": {
                                                    borderColor:
                                                        selectedValues.header_name
                                                            ? "#89c995fc"
                                                            : "#ff00009e",
                                                },
                                            }),
                                        }}
                                        onChange={(e) =>
                                            setSelectedValues({
                                                ...selectedValues,
                                                header_name: e,
                                            })
                                        }
                                    />
                                    <PreviewFirstColumn
                                        data={firstLine?.find(
                                            (e) =>
                                                e?.index ===
                                                selectedValues.header_name
                                                    ?.value
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label
                                        className="mr-sm-2"
                                        for="csv_hotel_address"
                                    >
                                        Hotel Address
                                        <span className="text-danger"> * </span>
                                    </label>
                                    <Select
                                        options={data?.map((e) => ({
                                            value: e?.index,
                                            label: e?.name,
                                        }))}
                                        name="header_address"
                                        isClearable={true}
                                        value={selectedValues.header_address}
                                        styles={{
                                            control: (base) => ({
                                                ...base,
                                                borderColor:
                                                    selectedValues.header_address
                                                        ? "#89c995fc"
                                                        : "#ff00009e",
                                                "&:hover": {
                                                    borderColor:
                                                        selectedValues.header_address
                                                            ? "#89c995fc"
                                                            : "#ff00009e",
                                                },
                                            }),
                                        }}
                                        onChange={(e) =>
                                            setSelectedValues({
                                                ...selectedValues,
                                                header_address: e,
                                            })
                                        }
                                    />
                                    <PreviewFirstColumn
                                        data={firstLine?.find(
                                            (e) =>
                                                e?.index ===
                                                selectedValues.header_address
                                                    ?.value
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label
                                        className="mr-sm-2"
                                        for="csv_hotel_address_2"
                                    >
                                        Hotel Address 2
                                    </label>
                                    <Select
                                        options={data?.map((e) => ({
                                            value: e?.index,
                                            label: e?.name,
                                        }))}
                                        name="header_address_2"
                                        isClearable={true}
                                        value={selectedValues.header_address_2}
                                        onChange={(e) =>
                                            setSelectedValues({
                                                ...selectedValues,
                                                header_address_2: e,
                                            })
                                        }
                                    />
                                    <PreviewFirstColumn
                                        data={firstLine?.find(
                                            (e) =>
                                                e?.index ===
                                                selectedValues.header_address_2
                                                    ?.value
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label
                                        className="mr-sm-2"
                                        for="csv_hotel_address_3"
                                    >
                                        Hotel Address 3
                                    </label>
                                    <Select
                                        options={data?.map((e) => ({
                                            value: e?.index,
                                            label: e?.name,
                                        }))}
                                        name="header_address_3"
                                        isClearable={true}
                                        value={selectedValues.header_address_3}
                                        onChange={(e) =>
                                            setSelectedValues({
                                                ...selectedValues,
                                                header_address_3: e,
                                            })
                                        }
                                    />
                                    <PreviewFirstColumn
                                        data={firstLine?.find(
                                            (e) =>
                                                e?.index ===
                                                selectedValues.header_address_3
                                                    ?.value
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label
                                        className="mr-sm-2"
                                        for="csv_hotel_country"
                                    >
                                        Country ISO Code
                                        <span className="text-danger"> * </span>
                                    </label>
                                    <Select
                                        options={data?.map((e) => ({
                                            value: e?.index,
                                            label: e?.name,
                                        }))}
                                        isClearable={true}
                                        name="header_country_code"
                                        value={
                                            selectedValues.header_country_code
                                        }
                                        styles={{
                                            control: (base) => ({
                                                ...base,
                                                borderColor:
                                                    selectedValues.header_country_code
                                                        ? "#89c995fc"
                                                        : "#ff00009e",
                                                "&:hover": {
                                                    borderColor:
                                                        selectedValues.header_country_code
                                                            ? "#89c995fc"
                                                            : "#ff00009e",
                                                },
                                            }),
                                        }}
                                        onChange={(e) =>
                                            setSelectedValues({
                                                ...selectedValues,
                                                header_country_code: e,
                                            })
                                        }
                                    />
                                    <PreviewFirstColumn
                                        data={firstLine?.find(
                                            (e) =>
                                                e?.index ===
                                                selectedValues
                                                    .header_country_code?.value
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label
                                        className="mr-sm-2"
                                        for="csv_hotel_lat"
                                    >
                                        Latitude
                                        <span className="text-warning-catalog">
                                            (Recommended)
                                        </span>
                                    </label>
                                    <Select
                                        options={data?.map((e) => ({
                                            value: e?.index,
                                            label: e?.name,
                                        }))}
                                        isClearable={true}
                                        name="header_latitude"
                                        value={selectedValues.header_latitude}
                                        onChange={(e) =>
                                            setSelectedValues({
                                                ...selectedValues,
                                                header_latitude: e,
                                            })
                                        }
                                    />
                                    <PreviewFirstColumn
                                        data={firstLine?.find(
                                            (e) =>
                                                e?.index ===
                                                selectedValues.header_latitude
                                                    ?.value
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label
                                        className="mr-sm-2"
                                        for="csv_hotel_lon"
                                    >
                                        Longitude
                                        <span className="text-warning-catalog">
                                            (Recommended)
                                        </span>
                                    </label>
                                    <Select
                                        options={data?.map((e) => ({
                                            value: e?.index,
                                            label: e?.name,
                                        }))}
                                        isClearable={true}
                                        name="header_longitude"
                                        value={selectedValues.header_longitude}
                                        onChange={(e) =>
                                            setSelectedValues({
                                                ...selectedValues,
                                                header_longitude: e,
                                            })
                                        }
                                    />
                                    <PreviewFirstColumn
                                        data={firstLine?.find(
                                            (e) =>
                                                e?.index ===
                                                selectedValues.header_longitude
                                                    ?.value
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label
                                        className="mr-sm-2"
                                        for="csv_hotel_city"
                                    >
                                        City
                                    </label>
                                    <Select
                                        options={data?.map((e) => ({
                                            value: e?.index,
                                            label: e?.name,
                                        }))}
                                        isClearable={true}
                                        name="header_city"
                                        value={selectedValues.header_city}
                                        onChange={(e) =>
                                            setSelectedValues({
                                                ...selectedValues,
                                                header_city: e,
                                            })
                                        }
                                    />
                                    <PreviewFirstColumn
                                        data={firstLine?.find(
                                            (e) =>
                                                e?.index ===
                                                selectedValues.header_city
                                                    ?.value
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label
                                        className="mr-sm-2"
                                        for="csv_hotel_lon"
                                    >
                                        City ID
                                    </label>
                                    <Select
                                        options={data?.map((e) => ({
                                            value: e?.index,
                                            label: e?.name,
                                        }))}
                                        isClearable={true}
                                        name="header_city_id"
                                        value={selectedValues.header_city_id}
                                        onChange={(e) =>
                                            setSelectedValues({
                                                ...selectedValues,
                                                header_city_id: e,
                                            })
                                        }
                                    />
                                    <PreviewFirstColumn
                                        data={firstLine?.find(
                                            (e) =>
                                                e?.index ===
                                                selectedValues.header_city_id
                                                    ?.value
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label
                                        className="mr-sm-2"
                                        for="csv_hotel_city"
                                    >
                                        Chain
                                    </label>
                                    <Select
                                        options={data?.map((e) => ({
                                            value: e?.index,
                                            label: e?.name,
                                        }))}
                                        isClearable={true}
                                        name="header_chain"
                                        value={selectedValues.header_chain}
                                        onChange={(e) =>
                                            setSelectedValues({
                                                ...selectedValues,
                                                header_chain: e,
                                            })
                                        }
                                        menuPlacement="top"
                                    />
                                    <PreviewFirstColumn
                                        data={firstLine?.find(
                                            (e) =>
                                                e?.index ===
                                                selectedValues.header_chain
                                                    ?.value
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label
                                        className="mr-sm-2"
                                        for="csv_hotel_city"
                                    >
                                        Property Type
                                    </label>
                                    <Select
                                        options={data?.map((e) => ({
                                            value: e?.index,
                                            label: e?.name,
                                        }))}
                                        isClearable={true}
                                        name="header_type"
                                        value={selectedValues.header_type}
                                        onChange={(e) =>
                                            setSelectedValues({
                                                ...selectedValues,
                                                header_type: e,
                                            })
                                        }
                                        menuPlacement="top"
                                    />

                                    <PreviewFirstColumn
                                        data={firstLine?.find(
                                            (e) =>
                                                e?.index ===
                                                selectedValues.header_type
                                                    ?.value
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label
                                        className="mr-sm-2"
                                        for="csv_hotel_city"
                                    >
                                        Star rating
                                    </label>
                                    <Select
                                        options={data?.map((e) => ({
                                            value: e?.index,
                                            label: e?.name,
                                        }))}
                                        isClearable={true}
                                        name="header_stars"
                                        value={selectedValues.header_stars}
                                        onChange={(e) =>
                                            setSelectedValues({
                                                ...selectedValues,
                                                header_stars: e,
                                            })
                                        }
                                        menuPlacement="top"
                                    />
                                    <PreviewFirstColumn
                                        data={firstLine?.find(
                                            (e) =>
                                                e?.index ===
                                                selectedValues.header_stars
                                                    ?.value
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group ">
                                    <label
                                        className="mr-sm-2"
                                        for="csv_hotel_city"
                                    >
                                        Zip code
                                    </label>
                                    <Select
                                        options={data?.map((e) => ({
                                            value: e?.index,
                                            label: e?.name,
                                        }))}
                                        isClearable={true}
                                        name="header_zip_code"
                                        value={selectedValues.header_zip_code}
                                        onChange={(e) =>
                                            setSelectedValues({
                                                ...selectedValues,
                                                header_zip_code: e,
                                            })
                                        }
                                        menuPlacement="top"
                                    />
                                    <PreviewFirstColumn
                                        data={firstLine?.find(
                                            (e) =>
                                                e?.index ===
                                                selectedValues.header_zip_code
                                                    ?.value
                                        )}
                                    />
                                </div>
                            </div>
                        </form>
                    </>
                )}

                {progressTest && loadingTest && (
                    <ProgressBar
                        now={progressTest}
                        label={`${progressTest}%`}
                        style={{
                            width: "100%",
                            height: "30px",
                            fontSize: "22px",
                        }}
                    />
                )}
            </Modal.Body>
            <Modal.Footer>
                {!hideBackButton && (
                    <>
                        <button
                            type="button"
                            className="btn btn-warning"
                            onClick={prev}
                        >
                            <i className="fas fa-caret-left"></i>
                            &nbsp;&nbsp; Back
                        </button>

                        <button
                            type="button"
                            className="btn btn-light"
                            onClick={handleClose}
                            style={{
                                marginLeft: "10px",
                            }}
                        >
                            Close
                        </button>
                    </>
                )}
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleStartProcessing}
                    disabled={loadingTest || loadingSaveAttributes}
                    style={{
                        marginLeft: "10px",
                    }}
                >
                    <i className="fas fa-upload"></i>
                    &nbsp;&nbsp; Start processing
                    {(loadingTest || loadingSaveAttributes) && (
                        <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    )}
                </button>
            </Modal.Footer>
        </>
    );
}

export default Step3;

const PreviewFirstColumn = ({ data }) => {
    return (
        <>
            {data && (
                <span className="preview-column">
                    {data && `Preview : ${data?.name}`}
                </span>
            )}
        </>
    );
};
