import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSupplierOutdated } from "../../redux/actions/AnalyticsActions";
import moment from "moment";
import Table from "../Table/Table";
import SyncLoader from "react-spinners/SyncLoader";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { HOME } from "../../utilis/const";

function SupplierOutdated() {
    const dispatch = useDispatch();
    const history = useHistory();
    const [daysOption, setDaysOption] = useState(7);
    const { supplierOutdated, loadingSupplierOutdated, partnerSteps } =
        useSelector((state) => ({
            supplierOutdated: state.analyticsReducer?.supplierOutdated ?? [],
            loadingSupplierOutdated:
                state.analyticsReducer?.loadingSupplierOutdated,
            partnerSteps: state.analyticsReducer.partnerSteps,
        }));

    useEffect(() => {
        dispatch(getSupplierOutdated(daysOption));
    }, [daysOption]);

    const renderTooltip = (name) => (props) =>
        (
            <Tooltip id="button-tooltip" {...props}>
                {name}
            </Tooltip>
        );

    const columns = [
        {
            name: <div className="table-title">Partner name</div>,
            sortable: true,
            selector: (e) => e?.partner_name,
            cell: (e) => {
                return (
                    <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            history.push(
                                `/provider-content/details/${e?.partner_id}`,
                                {
                                    params: {
                                        partner: {
                                            name: e.partner_name,
                                            id: e.partner_id,
                                        },
                                        pageType: HOME,
                                    },
                                }
                            );
                        }}
                    >
                        {e?.partner_name}
                    </span>
                );
            },
        },
        {
            name: <div className="table-title">Last update</div>,
            sortable: true,
            selector: (e) => e?.days_diff,
            cell: (e) => {
                return (
                    <>
                        {` ${moment(new Date(e?.last_date)).format(
                            "DD/MM/YYYY"
                        )}`}
                        <span
                            style={{
                                marginLeft: "10px",
                                fontWeight: "bold",
                                color: "#6c75e9",
                            }}
                        >
                            {`${e?.days_diff} days ago`}
                        </span>
                    </>
                );
            },
        },
    ];

    return (
        <div className="card" style={{ minHeight: "524px" }}>
            <div className="card-body">
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "10px",
                    }}
                >
                    <h4 className="card-title">
                        <img
                            src="../../assets/images/overdue.png"
                            alt="wrapkit"
                            style={{
                                width: " 20px",
                                marginBottom: "6px",
                                marginRight: "3px",
                            }}
                        />
                        Partners with Outdated Mappings{" "}
                        <OverlayTrigger
                            placement="top"
                            delay={{
                                show: 250,
                                hide: 400,
                            }}
                            overlay={renderTooltip(
                                "This is the partners that were not updated in a number of days you can select (by default 7 days)"
                            )}
                        >
                            <i
                                className="fas fa-info-circle"
                                style={{ marginLeft: "4px" }}
                            ></i>
                        </OverlayTrigger>
                    </h4>
                    <div className="input-group mb-3" style={{ width: "23%" }}>
                        <input
                            type="number"
                            value={daysOption}
                            onChange={(e) => {
                                setDaysOption(e.target.value);
                            }}
                            className="form-control"
                            placeholder="Recipient's username"
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                            min="0"
                        />
                        <span className="input-group-text" id="basic-addon2">
                            Days
                        </span>
                    </div>
                </div>
                {loadingSupplierOutdated ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            padding: "20px",
                        }}
                    >
                        <SyncLoader
                            color="#5856d694"
                            loading={true}
                            size={10}
                        />
                    </div>
                ) : (
                    <>
                        {supplierOutdated?.length === 0 &&
                        partnerSteps?.has_catalog ? (
                            <div
                                className="home-img-container"
                                style={{ marginTop: "16%" }}
                            >
                                <img
                                    src="../../assets/images/checkImage.png"
                                    alt="Mappings Performed Over Time no Data"
                                    style={{ width: "15%" }}
                                />
                                <div className="img-title-unmapped-count">
                                    <span>
                                        {`You have no partners with mappings outdated by ${daysOption} days or more.`}
                                    </span>
                                </div>
                            </div>
                        ) : supplierOutdated?.length === 0 ? (
                            <div className="home-img-container">
                                <img
                                    src="../../assets/images/outdatedPartnersBlur.png"
                                    alt="Mappings Performed Over Time no Data"
                                    style={{ width: "100%" }}
                                />
                                <div className="home-img-text-container-centered ">
                                    <span>
                                        This table will be available when you
                                        have enough data to display.
                                    </span>
                                </div>
                            </div>
                        ) : (
                            <Table
                                data={supplierOutdated ?? []}
                                columns={columns}
                                paginationPerPage={5}
                            />
                        )}
                    </>
                )}
            </div>
        </div>
    );
}
export default SupplierOutdated;
