import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTravelgateCredentials } from "../../redux/actions/ConnectorActions";
import ConnectorService from "../../services/ConnectorService";
import { Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import SyncLoader from "react-spinners/SyncLoader";

function EditTravelgateCredentails(props) {
    const { handleClose, open } = props;
    const [travelgateCredValues, setTravelgateCredValues] = useState({});
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState("password");
    const { loadingTravelgateCredentials, travelgateCredentials } = useSelector(
        (state) => ({
            loadingTravelgateCredentials:
                state.connectorReducer.loadingTravelgateCredentials,
            travelgateCredentials: state.connectorReducer.travelgateCredentials,
        })
    );

    useEffect(() => {
        if (Object.values(travelgateCredentials)) {
            setTravelgateCredValues({
                username: travelgateCredentials.username,
                password: travelgateCredentials.password,
            });
        }
    }, [loadingTravelgateCredentials]);

    const handleAddCridentals = async () => {
        setLoading(true);
        const res = await ConnectorService.addTravelgateCredentials(
            travelgateCredValues
        );
        if (res?.status === 200) {
            toast.success("Success");
            dispatch(getTravelgateCredentials());

            handleClose();
        } else {
            toast.error(res?.message);
        }
        setLoading(false);
    };

    const inputValue = (name) => (e) => {
        if (e.target.value != "") {
            travelgateCredValues[name] = e.target.value;
        } else {
            travelgateCredValues[name] = null;
        }
        setTravelgateCredValues({ ...travelgateCredValues });
    };

    return (
        <Modal
            show={open}
            // onHide={handleClose}
            dialogClassName="modal-40w modal-dialog modal-dialog-centered"
        >
            <div className="card mb-0 p-10">
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <h3
                                style={{
                                    width: "100%",
                                    maxWidth: "100%",
                                    padding: "0",
                                    marginTop: " 1rem",
                                    marginBottom: " 1rem",
                                    whiteSpace: "normal",
                                }}
                            >
                                Set your travelgate hub credentials
                            </h3>
                        </div>
                        {loadingTravelgateCredentials ? (
                            <div
                                className="col-md-12"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <div>
                                    <SyncLoader
                                        color="#5856d694"
                                        loading={true}
                                        size={10}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="col-md-12">
                                <>
                                    <div className="form-group">
                                        <label
                                            className="form-label"
                                            htmlFor="usernameTravelgate"
                                        >
                                            Travelgate Username
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="username"
                                            id="usernameTravelgate"
                                            placeholder="Travelgate Username"
                                            autoComplete="off"
                                            value={
                                                travelgateCredValues?.username
                                            }
                                            onChange={inputValue("username")}
                                        />
                                    </div>

                                    <div className="form-group mb-5">
                                        <label
                                            className="form-label"
                                            htmlFor="passwordTravelGate"
                                        >
                                            Travelgate Password
                                        </label>
                                        <div className="input-group">
                                            <input
                                                className="form-control"
                                                type={showPassword}
                                                name="password"
                                                id="passwordTravelGate"
                                                placeholder="Travelgate password"
                                                autoComplete="off"
                                                value={
                                                    travelgateCredValues?.password
                                                }
                                                onChange={inputValue(
                                                    "password"
                                                )}
                                            />
                                            <span className="input-group-text">
                                                {showPassword === "password" ? (
                                                    <i
                                                        className="fa fa-eye"
                                                        id="togglePassword2"
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() =>
                                                            setShowPassword(
                                                                "text"
                                                            )
                                                        }
                                                    ></i>
                                                ) : (
                                                    <i
                                                        className="fas fa-eye-slash"
                                                        id="togglehidePassword2"
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() =>
                                                            setShowPassword(
                                                                "password"
                                                            )
                                                        }
                                                    ></i>
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                </>
                            </div>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        type="button"
                        className="btn btn-light"
                        onClick={handleClose}
                    >
                        Close
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={handleAddCridentals}
                        disabled={loading}
                    >
                        {travelgateCredentials?.password !== "" &&
                        travelgateCredentials?.username !== ""
                            ? "Edit"
                            : "Add"}
                        {loading && (
                            <>
                                &nbsp;
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            </>
                        )}
                    </button>
                </Modal.Footer>
            </div>
        </Modal>
    );
}
export default EditTravelgateCredentails;
