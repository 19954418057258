import React, { useEffect } from "react";
import { separator } from "../../utilis/functions";
import { PieChart } from "react-minimal-pie-chart";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

const GlobalData = (props) => {
    const { headerData } = useSelector((state) => ({
        headerData: state.partnerReportReducer.headerData,
    }));
    const calculHeaderPourcentage = () => {
        let calculElement = {};
        if (headerData === undefined) {
            calculElement = {
                mapped_hotels: 0,
                total_hotels: 0,
                unmapped_hotels: 0,
                lastMappedPourcentage: 0,
                mappedPourcentage: 0,
                unmappedPourcentage: 0,
            };
        } else {
            let mapped_hotels = headerData?.mapped_hotels ?? 0;
            let unmapped_hotels = headerData?.unmapped_hotels ?? 0;
            let valid_hotels = headerData?.valid_hotels ?? 0;
            let lastMappedPourcentage =
                valid_hotels != 0 ? (mapped_hotels / valid_hotels) * 100 : 0;
            let mappedPourcentage =
                valid_hotels != 0 ? (mapped_hotels / valid_hotels) * 100 : 0;
            let unmappedPourcentage =
                valid_hotels != 0 ? (unmapped_hotels / valid_hotels) * 100 : 0;
            calculElement = {
                ...headerData,
                lastMappedPourcentage: lastMappedPourcentage,
                mappedPourcentage: mappedPourcentage,
                unmappedPourcentage: unmappedPourcentage,
            };
        }
        return calculElement;
    };

    return (
        <div className="row">
            <div className="col-md-12 col-lg-3" style={{ paddingRight: "0px" }}>
                <div
                    className={`card boxShadow card-info card-partner-details`}
                    style={{
                        borderRadius: "0.75rem",
                    }}
                >
                    <div
                        className="card-body"
                        style={{
                            padding: "28px",
                        }}
                    >
                        <div className="d-flex d-lg-flex d-md-block align-items-center">
                            <div>
                                <h2
                                    className="text-dark mb-1 w-100 text-truncate font-weight-medium"
                                    style={{
                                        fontSize: "20px",
                                    }}
                                >
                                    Mapping Rate
                                </h2>
                                <h6 className="text-muted font-weight-bold mb-0 w-100 text-truncate">
                                    (
                                    {`Last update :  ${moment(
                                        headerData?.created_at,
                                        "YYYYMMDD HH:mm:ss Z"
                                    ).fromNow()}`}
                                    )
                                </h6>
                            </div>
                            <div className="ml-auto mt-md-3 mt-lg-0">
                                <span className="opacity-7 text-muted">
                                    <PieChart
                                        style={{
                                            height: "58px",
                                        }}
                                        data={[
                                            {
                                                value:
                                                    calculHeaderPourcentage(
                                                        headerData
                                                    ).lastMappedPourcentage?.toFixed(
                                                        0
                                                    ) ?? 0,

                                                title: `${
                                                    calculHeaderPourcentage(
                                                        headerData
                                                    ).lastMappedPourcentage?.toFixed(
                                                        0
                                                    ) ?? 0
                                                }%`,
                                                color: "rgb(21, 191, 174)",
                                            },
                                        ]}
                                        totalValue={100}
                                        lineWidth={20}
                                        label={({ dataEntry }) =>
                                            dataEntry.title
                                        }
                                        labelStyle={{
                                            fontSize: "25px",
                                            fontFamily: "sans-serif",
                                            fill: "rgb(21, 191, 174)",
                                        }}
                                        labelPosition={0}
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-3 home" style={{ paddingRight: "0px" }}>
                <div
                    className={`card boxShadow card-info card-partner-details`}
                    style={{
                        borderRadius: "0.75rem",
                    }}
                >
                    <div
                        className="card-body"
                        style={{
                            padding: "28px",
                        }}
                    >
                        <div className="d-flex d-lg-flex d-md-block align-items-center">
                            <div>
                                <div className="d-inline-flex align-items-center">
                                    <h2 className="text-dark mb-1 w-100 text-truncate font-weight-medium">
                                        {headerData?.total_hotels
                                            ? separator(
                                                  headerData?.total_hotels
                                              )
                                            : 0}
                                    </h2>
                                </div>
                                <h6 className="text-muted font-weight-bold mb-0 w-100 text-truncate">
                                    Total hotels
                                </h6>
                            </div>
                            <div className="ml-auto mt-md-3 mt-lg-0">
                                <span className="opacity-7 text-muted">
                                    <i
                                        className="fas fa-building"
                                        style={{
                                            color: "rgb(95 117 232)",
                                        }}
                                    ></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-3 home" style={{ paddingRight: "0px" }}>
                <div
                    className={`card boxShadow card-info card-partner-details`}
                    style={{
                        borderRadius: "0.75rem",
                    }}
                >
                    <div className="card-body" style={{ padding: "28px" }}>
                        <div className="d-flex d-lg-flex d-md-block align-items-center">
                            <div>
                                <div className="d-inline-flex align-items-center">
                                    <h2 className="text-dark mb-1 w-100 text-truncate font-weight-medium">
                                        <sup className="set-doller">
                                            <i className="fas fa-hotel"></i>
                                        </sup>
                                        {headerData?.mapped_hotels
                                            ? separator(
                                                  headerData?.mapped_hotels
                                              )
                                            : 0}
                                    </h2>
                                    <span className="badge bg-primary font-12 text-white font-weight-medium badge-pill ml-2 d-md-none d-lg-block">
                                        {`${calculHeaderPourcentage(
                                            headerData
                                        ).mappedPourcentage?.toFixed(0)} %`}
                                    </span>
                                </div>
                                <h6 className="text-muted font-weight-bold mb-0 w-100 text-truncate">
                                    Mapped
                                </h6>
                            </div>
                            <div className="ml-auto mt-md-3 mt-lg-0">
                                <span className="opacity-7 text-muted">
                                    <i
                                        className="fas fa-building"
                                        style={{
                                            color: "rgb(95 117 232)",
                                        }}
                                    ></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-3 home">
                <div
                    className={`card boxShadow card-info card-partner-details`}
                    style={{ borderRadius: "0.75rem" }}
                >
                    <div
                        className="card-body"
                        style={{
                            padding: "28px",
                        }}
                    >
                        <div className="d-flex d-lg-flex d-md-block align-items-center">
                            <div>
                                <div className="d-inline-flex align-items-center">
                                    <h2 className="text-dark mb-1 w-100 text-truncate font-weight-medium">
                                        <sup className="set-doller">
                                            <i className="fas fa-hotel"></i>
                                        </sup>
                                        {headerData?.unmapped_hotels
                                            ? separator(
                                                  headerData?.unmapped_hotels
                                              )
                                            : 0}
                                    </h2>
                                    <span className="badge bg-primary font-12 text-white font-weight-medium badge-pill ml-2 d-md-none d-lg-block">
                                        {`${calculHeaderPourcentage(
                                            headerData
                                        ).unmappedPourcentage?.toFixed(0)} %`}
                                    </span>
                                </div>
                                <h6 className="text-muted font-weight-bold mb-0 w-100 text-truncate">
                                    Opportunities
                                </h6>
                            </div>
                            <div className="ml-auto mt-md-3 mt-lg-0">
                                <span className="opacity-7 text-muted">
                                    <i
                                        className="fas fa-building"
                                        style={{
                                            color: "rgb(95 117 232)",
                                        }}
                                    ></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default GlobalData;
