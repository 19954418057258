import axios from "axios";
import { toast } from "react-toastify";
const url = process.env.REACT_APP_BASE_URL;

const CompanyService = {
    update: async (company) => {
        const result = await axios
            .put(url + "/companies/update", company, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                toast.success("Company updated successfully");
                return {
                    data: res.data,
                    status: 200,
                };
            })
            .catch((error) => {
                toast.error("Company update failed");
                return {
                    message: error.response.data.message,
                    status: error.response.data.code,
                };
            });
        return result;
    },

    get: async () => {
        const result = await axios
            .get(url + "/companies/me", {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                return {
                    data: res.data,
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: error.response.data.msg,
                    status: error.response.data.code,
                };
            });
        return result;
    },
};

export default CompanyService;
