import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import SyncLoader from "react-spinners/SyncLoader";

const MappingDetailsChart = () => {
    const { compareMappingTotal, loadingCompareMappingTotal } = useSelector(
        (state) => ({
            compareMappingTotal: {
                ...state.partnerReportReducer.compareMappingTotal,
                current_rate:
                    parseFloat(
                        (state.partnerReportReducer.compareMappingTotal
                            ?.current_mapped /
                            state.partnerReportReducer.compareMappingTotal
                                ?.current_partner_total) *
                            100
                    ).toFixed(2) ?? 0,
                target_rate:
                    parseFloat(
                        (state.partnerReportReducer.compareMappingTotal
                            ?.target_mapped /
                            state.partnerReportReducer.compareMappingTotal
                                ?.target_partner_total) *
                            100
                    ).toFixed(2) ?? 0,
            },
            loadingCompareMappingTotal:
                state.partnerReportReducer.loadingCompareMappingTotal,
        })
    );

    const defaultOptionsDataSet1 = {
        chart: {
            zoomType: "xy",
            height: 200,
        },
        title: {
            text: "",
        },

        xAxis: [
            {
                categories: ["Selected Mapping", "Current Mapping"],
                crosshair: true,
            },
        ],
        yAxis: [
            {
                // Secondary yAxis

                labels: {
                    format: "{value} %",
                    style: {
                        color: Highcharts.getOptions().colors[0],
                    },
                },
                title: {
                    text: "",
                    style: {
                        color: Highcharts.getOptions().colors[0],
                    },
                },
                opposite: true,
            },
            {
                // Primary yAxis
                labels: {
                    format: "",
                    style: {
                        color: Highcharts.getOptions().colors[1],
                    },
                },
                title: {
                    text: "",
                    style: {
                        color: Highcharts.getOptions().colors[1],
                    },
                },
            },
        ],
        tooltip: {
            shared: false,
        },
        legend: {
            //  align: "center",
            //  x: 80,
            // verticalAlign: "bottom",
            //  y: 80,
            //   floating: true,
            backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || // theme
                "rgba(255,255,255,0.25)",
        },
        plotOptions: {
            column: {
                stacking: "normal",
                dataLabels: {
                    enabled: false,
                },
            },
            series: {
                pointWidth: 60,
            },
        },
        series: [
            {
                name: "Opportunities",
                type: "column",
                yAxis: 1,
                data: [
                    compareMappingTotal?.target_unmapped,
                    compareMappingTotal?.current_unmapped,
                ],
                color: "rgb(242, 95, 41)",
            },
            {
                type: "column",
                name: "Mapped properties",
                yAxis: 1,
                data: [
                    compareMappingTotal?.target_mapped,
                    compareMappingTotal?.current_mapped,
                ],
                color: "rgb(21, 191, 174)",
            },
            {
                name: "Partner's catalog mapping rate",
                type: "spline",
                data: [
                    Number(compareMappingTotal?.target_rate),
                    Number(compareMappingTotal?.current_rate),
                ],
                tooltip: {
                    valueSuffix: "%",
                },
                color: "rgba(95, 118, 232, 0.78)",
            },
        ],

        credits: {
            enabled: false,
        },
    };
    return (
        <div className="card" style={{ marginBottom: "15px" }}>
            <div className="card-header">
                <h4
                    className="card-title"
                    style={{
                        marginBottom: "0px",
                        paddingLeft: "15px",
                    }}
                >
                    <i
                        className="fas fa-chart-line"
                        style={{ marginRight: "4px" }}
                    ></i>
                    Partner Mapping Rate Comparison
                </h4>
            </div>
            <div className="card-body">
                {loadingCompareMappingTotal ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            padding: "20px",
                        }}
                    >
                        <SyncLoader
                            color="#5856d694"
                            loading={true}
                            size={10}
                        />
                    </div>
                ) : (
                    <>
                        {Object.keys(compareMappingTotal)?.length === 0 ? (
                            <div className="home-img-container">
                                <img
                                    src="../../assets/images/comparison.png"
                                    alt="Mappings Performed Over Time no Data"
                                    style={{ width: "100%", height: "200px" }}
                                />
                                <div className="home-img-text-container-centered ">
                                    <span>
                                        This graph will be available when you
                                        have enough data to display.
                                    </span>
                                </div>
                            </div>
                        ) : (
                            <div
                                style={{
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                }}
                            >
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={defaultOptionsDataSet1}
                                />
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default MappingDetailsChart;
