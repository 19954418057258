import React from "react";

import DataTable from "react-data-table-component";
import "react-data-table-component-extensions/dist/index.css";

const Table = ({
    data,
    columns,
    conditionalRowStyles,
    onRowClicked,
    highlightOnHover,
}) => {
    return (
        <DataTable
            columns={columns}
            data={data}
            // noHeader
            defaultSortField="id"
            defaultSortAsc={false}
            pagination
            highlightOnHover={highlightOnHover ?? false}
            onRowClicked={onRowClicked}
            responsive
            conditionalRowStyles={conditionalRowStyles}
            noDataComponent="This attribute was not found" //or your component
        />
    );
};

export default Table;
