import {
    GET_SEGMENT_LIST_SUCCESS,
    GET_SEGMENT_LIST_FAIL,
    GET_SEGMENT_LIST_LISTING,
    GET_CHAINS_LIST_SUCCESS,
    GET_CHAINS_LIST_FAIL,
    GET_CHAINS_LIST_LISTING,
    GET_STARS_LIST_LISTING,
    GET_STARS_LIST_SUCCESS,
    GET_STARS_LIST_FAIL,
    GET_SEGMENT_TYPES_LIST_SUCCESS,
    GET_SEGMENT_TYPES_LIST_FAIL,
    GET_SEGMENT_TYPES_LIST_LISTING,
    GET_CITIES_LIST_SUCCESS,
    GET_CITIES_LIST_FAIL,
    GET_CITIES_LIST_LISTING,
} from "../types";
import axios from "axios";

const PATH_URL = process.env.REACT_APP_BASE_URL;

export const getSegmentsList = () => async (dispatch) => {
    dispatch({
        type: GET_SEGMENT_LIST_LISTING,
    });
    try {
        const res = await axios.get(PATH_URL + "/catalogs/segments", {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        dispatch({
            type: GET_SEGMENT_LIST_SUCCESS,
            payload: res.data,
        });
    } catch (e) {
        dispatch({
            type: GET_SEGMENT_LIST_FAIL,
        });
    }
};
export const getChainsList = () => async (dispatch) => {
    dispatch({
        type: GET_CHAINS_LIST_LISTING,
    });
    try {
        const res = await axios.get(PATH_URL + "/list-chains", {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        dispatch({
            type: GET_CHAINS_LIST_SUCCESS,
            payload: res.data,
        });
    } catch (e) {
        dispatch({
            type: GET_CHAINS_LIST_FAIL,
        });
    }
};
export const getStarsList = () => async (dispatch) => {
    dispatch({
        type: GET_STARS_LIST_LISTING,
    });
    try {
        const res = await axios.get(PATH_URL + "/list-stars", {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        dispatch({
            type: GET_STARS_LIST_SUCCESS,
            payload: res.data,
        });
    } catch (e) {
        dispatch({
            type: GET_STARS_LIST_FAIL,
        });
    }
};
export const getSegmentTypeList = () => async (dispatch) => {
    dispatch({
        type: GET_SEGMENT_TYPES_LIST_LISTING,
    });
    try {
        const res = await axios.get(PATH_URL + "/list-property-types", {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        dispatch({
            type: GET_SEGMENT_TYPES_LIST_SUCCESS,
            payload: res.data,
        });
    } catch (e) {
        dispatch({
            type: GET_SEGMENT_TYPES_LIST_FAIL,
        });
    }
};

export const getCitiesList = () => async (dispatch) => {
    dispatch({
        type: GET_CITIES_LIST_LISTING,
    });
    try {
        const res = await axios.get(PATH_URL + "/list-cities", {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        dispatch({
            type: GET_CITIES_LIST_SUCCESS,
            payload: res.data,
        });
    } catch (e) {
        dispatch({
            type: GET_CITIES_LIST_FAIL,
        });
    }
};
