import React, { useEffect } from "react";
import Select from "../../components/Select";
import InputAutoComplete from "../../components/AutoComplete/ExploreMapping/HotelNameAutoComplete";
import { useDispatch, useSelector } from "react-redux";
import { getCountries } from "../../redux/actions/CountryActions";
import { useForm } from "react-hook-form";
import { Spinner } from "react-bootstrap";

function SearchForm(props) {
    const { handleSearch, filters, setFilters } = props;
    const dispatch = useDispatch();

    const { countries, loadingHotels } = useSelector((state) => ({
        countries: state?.countriesReducer?.countries ?? [],
        loadingHotels: state?.exploreMappingReducer?.loadingHotels,
    }));
    const { handleSubmit } = useForm();
    useEffect(() => {
        dispatch(getCountries());
    }, []);

    const inputValue = (name) => (e) => {
        if (e.target.value != "") {
            filters[name] = e.target.value;
        } else {
            filters[name] = null;
        }
        setFilters({ ...filters });
    };

    const getSelectedVal = (value) => {
        setFilters({ ...filters, hotel_name: value });
    };

    return (
        <div className="card" style={{ marginTop: "20px" }}>
            <div className="card-header p-0 pb-2">
                <h4
                    style={{
                        color: "#6a75e9",
                        marginLeft: "10px",
                        marginTop: "10px",
                        fontWeight: "500",
                    }}
                >
                    Search By :
                </h4>
            </div>
            <form
                className="search-form "
                onSubmit={handleSubmit(handleSearch)}
                id="create-search-form"
            >
                <div className="row" style={{ margin: "5px" }}>
                    <div
                        className="col-2"
                        style={{ padding: "4px", margin: "0px" }}
                    >
                        <input
                            type="text"
                            className="form-control"
                            name="hotel_code"
                            placeholder="Inventory Hotel code "
                            autoComplete="off"
                            onChange={inputValue("hotel_code")}
                        />
                    </div>
                    <div
                        className="col-2"
                        style={{ padding: "4px", margin: "0px" }}
                    >
                        <InputAutoComplete
                            isClearable={true}
                            value={filters?.hotel_name}
                            placeholder="Hotel name"
                            name="hotel_name"
                            onChange={getSelectedVal}
                            styles={{
                                control: (base) => ({
                                    ...base,
                                    height: 49,
                                    minHeight: 49,
                                }),
                            }}
                        />
                    </div>
                    <div
                        className="col-3"
                        style={{ padding: "4px", margin: "0px" }}
                    >
                        <Select
                            options={countries?.map((e) => ({
                                iso2: e?.iso2,
                                value: e?.id,
                                label: e?.name,
                            }))}
                            name="country"
                            value={filters?.country}
                            placeholder="Country"
                            styles={{
                                control: (base) => ({
                                    ...base,
                                    height: 49,
                                    minHeight: 49,
                                }),
                            }}
                            isClearable={true}
                            onChange={(e) => {
                                setFilters({ ...filters, country: e });
                            }}
                        />
                    </div>
                    <div
                        className="col-2"
                        style={{ padding: "4px", margin: "0px" }}
                    >
                        <input
                            value={filters?.city}
                            type="text"
                            className="form-control"
                            name="city"
                            placeholder="City"
                            autoComplete="off"
                            onChange={inputValue("city")}
                        />
                    </div>

                    <div
                        className="col-3"
                        style={{ padding: "4px", margin: "0px" }}
                    >
                        <input
                            type="text"
                            className="form-control"
                            name="address"
                            placeholder="Address"
                            onChange={inputValue("address")}
                        />
                    </div>
                </div>
                <div
                    className="card-footer"
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        padding: "8px",
                    }}
                >
                    <button
                        className="btn waves-effect waves-light btn-rounded btn-warning"
                        style={{ marginTop: "0px" }}
                        type="button"
                        onClick={() => {
                            document
                                .getElementById("create-search-form")
                                .reset();
                            setFilters({ country: null, hotel_name: null });
                        }}
                    >
                        Reset
                    </button>
                    &nbsp;
                    <button
                        type="submit"
                        className="btn waves-effect waves-light btn-rounded btn-primary"
                        style={{ marginTop: "0px" }}
                        disabled={loadingHotels}
                    >
                        Search
                        {loadingHotels && (
                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        )}
                    </button>
                </div>
            </form>
        </div>
    );
}
export default SearchForm;
