/* eslint-disable import/no-anonymous-default-export */
import {
    GET_SUPPLIER_OUTDATED_SUCCESS,
    GET_SUPPLIER_OUTDATED_FAIL,
    GET_SUPPLIER_OUTDATED_LISTING,
    GET_MAPPING_NUMBER_SUCCESS,
    GET_MAPPING_NUMBER_FAIL,
    GET_MAPPING_NUMBER_LISTING,
    GET_SUPPLIER_EXCLUSIVE_CONTENT_SUCCESS,
    GET_SUPPLIER_EXCLUSIVE_CONTENT_LISTING,
    GET_SUPPLIER_EXCLUSIVE_CONTENT_FAIL,
    GET_TOP_SUPPLIER_SUCCESS,
    GET_TOP_SUPPLIER_LISTING,
    GET_TOP_SUPPLIER_FAIL,
    GET_INVENTORY_COUNT_SUCCESS,
    GET_INVENTORY_COUNT_LISTING,
    GET_INVENTORY_COUNT_FAIL,
    GET_INVENTORY_UNMAPPED_SUCCESS,
    GET_INVENTORY_UNMAPPED_LISTING,
    GET_INVENTORY_UNMAPPED_FAIL,
    TIME_SERIE_MAPPED_RATE_LISTING,
    TIME_SERIE_MAPPED_RATE_SUCCESS,
    TIME_SERIE_MAPPED_RATE_FAIL,
    TIME_SERIE_INVENTORY_MAPPED_RATE_LISTING,
    TIME_SERIE_INVENTORY_MAPPED_RATE_SUCCESS,
    TIME_SERIE_INVENTORY_MAPPED_RATE_FAIL,
    GET_COMPLETED_STEPS_LISTING,
    GET_COMPLETED_STEPS_SUCCESS,
    GET_COMPLETED_STEPS_FAIL,
} from "../types";

const initialState = {
    mappingNumber: [],
    loadingMappingNumber: false,
    supplierOutdated: [],
    loadingSupplierOutdated: false,
    supplierExclusiveContent: {},
    loadingSupplierExclusiveContent: false,
    topSupplier: [],
    loadingTopSupplier: false,
    loadingInventoryCount: false,
    inventoryCount: [],
    inventoryUnmapped: [],
    loadingInventoryUnmapped: false,
    timeSerieMapppedRate: [],
    loadingTimeSerieMappedRate: false,
    timeSerieInventoryMapppedRate: [],
    loadingTimeSerieInventoryMappedRate: false,
    partnerSteps: {},
    loadingPartnerSteps: true,
    totalInventoryUnmapped: 0,
    totalInventoryCount: 0,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_SUPPLIER_OUTDATED_LISTING:
            return {
                ...state,
                loadingSupplierOutdated: true,
            };
        case GET_SUPPLIER_OUTDATED_SUCCESS:
            return {
                ...state,
                supplierOutdated: action.payload,
                loadingSupplierOutdated: false,
            };
        case GET_SUPPLIER_OUTDATED_FAIL:
            return {
                ...state,
                loadingSupplierOutdated: false,
            };
        case GET_MAPPING_NUMBER_LISTING:
            return {
                ...state,
                loadingMappingNumber: true,
            };
        case GET_MAPPING_NUMBER_SUCCESS:
            return {
                ...state,
                mappingNumber: action.payload,
                loadingMappingNumber: false,
            };
        case GET_MAPPING_NUMBER_FAIL:
            return {
                ...state,
                loadingMappingNumber: false,
            };
        case GET_SUPPLIER_EXCLUSIVE_CONTENT_LISTING:
            return {
                ...state,
                loadingSupplierExclusiveContent: true,
            };
        case GET_SUPPLIER_EXCLUSIVE_CONTENT_SUCCESS:
            return {
                ...state,
                supplierExclusiveContent: action.payload,
                loadingSupplierExclusiveContent: false,
            };
        case GET_SUPPLIER_EXCLUSIVE_CONTENT_FAIL:
            return {
                ...state,
                loadingSupplierExclusiveContent: false,
            };
        case GET_TOP_SUPPLIER_LISTING:
            return {
                ...state,
                loadingTopSupplier: true,
            };
        case GET_TOP_SUPPLIER_SUCCESS:
            return {
                ...state,
                topSupplier: action.payload,
                loadingTopSupplier: false,
            };
        case GET_TOP_SUPPLIER_FAIL:
            return {
                ...state,
                loadingTopSupplier: false,
            };
        case GET_INVENTORY_COUNT_LISTING:
            return {
                ...state,
                loadingInventoryCount: true,
            };
        case GET_INVENTORY_COUNT_SUCCESS:
            return {
                ...state,
                inventoryCount: action.payload,
                totalInventoryCount: action.total,
                loadingInventoryCount: false,
            };
        case GET_INVENTORY_COUNT_FAIL:
            return {
                ...state,
                loadingInventoryCount: false,
            };
        case GET_INVENTORY_UNMAPPED_LISTING:
            return {
                ...state,
                loadingInventoryUnmapped: true,
            };
        case GET_INVENTORY_UNMAPPED_SUCCESS:
            return {
                ...state,
                inventoryUnmapped: action.payload,
                totalInventoryUnmapped: action.total,
                loadingInventoryUnmapped: false,
            };
        case GET_INVENTORY_UNMAPPED_FAIL:
            return {
                ...state,
                loadingInventoryUnmapped: false,
            };
        case TIME_SERIE_MAPPED_RATE_LISTING:
            return {
                ...state,
                loadingTimeSerieMappedRate: true,
            };
        case TIME_SERIE_MAPPED_RATE_SUCCESS:
            return {
                ...state,
                timeSerieMapppedRate: action.payload,
                loadingTimeSerieMappedRate: false,
            };
        case TIME_SERIE_MAPPED_RATE_FAIL:
            return {
                ...state,
                loadingTimeSerieMappedRate: false,
                timeSerieMapppedRate: [],
            };
        case TIME_SERIE_INVENTORY_MAPPED_RATE_LISTING:
            return {
                ...state,
                loadingTimeSerieInventoryMappedRate: true,
            };
        case TIME_SERIE_INVENTORY_MAPPED_RATE_SUCCESS:
            return {
                ...state,
                timeSerieInventoryMapppedRate: action.payload,
                loadingTimeSerieInventoryMappedRate: false,
            };
        case TIME_SERIE_INVENTORY_MAPPED_RATE_FAIL:
            return {
                ...state,
                loadingTimeSerieInventoryMappedRate: false,
                timeSerieInventoryMapppedRate: [],
            };
        case GET_COMPLETED_STEPS_LISTING:
            return {
                ...state,
                loadingPartnerSteps: true,
                partnerSteps: {},
            };
        case GET_COMPLETED_STEPS_SUCCESS:
            return {
                ...state,
                partnerSteps: action.payload,
                loadingPartnerSteps: false,
            };
        case GET_COMPLETED_STEPS_FAIL:
            return {
                ...state,
                loadingPartnerSteps: false,
                partnerSteps: {},
            };
        default:
            return state;
    }
}
