import React from "react";
import { Modal } from "react-bootstrap";
import Select from "../../../components/Select";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { getPartnerAttributes } from "../../../redux/actions/PartnerActions";
import AddPartnerAttribute from "../../../components/AddPartnerAttribute";

function Step1(props) {
    const {
        handleClick,
        setSelectedPartner,
        selectedPartner,
        setPartnerName,
        partnerName,
        addPartner,
        setAddPartner,
        loadingCreation,
        showPartnerAttributes,
        setShowPartnerAttributes,
        listAttributes,
        setListAttributes,
        loadingSaveAttributes,
        handleClose,
    } = props;
    const dispatch = useDispatch();
    const { partnerList } = useSelector((state) => ({
        partnerList: state.partnerReducer.partners ?? [],
    }));

    return (
        <>
            <Modal.Body>
                <div className="row">
                    <div className="col-lg-6">
                        {addPartner ? (
                            <fieldset className="scheduler-border">
                                <legend className="scheduler-border">
                                    <h3 className="card-title">
                                        Add new partner
                                    </h3>
                                </legend>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <label for="partner_name">
                                                Partner name
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Partner name"
                                                name="partner_name"
                                                value={partnerName ?? ""}
                                                onChange={(e) => {
                                                    setPartnerName(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                    }}
                                >
                                    Already exists ? &nbsp;
                                    <Link
                                        onClick={() => {
                                            setAddPartner(false);
                                            setSelectedPartner(null);
                                            setListAttributes([]);
                                        }}
                                    >
                                        Select partner
                                    </Link>
                                </div>
                            </fieldset>
                        ) : (
                            <fieldset className="scheduler-border">
                                <legend className="scheduler-border">
                                    <h3 className="card-title">
                                        Select existing partner
                                    </h3>
                                </legend>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label for="selectedPartnerId">
                                            Select partner
                                        </label>
                                        <>
                                            <div style={{ display: "flex" }}>
                                                <div style={{ flexGrow: 1 }}>
                                                    <Select
                                                        options={partnerList?.map(
                                                            (e) => ({
                                                                ...e,
                                                                value: e?.id,
                                                                label: e?.name,
                                                            })
                                                        )}
                                                        isClearable={true}
                                                        name="selectedPartnerId"
                                                        id="selectedPartnerId"
                                                        value={selectedPartner}
                                                        onChange={(e) => {
                                                            dispatch(
                                                                getPartnerAttributes(
                                                                    e?.value
                                                                )
                                                            );
                                                            setSelectedPartner(
                                                                e
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                    }}
                                >
                                    Don't find it ? &nbsp;
                                    <Link
                                        onClick={() => {
                                            setAddPartner(true);
                                            setPartnerName("");
                                            setListAttributes([]);
                                        }}
                                    >
                                        Add new partner
                                    </Link>
                                </div>
                            </fieldset>
                        )}
                    </div>
                    <div className="col-lg-12" style={{ textAlign: "center" }}>
                        <Link
                            className="text-middle"
                            onClick={() =>
                                setShowPartnerAttributes(!showPartnerAttributes)
                            }
                        >
                            {showPartnerAttributes
                                ? "Hide  advanced Settings"
                                : "Show advanced Settings"}
                        </Link>
                    </div>
                    {showPartnerAttributes && (
                        <div className="col-lg-12">
                            <AddPartnerAttribute
                                // next={next}
                                listAttributes={listAttributes}
                                setListAttributes={setListAttributes}
                                loadingSaveAttributes={loadingSaveAttributes}
                                hideButton={true}
                            />
                        </div>
                    )}
                    <div className="col-lg-12">
                        <Modal.Footer>
                            <button
                                type="button"
                                className="btn btn-light"
                                onClick={handleClose}
                            >
                                Close
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={handleClick}
                                disabled={loadingCreation}
                            >
                                Next &nbsp;&nbsp;
                                <i className="fas fa-caret-right"></i>
                                {loadingCreation && (
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                )}
                            </button>
                        </Modal.Footer>
                    </div>
                </div>
            </Modal.Body>
        </>
    );
}
export default Step1;

// showPartnerAttributes={showPartnerAttributes}
// setShowPartnerAttributes={setShowPartnerAttributes}
// listAttributes={listAttributes}
// setListAttributes={setListAttributes}
// loadingSaveAttributes={loadingSaveAttributes}
