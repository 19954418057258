import React from "react";
import Table from "../Table/Table";
import { useSelector } from "react-redux";

const PropertyTypeList = () => {
    const { propertyTypeReportList, loadingPropertyType } = useSelector(
        (state) => ({
            propertyTypeReportList:
                state.partnerReportReducer.propertyTypeReportList ?? [],
            loadingPropertyType: state.partnerReportReducer.loadingPropertyType,
        })
    );
    const columns = [
        {
            id: "catalog_type",
            name: <div className="table-title">Type</div>,
            selector: "catalog_type",
            cell: (row) => <div>{row.catalog_type}</div>,
            sortable: true,
        },
        {
            id: "mapped_hotels",
            name: <div className="table-title">Record count</div>,
            selector: "mapped_hotels",
            cell: (row) => <div>{row.mapped_hotels}</div>,
            sortable: true,
        },
    ];
    return (
        <div className="responsive-table-container">
            <Table
                columns={columns}
                data={propertyTypeReportList}
                paginationPerPage={4}
                progressPending={loadingPropertyType}
            />
        </div>
    );
};

export default PropertyTypeList;
