import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { MenuItem, ControlledMenu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import { getDestinationCredentials } from "../../redux/actions/DestinationActions";
import { useDispatch } from "react-redux";

function CustomMenu(props) {
    const { currentElement, handleDelete } = props;
    const dispatch = useDispatch();
    const ref = useRef();
    const [isOpen, setOpen] = useState(false);
    const history = useHistory();
    return (
        <>
            <div
                ref={ref}
                className="btn"
                onClick={(event) => {
                    event.stopPropagation();
                    setOpen(true);
                }}
                style={{
                    cursor: "pointer",
                    justifyContent: "flex-end",
                    display: "flex",
                }}
            >
                <i className="fas fa-ellipsis-v"></i>{" "}
            </div>
            <ControlledMenu
                state={isOpen ? "open" : "closed"}
                anchorRef={ref}
                onClose={() => setOpen(false)}
            >
                <MenuItem
                    onClick={async (event) => {
                        if (currentElement?.configured) {
                            await dispatch(
                                getDestinationCredentials(currentElement?.id)
                            );
                        }
                        history.push(
                            `/export-destinations/${currentElement?.id}`,
                            {
                                params: {
                                    form: currentElement?.form,
                                    description: currentElement?.description,
                                    name: currentElement?.name,
                                    id: currentElement?.id,
                                    updateCredentials:
                                        currentElement?.configured
                                            ? true
                                            : false,
                                },
                            }
                        );
                    }}
                >
                    {currentElement?.configured
                        ? "Edit configuration"
                        : "Configure"}
                </MenuItem>
                {currentElement?.configured && (
                    <MenuItem onClick={handleDelete}>
                        Delete configuration
                    </MenuItem>
                )}
            </ControlledMenu>
        </>
    );
}
export default CustomMenu;
