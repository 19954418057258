/* eslint-disable import/no-anonymous-default-export */
import {
    GET_PAYMENT_CARDS_SUCCESS,
    GET_PAYMENT_CARDS_LISTING,
    GET_PAYMENT_CARDS_FAIL
} from '../types';
const initialState = {
    last4: undefined,
    loading:false,
    waitData : true ,
}

export default function(state = initialState, action){
    switch(action.type){
        case GET_PAYMENT_CARDS_LISTING:
            return {
                ...state,
                loading:true
            }  
        case GET_PAYMENT_CARDS_SUCCESS:
            return {
                ...state,
                last4 :action.payload.last4,
                loading:false,
                waitData:false,
            }     
        case GET_PAYMENT_CARDS_FAIL:
            return {
                ...state,
                loading:false
            }   
        default: return state
    }

}