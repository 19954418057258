import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { FILE_FORMAT, DATABASE, WEBHOOK } from "../../utilis/const";
import { getDestinations } from "../../redux/actions/DestinationActions";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import PartnerService from "../../services/PartnerService";
import { toast } from "react-toastify";
import Launching from "../../lotties/launching.gif";
import SyncLoader from "react-spinners/SyncLoader";

function MappingDestinationModal({
    show,
    handleClose,
    dataCell,
    addOriginPath,
}) {
    const dispatch = useDispatch();
    const { destinationsList, loading } = useSelector((state) => ({
        destinationsList:
            state.destinationReducer?.destinationList?.data?.filter(
                (e) => e?.configured === true
            ) ?? [],
        loading: state.destinationReducer?.loading,
    }));
    const [displayedData, setDisplayedData] = React.useState(destinationsList);

    React.useEffect(() => {
        setDisplayedData(destinationsList);
    }, [loading]);

    React.useEffect(() => {
        dispatch(getDestinations());
    }, []);

    const renderElement = (name, type) => {
        return (
            <fieldset className="choose-destination">
                <legend className="scheduler-border">{name}</legend>
                <div className="row">
                    {displayedData
                        ?.filter((e) => e?.data_type === type)
                        ?.map((e, index) => {
                            return (
                                <Destination
                                    element={e}
                                    dataCell={dataCell}
                                    handleClose={handleClose}
                                    addOriginPath={addOriginPath}
                                />
                            );
                        })}
                </div>
            </fieldset>
        );
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            dialogClassName={"modal-50w modal-dialog modal-dialog-centered"}
        >
            <Modal.Header
                style={{
                    display: "flex",
                    justify: "space-between",
                    color: "#32325d",
                    fontWeight: "600",
                    fontSize: "20px",
                }}
            >
                <div>Export Data</div>
                <div>
                    <button className="btn" onClick={handleClose}>
                        <i className="fas fa-times"></i>
                    </button>
                </div>
            </Modal.Header>
            <Modal.Body>
                {displayedData?.length > 0 ? (
                    <>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                // marginBottom: "10px",
                            }}
                        >
                            <span className="sub-title-info">
                                You can configure more data export in{" "}
                                <Link
                                    to="/export-destinations"
                                    style={{ marginLeft: "4px" }}
                                >
                                    Export data{" "}
                                </Link>
                            </span>
                        </div>
                        {displayedData?.filter(
                            (e) => e?.data_type === FILE_FORMAT
                        )?.length > 0 &&
                            renderElement("File format", FILE_FORMAT)}
                        {displayedData?.filter((e) => e?.data_type === WEBHOOK)
                            ?.length > 0 && renderElement("Webhook", WEBHOOK)}
                        {displayedData?.filter((e) => e?.data_type === DATABASE)
                            ?.length > 0 && renderElement("Database", DATABASE)}
                    </>
                ) : (
                    <div style={{ textAlign: "center" }}>
                        <img
                            src={Launching}
                            height="200"
                            width="200"
                            alt="Destinations"
                        />
                        <h3
                            style={{ fontWeight: "bold", marginBottom: "10px" }}
                        >
                            You can configure your data export in{" "}
                            <Link
                                to="/export-destinations"
                                style={{ marginLeft: "4px" }}
                            >
                                Export data
                            </Link>
                        </h3>
                    </div>
                )}
            </Modal.Body>
        </Modal>
    );
}
export default MappingDestinationModal;

const Destination = (props) => {
    const { element, dataCell, handleClose, addOriginPath } = props;
    const [pending, setPending] = useState(false);
    return (
        <div className="col-6 col-md-4 col-lg-3">
            <div
                className="integration-entity mx-auto"
                style={{ marginBottom: "1rem" }}
            >
                <div
                    className={`integration-entity-img-wrapper ${
                        pending ? "zoomImagett" : ""
                    }`}
                >
                    {pending ? (
                        <SyncLoader
                            color="#5856d694"
                            loading={true}
                            size={10}
                        />
                    ) : (
                        <img
                            id={element?.id}
                            className="cloudinary-image ls-is-cached lazyloaded"
                            alt={element?.name}
                            src={
                                addOriginPath
                                    ? "../" + element?.logo
                                    : element?.logo
                            }
                            height="60"
                            width="60"
                            onClick={async () => {
                                try {
                                    setPending(true);
                                    const res =
                                        await PartnerService.exportPartnerCatalog(
                                            element?.id,
                                            dataCell?.id
                                        );
                                    if (res.status === 200) {
                                        toast.success(
                                            `Successfully exported to ${element?.name}`
                                        );
                                        handleClose();
                                    } else {
                                        toast.error(res.message);
                                    }
                                    setPending(false);
                                } catch (error) {
                                    console.log("Error", error);
                                    setPending(false);
                                }
                            }}
                            disabled={pending}
                        />
                    )}
                </div>

                <div className="integration-entity-name">{element?.name}</div>
            </div>
        </div>
    );
};
