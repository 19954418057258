import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { separator } from "../../utilis/functions";
import { STATUS_PENDING } from "../../utilis/const";
import moment from "moment";
import Table from "../../components/Table/Table";
import { useHistory } from "react-router-dom";

const statusBadgeVariant = (status) => {
    switch (status) {
        case 0:
            return {
                color: "warning",
                status: "Loading",
            };
        case 1:
            return {
                color: "info",
                status: "Processing",
            };
        case 2:
            return {
                color: "success",
                status: "Mapped",
            };
        case 3:
            return {
                color: "danger",
                status: "Failed",
            };
        case 4:
            return {
                color: "cyan",
                status: "In queue",
            };
        default:
            return {
                color: "",
                status: "",
            };
    }
};

const PartnerDetailsTable = (props) => {
    const { dataList } = props;
    const history = useHistory();

    const renderTooltip = (name) => (props) =>
        (
            <Tooltip id="button-tooltip" {...props}>
                {name}
            </Tooltip>
        );

    const columns = [
        {
            name: <div className="table-title">Partner</div>,
            sortable: true,
            selector: (e) => e.partner_name,
            cell: (e) => {
                return (
                    <>
                        {e?.affiliate_id > 0 && (
                            <OverlayTrigger
                                placement="top"
                                delay={{
                                    show: 250,
                                    hide: 400,
                                }}
                                overlay={renderTooltip("Affiliate")}
                            >
                                <i
                                    className="fas fa-handshake"
                                    style={{
                                        marginRight: "5px",
                                        color: "#818181",
                                    }}
                                />
                            </OverlayTrigger>
                        )}

                        {e?.has_mapping_error && (
                            <OverlayTrigger
                                placement="top"
                                delay={{
                                    show: 250,
                                    hide: 400,
                                }}
                                overlay={renderTooltip(
                                    " Warning ! A mapping error was removed from this catalog's mapping. Take necessary action"
                                )}
                            >
                                <i
                                    className="fas fa-exclamation-triangle"
                                    style={{
                                        color: "rgb(254, 192, 106)",
                                        marginRight: "5px",
                                    }}
                                ></i>
                            </OverlayTrigger>
                        )}
                        <button
                            className="btn text-link-button2"
                            onClick={() =>
                                history.push(
                                    `/provider-content/details/${e?.partner_id}`,
                                    {
                                        params: {
                                            partner: {
                                                ...e,
                                                id: e.partner_id,
                                                affiliate_id: 0,
                                            },
                                        },
                                    }
                                )
                            }
                        >
                            {e?.partner_name}
                        </button>
                    </>
                );
            },
        },
        {
            name: <div className="table-title">Total hotels</div>,
            sortable: true,
            selector: (e) => e.total_hotels,
            cell: (e) => {
                return (
                    <>
                        {e?.total_hotels ? separator(e?.total_hotels) : 0}
                        <br />
                        {e?.hotels_rate !== 0 &&
                            e?.hotels_rate !== undefined &&
                            e?.hotels_rate !== null &&
                            e?.mapping_process_status_id === STATUS_PENDING && (
                                <>
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip("Rate/Sec")}
                                    >
                                        <small
                                            style={{
                                                color: "#21CA7F",
                                                fontWeight: "900",
                                                marginLeft: "5px",
                                            }}
                                        >
                                            {e?.hotels_rate}/Sec
                                        </small>
                                    </OverlayTrigger>
                                </>
                            )}
                    </>
                );
            },
        },

        {
            name: <div className="table-title">Mapped</div>,
            sortable: true,
            selector: (e) => e.mapped_hotels,
            cell: (e) => {
                const mapped_hotels = e?.mapped_hotels ?? 0;
                const total_hotels = e?.total_hotels ?? 0;
                const pourcentageMappedHotels =
                    total_hotels !== 0
                        ? (mapped_hotels / total_hotels) * 100
                        : 0;

                return (
                    <>
                        {`${pourcentageMappedHotels.toFixed(0)}% (${
                            e?.mapped_hotels ? separator(e?.mapped_hotels) : 0
                        })`}
                    </>
                );
            },
        },
        {
            name: <div className="table-title">Opportunities</div>,
            sortable: true,
            selector: (e) => e.unmapped_hotels,
            cell: (e) => {
                const unmapped_hotels = e?.unmapped_hotels ?? 0;
                const total_hotels = e?.total_hotels ?? 0;
                const pourcentageUnmappedHotels =
                    total_hotels !== 0
                        ? (unmapped_hotels / e?.total_hotels) * 100
                        : 0;

                return (
                    <>{`${pourcentageUnmappedHotels.toFixed(0)}% (${
                        e?.unmapped_hotels ? separator(e?.unmapped_hotels) : 0
                    })`}</>
                );
            },
        },
        {
            name: <div className="table-title">Valid</div>,
            sortable: true,
            selector: (e) => e.valid_hotels,
            cell: (e) => {
                return <>{e?.valid_hotels ? separator(e?.valid_hotels) : 0}</>;
            },
        },
        {
            name: <div className="table-title">Invalid</div>,
            sortable: true,
            selector: (e) => e.invalid_hotels,
            cell: (e) => {
                return (
                    <>{e?.invalid_hotels ? separator(e?.invalid_hotels) : 0}</>
                );
            },
        },
        {
            name: <div className="table-title">Status</div>,
            sortable: true,
            selector: (e) => e.mapping_process_status_id,
            cell: (e) => {
                return (
                    <span
                        text="white"
                        className={`badge rounded-pill text-white bg-${
                            statusBadgeVariant(e?.mapping_process_status_id)
                                ?.color
                        }`}
                    >
                        {
                            statusBadgeVariant(e?.mapping_process_status_id)
                                ?.status
                        }
                    </span>
                );
            },
        },
        {
            name: <div className="table-title">Created</div>,
            sortable: true,
            selector: (e) => e.created_at,
            cell: (e) => {
                return (
                    <>
                        {moment(new Date(e?.created_at)).format(
                            "DD/MM/YYYY HH:mm:ss"
                        )}
                    </>
                );
            },
        },
    ];
    return (
        <div className="card">
            <div className="card-body">
                <div className="table-responsive div-tooltip-catalog-partner">
                    <Table
                        data={dataList}
                        rowsPerPage={10}
                        columns={columns}
                        // loading={loading}
                        // conditionalRowStyles={conditionalRowStyles}
                    />
                </div>
            </div>
        </div>
    );
};

export default PartnerDetailsTable;
