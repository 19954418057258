import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "../../../components/Select";
import InputAutoComplete from "./AutoComplete";
import { getHotelsCandidates } from "../../../redux/actions/ManualMappingActions";

const SearchForm = ({ filters, setFilters, catalog_id ,token}) => {
    const { filtersValues } = useSelector((state) => ({
        filtersValues: state.manualMappingReducer.filterValues?.data ?? [],
    }));
    const dispatch = useDispatch();

    return (
        <div className="card">
            <div className="card-header p-0 pb-2">
                <h4
                    style={{
                        marginLeft: "10px",
                        marginTop: "10px",
                        fontWeight: "500",
                    }}
                >
                    Search By :
                </h4>
            </div>
            <div className="card-body" style={{ padding: "10px" }}>
                <div className="row">
                    {filtersValues?.map((e, index) => {
                        return (
                            <div
                                className="col-lg-3 col-md-12"
                                style={{
                                    paddingTop: "10px",
                                    margin: "0px",
                                }}
                                key={index}
                            >
                                {e?.autocomplete ? (
                                    <InputAutoComplete
                                        catalog_id={catalog_id}
                                        isClearable={true}
                                        name={e?.column}
                                        value={filters?.[e?.column]}
                                        filter={e?.column}
                                        placeholder={`Start typing for ${e?.name} ...`}
                                        token={token}
                                        onChange={(value) => {
                                            setFilters({
                                                ...filters,
                                                [e?.column]: value,
                                            });
                                        }}
                                    />
                                ) : (
                                    <Select
                                        options={e?.values?.map((v, i) => ({
                                            label: v,
                                            value: i,
                                        }))}
                                        name={e?.column}
                                        value={filters?.[e?.column]}
                                        placeholder={e?.name}
                                        isMulti
                                        isClearable={true}
                                        onChange={(element) => {
                                            setFilters({
                                                ...filters,
                                                [e?.column]: element,
                                            });
                                        }}
                                    />
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
            <div
                className="card-footer"
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    padding: "8px",
                }}
            >
                <button
                    className="btn waves-effect waves-light btn-rounded btn-warning text-white"
                    style={{ marginTop: "0px" }}
                    type="button"
                    onClick={async () => {
                        setFilters({
                            city: [],
                            country: [],
                            stars: [],
                            type: [],
                            hotel_code: [],
                            hotel_name: [],
                            zip_code: [],
                            chain: [],
                        });

                        await dispatch(getHotelsCandidates(catalog_id, {}, 1, 10,null,null,token));
                    }}
                >
                    Reset
                </button>
                &nbsp;
                <button
                    className="btn waves-effect waves-light btn-rounded btn-primary"
                    style={{ marginTop: "0px" }}
                    onClick={async () => {
                        await dispatch(getHotelsCandidates(catalog_id, filters, 1, 10,null,null,token));
                    }}
                >
                    Search
                </button>
            </div>
        </div>
    );
};
export default SearchForm;
