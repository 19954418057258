import React, { useEffect } from "react";
import { separator } from "../../utilis/functions";
import moment from "moment";
import { PieChart } from "react-minimal-pie-chart";
import { getAffiliateHeaderDetails } from "../../redux/actions/PartnerActions";
import { useDispatch, useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const AffiliateHeaderDetails = (props) => {
    const { partner } = props;
    const dispatch = useDispatch();
    const { affiliateHeaderDetails, loadingAffiliateHeaderDetails } =
        useSelector((state) => ({
            affiliateHeaderDetails:
                state?.partnerReducer?.affiliateHeaderDetails?.data ?? {},
            loadingAffiliateHeaderDetails:
                state?.partnerReducer?.loadingAffiliateHeaderDetails ?? false,
        }));

    useEffect(() => {
        dispatch(
            getAffiliateHeaderDetails(partner?.id, partner?.affiliate_id ?? 0)
        );
    }, []);

    const renderTooltip = (text) => (props) =>
        (
            <Tooltip id={`button-tooltip-${text}`} {...props}>
                {text}
            </Tooltip>
        );

    return (
        <>
            {!loadingAffiliateHeaderDetails && (
                <>
                    <div
                        className="col-lg-3 col-md-12 home"
                        style={{ paddingRight: "0px" }}
                    >
                        <div
                            className={`card boxShadow card-info card-partner-details`}
                            style={{
                                borderRadius: "0.75rem",
                            }}
                        >
                            <div
                                className="card-body"
                                style={{
                                    padding: "28px",
                                }}
                            >
                                <div className="d-flex d-lg-flex d-md-block align-items-center">
                                    <div>
                                        <h2
                                            className="text-dark mb-1 w-100 text-truncate font-weight-medium"
                                            style={{
                                                fontSize: "20px",
                                                marginTop: "8px",
                                            }}
                                        >
                                            Mapping Rate
                                        </h2>
                                        <h6 className="text-muted font-weight-bold mb-0 w-100 text-truncate">
                                            (
                                            {`Last update :  ${
                                                affiliateHeaderDetails?.created_at
                                                    ? moment(
                                                          affiliateHeaderDetails?.created_at,
                                                          "YYYYMMDD HH:mm:ss Z"
                                                      ).fromNow()
                                                    : "--"
                                            }`}
                                            )
                                        </h6>
                                        <h6
                                            style={{
                                                marginBottom: "9px",
                                            }}
                                        ></h6>
                                    </div>
                                    <div className="ml-auto mt-md-3 mt-lg-0">
                                        <span className="opacity-7 text-muted">
                                            <PieChart
                                                style={{
                                                    height: "58px",
                                                }}
                                                data={[
                                                    {
                                                        value:
                                                            (affiliateHeaderDetails?.affiliate_mappted
                                                                ? affiliateHeaderDetails?.affiliate_mappted *
                                                                  100
                                                                : 0
                                                            ).toFixed(0) ?? 0,

                                                        title: `${
                                                            (affiliateHeaderDetails?.affiliate_mappted
                                                                ? affiliateHeaderDetails?.affiliate_mappted *
                                                                  100
                                                                : 0
                                                            )?.toFixed(0) ?? 0
                                                        }%`,
                                                        color: "rgb(21, 191, 174)",
                                                    },
                                                ]}
                                                totalValue={100}
                                                lineWidth={20}
                                                label={({ dataEntry }) =>
                                                    dataEntry.title
                                                }
                                                labelStyle={{
                                                    fontSize: "25px",
                                                    fontFamily: "sans-serif",
                                                    fill: "rgb(21, 191, 174)",
                                                }}
                                                labelPosition={0}
                                            />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="col-lg-3 col-md-12 home"
                        style={{ paddingRight: "0px" }}
                    >
                        <div
                            className={`card boxShadow card-info card-partner-details`}
                            style={{
                                borderRadius: "0.75rem",
                            }}
                        >
                            <div
                                className="card-body"
                                style={{
                                    padding: "28px",
                                }}
                            >
                                <div className="d-flex d-md-block align-items-center">
                                    <div>
                                        <h2
                                            className="text-dark mb-1 w-100 text-truncate font-weight-medium"
                                            style={{
                                                fontSize: "18px",
                                            }}
                                        >
                                            Inventory
                                        </h2>

                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                marginBottom: "4px",
                                            }}
                                        >
                                            <div>
                                                <OverlayTrigger
                                                    placement="top"
                                                    delay={{
                                                        show: 250,
                                                        hide: 400,
                                                    }}
                                                    overlay={renderTooltip(
                                                        `Total hotels in ${partner?.name}'s inventory`
                                                    )}
                                                >
                                                    <h6 className="text-muted font-weight-bold mb-0 w-100 text-truncate">
                                                        {partner?.name.length >
                                                        6 ? (
                                                            <>
                                                                {`Total hotels in ${partner?.name.substring(
                                                                    0,
                                                                    4
                                                                )}...`}
                                                            </>
                                                        ) : (
                                                            <>
                                                                {`Total hotels in ${
                                                                    partner?.name ??
                                                                    ""
                                                                }'s inventory`}
                                                            </>
                                                        )}
                                                    </h6>
                                                </OverlayTrigger>
                                            </div>
                                            <div>
                                                <h6 className="text-muted font-weight-bold mb-0 w-100 text-truncate">
                                                    {affiliateHeaderDetails?.affiliate_total_hotels
                                                        ? separator(
                                                              affiliateHeaderDetails?.affiliate_total_hotels
                                                          )
                                                        : 0}
                                                </h6>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <div>
                                                <OverlayTrigger
                                                    placement="bottom"
                                                    delay={{
                                                        show: 250,
                                                        hide: 400,
                                                    }}
                                                    overlay={renderTooltip(
                                                        `Hotels shared with ${
                                                            partner?.name ?? ""
                                                        }`
                                                    )}
                                                >
                                                    <h6 className="text-muted font-weight-bold mb-0 w-100 text-truncate">
                                                        {partner?.name.length >
                                                        6 ? (
                                                            <>
                                                                {`Hotels shared with ${partner?.name.substring(
                                                                    0,
                                                                    4
                                                                )}...`}
                                                            </>
                                                        ) : (
                                                            <>
                                                                {`Hotels shared with ${
                                                                    partner?.name ??
                                                                    ""
                                                                }`}
                                                            </>
                                                        )}
                                                    </h6>
                                                </OverlayTrigger>
                                            </div>
                                            <div>
                                                <h6 className="text-muted font-weight-bold mb-0 w-100 text-truncate">
                                                    {affiliateHeaderDetails?.total_hotels
                                                        ? separator(
                                                              affiliateHeaderDetails?.total_hotels
                                                          )
                                                        : 0}
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="col-lg-3 col-md-12 home"
                        style={{ paddingRight: "0px" }}
                    >
                        <div
                            className={`card boxShadow card-info card-partner-details`}
                            style={{
                                borderRadius: "0.75rem",
                            }}
                        >
                            <div
                                className="card-body"
                                style={{
                                    padding: "28px",
                                }}
                            >
                                <div className="d-flex d-md-block align-items-center">
                                    <div>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <div>
                                                <h2
                                                    className="text-dark mb-1 w-100 text-truncate font-weight-medium"
                                                    style={{
                                                        fontSize: "18px",
                                                    }}
                                                >
                                                    Mapped
                                                </h2>
                                            </div>
                                            <div>
                                                <h2
                                                    className="text-dark mb-1 w-100 text-truncate font-weight-medium"
                                                    style={{
                                                        fontSize: "18px",
                                                    }}
                                                >
                                                    {affiliateHeaderDetails?.mapped_hotels
                                                        ? separator(
                                                              affiliateHeaderDetails?.mapped_hotels
                                                          )
                                                        : 0}
                                                </h2>
                                            </div>
                                        </div>

                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                marginBottom: "4px",
                                            }}
                                        >
                                            <div>
                                                <OverlayTrigger
                                                    placement="top"
                                                    delay={{
                                                        show: 250,
                                                        hide: 400,
                                                    }}
                                                    overlay={renderTooltip(
                                                        `${partner?.name} inventory`
                                                    )}
                                                >
                                                    <h6 className="text-muted font-weight-bold mb-0 w-100 text-truncate">
                                                        {partner?.name.length >
                                                        12 ? (
                                                            <>
                                                                {`${partner?.name.substring(
                                                                    0,
                                                                    9
                                                                )}... inventory`}
                                                            </>
                                                        ) : (
                                                            <>
                                                                {`${
                                                                    partner?.name ??
                                                                    ""
                                                                } inventory`}
                                                            </>
                                                        )}
                                                    </h6>
                                                </OverlayTrigger>
                                            </div>
                                            <div>
                                                <span
                                                    class="badge bg-primary font-12 text-white font-weight-medium badge-pill ml-2 d-md-none d-lg-block"
                                                    style={{
                                                        padding: "2px 10px",
                                                    }}
                                                >
                                                    {`${(affiliateHeaderDetails?.affiliate_mappted
                                                        ? affiliateHeaderDetails?.affiliate_mappted *
                                                          100
                                                        : 0
                                                    )?.toFixed(0)} %`}
                                                </span>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <div>
                                                <h6 className="text-muted font-weight-bold mb-0 w-100 text-truncate">
                                                    Shared hotels
                                                </h6>
                                            </div>
                                            <div>
                                                <span
                                                    class="badge bg-primary font-12 text-white font-weight-medium badge-pill ml-2 d-md-none d-lg-block"
                                                    style={{
                                                        padding: "2px 10px",
                                                    }}
                                                >
                                                    {`${(affiliateHeaderDetails?.company_mappted
                                                        ? affiliateHeaderDetails?.company_mappted *
                                                          100
                                                        : 0
                                                    )?.toFixed(0)} %`}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="col-lg-3 col-md-12 home"
                        style={{ paddingRight: "0px" }}
                    >
                        <div
                            className={`card boxShadow card-info card-partner-details`}
                            style={{
                                borderRadius: "0.75rem",
                            }}
                        >
                            <div
                                className="card-body"
                                style={{
                                    padding: "28px",
                                }}
                            >
                                <div className="d-flex d-md-block align-items-center">
                                    <div>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <div>
                                                <h2
                                                    className="text-dark mb-1 w-100 text-truncate font-weight-medium"
                                                    style={{
                                                        fontSize: "18px",
                                                    }}
                                                >
                                                    Opportunities
                                                </h2>
                                            </div>
                                            <div>
                                                <h2
                                                    className="text-dark mb-1 w-100 text-truncate font-weight-medium"
                                                    style={{
                                                        fontSize: "18px",
                                                    }}
                                                >
                                                    {affiliateHeaderDetails?.unmapped_hotels
                                                        ? separator(
                                                              affiliateHeaderDetails?.unmapped_hotels
                                                          )
                                                        : 0}
                                                </h2>
                                            </div>
                                        </div>

                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                marginBottom: "4px",
                                            }}
                                        >
                                            <div>
                                                <OverlayTrigger
                                                    placement="top"
                                                    delay={{
                                                        show: 250,
                                                        hide: 400,
                                                    }}
                                                    overlay={renderTooltip(
                                                        `${partner?.name} inventory`
                                                    )}
                                                >
                                                    <h6 className="text-muted font-weight-bold mb-0 w-100 text-truncate">
                                                        {partner?.name.length >
                                                        12 ? (
                                                            <>
                                                                {`${partner?.name.substring(
                                                                    0,
                                                                    9
                                                                )}... inventory`}
                                                            </>
                                                        ) : (
                                                            <>
                                                                {`${
                                                                    partner?.name ??
                                                                    ""
                                                                } inventory`}
                                                            </>
                                                        )}
                                                    </h6>
                                                </OverlayTrigger>
                                            </div>
                                            <div>
                                                <span
                                                    class="badge bg-danger font-12 text-white font-weight-medium badge-pill ml-2 d-md-none d-lg-block"
                                                    style={{
                                                        padding: "2px 10px",
                                                    }}
                                                >
                                                    {`${(affiliateHeaderDetails?.affiliate_opportunities
                                                        ? affiliateHeaderDetails?.affiliate_opportunities *
                                                          100
                                                        : 0
                                                    )?.toFixed(0)} %`}
                                                </span>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <div>
                                                <h6 className="text-muted font-weight-bold mb-0 w-100 text-truncate">
                                                    Shared hotels
                                                </h6>
                                            </div>
                                            <div>
                                                <span
                                                    class="badge bg-danger font-12 text-white font-weight-medium badge-pill ml-2 d-md-none d-lg-block"
                                                    style={{
                                                        padding: "2px 10px",
                                                    }}
                                                >
                                                    {`${(affiliateHeaderDetails?.company_opportunities
                                                        ? affiliateHeaderDetails?.company_opportunities *
                                                          100
                                                        : 0
                                                    )?.toFixed(0)} %`}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default AffiliateHeaderDetails;
