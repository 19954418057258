/* eslint-disable import/no-anonymous-default-export */
import {
    GET_FILTERS_LIST_LISTING,
    GET_FILTERS_LIST_SUCCESS,
    GET_FILTERS_LIST_FAIL,
    GET_HOTELS_CANDIDATES_LISTING,
    GET_HOTELS_CANDIDATES_SUCCESS,
    GET_HOTELS_CANDIDATES_FAIL,
    GET_HOTELS_HISTORY_LISTING,
    GET_HOTELS_HISTORY_SUCCESS,
    GET_HOTELS_HISTORY_FAIL,
} from "../types";

const initialState = {
    loadingFilterValues: true,
    filterValues: [],
    loadingHotelsCandidates: false,
    hotelsCandidates: [],
    loadingHotelsHistory: false,
    hotelsHistory: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_FILTERS_LIST_LISTING:
            return {
                ...state,
                loadingFilterValues: true,
            };
        case GET_FILTERS_LIST_SUCCESS:
            return {
                ...state,
                filterValues: action.payload,
                loadingFilterValues: false,
            };
        case GET_FILTERS_LIST_FAIL:
            return {
                ...state,
                loadingFilterValues: false,
            };
        case GET_HOTELS_CANDIDATES_LISTING:
            return {
                ...state,
                loadingHotelsCandidates: true,
            };
        case GET_HOTELS_CANDIDATES_SUCCESS:
            return {
                ...state,
                hotelsCandidates: action.payload,
                loadingHotelsCandidates: false,
            };
        case GET_HOTELS_CANDIDATES_FAIL:
            return {
                ...state,
                loadingHotelsCandidates: false,
            };
        case GET_HOTELS_HISTORY_LISTING:
            return {
                ...state,
                loadingHotelsHistory: true,
            };
        case GET_HOTELS_HISTORY_SUCCESS:
            return {
                ...state,
                hotelsHistory: action.payload,
                loadingHotelsHistory: false,
            };
        case GET_HOTELS_HISTORY_FAIL:
            return {
                ...state,
                loadingHotelsHistory: false,
            };

        default:
            return state;
    }
}
