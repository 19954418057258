import React, { useEffect, useState } from "react";
import Table from "../../components/Table/Table";
import AddAffiliatePartner from "./AddAffiliatePartner";
import { useDispatch, useSelector } from "react-redux";
import { getAffiliatePartnerList } from "../../redux/actions/AutoPilotActions";
import { getPartnerList } from "../../redux/actions/PartnerActions";
import { getCatalogs } from "../../redux/actions/CatalogActions";
import ToggleStatus from "./ToggleStatus";
import { Link, useHistory } from "react-router-dom";
import CatalogNoDataModal from "../../pages/Mapping/CatalogNoDataModal";
import {
    AFFILIATE,
    STATUS_PROCESSING,
    MANAGE_AFFILIATES,
    MANAGE_INVENTORY_SEGMENTS,
    CREATE_INVENTORY,
} from "../../utilis/const";
import { hasPermission } from "../../utilis/functions";
import { Tooltip, OverlayTrigger, Modal, Spinner } from "react-bootstrap";
import { getSegmentsList } from "../../redux/actions/SegmentActions";
import { toast } from "react-toastify";
import ConfirmAction from "../../components/ConfirmActionsModal";
import AutoPilotService from "../../services/AutoPilotService";
import { Helmet } from "react-helmet";
import ResendLogo from "../../lotties/resend.png";

function ManagePartnersScreen(props) {
    const [addAffiliatePartner, setAddAffiliatePartner] = useState(false);
    const [selectedElement, setSelectedElement] = useState();
    const [currentElement, setCurrentElement] = useState();
    const [updating, setUpdating] = useState(false);
    const [selectedPartnerId, setSelectedPartnerId] = useState();
    const [assignSegment, setAssignSegment] = useState(false);
    const [confirmReplaceAction, setConfirmReplaceAction] = useState(false);
    const [pendingConfirmAction, setPendingConfirmAction] = useState(false);
    const [addValues, setAddValues] = useState({});

    const dispatch = useDispatch();

    const history = useHistory();
    const { data, loading, activeCatalog, loadingCatalogs } = useSelector(
        (state) => ({
            data: state?.autoPilotReducer?.list ?? [],
            loading: state?.autoPilotReducer?.loading,
            activeCatalog: state.catalogReducer?.catalogs?.find(
                (e) => e.active === true
            ),
            loadingCatalogs: state?.catalogReducer?.loading,
        })
    );

    useEffect(() => {
        dispatch(getAffiliatePartnerList());
        dispatch(getPartnerList());
        dispatch(getCatalogs());
        dispatch(getSegmentsList());
    }, []);

    const renderTooltip = (props = (text) => (
        <Tooltip id="button-tooltip" {...props}>
            {text}
        </Tooltip>
    ));

    const columns = React.useMemo(() => {
        const required = [
            {
                name: <div className="table-title">Name</div>,
                selector: (e) => e?.details?.name,
                cell: (e) => {
                    return <>{e?.details?.name ?? ""}</>;
                },
                sortable: true,
            },
            {
                name: <div className="table-title">Partner</div>,
                selector: (e) => e?.partner?.name,
                cell: (e) => {
                    return <>{e?.partner?.name ?? ""}</>;
                },
                sortable: true,
            },
            {
                name: <div className="table-title">Email</div>,
                selector: (e) => e?.details?.email,
                cell: (e) => {
                    return <>{e?.details?.email ?? ""}</>;
                },
                sortable: true,
            },

            {
                name: <div className="table-title">Status</div>,
                selector: (e) => e?.invitation_accepted,
                sortable: true,
                cell: (e) => {
                    return (
                        <>
                            <span
                                text="white"
                                className={`badge rounded-pill bg-${
                                    e?.invitation_accepted && e?.active
                                        ? "success"
                                        : e?.invitation_accepted && !e?.active
                                        ? "light"
                                        : !e?.invitation_accepted &&
                                          !e?.invitation_sent
                                        ? "danger"
                                        : "warning"
                                }`}
                                style={{
                                    fontWeight: 700,
                                    fontSize: "13px",
                                    color:
                                        e?.invitation_accepted && !e?.active
                                            ? "#0000005c"
                                            : "white",
                                }}
                            >
                                {e?.invitation_accepted && e?.active
                                    ? "Active"
                                    : !e?.invitation_accepted &&
                                      !e?.invitation_sent
                                    ? "Declined"
                                    : e?.invitation_accepted && !e?.active
                                    ? "Inactive"
                                    : "Pending"}
                            </span>
                            {/* <ToggleStatus dataCell={e} /> */}
                        </>
                    );
                },
            },
            {
                name: <div className="table-title">Segment</div>,
                selector: (e) => e?.inventory_segment?.name,
                sortable: true,
                cell: (e) => {
                    return (
                        <>
                            {e?.inventory_segment?.name}
                            {hasPermission(MANAGE_INVENTORY_SEGMENTS) && (
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderTooltip("Assign segment")}
                                >
                                    <button
                                        className="btn"
                                        style={{ padding: "10px" }}
                                        onClick={() => {
                                            setCurrentElement(e);
                                            setAssignSegment(true);
                                        }}
                                    >
                                        <i className="fas fa-pencil-alt" />
                                    </button>
                                </OverlayTrigger>
                            )}
                        </>
                    );
                },
            },
        ];
        let extra = [];
        if (hasPermission(MANAGE_AFFILIATES)) {
            extra.push({
                name: <div className="table-title">Enable/Disable</div>,
                selector: (e) => e?.invitation_accepted,
                sortable: true,
                cell: (e) => {
                    return (
                        <>
                            {e?.invitation_accepted && (
                                <ToggleStatus dataCell={e} />
                            )}
                        </>
                    );
                },
            });
        }

        extra.push({
            name: <div className="table-title">Resend invitation</div>,
            cell: (e) => {
                return (
                    <>
                        {!e.invitation_accepted && !e?.invitation_sent && (
                            <ResendInvitation currentElement={e} />
                        )}
                    </>
                );
            },
        });
        extra.push({
            name: "",
            cell: (e) => {
                return (
                    <>
                        {e?.active && (
                            <Link
                                onClick={() => {
                                    history.push(
                                        `/affiliate/details/${e?.partner?.id}/${e?.details?.id}`,
                                        {
                                            params: {
                                                partner: {
                                                    ...e?.partner,
                                                    affiliate_id:
                                                        e?.details?.id,
                                                },
                                                pageType: AFFILIATE,
                                            },
                                        }
                                    );
                                }}
                            >
                                View details
                            </Link>
                        )}
                    </>
                );
            },
        });
        return [...required, ...extra];
    }, [localStorage.getItem("role")]);

    const handleConfirm = async () => {
        setPendingConfirmAction(true);
        let res = await AutoPilotService.addAffiliatePartner(addValues);
        if (res.status === 200) {
            toast.success("Created!");
            dispatch(getAffiliatePartnerList());
            setConfirmReplaceAction(false);
            setPendingConfirmAction(false);
        } else {
            toast.error(res.message);
            setConfirmReplaceAction(false);
            setPendingConfirmAction(false);
        }
    };
    return (
        <>
            <Helmet>
                <title>Affiliates</title>
            </Helmet>
            <div className="page-wrapper" style={{ display: "block" }}>
                <div className="page-breadcrumb">
                    <div className="row">
                        <div className="col-7 align-self-center">
                            <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
                                Manage your affiliates
                            </h3>
                        </div>
                        <div className="col-12">
                            <p className="card-text">
                                <span className="sub-title-info ">
                                    <i className="fa  fa-info-circle"></i>{" "}
                                    Create an affiliate to grant them access to
                                    the&nbsp;
                                    <span
                                        style={{
                                            fontWeight: "600",
                                            color: "black",
                                        }}
                                    >
                                        Cup
                                    </span>
                                    <span
                                        style={{
                                            color: "#5f76e8",
                                            fontWeight: "600",
                                        }}
                                    >
                                        id
                                    </span>
                                    &nbsp;affiliate platform. They will have
                                    access to your catalog and will be able to
                                    map it with their own.
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                {loadingCatalogs || loading ? (
                    <div className="text-center">
                        <div className="lds-ripple">
                            <div className="lds-pos"></div>
                            <div className="lds-pos"></div>
                        </div>
                    </div>
                ) : !activeCatalog ? (
                    <CatalogNoDataModal
                        title="Ready!"
                        text2="Your dedicated workspace is almost ready to go !"
                        text3="First, you’ll need to upload your hotel inventory."
                        buttonText="Upload inventory"
                        handleClick={async () => {
                            history.push("/catalogs-manager");
                        }}
                        hideButton={
                            hasPermission(CREATE_INVENTORY) ? false : true
                        }
                    />
                ) : activeCatalog?.mapping_process_status_id ==
                  STATUS_PROCESSING ? (
                    <div className="row">
                        <div className="col-12">
                            <CatalogNoDataModal
                                title="Almost ready!"
                                text="Your inventory is still processing."
                                hideButton={true}
                            />
                        </div>
                    </div>
                ) : data?.length === 0 ? (
                    <CatalogNoDataModal
                        handleClick={() => {
                            setAddAffiliatePartner(true);
                        }}
                        text2="You do not  have any affiliates yet."
                        text3="Add your first affiliate to grant them access to Cupid."
                        buttonText=" Add affiliate"
                        hideButton={
                            hasPermission(MANAGE_AFFILIATES) ? false : true
                        }
                    />
                ) : (
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="d-flex align-items-center">
                                            <div className="ml-auto">
                                                {hasPermission(
                                                    MANAGE_AFFILIATES
                                                ) && (
                                                    <div>
                                                        <button
                                                            className="btn btn-info"
                                                            onClick={() => {
                                                                setAddAffiliatePartner(
                                                                    true
                                                                );
                                                            }}
                                                        >
                                                            Add affiliate
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="table-responsive ">
                                            <Table
                                                data={data}
                                                rowsPerPage={10}
                                                columns={columns}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {addAffiliatePartner && (
                    <AddAffiliatePartner
                        show={addAffiliatePartner}
                        onClose={() => {
                            setAddAffiliatePartner(false);
                            setUpdating(false);
                        }}
                        update={updating}
                        element={selectedElement}
                        setElement={setSelectedElement}
                        setUpdating={setUpdating}
                        selectedPartnerId={selectedPartnerId}
                        setSelectedPartnerId={setSelectedPartnerId}
                        setConfirmReplaceAction={setConfirmReplaceAction}
                        setAddValues={setAddValues}
                    />
                )}
                {assignSegment && (
                    <AssignSegment
                        show={assignSegment}
                        handleClose={() => setAssignSegment(false)}
                        currentElement={currentElement}
                    />
                )}
                {confirmReplaceAction && (
                    <ConfirmAction
                        show={confirmReplaceAction}
                        onClose={() => setConfirmReplaceAction(false)}
                        subTitleMessage="This partner already has an affiliate account."
                        subTitleMessage1="This will deactivate the existing one and create a new affiliate account."
                        subTitleMessage2=" Do you want to proceed ?"
                        colorCode="danger"
                        handleClick={handleConfirm}
                        buttonText="Confirm"
                        disabled={pendingConfirmAction}
                    />
                )}
            </div>
        </>
    );
}
export default ManagePartnersScreen;

const AssignSegment = (props) => {
    const { handleClose, show, currentElement } = props;
    const [item, setItem] = useState(currentElement?.inventory_segment?.id);
    const [pending, setPending] = useState(false);
    const dispatch = useDispatch();
    const { segments } = useSelector((state) => ({
        segments: state.segmentReducer?.segments?.data,
    }));

    const handleChange = (e) => {
        setItem(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setPending(true);
        const res = await AutoPilotService.updateAffiliate(currentElement.id, {
            inventory_segment_id: parseInt(item, 10),
        });
        if (res.status === 200) {
            toast.success(res?.data?.message);
            dispatch(getAffiliatePartnerList());
            handleClose();
        } else {
            toast.error(res.message);
        }
        setPending(false);
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            dialogClassName="modal-30w modal-dialog modal-dialog-centered"
        >
            <Modal.Header
                style={{
                    color: "#212529",
                    fontSize: "18px",
                    fontWeight: "700",
                    left: "15px",
                    top: "15px",
                }}
            >
                Select segment
            </Modal.Header>
            <form onSubmit={handleSubmit}>
                <Modal.Body>
                    <div className="row">
                        <div
                            className="col-12"
                            style={{ marginBottom: "10px" }}
                        >
                            <div className="form-check">
                                <input
                                    className="form-radio-input"
                                    value={0}
                                    type="radio"
                                    aria-label="radio 1"
                                    onChange={handleChange}
                                    checked={parseInt(item, 10) === 0}
                                    id={0}
                                />
                                <label style={{ fontWeight: "400" }} for={0}>
                                    &nbsp;Active inventory
                                </label>
                            </div>
                        </div>
                        {segments?.map((e) => {
                            return (
                                <div
                                    className="col-12"
                                    style={{ marginBottom: "10px" }}
                                >
                                    <div className="form-check">
                                        <input
                                            className="form-radio-input"
                                            value={e.id}
                                            type="radio"
                                            aria-label="radio 1"
                                            onChange={handleChange}
                                            checked={
                                                parseInt(item, 10) ===
                                                parseInt(e.id, 10)
                                            }
                                            id={e.id}
                                        />
                                        <label
                                            style={{ fontWeight: "400" }}
                                            for={e.id}
                                        >
                                            &nbsp;{e.name}
                                        </label>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-primary"
                        type="submit"
                        disabled={pending}
                    >
                        Submit
                        {pending && (
                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        )}
                    </button>
                    <button
                        type="button"
                        className="btn btn-warning"
                        onClick={handleClose}
                    >
                        Close
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

const ResendInvitation = ({ currentElement }) => {
    const dispatch = useDispatch();
    return (
        <button
            className="btn"
            onClick={async () => {
                let res = await AutoPilotService.updateAffiliate(
                    currentElement?.id,
                    {
                        invitation_sent: true,
                    }
                );
                if (res.status === 200) {
                    toast.success(res?.data?.message);
                    dispatch(getAffiliatePartnerList());
                } else {
                    toast.error(res.message);
                }
            }}
        >
            <img
                src={ResendLogo}
                alt="Resend invitation"
                style={{
                    width: "25px",
                }}
            />
        </button>
    );
};
