import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Table from "../../components/Table/Table";
import { Spinner } from "react-bootstrap";
import moment from "moment";
import { UPDATE_EXISTING_CATALOG, ADD_TO_CATALOG } from "../../utilis/const";
import fileDownload from "js-file-download";
import { toast } from "react-toastify";
import { separator } from "../../utilis/functions";
import PartnerInventoryService from "../../services/PartnerInventoryService";

const statusBadgeVariant = (status) => {
    switch (status) {
        case 0:
            return {
                color: "warning",
                status: "Loading",
            };
        case 1:
            return {
                color: "info",
                status: "Processing",
            };
        case 2:
            return {
                color: "success",
                status: "Mapped",
            };
        case 3:
            return {
                color: "danger",
                status: "Failed",
            };
        default:
            return {
                color: "",
                status: "",
            };
    }
};
const actionBadgeVariant = (action) => {
    switch (action) {
        case ADD_TO_CATALOG:
            return {
                color: "#111111",
            };
        case UPDATE_EXISTING_CATALOG:
            return {
                color: "rgb(125 126 127)",
            };
        default:
            return {
                color: "",
                status: "",
            };
    }
};

const PartnerInventoriesHistoryDetails = (props) => {
    const { handleClose, show, data } = props;

    const columns = [
        {
            name: <div className="table-title">Total hotels</div>,
            sortable: true,
            selector: (e) => e?.total_hotels,
            cell: (e) => {
                return <>{e?.total_hotels ? separator(e?.total_hotels) : 0}</>;
            },
        },
        {
            name: <div className="table-title">Valid hotels</div>,
            sortable: true,
            selector: (e) => e?.valid_hotels,
            cell: (e) => {
                return <>{e?.valid_hotels ? separator(e?.valid_hotels) : 0}</>;
            },
        },
        {
            name: <div className="table-title">Invalid hotels</div>,
            sortable: true,
            selector: (e) => e?.invalid_hotels,
            cell: (e) => {
                return (
                    <>{e?.invalid_hotels ? separator(e?.invalid_hotels) : 0}</>
                );
            },
        },

        {
            name: <div className="table-title">Status</div>,
            sortable: true,
            selector: (e) => e?.status,
            cell: (e) => {
                return (
                    <span
                        text="white"
                        className={`badge rounded-pill text-white bg-${
                            statusBadgeVariant(e?.status)?.color
                        }`}
                    >
                        {statusBadgeVariant(e?.status)?.status}
                    </span>
                );
            },
        },
        {
            name: <div className="table-title">Action</div>,
            sortable: true,
            selector: (e) => e?.action,
            cell: (e) => {
                return (
                    <span
                        text="white"
                        className={`badge rounded-pill text-white `}
                        style={{
                            backgroundColor:
                                actionBadgeVariant(e?.action)?.color ?? "",
                        }}
                    >
                        {e?.action?.toUpperCase()}
                    </span>
                );
            },
        },
        {
            name: <div className="table-title">Created</div>,
            sortable: true,
            selector: (e) => e?.created_at,
            cell: (e) => {
                return (
                    <>
                        {moment(new Date(e?.created_at)).format(
                            "DD/MM/YYYY HH:mm:ss"
                        )}
                    </>
                );
            },
        },
        {
            name: <div className="table-title">Download</div>,
            sortable: false,
            cell: (e) => {
                return (
                    <>
                        {e?.status === 2 ? (
                            <DownloadCatalog dataCell={e} />
                        ) : (
                            e.mapping_process_status_id !== 2 &&
                            e.mapping_process_status_id !== 3 && (
                                <Spinner
                                    animation="border"
                                    variant="primary"
                                    size="sm"
                                />
                            )
                        )}
                    </>
                );
            },
        },
    ];
    return (
        <Modal
            show={show}
            onHide={handleClose}
            dialogClassName={"modal-90w modal-dialog modal-dialog-centered"}
        >
            <Modal.Header>
                <Modal.Title>Partner Inventory history </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table data={data ?? []} columns={columns} filter={false} />
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleClose}
                >
                    Close
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default PartnerInventoriesHistoryDetails;

function DownloadCatalog({ dataCell }) {
    const [pending, setPending] = useState(false);

    const handleDowload = (id) => async () => {
        setPending(true);
        try {
            let res = await PartnerInventoryService.dowloadPartnerInventory(id);
            if (res.status === 200) {
                fileDownload(res.data, res?.fileName);
                setPending(false);
            } else {
                toast.error("Something went wrong while downloading this file");
                setPending(false);
            }
        } catch (err) {
            toast.error("Something went wrong while downloading this file");
            setPending(false);
        }
    };

    return (
        <>
            <button className="btn" onClick={handleDowload(dataCell?.id)}>
                {pending ? (
                    <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                ) : (
                    <i className="fas fa-download"></i>
                )}
            </button>
        </>
    );
}
