import React, { useEffect, useState } from "react";
import Form from "@rjsf/core";
import ConnectorService from "../../../services/ConnectorService";
import { Modal, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { getCredentials } from "../../../redux/actions/ConnectorActions";
import { CONNECTOR_FREQUENCY_NEVER, CUPID_MODEL } from "../../../utilis/const";
import { toast } from "react-toastify";
import { PUBLIC_CONNECTOR } from "../../../utilis/const";
import Launching from "../../../lotties/launching.gif";
import PartnerService from "../../../services/PartnerService";
import TravelgateConnectorCredentials from "../../../components/TravelgateConfig/TravelgateConncetorCredentails";

function Step3(props) {
    const {
        selectedConnector,
        setCurrentPage,
        partner,
        connectorId,
        handleClose,
        connectorType,
        prev,
        loadingSaveAttributes,
        setLoadingSaveAttributes,
        listAttributes,
        selectedModel,
        selectedCupidModel,
        giataPartnerKey,
        isTravelgateHub,
        formData,
        setFormData,
        setSelectTravelgateConfig,
        selectTravelgateConfig,
        files,
        setFiles,
        setUseManualMappings,
        useManualMappings,
    } = props;
    const [loading, setLoading] = useState(false);
    const [loadingTestConnection, setLoadingTestConnection] = useState(false);
    const dispatch = useDispatch();
    const handleAddAttributes = async () => {
        setLoadingSaveAttributes(true);
        const res = await PartnerService.addPartnerAttributes({
            partner_id: partner.value,
            attributes: listAttributes,
        });
        if (res.status !== 200) {
            toast.error(res.message, {
                toastId: "partner-attributes",
            });
        }
        setLoadingSaveAttributes(false);
    };
    const handleSubmit = async (values) => {
        setLoading(true);
        await handleAddAttributes();
        const res = await ConnectorService.add(
            connectorId,
            partner.value,
            CONNECTOR_FREQUENCY_NEVER,
            values?.formData,
            connectorType === PUBLIC_CONNECTOR ? 1 : 0,
            selectedModel === CUPID_MODEL
                ? selectedCupidModel?.name
                : selectedModel,
            giataPartnerKey?.value,
            useManualMappings
        );
        if (res.status === 200) {
            setLoading(false);
            dispatch(getCredentials());
            toast.success("Created!");
            handleClose();
            window.analytics.track("Add connector", {
                userId: localStorage.getItem("userId"),
                companyId: localStorage.getItem("companyId"),
                connectorId: connectorId,
                partnerId: partner.value,
            });
        } else {
            toast.error(res.message);
            setLoading(false);
        }
    };
    const handleSubmitPublic = async () => {
        setLoading(true);
        await handleAddAttributes();
        const res = await ConnectorService.add(
            connectorId,
            partner.value,
            CONNECTOR_FREQUENCY_NEVER,
            "",
            connectorType === PUBLIC_CONNECTOR ? 1 : 0,
            selectedModel === CUPID_MODEL
                ? selectedCupidModel?.name
                : selectedModel,
            giataPartnerKey,
            useManualMappings
        );
        if (res.status === 200) {
            setLoading(false);
            dispatch(getCredentials());
            toast.success("Created!");
            handleClose();
            window.analytics.track("Add connector", {
                userId: localStorage.getItem("userId"),
                companyId: localStorage.getItem("companyId"),
                connectorId: connectorId,
                partnerId: partner.value,
            });
        } else {
            setLoading(false);
        }
    };

    const handleTestConnection = async () => {
        setLoadingTestConnection(true);
        try {
            const res = await ConnectorService.connectorTestConnection(
                connectorId,
                formData
            );
            if (res.status === 200) {
                setLoadingTestConnection(false);
                toast.success(res?.data?.msg);
            } else {
                setLoadingTestConnection(false);
                toast.error(res?.message);
            }
        } catch {
            setLoadingTestConnection(false);
            toast.error("Error establishing connection");
        }
    };
    const onChange = React.useCallback(({ formData }) => {
        setFormData(formData);
    }, []);

    return (
        <>
            {isTravelgateHub ? (
                <TravelgateConnectorCredentials
                    selectTravelgateConfig={selectTravelgateConfig}
                    setSelectTravelgateConfig={setSelectTravelgateConfig}
                    selectedConnector={selectedConnector}
                    files={files}
                    setFiles={setFiles}
                    prev={prev}
                    handleClose={handleClose}
                    onChange={onChange}
                    formData={formData}
                    handleSubmit={handleSubmit}
                    loadingTestConnection={loadingTestConnection}
                    handleTestConnection={handleTestConnection}
                    loading={loading}
                    loadingSaveAttributes={loadingSaveAttributes}
                    setFormData={setFormData}
                    setUseManualMappings={setUseManualMappings}
                />
            ) : (
                <>
                    {selectedConnector?.form &&
                        selectedConnector?.form !== "" && (
                            <>
                                <Form
                                    schema={JSON.parse(selectedConnector?.form)}
                                    uiSchema={
                                        selectedConnector?.ui_form_schema &&
                                        selectedConnector?.ui_form_schema !== ""
                                            ? JSON.parse(
                                                  selectedConnector?.ui_form_schema
                                              )
                                            : {}
                                    }
                                    onChange={onChange}
                                    formData={formData}
                                    onSubmit={handleSubmit}
                                    onError={() => console.log("Error")}
                                >
                                    <Modal.Footer
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            paddingLeft: "0px",
                                            paddingRight: "0px",
                                        }}
                                    >
                                        <div
                                            className="custom-control custom-checkbox"
                                            style={{
                                                marginRight: "35px",
                                            }}
                                        >
                                            <>
                                                <input
                                                    id={"usePreviousMapping"}
                                                    checked={useManualMappings}
                                                    onClick={() => {
                                                        setUseManualMappings(
                                                            !useManualMappings
                                                        );
                                                    }}
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    // id="customCheck1"
                                                />
                                                <label
                                                    className="custom-control-label"
                                                    for="usePreviousMapping"
                                                >
                                                    Use previous manual mapping
                                                </label>
                                            </>
                                        </div>
                                        <div>
                                            <button
                                                type="button"
                                                className="btn btn-warning m-2"
                                                onClick={() => {
                                                    if (isTravelgateHub) {
                                                        prev();
                                                    } else {
                                                        setCurrentPage(2);
                                                    }
                                                }}
                                            >
                                                <i className="fas fa-caret-left"></i>{" "}
                                                &nbsp;&nbsp; Back
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-light m-2"
                                                onClick={handleClose}
                                            >
                                                Close
                                            </button>
                                            <button
                                                onClick={handleTestConnection}
                                                className="btn btn-success m-2"
                                                disabled={loadingTestConnection}
                                            >
                                                Test connection
                                                {loadingTestConnection && (
                                                    <Spinner
                                                        as="span"
                                                        animation="grow"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                )}
                                            </button>
                                            <button
                                                type="submit"
                                                className="btn btn-primary m-2"
                                                disabled={
                                                    loading ||
                                                    loadingSaveAttributes
                                                }
                                            >
                                                Submit
                                                {(loading ||
                                                    loadingSaveAttributes) && (
                                                    <Spinner
                                                        as="span"
                                                        animation="grow"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                )}
                                            </button>
                                        </div>
                                    </Modal.Footer>
                                </Form>
                            </>
                        )}
                    {connectorType === PUBLIC_CONNECTOR && (
                        <>
                            <div style={{ textAlign: "center" }}>
                                <img
                                    src={Launching}
                                    height="250"
                                    width="250"
                                    alt="Public connector"
                                />
                                <h3 style={{ fontWeight: "bold" }}>
                                    This connector is readily available through
                                    Cupid You can start mapping right away.
                                </h3>
                            </div>
                            <Modal.Footer
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    paddingLeft: "0px",
                                    paddingRight: "0px",
                                }}
                            >
                                <div
                                    className="custom-control custom-checkbox"
                                    style={{
                                        marginRight: "35px",
                                    }}
                                >
                                    <>
                                        <input
                                            id={"usePreviousMapping"}
                                            checked={useManualMappings}
                                            onClick={() => {
                                                setUseManualMappings(
                                                    !useManualMappings
                                                );
                                            }}
                                            type="checkbox"
                                            className="custom-control-input"
                                            style={{
                                                cursor: "pointer",
                                            }}
                                            // id="customCheck1"
                                        />
                                        <label
                                            className="custom-control-label"
                                            for="usePreviousMapping"
                                        >
                                            Use previous manual mapping
                                        </label>
                                    </>
                                </div>
                                <div>
                                    <button
                                        type="button"
                                        className="btn btn-warning m-2"
                                        onClick={() => setCurrentPage(3)}
                                    >
                                        <i className="fas fa-caret-left"></i>{" "}
                                        &nbsp;&nbsp; Back
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-light m-2"
                                        onClick={handleClose}
                                    >
                                        Close
                                    </button>

                                    <button
                                        onClick={handleSubmitPublic}
                                        className="btn btn-primary m-2"
                                        disabled={
                                            loading || loadingSaveAttributes
                                        }
                                    >
                                        Submit
                                        {(loading || loadingSaveAttributes) && (
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        )}
                                    </button>
                                </div>
                            </Modal.Footer>
                        </>
                    )}
                </>
            )}
        </>
    );
}
export default Step3;
