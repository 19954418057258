import axios from "axios";
const url = process.env.REACT_APP_BASE_URL;

const AnalyticsService = {
    downloadInventoryUnmappedHotels: async () => {
        const result = await axios
            .get(url + `/hotel-catalogs/download-inventory-unmapped`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                responseType: "blob",
            })
            .then((res) => {
                const re = new RegExp('filename=\\"([a-zA-Z0-9.\\-_]+)');
                return {
                    data: res.data,
                    fileName:
                        res.headers?.["content-disposition"]?.match(re)?.[1],
                    status: 200,
                };
            })
            .catch((error) => {
                console.log("Error", error);
                return {
                    message: error.response.data.msg,
                    status: error.response.data.code,
                };
            });
        return result;
    },
    downloadPartnerExclusiveContent: async (partner_id) => {
        const result = await axios
            .get(url + `/partner-catalogs/download-exclusive-content-report`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                params: {
                    partner_id: partner_id,
                },
                //responseType: "blob",
            })
            .then((res) => {
                const re = new RegExp('filename=\\"([a-zA-Z0-9.\\-_]+)');
                return {
                    data: res.data?.data,
                    fileName:
                        res.headers?.["content-disposition"]?.match(re)?.[1],
                    status: 200,
                };
            })
            .catch((error) => {
                console.log("Error", error);
                return {
                    message: error.response.data.msg,
                    status: error.response.data.code,
                };
            });
        return result;
    },
};

export default AnalyticsService;
