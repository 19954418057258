import React from "react";
import { separator } from "../../utilis/functions";
import moment from "moment";
import { Col, Card, Row } from "react-bootstrap";
import Avatar from "react-avatar";
import CSV_LOGO from "../../lotties/csv-upload.png";

const spaceBetween = {
    display: "flex",
    justifyContent: "space-between",
};
const hotelDetails = {
    color: "rgb(128, 134, 152)",
    font: '500 1rem / 16px Roboto, "Helvetica Neue", sans-serif',
    letterSpacing: "0.3px",
    fontWeight: "500",
    fontSize: "1rem",
    paddingTop: "5px",
};
const PartnerinventoriesHeaderDetails = (props) => {
    const { activePartnerInventory } = props;
    return (
        <>
            <Col md={6} lg={6}>
                <Card className="card-partner-details">
                    <Row className="no-gutters">
                        <Col>
                            <Card.Body>
                                <Card.Title
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginBottom: "0px",
                                    }}
                                >
                                    <div
                                        style={{
                                            color: "#444",
                                        }}
                                    >
                                        <Avatar
                                            name={"P S"}
                                            round={true}
                                            size={30}
                                            color="#222"
                                            style={{
                                                marginRight: "11px",
                                            }}
                                        />
                                        {`${activePartnerInventory?.partner_name} summary`}

                                        <span
                                            style={{
                                                marginLeft: "11px",
                                                color: "rgb(128, 134, 152)",
                                                font: '500 1rem / 16px Roboto, "Helvetica Neue", sans-serif',
                                                letterSpacing: "0.3px",
                                            }}
                                        >
                                            ( Last update :&nbsp;
                                            {activePartnerInventory?.created_at
                                                ? moment(
                                                      new Date(
                                                          activePartnerInventory?.created_at
                                                      )
                                                  ).format("DD/MM/YYYY HH:mm")
                                                : "--"}
                                            )
                                        </span>
                                    </div>
                                    <div>
                                        {/* <SummaryMenu
                                            setShowLogoutConfirm={
                                                setShowLogoutConfirm
                                            }
                                        /> */}
                                    </div>
                                </Card.Title>
                                <Card.Text
                                    style={{
                                        marginTop: "0px",
                                        color: "#808698",
                                        fontWeight: "500",
                                        fontSize: "1rem",
                                        // font: "400 12px/16px Roboto,Helvetica Neue,sans-serif",
                                        letterSpacing: ".3px",
                                        position: "relative",
                                        bottom: "0",

                                        paddingTop: "4px",
                                    }}
                                >
                                    <div
                                        style={{
                                            ...spaceBetween,
                                        }}
                                    >
                                        <div
                                            style={{
                                                color: "#444",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    ...hotelDetails,
                                                }}
                                            >
                                                Total hotels :&nbsp;
                                                {activePartnerInventory?.total_hotels
                                                    ? separator(
                                                          activePartnerInventory?.total_hotels
                                                      )
                                                    : "--"}
                                            </div>
                                            <div
                                                style={{
                                                    ...hotelDetails,
                                                }}
                                            >
                                                <span
                                                    className="title"
                                                    style={{
                                                        fontSize: "15px",
                                                    }}
                                                >
                                                    <span
                                                        className="number"
                                                        style={{
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        Valid :&nbsp;
                                                    </span>

                                                    {activePartnerInventory?.valid_hotels
                                                        ? separator(
                                                              activePartnerInventory?.valid_hotels
                                                          )
                                                        : 0}
                                                </span>
                                            </div>
                                            <div
                                                style={{
                                                    ...hotelDetails,
                                                }}
                                            >
                                                <span
                                                    className="number"
                                                    style={{
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    Invalid :&nbsp;
                                                </span>
                                                {activePartnerInventory?.invalid_hotels
                                                    ? separator(
                                                          activePartnerInventory?.invalid_hotels
                                                      )
                                                    : 0}
                                            </div>
                                        </div>

                                        <div
                                            className="integration-entity"
                                            style={{
                                                marginBottom: "0px",
                                            }}
                                        >
                                            <div
                                                className="integration-entity-img-wrapper-connector-inventory"
                                                style={{
                                                    margin: "0px",
                                                }}
                                            >
                                                <img
                                                    id={"source_logo"}
                                                    className="cloudinary-image ls-is-cached lazyloaded round-img"
                                                    alt={"source_logo"}
                                                    src={
                                                        activePartnerInventory?.connector_logo &&
                                                        activePartnerInventory?.connector_id !==
                                                            0
                                                            ? activePartnerInventory?.connector_logo
                                                            : CSV_LOGO
                                                    }
                                                    width="50"
                                                    height="50"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Card.Text>
                            </Card.Body>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </>
    );
};

export default PartnerinventoriesHeaderDetails;
