import React, { useCallback, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { separator, findDuplicates } from "../utilis/functions";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import LogoCSV from "../lotties/csv-logo.png";
import Papa from "papaparse";

const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 6,
    borderRadius: 5,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
};

const focusedStyle = {
    borderColor: "#7f88ba",
};

const acceptStyle = {
    borderColor: "#7f88ba",
};

const rejectStyle = {
    borderColor: "#ff1744",
};

function DropzonePreview({
    files,
    setFiles,
    setData,
    isCatalog,
    setFirstLine,
}) {
    const [count, setCount] = useState();
    const [parsedData, setParsedData] = useState([]);

    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
        open,
    } = useDropzone({
        acceptedFiles:
            ".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values",
        onDrop: useCallback(async (acceptedFiles) => {
            var reader = new FileReader();
            reader.onload = function (e) {
                const text = e.target.result;
                let headers = [];
                let firstLine = [];
                Papa.parse(acceptedFiles[0], {
                    skipEmptyLines: true,
                    preview: 1,
                    header: true,
                    complete: (result) => {
                        headers = Object.keys(result.data?.[0]);
                        firstLine = Object.values(result?.data?.[0]);

                        setParsedData(result.data);
                        if (headers.length < 5) {
                            toast.error(
                                "Required columns does not exist in the imported file "
                            );
                            setFiles([]);
                        } else if (
                            !headers.every((i) => isNaN(parseInt(i, 10)))
                        ) {
                            toast.error("Invalid file");
                            setFiles([]);
                        } else if (
                            findDuplicates(result.meta?.fields)?.length > 0
                        ) {
                            toast.error("This file has duplicate fields");
                            setFiles([]);
                        } else {
                            setFiles(
                                acceptedFiles.map((file) => {
                                    return Object.assign(file, {
                                        preview: URL.createObjectURL(file),
                                    });
                                })
                            );
                            setData(
                                headers?.map((e, i) => ({
                                    index: i,
                                    name: e,
                                }))
                            );

                            setFirstLine(
                                firstLine?.map((e, i) => ({
                                    index: i,
                                    name: e,
                                }))
                            );
                        }
                    },
                });
                var lineCount = text.split("\n").length;
                setCount(lineCount - 1);
                if (isCatalog) {
                    localStorage.setItem("fileSize", lineCount - 1);
                }
            };
            reader.readAsText(acceptedFiles[0]);
        }),
    });
    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isFocused, isDragAccept, isDragReject]
    );
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Remove
        </Tooltip>
    );
    const fileList = (
        <>
            {files.map((file) => (
                <div className="text-center ">
                    <img
                        src={LogoCSV}
                        alt="CSV"
                        style={{ width: "64px", marginBottom: "20px" }}
                    />
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip}
                    >
                        <button
                            className="btn"
                            onClick={(e) => {
                                setFiles([]);
                            }}
                            style={{
                                paddingLeft: "0px",
                                // padding: "0px",
                                //marginBottom: "5%",
                            }}
                        >
                            <i
                                className="far fa-times-circle"
                                style={{
                                    color: "#776b6b",
                                    fontSize: "17px",
                                    marginBottom: "60px",
                                }}
                            ></i>
                        </button>
                    </OverlayTrigger>
                    <h3 style={{ fontWeight: "500" }}>
                        <i
                            className="fas fa-check"
                            style={{ color: "green", padding: "7px" }}
                        ></i>
                        {file.path}
                        {/*<img src="../assets/images/remove.png" alt="wrapkit" style={{ width: "30px" }} onClick={() => setFiles([])} />*/}
                    </h3>
                    <h5
                        style={{
                            fontWeight: "800",
                            color: "rgb(74 76 80 / 74%)",
                        }}
                    >
                        {count && `${separator(count)} properties`}
                    </h5>
                    <h5
                        style={{
                            fontWeight: "800",
                            color: "rgb(74 76 80 / 74%)",
                        }}
                    >
                        {file?.size &&
                            (file?.size / (1024 * 1024)).toFixed(2) + "MB"}
                    </h5>
                </div>
            ))}
        </>
    );

    let inputProps = getInputProps();
    inputProps.name = "files";
    return (
        <>
            {files?.length == 0 && (
                <section>
                    <div className="container">
                        <div
                            {...getRootProps({ style })}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <input {...getInputProps()} />
                            <p>
                                Drag and drop file here or click to select files
                            </p>
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={(e) => {
                                    e?.preventDefault();
                                    open();
                                }}
                            >
                                Open File Dialog
                            </button>
                        </div>
                    </div>
                </section>
            )}

            {fileList}
        </>
    );
}

export default DropzonePreview;
