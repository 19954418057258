import HomeScreen from "./pages/Home/HomeScreen";
import APIScreen from "./pages/Build/APIScreen";
import CatalogsManagerScreen from "./pages/Catalog/CatalogsManagerScreen";
import PartnersDetails from "./pages/Mapping/PartnersDetails";
import PartnerCatalogDetails from "./pages/Mapping/PartnersCatalogDetails";
import ToReview from "./pages/Mapping/ToReview";
import BasicInfoScreen from "./pages/Company/BasicInfoScreen";
import ManageTeamScreen from "./pages/Company/ManageTeamScreen";
import PaymentScreen from "./pages/Company/PaymentScreen";
import ManagePartnersScreen from "./pages/Company/ManagePartnersScreen";
import ConnectorsList from "./pages/Connectors/ConnectorsList";
import ExploreMapping from "./pages/Explore-Mapping/ExploreMapping";
import HotelDetails from "./pages/Explore-Mapping/HotelDetails";
import ExportDestinations from "./pages/Export-Destination/DestinationsList";
import DestinationForm from "./pages/Export-Destination/DestinationForm";
import PartnerInventoriesList from "./pages/Partners-Inventories/PartnerInventoriesList";
import PartnerInventoriesDetails from "./pages/Partners-Inventories/PartnerInventoriesDetails";
import VersionLogs from "./pages/Versions/VersionsLogs";
import AffiliateDetails from "./pages/Company/AffiliateDetails";
import StaticContent from "./pages/Static-Content/index";
import HotelsList from "./pages/Static-Content/HotelsList";
import DuplicateDetection from "./pages/Catalog/DuplicateDetection";
import InvalideDetection from "./pages/Catalog/InvalideDetection";
import ManualMapping from "./pages/Mapping/ManualMapping/index";
import GettingStarted from "./pages/get-started/index"
import {
    MANAGE_PAYMENTS,
    MANAGE_API_KEYS,
    MANAGE_DESTINATIONS,
    MANAGE_COMPANY,
    MANAGE_TEAM,
    MANAGE_MODELS,
    MANAGE_CONNECTORS,
} from "./utilis/const";
import { hasPermission } from "./utilis/functions";
import GiataPage from "./pages/Models/Giata/index";

const routesList = [
    {
        path: "/home",
        component: HomeScreen,
    },
    {
        path: "/catalogs-manager/duplicate",
        component: DuplicateDetection,
    },
    {
        path: "/catalogs-manager/invalide",
        component: InvalideDetection,
    },
    {
        path: "/hotels/:id",
        component: StaticContent,
    },
    {
        path: "/catalogs-manager/hotels-list",
        component: HotelsList,
    },
    {
        path: "/catalogs-manager",
        component: CatalogsManagerScreen,
    },

    // {
    //     path: "/manage-team",
    //     component: ManageTeamScreen,
    // },

    {
        path: "/to-review/:id",
        component: ToReview,
    },
    {
        path: "/provider-content/details/:id",
        component: PartnerCatalogDetails,
    },
    {
        path: "/provider-content",
        component: PartnersDetails,
    },
    {
        path: "/affiliate/details/:id/:affiliate_id",
        component: PartnerCatalogDetails,
    },
    {
        path: "/affiliate",
        component: ManagePartnersScreen,
    },

    {
        path: "/explore-mapping/details/:id",
        component: HotelDetails,
    },
    {
        path: "/explore-mapping",
        component: ExploreMapping,
    },

    {
        path: "/partners-inventories",
        component: PartnerInventoriesList,
    },
    {
        path: "/partner-inventories/details/:id",
        component: PartnerInventoriesDetails,
    },
    {
        path: "/changelog",
        component: VersionLogs,
    },
    {
        path: "/external-manual-mapping",
        component: ManualMapping,
    },
    {
        path: "/manual-mapping/:id",
        component: ManualMapping,
    },
        {
        path: "/get-started",
        component: GettingStarted,
    },
];
const getRoutes = () => {
    let extraRoutes = [];

    if (hasPermission(MANAGE_DESTINATIONS)) {
        extraRoutes.push({
            path: "/export-destinations/:name",
            component: DestinationForm,
        });
        extraRoutes.push({
            path: "/export-destinations",
            component: ExportDestinations,
        });
    }
    if (hasPermission(MANAGE_PAYMENTS)) {
        extraRoutes.push({
            path: "/payment",
            component: PaymentScreen,
        });
    }
    if (hasPermission(MANAGE_API_KEYS)) {
        extraRoutes.push({
            path: "/api-keys",
            component: APIScreen,
        });
    }
    if (hasPermission(MANAGE_COMPANY)) {
        extraRoutes.push({
            path: "/basic-info",
            component: BasicInfoScreen,
        });
    }
    if (hasPermission(MANAGE_TEAM)) {
        extraRoutes.push({
            path: "/manage-team",
            component: ManageTeamScreen,
        });
    }
    if (hasPermission(MANAGE_MODELS)) {
        extraRoutes.push({
            path: "/models/giata",
            component: GiataPage,
        });
    }
    if (hasPermission(MANAGE_CONNECTORS)) {
        extraRoutes.push({
            path: "/connectors",
            component: ConnectorsList,
        });
    }
    const listRoutes = [...routesList, ...extraRoutes];
    return listRoutes;
};
export default getRoutes;
