import React, { useState, useEffect } from "react";
import SearchForm from "./SearchForm";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { Spinner } from "react-bootstrap";
import { Redirect } from 'react-router-dom';

import {
    getFiltersList,
    getHotelsCandidates,
} from "../../../redux/actions/ManualMappingActions";
import HotelsList from "./HotelsList";
import { useParams, useLocation } from "react-router-dom";
import { PROCCESSING_VIEW, PROCCESSED_VIEW } from "../../../utilis/const";
import ProccessedHotelsList from "./ProccessedHotelsList";
import PartnerReportService from "../../../services/PartnerReportService";
import { toast } from "react-toastify";
import Header from "../../Partner-Report/Header";

const ManualMapping = () => {
    const { id } = useParams();
    const params = useLocation();
    const partner = params?.state?.params?.partner;

    const dispatch = useDispatch();
    const queryParams = new URLSearchParams(params.search);
    const token = queryParams.get("token_external_manual_mapping");
    const [currentTab, setCurrentTab] = useState("manual mapping");
    const [switchedView, setSwitchedView] = useState(PROCCESSING_VIEW);
    const [filters, setFilters] = useState([]);

    const { loadingFilterValues } = useSelector((state) => ({
        loadingFilterValues:
            state.manualMappingReducer.loadingFilterValues ?? false,
    }));


    useEffect(() => {
        dispatch(getFiltersList(id, token));
        dispatch(getHotelsCandidates(id, {}, 1, 10, null, null, token));
    }, []);
    const isExternalMapping =
        params.pathname.includes("external-manual-mapping") && token;
    
    console.log("token => " , token)
    return (
        <>
            <Helmet>
                <title>Manual mapping</title>
            </Helmet>
            <Header
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                    isAffiliate={false}
                    reportInfo={{ manual_mapping_token: token }}
                />
            <div
                className="page-wrapper"
                style={{
                    display: "block",
                    marginLeft: isExternalMapping && "0px",
                    padding:"0px"
                }}
            >
   
                <div className="page-breadcrumb">
                    <div className="row">
                        {/* <div className="col-12 align-self-center" >
                            <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
                                <button
                                    className="btn"
                                    onClick={() => {
                                        history.push(
                                            `/provider-content/details/${partner?.id}`,
                                            {
                                                params: {
                                                    partner: {
                                                        name: partner.name,
                                                        id: partner.id,
                                                        giata_code:
                                                            partner?.giata_code,
                                                        affiliate_id:
                                                            partner?.affiliate_id,
                                                    },
                                                },
                                            }
                                        );
                                    }}
                                    style={{ paddingLeft: "0px" }}
                                >
                                    <i className="fas fa-arrow-left"></i>
                                </button>
                                Review possible matches
                            </h3>
                        </div> */}
                        <div
                            className="col-12"
                            style={{
                                justifyContent: "space-between",
                                display: "flex",
                            }}
                        >
                            <p className="card-text">
                                <span className="sub-title-info ">
                                    <i className="fa  fa-info-circle"></i>
                                    &nbsp; We found some potential matches that
                                    you can manually review and either map them.
                                </span>
                            </p>
                            {/* {isExternalMapping && (
                                <button
                                    className="btn btn-primary"
                                    style={{
                                        borderRadius: "9px",
                                        width: "fit-content",
                                    }}
                                    disabled={pendingMappingReport}
                                    onClick={
                                        async () => {
                                        setPendingMappingReport(true);
                                        try {
                                            console.log("id", id);
                                            const res =
                                                await PartnerReportService.getReportToken(
                                                    id,
                                                    token
                                                );

                                            if (res.status === 200) {
                                                Redirect(`/partner-reports?token=${res?.data?.token}`)
                                                // window.history(
                                                //     `${window.location.origin}/partner-reports?token=${res?.data?.token}`
                                                // );
                                                setPendingMappingReport(false);
                                            } else {
                                                toast.error(
                                                    res?.message ??
                                                        "An error has occured"
                                                );
                                                setPendingMappingReport(false);
                                            }
                                        } catch (e) {
                                            toast.error("An error has occured");
                                            setPendingMappingReport(false);
                                        }
                                    }
                                }
                                >
                                    <i
                                        className="fas fa-share-alt"
                                        style={{
                                            marginRight: "4px",
                                        }}
                                    ></i>
                                    Mapping report
                                    {pendingMappingReport && (
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    )}
                                </button>
                            )} */}
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        {loadingFilterValues ? (
                            <div className="text-center">
                                <div className="lds-ripple">
                                    <div className="lds-pos"></div>
                                    <div className="lds-pos"></div>
                                </div>
                            </div>
                        ) : (
                            <>
                                <div
                                    className="col-12"
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        marginBottom: "10px",
                                        //marginLeft: "2px",
                                    }}
                                >
                                    <div
                                        role="group"
                                        className="btn-group btn-group-sm"
                                        style={{
                                            height: "40px",
                                            //   width: "100%",
                                        }}
                                    >
                                        <button
                                            type="button"
                                            className={`btn btn-${
                                                switchedView ===
                                                PROCCESSING_VIEW
                                                    ? "success"
                                                    : "light"
                                            }`}
                                            style={{
                                                borderRadius:
                                                    "8px 0px  0px  8px",
                                            }}
                                            onClick={() => {
                                                setSwitchedView(
                                                    PROCCESSING_VIEW
                                                );
                                            }}
                                        >
                                            Processing
                                        </button>
                                        <button
                                            type="button"
                                            className={`btn btn-${
                                                switchedView === PROCCESSED_VIEW
                                                    ? "success"
                                                    : "light"
                                            }`}
                                            style={{
                                                borderRadius:
                                                    "0px 8px  8px  0px",
                                            }}
                                            onClick={() => {
                                                setSwitchedView(
                                                    PROCCESSED_VIEW
                                                );
                                            }}
                                        >
                                            Proccessed
                                        </button>
                                    </div>
                                </div>
                                {switchedView === PROCCESSING_VIEW ? (
                                    <>
                                        <div className="col-12 align-self-center">
                                            <SearchForm
                                                filters={filters}
                                                setFilters={setFilters}
                                                catalog_id={id}
                                                token={token}
                                            />
                                        </div>
                                        <div className="col-lg-12 col-md-12">
                                            <HotelsList
                                                filters={filters}
                                                catalog_id={id}
                                                partner={partner}
                                                switchedView={switchedView}
                                                token={token}
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <div
                                        className="card"
                                        style={{ width: "100%" }}
                                    >
                                        <div className="card-body">
                                            <ProccessedHotelsList
                                                partner_id={partner?.id}
                                                dispatch={dispatch}
                                                token={token}
                                            />
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
export default ManualMapping;
