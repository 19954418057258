import React from "react";
import Table from "../Table/Table";
import { useSelector } from "react-redux";

const CityList = () => {
    const { cityReportList } = useSelector((state) => ({
        cityReportList: state.partnerReportReducer.cityReportList ?? [],
    }));
    const columns = [
        {
            id: "catalog_city",
            name: <div className="table-title">City</div>,
            selector: (row) => row.catalog_city,
            sortable: true,
            cell: (row) => <div>{row.catalog_city}</div>,
        },
        {
            id: "mapped_hotels",
            name: <div className="table-title">Mapped Hotels</div>,
            selector: (row) => row.mapped_hotels,
            cell: (row) => <div>{row.mapped_hotels}</div>,
            sortable: true,
        },
    ];
    return (
        <div className="responsive-table-container">
            <Table
                columns={columns}
                data={cityReportList}
                paginationPerPage={4}
            />
        </div>
    );
};

export default CityList;
