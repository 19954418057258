import React, { useEffect, useState } from "react";
import Table from "../../components/Table/TablePaginationFromApi";
import { useSelector, useDispatch } from "react-redux";
import { getInventoryUnmapped } from "../../redux/actions/AnalyticsActions";
import AnalyticsService from "../../services/AnalyticsService";
import fileDownload from "js-file-download";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { STATUS_SUCCESS_INVENTORY, IMPROVEMENTS } from "../../utilis/const";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const UnmappedCount = () => {
    const dispatch = useDispatch();
    const [pending, setPending] = useState(false);
    const [perPage, setPerPage] = useState(10);
    const [filterText, setFilterText] = useState("");
    const [column, setColumn] = useState();
    const [sortDirection, setSortDirection] = useState();
    const {
        inventoryUnmapped,
        loadingInventoryUnmapped,
        activeCatalog,
        totalInventoryUnmapped,
    } = useSelector((state) => ({
        inventoryUnmapped: state.analyticsReducer?.inventoryUnmapped ?? [],
        totalInventoryUnmapped:
            state.analyticsReducer?.totalInventoryUnmapped ?? 0,
        loadingInventoryUnmapped:
            state.analyticsReducer?.loadingInventoryUnmapped,
        activeCatalog: state.catalogReducer?.catalogs?.find(
            (e) =>
                e.active === true &&
                e?.mapping_process_status_id === STATUS_SUCCESS_INVENTORY
        ),
    }));
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    const renderTooltip = (name) => (props) =>
        (
            <Tooltip id="button-tooltip" {...props}>
                {name}
            </Tooltip>
        );

    const columns = [
        {
            id: "hotel_code",
            name: <div className="table-title">Inventory hotel code</div>,
            cell: (e) => {
                return <span>{e?.hotel_code}</span>;
            },
            sortable: true,
        },
        {
            id: "name",
            name: <div className="table-title">Hotel name</div>,
            cell: (e) => {
                return <span>{e?.name}</span>;
            },
            sortable: true,
        },
        {
            id: "address",
            name: <div className="table-title">Address</div>,
            cell: (e) => {
                return <span>{e?.address}</span>;
            },
            sortable: true,
        },
        {
            id: "city",
            name: <div className="table-title">City</div>,
            cell: (e) => {
                return <span>{e?.city}</span>;
            },
            sortable: true,
        },
        {
            id: "country",
            name: <div className="table-title">Country</div>,
            cell: (e) => {
                return <span>{e?.country}</span>;
            },
            sortable: true,
        },
        {
            id: "latitude",
            name: <div className="table-title">Latitude</div>,
            cell: (e) => {
                return <span>{e?.latitude}</span>;
            },
            sortable: true,
        },
        {
            id: "longitude",
            name: <div className="table-title">Longitude</div>,
            cell: (e) => {
                return <span>{e?.longitude}</span>;
            },
            sortable: true,
        },
        {
            id: "rank",
            name: <div className="table-title">Rank</div>,
            cell: (e) => {
                return <>{e?.rank === 0 ? "" : e?.rank}</>;
            },
            sortable: true,
            maxWidth: "60px",
        },
        {
            id: "quality_score",
            name: <div className="table-title">Data Quality Score</div>,
            cell: (e) => {
                return (
                    <>
                        {e?.quality_score === -1
                            ? "N/A"
                            : e?.quality_score < 0
                            ? 0
                            : e?.quality_score}
                    </>
                );
            },
            sortable: true,
        },
        {
            id: "quality_reasons",
            minWidth: "300px",
            name: (
                <div className="table-title">
                    Data Quality Score Reason{" "}
                    <OverlayTrigger
                        placement="top"
                        delay={{
                            show: 250,
                            hide: 400,
                        }}
                        overlay={renderTooltip(
                            "Suggestions to improve data quality of your inventory for easier mapping"
                        )}
                    >
                        <i
                            className="fas fa-info-circle"
                            style={{ marginLeft: "4px" }}
                        ></i>
                    </OverlayTrigger>
                </div>
            ),
            cell: (e) => {
                return (
                    <ul style={{ paddingLeft: "13px", marginBottom: 0 }}>
                        {e?.quality_reasons?.map((element, index) => {
                            return (
                                <OverlayTrigger
                                    placement="top"
                                    delay={{
                                        show: 250,
                                        hide: 400,
                                    }}
                                    overlay={renderTooltip(element)}
                                >
                                    <li key={index}>
                                        {IMPROVEMENTS.get(element)}
                                    </li>
                                </OverlayTrigger>
                            );
                        })}
                    </ul>
                );
            },
            sortable: false,
        },
    ];

    useEffect(() => {
        dispatch(getInventoryUnmapped(1, perPage, filterText));
    }, []);

    const handlePageChange = (page) => {
        dispatch(
            getInventoryUnmapped(
                page,
                perPage,
                filterText,
                column,
                sortDirection
            )
        );
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        dispatch(
            getInventoryUnmapped(
                page,
                newPerPage,
                filterText,
                column,
                sortDirection
            )
        );
        setPerPage(newPerPage);
    };

    const handleSort = async (c, sortD) => {
        dispatch(getInventoryUnmapped(1, perPage, filterText, c?.id, sortD));
        setSortDirection(sortD);
        setColumn(c?.id);
    };
    return (
        <div className="card">
            <div className="card-body">
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "16px",
                    }}
                >
                    <div>
                        <h4 className="card-title">
                            Inventory - Not Mapped{" "}
                            <OverlayTrigger
                                placement="top"
                                delay={{
                                    show: 250,
                                    hide: 400,
                                }}
                                overlay={renderTooltip(
                                    "These are the properties from your inventory that weren’t mapped with any of your partners."
                                )}
                            >
                                <i
                                    className="fas fa-info-circle"
                                    style={{ marginLeft: "4px" }}
                                ></i>
                            </OverlayTrigger>
                        </h4>
                    </div>
                    {inventoryUnmapped?.length !== 0 &&
                        !loadingInventoryUnmapped && (
                            <button
                                className="btn btn-sm waves-effect waves-light btn-rounded btn-outline-secondary"
                                style={{ marginRight: "12px" }}
                                onClick={async () => {
                                    setPending(true);
                                    try {
                                        const res =
                                            await AnalyticsService.downloadInventoryUnmappedHotels();
                                        if (res.status === 200) {
                                            fileDownload(
                                                res.data,
                                                res?.fileName
                                            );
                                            setPending(false);
                                        } else {
                                            toast.error(
                                                "Something went wrong while downloading this file"
                                            );
                                            setPending(false);
                                        }
                                    } catch (err) {
                                        toast.error(
                                            "Something went wrong while downloading this file"
                                        );
                                        setPending(false);
                                    }
                                }}
                                disabled={pending}
                            >
                                {pending ? (
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                ) : (
                                    <i
                                        className="fas fa-download"
                                        style={{ marginRight: "5px" }}
                                    ></i>
                                )}
                                Download CSV
                            </button>
                        )}
                </div>
                {inventoryUnmapped?.length === 0 &&
                activeCatalog &&
                !loadingInventoryUnmapped &&
                filterText == "" ? (
                    <div className="home-img-container">
                        <img
                            src="../../assets/images/checkImage.png"
                            alt="Mappings Performed Over Time no Data"
                            style={{ width: "9%" }}
                        />
                        <div className="img-title-unmapped-count">
                            <span>
                                All the properties from your inventory are
                                mapped at least once.
                            </span>
                        </div>
                    </div>
                ) : inventoryUnmapped?.length === 0 &&
                  !loadingInventoryUnmapped &&
                  filterText == "" ? (
                    <div className="home-img-container">
                        <img
                            src="../../assets/images/mappingCountSupplierBlur.png"
                            alt="Mappings Performed Over Time no Data"
                            style={{ width: "100%" }}
                        />
                        <div className="home-img-text-container-centered ">
                            <span>
                                This table will be available when you have
                                enough data to display.
                            </span>
                        </div>
                    </div>
                ) : (
                    <Table
                        data={inventoryUnmapped ?? []}
                        columns={columns}
                        totalRows={totalInventoryUnmapped}
                        handlePageChange={handlePageChange}
                        onChangeRowsPerPage={handlePerRowsChange}
                        progressPending={loadingInventoryUnmapped}
                        onFilter={() => {
                            setResetPaginationToggle(true);

                            dispatch(
                                getInventoryUnmapped(1, perPage, filterText)
                            );
                        }}
                        filterValue={filterText}
                        handleClearFilter={() => {
                            setFilterText("");
                            setResetPaginationToggle(true);
                            dispatch(getInventoryUnmapped(1, perPage, ""));
                        }}
                        onChange={(e) => {
                            setFilterText(e.target.value);
                            if (e.target.value === "") {
                                setResetPaginationToggle(true);
                                dispatch(getInventoryUnmapped(1, perPage, ""));
                            }
                        }}
                        filter={true}
                        resetPaginationToggle={resetPaginationToggle}
                        onSort={handleSort}
                        sortServer={true}
                    />
                )}
            </div>
        </div>
    );
};

export default UnmappedCount;
