import React from "react";
import AsyncSelect from "react-select/async";
import ManualMappingService from "../../../services/ManualMappingService";

export default function InputAutoComplete({
    isClearable,
    styles,
    value,
    name,
    placeholder,
    onChange,
    filter,
    catalog_id,
    token
}) {
    const loadOptions = (inputValue, callback) => {
        let cancelToken;
        if (inputValue?.length > 2) {
            ManualMappingService.autocompleteSearch(
                catalog_id,
                filter,
                encodeURIComponent(inputValue.trim()),
                cancelToken,
                token
            ).then((res) => {
                let data =
                    res?.data?.length > 0
                        ? [
                              ...res?.data?.map((e, index) => ({
                                  id: index + 1,
                                  name: e,
                              })),
                          ]
                        : [];
                callback(
                    data?.map((result) => {
                        return {
                            value: result?.name,
                            label: result.name,
                            inputValue: inputValue,
                        };
                    })
                );
            });
        }
    };

    let props = {
        inputId: name + "-input",
        id: name,
        name,
        isClearable: isClearable ?? false,

        menuPlacement: "auto",
        // isMulti: isMulti || false,
        styles: {
            ...styles,
            ...{
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
            },
            ...{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            },
        },
        theme: (theme) => ({
            ...theme,
            colors: {
                ...theme.colors,
                primary25: "#D3D7DB",
                primary: "#D3D7DB",
            },
        }),
        //  isOptionDisabled: isOptionDisabled,
    };
    return (
        <>
            <AsyncSelect
                value={value}
                //cacheOptions
                loadOptions={loadOptions}
                placeholder={placeholder ?? "Select..."}
                onChange={(property, value) => {
                    onChange(property);
                }}
                {...props}
                isMulti
            />
        </>
    );
}
