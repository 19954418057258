import {
    GET_COUNTRIES_LIST_SUCCESS,
    GET_COUNTRIES_LIST_LISTING,
    GET_COUNTRIES_LIST_FAIL,
} from "../types";
import axios from "axios";

const PATH_URL = process.env.REACT_APP_BASE_URL;

export const getCountries = () => async (dispatch) => {
    dispatch({
        type: GET_COUNTRIES_LIST_LISTING,
    });
    try {
        const res = await axios.get(PATH_URL + `/countries`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        dispatch({
            type: GET_COUNTRIES_LIST_SUCCESS,
            payload: res.data.data,
        });
    } catch (e) {
        dispatch({
            type: GET_COUNTRIES_LIST_FAIL,
            payload: console.log("Error in actions ", e),
        });
    }
};
