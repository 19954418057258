import React, { useState } from "react";
import Form from "@rjsf/core";
import ConnectorService from "../../../../services/ConnectorService";
import { Modal, Spinner } from "react-bootstrap";
import { CONNECTOR_FREQUENCY_NEVER } from "../../../../utilis/const";
import { toast } from "react-toastify";
import Launching from "../../../../lotties/launching.gif";
import { useDispatch } from "react-redux";
import {
    getPartnersInventoriesList,
    getPartnerInventoriesByIdList,
} from "../../../../redux/actions/PartnerActions";
import PartnerService from "../../../../services/PartnerService";
import { getCompletedSteps } from "../../../../redux/actions/AnalyticsActions";
import TravelgateConnectorCredentials from "../../../../components/TravelgateConfig/TravelgateConncetorCredentails";

function Step3(props) {
    const {
        selectedConnector,
        showForm,
        partner,
        handleClose,
        prev,
        cridentialsId,
        formData,
        setFormData,
        isPublic,
        setHideBackButton,
        hideBackButton,
        setLoadingSaveAttributes,
        listAttributes,
        loadingSaveAttributes,
        isTravelgateHub,
        setSelectTravelgateConfig,
        selectTravelgateConfig,
        setFiles,
        files,
        listPage,
    } = props;
    const [loading, setLoading] = useState(false);
    const [loadingTestConnection, setLoadingTestConnection] = useState(false);

    const handleTestConnection = async () => {
        setLoadingTestConnection(true);
        try {
            const res = await ConnectorService.connectorTestConnection(
                selectedConnector?.id,
                formData
            );
            if (res.status === 200) {
                setLoadingTestConnection(false);
                toast.success(res?.data?.msg);
            } else {
                setLoadingTestConnection(false);
                toast.error(res?.message);
            }
        } catch {
            setLoadingTestConnection(false);
            toast.error("Error establishing connection");
        }
    };

    const dispatch = useDispatch();
    const handleSubmit = async (values) => {
        setLoading(true);
        setHideBackButton(true);
        setLoadingSaveAttributes(true);
        const res = await PartnerService.addPartnerAttributes({
            partner_id: partner?.id ?? partner?.value,
            attributes: listAttributes,
        });
        if (res.status !== 200) {
            toast.error(res.message, {
                toastId: "partner-attributes",
            });
        }
        setLoadingSaveAttributes(false);
        const bodyFormData = new FormData();
        bodyFormData.append("partner_id", partner?.id ?? partner?.value);
        if (!showForm && cridentialsId && !isPublic) {
            const resMapping =
                await ConnectorService.createPartnerInventoryFromConnector(
                    cridentialsId,
                    bodyFormData
                );
            if (resMapping?.status === 200) {
                setLoading(false);
                handleClose();
                toast.success("Partner inventory created! ");
                window.analytics.track("Add new partner catalog", {
                    userID: localStorage.getItem("userId"),
                    companyID: localStorage.getItem("companyId"),
                    companyName: localStorage.getItem("companyName"),
                    firstName: localStorage.getItem("firstName"),
                    lastName: localStorage.getItem("lastName"),
                    email: localStorage.getItem("email"),
                    selectedPartnerID: partner?.id,
                    selectedPartnerName: partner?.name,
                    Type: "connector",
                    connectorName: selectedConnector.name,
                });
            } else {
                toast.error(resMapping?.message);
                setLoading(false);
                setHideBackButton(false);
            }
        } else if (isPublic) {
            const res = await ConnectorService.runMappingWithPublicConnector(
                partner?.connector_id,
                bodyFormData
            );
            if (res.status === 200) {
                setLoading(false);
                toast.success("Partner inventory created! ");
                handleClose();
                window.analytics.track("Add new partner inventory", {
                    userID: localStorage.getItem("userId"),
                    companyID: localStorage.getItem("companyId"),
                    companyName: localStorage.getItem("companyName"),
                    firstName: localStorage.getItem("firstName"),
                    lastName: localStorage.getItem("lastName"),
                    email: localStorage.getItem("email"),
                    selectedPartnerID: partner?.id,
                    selectedPartnerName: partner?.name,
                    Type: "connector",
                    connectorName: selectedConnector.name,
                });
            }
        } else {
            const res = await ConnectorService.add(
                selectedConnector?.id,
                partner?.id ?? partner?.value,
                CONNECTOR_FREQUENCY_NEVER,
                values?.formData,
                undefined
            );
            if (res.status === 200) {
                const resMapping =
                    await ConnectorService.createPartnerInventoryFromConnector(
                        res?.data?.id,
                        bodyFormData
                    );
                if (resMapping?.status === 200) {
                    setLoading(false);
                    toast.success("Partner inventory created!");
                    window.analytics.track("Add new partner inventory", {
                        userID: localStorage.getItem("userId"),
                        companyID: localStorage.getItem("companyId"),
                        companyName: localStorage.getItem("companyName"),
                        firstName: localStorage.getItem("firstName"),
                        lastName: localStorage.getItem("lastName"),
                        email: localStorage.getItem("email"),
                        selectedPartnerID: partner?.id,
                        selectedPartnerName: partner?.name,
                        Type: "connector",
                        connectorName: selectedConnector.name,
                    });
                }
                handleClose();
            } else {
                toast.error(res?.message);
                setLoading(false);
                setHideBackButton(false);
            }
        }
        if (listPage) {
            dispatch(getPartnerInventoriesByIdList(partner?.id));
        } else {
            dispatch(getPartnersInventoriesList());
        }
        setLoading(false);
        setHideBackButton(false);
        dispatch(getCompletedSteps());
    };

    const onChange = React.useCallback(({ formData }) => {
        setFormData(formData);
    }, []);
    return (
        <>
            {showForm &&
                selectedConnector?.form &&
                selectedConnector?.form !== "" && (
                    <>
                        {isTravelgateHub ? (
                            <TravelgateConnectorCredentials
                                selectTravelgateConfig={selectTravelgateConfig}
                                setSelectTravelgateConfig={
                                    setSelectTravelgateConfig
                                }
                                selectedConnector={selectedConnector}
                                files={files}
                                setFiles={setFiles}
                                prev={prev}
                                handleClose={handleClose}
                                onChange={onChange}
                                formData={formData}
                                handleSubmit={handleSubmit}
                                loading={loading}
                                loadingSaveAttributes={loadingSaveAttributes}
                                hideTestButton={false}
                                setFormData={setFormData}
                                hideBackButton={hideBackButton}
                                loadingTestConnection={loadingTestConnection}
                                setLoadingTestConnection={
                                    setLoadingTestConnection
                                }
                                handleTestConnection={handleTestConnection}
                                hideUseMapping={true}
                            />
                        ) : (
                            <Form
                                schema={JSON.parse(selectedConnector?.form)}
                                uiSchema={
                                    selectedConnector?.ui_form_schema &&
                                    selectedConnector?.ui_form_schema !== ""
                                        ? JSON.parse(
                                              selectedConnector?.ui_form_schema
                                          )
                                        : {}
                                }
                                onChange={onChange}
                                formData={formData}
                                onSubmit={handleSubmit}
                                onError={() => console.log("Error")}
                                autoComplete="off"
                            >
                                <Modal.Footer>
                                    {!hideBackButton && (
                                        <>
                                            <button
                                                type="button"
                                                className="btn btn-warning"
                                                onClick={prev}
                                            >
                                                <i className="fas fa-caret-left"></i>
                                                &nbsp;&nbsp; Back
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-light"
                                                onClick={handleClose}
                                                style={{
                                                    marginLeft: "10px",
                                                }}
                                            >
                                                Close
                                            </button>
                                        </>
                                    )}
                                    <button
                                        onClick={handleTestConnection}
                                        className="btn btn-success"
                                        style={{
                                            marginLeft: "10px",
                                        }}
                                        disabled={loadingTestConnection}
                                    >
                                        Test connection
                                        {loadingTestConnection && (
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        )}
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                        disabled={
                                            loading || loadingSaveAttributes
                                        }
                                        style={{
                                            marginLeft: "10px",
                                        }}
                                    >
                                        Start processing
                                        {(loading || loadingSaveAttributes) && (
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        )}
                                    </button>
                                </Modal.Footer>
                            </Form>
                        )}
                    </>
                )}
            {!showForm && !isPublic && (
                <>
                    <div style={{ textAlign: "center" }}>
                        <img
                            src={Launching}
                            height="250"
                            width="250"
                            alt="Lunch"
                        />
                        <h3 style={{ fontWeight: "bold" }}>
                            This partner catalog was previously configured.
                        </h3>
                        <h3
                            style={{
                                marginBottom: "40px",
                                fontWeight: "bold",
                            }}
                        >
                            You can start mapping right away.
                        </h3>
                    </div>
                    <Modal.Footer>
                        {!hideBackButton && (
                            <>
                                <button
                                    type="button"
                                    className="btn btn-warning"
                                    onClick={prev}
                                >
                                    <i className="fas fa-caret-left"></i>{" "}
                                    &nbsp;&nbsp; Back
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-light"
                                    onClick={handleClose}
                                    style={{
                                        marginLeft: "10px",
                                    }}
                                >
                                    Close
                                </button>
                            </>
                        )}
                        <button
                            className="btn btn-primary"
                            disabled={loading || loadingSaveAttributes}
                            onClick={handleSubmit}
                            style={{
                                marginLeft: "10px",
                            }}
                        >
                            Run mapping
                            {(loading || loadingSaveAttributes) && (
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            )}
                        </button>
                    </Modal.Footer>
                </>
            )}
            {isPublic && (
                <>
                    <div style={{ textAlign: "center" }}>
                        <img
                            src={Launching}
                            height="250"
                            width="250"
                            Alt="Public connector"
                        />
                        <h3 style={{ fontWeight: "bold" }}>
                            This partner's catalog is readily available through
                            Cupid
                        </h3>
                        <h3
                            style={{ marginBottom: "40px", fontWeight: "bold" }}
                        >
                            You can start mapping right away.
                        </h3>
                    </div>
                    <Modal.Footer>
                        <div>
                            {!hideBackButton && (
                                <>
                                    <button
                                        type="button"
                                        className="btn btn-warning"
                                        onClick={prev}
                                    >
                                        <i className="fas fa-caret-left"></i>{" "}
                                        &nbsp;&nbsp; Back
                                    </button>

                                    <button
                                        type="button"
                                        className="btn btn-light"
                                        onClick={handleClose}
                                        style={{
                                            marginLeft: "10px",
                                        }}
                                    >
                                        Close
                                    </button>
                                </>
                            )}
                            <button
                                className="btn btn-primary"
                                disabled={loading || loadingSaveAttributes}
                                onClick={handleSubmit}
                                style={{
                                    marginLeft: "10px",
                                }}
                            >
                                Start processing
                                {(loading || loadingSaveAttributes) && (
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                )}
                            </button>
                        </div>
                    </Modal.Footer>
                </>
            )}
        </>
    );
}
export default Step3;
