import React, { useEffect } from "react";
import { getTopSupplier } from "../../redux/actions/AnalyticsActions";
import { useDispatch, useSelector } from "react-redux";
import Table from "../Table/Table";
import SyncLoader from "react-spinners/SyncLoader";
import { separator } from "../../utilis/functions";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const TopSupplier = () => {
    const dispatch = useDispatch();
    const { topSupplier, loadingTopSupplier } = useSelector((state) => ({
        loadingTopSupplier: state.analyticsReducer.loadingTopSupplier,
        topSupplier: state.analyticsReducer.topSupplier ?? [],
    }));

    useEffect(() => {
        dispatch(getTopSupplier());
    }, []);

    const renderTooltip = (name) => (props) =>
        (
            <Tooltip id="button-tooltip" {...props}>
                {name}
            </Tooltip>
        );

    const columns = [
        {
            id: "partner_name",
            name: <div className="table-title">Partner name</div>,
            sortable: true,
            selector: (e) => e?.partner_name,
            cell: (e) => {
                return <>{e?.partner_name}</>;
            },
        },
        {
            id: "sum_mapped_hotels",
            name: <div className="table-title">Total mapped</div>,
            sortable: true,
            selector: (e) => e?.sum_mapped_hotels,
            cell: (e) => {
                return (
                    <>
                        {e?.sum_mapped_hotels
                            ? separator(e?.sum_mapped_hotels)
                            : 0}
                    </>
                );
            },
        },
        {
            id: "average",
            name: <div className="table-title">% of inventory</div>,
            sortable: true,
            selector: (e) => e?.average,
            grow: "2",
            cell: (e, index) => {
                return (
                    <>
                        <div
                            className="progress"
                            style={{ height: "10px", width: "100%" }}
                        >
                            <div
                                className="progress-bar"
                                role="progressbar"
                                style={{
                                    width: ` ${e?.average}%`,
                                    backgroundColor: "rgb(21, 191, 174)",
                                }}
                                aria-valuenow="25"
                                aria-valuemin="0"
                                aria-valuemax="100"
                            ></div>
                        </div>
                        <div className="col-4 text-right">
                            <span className="mb-0 font-14 text-dark font-weight-medium">
                                {e?.average?.toFixed(2)} %
                            </span>
                        </div>
                    </>
                );
            },
        },
    ];

    return (
        <div className="card" style={{ minHeight: "639px" }}>
            <div className="card-body">
                <h4 className="card-title" style={{ marginBottom: "10px" }}>
                    <img
                        src="../../assets/images/rank.png"
                        alt="wrapkit"
                        style={{
                            width: " 20px",
                            marginBottom: "6px",
                            marginRight: "3px",
                        }}
                    />
                    Top Partners by Mapping Rate
                    <OverlayTrigger
                        placement="top"
                        delay={{
                            show: 250,
                            hide: 400,
                        }}
                        overlay={renderTooltip(
                            `This is the ranking of your partners by the proportion of your inventory that has been mapped against their catalog.`
                        )}
                    >
                        <i
                            className="fas fa-info-circle"
                            style={{ marginLeft: "4px" }}
                        ></i>
                    </OverlayTrigger>
                </h4>

                {loadingTopSupplier ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            padding: "20px",
                        }}
                    >
                        <SyncLoader
                            color="#5856d694"
                            loading={true}
                            size={10}
                        />
                    </div>
                ) : (
                    <>
                        {topSupplier?.length === 0 ? (
                            <div className="home-img-container">
                                <img
                                    src="../../assets/images/topSupplierBlur.png"
                                    alt="Mappings Performed Over Time no Data"
                                    style={{ width: "100%" }}
                                />
                                <div className="home-img-text-container-centered ">
                                    <span>
                                        This table will be available when you
                                        have enough data to display.
                                    </span>
                                </div>
                            </div>
                        ) : (
                            <Table
                                data={topSupplier ?? []}
                                columns={columns}
                                filter={true}
                                paginationPerPage={5}
                            />
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default TopSupplier;
