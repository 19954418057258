import {
    GET_TEAM_DATA,
    GET_TEAM_DATA_SUCCESS,
    GET_TEAM_DATA_FAIL,
    UPDATE_TEAM_DATA,
    ADD_TEAM_DATA,
    UPDATE_TEAM_DATA_SUCCESS,
    ADD_TEAM_DATA_SUCCESS,
    UPDATE_TEAM_DATA_FAIL,
    ADD_TEAM_DATA_FAIL,
    DELETE_TEAM_DATA_SUCCESS,
    DELETE_TEAM_DATA_FAIL,
    DELETE_TEAM_DATA,
} from "../types";
import ManageTeamService from "../../services/ManageTeamService";

export const getTeamData = () => async (dispatch) => {
    dispatch({
        type: GET_TEAM_DATA,
    });
    try {
        const res = await ManageTeamService.get();
        dispatch({
            type: GET_TEAM_DATA_SUCCESS,
            payload: res.users,
        });
    } catch (e) {
        dispatch({
            type: GET_TEAM_DATA_FAIL,
        });
    }
};

export const updateTeamData = (data) => async (dispatch) => {
    dispatch({
        type: UPDATE_TEAM_DATA,
    });
    try {
        const res = await ManageTeamService.update(data.id, data);
        dispatch({
            type: UPDATE_TEAM_DATA_SUCCESS,
            payload: data,
        });
    } catch (e) {
        dispatch({
            type: UPDATE_TEAM_DATA_FAIL,
        });
    }
};

export const addTeamData = (data) => async (dispatch) => {
    dispatch({
        type: ADD_TEAM_DATA,
    });
    try {
        await ManageTeamService.add(data);
        dispatch({
            type: ADD_TEAM_DATA_SUCCESS,
            payload: data,
        });
    } catch (e) {
        dispatch({
            type: ADD_TEAM_DATA_FAIL,
        });
    }
};

export const deleteTeamData = (userId) => async (dispatch) => {
    dispatch({
        type: DELETE_TEAM_DATA,
    });
    try {
        const res = await ManageTeamService.delete(userId);
        dispatch({
            type: DELETE_TEAM_DATA_SUCCESS,
            payload: userId,
        });
    } catch (e) {
        dispatch({
            type: DELETE_TEAM_DATA_FAIL,
        });
    }
};
