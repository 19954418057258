import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getConnectors } from "../../../../redux/actions/ConnectorActions";
import ConnectorService from "../../../../services/ConnectorService";
import { Modal, Spinner } from "react-bootstrap";
import infoGif from "../../../../lotties/info.gif";
import { TRAVEL_GATE_FORM, TRAVEL_GATE_ID } from "../../../../utilis/const";

function Step2(props) {
    const {
        selectedConnector,
        setSelectedConnector,
        next,
        prev,
        setCridentialsId,
        setSelectedMethod,
        setIsTravelgateHub,
        handleClose,
        setConnectorUiSchema,
        setConnectorForm,
        setFormData,
        setSelectTravelgateConfig,
        setShowForm,
    } = props;
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { hubConnectors } = useSelector((state) => ({
        hubConnectors: state.connectorReducer.listConnectors?.filter(
            (e) => e?.is_direct === false
        ),
    }));

    const handleCheckCridentals = async () => {
        setLoading(true);
        const res = await ConnectorService.checkInventoryCridentials(
            selectedConnector?.id
        );
        if (res?.status === 200) {
            setCridentialsId(res?.data?.id);
            const parsedData = await testt(
                selectedConnector?.form,
                res.data?.credentials
            );
            await setConnectorForm(parsedData);
            setFormData(parsedData);
            setConnectorUiSchema(selectedConnector?.ui_form_schema);
            await setShowForm(false);

            next();
        } else {
            const parsedData = await vide(selectedConnector?.form);
            setConnectorForm(parsedData);
            setConnectorUiSchema(selectedConnector?.ui_form_schema);
            await setShowForm(true);
            next();
        }
        setLoading(false);
    };

    const testt = async (form, dd) => {
        var parsedData = form ? JSON.parse(form) : {};
        var keys = Object.keys(parsedData["properties"]);
        keys?.forEach((e) => {
            const tt = Object.keys(parsedData["properties"])?.find(
                (d) => d === e
            );
            parsedData["properties"][e] = {
                ...parsedData["properties"][e],
                default: dd?.[tt],
            };
        });
        return parsedData;
    };
    const vide = async (form) => {
        var parsedData = form ? JSON.parse(form) : {};
        var keys = Object.keys(parsedData["properties"]);
        keys?.forEach((e) => {
            const tt = Object.keys(parsedData["properties"])?.find(
                (d) => d === e
            );
            parsedData["properties"][e] = {
                ...parsedData["properties"][e],
                default: "",
            };
        });
        return parsedData;
    };

    useEffect(() => {
        dispatch(getConnectors());
    }, []);

    const hubConnectorsElements = hubConnectors?.map((e) => {
        return (
            <div className="col-6 col-md-4 col-lg-3">
                <div
                    className="integration-entity mx-auto"
                    style={{ marginBottom: "0px" }}
                >
                    <div
                        className="integration-entity-img-wrapper-connector"
                        style={{
                            transform:
                                selectedConnector?.id === e?.id
                                    ? "scale(1.2)"
                                    : "",
                        }}
                    >
                        <img
                            id={e.id}
                            className="cloudinary-image ls-is-cached lazyloaded round-img"
                            alt={e?.name}
                            src={e.logo}
                            width="100%"
                            height="100"
                            onClick={() => {
                                setSelectedConnector(e);
                                setSelectedMethod("Hub");
                                setFormData({});
                                setSelectTravelgateConfig(TRAVEL_GATE_FORM);
                                if (e?.id === TRAVEL_GATE_ID) {
                                    setIsTravelgateHub(true);
                                } else {
                                    setIsTravelgateHub(false);
                                }
                            }}
                            style={{
                                border:
                                    selectedConnector?.id === e?.id
                                        ? "2px solid rgb(96, 118, 232)"
                                        : "",
                            }}
                        />
                    </div>
                    <div className="integration-entity-name">{e?.name}</div>
                </div>
            </div>
        );
    });

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <fieldset className="scheduler-border">
                        <legend className="scheduler-border">HUB</legend>
                        <div className="row">{hubConnectorsElements}</div>
                    </fieldset>
                </div>
            </div>

            <Modal.Footer>
                <button className="btn btn-warning" onClick={prev}>
                    <i className="fas fa-caret-left"></i> &nbsp;&nbsp; Back
                </button>
                <button
                    type="button"
                    className="btn btn-light"
                    onClick={handleClose}
                >
                    Close
                </button>
                <button
                    className="btn btn-primary"
                    onClick={handleCheckCridentals}
                    disabled={loading}
                >
                    Next &nbsp;&nbsp;
                    <i className="fas fa-caret-right"></i>
                    {loading && (
                        <>
                            &nbsp;
                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        </>
                    )}
                </button>
            </Modal.Footer>
        </>
    );
}
export default Step2;
