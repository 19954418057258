import React from 'react';

function notFound(){
    return (
            <section className="NotfoundPage w-100 py-5 d-flex flex-column justify-content-center align-items-center position-relative overflow-hidden">


            <div className="d-flex flex-column justify-content-center align-items-center gap-3" style={{gap:"24px"}}>
                <div className="d-flex justify-content-center align-items-center gap-3 flex-row text-center" >
                    <h1 className="text-center text-dark display-1 fw-bold">404</h1>
                    <p className="text-dark text-start h4 fw-bold">
                        Oops! We can't seem to find the page <br /> you're looking for.
                    </p>
                </div>

                {/* <NotFound page="404" /> */}
                <img src="/assets/images/notFound.svg" alt="not found" style={{width:"400px", height:"auto"}} />

                <button
                    onClick={() => window.location.href = '/sign-in'}
                    type="button"
                    className="btn btn-primary rounded-pill shadow d-flex justify-content-center align-items-center gap-2"
                >
                <span className="text-white">Back to Login </span>
                </button>
            </div>
            </section>

    )

}
export default notFound;