import {
    GET_PAYMENT_CARDS_SUCCESS,
    GET_PAYMENT_CARDS_LISTING,
    GET_PAYMENT_CARDS_FAIL,
} from "../types";
import axios from "axios";

const PATH_URL = process.env.REACT_APP_BASE_URL;

export const getPaymentCards = () => async (dispatch) => {
    dispatch({
        type: GET_PAYMENT_CARDS_LISTING,
    });
    try {
        const res = await axios.get(PATH_URL + "/stripe/token", {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        dispatch({
            type: GET_PAYMENT_CARDS_SUCCESS,
            payload: res.data,
        });
    } catch (e) {
        dispatch({
            type: GET_PAYMENT_CARDS_FAIL,
        });
    }
};
