import React, { useEffect, useState } from "react";
import Table from "../../components/Table/TablePaginationFromApi";
import { useSelector, useDispatch } from "react-redux";
import { geMappedHotelListReport } from "../../redux/actions/PartnerReportAction";

const MappedHotelList = (props) => {
    const { token, filters } = props;
    const dispatch = useDispatch();
    const [perPage, setPerPage] = useState(10);
    const [filterText, setFilterText] = useState("");
    const [column, setColumn] = useState();
    const [sortDirection, setSortDirection] = useState();
    const { mappedHotelReportList, loadingMappedHotels, countHotelMapped } =
        useSelector((state) => ({
            mappedHotelReportList:
                state.partnerReportReducer.mappedHotelReportList ?? [],
            loadingMappedHotels: state.partnerReportReducer.loadingMappedHotels,
            countHotelMapped: state.partnerReportReducer.countHotelMapped,
        }));
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    const columns = [
        {
            id: "inventory_hotel_code",
            name: <div className="table-title">Inventory Hotel Code</div>,
            cell: (e) => {
                return <>{e?.inventory_hotel_code}</>;
            },
            sortable: true,
        },
        {
            id: "catalog_hotel_code",
            name: <div className="table-title">Partner Hotel Code</div>,
            cell: (e) => {
                return <>{e?.catalog_hotel_code}</>;
            },
            sortable: true,
        },
        {
            id: "catalog_hotel_name",
            name: <div className="table-title">Name</div>,
            cell: (e) => {
                return <>{e?.catalog_hotel_name}</>;
            },
            sortable: true,
        },
        {
            id: "catalog_city",
            name: <div className="table-title">City</div>,
            cell: (e) => {
                return <>{e?.catalog_city}</>;
            },
            sortable: true,
        },
        {
            id: "catalog_country",
            name: <div className="table-title">Country</div>,
            cell: (e) => {
                return <>{e?.catalog_country}</>;
            },
            sortable: true,
        },
        {
            id: "catalog_address",
            name: <div className="table-title">Address</div>,
            cell: (e) => {
                return <>{e?.catalog_address}</>;
            },
            sortable: true,
        },

        {
            id: "catalog_latitude",
            name: <div className="table-title">Latitude</div>,
            cell: (e) => {
                return <>{e?.catalog_latitude}</>;
            },
            sortable: true,
        },
        {
            id: "catalog_longitude",
            name: <div className="table-title">Longitude</div>,
            cell: (e) => {
                return <>{e?.catalog_longitude}</>;
            },
            sortable: true,
        },
        {
            id: "rank",
            name: <div className="table-title">Rank</div>,
            cell: (e) => {
                return <>{e?.rank}</>;
            },
            sortable: true,
        },
    ];

    useEffect(() => {
        dispatch(
            geMappedHotelListReport(token, 1, perPage, filterText, filters)
        );
    }, []);

    const handlePageChange = (page) => {
        dispatch(
            geMappedHotelListReport(
                token,
                page,
                perPage,
                filterText,
                filters,
                column,
                sortDirection
            )
        );
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        dispatch(
            geMappedHotelListReport(
                token,
                page,
                newPerPage,
                filterText,
                filters,
                column,
                sortDirection
            )
        );
        setPerPage(newPerPage);
    };

    const handleSort = async (c, sortD) => {
        dispatch(
            geMappedHotelListReport(
                token,
                1,
                perPage,
                filterText,
                filters,
                c?.id,
                sortD
            )
        );
        setColumn(c?.id);
        setSortDirection(sortD);
    };
    return (
        <div style={{ marginTop: "15px" }}>
            <Table
                data={mappedHotelReportList ?? []}
                columns={columns}
                totalRows={countHotelMapped}
                handlePageChange={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                progressPending={loadingMappedHotels}
                onFilter={() => {
                    setResetPaginationToggle(true);

                    dispatch(
                        geMappedHotelListReport(
                            token,
                            1,
                            perPage,
                            filterText,
                            filters
                        )
                    );
                }}
                filterValue={filterText}
                handleClearFilter={() => {
                    setFilterText("");
                    setResetPaginationToggle(true);
                    dispatch(
                        geMappedHotelListReport(token, 1, perPage, "", filters)
                    );
                }}
                onChange={(e) => {
                    setFilterText(e.target.value);
                    if (e.target.value === "") {
                        setResetPaginationToggle(true);
                        dispatch(
                            geMappedHotelListReport(
                                token,
                                1,
                                perPage,
                                filterText,
                                filters
                            )
                        );
                    }
                }}
                filter={true}
                resetPaginationToggle={resetPaginationToggle}
                onSort={handleSort}
                sortServer={true}
            />
        </div>
    );
};

export default MappedHotelList;
