import React from "react";
import Select from "../Select";
import { useSelector, useDispatch } from "react-redux";
import HotelNameAutoComplete from "../AutoComplete/PartnerReports/HotelNameAutoComplete";
import InventoryHotelIdAutoComplete from "../AutoComplete/PartnerReports/InventoryHotelIdAutoComplete";
import PartnerHotelIdAutoComplete from "../AutoComplete/PartnerReports/PartnerHotelIdAutoComplete";
import CitiesAutoComplete from "../AutoComplete/PartnerReports/CitiesAutoComplete";
import {
    getCountryReport,
    getCityReport,
    getChainReport,
    getPropertyTypeReport,
    getInvalidHotelListReport,
    geMappedHotelListReport,
    getOpportunitiesHotelListReport,
} from "../../redux/actions/PartnerReportAction";
import { intToString } from "../../utilis/functions";

const SearchForm = (props) => {
    const dispatch = useDispatch();
    const { filters, setFilters, token } = props;
    const {
        inventoryCountries,
        chainReportListFilter,
        propertyTypeReportList,
    } = useSelector((state) => ({
        inventoryCountries: state.partnerReportReducer.inventoryCountries ?? [],
        chainReportListFilter:
            state.partnerReportReducer.chainReportListFilter ?? [],
        propertyTypeReportList:
            state.partnerReportReducer.propertyTypeReportListFilter ?? [],
    }));
    return (
        <div className="row">
            <div
                className="col-lg-3 col-md-12"
                style={{ paddingTop: "10px", margin: "0px" }}
            >
                <InventoryHotelIdAutoComplete
                    isClearable={true}
                    value={filters?.hotel_id}
                    placeholder="Inventory Hotel Code"
                    name="hotel_id"
                    onChange={(value) => {
                        setFilters({ ...filters, hotel_id: value });
                    }}
                    token={token}
                />
            </div>
            <div
                className="col-lg-3 col-md-12"
                style={{ paddingTop: "10px", margin: "0px" }}
            >
                <CitiesAutoComplete
                    isClearable={true}
                    value={filters?.cities}
                    placeholder="City"
                    name="cities"
                    onChange={(value) => {
                        setFilters({ ...filters, cities: value });
                    }}
                    token={token}
                />
            </div>
            <div
                className="col-lg-3 col-md-12"
                style={{ paddingTop: "10px", margin: "0px" }}
            >
                <Select
                    options={inventoryCountries?.map((e, index) => ({
                        label:
                            e?.country + `  (${intToString(e?.mapped_hotels)})`,
                        value: e?.country,
                    }))}
                    name="countries"
                    value={filters?.countries}
                    placeholder="Country"
                    isMulti
                    isClearable={true}
                    onChange={(e) => {
                        setFilters({ ...filters, countries: e });
                    }}
                />
            </div>
            <div
                className="col-lg-3 col-md-12"
                style={{ paddingTop: "10px", margin: "0px" }}
            >
                <HotelNameAutoComplete
                    isClearable={true}
                    value={filters?.hotel_name}
                    placeholder="Name"
                    name="hotel_name"
                    onChange={(value) => {
                        setFilters({ ...filters, hotel_name: value });
                    }}
                    isMulti
                    token={token}
                />
            </div>
            <div
                className="col-lg-3 col-md-12"
                style={{ paddingTop: "10px", margin: "0px" }}
            >
                <PartnerHotelIdAutoComplete
                    isClearable={true}
                    value={filters?.partner_hotel_id}
                    placeholder="Partner Hotel Code"
                    name="partner_hotel_id"
                    onChange={(value) => {
                        setFilters({ ...filters, partner_hotel_id: value });
                    }}
                    token={token}
                />
            </div>
            <div
                className="col-lg-3 col-md-12"
                style={{ paddingTop: "10px", margin: "0px" }}
            >
                <Select
                    options={chainReportListFilter?.map((e, index) => ({
                        label: e?.catalog_chain,
                        value: index,
                    }))}
                    name="chains"
                    value={filters?.chains}
                    placeholder="Chain"
                    isMulti
                    isClearable={true}
                    onChange={(e) => {
                        setFilters({ ...filters, chains: e });
                    }}
                />
            </div>
            <div
                className="col-lg-3 col-md-12"
                style={{ paddingTop: "10px", margin: "0px" }}
            >
                <Select
                    options={propertyTypeReportList?.map((e, index) => ({
                        label: e?.catalog_type,
                        value: index,
                    }))}
                    name="propertyType"
                    value={filters?.propertyType}
                    placeholder="Property Type"
                    isMulti
                    isClearable={true}
                    onChange={(e) => {
                        setFilters({ ...filters, propertyType: e });
                    }}
                />
            </div>

            <div
                className="col-lg-12 col-md-12"
                style={{
                    paddingTop: "10px",
                    margin: "0px",
                    display: "flex",
                    justifyContent: "flex-end",
                }}
            >
                <button
                    className="btn btn-primary"
                    onClick={() => {
                        dispatch(
                            getCountryReport(token, {
                                hotel_id:
                                    filters?.hotel_id?.map((e) => e?.label) ??
                                    [],
                                cities:
                                    filters?.cities?.map((e) => e?.value) ?? [],
                                countries:
                                    filters?.countries?.map((e) => e?.value) ??
                                    [],
                                hotel_name:
                                    filters?.hotel_name?.map((e) => e?.label) ??
                                    [],
                                partner_hotel_id:
                                    filters?.partner_hotel_id?.map(
                                        (e) => e?.label
                                    ) ?? [],
                                chains:
                                    filters?.chains?.map((e) => e?.label) ?? [],
                                propertyType:
                                    filters?.propertyType?.map(
                                        (e) => e?.label
                                    ) ?? [],
                                stars:
                                    filters?.stars?.map((e) => e?.label) ?? [],
                            })
                        );
                        dispatch(
                            getCityReport(token, {
                                hotel_id:
                                    filters?.hotel_id?.map((e) => e?.label) ??
                                    [],
                                cities:
                                    filters?.cities?.map((e) => e?.value) ?? [],
                                countries:
                                    filters?.countries?.map((e) => e?.value) ??
                                    [],
                                hotel_name:
                                    filters?.hotel_name?.map((e) => e?.label) ??
                                    [],
                                partner_hotel_id:
                                    filters?.partner_hotel_id?.map(
                                        (e) => e?.label
                                    ) ?? [],
                                chains:
                                    filters?.chains?.map((e) => e?.label) ?? [],
                                propertyType:
                                    filters?.propertyType?.map(
                                        (e) => e?.label
                                    ) ?? [],
                                stars:
                                    filters?.stars?.map((e) => e?.label) ?? [],
                            })
                        );
                        dispatch(
                            getChainReport(token, {
                                hotel_id:
                                    filters?.hotel_id?.map((e) => e?.label) ??
                                    [],
                                cities:
                                    filters?.cities?.map((e) => e?.value) ?? [],
                                countries:
                                    filters?.countries?.map((e) => e?.value) ??
                                    [],
                                hotel_name:
                                    filters?.hotel_name?.map((e) => e?.label) ??
                                    [],
                                partner_hotel_id:
                                    filters?.partner_hotel_id?.map(
                                        (e) => e?.label
                                    ) ?? [],
                                chains:
                                    filters?.chains?.map((e) => e?.label) ?? [],
                                propertyType:
                                    filters?.propertyType?.map(
                                        (e) => e?.label
                                    ) ?? [],
                                stars:
                                    filters?.stars?.map((e) => e?.label) ?? [],
                            })
                        );
                        dispatch(
                            getPropertyTypeReport(token, {
                                hotel_id:
                                    filters?.hotel_id?.map((e) => e?.label) ??
                                    [],
                                cities:
                                    filters?.cities?.map((e) => e?.value) ?? [],
                                countries:
                                    filters?.countries?.map((e) => e?.value) ??
                                    [],
                                hotel_name:
                                    filters?.hotel_name?.map((e) => e?.label) ??
                                    [],
                                partner_hotel_id:
                                    filters?.partner_hotel_id?.map(
                                        (e) => e?.label
                                    ) ?? [],
                                chains:
                                    filters?.chains?.map((e) => e?.label) ?? [],
                                propertyType:
                                    filters?.propertyType?.map(
                                        (e) => e?.label
                                    ) ?? [],
                                stars:
                                    filters?.stars?.map((e) => e?.label) ?? [],
                            })
                        );
                        // dispatch(
                        //     getInventoryCities(
                        //         token?.partnerId,
                        //         token?.catalogId,
                        //         token?.companyId,
                        //         filters
                        //     )
                        // );
                        // dispatch(
                        //     getInventoryCountries(
                        //         token?.partnerId,
                        //         token?.catalogId,
                        //         token?.companyId,
                        //         filters
                        //     )
                        // );
                        dispatch(
                            getOpportunitiesHotelListReport(token, 1, 10, "", {
                                hotel_id:
                                    filters?.hotel_id?.map((e) => e?.label) ??
                                    [],
                                cities:
                                    filters?.cities?.map((e) => e?.value) ?? [],
                                countries:
                                    filters?.countries?.map((e) => e?.value) ??
                                    [],
                                hotel_name:
                                    filters?.hotel_name?.map((e) => e?.label) ??
                                    [],
                                partner_hotel_id:
                                    filters?.partner_hotel_id?.map(
                                        (e) => e?.label
                                    ) ?? [],
                                chains:
                                    filters?.chains?.map((e) => e?.label) ?? [],
                                propertyType:
                                    filters?.propertyType?.map(
                                        (e) => e?.label
                                    ) ?? [],
                                stars:
                                    filters?.stars?.map((e) => e?.label) ?? [],
                            })
                        );
                        dispatch(
                            geMappedHotelListReport(token, 1, 10, "", {
                                hotel_id:
                                    filters?.hotel_id?.map((e) => e?.label) ??
                                    [],
                                cities:
                                    filters?.cities?.map((e) => e?.value) ?? [],
                                countries:
                                    filters?.countries?.map((e) => e?.value) ??
                                    [],
                                hotel_name:
                                    filters?.hotel_name?.map((e) => e?.label) ??
                                    [],
                                partner_hotel_id:
                                    filters?.partner_hotel_id?.map(
                                        (e) => e?.label
                                    ) ?? [],
                                chains:
                                    filters?.chains?.map((e) => e?.label) ?? [],
                                propertyType:
                                    filters?.propertyType?.map(
                                        (e) => e?.label
                                    ) ?? [],
                                stars:
                                    filters?.stars?.map((e) => e?.label) ?? [],
                            })
                        );

                        dispatch(
                            getInvalidHotelListReport(token, 1, 10, "", {
                                hotel_id:
                                    filters?.hotel_id?.map((e) => e?.label) ??
                                    [],
                                cities:
                                    filters?.cities?.map((e) => e?.value) ?? [],
                                countries:
                                    filters?.countries?.map((e) => e?.value) ??
                                    [],
                                hotel_name:
                                    filters?.hotel_name?.map((e) => e?.label) ??
                                    [],
                                partner_hotel_id:
                                    filters?.partner_hotel_id?.map(
                                        (e) => e?.label
                                    ) ?? [],
                                chains:
                                    filters?.chains?.map((e) => e?.label) ?? [],
                                propertyType:
                                    filters?.propertyType?.map(
                                        (e) => e?.label
                                    ) ?? [],
                                stars:
                                    filters?.stars?.map((e) => e?.label) ?? [],
                            })
                        );
                    }}
                >
                    Search
                </button>
            </div>
        </div>
    );
};

export default SearchForm;
