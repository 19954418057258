import React from "react";
import SelectTravelgateConfig from "./SelectTravelgateConfig";
import { TRAVEL_GATE_JSON, TRAVEL_GATE_FORM } from "../../utilis/const";
import UploadTravelgateConfig from "./UploadTravelgateConfig";
import { Modal, Spinner } from "react-bootstrap";
import Form from "@rjsf/core";

const TravelgateConnectorCredentials = (props) => {
    const {
        selectTravelgateConfig,
        setSelectTravelgateConfig,
        selectedConnector,
        files,
        setFiles,
        prev,
        handleClose,
        onChange,
        formData,
        handleSubmit,
        loadingTestConnection,
        handleTestConnection,
        loading,
        loadingSaveAttributes,
        hideTestButton,
        setFormData,
        setUseManualMappings,
        useManualMappings,
        hideBackButton,
        inventoryPage,
        updateInventoryDaily,
        setUpdateInventoryDaily,
        hideUseMapping,
    } = props;
    const [data, setData] = React.useState({});
    return (
        <>
            <SelectTravelgateConfig
                selectTravelgateConfig={selectTravelgateConfig}
                setSelectTravelgateConfig={setSelectTravelgateConfig}
            />
            {selectTravelgateConfig === TRAVEL_GATE_FORM ? (
                <>
                    {selectedConnector?.form &&
                        selectedConnector?.form !== "" && (
                            <>
                                <Form
                                    schema={JSON.parse(selectedConnector?.form)}
                                    uiSchema={
                                        selectedConnector?.ui_form_schema &&
                                        selectedConnector?.ui_form_schema !== ""
                                            ? JSON.parse(
                                                  selectedConnector?.ui_form_schema
                                              )
                                            : {}
                                    }
                                    onChange={onChange}
                                    formData={formData}
                                    onSubmit={handleSubmit}
                                    onError={() => console.log("Error")}
                                >
                                    <Modal.Footer
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            paddingLeft: "0px",
                                            paddingRight: "0px",
                                        }}
                                    >
                                        <div
                                            className="custom-control custom-checkbox"
                                            style={{
                                                marginRight: "35px",
                                            }}
                                        >
                                            {!hideBackButton && (
                                                <>
                                                    {inventoryPage ? (
                                                        <>
                                                            <input
                                                                id={
                                                                    "updateInventoryDaily"
                                                                }
                                                                checked={
                                                                    updateInventoryDaily
                                                                }
                                                                onClick={() => {
                                                                    setUpdateInventoryDaily(
                                                                        !updateInventoryDaily
                                                                    );
                                                                }}
                                                                type="checkbox"
                                                                className="custom-control-input"
                                                                style={{
                                                                    cursor: "pointer",
                                                                }}
                                                                // id="customCheck1"
                                                            />
                                                            <label
                                                                className="custom-control-label"
                                                                for="updateInventoryDaily"
                                                            >
                                                                Update my
                                                                inventory daily
                                                            </label>
                                                        </>
                                                    ) : !hideUseMapping ? (
                                                        <>
                                                            <input
                                                                id={
                                                                    "usePreviousMapping"
                                                                }
                                                                checked={
                                                                    useManualMappings
                                                                }
                                                                onClick={() => {
                                                                    setUseManualMappings(
                                                                        !useManualMappings
                                                                    );
                                                                }}
                                                                type="checkbox"
                                                                className="custom-control-input"
                                                                style={{
                                                                    cursor: "pointer",
                                                                }}
                                                                // id="customCheck1"
                                                            />
                                                            <label
                                                                className="custom-control-label"
                                                                for="usePreviousMapping"
                                                            >
                                                                Use previous
                                                                manual mapping
                                                            </label>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                        <div>
                                            {!hideBackButton && (
                                                <>
                                                    <button
                                                        type="button"
                                                        className="btn btn-warning"
                                                        onClick={() => {
                                                            prev();
                                                        }}
                                                    >
                                                        <i className="fas fa-caret-left"></i>{" "}
                                                        &nbsp;&nbsp; Back
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-light"
                                                        onClick={handleClose}
                                                        style={{
                                                            marginLeft: "10px",
                                                        }}
                                                    >
                                                        Close
                                                    </button>
                                                </>
                                            )}
                                            {!hideTestButton && (
                                                <button
                                                    onClick={
                                                        handleTestConnection
                                                    }
                                                    className="btn btn-success"
                                                    style={{
                                                        marginLeft: "10px",
                                                    }}
                                                    disabled={
                                                        loadingTestConnection
                                                    }
                                                >
                                                    Test connection
                                                    {loadingTestConnection && (
                                                        <Spinner
                                                            as="span"
                                                            animation="grow"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />
                                                    )}
                                                </button>
                                            )}
                                            <button
                                                type="submit"
                                                className="btn btn-primary"
                                                disabled={
                                                    loading ||
                                                    loadingSaveAttributes
                                                }
                                                style={{
                                                    marginLeft: "10px",
                                                }}
                                            >
                                                Submit
                                                {(loading ||
                                                    loadingSaveAttributes) && (
                                                    <Spinner
                                                        as="span"
                                                        animation="grow"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                )}
                                            </button>
                                        </div>
                                    </Modal.Footer>
                                </Form>
                            </>
                        )}
                </>
            ) : selectTravelgateConfig === TRAVEL_GATE_JSON ? (
                <>
                    <Modal.Body>
                        <UploadTravelgateConfig
                            files={files}
                            setFiles={setFiles}
                            setData={setData}
                            setSelectTravelgateConfig={
                                setSelectTravelgateConfig
                            }
                            setFormData={setFormData}
                        />
                    </Modal.Body>
                    <Modal.Footer
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            paddingLeft: "0px",
                            paddingRight: "0px",
                        }}
                    >
                        {!hideBackButton && !inventoryPage && (
                            <div
                                className="custom-control custom-checkbox"
                                style={{
                                    marginRight: "35px",
                                }}
                            >
                                <input
                                    id={"usePreviousMapping"}
                                    checked={useManualMappings}
                                    onClick={() => {
                                        setUseManualMappings(
                                            !useManualMappings
                                        );
                                    }}
                                    type="checkbox"
                                    className="custom-control-input"
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    // id="customCheck1"
                                />
                                <label
                                    className="custom-control-label"
                                    for="usePreviousMapping"
                                >
                                    Use previous manual mapping
                                </label>
                            </div>
                        )}
                        <div>
                            <button
                                type="button"
                                className="btn btn-warning m-2"
                                onClick={() => {
                                    prev();
                                }}
                            >
                                <i className="fas fa-caret-left"></i>{" "}
                                &nbsp;&nbsp; Back
                            </button>
                            <button
                                type="button"
                                className="btn btn-light"
                                onClick={handleClose}
                            >
                                Close
                            </button>
                            {!hideTestButton && (
                                <button
                                    onClick={async () => {
                                        await setFormData(data);
                                        handleTestConnection();
                                    }}
                                    className="btn btn-success m-2"
                                    disabled={loadingTestConnection}
                                >
                                    Test connection
                                    {loadingTestConnection && (
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    )}
                                </button>
                            )}
                            <button
                                type="button"
                                className="btn btn-primary m-2"
                                disabled={loading || loadingSaveAttributes}
                                onClick={() => {
                                    handleSubmit({
                                        formData: data,
                                    });
                                }}
                            >
                                Submit
                                {(loading || loadingSaveAttributes) && (
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                )}
                            </button>
                        </div>
                    </Modal.Footer>
                </>
            ) : (
                <Modal.Footer>
                    <button
                        type="button"
                        className="btn btn-warning"
                        onClick={() => {
                            prev();
                        }}
                    >
                        <i className="fas fa-caret-left"></i> &nbsp;&nbsp; Back
                    </button>
                    <button
                        type="button"
                        className="btn btn-light"
                        onClick={handleClose}
                    >
                        Close
                    </button>
                </Modal.Footer>
            )}
        </>
    );
};

export default TravelgateConnectorCredentials;
