import { combineReducers } from "redux";
import DashBoardReducer from "./DashboardReducer";
import CatalogReducer from "./CatalogReducer";
import PartnerCatalogReducer from "./PartnerCatalogReducer";
import PartnerReducer from "./PartnerReducer";
import LoginReducer from "./LoginReducer";
import CompanyReducer from "./CompanyReducer";
import ManageTeamReducer from "./ManageTeamReducer";
import PaymentReducer from "./PaymentReducer";
import AutoPilotReducer from "./AutoPilotReducer";
import ExploreMappingReducer from "./ExploreMappingReducer";
import CountriesReducer from "./CountriesReducer";
import ConnnectorReducer from "./ConnectorReducer";
import DestinationReducer from "./DestinationReducer";
import AnalyticsReducer from "./AnalyticsReducer";
import SegmentReducer from "./SegmentReducer";
import PartnerReportReducer from "./PartnerReportReducer";
import ModelsReducer from "./ModelsReducer";
import ManualMappingReducer from "./ManualMappingReducer";
import HotelRankingReducer from "./HotelRankingReducer";
import { USER_LOGOUT } from "../types";
import HotelsReducer from "./HotelsReducer";

const appReducer = combineReducers({
    dashboardReducer: DashBoardReducer,
    catalogReducer: CatalogReducer,
    partnerCatalogReducer: PartnerCatalogReducer,
    partnerReducer: PartnerReducer,
    loginReducer: LoginReducer,
    companyReducer: CompanyReducer,
    manageTeamReducer: ManageTeamReducer,
    paymentReducer: PaymentReducer,
    autoPilotReducer: AutoPilotReducer,
    exploreMappingReducer: ExploreMappingReducer,
    countriesReducer: CountriesReducer,
    connectorReducer: ConnnectorReducer,
    destinationReducer: DestinationReducer,
    analyticsReducer: AnalyticsReducer,
    segmentReducer: SegmentReducer,
    partnerReportReducer: PartnerReportReducer,
    modelsReducer: ModelsReducer,
    manualMappingReducer: ManualMappingReducer,
    hotelRankingReducer: HotelRankingReducer,
    hotelsReducer: HotelsReducer,
});

const rootReducer = (state, action) => {
    if (action.type === USER_LOGOUT) {
        return appReducer(undefined, action);
    }

    return appReducer(state, action);
};
export default rootReducer;
