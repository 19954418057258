import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Card, Row, Col, Tooltip, OverlayTrigger } from "react-bootstrap";
import Avatar from "react-avatar";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { CLEAR_PARTNER_HAS_CATALOG } from "../../redux/types";
import { useHistory } from "react-router-dom";
import DownloadCatalog from "./DownloadCatalog";
import {
    getPartnerCatalogsLastUpdate,
    getPartnerCatalogsLastUpdatePeriodique,
} from "../../redux/actions/PartnerActions";
import MappingDestinationModal from "./MappingDestinationModal";
import {
    getPartnerCatalogsByStatus,
    getPartnerCatalogsByStatusPeriodique,
} from "../../redux/actions/PartnerCataogActions";
import useCollapse from "react-collapsed";
import ReactPaginate from "react-paginate";
import { getCatalogs } from "../../redux/actions/CatalogActions";
import CatalogNoDataModal from "./CatalogNoDataModal";
import { separator } from "../../utilis/functions";
import {
    STATUS_PROCESSING,
    STATUS_PENDING,
    STATUS_SUCCESS_CATALOG,
    STATUS_FAILED,
    STATUS_IN_QUEUE,
    STATUS_STOPPED,
    CREATE_CATALOG,
    CREATE_INVENTORY,
    CARD_VIEW,
    TABLE_VIEW,
    GIATA_MODEL,
    MAPPINGS,
    DELETE_MAPPING,
} from "../../utilis/const";
import { hasPermission } from "../../utilis/functions";
import PartnerOutdatedCatalog from "./PartnerOutdatedCatalog";
import PartnerDetailsTable from "./PartnerDetailsTable";
import ToggleSwitch from "../../components/ToggleSwitch";
import { getCompletedSteps } from "../../redux/actions/AnalyticsActions";
import ShareMappingReport from "./ShareMappingReportModal";
import ConfirmAction from "../../components/ConfirmActionsModal";
import PartnerService from "../../services/PartnerService";
import { toast } from "react-toastify";

import PartnersDetailsProccessingList from "./PartnersDetailsProccessingList";
import RunMappingModal from "../../components/RunMappingModal";

const renderTooltip = (name) => (props) =>
    (
        <Tooltip id="button-tooltip" {...props}>
            {name}
        </Tooltip>
    );

const TextDateStyle = {
    color: "rgb(128, 134, 152)",
    font: '500 1rem / 16px Roboto, "Helvetica Neue", sans-serif',
    letterSpacing: "0.3px",
    fontWeight: "500",
    fontSize: "1rem",
    paddingTop: "5px",
};

const CardTitleStyle = {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "25px",
};

const CardStatusStyle = {
    width: "100%",
    fontWeight: 800,
    fontSize: "13px",
    height: "20px",
};

function PartnersDetails() {
    const config = {
        defaultExpanded: true,
    };
    const [filter, setFilter] = useState("");
    const dispatch = useDispatch();
    const history = useHistory();
    const [currentElement, setCurrentElement] = useState();
    const { getCollapseProps, getToggleProps, isExpanded } =
        useCollapse(config);
    const [openMappingDestinationDialog, setOpenMappingDestinationDialog] =
        React.useState(false);

    const [showRerunMapping, setShowRerunMapping] = React.useState(false);
    const [showShareReport, setShowShareReport] = React.useState(false);
    const [showConfirmRemoveModal, setShowConfirmRemoveModal] =
        React.useState(false);
    const [loadingRemove, setLoadingRemove] = React.useState(false);
    const [idToRemove, setIdToRemove] = React.useState();
    const [partnerIdToRemove, setPartnerIdToRemove] = React.useState();

    const {
        dataList,
        loading,
        inventories,
        loadingInventories,
        loadingLastUpdateCatalogsPeriodique,
        partnerSteps,
        loadingPartnerSteps,
    } = useSelector((state) => ({
        loadingLastUpdateCatalogsPeriodique:
            state?.partnerReducer.loadingLastUpdateCatalogsPeriodique,
        dataList:
            state?.partnerReducer.lastUpdateCatalogs?.map((e) => {
                const mapped_hotels = e?.mapped_hotels ?? 0;
                const invalid_hotels = e?.invalid_hotels ?? 0;
                const unmapped_hotels = e?.unmapped_hotels ?? 0;
                const total_hotels = e?.total_hotels ?? 0;
                const pourcentageMappedHotels =
                    total_hotels !== 0
                        ? (mapped_hotels / total_hotels) * 100
                        : 0;
                const pourcentageInvalidHotels =
                    total_hotels !== 0
                        ? (invalid_hotels / e?.total_hotels) * 100
                        : 0;
                const pourcentageUnmappedHotels =
                    total_hotels !== 0
                        ? (unmapped_hotels / e?.total_hotels) * 100
                        : 0;

                return {
                    ...e,
                    pourcentageInvalidHotels: pourcentageInvalidHotels,
                    pourcentageUnmappedHotels: pourcentageUnmappedHotels,
                    pourcentageMappedHotels: pourcentageMappedHotels,
                };
            }) ?? [],
        loading: state?.partnerReducer.loadingLastUpdateCatalogs,
        inventories: state.catalogReducer?.catalogs ?? [],
        loadingInventories: state.catalogReducer?.loading ?? [],
        loadingPartnerSteps: state.analyticsReducer.loadingPartnerSteps,
        partnerSteps: state.analyticsReducer.partnerSteps,
    }));

    const [displayedData, setDisplayedData] = React.useState(dataList);
    const [itemsPerPage, setItemsPerPage] = useState(12);
    const [currentItems, setCurrentItems] = useState();
    const [pageCount, setPageCount] = useState(0);
    const [data, setData] = useState(dataList);
    const [itemOffset, setItemOffset] = useState(0);
    const [showRunMappingModal, setShowRunMappingModal] = useState(false);

    useEffect(() => {
        dispatch(getCatalogs());
        dispatch(getPartnerCatalogsLastUpdate());
        dispatch(
            getPartnerCatalogsByStatus(undefined, [
                STATUS_PENDING,
                STATUS_PROCESSING,
            ])
        );
        dispatch(getCompletedSteps());
    }, []);

    // useEffect(() => {
    //     setData(dataList);
    // }, [dataList]);

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        setCurrentItems(displayedData.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(displayedData.length / itemsPerPage));
    }, [
        itemOffset,
        itemsPerPage,
        filter,
        loadingLastUpdateCatalogsPeriodique,
        loading,
        displayedData,
    ]);
    useEffect(() => {
        if (filter !== "") {
            const newData = dataList.filter((element) => {
                let match = false;

                let stringValue = element?.partner_name?.toString() ?? "";
                match =
                    match ||
                    stringValue
                        ?.toLowerCase()
                        ?.search(filter?.toLowerCase()) !== -1;
                return match;
            });

            setDisplayedData(newData);
        } else {
            setDisplayedData(dataList);
        }
    }, [filter, loadingLastUpdateCatalogsPeriodique, loading]);

    useEffect(() => {
        setInterval(() => {
            if (
                window.location?.pathname != "/sign-in" &&
                window.location.pathname == "/provider-content"
            ) {
                dispatch(
                    getPartnerCatalogsByStatusPeriodique(undefined, [
                        STATUS_PENDING,
                        STATUS_PROCESSING,
                    ])
                );
                dispatch(getPartnerCatalogsLastUpdatePeriodique());
            }
        }, 10000);
    }, []);

    useEffect(() => {
        dispatch({ type: CLEAR_PARTNER_HAS_CATALOG });
    }, []);

    const handlePageClick = (event) => {
        const newOffset =
            (event.selected * itemsPerPage) % displayedData.length;
        setItemOffset(newOffset);
    };

    let [switchView, setSwitchView] = useState(TABLE_VIEW);

    const OnSwitchViewChange = (checked) => {
        if (checked) {
            setSwitchView(CARD_VIEW);
        } else {
            setSwitchView(TABLE_VIEW);
        }
    };

    const handleDelete = async () => {
        setLoadingRemove(true);
        try {
            let res = await PartnerService.deletePartnerCatalogs(
                partnerIdToRemove
            );
            if (res.status === 200) {
                setLoadingRemove(false);
                setShowConfirmRemoveModal(false);
                dispatch(getPartnerCatalogsLastUpdate());
            } else {
                toast.error("Something went wrong while deleting this catalog");
                setShowConfirmRemoveModal(false);
                setLoadingRemove(false);
            }
        } catch (err) {
            toast.error("Something went wrong while deleting this catalog");
            setShowConfirmRemoveModal(false);
            setLoadingRemove(false);
        }
    };
    return (
        <>
            <Helmet>
                <title>Partners content</title>
            </Helmet>
            <div className="page-wrapper" style={{ display: "block" }}>
                <div className="page-breadcrumb">
                    <div className="row">
                        <div className="col-12 align-self-center">
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
                                    Partners content
                                </h3>
                            </div>
                        </div>
                        <div className="col-12">
                            <p className="card-text">
                                <span className="sub-title-info ">
                                    <i className="fa  fa-info-circle"></i>
                                    &nbsp;Your partner catalogs are mapped
                                    against your inventory and the resulting
                                    mapping are displayed here, grouped by
                                    partner.
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                {loadingInventories || loadingPartnerSteps ? (
                    <div className="text-center">
                        <div className="lds-ripple">
                            <div className="lds-pos"></div>
                            <div className="lds-pos"></div>
                        </div>
                    </div>
                ) : partnerSteps?.has_inventory === false ? (
                    <CatalogNoDataModal
                        title="Ready!"
                        text2="Your dedicated workspace is almost ready to go !"
                        text3="First, you’ll need to upload your hotel inventory."
                        buttonText="Upload inventory"
                        handleClick={async () => {
                            history.push("/catalogs-manager");
                        }}
                        hideButton={
                            hasPermission(CREATE_INVENTORY) ? false : true
                        }
                    />
                ) : currentItems?.length === 0 &&
                  inventories?.find(
                      (e) =>
                          (e?.mapping_process_status_id === STATUS_PROCESSING ||
                              e?.mapping_process_status_id ===
                                  STATUS_PENDING) &&
                          e?.active === true
                  ) ? (
                    <div className="row">
                        <div className="col-12">
                            <CatalogNoDataModal
                                title="Almost ready!"
                                text="Your inventory is still processing."
                                hideButton={true}
                            />
                        </div>
                    </div>
                ) : currentItems?.length === 0 && filter === "" ? (
                    <CatalogNoDataModal
                        title="Your dedicated workspace is almost ready to go !"
                        text="Before we start mapping your content, you just need to add your partner’s inventory."
                        buttonText=" Add new partner inventory"
                        handleClick={() =>
                            history.push("/partners-inventories")
                        }
                        hideButton={
                            hasPermission(CREATE_CATALOG) ? false : true
                        }
                    />
                ) : (
                    <div className="container-fluid">
                        <div className="row">
                            <PartnerOutdatedCatalog
                                hideRunMappingButton={inventories?.find(
                                    (e) =>
                                        e?.mapping_process_status_id ==
                                            STATUS_SUCCESS_CATALOG &&
                                        e?.active == true
                                )}
                            />
                            <PartnersDetailsProccessingList />

                            <div
                                className="col-lg12 col-md-12 col-sm-12"
                                style={{ marginBottom: "15px" }}
                            >
                                <ToggleSwitch
                                    id="switchView"
                                    checked={switchView === CARD_VIEW}
                                    onChange={OnSwitchViewChange}
                                />
                            </div>
                            {switchView === TABLE_VIEW ? (
                                <>
                                    <div className="col-lg-12 col-md-12">
                                        <div
                                            className="card-body"
                                            style={{ padding: "0px" }}
                                        >
                                            <div className="search-input">
                                                <span className="hevo-web-icon search-icon hevo-search">
                                                    <i className="fas fa-search"></i>
                                                </span>
                                                <input
                                                    style={{ width: "90%" }}
                                                    type="text"
                                                    name="filter"
                                                    value={filter}
                                                    onChange={(event) =>
                                                        setFilter(
                                                            event?.target?.value
                                                        )
                                                    }
                                                    placeholder="Search Partner"
                                                    autoComplete="off"
                                                    id="inte_input"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="col-lg-12 col-md-12"
                                        style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                        }}
                                    >
                                        <div
                                            className="col-lg-6 col-md-6"
                                            style={{
                                                paddingLeft: "0px",
                                                paddingRight: "0px",
                                            }}
                                        >
                                            <CardDots />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="">
                                            <div
                                                className="card-body"
                                                style={{ padding: "0px" }}
                                            >
                                                <div className="row">
                                                    {currentItems?.map(
                                                        (e, index) => {
                                                            return (
                                                                <div className="col-6 col-md-4 col-lg-4">
                                                                    <Card
                                                                        className="card-destination"
                                                                        onClick={() =>
                                                                            history.push(
                                                                                `/provider-content/details/${e?.partner_id}`,
                                                                                {
                                                                                    params: {
                                                                                        partner:
                                                                                            {
                                                                                                ...e,
                                                                                                id: e.partner_id,
                                                                                                affiliate_id: 0,
                                                                                            },
                                                                                    },
                                                                                }
                                                                            )
                                                                        }
                                                                    >
                                                                        <Row className="no-gutters">
                                                                            <Col
                                                                                md={
                                                                                    1
                                                                                }
                                                                                lg={
                                                                                    1
                                                                                }
                                                                            >
                                                                                <Avatar
                                                                                    name={e?.partner_name?.charAt(
                                                                                        0
                                                                                    )}
                                                                                    round={
                                                                                        true
                                                                                    }
                                                                                    size={
                                                                                        40
                                                                                    }
                                                                                    color="#222"
                                                                                    style={{
                                                                                        marginLeft:
                                                                                            "11px",
                                                                                        marginTop:
                                                                                            "25px",
                                                                                    }}
                                                                                />
                                                                            </Col>
                                                                            <Col>
                                                                                <BodyCard
                                                                                    e={
                                                                                        e
                                                                                    }
                                                                                    setShowRunMappingModal={
                                                                                        setShowRunMappingModal
                                                                                    }
                                                                                    setCurrentElement={
                                                                                        setCurrentElement
                                                                                    }
                                                                                    setIdToRemove={
                                                                                        setIdToRemove
                                                                                    }
                                                                                    setOpenMappingDestinationDialog={
                                                                                        setOpenMappingDestinationDialog
                                                                                    }
                                                                                    setShowShareReport={
                                                                                        setShowShareReport
                                                                                    }
                                                                                    setShowConfirmRemoveModal={
                                                                                        setShowConfirmRemoveModal
                                                                                    }
                                                                                    setPartnerIdToRemove={
                                                                                        setPartnerIdToRemove
                                                                                    }
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </Card>
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                                    <div className="col-lg-12 col-md-12">
                                                        {currentItems?.length >
                                                        0 ? (
                                                            <div
                                                                className="row"
                                                                style={{
                                                                    width: "100%",
                                                                    display:
                                                                        "flex",
                                                                    justifyContent:
                                                                        "center",
                                                                }}
                                                            >
                                                                <ReactPaginate
                                                                    className="react-pagination-div"
                                                                    breakClassName={
                                                                        "page-item"
                                                                    }
                                                                    breakLinkClassName={
                                                                        "page-link"
                                                                    }
                                                                    containerClassName={
                                                                        "pagination"
                                                                    }
                                                                    pageClassName={
                                                                        "page-item"
                                                                    }
                                                                    pageLinkClassName={
                                                                        "page-link"
                                                                    }
                                                                    previousClassName={
                                                                        "page-item"
                                                                    }
                                                                    previousLinkClassName={
                                                                        "page-link"
                                                                    }
                                                                    nextClassName={
                                                                        "page-item"
                                                                    }
                                                                    nextLinkClassName={
                                                                        "page-link"
                                                                    }
                                                                    activeClassName={
                                                                        "active"
                                                                    }
                                                                    breakLabel="..."
                                                                    nextLabel=" >"
                                                                    onPageChange={
                                                                        handlePageClick
                                                                    }
                                                                    pageRangeDisplayed={
                                                                        5
                                                                    }
                                                                    pageCount={
                                                                        pageCount
                                                                    }
                                                                    previousLabel="<"
                                                                    renderOnZeroPageCount={
                                                                        null
                                                                    }
                                                                />
                                                            </div>
                                                        ) : currentItems?.length ===
                                                              0 &&
                                                          filter !== "" ? (
                                                            <div
                                                                className="card"
                                                                style={{
                                                                    padding:
                                                                        "2rem",
                                                                    width: "100%",
                                                                }}
                                                            >
                                                                <div className="empty text-center">
                                                                    <p>
                                                                        Sorry,
                                                                        we
                                                                        couldn&apos;t
                                                                        find any
                                                                        partner
                                                                        for your
                                                                        search
                                                                        criteria.
                                                                        Please
                                                                        check
                                                                        your
                                                                        information
                                                                        and try
                                                                        again.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        ) : !loading ? (
                                                            <div
                                                                className="card"
                                                                style={{
                                                                    padding:
                                                                        "2rem",
                                                                    width: "100%",
                                                                }}
                                                            >
                                                                <div className="empty text-center">
                                                                    <p>
                                                                        No data
                                                                        to
                                                                        display.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <div className="col-lg-12 col-md-12">
                                    <PartnerDetailsTable dataList={dataList} />
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
            {openMappingDestinationDialog && (
                <MappingDestinationModal
                    show={openMappingDestinationDialog}
                    dataCell={currentElement}
                    handleClose={() => {
                        setOpenMappingDestinationDialog(false);
                    }}
                />
            )}
            {showShareReport && (
                <ShareMappingReport
                    show={showShareReport}
                    handleClose={() => {
                        setShowShareReport(false);
                    }}
                    //  partner={partner}
                    id={currentElement?.partner_id}
                    activeCatalogId={currentElement?.id}
                    pageType={MAPPINGS}
                    affiliateId={0}
                />
            )}
            {showConfirmRemoveModal && (
                <ConfirmAction
                    show={showConfirmRemoveModal}
                    onClose={() => setShowConfirmRemoveModal(false)}
                    currentElement={currentElement}
                    titleMessage="Warning !"
                    subTitleMessage="Are you sure you want to delete this mapping?"
                    colorCode="danger"
                    handleClick={handleDelete}
                    buttonText="Confirm"
                    disabled={loadingRemove}
                />
            )}
            {showRunMappingModal && (
                <RunMappingModal
                    open={showRunMappingModal}
                    handleClose={() => setShowRunMappingModal(false)}
                    currentElement={currentElement}
                />
            )}
        </>
    );
}
export default PartnersDetails;

const MappedCard = ({ e }) => {
    return (
        <Card.Text className="card-text-mapping-details">
            <Row className="no-gutters">
                <Col md={12} lg={12}>
                    <div
                        className="graph-details"
                        style={{
                            height: "20px",
                        }}
                    >
                        <OverlayTrigger
                            placement="top"
                            delay={{
                                show: 100,
                                hide: 100,
                            }}
                            overlay={renderTooltip(
                                `Mapped hotels : ${
                                    e?.mapped_hotels
                                        ? separator(e?.mapped_hotels)
                                        : 0
                                } (${
                                    e?.pourcentageMappedHotels?.toFixed(
                                        2,
                                        10
                                    ) ?? 0
                                }%)`
                            )}
                        >
                            <div
                                className="graph-item-details"
                                style={{
                                    backgroundColor: "#15BFAE",
                                    width: `${e?.pourcentageMappedHotels}%`,
                                    height: "20px",
                                }}
                            ></div>
                        </OverlayTrigger>
                        <OverlayTrigger
                            placement="top"
                            delay={{
                                show: 100,
                                hide: 100,
                            }}
                            overlay={renderTooltip(
                                `Not Mapped hotels : ${
                                    e?.unmapped_hotels
                                        ? separator(e?.unmapped_hotels)
                                        : 0
                                } (${
                                    e?.pourcentageUnmappedHotels?.toFixed(
                                        2,
                                        10
                                    ) ?? 0
                                }%)`
                            )}
                        >
                            <div
                                className="graph-item-details"
                                style={{
                                    backgroundColor: "#F25F29",
                                    width: `${
                                        e?.pourcentageUnmappedHotels ?? 0
                                    }%`,
                                    height: "20px",
                                }}
                            ></div>
                        </OverlayTrigger>
                        {e?.model !== GIATA_MODEL && (
                            <OverlayTrigger
                                placement="top"
                                delay={{
                                    show: 100,
                                    hide: 100,
                                }}
                                overlay={renderTooltip(
                                    `Invalid hotels : ${
                                        e?.invalid_hotels
                                            ? separator(e?.invalid_hotels)
                                            : 0
                                    } (${
                                        e?.pourcentageInvalidHotels?.toFixed(
                                            2,
                                            10
                                        ) ?? 0
                                    }%)`
                                )}
                            >
                                <div
                                    className="graph-item-details"
                                    style={{
                                        backgroundColor: "#122e4024",
                                        width: `${
                                            e?.pourcentageInvalidHotels ?? 0
                                        }%`,
                                        height: "20px",
                                    }}
                                ></div>
                            </OverlayTrigger>
                        )}
                    </div>
                </Col>
            </Row>
        </Card.Text>
    );
};

const statusBadgeVariant = (status) => {
    switch (status) {
        case STATUS_PENDING:
            return {
                color: "warning",
                status: "Pending",
            };
        case STATUS_FAILED:
            return {
                color: "danger",
                status: "Failed",
            };
        case STATUS_STOPPED:
            return {
                color: "cyan",
                status: "Stoppped",
            };
        case STATUS_IN_QUEUE:
            return {
                color: "cyan",
                status: "In queue",
            };
        default:
            return {
                color: "",
                status: "",
            };
    }
};

const ProccessingCard = ({ e }) => {
    return (
        <Card.Text className="card-text-mapping-details">
            <span
                text="white"
                className={`badge rounded-pill text-white bg-info`}
                style={{
                    ...CardStatusStyle,
                }}
            >
                <>{e?.model !== GIATA_MODEL ? "Processing" : e?.giata_step}</>
            </span>
        </Card.Text>
    );
};

const OutdatedCard = () => {
    return (
        <Card.Text className="card-text-mapping-details">
            <span
                text="white"
                className={`badge rounded-pill text-white bg-dark`}
                style={{
                    ...CardStatusStyle,
                }}
            >
                Not Mapped
            </span>
        </Card.Text>
    );
};

const BodyCard = ({
    e,
    setOpenMappingDestinationDialog,
    setCurrentElement,
    setShowShareReport,
    setShowRerunMapping,
    setIdToRemove,
    setShowConfirmRemoveModal,
    setPartnerIdToRemove,
    setShowRunMappingModal,
}) => {
    const handleRunMapping = () => {
        setCurrentElement(e);
        setShowRunMappingModal(true);
    };
    return (
        <Col>
            <Card.Body style={{ paddingRight: "0px" }}>
                <Card.Title style={{ ...CardTitleStyle }}>
                    <div
                        style={{
                            color: "#444",
                        }}
                    >
                        {e?.partner_name}

                        <div
                            style={{
                                ...TextDateStyle,
                            }}
                        >
                            Last update :&nbsp;
                            {e?.created_at &&
                            (e?.outdated || e?.partner_catalog_id === 0)
                                ? moment(new Date(e?.created_at)).format(
                                      "DD/MM/YYYY HH:mm"
                                  )
                                : moment(
                                      new Date(e?.catalog_created_at)
                                  ).format("DD/MM/YYYY HH:mm")}
                        </div>
                    </div>
                    {e?.outdated || e?.partner_catalog_id === 0 ? (
                        <div onClick={(e) => e.stopPropagation()}>
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip("Run mapping")}
                            >
                                <button
                                    type="button"
                                    className="btn"
                                    onClick={handleRunMapping}
                                >
                                    <i
                                        className="far fa-play-circle"
                                        style={{
                                            color: "#21CA7F",
                                            fontSize: "20px",
                                        }}
                                    ></i>
                                </button>
                            </OverlayTrigger>
                        </div>
                    ) : (
                        <div onClick={(e) => e.stopPropagation()}>
                            {((e?.mapping_process_status_id === STATUS_FAILED &&
                                hasPermission(DELETE_MAPPING) === true) ||
                                (e?.mapping_process_status_id !==
                                    STATUS_PROCESSING &&
                                    e?.mapping_process_status_id !==
                                        STATUS_PENDING)) && (
                                <>
                                    <DownloadCatalog
                                        dataCell={e}
                                        id={e?.partner_catalog_id}
                                        setOpenMappingDestinationDialog={
                                            setOpenMappingDestinationDialog
                                        }
                                        setCurrentElement={setCurrentElement}
                                        setShowShareReport={setShowShareReport}
                                        setShowRerunMapping={
                                            setShowRerunMapping
                                        }
                                        status={e?.mapping_process_status_id}
                                        affiliateId={0}
                                        hide={true}
                                        setIdToRemove={setIdToRemove}
                                        setShowConfirmRemoveModal={
                                            setShowConfirmRemoveModal
                                        }
                                        setPartnerIdToRemove={
                                            setPartnerIdToRemove
                                        }
                                        partnersPage={true}
                                        partnerId={e?.partner_id}
                                    />
                                </>
                            )}
                        </div>
                    )}
                </Card.Title>
                {e?.outdated || e?.partner_catalog_id === 0 ? (
                    <OutdatedCard
                        setShowRunMappingModal={setShowRunMappingModal}
                    />
                ) : e?.mapping_process_status_id === STATUS_PROCESSING ? (
                    <ProccessingCard e={e} />
                ) : e?.mapping_process_status_id === STATUS_PENDING ? (
                    <StatusCard status={STATUS_PENDING} />
                ) : e?.mapping_process_status_id === STATUS_FAILED ? (
                    <StatusCard status={STATUS_FAILED} />
                ) : e?.mapping_process_status_id === STATUS_IN_QUEUE ? (
                    <StatusCard status={STATUS_IN_QUEUE} />
                ) : e?.mapping_process_status_id === STATUS_STOPPED ? (
                    <StatusCard status={STATUS_STOPPED} />
                ) : (
                    <MappedCard e={e} />
                )}
            </Card.Body>
        </Col>
    );
};

const StatusCard = ({ status }) => {
    return (
        <Card.Text className="card-text-mapping-details">
            <span
                text="white"
                className={`badge rounded-pill text-white bg-${
                    statusBadgeVariant(status)?.color
                }
        `}
                style={{
                    ...CardStatusStyle,
                }}
            >
                {statusBadgeVariant(status)?.status}
            </span>
        </Card.Text>
    );
};

const CardDots = () => {
    return (
        <Card
            className="card-partner-details"
            style={{
                marginBottom: "17px",
                padding: "8px",
            }}
        >
            <Col md={12} lg={12}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <div>
                        <span
                            className="dot"
                            style={{
                                backgroundColor: "rgb(21, 191, 174)",
                            }}
                        ></span>
                        <span
                            style={{
                                fontSize: "small",
                            }}
                        >
                            &nbsp; Mapped hotels&nbsp;
                        </span>
                    </div>
                    <div>
                        <span
                            className="dot"
                            style={{
                                backgroundColor: "rgb(242, 95, 41)",
                            }}
                        ></span>
                        <span
                            style={{
                                fontSize: "small",
                            }}
                        >
                            &nbsp; Not Mapped hotels &nbsp;
                        </span>
                    </div>
                    <div>
                        <span
                            className="dot"
                            style={{
                                backgroundColor: "rgba(18, 46, 64, 0.14)",
                            }}
                        ></span>
                        <span
                            style={{
                                fontSize: "small",
                            }}
                        >
                            &nbsp; Invalid hotels
                        </span>
                    </div>
                </div>
            </Col>
        </Card>
    );
};
