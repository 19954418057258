import React from "react";
import { useDispatch } from "react-redux";
import Lottie from "react-lottie";
import animationData from "../../lotties/rocket.json";
import { CREATE_INVENTORY } from "../../utilis/const";
import { hasPermission } from "../../utilis/functions";

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};

function WelcomeDialog(props) {
    const { handleClose, uploadCatalog } = props;
    const dispatch = useDispatch();
    const onClose = () => {
        // dispatch({ type: HIDE_WELCOME_DIALOG });
        handleClose();
    };

    return (
        <div style={{ textAlign: "center" }}>
            <Lottie options={defaultOptions} width={300} />
            <h2 className="modalTitle">Ready !</h2>
            <p
                className="modalP"
                style={{ fontSize: "21px", fontWeight: "600" }}
            >
                Your dedicated workspace is almost ready to go !
            </p>
            <p
                className="modalP"
                style={{ fontSize: "21px", fontWeight: "600" }}
            >
                First, you’ll need to upload your hotel inventory{" "}
            </p>
            {hasPermission(CREATE_INVENTORY) && (
                <button
                    className="modalBtn btn waves-effect waves-light btn-rounded btn-primary"
                    onClick={() => {
                        onClose();
                        //  dispatch({ type: HIDE_WELCOME_DIALOG });
                        uploadCatalog();
                        window.analytics.track("Add new inventory (click)", {
                            userID: localStorage.getItem("userId"),
                            companyID: localStorage.getItem("companyId"),
                            companyName: localStorage.getItem("companyName"),
                            firstName: localStorage.getItem("firstName"),
                            lastName: localStorage.getItem("lastName"),
                            email: localStorage.getItem("email"),
                        });
                    }}
                >
                    Upload inventory
                </button>
            )}
        </div>
    );
}

export default WelcomeDialog;
