// Types for login
export const REFRESH_TOKEN = "REFRESH_TOKEN";

// Types for Upload catalog
export const GET_CATALOGS_SUCCESS = "GET_CATALOGS_SUCCESS";
export const GET_CATALOGS_FAIL = "GET_CATALOGS_FAIL";
export const GET_CATALOGS_LISTING = "GET_CATALOGS_LISTING";

// Types for mappings catalogs
export const GET_MAPPING_CATALOGS_SUCCESS = "GET_MAPPING_CATALOGS_SUCCESS";
export const GET_MAPPING_CATALOGS_SUCCESS_PERIODIQUE =
    "GET_MAPPING_CATALOGS_SUCCESS_PERIODIQUE";
export const GET_MAPPING_CATALOGS_FAIL = "GET_MAPPING_CATALOGS_FAIL";
export const GET_MAPPING_CATALOGS_LISTING = "GET_MAPPING_CATALOGS_LISTING";

//Types for Dashboard data
export const GET_DASHBOARD_DATA_SUCCESS = "GET_DASHBOARD_DATA_SUCCESS";
export const GET_DASHBOARD_DATA_FAIL = "GET_DASHBOARD_DATA_FAIL";
export const GET_DASHBOARD_DATA_LISTING = "GET_DASHBOARD_DATA_LISTING";

// Types for user connected
export const GET_USER_CONNECTED_SUCCESS = "GET_USER_CONNECTED_SUCCESS";
export const GET_USER_CONNECTED_LISTING = "GET_USER_CONNECTED_LISTING";
export const GET_USER_CONNECTED_FAIL = "GET_USER_CONNECTED_FAIL";

//Types for to review hotels
export const GET_CATALOG_HOTEL_TO_REVIEW_SUCCESS =
    "GET_CATALOG_HOTEL_TO_REVIEW_SUCCESS";
export const GET_CATALOG_HOTEL_TO_REVIEW_LISTING =
    "GET_CATALOG_HOTEL_TO_REVIEW_LISTING";
export const GET_CATALOG_HOTEL_TO_REVIEW_FAIL =
    "GET_CATALOG_HOTEL_TO_REVIEW_FAIL";
export const CLEAR_CATALOG_HOTEL_TO_REVIEW = "CLEAR_CATALOG_HOTEL_TO_REVIEW";
export const DISPLAY_FINISH_MESSAGE = "DISPLAY_FINISH_MESSAGE";

//Types for partners list
export const GET_PARTNERS_LIST_SUCCESS = "GET_PARTNERS_LIST_SUCCESS";
export const GET_PARTNERS_LIST_FAIL = "GET_PARTNERS_LIST_FAIL";
export const GET_PARTNERS_LIST_LISTING = "GET_PARTNERS_LIST_LISTING";

//Types for partner by id
export const GET_PARTNER_BY_ID_LISTING = "GET_PARTNER_BY_ID_LISTING";
export const GET_PARTNER_BY_ID_SUCCESS = "GET_PARTNER_BY_ID_SUCCESS";
export const GET_PARTNER_BY_ID_FAIL = "GET_PARTNER_BY_ID_FAIL";

export const GET_PARTNER_ATTRIBUTES_LISTING = "GET_PARTNER_ATTRIBUTES_LISTING";
export const GET_PARTNER_ATTRIBUTES_FAIL = "GET_PARTNER_ATTRIBUTES_FAIL";
export const GET_PARTNER_ATTRIBUTES_SUCCESS = "GET_PARTNER_ATTRIBUTES_SUCCESS";

export const GET_CATALOGS_FIRST_FAIL = "GET_CATALOGS_FIRST_FAIL";
export const GET_CATALOGS_FIRST_LISTING = "GET_CATALOGS_FIRST_LISTING";
export const GET_CATALOGS_FIRST_SUCCESS = "GET_CATALOGS_FIRST_LISTING";

export const GET_PARTNER_CATALOGS_LAST_UPDATE_FAIL =
    "GET_PARTNER_CATALOGS_LAST_UPDATE_FAIL";
export const GET_PARTNER_CATALOGS_LAST_UPDATE_LISTING =
    "GET_PARTNER_CATALOGS_LAST_UPDATE_LISTING";
export const GET_PARTNER_CATALOGS_LAST_UPDATE_LISTING_PERIODIQUE =
    "GET_PARTNER_CATALOGS_LAST_UPDATE_LISTING_PERIODIQUE";
export const GET_PARTNER_CATALOGS_LAST_UPDATE_SUCCESS =
    "GET_PARTNER_CATALOGS_LAST_UPDATE_SUCCESS";
export const GET_PARTNER_CATALOGS_LAST_UPDATE_SUCCESS_PERIODIQUE =
    "GET_PARTNER_CATALOGS_LAST_UPDATE_SUCCESS_PERIODIQUE";

export const GET_PARTNER_CATALOGS_BY_ID_FAIL =
    "GET_PARTNER_CATALOGS_BY_ID_FAIL";
export const GET_PARTNER_CATALOGS_BY_ID_LISTING =
    "GET_PARTNER_CATALOGS_BY_ID_LISTING";
export const GET_PARTNER_CATALOGS_BY_ID_SUCCESS =
    "GET_PARTNER_CATALOGS_BY_ID_SUCCESS";

//Types for progress  upload
export const GET_PROGRESS_CATALOG_SUCCESS = "GET_PROGRESS_CATALOG_SUCCESS";
export const GET_PROGRESS_CATALOG_FAIL = "GET_PROGRESS_CATALOG_FAIL";
export const GET_PROGRESS_CATALOG_LISTING = "GET_PROGRESS_CATALOG_LISTING";

//Types for get Catalog
export const GET_CATALOG_SUCCESS = "GET_CATALOG_SUCCESS";
export const GET_CATALOG_FAIL = "GET_CATALOG_FAIL";
export const GET_CATALOG_LISTING = "GET_CATALOG_LISTING";

//Types for Logout
export const USER_LOGOUT = "USER_LOGOUT";

//Types for Welcome dialog in catalog
export const SHOW_WELCOME_DIALOG = "SHOW_WELCOME_DIALOG";
export const HIDE_WELCOME_DIALOG = "HIDE_WELCOME_DIALOG";

//Types for Welcome dialog in catalog
export const SHOW_FINISH_PROCCESSING_DIALOG = "SHOW_FINISH_PROCCESSING_DIALOG";
export const HIDE_FINISH_PROCCESSING_DIALOG = "HIDE_FINISH_PROCCESSING_DIALOG";

//Types for open dialog add new cataog
export const OPEN_DIALOG_ADD_NEW_CATALOG = "OPEN_DIALOG_ADD_NEW_CATALOG";
export const HIDE_DIALOG_ADD_NEW_CATALOG = "HIDE_DIALOG_ADD_NEW_CATALOG";

//Types for email verification
export const VERIFY_EMAIL_SUCCESS = "VERIFY_EMAIL_SUCCESS";
export const VERIFY_EMAIL_LISTING = "VERIFY_EMAIL_LISTING";
export const VERIFY_EMAIL_FAIL = "VERIFY_EMAIL_FAIL";

//Types for email code verification
export const VERIFY_EMAIL_CODE_SUCCESS = "VERIFY_EMAIL_CODE_LISTING";
export const VERIFY_EMAIL_CODE_LISTING = "VERIFY_EMAIL_CODE_LISTING";
export const VERIFY_EMAIL_CODE_FAIL = "VERIFY_EMAIL_CODE_FAIL";

//Company details
export const GET_COMPANY_DATA = "GET_COMPANY_DATA";
export const GET_COMPANY_DATA_SUCCESS = "GET_COMPANY_DATA_SUCCESS";
export const GET_COMPANY_DATA_FAIL = "GET_COMPANY_DATA_FAIL";
export const UPDATE_COMPANY_DATA = "UPDATE_COMPANY_DATA";
export const UPDATE_COMPANY_DATA_SUCCESS = "UPDATE_COMPANY_DATA_SUCCESS";
export const UPDATE_COMPANY_DATA_FAIL = "UPDATE_COMPANY_DATA_FAIL";

//Team details
export const DELETE_TEAM_DATA = "DELETE_TEAM_DATA";
export const DELETE_TEAM_DATA_SUCCESS = "DELETE_TEAM_DATA_SUCCESS";
export const DELETE_TEAM_DATA_FAIL = "DELETE_TEAM_DATA_FAIL";
export const GET_TEAM_DATA_FAIL = "GET_TEAM_DATA_FAIL";
export const GET_TEAM_DATA_SUCCESS = "GET_TEAM_DATA_SUCCESS";
export const GET_TEAM_DATA = "GET_TEAM_DATA";
export const UPDATE_TEAM_DATA = "UPDATE_TEAM_DATA";
export const UPDATE_TEAM_DATA_SUCCESS = "UPDATE_TEAM_DATA_SUCCESS";
export const UPDATE_TEAM_DATA_FAIL = "UPDATE_TEAM_DATA_FAIL";
export const ADD_TEAM_DATA = "ADD_TEAM_DATA";
export const ADD_TEAM_DATA_SUCCESS = "ADD_TEAM_DATA_SUCCESS";
export const ADD_TEAM_DATA_FAIL = "ADD_TEAM_DATA_FAIL";

//Payment cards
export const GET_PAYMENT_CARDS_SUCCESS = "GET_PAYMENT_CARDS_SUCCESS";
export const GET_PAYMENT_CARDS_LISTING = "GET_PAYMENT_CARDS_LISTING";
export const GET_PAYMENT_CARDS_FAIL = "GET_PAYMENT_CARDS_FAIL";

//Auto-pilot
export const GET_AFFILIATE_PARTNER_SUCCESS = "GET_AFFILIATE_PARTNER_SUCCESS";
export const GET_AFFILIATE_PARTNER_LISTING = "GET_AFFILIATE_PARTNER_LISTING";
export const GET_AFFILIATE_PARTNER_FAIL = "GET_AFFILIATE_PARTNER_FAIL";

//Explore mapping
export const SEARCH_HOTELS_SUCCESS = "SEARCH_HOTELS_SUCCESS";
export const SEARCH_HOTELS_LISTING = "SEARCH_HOTELS_LISTING";
export const SEARCH_HOTELS_FAIL = "SEARCH_HOTELS_FAIL";
export const HOTEL_DETAILS_SUCCESS = "HOTEL_DETAILS_SUCCESS";
export const HOTEL_DETAILS_FAIL = "HOTEL_DETAILS_FAIL";
export const HOTEL_DETAILS_LISTING = "HOTEL_DETAILS_LISTING";

//Countries
export const GET_COUNTRIES_LIST_SUCCESS = "GET_COUNTRIES_LIST_SUCCESS";
export const GET_COUNTRIES_LIST_LISTING = "GET_COUNTRIES_LIST_LISTING";
export const GET_COUNTRIES_LIST_FAIL = "GET_COUNTRIES_LIST_FAIL";

//Auto complete hotel name
export const GET_AUTO_COMPLETE_HOTEL_NAME_SUCCESS =
    "GET_AUTO_COMPLETE_HOTEL_NAME_SUCCESS";
export const GET_AUTO_COMPLETE_HOTEL_NAME_LISTING =
    "GET_AUTO_COMPLETE_HOTEL_NAME_LISTING";
export const GET_AUTO_COMPLETE_HOTEL_NAME_FAIL =
    "GET_AUTO_COMPLETE_HOTEL_NAME_FAIL";

//Auto pilot connectors
export const GET_CONNECTORS_SUCCESS = "GET_CONNECTORS_SUCCESS";
export const GET_CONNECTORS_LISTING = "GET_CONNECTORS_LISTING";
export const GET_CONNECTORS_FAIL = "GET_CONNECTORS_FAIL";

//TravelgateCredentials
export const GET_TRAVELGATE_CRIDENTIALS_LISTING =
    "GET_TRAVELGATE_CRIDENTIALS_LISTING";
export const GET_TRAVELGATE_CRIDENTIALS_SUCCESS =
    "GET_TRAVELGATE_CRIDENTIALS_SUCCESS";
export const GET_TRAVELGATE_CRIDENTIALS_FAIL =
    "GET_TRAVELGATE_CRIDENTIALS_FAIL";

//Auto pilot cridentials
export const GET_CRIDENTIALS_SUCCESS = "GET_CRIDENTIALS_SUCCESS";
export const GET_CRIDENTIALS_LISTING = "GET_CRIDENTIALS_LISTING";
export const GET_CRIDENTIALS_FAIL = "GET_CRIDENTIALS_FAIL";

//Destinations
export const GET_DESTINATION_LIST_DATA_FAIL = "GET_DESTINATION_LIST_DATA_FAIL";
export const GET_DESTINATION_LIST_DATA_LISTING =
    "GET_DESTINATION_LIST_DATA_LISTING";
export const GET_DESTINATION_LIST_DATA_SUCCESS =
    "GET_DESTINATION_LIST_DATA_SUCCESS";

export const GET_DESTINATION_CREDENTIALS_DATA_FAIL =
    "GET_DESTINATION_CREDENTIALS_DATA_FAIL";
export const GET_DESTINATION_CREDENTIALS_DATA_LISTING =
    "GET_DESTINATION_CREDENTIALS_DATA_LISTING";
export const GET_DESTINATION_CREDENTIALS_DATA_SUCCESS =
    "GET_DESTINATION_CREDENTIALS_DATA_SUCCESS";

// Check if partner has active catalog
export const CHECK_PARTNER_CATALOGS_LISTING = "CHECK_PARTNER_CATALOGS_LISTING";
export const CHECK_PARTNER_CATALOGS_SUCCESS = "CHECK_PARTNER_CATALOGS_SUCCESS";
export const CHECK_PARTNER_CATALOGS_FAIL = "CHECK_PARTNER_CATALOGS_FAIL";
export const CLEAR_PARTNER_HAS_CATALOG = "CLEAR_PARTNER_HAS_CATALOG";

//Get partner grouped catalogs

export const GET_PARTNER_GROUPED_LIST_FAILD = "GET_PARTNER_GROUPED_LIST_FAILD";
export const GET_PARTNER_GROUPED_LIST_SUCCESS =
    "GET_PARTNER_GROUPED_LIST_SUCCESS";
export const GET_PARTNER_GROUPED_LIST_LISTING =
    "GET_PARTNER_GROUPED_LIST_LISTING";

//Analytics

/* MAPPING NUMBER */
export const GET_MAPPING_NUMBER_SUCCESS = "GET_MAPPING_NUMBER_SUCCESS";
export const GET_MAPPING_NUMBER_FAIL = "GET_MAPPING_NUMBER_FAIL";
export const GET_MAPPING_NUMBER_LISTING = "GET_MAPPING_NUMBER_LISTING";

/* Supplier outdated */
export const GET_SUPPLIER_OUTDATED_SUCCESS = "GET_SUPPLIER_OUTDATED_SUCCESS";
export const GET_SUPPLIER_OUTDATED_FAIL = "GET_SUPPLIER_OUTDATED_FAIL";
export const GET_SUPPLIER_OUTDATED_LISTING = "GET_SUPPLIER_OUTDATED_LISTING";

/* Suppliers exclusive content */
export const GET_SUPPLIER_EXCLUSIVE_CONTENT_SUCCESS =
    "GET_SUPPLIER_EXCLUSIVE_CONTENT_SUCCESS";
export const GET_SUPPLIER_EXCLUSIVE_CONTENT_LISTING =
    "GET_SUPPLIER_EXCLUSIVE_CONTENT_LISTING";
export const GET_SUPPLIER_EXCLUSIVE_CONTENT_FAIL =
    "GET_SUPPLIER_EXCLUSIVE_CONTENT_FAIL";

/* Top supplier */
export const GET_TOP_SUPPLIER_SUCCESS = "GET_TOP_SUPPLIER_SUCCESS";
export const GET_TOP_SUPPLIER_LISTING = "GET_TOP_SUPPLIER_LISTING";
export const GET_TOP_SUPPLIER_FAIL = "GET_TOP_SUPPLIER_FAIL";

/* list of inventory with count */
export const GET_INVENTORY_COUNT_SUCCESS = "GET_INVENTORY_COUNT_SUCCESS";
export const GET_INVENTORY_COUNT_LISTING = "GET_INVENTORY_COUNT_LISTING";
export const GET_INVENTORY_COUNT_FAIL = "GET_INVENTORY_COUNT_FAIL";

/* list of inventory unmapped */
export const GET_INVENTORY_UNMAPPED_SUCCESS = "GET_INVENTORY_UNMAPPED_SUCCESS";
export const GET_INVENTORY_UNMAPPED_LISTING = "GET_INVENTORY_UNMAPPED_LISTING";
export const GET_INVENTORY_UNMAPPED_FAIL = "GET_INVENTORY_UNMAPPED_FAIL";

/* Timeserie mapped rate */
export const TIME_SERIE_MAPPED_RATE_LISTING = "TIME_SERIE_MAPPED_RATE_LISTING";
export const TIME_SERIE_MAPPED_RATE_SUCCESS = "TIME_SERIE_MAPPED_RATE_SUCCESS";
export const TIME_SERIE_MAPPED_RATE_FAIL = "TIME_SERIE_MAPPED_RATE_FAIL";

/* Timeserie inventory mapped rate */
export const TIME_SERIE_INVENTORY_MAPPED_RATE_LISTING =
    "TIME_SERIE_INVENTORY_MAPPED_RATE_LISTING";
export const TIME_SERIE_INVENTORY_MAPPED_RATE_SUCCESS =
    "TIME_SERIE_INVENTORY_MAPPED_RATE_SUCCESS";
export const TIME_SERIE_INVENTORY_MAPPED_RATE_FAIL =
    "TIME_SERIE_INVENTORY_MAPPED_RATE_FAIL";

/* Get completed steps home page  */
export const GET_COMPLETED_STEPS_LISTING = "GET_COMPLETED_STEPS_LISTING";
export const GET_COMPLETED_STEPS_SUCCESS = "GET_COMPLETED_STEPS_SUCCESS";
export const GET_COMPLETED_STEPS_FAIL = "GET_COMPLETED_STEPS_FAIL";

/* Get credentials by id  */
export const GET_CRIDENTIALS_BY_ID_LISTING = "GET_CRIDENTIALS_BY_ID_LISTING";
export const GET_CRIDENTIALS_BY_ID_SUCCESS = "GET_CRIDENTIALS_BY_ID_SUCCESS";
export const GET_CRIDENTIALS_BY_ID_FAIL = "GET_CRIDENTIALS_BY_ID_FAIL";

/* Get credentials by id  */
export const GET_CATALOG_DUPLICATE_LISTING = "GET_CATALOG_DUPLICATE_LISTING";
export const GET_CATALOG_DUPLICATE_SUCCESS = "GET_CATALOG_DUPLICATE_SUCCESS";
export const GET_CATALOG_DUPLICATE_FAIL = "GET_CATALOG_DUPLICATE_FAIL";

// Get outadated catalogs
export const GET_OUTDATED_CATALOGS_SUCCESS = "GET_OUTDATED_CATALOGS_SUCCESS";
export const GET_OUTDATED_CATALOGS_FAIL = "GET_OUTDATED_CATALOGS_FAIL";
export const GET_OUTDATED_CATALOGS_LISTING = "GET_OUTDATED_CATALOGS_LISTING";

// Get segment list
export const GET_SEGMENT_LIST_SUCCESS = "GET_SEGMENT_LIST_SUCCESS";
export const GET_SEGMENT_LIST_FAIL = "GET_SEGMENT_LIST_FAIL";
export const GET_SEGMENT_LIST_LISTING = "GET_SEGMENT_LIST_LISTING";

// Get chains list
export const GET_CHAINS_LIST_SUCCESS = "GET_CHAINS_LIST_SUCCESS";
export const GET_CHAINS_LIST_FAIL = "GET_CHAINS_LIST_FAIL";
export const GET_CHAINS_LIST_LISTING = "GET_CHAINS_LIST_LISTING";

// Get chains list
export const GET_STARS_LIST_SUCCESS = "GET_STARS_LIST_SUCCESS";
export const GET_STARS_LIST_FAIL = "GET_STARS_LIST_FAIL";
export const GET_STARS_LIST_LISTING = "GET_STARS_LIST_LISTING";

// Get segment types list
export const GET_SEGMENT_TYPES_LIST_SUCCESS = "GET_SEGMENT_TYPES_LIST_SUCCESS";
export const GET_SEGMENT_TYPES_LIST_FAIL = "GET_SEGMENT_TYPES_LIST_FAIL";
export const GET_SEGMENT_TYPES_LIST_LISTING = "GET_SEGMENT_TYPES_LIST_LISTING";

// Get cities list
export const GET_CITIES_LIST_SUCCESS = "GET_CITIES_LIST_SUCCESS";
export const GET_CITIES_LIST_FAIL = "GET_CITIES_LIST_FAIL";
export const GET_CITIES_LIST_LISTING = "GET_CITIES_LIST_LISTING";

//Partner report

/* get total partner hotels*/
export const GET_TOTAL_PARTNER_HOTEL_SUCCESS =
    "GET_TOTAL_PARTNER_HOTEL_SUCCESS";
export const GET_TOTAL_PARTNER_HOTEL_FAIL = "GET_TOTAL_PARTNER_HOTEL_FAIL";
export const GET_TOTAL_PARTNER_HOTEL_LISTING =
    "GET_TOTAL_PARTNER_HOTEL_LISTING";

/* get active mapping rate*/
export const GET_ACTIVE_RATE_SUCCESS = "GET_ACTIVE_RATE_SUCCESS";
export const GET_ACTIVE_RATE_FAIL = "GET_ACTIVE_RATE_FAIL";
export const GET_ACTIVE_RATE_LISTING = "GET_ACTIVE_RATE_LISTING";

/* get mapping rate over time*/
export const GET_MAPPING_RATE_OVER_TIME_SUCCESS =
    "GET_MAPPING_RATE_OVER_TIME_SUCCESS";
export const GET_MAPPING_RATE_OVER_TIME_FAIL =
    "GET_MAPPING_RATE_OVER_TIME_FAIL";
export const GET_MAPPING_RATE_OVER_TIME_LISTING =
    "GET_MAPPING_RATE_OVER_TIME_LISTING";

/* get mapping rate over time*/
export const GET_NUMBER_MAPPING_PERFORMED_SUCCESS =
    "GET_NUMBER_MAPPING_PERFORMED_SUCCESS";
export const GET_NUMBER_MAPPING_PERFORMED_FAIL =
    "GET_NUMBER_MAPPING_PERFORMED_FAIL";
export const GET_NUMBER_MAPPING_PERFORMED_LISTING =
    "GET_NUMBER_MAPPING_PERFORMED_LISTING";

/* get properties unmapped*/
export const GET_PROPERTIES_UNMAPPED_SUCCESS =
    "GET_PROPERTIES_UNMAPPED_SUCCESS";
export const GET_PROPERTIES_UNMAPPED_FAIL = "GET_PROPERTIES_UNMAPPED_FAIL";
export const GET_PROPERTIES_UNMAPPED_LISTING =
    "GET_PROPERTIES_UNMAPPED_LISTING";

//Models
/**
 * GIATA
 **/
//get all partner keys
export const GET_ALL_PARTNER_KEYS_LISTING = "GET_ALL_PARTNER_KEYS_LISTING";
export const GET_ALL_PARTNER_KEYS_SUCCESS = "GET_ALL_PARTNER_KEYS_SUCCESS";
export const GET_ALL_PARTNER_KEYS_FAIL = "GET_ALL_PARTNER_KEYS_FAIL";

//get  partners list

export const GET_GIATA_PARTNERS_LIST_LISTING =
    "GET_GIATA_PARTNERS_LIST_LISTING";
export const GET_GIATA_PARTNERS_LIST_SUCCESS =
    "GET_GIATA_PARTNERS_LIST_SUCCESS";
export const GET_GIATA_PARTNERS_LIST_FAIL = "GET_GIATA_PARTNERS_LIST_FAIL";

//get cupid models

export const GET_CUPID_MODELS_LISTING = "GET_CUPID_MODELS_LISTING";
export const GET_CUPID_MODELS_SUCCESS = "GET_CUPID_MODELS_SUCCESS";
export const GET_CUPID_MODELS_FAIL = "GET_CUPID_MODELS_FAIL";

// get partners list every 5 s

export const GET_GIATA_PARTNERS_LIST_PERIODIQUE_SUCCESS =
    "GET_GIATA_PARTNERS_LIST_PERIODIQUE_SUCCESS";

// has giata account
export const HAS_GIATA_ACCOUNT_LISTING = "HAS_GIATA_ACCOUNT_LISTING";
export const HAS_GIATA_ACCOUNT_SUCCESS = "HAS_GIATA_ACCOUNT_SUCCESS";
export const HAS_GIATA_ACCOUNT_FAIL = "HAS_GIATA_ACCOUNT_FAIL";

//Refresh giata data
export const REFRESH_GIATA_DATA_LISTING = "REFRESH_GIATA_DATA_LISTING";
export const REFRESH_GIATA_DATA_SUCCESS = "REFRESH_GIATA_DATA_SUCCESS";
export const REFRESH_GIATA_DATA_FAIL = "REFRESH_GIATA_DATA_FAIL";

//PARTNER REPORT

//Countries
export const COUNTRY_REPORT_SUCCESS = "COUNTRY_REPORT_SUCCESS";
export const COUNTRY_REPORT_LISTING = "COUNTRY_REPORT_LISTING";
export const COUNTRY_REPORT_FAIL = "COUNTRY_REPORT_FAIL";

//Cities
export const CITY_REPORT_SUCCESS = "CITY_REPORT_SUCCESS";
export const CITY_REPORT_LISTING = "CITY_REPORT_LISTING";
export const CITY_REPORT_FAIL = "CITY_REPORT_FAIL";

//Chains
export const CHAIN_REPORT_LIST_SUCCESS = "CHAIN_REPORT_LIST_SUCCESS";
export const CHAIN_REPORT_SUCCESS = "CHAIN_REPORT_SUCCESS";
export const CHAIN_REPORT_LISTING = "CHAIN_REPORT_LISTING";
export const CHAIN_REPORT_FAIL = "CHAIN_REPORT_FAIL";

//Property Type
export const PROPERTY_TYPE_REPORT_LIST_SUCCESS =
    "PROPERTY_TYPE_REPORT_LIST_SUCCESS";
export const PROPERTY_TYPE_REPORT_SUCCESS = "PROPERTY_TYPE_REPORT_SUCCESS";
export const PROPERTY_TYPE_REPORT_LISTING = "PROPERTY_TYPE_REPORT_LISTING";
export const PROPERTY_TYPE_REPORT_FAIL = "PROPERTY_TYPE_REPORT_FAIL";

// Mapped hotel list

export const GET_MAPPED_HOTEL_LIST_LISTING = "GET_MAPPED_HOTEL_LIST_LISTING";
export const GET_MAPPED_HOTEL_LIST_SUCCESS = "GET_MAPPED_HOTEL_LIST_SUCCESS";
export const GET_MAPPED_HOTEL_LIST_FAIL = "GET_MAPPED_HOTEL_LIST_FAIL";

//Oppurtinuties

export const GET_OPPORTUNITIES_HOTEL_LIST_LISTING =
    "GET_OPPORTUNITIES_HOTEL_LIST_LISTING";
export const GET_OPPORTUNITIES_HOTEL_LIST_SUCCESS =
    "GET_OPPORTUNITIES_HOTEL_LIST_SUCCESS";
export const GET_OPPORTUNITIES_HOTEL_LIST_FAIL =
    "GET_OPPORTUNITIES_HOTEL_LIST_FAIL";

// Invalid hotels
export const GET_INVALID_HOTEL_LIST_LISTING = "GET_INVALID_HOTEL_LIST_LISTING";
export const GET_INVALID_HOTEL_LIST_SUCCESS = "GET_INVALID_HOTEL_LIST_SUCCESS";
export const GET_INVALID_HOTEL_LIST_FAIL = "GET_INVALID_HOTEL_LIST_FAIL";

// Inventory hotel code and name
export const GET_INVENTORY_HOTEL_CODE_NAME_LISTING =
    "GET_INVENTORY_HOTEL_CODE_NAME_LISTING";
export const GET_INVENTORY_HOTEL_CODE_NAME_SUCCESS =
    "GET_INVENTORY_HOTEL_CODE_NAME_SUCCESS";
export const GET_INVENTORY_HOTEL_CODE_NAME_FAIL =
    "GET_INVENTORY_HOTEL_CODE_NAME_FAIL";

// Inventory cities
export const GET_INVENTORY_CITIES_LISTING = "GET_INVENTORY_CITIES_LISTING";
export const GET_INVENTORY_CITIES_SUCCESS = "GET_INVENTORY_CITIES_SUCCESS";
export const GET_INVENTORY_CITIES_FAIL = "GET_INVENTORY_CITIES_FAIL";

// Inventory countries
export const GET_INVENTORY_COUNTRIES_LISTING =
    "GET_INVENTORY_COUNTRIES_LISTING";
export const GET_INVENTORY_COUNTRIES_SUCCESS =
    "GET_INVENTORY_COUNTRIES_SUCCESS";
export const GET_INVENTORY_COUNTRIES_FAIL = "GET_INVENTORY_COUNTRIES_FAIL";

// Header details
export const GET_HEADER_DETAILS_LISTING = "GET_HEADER_DETAILS_LISTING";
export const GET_HEADER_DETAILS_SUCCESS = "GET_HEADER_DETAILS_SUCCESS";
export const GET_HEADER_DETAILS_FAIL = "GET_HEADER_DETAILS_FAIL";

//Manual mapping

/*Return a list of filters and possible values*/
export const GET_FILTERS_LIST_LISTING = "GET_FILTERS_LIST_LISTING";
export const GET_FILTERS_LIST_SUCCESS = "GET_FILTERS_LIST_SUCCESS";
export const GET_FILTERS_LIST_FAIL = "GET_FILTERS_LIST_FAIL";

/*Returns a list hotels and their respective candidates */
export const GET_HOTELS_CANDIDATES_LISTING = "GET_HOTELS_CANDIDATES_LISTING";
export const GET_HOTELS_CANDIDATES_SUCCESS = "GET_HOTELS_CANDIDATES_SUCCESS";
export const GET_HOTELS_CANDIDATES_FAIL = "GET_HOTELS_CANDIDATES_FAIL";

/** Return partner previous mappings */
export const GET_HOTELS_HISTORY_LISTING = "GET_HOTELS_HISTORY_LISTING";
export const GET_HOTELS_HISTORY_SUCCESS = "GET_HOTELS_HISTORY_SUCCESS";
export const GET_HOTELS_HISTORY_FAIL = "GET_HOTELS_HISTORY_FAIL";

/** Get Hotel Ranking list */

export const GET_HOTEL_RANKING_SUCCESS = "GET_HOTEL_RANKING_SUCCESS";
export const GET_HOTEL_RANKING_LISTING = "GET_HOTEL_RANKING_LISTING";
export const GET_HOTEL_RANKING_FAIL = "GET_HOTEL_RANKING_FAIL";

/** Get Report info */

export const GET_REPORT_INFO_LISTING = "GET_REPORT_INFO_LISTING";
export const GET_REPORT_INFO_SUCCESS = "GET_REPORT_INFO_SUCCESS";
export const GET_REPORT_INFO_FAIL = "GET_REPORT_INFO_FAIL";

/** Partner reports : comparable mappings*/
export const GET_COMPARABLE_MAPPINGS_SUCCESS =
    "GET_COMPARABLE_MAPPINGS_SUCCESS";
export const GET_COMPARABLE_MAPPINGS_LISTING =
    "GET_COMPARABLE_MAPPINGS_LISTING";
export const GET_COMPARABLE_MAPPINGS_FAIL = "GET_COMPARABLE_MAPPINGS_FAIL";

/** get Compare mapping total */
export const GET_COMPARE_MAPPIING_TOTAL_LISTING =
    "GET_COMPARE_MAPPIING_TOTAL_LISTING";
export const GET_COMPARE_MAPPIING_TOTAL_SUCCESS =
    "GET_COMPARE_MAPPIING_TOTAL_SUCCESS";
export const GET_COMPARE_MAPPIING_TOTAL_FAIL =
    "GET_COMPARE_MAPPIING_TOTAL_FAIL";

/** get Compare  mapped */
export const GET_COMPARE_UNMAPPED_LISTING = "GET_COMPARE_UNMAPPED_LISTING";
export const GET_COMPARE_UNMAPPED_SUCCESS = "GET_COMPARE_UNMAPPED_SUCCESS";
export const GET_COMPARE_UNMAPPED_FAIL = "GET_COMPARE_UNMAPPED_FAIL";

/** get Compare unmapped */
export const GET_COMPARE_MAPPED_LISTING = "GET_COMPARE_MAPPED_LISTING";
export const GET_COMPARE_MAPPED_SUCCESS = "GET_COMPARE_MAPPED_SUCCESS";
export const GET_COMPARE_MAPPED_FAIL = "GET_COMPARE_MAPPED_FAIL";

/** get Search fields */
export const GET_SEARCH_FIELDS_LISTING = "GET_SEARCH_FIELDS_LISTING";
export const GET_SEARCH_FIELDS_SUCCESS = "GET_SEARCH_FIELDS_SUCCESS";
export const GET_SEARCH_FIELDS_FAIL = "GET_SEARCH_FIELDS_FAIL";

/** get Old hotels */
export const GET_OLD_HOTELS_LISTING = "GET_OLD_HOTELS_LISTING";
export const GET_OLD_HOTELS_FAIL = "GET_OLD_HOTELS_FAIL";
export const GET_OLD_HOTELS_SUCCESS = "GET_OLD_HOTELS_SUCCESS";

/** get Affiliate  header details */

export const GET_AFFILIATE_HEADER_DETAILS_SUCCESS =
    "GET_AFFILIATE_HEADER_DETAILS_SUCCESS";
export const GET_AFFILIATE_HEADER_DETAILS_FAIL =
    "GET_AFFILIATE_HEADER_DETAILS_FAIL";
export const GET_AFFILIATE_HEADER_DETAILS_LISTING =
    "GET_AFFILIATE_HEADER_DETAILS_LISTING";

/// get inventory connector credentials
export const GET_CONNECTOR_INVENTORY_CREDENTIALS_SUCCESS =
    "GET_CONNECTOR_INVENTORY_CREDENTIALS_SUCCESS";
export const GET_CONNECTOR_INVENTORY_CREDENTIALS_FAIL =
    "GET_CONNECTOR_INVENTORY_CREDENTIALS_FAIL";
export const GET_CONNECTOR_INVENTORY_CREDENTIALS_LISTING =
    "GET_CONNECTOR_INVENTORY_CREDENTIALS_LISTING";

/// get partner inventories list

export const GET_PARTNERS_INVENTORIES_LIST_LISTING =
    "GET_PARTNERS_INVENTORIES_LIST_LISTING";
export const GET_PARTNERS_INVENTORIES_LIST_SUCCESS =
    "GET_PARTNERS_INVENTORIES_LIST_SUCCESS";
export const GET_PARTNERS_INVENTORIES_LIST_LISTING_PERIODIQUE =
    "GET_PARTNERS_INVENTORIES_LIST_LISTING_PERIODIQUE";
export const GET_PARTNERS_INVENTORIES_LIST_FAIL =
    "GET_PARTNERS_INVENTORIES_LIST_FAIL";

// by id
export const GET_PARTNER_INVENTORIES_LIST_LISTING =
    "GET_PARTNER_INVENTORIES_LIST_LISTING";
export const GET_PARTNER_INVENTORIES_LIST_SUCCESS =
    "GET_PARTNER_INVENTORIES_LIST_SUCCESS";
export const GET_PARTNER_INVENTORIES_LIST_FAIL =
    "GET_PARTNER_INVENTORIES_LIST_FAIL";

//Static content
export const GET_HOTELS_INVENTORY_LIST_LISTING =
    "GET_HOTELS_INVENTORY_LIST_LISTING";
export const GET_HOTELS_INVENTORY_LIST_SUCCESS =
    "GET_HOTELS_INVENTORY_LIST_SUCCESS";
export const GET_HOTELS_INVENTORY_LIST_FAIL = "GET_HOTELS_INVENTORY_LIST_FAIL";

//Get active inventory
export const GET_ACTIVE_INVENTORY_LISTING = "GET_ACTIVE_INVENTORY_LISTING";
export const GET_ACTIVE_INVENTORY_SUCCESS = "GET_ACTIVE_INVENTORY_SUCCESS";
export const GET_ACTIVE_INVENTORY_FAIL = "GET_ACTIVE_INVENTORY_FAIL";

//get hotel reference details
export const GET_REFERENCE_HOTEL_SUCCESS = "GET_REFERENCE_HOTEL_SUCCESS";
export const GET_REFERENCE_HOTEL_FAIL = "GET_REFERENCE_HOTEL_FAIL";
export const GET_REFERENCE_HOTEL_LISTING = "GET_REFERENCE_HOTEL_LISTING";
