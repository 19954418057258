import React, { useState } from "react";
import Table from "../../components/Table/Table";
import ExploreMappingService from "../../services/ExploreMappingService";
import { toast } from "react-toastify";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import ConfirmAction from "../../components/ConfirmActionsModal";
import { hasPermission } from "../../utilis/functions";
import { REPORT_MAPPING_ERROR } from "../../utilis/const";
import { useDispatch } from "react-redux";
import { getHotelDetails } from "../../redux/actions/ExploreMappingActions";
import { useParams } from "react-router-dom";

function HotelMappingList(props) {
    const { data, hotel } = props;
    const dispatch = useDispatch();
    const [tableData, setTableData] = useState(data);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [currentElement, setCurrentElement] = useState();
    const [indexElement, setIndexElement] = useState();
    const [loading, setLoading] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const { id } = useParams();

    const handleReportErrorMapping = async () => {
        setLoading(true);
        const res = await ExploreMappingService.reportMappingError({
            catalog_id: currentElement?.catalog_id,
            catalog_hotel_code: currentElement?.hotel_code,
            inventory_id: currentElement?.inventory_id,
            inventory_hotel_code: hotel?.hotel_code,
            source: "cupid",
        });
        if (res.status === 200) {
            setShowMessage(true);
            dispatch(getHotelDetails(id));
            // setTableData(
            //     tableData?.filter((e, index) => index !== indexElement)
            // );
        } else {
            toast.error(res.message);
        }
        setLoading(false);
        setShowConfirmModal(false);
    };

    const renderTooltip = (props = (text) => (
        <Tooltip id="button-tooltip" {...props}>
            {text}
        </Tooltip>
    ));

    const columns = React.useMemo(() => {
        const required = [
            {
                name: <div className="table-title">Partner Hotel code</div>,
                selector: (e) => e?.hotel_code,
                cell: (e) => {
                    return <>{e?.hotel_code}</>;
                },
                sortable: true,
            },
            {
                name: <div className="table-title">Hotel Name</div>,
                selector: (e) => e?.hotel_name,
                cell: (e) => {
                    return <>{e?.hotel_name}</>;
                },
                sortable: true,
            },
            {
                name: <div className="table-title">Partner</div>,
                selector: (e) => e?.partner_name,
                cell: (e) => {
                    return <>{e?.partner_name}</>;
                },
                sortable: true,
            },
            {
                name: <div className="table-title">Catalog</div>,
                selector: (e) => e?.catalog_name,
                cell: (e) => {
                    return <>{e?.catalog_name}</>;
                },
                sortable: true,
            },
            {
                name: <div className="table-title">Address</div>,
                selector: (e) => e?.address,
                cell: (e) => {
                    return <>{e?.address}</>;
                },
                sortable: true,
            },
            {
                name: <div className="table-title">City</div>,
                selector: (e) => e?.city,
                cell: (e) => {
                    return <>{e?.city}</>;
                },
                sortable: true,
            },
            {
                name: <div className="table-title">Country</div>,
                selector: (e) => e?.country,
                cell: (e) => {
                    return <>{e?.country}</>;
                },
                sortable: true,
            },
            {
                name: <div className="table-title">Mapping error reported</div>,
                selector: (e) => e?.source,
                cell: (e) => {
                    return <>{e?.source}</>;
                },
                sortable: true,
            },
        ];
        let extra = [];
        if (hasPermission(REPORT_MAPPING_ERROR)) {
            extra.push({
                name: <div className="table-title">Actions</div>,
                cell: (e, index) => {
                    return (
                        <>
                            {!e?.is_mapping_error && e?.source === "" && (
                                <ReportMapping
                                    setCurrentElement={setCurrentElement}
                                    setShowConfirmModal={setShowConfirmModal}
                                    currentElement={e}
                                    indexElement={index}
                                    setIndexElement={setIndexElement}
                                />
                            )}
                            {(e?.is_mapping_error || e?.source !== "") && (
                                <OverlayTrigger
                                    placement="top"
                                    delay={{
                                        show: 250,
                                        hide: 400,
                                    }}
                                    overlay={renderTooltip(
                                        "Mapping error reported"
                                    )}
                                >
                                    <i
                                        className="fas fa-exclamation-triangle"
                                        style={{
                                            color: "rgb(254, 192, 106)",
                                        }}
                                    ></i>
                                </OverlayTrigger>
                            )}
                        </>
                    );
                },
                sortable: false,
            });
        }

        return [...required, ...extra];
    }, []);
    const conditionalRowStyles = [
        {
            when: (row) => row?.is_mapping_error || row?.source !== "",
            style: {
                backgroundColor: "#7c87984a",
            },
        },
    ];

    return (
        <>
            <div
                style={{
                    justifyContent: "flex-end",
                    marginTop: "10px",
                    display: "flex",
                }}
            >
                <span className="font-weight-medium">
                    <span
                        text="white"
                        className={`badge rounded-pill text-white bg-success`}
                        style={{ fontSize: "15px" }}
                    >
                        {tableData?.length ?? 0} Mapping results
                    </span>
                </span>
            </div>
            <Table
                data={tableData ?? []}
                columns={columns}
                conditionalRowStyles={conditionalRowStyles}
            />
            {setShowConfirmModal && (
                <ConfirmAction
                    show={showConfirmModal}
                    onClose={() => setShowConfirmModal(false)}
                    currentElement={currentElement}
                    titleMessage="Warning !"
                    subTitleMessage="Are you sure you want to report a mapping error?"
                    colorCode="danger"
                    handleClick={handleReportErrorMapping}
                    buttonText="Confirm"
                    disabled={loading}
                />
            )}
            {showMessage && (
                <ConfirmAction
                    show={showMessage}
                    onClose={() => setShowMessage(false)}
                    currentElement={currentElement}
                    subTitleMessage2="Thank you for reporting this mismatch.We will investigate the issue."
                    colorCode="primary"
                    handleClick={() => {
                        setShowMessage(false);
                    }}
                    buttonText="Close"
                    showButton={true}
                />
            )}
        </>
    );
}

function ReportMapping(props) {
    const {
        currentElement,
        setCurrentElement,
        setShowConfirmModal,
        indexElement,
        setIndexElement,
    } = props;
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Report mapping error
        </Tooltip>
    );

    return (
        <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}
        >
            <i
                className="far fa-flag"
                style={{ color: "red", cursor: "pointer" }}
                onClick={() => {
                    setCurrentElement(currentElement);
                    setShowConfirmModal(true);
                    setIndexElement(indexElement);
                }}
            ></i>
        </OverlayTrigger>
    );
}
export default HotelMappingList;
