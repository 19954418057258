/* eslint-disable import/no-anonymous-default-export */
import {
    GET_DASHBOARD_DATA_FAIL, 
    GET_DASHBOARD_DATA_LISTING,
    GET_DASHBOARD_DATA_SUCCESS,
    GET_USER_CONNECTED_LISTING,
    GET_USER_CONNECTED_SUCCESS,
    GET_USER_CONNECTED_FAIL
} from '../types'

const initialState = {
    dashboardData:[],
    userConnected:{},
    loading:false,
    loadingUserConnected:false
}

export default function(state = initialState, action){
    switch(action.type){
        case GET_DASHBOARD_DATA_LISTING:
            return {
                ...state,
                loading:true
            }   
        case GET_DASHBOARD_DATA_SUCCESS:
            return {
                ...state,
                dashboardData:action.payload,
                loading:false
            }   
        case GET_DASHBOARD_DATA_FAIL:
            return {
                ...state,
                loading:false
            }        
        case GET_USER_CONNECTED_LISTING:
            return {
                ...state,
                loadingUserConnected:true
            }   
        case GET_USER_CONNECTED_SUCCESS:
            return {
                ...state,
                userConnected:action.payload,
                loadingUserConnected:false
            }   
        case GET_USER_CONNECTED_FAIL:
            return {
                ...state,
                loadingUserConnected:false
            }
        default: return state
    }

}