import React, { useState, useEffect } from "react";
import Stepper from "react-stepper-horizontal";
import { toast } from "react-toastify";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import { Modal } from "react-bootstrap";
import PartnerService from "../../../../services/PartnerService";
import { useDispatch, useSelector } from "react-redux";
import { getPartnerList } from "../../../../redux/actions/PartnerActions";
import { TRAVEL_GATE_FORM } from "../../../../utilis/const";
import TravelgateCredentials from "./TravelgateCredentials";

function ConnectorChoise(props) {
    const { handleClose, partner } = props;
    const dispatch = useDispatch();
    const [hideBackButton, setHideBackButton] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedConnector, setSelectedConnector] = useState(null);
    const [showForm, setShowForm] = useState(true);
    const [cridentialsId, setCridentialsId] = useState(null);
    const [isPublic, setIsPublic] = useState(false);
    const [formData, setFormData] = React.useState({});
    const [showAddNewPartner, setShowAddNewPartner] = useState(false);
    const [partnerName, setPartnerName] = useState("");
    const [loadingPartnerCreation, setLoadingPartnerCreation] = useState(false);
    const [selectedMethod, setSelectedMethod] = useState("");
    const [listAttributes, setListAttributes] = useState([]);
    const [loadingSaveAttributes, setLoadingSaveAttributes] =
        React.useState(false);
    const { partnerAttributes, loadingAttributes } = useSelector((state) => ({
        loadingAttributes: state.partnerReducer.loadingAttributes,
        partnerAttributes: state.partnerReducer.partnerAttributes ?? [],
    }));
    const [showPartnerAttributes, setShowPartnerAttributes] =
        React.useState(false);
    const [isTravelgateHub, setIsTravelgateHub] = useState(false);
    const [travelgateCredValues, setTravelgateCredValues] = useState({});
    const [files, setFiles] = useState([]);
    const [selectTravelgateConfig, setSelectTravelgateConfig] =
        useState(TRAVEL_GATE_FORM);

    useEffect(() => {
        setListAttributes(partnerAttributes);
    }, [loadingAttributes]);

    const sections = React.useMemo(() => {
        let required = [];
        if (!partner) {
            required.push({
                title: "Select partner",
            });
        }
        required = [
            ...required,
            {
                title: "Select type",
            },
        ];
        let extra = [];
        if (isTravelgateHub === true) {
            extra.push({
                title: "Travelgate Credentials",
            });
        }

        return [
            ...required,
            ...extra,
            {
                title: "Credentials",
            },
        ];
    }, [isTravelgateHub]);

    const next = async () => {
        if (currentPage === 1) {
            if (!partner) {
                SubmitFirstStep();
            } else {
                setCurrentPage((prev) => prev + 1);
            }
        } else if (currentPage === 2 && selectedConnector === null) {
            toast.error("Please select a connector", {
                toastId: "partner-catalog-connector-step2",
            });
        } else {
            setCurrentPage((prev) => prev + 1);
        }
    };
    const SubmitFirstStep = async () => {
        if (selectedOption?.value === undefined && !showAddNewPartner) {
            toast.error("Partner is required to pass", {
                toastId: "partner-select-catalog-csv-partner-step1",
            });
        } else if (showAddNewPartner && partnerName === "") {
            toast.error("Partner name is required to pass", {
                toastId: "partner-catalog-csv-name-step1",
            });
        } else if (showAddNewPartner && partnerName !== "") {
            const option = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            setLoadingPartnerCreation(true);
            const res = await PartnerService.addNewPartner(partnerName, option);
            if (res?.status === 200) {
                setLoadingPartnerCreation(false);
                setShowAddNewPartner(false);
                dispatch(getPartnerList());
                setSelectedOption({
                    value: res?.data?.partnerId,
                    label: partnerName,
                });
                setPartnerName("");
                setCurrentPage((prev) => prev + 1);
            } else {
                setLoadingPartnerCreation(false);
                toast.error(res?.message);
            }
        } else {
            setCurrentPage((prev) => prev + 1);
        }
    };
    const prev = () => setCurrentPage((prev) => prev - 1);
    const renderSteps = () => {
        switch (currentPage) {
            case 1:
                return (
                    <Step1
                        selectedOption={selectedOption}
                        setSelectedOption={setSelectedOption}
                        next={next}
                        setSelectedConnector={setSelectedConnector}
                        setIsPublic={setIsPublic}
                        showAddNewPartner={showAddNewPartner}
                        setShowAddNewPartner={setShowAddNewPartner}
                        partnerName={partnerName}
                        setPartnerName={setPartnerName}
                        loadingPartnerCreation={loadingPartnerCreation}
                        partner={partner}
                        loadingSaveAttributes={loadingSaveAttributes}
                        showPartnerAttributes={showPartnerAttributes}
                        setShowPartnerAttributes={setShowPartnerAttributes}
                        listAttributes={listAttributes}
                        setListAttributes={setListAttributes}
                        handleClose={handleClose}
                    />
                );

            case 2:
                return (
                    <Step2
                        partner={selectedOption ?? partner}
                        selectedConnector={selectedConnector}
                        setSelectedConnector={setSelectedConnector}
                        next={next}
                        prev={prev}
                        setShowForm={setShowForm}
                        setCridentialsId={setCridentialsId}
                        setIsPublic={setIsPublic}
                        setSelectedMethod={setSelectedMethod}
                        setIsTravelgateHub={setIsTravelgateHub}
                        handleClose={handleClose}
                        setFiles={setFiles}
                        setFormData={setFormData}
                        setSelectTravelgateConfig={setSelectTravelgateConfig}
                    />
                );

            case 3:
                return (
                    <Step3
                        selectedConnector={selectedConnector}
                        partner={selectedOption ?? partner}
                        prev={prev}
                        showForm={showForm}
                        cridentialsId={cridentialsId}
                        handleClose={handleClose}
                        formData={formData}
                        setFormData={setFormData}
                        isPublic={isPublic}
                        setHideBackButton={setHideBackButton}
                        hideBackButton={hideBackButton}
                        partnerExist={partner}
                        setLoadingSaveAttributes={setLoadingSaveAttributes}
                        listAttributes={listAttributes}
                        isTravelgateHub={isTravelgateHub}
                        setSelectTravelgateConfig={setSelectTravelgateConfig}
                        selectTravelgateConfig={selectTravelgateConfig}
                        files={files}
                        setFiles={setFiles}
                    />
                );
            default:
                return <></>;
        }
    };
    const renderStepsWithoutFirstStep = () => {
        switch (currentPage) {
            case 1:
                return (
                    <Step2
                        hideBackButton={partner}
                        partner={selectedOption ?? partner}
                        selectedConnector={selectedConnector}
                        setSelectedConnector={setSelectedConnector}
                        next={next}
                        prev={prev}
                        setShowForm={setShowForm}
                        setCridentialsId={setCridentialsId}
                        setIsPublic={setIsPublic}
                        setSelectedMethod={setSelectedMethod}
                        setIsTravelgateHub={setIsTravelgateHub}
                        handleClose={handleClose}
                        setFiles={setFiles}
                        setFormData={setFormData}
                        setSelectTravelgateConfig={setSelectTravelgateConfig}
                    />
                );

            case 2:
                return (
                    <Step3
                        selectedConnector={selectedConnector}
                        partner={selectedOption ?? partner}
                        prev={prev}
                        showForm={showForm}
                        cridentialsId={cridentialsId}
                        handleClose={handleClose}
                        formData={formData}
                        setFormData={setFormData}
                        isPublic={isPublic}
                        setHideBackButton={setHideBackButton}
                        hideBackButton={hideBackButton}
                        partnerExist={partner}
                        setLoadingSaveAttributes={setLoadingSaveAttributes}
                        listAttributes={listAttributes}
                        isTravelgateHub={isTravelgateHub}
                        setSelectTravelgateConfig={setSelectTravelgateConfig}
                        selectTravelgateConfig={selectTravelgateConfig}
                        files={files}
                        setFiles={setFiles}
                        listPage={true}
                    />
                );
            default:
                return <></>;
        }
    };
    const renderStepsWithTravelGateCredentials = () => {
        switch (currentPage) {
            case 1:
                return (
                    <Step1
                        selectedOption={selectedOption}
                        setSelectedOption={setSelectedOption}
                        next={next}
                        setSelectedConnector={setSelectedConnector}
                        setIsPublic={setIsPublic}
                        showAddNewPartner={showAddNewPartner}
                        setShowAddNewPartner={setShowAddNewPartner}
                        partnerName={partnerName}
                        setPartnerName={setPartnerName}
                        loadingPartnerCreation={loadingPartnerCreation}
                        partner={partner}
                        loadingSaveAttributes={loadingSaveAttributes}
                        showPartnerAttributes={showPartnerAttributes}
                        setShowPartnerAttributes={setShowPartnerAttributes}
                        listAttributes={listAttributes}
                        setListAttributes={setListAttributes}
                        handleClose={handleClose}
                    />
                );

            case 2:
                return (
                    <Step2
                        partner={selectedOption ?? partner}
                        selectedConnector={selectedConnector}
                        setSelectedConnector={setSelectedConnector}
                        next={next}
                        prev={prev}
                        setShowForm={setShowForm}
                        setCridentialsId={setCridentialsId}
                        setIsPublic={setIsPublic}
                        setIsTravelgateHub={setIsTravelgateHub}
                        handleClose={handleClose}
                        setFiles={setFiles}
                        setFormData={setFormData}
                        setSelectTravelgateConfig={setSelectTravelgateConfig}
                    />
                );
            case 3:
                return (
                    <TravelgateCredentials
                        partner={selectedOption ?? partner}
                        selectedConnector={selectedConnector}
                        setSelectedConnector={setSelectedConnector}
                        next={next}
                        prev={prev}
                        setShowForm={setShowForm}
                        setCridentialsId={setCridentialsId}
                        setIsPublic={setIsPublic}
                        travelgateCredValues={travelgateCredValues}
                        setTravelgateCredValues={setTravelgateCredValues}
                        handleClose={handleClose}
                    />
                );
            case 4:
                return (
                    <Step3
                        selectedConnector={selectedConnector}
                        partner={selectedOption ?? partner}
                        prev={prev}
                        showForm={showForm}
                        cridentialsId={cridentialsId}
                        handleClose={handleClose}
                        formData={formData}
                        setFormData={setFormData}
                        isPublic={isPublic}
                        selectedMethod={selectedMethod}
                        setHideBackButton={setHideBackButton}
                        hideBackButton={hideBackButton}
                        partnerExist={partner}
                        setLoadingSaveAttributes={setLoadingSaveAttributes}
                        listAttributes={listAttributes}
                        isTravelgateHub={isTravelgateHub}
                        setSelectTravelgateConfig={setSelectTravelgateConfig}
                        selectTravelgateConfig={selectTravelgateConfig}
                        files={files}
                        setFiles={setFiles}
                    />
                );
            default:
                return <></>;
        }
    };
    const renderStepsWithTravelGateCredentialsWithoutFirstStep = () => {
        switch (currentPage) {
            case 1:
                return (
                    <Step2
                        hideBackButton={partner}
                        partner={selectedOption ?? partner}
                        selectedConnector={selectedConnector}
                        setSelectedConnector={setSelectedConnector}
                        next={next}
                        prev={prev}
                        setShowForm={setShowForm}
                        setCridentialsId={setCridentialsId}
                        setIsPublic={setIsPublic}
                        setIsTravelgateHub={setIsTravelgateHub}
                        handleClose={handleClose}
                        setFiles={setFiles}
                        setFormData={setFormData}
                        setSelectTravelgateConfig={setSelectTravelgateConfig}
                    />
                );
            case 2:
                return (
                    <TravelgateCredentials
                        partner={selectedOption ?? partner}
                        selectedConnector={selectedConnector}
                        setSelectedConnector={setSelectedConnector}
                        next={next}
                        prev={prev}
                        setShowForm={setShowForm}
                        setCridentialsId={setCridentialsId}
                        setIsPublic={setIsPublic}
                        travelgateCredValues={travelgateCredValues}
                        setTravelgateCredValues={setTravelgateCredValues}
                        handleClose={handleClose}
                    />
                );
            case 3:
                return (
                    <Step3
                        selectedConnector={selectedConnector}
                        partner={selectedOption ?? partner}
                        prev={prev}
                        showForm={showForm}
                        cridentialsId={cridentialsId}
                        handleClose={handleClose}
                        formData={formData}
                        setFormData={setFormData}
                        isPublic={isPublic}
                        selectedMethod={selectedMethod}
                        setHideBackButton={setHideBackButton}
                        hideBackButton={hideBackButton}
                        partnerExist={partner}
                        setLoadingSaveAttributes={setLoadingSaveAttributes}
                        listAttributes={listAttributes}
                        isTravelgateHub={isTravelgateHub}
                        setSelectTravelgateConfig={setSelectTravelgateConfig}
                        selectTravelgateConfig={selectTravelgateConfig}
                        files={files}
                        setFiles={setFiles}
                    />
                );
            default:
                return <></>;
        }
    };

    return (
        <Modal.Body style={{ paddingBottom: "0px" }}>
            <div className="card-header p-0 pb-2">
                <Stepper
                    steps={sections}
                    activeStep={currentPage}
                    activeColor="#D3D7DB"
                    defaultBarColor="#D3D7DB"
                    completeColor="#6076E8"
                    completeBarColor="#6076E8"
                    fontFamily="Roboto"
                    textColor="#fff"
                    defaultBorderStyle="solid 6px"
                    defaultBorderWidth={10}
                />
            </div>
            <div className="card-body">
                {isTravelgateHub && partner
                    ? renderStepsWithTravelGateCredentialsWithoutFirstStep()
                    : isTravelgateHub && !partner
                    ? renderStepsWithTravelGateCredentials()
                    : !isTravelgateHub && partner
                    ? renderStepsWithoutFirstStep()
                    : renderSteps()}
            </div>
        </Modal.Body>
    );
}
export default ConnectorChoise;
