import axios from "axios";
const PATH_URL = process.env.REACT_APP_BASE_URL_AUTH;
const PATH_URL_DASHBOARD = process.env.REACT_APP_BASE_URL;

var CancelToken = axios.CancelToken;
var cancel;

const PartnerReportService = {
    searchInventoryHotelId: async (token, inventory_hotel_id) => {
        const result = await axios
            .get(PATH_URL + `reports/${token}/inventory-hotel-id`, {
                params: {
                    q: inventory_hotel_id,
                },
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                return {
                    data: res.data?.data,
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: error.response.data.message,
                    status: error.response.data.code,
                };
            });
        return result;
    },
    searchPartnerHotelId: async (token, partner_hotel_id) => {
        const result = await axios
            .get(PATH_URL + `reports/${token}/partner-hotel-id`, {
                params: {
                    q: partner_hotel_id,
                },
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                return {
                    data: res.data?.data,
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: error.response.data.message,
                    status: error.response.data.code,
                };
            });
        return result;
    },
    searchInventoryHotelName: async (token, inventory_hotel_name) => {
        const result = await axios
            .get(PATH_URL + `reports/${token}/inventory-hotel-name`, {
                params: {
                    q: inventory_hotel_name,
                },
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                return {
                    data: res.data?.data,
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: error.response.data.message,
                    status: error.response.data.code,
                };
            });
        return result;
    },
    searchCities: async (token, city) => {
        const result = await axios
            .get(PATH_URL + `reports/${token}/inventory-cities`, {
                params: {
                    q: city,
                },
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                return {
                    data: res.data?.data,
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: error.response.data.message,
                    status: error.response.data.code,
                };
            });
        return result;
    },
    getReportToken: async (mapping_id, token) => {
        const result = await axios
            .post(
                !token
                    ? PATH_URL_DASHBOARD + `/reports`
                    : `${PATH_URL.replace(
                          "dashboard",
                          ""
                      )}external-manual-mapping/reports?token=${token}`,
                { mapping_id: mapping_id },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem(
                            "token"
                        )}`,
                    },
                }
            )
            .then((res) => {
                return {
                    data: res.data?.data,
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: error.response?.data?.message,
                    status: error.response?.data?.code,
                };
            });
        return result;
    },
    autocompleteSearch: async (token, mapping_id, filter, serachValue) => {
        if (cancel !== undefined) {
            cancel();
        }
        const result = await axios
            .get(
                PATH_URL +
                    `reports/${token}/compare/${mapping_id}/filters/${filter}`,
                {
                    cancelToken: new CancelToken(function executor(c) {
                        // An executor function receives a cancel function as a parameter
                        cancel = c;
                    }),
                    params: {
                        search: serachValue,
                    },
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem(
                            "token"
                        )}`,
                    },
                }
            )
            .then((res) => {
                return {
                    data: res.data?.data,
                    status: 200,
                };
            })
            .catch((error) => {
                console.log("error", error);
                return {
                    message: error?.response?.data?.message,
                    status: error?.response?.data?.code,
                };
            });
        return result;
    },
};
export default PartnerReportService;
