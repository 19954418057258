import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import moment from "moment";
import LoginService from "./services/LoginService";
import axios from "axios";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import { createBrowserHistory } from "history";
import { CookiesProvider } from "react-cookie";

const token = localStorage.getItem("token");
const PATHNAME_SIGN_IN = "/sign-in";
const PATHNAME_SIGN_UP = "/sign-up";

const history = createBrowserHistory();

let prevPath = null;

// listen and notify Segment of client-side page updates
history.listen((location) => {
    if (location.pathname !== prevPath) {
        prevPath = location.pathname;
        window.analytics.page();
    }
});
axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const status = error.response?.status || 500;
        const AUTHORIZATION_MESSAGE_1 =
            "token contains an invalid number of segments";
        const AUTHORIZATION_MESSAGE_2 = "Token is expired";
        if (
            status === 401 &&
            (error.response.data.message?.toLowerCase() ===
                AUTHORIZATION_MESSAGE_1.toLocaleLowerCase() ||
                error.response.data.message?.toLowerCase() ===
                    AUTHORIZATION_MESSAGE_2.toLocaleLowerCase()) &&
            window.location?.pathname != PATHNAME_SIGN_IN &&
            window.location?.pathname != PATHNAME_SIGN_UP
        ) {
            setTimeout(() => {
                window.location =
                    window.location.protocol +
                    "//" +
                    window.location.host +
                    "/sign-in";
            }, 3000);
            localStorage.clear();
            return toast.error(" Session expired", {
                toastId: "session-expired-id",
            });
        }
        return Promise.reject(error); // Delegate error to calling side
    }
);

setInterval(() => {
    if (
        token &&
        window.location?.pathname != PATHNAME_SIGN_IN &&
        window.location?.pathname != PATHNAME_SIGN_UP
    ) {
        LoginService.refreshToken().then((res) => {
            if (res?.status === 200) {
                var now = moment(new Date(), "DD/MM/YYYY HH:mm:ss");
                var expire = moment(
                    new Date(res?.data?.expire),
                    "DD/MM/YYYY HH:mm:ss"
                );
                var diffTime = moment.duration(expire?.diff(now));
                localStorage.setItem("token", res?.data?.token);
                localStorage.setItem(
                    "expireDuration",
                    diffTime.asMilliseconds()
                );
                localStorage.setItem("expire", res?.data?.expire);
            }
        });
    }
}, 900000);

Sentry.init({
    dsn: "https://a0a2c99c81b34fa986c10abd3435d05c@o1141414.ingest.sentry.io/6223011",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

ReactDOM.render(
    <React.Fragment>
        <CookiesProvider>
                <App />
        </CookiesProvider>
    </React.Fragment>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
