import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useHistory } from "react-router-dom";
import {
    getDestinations,
    getDestinationCredentials,
} from "../../redux/actions/DestinationActions";
import { useDispatch, useSelector } from "react-redux";
import DestinationService from "../../services/DestinationService";
import ConfirmAction from "../../components/ConfirmActionsModal";
import { toast } from "react-toastify";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { FILE_FORMAT, DATABASE, WEBHOOK } from "../../utilis/const";
import { Card, Row, Col } from "react-bootstrap";
import CatalogNoDataModal from "../../pages/Mapping/CatalogNoDataModal";
import { CREATE_INVENTORY, CREATE_CATALOG } from "../../utilis/const";
import { hasPermission } from "../../utilis/functions";
import { getCompletedSteps } from "../../redux/actions/AnalyticsActions";

import CustomMenu from "./CustomMenu";
const schema = yup.object().shape({
    email: yup
        .string()
        .email("Must be a valid email")
        .required("Email is required"),
    //  password: yup.string().required("Password is required"),
});

const destinations = [
    {
        alt: "MySQL",
        src: "../assets/images/mySql-logo.png",
        height: "70",
        width: "70",
        name: "Mysql",
        type: DATABASE,
        configured: true,
    },
    {
        alt: "Amazon Aurora for MySQL",
        src: "../assets/images/mysql-aurora.png",
        height: "60",
        width: "58",
        name: "Amazon Aurora for MySQL",
        type: DATABASE,
        configured: false,
    },

    {
        alt: "BigQuery",
        src: "../assets/images/bigQuery.png",
        height: "60",
        width: "60",
        name: "Big Query",
        type: DATABASE,
        configured: true,
    },
    {
        alt: "Redshift",
        src: "../assets/images/redshift.png",
        height: "60",
        width: "60",
        name: "Redshift",
        type: DATABASE,
        configured: true,
    },
    {
        alt: "PostgreSQL",
        src: "../assets/images/postgresql.png",
        height: "60",
        width: "60",
        name: "PostgreSQL",
        type: DATABASE,
        configured: true,
    },
    {
        alt: "MS SQL Server",
        src: "../assets/images/msSqlServer.png",
        height: "60",
        width: "60",
        name: "MS SQL Server",
        type: DATABASE,
        configured: true,
    },
    {
        alt: "FTP",
        src: "../assets/images/ftp.png",
        height: "60",
        width: "60",
        name: "FTP",
        type: FILE_FORMAT,
        configured: false,
    },
    {
        alt: "Amazon S3",
        src: "../assets/images/amazon-s3.png",
        height: "50",
        width: "50",
        name: "Amazon S3",
        type: FILE_FORMAT,
        configured: false,
    },
    {
        alt: "Google cloud storage",
        src: "../assets/images/gc.png",
        height: "60",
        width: "60",
        name: "Google cloud storage",
        type: FILE_FORMAT,
        configured: true,
    },
];

function DestinationsList() {
    const [filter, setFilter] = React.useState("");
    const [confirmDeleteAction, setConfirmDeleteAction] = React.useState(false);
    const [currentElement, setCurrentElement] = React.useState();
    const [loadingDelete, setLoadingDelete] = React.useState(false);
    const {
        destinationsList,
        loading,
        loadingCredentials,
        partnerSteps,
        loadingPartnerSteps,
    } = useSelector((state) => ({
        destinationsList: state.destinationReducer?.destinationList?.data ?? [],
        loading: state.destinationReducer?.loading,
        loadingCredentials: state.destinationReducer?.loadingCredentials,
        loadingPartnerSteps: state.analyticsReducer.loadingPartnerSteps,
        partnerSteps: state.analyticsReducer.partnerSteps,
    }));

    const [displayedData, setDisplayedData] = React.useState(destinationsList);
    const history = useHistory();
    const dispatch = useDispatch();
    const {
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        if (filter !== "") {
            const newData = destinationsList.filter((element) => {
                let match = false;

                let stringValue = element?.name?.toString() ?? "";
                match =
                    match ||
                    stringValue
                        ?.toLowerCase()
                        ?.search(filter?.toLowerCase()) !== -1;
                return match;
            });

            setDisplayedData(newData);
        } else {
            setDisplayedData(destinationsList);
        }
    }, [filter, loading]);

    const handleRemove = async () => {
        setLoadingDelete(true);
        const res = await DestinationService.deleteConfiguration(
            currentElement?.id
        );
        if (res.status === 200) {
            toast.success("Configuration deleted successfully");
            setLoadingDelete(false);
            dispatch(getDestinations());
            setConfirmDeleteAction(false);
        } else {
            toast.success(res.message);
            setLoadingDelete(false);
        }
    };

    const renderTooltip = (text) => (props) =>
        (
            <Tooltip id="button-tooltip" {...props}>
                {text}
            </Tooltip>
        );

    const renderElement = (name, type) => {
        return (
            <fieldset className="choose-destination">
                <legend className="scheduler-border">{name}</legend>
                <div className="row">
                    {displayedData
                        ?.filter((e) => e?.data_type === type)
                        ?.map((e, index) => {
                            return (
                                <div className="col-6 col-md-4 col-lg-3">
                                    <Card
                                        className="card-destination"
                                        onClick={async () => {
                                            if (!confirmDeleteAction) {
                                                if (e?.configured) {
                                                    await dispatch(
                                                        getDestinationCredentials(
                                                            e?.id
                                                        )
                                                    );
                                                }
                                                history.push(
                                                    `/export-destinations/${e?.id}`,
                                                    {
                                                        params: {
                                                            form: e?.form,
                                                            uiForm: e?.ui_form_schema,
                                                            description:
                                                                e?.description,
                                                            name: e?.name,
                                                            id: e?.id,
                                                            updateCredentials:
                                                                e?.configured
                                                                    ? true
                                                                    : false,
                                                        },
                                                    }
                                                );
                                            }
                                        }}
                                    >
                                        <Row className="no-gutters">
                                            <Col md={2} lg={2}>
                                                <Card.Img
                                                    variant="top"
                                                    style={{
                                                        width: "40px",
                                                        marginLeft: "20px",
                                                        marginTop: "25px",
                                                    }}
                                                    src={e?.logo}
                                                />
                                            </Col>
                                            <Col>
                                                <Card.Body
                                                    style={{
                                                        paddingRight: "0px",
                                                    }}
                                                >
                                                    <Card.Title
                                                        style={{
                                                            display: "flex",
                                                            marginBottom: "0px",
                                                            justifyContent:
                                                                "space-between",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                color: "#6876e8",
                                                            }}
                                                        >
                                                            {e?.name}
                                                            {e?.configured && (
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    delay={{
                                                                        show: 250,
                                                                        hide: 400,
                                                                    }}
                                                                    overlay={renderTooltip(
                                                                        "Already configured"
                                                                    )}
                                                                >
                                                                    <i
                                                                        className="fas fa-check-circle"
                                                                        style={{
                                                                            color: "#4fcb4ffa",
                                                                            marginLeft:
                                                                                "5px",
                                                                        }}
                                                                    ></i>
                                                                </OverlayTrigger>
                                                            )}
                                                        </div>
                                                        <div
                                                            onClick={(e) =>
                                                                e.stopPropagation()
                                                            }
                                                        >
                                                            <CustomMenu
                                                                handleDelete={async () => {
                                                                    setCurrentElement(
                                                                        e
                                                                    );
                                                                    setConfirmDeleteAction(
                                                                        true
                                                                    );
                                                                }}
                                                                currentElement={
                                                                    e
                                                                }
                                                            />
                                                        </div>
                                                    </Card.Title>
                                                    <Card.Text
                                                        style={{
                                                            marginTop: "0px",
                                                            color: "#808698",
                                                            font: "400 12px/16px Roboto,Helvetica Neue,sans-serif",
                                                            letterSpacing:
                                                                ".3px",
                                                        }}
                                                    >
                                                        {`Export mapping to ${e?.name}`}
                                                    </Card.Text>
                                                </Card.Body>
                                            </Col>
                                        </Row>
                                    </Card>
                                </div>
                            );
                        })}
                </div>
            </fieldset>
        );
    };

    useEffect(() => {
        dispatch(getDestinations());
        dispatch(getCompletedSteps());
    }, []);

    return (
        <>
            <Helmet>
                <title> Data Export</title>
            </Helmet>
            <div className="page-wrapper" style={{ display: "block" }}>
                <div className="page-breadcrumb">
                    <div className="row">
                        <div className="col-7 align-self-center">
                            <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
                                Data Export
                            </h3>
                        </div>
                        <div className="col-12">
                            <p className="card-text">
                                <span className="sub-title-info ">
                                    <i className="fa  fa-info-circle"></i>
                                    &nbsp;Data Export allow you to export your
                                    mapping results to the target of your
                                    choice. After you set up a destination,
                                    mappings are exported to it automatically
                                    once performed.
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                {loading || loadingCredentials || loadingPartnerSteps ? (
                    <div className="text-center">
                        <div className="lds-ripple">
                            <div className="lds-pos"></div>
                            <div className="lds-pos"></div>
                        </div>
                    </div>
                ) : partnerSteps?.has_inventory === false ? (
                    <CatalogNoDataModal
                        title="Ready!"
                        text2="Your dedicated workspace is almost ready to go !"
                        text3="First, you’ll need to upload your hotel inventory."
                        buttonText="Upload inventory"
                        handleClick={async () => {
                            history.push("/catalogs-manager");
                        }}
                        hideButton={
                            hasPermission(CREATE_INVENTORY) ? false : true
                        }
                    />
                ) : (
                    <div className="container-fluid">
                        <div className="search-input">
                            <span className="hevo-web-icon search-icon hevo-search">
                                <i className="fas fa-search"></i>
                            </span>
                            <input
                                style={{ width: "90%" }}
                                type="text"
                                name="filter"
                                value={filter}
                                onChange={(e) => setFilter(e?.target?.value)}
                                placeholder="Search"
                                autocomplete="off"
                                id="inte_input"
                            />
                        </div>
                        <div className="card" style={{ padding: "20px" }}>
                            {displayedData?.length === 0 && filter !== "" ? (
                                <div className="suggest-integration suggest-integration-container mb-0">
                                    <div className="no-gutters row justify-content-between">
                                        <div className="left-content col-md text-center text-md-left pr-4">
                                            <h2 className="font-weight-normal">
                                                <span className="d-block text-primary">
                                                    Can’t find your destination?
                                                </span>
                                                Please let us know, we will
                                                build it for you
                                            </h2>
                                            <form
                                                className="d-flex suggestion-integration-form mt-8 invalid submission-tried"
                                                onSubmit={() => {}}
                                            >
                                                <div className="input-group input-group--elevated email-control">
                                                    <input
                                                        type="text"
                                                        className="form-control2"
                                                        name="destination"
                                                        id="destination"
                                                        placeholder="Destination name"
                                                        autoComplete="off"
                                                    />
                                                    {errors?.email && (
                                                        <small
                                                            id="emailHelp"
                                                            className="text-danger form-text"
                                                        >
                                                            {
                                                                errors?.email
                                                                    ?.message
                                                            }
                                                        </small>
                                                    )}
                                                </div>
                                                <button
                                                    className="btn  btn-white btn-icon stacked-btn submit-btn ml-3"
                                                    type="submit"
                                                >
                                                    <i className="fas fa-long-arrow-alt-right"></i>
                                                </button>
                                            </form>
                                        </div>
                                        <img
                                            className="suggest-integration-illustration col-md-auto d-none d-md-block img-fluid"
                                            src="../assets/images/build-destination.png"
                                            alt="Build destination"
                                            style={{
                                                height: "170px",
                                                display: "flex",
                                                justifyContent: "center",
                                                marginTop: "18px",
                                                width: "450px",
                                            }}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <>
                                    {displayedData?.filter(
                                        (e) => e?.data_type === WEBHOOK
                                    )?.length > 0 &&
                                        renderElement("Webhook", WEBHOOK)}
                                    {displayedData?.filter(
                                        (e) => e?.data_type === FILE_FORMAT
                                    )?.length > 0 &&
                                        renderElement(
                                            "File format",
                                            FILE_FORMAT
                                        )}
                                    {displayedData?.filter(
                                        (e) => e?.data_type === DATABASE
                                    )?.length > 0 &&
                                        renderElement("Database", DATABASE)}
                                </>
                            )}
                        </div>
                    </div>
                )}
            </div>
            {confirmDeleteAction && (
                <ConfirmAction
                    show={confirmDeleteAction}
                    onClose={() => setConfirmDeleteAction(false)}
                    currentElement={currentElement}
                    titleMessage="Warning !"
                    subTitleMessage="Are you sure you want to delete this destination credentials?"
                    colorCode="danger"
                    handleClick={handleRemove}
                    buttonText="Confirm"
                    disabled={loadingDelete}
                />
            )}
        </>
    );
}
export default DestinationsList;
