import React from "react";
import { separator } from "../../utilis/functions";
import {
    STATUS_PROCESSING,
    STATUS_PENDING,
    GIATA_MODEL,
} from "../../utilis/const";
import { ListGroup, Spinner, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import useCollapse from "react-collapsed";
import { Link, useHistory } from "react-router-dom";
import ConfirmAction from "../../components/ConfirmActionsModal";
import PartnerCatalogService from "../../services/PartnerCatalogService";
import { toast } from "react-toastify";
import { getPartnerCatalogsByStatusPeriodique } from "../../redux/actions/PartnerCataogActions";

const renderTooltip = (name) => (props) =>
    (
        <Tooltip id="button-tooltip" {...props}>
            {name}
        </Tooltip>
    );

const PartnersDetailsProccessingList = (props) => {
    const config = {
        defaultExpanded: true,
    };
    const dispatch = useDispatch();
    const { getCollapseProps, getToggleProps, isExpanded } =
        useCollapse(config);
    const [currentElement, setCurrentElement] = React.useState({});
    const history = useHistory();
    const [showConfirmStopMapping, setShowConfirmStopMapping] =
        React.useState(false);
    const [loadingStopMapping, setLoadingStopMapping] = React.useState(false);

    const { partnerCatalogs } = useSelector((state) => ({
        partnerCatalogs:
            state?.partnerCatalogReducer.partnerCatalogs?.map((e, index) => {
                var rate = (
                    ((e?.valid_hotels - e?.mapped_hotels - e?.unmapped_hotels) *
                        1.6) /
                    1000 /
                    60
                ).toFixed(0, 10);
                return { ...e, rate: rate };
            }) ?? [],
    }));
    const handleStopMapping = async () => {
        setLoadingStopMapping(true);
        try {
            let res = await PartnerCatalogService.StopMapping(
                currentElement?.id,
                currentElement?.model
            );

            if (res.status === 200) {
                setLoadingStopMapping(false);
                setShowConfirmStopMapping(false);
                dispatch();
                dispatch(
                    getPartnerCatalogsByStatusPeriodique(undefined, [
                        STATUS_PENDING,
                        STATUS_PROCESSING,
                    ])
                );
            } else {
                toast.error(res.message);
                setLoadingStopMapping(false);
                setShowConfirmStopMapping(false);
            }
        } catch (err) {
            toast.error("Something went wrong while stopping this mapping");
            setLoadingStopMapping(false);
            setShowConfirmStopMapping(false);
        }
    };
    return (
        <>
            {partnerCatalogs?.length > 0 && (
                <>
                    <div className="col-lg-12 col-md-12">
                        <div
                            className="collapsible "
                            style={{
                                marginBottom: "20px",
                                border: "2px solid rgb(99 117 231 / 77%)",
                                borderRadius: "8px",
                            }}
                        >
                            <div className="header" {...getToggleProps()}>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div>
                                        <i
                                            className="fas fa-info-circle"
                                            style={{
                                                color: "rgb(254 192 106)",
                                                fontSize: "20px",
                                            }}
                                        ></i>
                                        &nbsp;{" "}
                                        {`You have ${
                                            partnerCatalogs?.filter(
                                                (e) =>
                                                    e?.mapping_process_status_id ===
                                                        STATUS_PROCESSING ||
                                                    e?.mapping_process_status_id ===
                                                        STATUS_PENDING
                                            )?.length
                                        } mapping currently processing. `}
                                    </div>
                                    <div>
                                        <>
                                            {isExpanded ? (
                                                <div
                                                    className="hideOldBlocs"
                                                    style={{
                                                        margin: "0px",
                                                    }}
                                                >
                                                    <h4>
                                                        <Link
                                                            style={{
                                                                fontSize:
                                                                    "17px",
                                                            }}
                                                        >
                                                            Hide details
                                                        </Link>
                                                    </h4>
                                                </div>
                                            ) : (
                                                <div
                                                    className="showOldBlocs"
                                                    style={{
                                                        margin: "0px",
                                                    }}
                                                >
                                                    <h4>
                                                        <Link
                                                            style={{
                                                                fontSize:
                                                                    "17px",
                                                            }}
                                                        >
                                                            More details
                                                        </Link>
                                                    </h4>
                                                </div>
                                            )}
                                        </>
                                    </div>
                                </div>
                            </div>
                            <div {...getCollapseProps()}>
                                <div className="content">
                                    <ListGroup variant="flush">
                                        {partnerCatalogs?.map((e, index) => {
                                            return (
                                                <ListGroup.Item
                                                    key={index}
                                                    style={{
                                                        backgroundColor:
                                                            "#eeeffd",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                        history.push(
                                                            `/provider-content/details/${e?.partner_id}`,
                                                            {
                                                                params: {
                                                                    partner: {
                                                                        name: e.partner_name,
                                                                        id: e.partner_id,
                                                                        created_at:
                                                                            e?.created_at,
                                                                        giata_code:
                                                                            e?.giata_code,
                                                                        affiliate_id:
                                                                            e?.affiliate_id,
                                                                    },
                                                                },
                                                            }
                                                        );
                                                    }}
                                                >
                                                    <div className="row">
                                                        <div className="col-lg-3">
                                                            {e?.mapping_process_status_id ===
                                                                STATUS_PROCESSING ||
                                                            e?.mapping_process_status_id ===
                                                                STATUS_PENDING ? (
                                                                <Spinner
                                                                    animation="border"
                                                                    variant="primary"
                                                                    size="sm"
                                                                />
                                                            ) : (
                                                                <i
                                                                    className="fas fa-check"
                                                                    style={{
                                                                        color: "green",
                                                                    }}
                                                                ></i>
                                                            )}
                                                            &nbsp;&nbsp;
                                                            {e?.name}
                                                            {e?.mapping_process_status_id ===
                                                                STATUS_PROCESSING && (
                                                                <small
                                                                    style={{
                                                                        fontSize:
                                                                            "13px",
                                                                        color: "#21CA7F",
                                                                        fontWeight:
                                                                            "900",
                                                                        margin: "0px",
                                                                    }}
                                                                >
                                                                    {e?.model !==
                                                                    GIATA_MODEL ? (
                                                                        <>
                                                                            &nbsp;
                                                                            {`Estimated
                                                                                        time
                                                                                        ~
                                                                                        ${
                                                                                            e?.rate ??
                                                                                            0
                                                                                        }
                                                                                        min`}
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            &nbsp;
                                                                            {
                                                                                e?.giata_step
                                                                            }
                                                                        </>
                                                                    )}
                                                                </small>
                                                            )}
                                                        </div>
                                                        <div className="col-lg-2 expanded-div">
                                                            {`${e?.partner_name} `}
                                                        </div>
                                                        <div className="col-lg-2 expanded-div">
                                                            {`${
                                                                e?.total_hotels
                                                                    ? separator(
                                                                          e?.total_hotels
                                                                      )
                                                                    : 0
                                                            } Total `}
                                                            {e?.hotels_rate !==
                                                                0 &&
                                                                e?.hotels_rate !==
                                                                    null &&
                                                                e?.mapping_process_status_id ===
                                                                    STATUS_PENDING && (
                                                                    <>
                                                                        &nbsp;
                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            delay={{
                                                                                show: 250,
                                                                                hide: 400,
                                                                            }}
                                                                            overlay={renderTooltip(
                                                                                "Rate/Sec"
                                                                            )}
                                                                        >
                                                                            <small
                                                                                style={{
                                                                                    fontSize:
                                                                                        "13px",
                                                                                    color: "#21CA7F",
                                                                                    fontWeight:
                                                                                        "900",
                                                                                    margin: "0px",
                                                                                }}
                                                                            >
                                                                                {
                                                                                    e?.hotels_rate
                                                                                }
                                                                                /Sec
                                                                            </small>
                                                                        </OverlayTrigger>
                                                                    </>
                                                                )}
                                                        </div>

                                                        <div className="col-lg-2 expanded-div">
                                                            {`${
                                                                e?.mapped_hotels
                                                                    ? separator(
                                                                          e?.mapped_hotels
                                                                      )
                                                                    : 0
                                                            }
                                                                                     Mapped`}
                                                        </div>
                                                        <div className="col-lg-2 expanded-div">
                                                            {`${
                                                                e?.unmapped_hotels
                                                                    ? separator(
                                                                          e?.unmapped_hotels
                                                                      )
                                                                    : 0
                                                            } Opportunities`}
                                                        </div>
                                                        <div className="col-lg-1 expanded-div">
                                                            <OverlayTrigger
                                                                placement="top"
                                                                delay={{
                                                                    show: 250,
                                                                    hide: 400,
                                                                }}
                                                                overlay={renderTooltip(
                                                                    "Stop Mapping"
                                                                )}
                                                            >
                                                                <button
                                                                    type="button"
                                                                    className="btn"
                                                                    onClick={async (
                                                                        event
                                                                    ) => {
                                                                        event.stopPropagation();
                                                                        setCurrentElement(
                                                                            e
                                                                        );
                                                                        setShowConfirmStopMapping(
                                                                            true
                                                                        );
                                                                    }}
                                                                >
                                                                    <i
                                                                        className="fas fa-stop-circle"
                                                                        style={{
                                                                            color: "#FF2851",
                                                                            fontSize:
                                                                                "20px",
                                                                        }}
                                                                    ></i>
                                                                </button>
                                                            </OverlayTrigger>
                                                        </div>
                                                    </div>
                                                </ListGroup.Item>
                                            );
                                        })}
                                    </ListGroup>
                                </div>
                            </div>
                        </div>
                    </div>
                    {showConfirmStopMapping && (
                        <ConfirmAction
                            show={showConfirmStopMapping}
                            onClose={() => setShowConfirmStopMapping(false)}
                            currentElement={currentElement}
                            titleMessage="Warning !"
                            subTitleMessage="Are you sure you want to stop this mapping?"
                            colorCode="danger"
                            handleClick={handleStopMapping}
                            buttonText="Confirm"
                            disabled={loadingStopMapping}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default PartnersDetailsProccessingList;
