import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Select from "../../components/Select";
import { useSelector, useDispatch } from "react-redux";
import AutoPilotService from "../../services/AutoPilotService";
import { toast } from "react-toastify";
import { getAffiliatePartnerList } from "../../redux/actions/AutoPilotActions";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import PartnerService from "../../services/PartnerService";
import { getPartnerList } from "../../redux/actions/PartnerActions";
import { getSegmentsList } from "../../redux/actions/SegmentActions";

const schema1 = yup.object().shape({
    email: yup.string().email().required("Email is required"),
    partner_id: yup.object().required("Partner is required"),
});

const schema2 = yup.object().shape({
    email: yup.string().email().required("Email is required"),
});

const schema3 = yup.object().shape({
    partner_name: yup.string().required("Partner name  is required"),
});

function AddAffiliatePartner(props) {
    const {
        show,
        onClose,
        update,
        element,
        setElement,
        setSelectedPartnerId,
        setAddValues,
        setConfirmReplaceAction,
    } = props;
    const [loading, setLoading] = useState(false);
    const [addNewPartner, setAddNewPartner] = useState(false);
    const [refreshPartnerList, setRefreshPartnerList] = useState(false);
    const [selectedPartner, setSelectedPartner] = useState();
    const { partnerList, segments } = useSelector((state) => ({
        partnerList: state.partnerReducer.partners ?? [],
        segments:
            state.segmentReducer.segments?.data?.length > 0
                ? [
                      { id: 0, name: "Active inventory" },
                      ...state.segmentReducer.segments?.data,
                  ]
                : [{ id: 0, name: "Active inventory" }],
    }));
    const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
    } = useForm({
        resolver: yupResolver(update ? schema2 : schema1),
    });

    async function submit(e) {
        setLoading(true);
        if (update) {
            let res = await AutoPilotService.updateAffiliatePartner(e);
            if (res.status === 200) {
                toast.success("Updated!");
                dispatch(getAffiliatePartnerList());
                handleClose();
            } else {
                toast.error(res.message);
            }
        } else {
            let res = await AutoPilotService.checkAffiliateExists({
                email: e?.email,
                partner_id: parseInt(e.partner_id?.value, 10),
            });
            if (res.status === 200) {
                if (res?.data?.data?.email_affiliate_exists === true) {
                    toast.error(
                        "An affiliate with the same email already exists"
                    );
                } else if (res?.data?.data?.partner_affiliate_exists === true) {
                    setConfirmReplaceAction(true);
                    setAddValues({
                        ...e,
                        partner_id: parseInt(e.partner_id?.value, 10),
                        segment_id: parseInt(e.segment_id?.value, 10),
                    });
                    handleClose();
                } else {
                    let res = await AutoPilotService.addAffiliatePartner({
                        inventory_segment_id: parseInt(e.segment_id?.value, 10),
                        email: e?.email,
                        partner_id: parseInt(e.partner_id?.value, 10),
                    });
                    if (res?.status === 200) {
                        toast.success("Created!");
                        dispatch(getAffiliatePartnerList());
                        handleClose();
                    } else {
                        toast.error(res.message);
                    }
                }
            } else {
                toast.error(res.message);
            }
        }
        setLoading(false);
    }

    useEffect(() => {
        dispatch(getPartnerList());
    }, []);

    useEffect(() => {
        if (update) {
            setValue("email", element?.email);
            setValue("password", element?.password);
            setValue("username", element?.username);
            setValue("partner_id", element?.partner_id);
            setValue("partner_name", element?.partner_name);
            setValue("id", element?.id);
            setElement();
        }
    }, []);

    useEffect(() => {
        dispatch(getSegmentsList());
    }, []);

    const handleClose = () => {
        setSelectedPartnerId();
        onClose();
    };

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Create new partner
        </Tooltip>
    );

    useEffect(() => {
        if (selectedPartner) {
            setValue("partner_id", selectedPartner);
            setSelectedPartnerId(selectedPartner);
        }
    }, [selectedPartner]);

    return (
        <Modal
            show={show}
            onHide={handleClose}
            dialogClassName="modal-40w modal-dialog modal-dialog-centered"
        >
            <Modal.Title>
                <h3
                    style={{
                        fontWeight: 400,
                        color: "#6076E8",
                        marginTop: "25px",
                        textAlign: "center",
                        marginBottom: "0px",
                    }}
                >
                    {addNewPartner
                        ? "Add new partner"
                        : update
                        ? "Update affiliate "
                        : "Add affiliate"}
                </h3>
            </Modal.Title>
            <Modal.Body
                style={{
                    marginTop: "0px",
                    marginRight: "2rem",
                    marginLeft: "2rem",
                    marginBottom: "Opx",
                }}
            >
                {addNewPartner ? (
                    <AddNewPartner
                        handleClose={() => setAddNewPartner(false)}
                        setRefreshPartnerList={setRefreshPartnerList}
                        setSelectedPartnerId={setSelectedPartner}
                    />
                ) : (
                    <form className="mt-3" onSubmit={handleSubmit(submit)}>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="form-group">
                                    <label for="selectedPartnerId">
                                        Select your partner
                                    </label>
                                    {update ? (
                                        <input
                                            {...register("partner_name")}
                                            type="text"
                                            id="selectedPartnerId"
                                            className="form-control"
                                            disabled
                                        />
                                    ) : (
                                        <>
                                            <div style={{ display: "flex" }}>
                                                <div style={{ flexGrow: 1 }}>
                                                    <Select
                                                        options={partnerList?.map(
                                                            (e) => ({
                                                                value: e?.id,
                                                                label: e?.name,
                                                            })
                                                        )}
                                                        name="selectedPartnerId"
                                                        id="selectedPartnerId"
                                                        value={getValues(
                                                            "partner_id"
                                                        )}
                                                        onChange={(e) => {
                                                            setValue(
                                                                "partner_id",
                                                                e
                                                            );
                                                        }}
                                                    />
                                                </div>
                                                <OverlayTrigger
                                                    placement="top"
                                                    delay={{
                                                        show: 250,
                                                        hide: 400,
                                                    }}
                                                    overlay={renderTooltip}
                                                >
                                                    <button
                                                        type="button"
                                                        className="btn"
                                                        style={{
                                                            color: "rgb(62 89 227 / 70%)",
                                                        }}
                                                        onClick={() => {
                                                            setAddNewPartner(
                                                                true
                                                            );
                                                        }}
                                                    >
                                                        <i className="fas fa-plus"></i>
                                                    </button>
                                                </OverlayTrigger>
                                            </div>
                                            {errors.partner_id && (
                                                <small className="text-danger form-text">
                                                    {errors.partner_id.message}
                                                </small>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="form-group">
                                    <label for="email">Email</label>
                                    <input
                                        {...register("email")}
                                        type="text"
                                        id="email"
                                        className="form-control"
                                        placeholder="Enter your partner's email"
                                    />
                                    {errors.email && (
                                        <small className="text-danger form-text">
                                            {errors.email.message}
                                        </small>
                                    )}
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="form-group">
                                    <label for="selectedSegmentId">
                                        Select your inventory segment
                                    </label>
                                    <Select
                                        options={segments?.map((e) => ({
                                            value: e?.id,
                                            label: e?.name,
                                        }))}
                                        name="segment_id"
                                        id="selectedSegmentId"
                                        value={getValues("segment_id")}
                                        onChange={(e) => {
                                            setValue("segment_id", e);
                                        }}
                                        label="Segment"
                                    />
                                </div>
                            </div>
                        </div>
                        <Modal.Footer>
                            <button
                                className="btn btn-warning"
                                type="button"
                                onClick={handleClose}
                            >
                                Close
                            </button>
                            <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={loading}
                            >
                                Send invitation
                                {loading && (
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                )}
                            </button>
                        </Modal.Footer>
                    </form>
                )}
            </Modal.Body>
        </Modal>
    );
}
export default AddAffiliatePartner;

function AddNewPartner(props) {
    const { setSelectedPartnerId, handleClose } = props;
    const [loading, setLoading] = useState();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema3),
    });

    const dispatch = useDispatch();

    const submit = async (values) => {
        setLoading(true);

        const option = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        const res = await PartnerService.addNewPartner(
            values.partner_name,
            option
        );
        if (res?.status === 200) {
            setLoading(false);
            await dispatch(getPartnerList());
            setSelectedPartnerId({
                value: res?.data?.partnerId,
                label: values.partner_name,
            });
            handleClose();
            toast.success("Created!");
        } else {
            setLoading(false);
            toast.error(res?.message);
        }
    };
    return (
        <form className="mt-3" onSubmit={handleSubmit(submit)}>
            <div className="row">
                <div className="col-lg-12">
                    <div className="form-group">
                        <label for="partner_name"> Partner name </label>
                        <input
                            {...register("partner_name")}
                            type="text"
                            id="partner_name"
                            className="form-control"
                        />
                        {errors.password && (
                            <small className="text-danger form-text">
                                {errors.password.message}
                            </small>
                        )}
                    </div>
                </div>
            </div>
            <Modal.Footer>
                <button
                    className="btn waves-effect waves-light btn-rounded btn-warning"
                    type="button"
                    onClick={handleClose}
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    className="btn waves-effect waves-light btn-rounded btn-primary"
                    disabled={loading}
                >
                    Confirm
                    {loading && (
                        <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    )}
                </button>
            </Modal.Footer>
        </form>
    );
}
