/* eslint-disable import/no-anonymous-default-export */
import {
    COUNTRY_REPORT_SUCCESS,
    COUNTRY_REPORT_LISTING,
    COUNTRY_REPORT_FAIL,
    CITY_REPORT_SUCCESS,
    CITY_REPORT_LISTING,
    CITY_REPORT_FAIL,
    CHAIN_REPORT_LISTING,
    CHAIN_REPORT_SUCCESS,
    CHAIN_REPORT_FAIL,
    PROPERTY_TYPE_REPORT_LISTING,
    PROPERTY_TYPE_REPORT_SUCCESS,
    PROPERTY_TYPE_REPORT_FAIL,
    GET_MAPPED_HOTEL_LIST_LISTING,
    GET_MAPPED_HOTEL_LIST_SUCCESS,
    GET_MAPPED_HOTEL_LIST_FAIL,
    GET_OPPORTUNITIES_HOTEL_LIST_LISTING,
    GET_OPPORTUNITIES_HOTEL_LIST_SUCCESS,
    GET_OPPORTUNITIES_HOTEL_LIST_FAIL,
    GET_INVALID_HOTEL_LIST_LISTING,
    GET_INVALID_HOTEL_LIST_SUCCESS,
    GET_INVALID_HOTEL_LIST_FAIL,
    GET_INVENTORY_HOTEL_CODE_NAME_LISTING,
    GET_INVENTORY_HOTEL_CODE_NAME_SUCCESS,
    GET_INVENTORY_HOTEL_CODE_NAME_FAIL,
    GET_INVENTORY_CITIES_LISTING,
    GET_INVENTORY_CITIES_SUCCESS,
    GET_INVENTORY_CITIES_FAIL,
    GET_INVENTORY_COUNTRIES_LISTING,
    GET_INVENTORY_COUNTRIES_SUCCESS,
    GET_INVENTORY_COUNTRIES_FAIL,
    GET_HEADER_DETAILS_LISTING,
    GET_HEADER_DETAILS_SUCCESS,
    GET_HEADER_DETAILS_FAIL,
    GET_MAPPING_RATE_OVER_TIME_SUCCESS,
    GET_MAPPING_RATE_OVER_TIME_FAIL,
    GET_MAPPING_RATE_OVER_TIME_LISTING,
    GET_NUMBER_MAPPING_PERFORMED_SUCCESS,
    GET_NUMBER_MAPPING_PERFORMED_FAIL,
    GET_NUMBER_MAPPING_PERFORMED_LISTING,
    CHAIN_REPORT_LIST_SUCCESS,
    PROPERTY_TYPE_REPORT_LIST_SUCCESS,
    GET_REPORT_INFO_LISTING,
    GET_REPORT_INFO_SUCCESS,
    GET_REPORT_INFO_FAIL,
    GET_COMPARABLE_MAPPINGS_SUCCESS,
    GET_COMPARABLE_MAPPINGS_LISTING,
    GET_COMPARABLE_MAPPINGS_FAIL,
    GET_COMPARE_MAPPIING_TOTAL_LISTING,
    GET_COMPARE_MAPPIING_TOTAL_SUCCESS,
    GET_COMPARE_MAPPIING_TOTAL_FAIL,
    GET_COMPARE_MAPPED_LISTING,
    GET_COMPARE_MAPPED_SUCCESS,
    GET_COMPARE_MAPPED_FAIL,
    GET_COMPARE_UNMAPPED_LISTING,
    GET_COMPARE_UNMAPPED_SUCCESS,
    GET_COMPARE_UNMAPPED_FAIL,
    GET_SEARCH_FIELDS_LISTING,
    GET_SEARCH_FIELDS_SUCCESS,
    GET_SEARCH_FIELDS_FAIL,
    GET_OLD_HOTELS_LISTING,
    GET_OLD_HOTELS_FAIL,
    GET_OLD_HOTELS_SUCCESS,
} from "../types";

const initialState = {
    cityReportList: [],
    loadingCities: false,
    countryReportList: [],
    loadingCountries: false,
    chainReportList: [],
    chainReportListFilter: [],
    loadingChains: false,
    propertyTypeReportList: [],
    propertyTypeReportListFilter: [],
    loadingPropertyType: false,
    mappedHotelReportList: [],
    loadingMappedHotels: false,
    countHotelMapped: 0,
    loadingOpportunitiesHotels: false,
    countOpportunitiesHotels: 0,
    OpportunitiesHotelReportList: [],
    loadingInvalidHotels: false,
    InvalidHotelReportList: [],
    countHotelInvalid: 0,
    inventoryHotelcodeName: [],
    loadingInventoryCodeName: false,
    inventoryCities: [],
    loadingInventoryCities: false,
    inventoryCountries: [],
    loadingInventoryCountries: false,
    headerData: {},
    loadingHeaderData: true,
    loadingMappingRateOverTime: false,
    mappingRateOverTime: [],
    numberMappingPerformed: [],
    loadingNumberMappingPerformed: false,
    reportInfo: {},
    loadingReportInfo: true,
    loadingComparableMappings: false,
    comparableMappings: [],
    loadingCompareMappingTotal: true,
    compareMappingTotal: {},
    loadingCompareMapped: false,
    compareMapped: {},
    loadingCompareUnmapped: false,
    compareUnmapped: {},
    loadingSearchFields: true,
    searchFields: [],
    oldhotels: [],
    loadingOldHotels: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case COUNTRY_REPORT_LISTING:
            return {
                ...state,
                loadingCountries: true,
            };
        case COUNTRY_REPORT_SUCCESS:
            return {
                ...state,
                countryReportList: action.payload,
                loadingCountries: false,
            };
        case COUNTRY_REPORT_FAIL:
            return {
                ...state,
                loadingCountries: false,
            };
        case CITY_REPORT_LISTING:
            return {
                ...state,
                loadingCities: true,
            };
        case CITY_REPORT_SUCCESS:
            return {
                ...state,
                cityReportList: action.payload,
                loadingCities: false,
            };
        case CITY_REPORT_FAIL:
            return {
                ...state,
                loadingCities: false,
            };
        case CHAIN_REPORT_LISTING:
            return {
                ...state,
                loadingChains: true,
            };
        case CHAIN_REPORT_SUCCESS:
            return {
                ...state,
                chainReportList: action.payload,
                loadingChains: false,
            };
        case CHAIN_REPORT_LIST_SUCCESS:
            return {
                ...state,
                chainReportListFilter: action.payload,
            };
        case CHAIN_REPORT_FAIL:
            return {
                ...state,
                loadingChains: false,
            };
        case PROPERTY_TYPE_REPORT_LISTING:
            return {
                ...state,
                loadingPropertyType: true,
            };
        case PROPERTY_TYPE_REPORT_SUCCESS:
            return {
                ...state,
                propertyTypeReportList: action.payload,
                loadingPropertyType: false,
            };
        case PROPERTY_TYPE_REPORT_LIST_SUCCESS:
            return {
                ...state,
                propertyTypeReportListFilter: action.payload,
            };
        case PROPERTY_TYPE_REPORT_FAIL:
            return {
                ...state,
                loadingPropertyType: false,
            };
        case GET_MAPPED_HOTEL_LIST_LISTING:
            return {
                ...state,
                loadingMappedHotels: true,
            };
        case GET_MAPPED_HOTEL_LIST_SUCCESS:
            return {
                ...state,
                mappedHotelReportList: action.payload,
                countHotelMapped: action?.total,
                loadingMappedHotels: false,
            };
        case GET_MAPPED_HOTEL_LIST_FAIL:
            return {
                ...state,
                loadingMappedHotels: false,
            };

        case GET_OPPORTUNITIES_HOTEL_LIST_LISTING:
            return {
                ...state,
                loadingOpportunitiesHotels: true,
            };
        case GET_OPPORTUNITIES_HOTEL_LIST_SUCCESS:
            return {
                ...state,
                OpportunitiesHotelReportList: action.payload,
                countOpportunitiesHotels: action?.total,
                loadingOpportunitiesHotels: false,
            };
        case GET_OPPORTUNITIES_HOTEL_LIST_FAIL:
            return {
                ...state,
                loadingOpportunitiesHotels: false,
            };
        case GET_INVALID_HOTEL_LIST_LISTING:
            return {
                ...state,
                loadingInvalidHotels: true,
            };
        case GET_INVALID_HOTEL_LIST_SUCCESS:
            return {
                ...state,
                InvalidHotelReportList: action.payload,
                countHotelInvalid: action?.total,
                loadingInvalidHotels: false,
            };
        case GET_INVALID_HOTEL_LIST_FAIL:
            return {
                ...state,
                loadingInvalidHotels: false,
            };
        case GET_INVENTORY_HOTEL_CODE_NAME_LISTING:
            return {
                ...state,
                loadingInventoryCodeName: true,
            };
        case GET_INVENTORY_HOTEL_CODE_NAME_SUCCESS:
            return {
                ...state,
                inventoryHotelcodeName: action.payload,
                loadingInventoryCodeName: false,
            };
        case GET_INVENTORY_HOTEL_CODE_NAME_FAIL:
            return {
                ...state,
                loadingInventoryCodeName: false,
            };
        case GET_INVENTORY_CITIES_LISTING:
            return {
                ...state,
                loadingInventoryCities: true,
            };
        case GET_INVENTORY_CITIES_SUCCESS:
            return {
                ...state,
                inventoryCities: action.payload,
                loadingInventoryCities: false,
            };
        case GET_INVENTORY_CITIES_FAIL:
            return {
                ...state,
                loadingInventoryCities: false,
            };
        case GET_INVENTORY_COUNTRIES_LISTING:
            return {
                ...state,
                loadingInventoryCountries: true,
            };
        case GET_INVENTORY_COUNTRIES_SUCCESS:
            return {
                ...state,
                inventoryCountries: action.payload,
                loadingInventoryCountries: false,
            };
        case GET_INVENTORY_COUNTRIES_FAIL:
            return {
                ...state,
                loadingInventoryCountries: false,
            };
        case GET_HEADER_DETAILS_LISTING:
            return {
                ...state,
                loadingHeaderData: true,
            };
        case GET_HEADER_DETAILS_SUCCESS:
            return {
                ...state,
                headerData: action.payload.header,
                loadingHeaderData: false,
            };
        case GET_HEADER_DETAILS_FAIL:
            return {
                ...state,
                loadingHeaderData: false,
            };
        case GET_MAPPING_RATE_OVER_TIME_LISTING:
            return {
                ...state,
                loadingMappingRateOverTime: true,
            };
        case GET_MAPPING_RATE_OVER_TIME_SUCCESS:
            return {
                ...state,
                mappingRateOverTime: action.payload,
                loadingMappingRateOverTime: false,
            };
        case GET_MAPPING_RATE_OVER_TIME_FAIL:
            return {
                ...state,
                loadingMappingRateOverTime: false,
            };
        case GET_NUMBER_MAPPING_PERFORMED_LISTING:
            return {
                ...state,
                loadingNumberMappingPerformed: true,
            };
        case GET_NUMBER_MAPPING_PERFORMED_SUCCESS:
            return {
                ...state,
                numberMappingPerformed: action.payload,
                loadingNumberMappingPerformed: false,
            };
        case GET_NUMBER_MAPPING_PERFORMED_FAIL:
            return {
                ...state,
                loadingNumberMappingPerformed: false,
            };
        case GET_REPORT_INFO_LISTING:
            return {
                ...state,
                loadingReportInfo: true,
            };
        case GET_REPORT_INFO_SUCCESS:
            return {
                ...state,
                reportInfo: action.payload,
                loadingReportInfo: false,
            };
        case GET_REPORT_INFO_FAIL:
            return {
                ...state,
                loadingReportInfo: false,
            };
        case GET_COMPARABLE_MAPPINGS_LISTING:
            return {
                ...state,
                loadingComparableMappings: true,
            };
        case GET_COMPARABLE_MAPPINGS_SUCCESS:
            return {
                ...state,
                comparableMappings: action.payload,
                loadingComparableMappings: false,
            };
        case GET_COMPARABLE_MAPPINGS_FAIL:
            return {
                ...state,
                loadingComparableMappings: false,
            };
        case GET_COMPARE_MAPPIING_TOTAL_LISTING:
            return {
                ...state,
                loadingCompareMappingTotal: true,
            };
        case GET_COMPARE_MAPPIING_TOTAL_SUCCESS:
            return {
                ...state,
                compareMappingTotal: action.payload,
                loadingCompareMappingTotal: false,
            };
        case GET_COMPARE_MAPPIING_TOTAL_FAIL:
            return {
                ...state,
                loadingCompareMappingTotal: false,
            };

        case GET_COMPARE_MAPPED_LISTING:
            return {
                ...state,
                loadingCompareMapped: true,
            };
        case GET_COMPARE_MAPPED_SUCCESS:
            return {
                ...state,
                compareMapped: action.payload,
                loadingCompareMapped: false,
            };
        case GET_COMPARE_MAPPED_FAIL:
            return {
                ...state,
                loadingCompareMapped: false,
            };
        case GET_OLD_HOTELS_LISTING:
            return {
                ...state,
                loadingOldHotels: true,
            };
        case GET_OLD_HOTELS_SUCCESS:
            return {
                ...state,
                oldhotels: action.payload,
                loadingOldHotels: false,
            };
        case GET_OLD_HOTELS_FAIL:
            return {
                ...state,
                loadingOldHotels: false,
            };
        case GET_COMPARE_UNMAPPED_LISTING:
            return {
                ...state,
                loadingCompareUnmapped: true,
            };
        case GET_COMPARE_UNMAPPED_SUCCESS:
            return {
                ...state,
                compareUnmapped: action.payload,
                loadingCompareUnmapped: false,
            };
        case GET_COMPARE_UNMAPPED_FAIL:
            return {
                ...state,
                loadingCompareUnmapped: false,
            };

        case GET_SEARCH_FIELDS_LISTING:
            return {
                ...state,
                loadingSearchFields: true,
            };
        case GET_SEARCH_FIELDS_SUCCESS:
            return {
                ...state,
                searchFields: action.payload,
                loadingSearchFields: false,
            };
        case GET_SEARCH_FIELDS_FAIL:
            return {
                ...state,
                loadingSearchFields: false,
            };
        default:
            return state;
    }
}
