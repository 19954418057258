import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import HotelViewCard from "./HotelViewCard";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCatalogToReviewHotelList } from "../../redux/actions/PartnerCataogActions";
import Lottie from "react-lottie";
import animationData from "../../lotties/done.json";
import { separator } from "../../utilis/functions";
import { Helmet } from "react-helmet";

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};
function ReviewCarousal() {
    const { id } = useParams();
    const params = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const [hotelReviews, setHotelReviews] = useState([]);
    const [matchHotels, setMatchHotels] = useState([]);
    const [selectedItem, setSelectedItem] = useState(0);
    const { hotels, loading, catalog, company, candidat, finish } = useSelector(
        (state) => ({
            hotels: state.partnerCatalogReducer.reviewHotels,
            loading: state.partnerCatalogReducer.loadingReviewHotel,
            candidat: state.partnerCatalogReducer.candidates,
            catalog: state.partnerCatalogReducer.catalog,
            company: state.partnerCatalogReducer.company,
            finish: state.partnerCatalogReducer.finish,
        })
    );
    const [countCandidates, setCountCandidates] = useState(candidat);
    const [countMatched, setCountMatched] = useState(0);
    const { partner, catalogName } = {
        partner: params?.state?.params?.partner,
        catalogName: params?.state?.params?.catalogName,
    };
    useEffect(() => {
        setCountCandidates(candidat);
    }, [candidat]);

    useEffect(() => {
        dispatch(getCatalogToReviewHotelList(id));
    }, []);

    useEffect(() => {
        if (hotels?.length > 0) {
            let hotelsToReview = [];
            for (let h in hotels) {
                for (let c in hotels?.[h]?.candidates) {
                    hotelsToReview.push({
                        ...hotels?.[h],
                        condidat: hotels?.[h]?.candidates[c],
                    });
                }
            }
            setHotelReviews(hotelsToReview);
        }
    }, [hotels]);

    const handleChange = (indexSelected) => {
        setSelectedItem(indexSelected);
    };

    useEffect(() => {
        if (matchHotels?.length == hotels?.length && hotels?.length != 0) {
            dispatch(getCatalogToReviewHotelList(id));
            setMatchHotels([]);
            setSelectedItem(0);
        }
    }, [matchHotels]);

    useEffect(() => {
        if (matchHotels?.length == hotels?.length - 1) {
            setSelectedItem(0);
        }
    }, [selectedItem, matchHotels]);
    return (
        <>
            <Helmet>
                <title> Review</title>
            </Helmet>
            <div className="page-wrapper" style={{ display: "block" }}>
                <div className="page-breadcrumb">
                    <div className="row">
                        <div className="col-12 align-self-center">
                            <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
                                <button
                                    className="btn"
                                    onClick={() => {
                                        history.push(
                                            `/provider-content/details/${partner?.id}`,
                                            {
                                                params: {
                                                    partner: partner,
                                                },
                                            }
                                        );
                                    }}
                                    style={{ paddingLeft: "0px" }}
                                >
                                    <i className="fas fa-arrow-left"></i>
                                </button>
                                Review possible matches
                            </h3>
                            <div className="d-flex align-items-center">
                                <nav aria-label="breadcrumb">
                                    <ol className=" m-0 p-0">
                                        <div className=" align-items-center">
                                            <h6 className="font-weight-medium text-dark">
                                                {" "}
                                                {`Review  ${
                                                    countCandidates
                                                        ? separator(
                                                              countCandidates
                                                          )
                                                        : 0
                                                } hotels from your list`}
                                            </h6>
                                            <span
                                                className="title"
                                                style={{
                                                    color: "rgb(85, 85, 85)",
                                                }}
                                            >
                                                <i className="fa  fa-info-circle"></i>
                                                &nbsp; We found some potential
                                                matches that you can manually
                                                review and either map (select
                                                yes), discard (select no). The
                                                property on the left is from
                                                your inventory, the one on the
                                                right is from your partner's
                                                catalog. You can also skip a
                                                pair by clicking the blue arrow
                                                and it will show up again later.
                                            </span>
                                        </div>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    {loading ? (
                        <div className="text-center">
                            <div className="lds-ripple">
                                <div className="lds-pos"></div>
                                <div className="lds-pos"></div>
                            </div>
                        </div>
                    ) : finish ? (
                        <div style={{ textAlign: "center" }}>
                            <Lottie options={defaultOptions} width={300} />
                            <h2 className="modalTitle">All done !</h2>
                            <p className="modalP">
                                This catalogue has no more hotels to review
                            </p>
                            <button
                                className="modalBtn btn waves-effect waves-light btn-rounded btn-dark"
                                onClick={() =>
                                    history.push("/provider-content")
                                }
                            >
                                Go back
                            </button>
                        </div>
                    ) : (
                        <>
                            {hotelReviews?.length > 0 && (
                                <div
                                    className="text-center"
                                    style={{ marginTop: "20px" }}
                                >
                                    <h3
                                        style={{
                                            fontWeight: "800",
                                            color: "#6A76E9",
                                        }}
                                    >
                                        Is this the same proprety?
                                    </h3>
                                    <span
                                        style={{
                                            fontWeight: "800",
                                            color: "#07bc0c",
                                        }}
                                    >{` Processed : ${
                                        countMatched
                                            ? separator(countMatched)
                                            : 0
                                    }  out of ${
                                        candidat ? separator(candidat) : 0
                                    } `}</span>
                                    <p className="carousel-status"></p>
                                </div>
                            )}
                            <Carousel
                                showThumbs={false}
                                showStatus={false}
                                showArrows={true}
                                onChange={handleChange}
                                //infiniteLoop
                                //emulateTouch
                                autoFocus={false}
                                centerMode
                                useKeyboardArrows
                                selectedItem={selectedItem}
                                key={selectedItem}
                            >
                                {hotelReviews?.map((e) => {
                                    return (
                                        <HotelViewCard
                                            key={e.id}
                                            hotel={e}
                                            matchHotels={matchHotels}
                                            setMatchHotels={setMatchHotels}
                                            catalog={catalog}
                                            company={company}
                                            hotelsList={hotelReviews}
                                            setHotelList={setHotelReviews}
                                            setCountCandidates={
                                                setCountCandidates
                                            }
                                            countCandidates={countCandidates}
                                            setCountMatched={setCountMatched}
                                            countMatched={countMatched}
                                            hotels={hotels}
                                            partner={partner}
                                            catalogName={catalogName}
                                        />
                                    );
                                })}
                            </Carousel>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}
export default ReviewCarousal;
