import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Stepper from "react-stepper-horizontal";
import Step3 from "./Step3";
import Step1 from "./Step1";
import Step2 from "./Step2";
import { toast } from "react-toastify";
import PartnerService from "../../../services/PartnerService";
import { useDispatch, useSelector } from "react-redux";
import { getPartnerList } from "../../../redux/actions/PartnerActions";
import SelectModel from "./SelectModel";
import { hasGiataCredentials } from "../../../redux/actions/ModelsActions";
import {
    GIATA_MODEL,
    CUPID_MODEL,
    TRAVEL_GATE_FORM,
} from "../../../utilis/const";
import TravelgateCredentials from "./TravelgateCredentials";
import { getCupidModels } from "../../../redux/actions/ModelsActions";

function AddConnector(props) {
    const { open, handleClose } = props;
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedConnector, setSelectedConnector] = useState();
    const [selectedPartner, setSelectedPartner] = useState();
    const [partnerName, setPartnerName] = useState("");
    const [loadingCreation, setLoadingCreation] = useState(false);
    const [addPartner, setAddPartner] = useState(false);
    const [connectorType, setConnectorType] = useState("");
    const [listAttributes, setListAttributes] = React.useState([]);
    const [showPartnerAttributes, setShowPartnerAttributes] =
        React.useState(false);
    const [loadingSaveAttributes, setLoadingSaveAttributes] =
        React.useState(false);
    const [selectedModel, setSelectedModel] = useState(CUPID_MODEL);
    const [useManualMappings, setUseManualMappings] = useState(true);
    const [giataPartnerKey, setGiataPartnerKey] = useState();
    const [selectedCupidModel, setSelectedCupidModel] = useState();
    const [isTravelgateHub, setIsTravelgateHub] = useState(false);
    const [travelgateCredValues, setTravelgateCredValues] = useState({});
    const [formData, setFormData] = React.useState({});
    const [files, setFiles] = useState([]);
    const [selectTravelgateConfig, setSelectTravelgateConfig] =
        useState(TRAVEL_GATE_FORM);

    const dispatch = useDispatch();
    const { partnerAttributes, loadingAttributes, cupidModelsList } =
        useSelector((state) => ({
            loadingAttributes: state.partnerReducer.loadingAttributes,
            partnerAttributes: state.partnerReducer.partnerAttributes ?? [],
            cupidModelsList: state.modelsReducer.cupidModelsList?.models ?? [],
        }));

    React.useEffect(() => {
        dispatch(hasGiataCredentials());
    }, []);

    React.useEffect(() => {
        setListAttributes(partnerAttributes);
    }, [loadingAttributes]);

    React.useEffect(() => {
        if (!selectedCupidModel && cupidModelsList?.length > 0) {
            setSelectedCupidModel(cupidModelsList?.[0]);
        }
    }, [cupidModelsList]);

    const sections = React.useMemo(() => {
        const required = [
            {
                title: "Partner",
            },
            {
                title: "Select Model",
            },
            {
                title: "Connectors",
            },
        ];
        let extra = [];
        if (isTravelgateHub === true) {
            extra.push({
                title: "Travelgate Credentials",
            });
        }

        return [
            ...required,
            ...extra,
            {
                title: "Credentials",
            },
        ];
    }, [isTravelgateHub]);

    const handleClick = async () => {
        if (selectedPartner?.value === undefined && !addPartner) {
            toast.error("Partner is required to pass", {
                toastId: "partner-select-connector-partner-step1",
            });
        } else if (addPartner && partnerName === "") {
            toast.error("Partner name is required to pass", {
                toastId: "partner-connector-name-step1",
            });
        } else if (addPartner && partnerName !== "") {
            const option = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            setLoadingCreation(true);
            const res = await PartnerService.addNewPartner(partnerName, option);
            if (res?.status === 200) {
                setLoadingCreation(false);
                setAddPartner(false);
                dispatch(getPartnerList());
                setSelectedPartner({
                    value: res?.data?.partnerId,
                    label: partnerName,
                });
                setPartnerName("");
                setCurrentPage((prev) => prev + 1);
            } else {
                setLoadingCreation(false);
                toast.error(res?.message);
            }
        } else {
            setCurrentPage((prev) => prev + 1);
        }
    };

    const prev = () => setCurrentPage((prev) => prev - 1);

    const renderSteps = () => {
        switch (currentPage) {
            case 1:
                return (
                    <Step1
                        handleClick={handleClick}
                        setSelectedPartner={setSelectedPartner}
                        selectedPartner={selectedPartner}
                        partnerName={partnerName}
                        setPartnerName={setPartnerName}
                        addPartner={addPartner}
                        setAddPartner={setAddPartner}
                        loadingCreation={loadingCreation}
                        showPartnerAttributes={showPartnerAttributes}
                        setShowPartnerAttributes={setShowPartnerAttributes}
                        listAttributes={listAttributes}
                        setListAttributes={setListAttributes}
                        loadingSaveAttributes={loadingSaveAttributes}
                        handleClose={handleClose}
                    />
                );

            case 2:
                return (
                    <SelectModel
                        selectedModel={selectedModel}
                        setSelectedModel={setSelectedModel}
                        prev={prev}
                        setCurrentPage={() => {
                            if (!selectedModel) {
                                toast.error("Please select a model", {
                                    toastId:
                                        "model-partner-catalog-connector-step2",
                                });
                            } else if (
                                (selectedModel === GIATA_MODEL ||
                                    selectedCupidModel.run_giata === true) &&
                                !giataPartnerKey
                            ) {
                                toast.error(
                                    "Please select a giata partner key",
                                    {
                                        toastId:
                                            "giata-key-partner-catalog-connector-step2",
                                    }
                                );
                            } else if (
                                selectedModel === CUPID_MODEL &&
                                !selectedCupidModel
                            ) {
                                toast.error("Please select a cupid model", {
                                    toastId: "cupid-key-model-connector-step2",
                                });
                            } else {
                                setCurrentPage(3);
                                //  }
                            }
                        }}
                        selectedPartner={selectedPartner}
                        giataPartnerKey={giataPartnerKey}
                        setGiataPartnerKey={setGiataPartnerKey}
                        selectedCupidModel={selectedCupidModel}
                        setSelectedCupidModel={setSelectedCupidModel}
                        handleClose={handleClose}
                    />
                );
            case 3:
                return (
                    <Step2
                        handleClick={() => {
                            if (selectedConnector === undefined) {
                                toast.error(
                                    "Connector is required to pass next step",
                                    {
                                        toastId: "connector-step2",
                                    }
                                );
                            } else {
                                setCurrentPage(4);
                            }
                        }}
                        setSelectedConnector={setSelectedConnector}
                        selectedConnector={selectedConnector}
                        setCurrentPage={setCurrentPage}
                        selectedPartner={selectedPartner}
                        setConnectorType={setConnectorType}
                        prev={prev}
                        setIsTravelgateHub={setIsTravelgateHub}
                        handleClose={handleClose}
                        setSelectTravelgateConfig={setSelectTravelgateConfig}
                        setFormData={setFormData}
                        setFiles={setFiles}
                    />
                );
            case 4:
                return (
                    <Step3
                        selectedConnector={selectedConnector}
                        setCurrentPage={setCurrentPage}
                        partner={selectedPartner}
                        connectorId={selectedConnector?.id}
                        handleClose={handleClose}
                        connectorType={connectorType}
                        prev={prev}
                        loadingSaveAttributes={loadingSaveAttributes}
                        setLoadingSaveAttributes={setLoadingSaveAttributes}
                        listAttributes={listAttributes}
                        selectedModel={selectedModel}
                        selectedCupidModel={selectedCupidModel}
                        giataPartnerKey={giataPartnerKey}
                        isTravelgateHub={isTravelgateHub}
                        formData={formData}
                        setFormData={setFormData}
                        selectTravelgateConfig={selectTravelgateConfig}
                        setSelectTravelgateConfig={setSelectTravelgateConfig}
                        setFiles={setFiles}
                        files={files}
                        setUseManualMappings={setUseManualMappings}
                        useManualMappings={useManualMappings}
                    />
                );

            default:
                return <></>;
        }
    };
    const renderStepsWithTravelgateCredentials = () => {
        switch (currentPage) {
            case 1:
                return (
                    <Step1
                        handleClick={handleClick}
                        setSelectedPartner={setSelectedPartner}
                        selectedPartner={selectedPartner}
                        partnerName={partnerName}
                        setPartnerName={setPartnerName}
                        addPartner={addPartner}
                        setAddPartner={setAddPartner}
                        loadingCreation={loadingCreation}
                        showPartnerAttributes={showPartnerAttributes}
                        setShowPartnerAttributes={setShowPartnerAttributes}
                        listAttributes={listAttributes}
                        setListAttributes={setListAttributes}
                        loadingSaveAttributes={loadingSaveAttributes}
                        handleClose={handleClose}
                    />
                );

            case 2:
                return (
                    <SelectModel
                        selectedModel={selectedModel}
                        setSelectedModel={setSelectedModel}
                        prev={prev}
                        setCurrentPage={() => {
                            if (!selectedModel) {
                                toast.error("Please select a model", {
                                    toastId:
                                        "model-partner-catalog-connector-step2",
                                });
                            } else if (
                                selectedModel === GIATA_MODEL &&
                                !giataPartnerKey
                            ) {
                                toast.error(
                                    "Please select a giata partner key",
                                    {
                                        toastId:
                                            "giata-key-partner-catalog-connector-step2",
                                    }
                                );
                            } else if (
                                selectedModel === CUPID_MODEL &&
                                !selectedCupidModel
                            ) {
                                toast.error("Please select a cupid model", {
                                    toastId: "cupid-key-model-connector-step2",
                                });
                            } else {
                                setCurrentPage(3);
                                //  }
                            }
                        }}
                        selectedPartner={selectedPartner}
                        giataPartnerKey={giataPartnerKey}
                        setGiataPartnerKey={setGiataPartnerKey}
                        selectedCupidModel={selectedCupidModel}
                        setSelectedCupidModel={setSelectedCupidModel}
                        handleClose={handleClose}
                    />
                );
            case 3:
                return (
                    <Step2
                        handleClick={() => {
                            if (selectedConnector === undefined) {
                                toast.error(
                                    "Connector is required to pass next step",
                                    {
                                        toastId: "connector-step2",
                                    }
                                );
                            } else {
                                setCurrentPage(4);
                            }
                        }}
                        setSelectedConnector={setSelectedConnector}
                        selectedConnector={selectedConnector}
                        setCurrentPage={setCurrentPage}
                        selectedPartner={selectedPartner}
                        setConnectorType={setConnectorType}
                        prev={prev}
                        setIsTravelgateHub={setIsTravelgateHub}
                        handleClose={handleClose}
                        setSelectTravelgateConfig={setSelectTravelgateConfig}
                        setFormData={setFormData}
                        setFiles={setFiles}
                    />
                );
            case 4:
                return (
                    <TravelgateCredentials
                        selectedConnector={selectedConnector}
                        setSelectedConnector={setSelectedConnector}
                        next={() => setCurrentPage((prev) => prev + 1)}
                        travelgateCredValues={travelgateCredValues}
                        setTravelgateCredValues={setTravelgateCredValues}
                        prev={prev}
                        handleClose={handleClose}
                    />
                );
            case 5:
                return (
                    <Step3
                        selectedConnector={selectedConnector}
                        setCurrentPage={setCurrentPage}
                        partner={selectedPartner}
                        connectorId={selectedConnector?.id}
                        handleClose={handleClose}
                        connectorType={connectorType}
                        prev={prev}
                        loadingSaveAttributes={loadingSaveAttributes}
                        setLoadingSaveAttributes={setLoadingSaveAttributes}
                        listAttributes={listAttributes}
                        selectedModel={selectedModel}
                        selectedCupidModel={selectedCupidModel}
                        giataPartnerKey={giataPartnerKey}
                        isTravelgateHub={isTravelgateHub}
                        formData={formData}
                        setFormData={setFormData}
                        selectTravelgateConfig={selectTravelgateConfig}
                        setSelectTravelgateConfig={setSelectTravelgateConfig}
                        setFiles={setFiles}
                        files={files}
                        setUseManualMappings={setUseManualMappings}
                        useManualMappings={useManualMappings}
                    />
                );

            default:
                return <></>;
        }
    };
    return (
        <Modal
            show={open}
            // onHide={handleClose}
            dialogClassName="modal-90w modal-dialog modal-dialog-centered"
        >
            <div className="card mb-0 p-10">
                <Modal.Body>
                    <div className="card-header p-0 pb-2">
                        <Stepper
                            steps={sections}
                            activeStep={currentPage}
                            activeColor="#D3D7DB"
                            defaultBarColor="#D3D7DB"
                            completeColor="#6076E8"
                            completeBarColor="#6076E8"
                            fontFamily="Roboto"
                            textColor="#fff"
                            defaultBorderStyle="solid 6px"
                            defaultBorderWidth={10}
                        />
                    </div>
                    {isTravelgateHub
                        ? renderStepsWithTravelgateCredentials()
                        : renderSteps()}
                </Modal.Body>
            </div>
        </Modal>
    );
}
export default AddConnector;
