import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "../../Select";
import moment from "moment";
import {
    getSearchFields,
    getCompareMappingTotal,
    getComapareUnmapped,
    getComapareMapped,
    getOldHotels,
} from "../../../redux/actions/PartnerReportAction";

const MappingsComparable = (props) => {
    const { selectedMapping, setSelectedMapping, token, filters } = props;
    const dispatch = useDispatch();
    const { comparableMappings } = useSelector((state) => ({
        comparableMappings: state.partnerReportReducer.comparableMappings ?? [],
    }));

    return (
        <div className="card" style={{ width: "50%" }}>
            <div className="card-header">Select mapping</div>
            <div className="card-body">
                <div className="col-lg-12 col-md-12">
                    <Select
                        options={comparableMappings?.map((e, index) => ({
                            label:
                                e?.catalog_name +
                                `   (${
                                    e?.created_at
                                        ? moment(
                                              new Date(e?.created_at)
                                          ).format("DD/MM/YYYY HH:mm")
                                        : "--"
                                })`,

                            value: e?.id,
                        }))}
                        // formatOptionLabel={formatOptionLabel}
                        name="mappings"
                        value={selectedMapping}
                        placeholder="Mappings"
                        isClearable={true}
                        onChange={(e) => {
                            setSelectedMapping(e);
                            if (e !== null) {
                                dispatch(getSearchFields(token, e?.value));
                                dispatch(
                                    getCompareMappingTotal(token, e?.value)
                                );
                                dispatch(
                                    getComapareUnmapped(
                                        token,
                                        e?.value,
                                        1,
                                        10,
                                        "",
                                        filters
                                    )
                                );
                                dispatch(
                                    getComapareMapped(
                                        token,
                                        e?.value,
                                        1,
                                        10,
                                        "",
                                        filters
                                    )
                                );
                                dispatch(
                                    getOldHotels(
                                        token,
                                        e?.value,
                                        1,
                                        10,
                                        "",
                                        filters
                                    )
                                );
                            }
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default MappingsComparable;
