import React from "react";

import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

const Table = ({
    data,
    columns,
    conditionalRowStyles,
    onRowClicked,
    filter,
    paginationPerPage,
    progressPending,
    highlightOnHover,
}) => {
    const tableData = {
        columns,
        data,
    };

    return (
        <DataTableExtensions
            {...tableData}
            print={false}
            export={false}
            filter={filter ?? true}
        >
            <DataTable
                columns={columns}
                data={data}
                // noHeader
                defaultSortField="id"
                defaultSortAsc={false}
                pagination
                highlightOnHover={highlightOnHover ?? true}
                responsive
                conditionalRowStyles={conditionalRowStyles}
                paginationPerPage={paginationPerPage ?? 10}
                paginationRowsPerPageOptions={[3, 5, 10, 25, 50, 100]}
                progressPending={progressPending ?? false}
                noDataComponent="This attribute was not found" //or your component
            />
        </DataTableExtensions>
    );
};

export default Table;
