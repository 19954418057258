import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js/pure";
import {
    CardElement,
    Elements,
    useElements,
    useStripe,
} from "@stripe/react-stripe-js";
import "./styles.css";
import PaymentService from "../../services/PaymentService";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getPaymentCards } from "../../redux/actions/PaymentActions";
import { Helmet } from "react-helmet";

const CARD_OPTIONS = {
    hidePostalCode: true,
    iconStyle: "solid",
    style: {
        base: {
            iconColor: "3e59e3",
            color: "black",
            fontWeight: 500,
            fontSize: "16px",
            fontSmoothing: "antialiased",
            ":-webkit-autofill": {
                color: "#fce883",
            },
            "::placeholder": {
                color: "#87bbfd",
            },
        },
        invalid: {
            iconColor: "#e8102d",
            color: "#e8102d",
        },
    },
};

const CardField = ({ onChange }) => (
    <div className="FormRow">
        <CardElement options={CARD_OPTIONS} onChange={onChange} />
    </div>
);

const SubmitButton = ({ processing, error, children, disabled }) => (
    <button
        className="btn btn-block waves-effect waves-light btn-rounded btn-outline-primary"
        type="submit"
        disabled={processing}
    >
        Done
        {processing && (
            <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
            />
        )}
    </button>
);

const ErrorMessage = ({ children }) => (
    <span style={{ color: "red", alignItems: "center" }}>{children}</span>
);

const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [cardComplete, setCardComplete] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [addPaymentCard, setAddPaymentCard] = useState(false);
    const dispatch = useDispatch();
    const { last4, loading, waitData } = useSelector((state) => ({
        last4: state.paymentReducer?.last4,
        loading: state.paymentReducer?.loading,
        waitData: state.paymentReducer?.waitData,
    }));

    useEffect(() => {
        dispatch(getPaymentCards());
    }, []);

    useEffect(() => {
        if (last4 === "" && waitData === false) {
            setAddPaymentCard(true);
        }
    }, [last4, waitData]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        if (error) {
            elements.getElement("card").focus();
            return;
        }

        if (cardComplete) {
            setProcessing(true);
        }

        const payload = await stripe.createToken(
            elements.getElement(CardElement)
        );
        setProcessing(false);
        if (payload.error) {
            setError(payload.error);
        } else {
            setProcessing(true);
            const res = await PaymentService.StripeTokenService({
                token: payload?.token?.id,
                Last4: payload?.token?.card?.last4,
            });
            if (res.status === 200) {
                setAddPaymentCard(false);
                setProcessing(false);
                dispatch(getPaymentCards());
            } else {
                setProcessing(false);
            }
        }
    };

    return (
        <>
            <Helmet>
                <title>Payment</title>
            </Helmet>
            {loading ? (
                <div className="text-center">
                    <div className="lds-ripple">
                        <div className="lds-pos"></div>
                        <div className="lds-pos"></div>
                    </div>
                </div>
            ) : (
                <>
                    <div className="page-breadcrumb">
                        <div className="row">
                            <div className="col-12 align-self-center">
                                <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
                                    {addPaymentCard && (
                                        <button
                                            className="btn"
                                            onClick={() => {
                                                setAddPaymentCard(false);
                                            }}
                                            style={{ paddingLeft: "0px" }}
                                        >
                                            <i className="fas fa-arrow-left"></i>
                                        </button>
                                    )}
                                    Payment
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-8">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title">
                                            {addPaymentCard
                                                ? "Add payment method"
                                                : "How Payment Works"}
                                        </h4>
                                        <h6 className="sub-title-info">
                                            {addPaymentCard
                                                ? "Add a debit or credit card  "
                                                : "We'll charge this card when you start using the service!"}
                                        </h6>
                                        <div
                                            className="col-12"
                                            style={{
                                                padding: "0px",
                                            }}
                                        >
                                            <hr />
                                        </div>
                                        <div
                                            className="col-12"
                                            style={{
                                                padding: "0px",
                                            }}
                                        >
                                            {addPaymentCard ? (
                                                <>
                                                    <form
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        onSubmit={handleSubmit}
                                                    >
                                                        <div
                                                            // className="row"
                                                            style={{
                                                                display: "flex",
                                                                justifyContent:
                                                                    "space-between",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    width: "100%",
                                                                    marginRight:
                                                                        "20px",
                                                                }}
                                                            >
                                                                <fieldset className="FormGroup">
                                                                    <CardField
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            setError(
                                                                                e.error
                                                                            );
                                                                            setCardComplete(
                                                                                e.complete
                                                                            );
                                                                        }}
                                                                    />
                                                                </fieldset>
                                                                {error && (
                                                                    <ErrorMessage>
                                                                        {
                                                                            error.message
                                                                        }
                                                                    </ErrorMessage>
                                                                )}
                                                            </div>
                                                            <div
                                                                style={{
                                                                    width: "150px",
                                                                }}
                                                            >
                                                                <SubmitButton
                                                                    processing={
                                                                        processing
                                                                    }
                                                                    error={
                                                                        error
                                                                    }
                                                                    disabled={
                                                                        !stripe
                                                                    }
                                                                >
                                                                    Done
                                                                </SubmitButton>
                                                            </div>
                                                        </div>
                                                    </form>
                                                    <div className="col-12">
                                                        <p className="card-text">
                                                            <small className="text-muted">
                                                                By adding your
                                                                card you
                                                                authorise&nbsp;
                                                                <span
                                                                    style={{
                                                                        fontWeight:
                                                                            "600",
                                                                        color: "black",
                                                                    }}
                                                                >
                                                                    Cup
                                                                </span>
                                                                <span
                                                                    style={{
                                                                        color: "#5f76e8",
                                                                        fontWeight:
                                                                            "600",
                                                                    }}
                                                                >
                                                                    id
                                                                </span>
                                                                &nbsp; to send
                                                                instructions to
                                                                the financial
                                                                institution that
                                                                issued your card
                                                                to take payments
                                                                from your card
                                                                account in
                                                                accordance with
                                                                the terms of
                                                                your agreement
                                                                with us.
                                                            </small>
                                                        </p>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent:
                                                                "space-between",
                                                        }}
                                                    >
                                                        <div>
                                                            <i className="fab fa-cc-visa"></i>{" "}
                                                            Card ending in{" "}
                                                            {last4}
                                                        </div>
                                                        <div>
                                                            <button
                                                                className="btn waves-effect waves-light btn-rounded btn-outline-primary"
                                                                onClick={() =>
                                                                    setAddPaymentCard(
                                                                        true
                                                                    )
                                                                }
                                                            >
                                                                Change
                                                            </button>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

const ELEMENTS_OPTIONS = {
    fonts: [
        {
            cssSrc: "https://fonts.googleapis.com/css?family=Roboto",
        },
    ],
};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
loadStripe.setLoadParameters({ advancedFraudSignals: false });

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const PaymentScreen = () => {
    return (
        <div className="page-wrapper" style={{ display: "block" }}>
            <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
                <CheckoutForm />
            </Elements>
        </div>
    );
};

export default PaymentScreen;
