import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import {
    CSV_CHOISE,
    CONNECTOR_CHOISE,
    DEFAULT_CHOISE,
} from "../../../utilis/const";
import csvGif from "../../../lotties/upload-csv.gif";
import connectorGif from "../../../lotties/connector.gif";
import AddInventory from "./Csv/index";
import ConnectorChoise from "./Connector/index";
import { getPartnerList } from "../../../redux/actions/PartnerActions";
import { useDispatch } from "react-redux";

function ChoiseConnectorCsv(props) {
    const {
        open,
        handleClose,
        setProgressPourcentage,
        setFirstProccessing,
        activeCatalog,
        partner,
    } = props;
    const dispatch = useDispatch();
    const [choise, setChoise] = useState(DEFAULT_CHOISE);
    const handleCloseModal = () => {
        if (choise === DEFAULT_CHOISE) {
            handleClose();
        }
    };
    const renderElement = () => {
        switch (choise) {
            case CSV_CHOISE:
                return (
                    <>
                        <AddInventory
                            partner={partner}
                            handleClose={handleClose}
                            activeCatalog={activeCatalog}
                            setProgressPourcentage={setProgressPourcentage}
                            setFirstProccessing={setFirstProccessing}
                        />
                    </>
                );
            case CONNECTOR_CHOISE:
                return (
                    <>
                        <ConnectorChoise
                            handleClose={handleClose}
                            setProgressPourcentage={setProgressPourcentage}
                            setFirstProccessing={setFirstProccessing}
                            partner={partner}
                        />
                    </>
                );
            case DEFAULT_CHOISE:
                return (
                    <>
                        <Modal.Header
                            style={{
                                textAlign: "center",
                                display: "block",
                                color: "#6a76e9",
                                fontWeight: "500",
                                fontSize: "27px",
                            }}
                        >
                            Choose your upload method
                        </Modal.Header>
                        <Modal.Body style={{ textAlign: "center" }}>
                            <div
                                className="row"
                                style={{
                                    justifyContent: "center",
                                    paddingRight: "70px",
                                    paddingLeft: "70px",
                                    paddingBottom: "30px",
                                }}
                            >
                                <div
                                    className="col-6 zoomImage"
                                    style={{
                                        transform:
                                            choise === CSV_CHOISE
                                                ? "scale(1.1)"
                                                : "",
                                    }}
                                    key={choise}
                                >
                                    <button
                                        className="btn"
                                        onClick={() => setChoise(CSV_CHOISE)}
                                    >
                                        <img
                                            alt=""
                                            src={csvGif}
                                            style={{
                                                width: 200,
                                                height: 200,
                                                //borderRadius: 5,
                                                border:
                                                    choise === CSV_CHOISE
                                                        ? "2px solid #177c1f7d"
                                                        : "",
                                            }}
                                        />
                                    </button>
                                    <br />
                                    <span
                                        style={{
                                            color: "rgb(85, 85, 85)",
                                            fontSize: "18px",
                                            fontWeight: "600",
                                        }}
                                    >
                                        {" "}
                                        CSV
                                    </span>
                                </div>
                                <div
                                    className="col-6 zoomImage"
                                    style={{
                                        transform:
                                            choise === CONNECTOR_CHOISE
                                                ? "scale(1.1)"
                                                : "",
                                    }}
                                    key={choise}
                                >
                                    <button
                                        className="btn"
                                        onClick={() =>
                                            setChoise(CONNECTOR_CHOISE)
                                        }
                                    >
                                        <img
                                            alt=""
                                            src={connectorGif}
                                            style={{
                                                width: 200,
                                                height: 200,
                                                //borderRadius: 5,
                                                border:
                                                    choise === CONNECTOR_CHOISE
                                                        ? "2px solid #177c1f7d"
                                                        : "",
                                            }}
                                        />
                                    </button>
                                    <br />
                                    <span
                                        style={{
                                            color: "rgb(85, 85, 85)",
                                            fontSize: "18px",
                                            fontWeight: "600",
                                            marginRight: "40px",
                                        }}
                                    >
                                        Connector
                                    </span>
                                </div>
                            </div>
                        </Modal.Body>
                    </>
                );
            default:
                return <></>;
        }
    };

    useEffect(() => {
        dispatch(getPartnerList());
    }, []);
    return (
        <Modal
            show={open}
            onHide={handleCloseModal}
            dialogClassName={
                choise === DEFAULT_CHOISE
                    ? "modal-50w modal-dialog modal-dialog-centered"
                    : "modal-90w modal-dialog modal-dialog-centered"
            }
        >
            {renderElement()}
        </Modal>
    );
}
export default ChoiseConnectorCsv;
