import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { HOME } from "../../utilis/const";

function Card1() {
    const history = useHistory();
    const { partnerSteps } = useSelector((state) => ({
        partnerSteps: state.analyticsReducer.partnerSteps,
    }));
    return (
        <div
            className={`card boxShadow ${
                partnerSteps?.has_downloaded_catalog
                    ? `card-info-complete`
                    : `card-info`
            }`}
        >
            <div
                className={`IconBadge card-home  ${
                    partnerSteps?.has_downloaded_catalog
                        ? `card-home-icon-complete`
                        : `purple`
                }`}
                style={{ padding: "6px" }}
            >
                <span
                    style={{
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "31px",
                        marginLeft: "20px",
                    }}
                >
                    3
                </span>
            </div>
            <div
                className="card-body card-body-home"
                style={{ minHeight: "135px" }}
            >
                <h4
                    className="card-title cardTitle"
                    style={{ maxHeight: "48px" }}
                >
                    Download your mapping
                </h4>
                <p className="card-paragraph">
                    Get your mapping results in minutes and get it done!
                </p>
                <ul className="list-style-none mb-0"></ul>
            </div>
            <div className="card-footer card-info-footer">
                {partnerSteps?.has_downloaded_catalog ? (
                    <button
                        type="button"
                        disabled={true}
                        className="button-step-complete"
                    >
                        <i className="fas fa-check mr-6"></i>
                        This step is complete!
                    </button>
                ) : (
                    <button
                        className="btn waves-effect waves-light btn-rounded btn-outline-primary"
                        onClick={() => {
                            history.push(
                                `/provider-content/details/${partnerSteps?.last_partner_id}`,
                                {
                                    params: {
                                        partner: {
                                            id: partnerSteps.last_partner_id,
                                            name: partnerSteps.last_partner_name,
                                        },
                                        pageType: HOME,
                                    },
                                }
                            );
                        }}
                    >
                        Download your mapping
                    </button>
                )}
            </div>
        </div>
    );
}
export default Card1;
