import React from "react";
import { Modal, ProgressBar, Spinner } from "react-bootstrap";
import Select from "../../../../components/Select";
import Lottie from "react-lottie";
import animationData from "../../../../lotties/upload_file.json";
import MatchingFieldsInfo from "../../../../components/MatchingFieldsInfo";

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};
function Step3(props) {
    const {
        loading,
        selectedValues,
        setSelectedValues,
        data,
        progress,
        prev,
        handleClose,
        handleStartProcessing,
        hideBackButton,
        firstLine,
    } = props;
    return (
        <>
            <Modal.Body>
                <>
                    {loading ? (
                        <div>
                            <Lottie
                                options={defaultOptions}
                                height={400}
                                width={400}
                            />
                            <div
                                style={{
                                    textAlign: "center",
                                    paddingBottom: "15px",
                                }}
                            >
                                <span
                                    style={{
                                        fontSize: "21px",
                                        fontWeight: "600",
                                    }}
                                >
                                    Uploading data to the server. Please do not
                                    refresh this page.
                                </span>
                            </div>
                        </div>
                    ) : (
                        <>
                            <MatchingFieldsInfo />
                            <form className="row">
                                <div className="col-4">
                                    <div className="form-group">
                                        <label
                                            className="mr-sm-2"
                                            htmlFor="csv_hotel_code"
                                        >
                                            Hotel ID
                                            <span className="text-danger">
                                                {" "}
                                                *{" "}
                                            </span>
                                        </label>
                                        <Select
                                            options={data?.map((e) => ({
                                                value: e?.index,
                                                label: e?.name,
                                            }))}
                                            name="header_id"
                                            isClearable={true}
                                            value={selectedValues.header_id}
                                            styles={{
                                                control: (base) => ({
                                                    ...base,
                                                    borderColor:
                                                        selectedValues.header_id !=
                                                            undefined ||
                                                        selectedValues.header_id !=
                                                            null
                                                            ? "#89c995fc"
                                                            : "#ff00009e",
                                                    "&:hover": {
                                                        borderColor:
                                                            selectedValues.header_id !=
                                                                undefined ||
                                                            selectedValues.header_id !=
                                                                null
                                                                ? "#89c995fc"
                                                                : "#ff00009e",
                                                    },
                                                }),
                                            }}
                                            onChange={(e) =>
                                                setSelectedValues({
                                                    ...selectedValues,
                                                    header_id: e,
                                                })
                                            }
                                        />
                                        <span className="preview-column">
                                            {firstLine?.find(
                                                (e) =>
                                                    e?.index ===
                                                    selectedValues.header_id
                                                        ?.value
                                            ) &&
                                                `Preview : ${
                                                    firstLine?.find(
                                                        (e) =>
                                                            e?.index ===
                                                            selectedValues
                                                                .header_id
                                                                ?.value
                                                    )?.name
                                                }`}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-group">
                                        <label
                                            className="mr-sm-2"
                                            htmlFor="csv_hotel_name"
                                        >
                                            Hotel Name
                                            <span className="text-danger">
                                                {" "}
                                                *{" "}
                                            </span>
                                        </label>
                                        <Select
                                            options={data?.map((e) => ({
                                                value: e?.index,
                                                label: e?.name,
                                            }))}
                                            isClearable={true}
                                            name="header_name"
                                            value={selectedValues.header_name}
                                            styles={{
                                                control: (base) => ({
                                                    ...base,
                                                    borderColor:
                                                        selectedValues.header_name
                                                            ? "#89c995fc"
                                                            : "#ff00009e",
                                                    "&:hover": {
                                                        borderColor:
                                                            selectedValues.header_name
                                                                ? "#89c995fc"
                                                                : "#ff00009e",
                                                    },
                                                }),
                                            }}
                                            onChange={(e) =>
                                                setSelectedValues({
                                                    ...selectedValues,
                                                    header_name: e,
                                                })
                                            }
                                        />
                                        <span className="preview-column">
                                            {firstLine?.find(
                                                (e) =>
                                                    e?.index ===
                                                    selectedValues.header_name
                                                        ?.value
                                            ) &&
                                                `Preview : ${
                                                    firstLine?.find(
                                                        (e) =>
                                                            e?.index ===
                                                            selectedValues
                                                                .header_name
                                                                ?.value
                                                    )?.name
                                                }`}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-group">
                                        <label
                                            className="mr-sm-2"
                                            htmlFor="csv_hotel_address"
                                        >
                                            Hotel Address
                                            <span className="text-danger">
                                                &nbsp;*
                                            </span>
                                        </label>
                                        <Select
                                            options={data?.map((e) => ({
                                                value: e?.index,
                                                label: e?.name,
                                            }))}
                                            isClearable={true}
                                            name="header_address"
                                            value={
                                                selectedValues.header_address
                                            }
                                            styles={{
                                                control: (base) => ({
                                                    ...base,
                                                    borderColor:
                                                        selectedValues.header_address
                                                            ? "#89c995fc"
                                                            : "#ff00009e",
                                                    "&:hover": {
                                                        borderColor:
                                                            selectedValues.header_address
                                                                ? "#89c995fc"
                                                                : "#ff00009e",
                                                    },
                                                }),
                                            }}
                                            onChange={(e) =>
                                                setSelectedValues({
                                                    ...selectedValues,
                                                    header_address: e,
                                                })
                                            }
                                        />
                                        <span className="preview-column">
                                            {firstLine?.find(
                                                (e) =>
                                                    e?.index ===
                                                    selectedValues
                                                        .header_address?.value
                                            ) &&
                                                `Preview : ${
                                                    firstLine?.find(
                                                        (e) =>
                                                            e?.index ===
                                                            selectedValues
                                                                .header_address
                                                                ?.value
                                                    )?.name
                                                }`}
                                        </span>
                                    </div>
                                </div>
                                {/* <div className="col-4">
                                    <div className="form-group">
                                        <label
                                            className="mr-sm-2"
                                            for="csv_hotel_address_2"
                                        >
                                            Hotel Address 2
                                        </label>
                                        <Select
                                            options={data?.map((e) => ({
                                                value: e?.index,
                                                label: e?.name,
                                            }))}
                                            name="header_address_2"
                                            isClearable={true}
                                            value={
                                                selectedValues.header_address_2
                                            }
                                            onChange={(e) =>
                                                setSelectedValues({
                                                    ...selectedValues,
                                                    header_address_2: e,
                                                })
                                            }
                                        />

                                        <span className="preview-column">
                                            {firstLine?.find(
                                                (e) =>
                                                    e?.index ===
                                                    selectedValues
                                                        .header_address_2?.value
                                            ) &&
                                                `Preview : ${
                                                    firstLine?.find(
                                                        (e) =>
                                                            e?.index ===
                                                            selectedValues
                                                                .header_address_2
                                                                ?.value
                                                    )?.name
                                                }`}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-group">
                                        <label
                                            className="mr-sm-2"
                                            for="csv_hotel_address_3"
                                        >
                                            Hotel Address 3
                                        </label>
                                        <Select
                                            options={data?.map((e) => ({
                                                value: e?.index,
                                                label: e?.name,
                                            }))}
                                            name="header_address_3"
                                            isClearable={true}
                                            value={
                                                selectedValues.header_address_3
                                            }
                                            onChange={(e) =>
                                                setSelectedValues({
                                                    ...selectedValues,
                                                    header_address_3: e,
                                                })
                                            }
                                        />
                                        <span className="preview-column">
                                            {firstLine?.find(
                                                (e) =>
                                                    e?.index ===
                                                    selectedValues
                                                        .header_address_3?.value
                                            ) &&
                                                `Preview : ${
                                                    firstLine?.find(
                                                        (e) =>
                                                            e?.index ===
                                                            selectedValues
                                                                .header_address_3
                                                                ?.value
                                                    )?.name
                                                }`}
                                        </span>
                                    </div>
                                </div> */}
                                <div className="col-4">
                                    <div className="form-group">
                                        <label
                                            className="mr-sm-2"
                                            htmlFor="csv_hotel_country"
                                        >
                                            Country ISO Code
                                            <span className="text-danger">
                                                &nbsp;*
                                            </span>
                                        </label>
                                        <Select
                                            options={data?.map((e) => ({
                                                value: e?.index,
                                                label: e?.name,
                                            }))}
                                            isClearable={true}
                                            name="header_country_code"
                                            value={
                                                selectedValues.header_country_code
                                            }
                                            styles={{
                                                control: (base) => ({
                                                    ...base,
                                                    borderColor:
                                                        selectedValues.header_country_code
                                                            ? "#89c995fc"
                                                            : "#ff00009e",
                                                    "&:hover": {
                                                        borderColor:
                                                            selectedValues.header_country_code
                                                                ? "#89c995fc"
                                                                : "#ff00009e",
                                                    },
                                                }),
                                            }}
                                            onChange={(e) =>
                                                setSelectedValues({
                                                    ...selectedValues,
                                                    header_country_code: e,
                                                })
                                            }
                                        />
                                        <span className="preview-column">
                                            {firstLine?.find(
                                                (e) =>
                                                    e?.index ===
                                                    selectedValues
                                                        .header_country_code
                                                        ?.value
                                            ) &&
                                                `Preview : ${
                                                    firstLine?.find(
                                                        (e) =>
                                                            e?.index ===
                                                            selectedValues
                                                                .header_country_code
                                                                ?.value
                                                    )?.name
                                                }`}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-group">
                                        <label
                                            className="mr-sm-2"
                                            htmlFor="csv_hotel_lat"
                                        >
                                            Latitude
                                            <span className="text-danger">
                                                &nbsp;*
                                            </span>
                                        </label>
                                        <Select
                                            options={data?.map((e) => ({
                                                value: e?.index,
                                                label: e?.name,
                                            }))}
                                            isClearable={true}
                                            name="header_latitude"
                                            value={
                                                selectedValues.header_latitude
                                            }
                                            onChange={(e) =>
                                                setSelectedValues({
                                                    ...selectedValues,
                                                    header_latitude: e,
                                                })
                                            }
                                            styles={{
                                                control: (base) => ({
                                                    ...base,
                                                    borderColor:
                                                        selectedValues.header_latitude
                                                            ? "#89c995fc"
                                                            : "#ff00009e",
                                                    "&:hover": {
                                                        borderColor:
                                                            selectedValues.header_latitude
                                                                ? "#89c995fc"
                                                                : "#ff00009e",
                                                    },
                                                }),
                                            }}
                                        />
                                        <span className="preview-column">
                                            {firstLine?.find(
                                                (e) =>
                                                    e?.index ===
                                                    selectedValues
                                                        .header_latitude?.value
                                            ) &&
                                                `Preview : ${
                                                    firstLine?.find(
                                                        (e) =>
                                                            e?.index ===
                                                            selectedValues
                                                                .header_latitude
                                                                ?.value
                                                    )?.name
                                                }`}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-group">
                                        <label
                                            className="mr-sm-2"
                                            htmlFor="csv_hotel_lon"
                                        >
                                            Longitude
                                            <span className="text-danger">
                                                &nbsp;*
                                            </span>
                                        </label>
                                        <Select
                                            options={data?.map((e) => ({
                                                value: e?.index,
                                                label: e?.name,
                                            }))}
                                            isClearable={true}
                                            name="header_longitude"
                                            value={
                                                selectedValues.header_longitude
                                            }
                                            onChange={(e) =>
                                                setSelectedValues({
                                                    ...selectedValues,
                                                    header_longitude: e,
                                                })
                                            }
                                            styles={{
                                                control: (base) => ({
                                                    ...base,
                                                    borderColor:
                                                        selectedValues.header_longitude
                                                            ? "#89c995fc"
                                                            : "#ff00009e",
                                                    "&:hover": {
                                                        borderColor:
                                                            selectedValues.header_longitude
                                                                ? "#89c995fc"
                                                                : "#ff00009e",
                                                    },
                                                }),
                                            }}
                                        />
                                        <span className="preview-column">
                                            {firstLine?.find(
                                                (e) =>
                                                    e?.index ===
                                                    selectedValues
                                                        .header_longitude?.value
                                            ) &&
                                                `Preview : ${
                                                    firstLine?.find(
                                                        (e) =>
                                                            e?.index ===
                                                            selectedValues
                                                                .header_longitude
                                                                ?.value
                                                    )?.name
                                                }`}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-group">
                                        <label
                                            className="mr-sm-2"
                                            htmlFor="csv_hotel_city"
                                        >
                                            City
                                        </label>
                                        <Select
                                            options={data?.map((e) => ({
                                                value: e?.index,
                                                label: e?.name,
                                            }))}
                                            isClearable={true}
                                            name="header_city"
                                            value={selectedValues.header_city}
                                            onChange={(e) =>
                                                setSelectedValues({
                                                    ...selectedValues,
                                                    header_city: e,
                                                })
                                            }
                                        />
                                        <span className="preview-column">
                                            {firstLine?.find(
                                                (e) =>
                                                    e?.index ===
                                                    selectedValues.header_city
                                                        ?.value
                                            ) &&
                                                `Preview : ${
                                                    firstLine?.find(
                                                        (e) =>
                                                            e?.index ===
                                                            selectedValues
                                                                .header_city
                                                                ?.value
                                                    )?.name
                                                }`}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-group">
                                        <label
                                            className="mr-sm-2"
                                            htmlFor="header_city_id"
                                        >
                                            City ID
                                        </label>
                                        <Select
                                            options={data?.map((e) => ({
                                                value: e?.index,
                                                label: e?.name,
                                            }))}
                                            isClearable={true}
                                            name="header_city_id"
                                            value={
                                                selectedValues.header_city_id
                                            }
                                            onChange={(e) =>
                                                setSelectedValues({
                                                    ...selectedValues,
                                                    header_city_id: e,
                                                })
                                            }
                                            menuPlacement="top"
                                        />
                                        <span className="preview-column">
                                            {firstLine?.find(
                                                (e) =>
                                                    e?.index ===
                                                    selectedValues
                                                        .header_city_id?.value
                                            ) &&
                                                `Preview : ${
                                                    firstLine?.find(
                                                        (e) =>
                                                            e?.index ===
                                                            selectedValues
                                                                .header_city_id
                                                                ?.value
                                                    )?.name
                                                }`}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-group">
                                        <label
                                            className="mr-sm-2"
                                            htmlFor="csv_hotel_city"
                                        >
                                            Chain
                                        </label>
                                        <Select
                                            options={data?.map((e) => ({
                                                value: e?.index,
                                                label: e?.name,
                                            }))}
                                            isClearable={true}
                                            name="header_chain"
                                            value={selectedValues.header_chain}
                                            onChange={(e) =>
                                                setSelectedValues({
                                                    ...selectedValues,
                                                    header_chain: e,
                                                })
                                            }
                                            menuPlacement="top"
                                        />
                                        <span className="preview-column">
                                            {firstLine?.find(
                                                (e) =>
                                                    e?.index ===
                                                    selectedValues.header_chain
                                                        ?.value
                                            ) &&
                                                `Preview : ${
                                                    firstLine?.find(
                                                        (e) =>
                                                            e?.index ===
                                                            selectedValues
                                                                .header_chain
                                                                ?.value
                                                    )?.name
                                                }`}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-group">
                                        <label
                                            className="mr-sm-2"
                                            htmlFor="csv_hotel_city"
                                        >
                                            Property Type
                                        </label>
                                        <Select
                                            options={data?.map((e) => ({
                                                value: e?.index,
                                                label: e?.name,
                                            }))}
                                            isClearable={true}
                                            name="header_type"
                                            value={selectedValues.header_type}
                                            onChange={(e) =>
                                                setSelectedValues({
                                                    ...selectedValues,
                                                    header_type: e,
                                                })
                                            }
                                            menuPlacement="top"
                                        />
                                        <span className="preview-column">
                                            {firstLine?.find(
                                                (e) =>
                                                    e?.index ===
                                                    selectedValues.header_type
                                                        ?.value
                                            ) &&
                                                `Preview : ${
                                                    firstLine?.find(
                                                        (e) =>
                                                            e?.index ===
                                                            selectedValues
                                                                .header_type
                                                                ?.value
                                                    )?.name
                                                }`}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-group">
                                        <label
                                            className="mr-sm-2"
                                            htmlFor="csv_hotel_city"
                                        >
                                            Star rating
                                        </label>
                                        <Select
                                            options={data?.map((e) => ({
                                                value: e?.index,
                                                label: e?.name,
                                            }))}
                                            isClearable={true}
                                            name="header_stars"
                                            value={selectedValues.header_stars}
                                            onChange={(e) =>
                                                setSelectedValues({
                                                    ...selectedValues,
                                                    header_stars: e,
                                                })
                                            }
                                            menuPlacement="top"
                                        />
                                        <span className="preview-column">
                                            {firstLine?.find(
                                                (e) =>
                                                    e?.index ===
                                                    selectedValues.header_stars
                                                        ?.value
                                            ) &&
                                                `Preview : ${
                                                    firstLine?.find(
                                                        (e) =>
                                                            e?.index ===
                                                            selectedValues
                                                                .header_stars
                                                                ?.value
                                                    )?.name
                                                }`}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-group">
                                        <label
                                            className="mr-sm-2"
                                            htmlFor="csv_hotel_city"
                                        >
                                            Zip code
                                        </label>
                                        <Select
                                            options={data?.map((e) => ({
                                                value: e?.index,
                                                label: e?.name,
                                            }))}
                                            isClearable={true}
                                            name="header_zip_code"
                                            value={
                                                selectedValues.header_zip_code
                                            }
                                            onChange={(e) =>
                                                setSelectedValues({
                                                    ...selectedValues,
                                                    header_zip_code: e,
                                                })
                                            }
                                            menuPlacement="top"
                                        />
                                        <span className="preview-column">
                                            {firstLine?.find(
                                                (e) =>
                                                    e?.index ===
                                                    selectedValues
                                                        .header_zip_code?.value
                                            ) &&
                                                `Preview : ${
                                                    firstLine?.find(
                                                        (e) =>
                                                            e?.index ===
                                                            selectedValues
                                                                .header_zip_code
                                                                ?.value
                                                    )?.name
                                                }`}
                                        </span>
                                    </div>
                                </div>
                            </form>
                        </>
                    )}

                    {progress && loading && (
                        <ProgressBar
                            now={progress}
                            label={`${progress}%`}
                            style={{
                                width: "100%",
                                height: "30px",
                                fontSize: "22px",
                            }}
                        />
                    )}
                </>
            </Modal.Body>
            <Modal.Footer>
                <>
                    {!hideBackButton && (
                        <>
                            <button
                                type="button"
                                className="btn btn-warning"
                                onClick={prev}
                            >
                                <i className="fas fa-caret-left"></i>{" "}
                                &nbsp;&nbsp; Back
                            </button>

                            <button
                                type="button"
                                className="btn btn-light"
                                onClick={handleClose}
                            >
                                Close
                            </button>
                        </>
                    )}
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleStartProcessing}
                        disabled={loading}
                    >
                        <i className="fas fa-upload"></i>
                        &nbsp;&nbsp; Start processing
                        {loading && (
                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        )}
                    </button>
                </>
            </Modal.Footer>
        </>
    );
}

export default Step3;
