import React, {useEffect, useMemo, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory ,useLocation} from "react-router-dom";
import InvalideHotelsService from "../../services/InvalideHotelSrvice"
import { toast } from 'react-toastify';
import Table from "../../components/Table/TablePaginationFromApi";
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux';
import { getCountries } from '../../redux/actions/CountryActions';

const reasons = [
    {
        label:"All reasons",
        value:"All reasons"
    },
    {
        label:"Address incomplete",
        value:"Address incomplete"
    },
    {
        label:"Incorrect country code",
        value:"Incorrect country code"
    },
    {
        label:"Missing field: address",
        value:"Missing fields: address"
    },
    {
        label:"Missing field: country code",
        value:"Missing fields: country"
    },
    {
        label:"Missing field: name",
        value:"Missing fields: name"
    },
    {
        label:"Missing field: HotelID",
        value:"Missing fields: ID"
    },

]

export default function InvalideHotels() {
    const history = useHistory();
    const location = useLocation();
    const [data,setData] = useState(null)
    const [isLoading,setLoading] = useState(false)
    const [keywordSearsh,setKeywordSearch] = useState("")
    const [CountrySelected,setCountrySelected] = useState([])
    const [offset,setOffset] = useState(0)
    const [limit,setLimit] = useState(10)
    const [reason,setReason] = useState('')
    const [countData,setcountData] = useState(0)
    const queryParams = new URLSearchParams(location.search);
    const timeoutId = useRef(null);
    const dispatch = useDispatch();
    const { countries} = useSelector

    ((state) => ({
        countries: state.countriesReducer.countries,
    }));
    const getInvalideHotels = async(keyword=keywordSearsh,CountrySelect=CountrySelected,reasonPage=reason,limitPage=limit,offsetPage=offset) =>{
            setLoading(true)
            setData(null)
            const getHotels = await InvalideHotelsService.getInvalideHotels(queryParams.get('id'),offsetPage,limitPage,keyword,CountrySelect,reasonPage)
            if(getHotels){
                setLoading(false)
                if(getHotels.status == 200 ){
                    if(getHotels.data.hotels){
                        let newState = data
                        newState=getHotels.data.hotels
                        setData(newState)
                        setcountData(getHotels.data.count)
                    }
                    else {
                        toast.info("No invalid hotels for this search",{toastId: "No invalid hotels for this search"});
                    }
                }   
                else 
                    toast.error("Error getting invalid hotels",{toastId: "error getting duplicate"});

            }
    }
    const handleFilterCountry = async(e) =>{
        setCountrySelected(e)
        if (timeoutId.current) 
            clearTimeout(timeoutId.current);
        timeoutId.current = setTimeout(() => {
            getInvalideHotels(keywordSearsh, e);
        }, 500);    
    }
    const handleFilterReason = async(e) =>{
        if(e.value !== "All reasons"){
            setReason(e.value)
            getInvalideHotels(keywordSearsh, CountrySelected,e.value);          
        }
        else{
            setReason("")
            getInvalideHotels(keywordSearsh, CountrySelected,"");  
        }
 
    }
    const handlePerRowsChange = async (newPerPage, page) => {
        setLimit(newPerPage) 
        getInvalideHotels(keywordSearsh,CountrySelected,reason,newPerPage,0)
    }
    const handlePageChange = (page) => {
        const newoffset = (page-1)*(limit)
        setOffset(newoffset)
        getInvalideHotels(keywordSearsh,CountrySelected,reason,limit,newoffset)
    };
    const GetValueVountry = useMemo(()=>{
        if(Array.isArray(countries)){
            const newState = countries.map(item=>{
                return{
                    value: item.iso2, 
                    label: item.name
                }
            })
            return newState
        }
        
        return []
    },[countries])
    useEffect(()=>{
        dispatch(getCountries());
        getInvalideHotels()
    },[])
    const columns = [
        {
            id: "HotelId",
            name: <div className="table-title">HotelID</div>,
            cell: (e) => {
                return <>{e?.hotel_code}</>;
            },
        },
        {
            id: "Name",
            name: <div className="table-title">Name</div>,
            cell: (e) => {
                return <>{e?.name || <span className='emptyRow'>Empty</span>} </>;
            },
        },
        {
            id: "Country code",
            name: <div className="table-title">Country code</div>,
            cell: (e) => {
                return <>{e?.country || <span className='emptyRow'>Empty</span>}</>;
            },
        },
        {
            id: "Name",
            name: <div className="table-title">City</div>,
            cell: (e) => {
                return <>{e?.city || <span className='emptyRow'>Empty</span>}</>;
            },
        },
        {
            id: "Country",
            name: <div className="table-title">Address</div>,
            cell: (e) => {
                return <>{e?.address || <span className='emptyRow'>Empty</span> }</>;
            },
        },
        {
            id: "Reason",
            name: <div className="table-title">Reason</div>,
            cell: (e) => {
                return <>{e?.invalidReason || <span className='emptyRow'>Empty</span>}</>;
            },
        },
    ];
  return (
    <>
            <Helmet>
                <title> Invalid properties </title>
            </Helmet>
            <div className="page-wrapper InvalideHotels " style={{ display: "block" }}>
                <div  className="duplicateInventory" style={{minHeight:"100vh"}}>
                    <div className='duplicateInventory-back' onClick={()=>{history.push("/catalogs-manager")}}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19 12H5M5 12L12 19M5 12L12 5" stroke="#6275E7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <p class="text-purple fs-6 fw-bold font-family-Lato m-0">Back</p>
                    </div>
                    <div >
                        <h3 className="page-title text-truncate text-dark font-weight-medium">
                            Invalid properties
                        </h3>
                    </div>

                                    <div className='searchAcionduplicateInventory'>
                                        <div className='searchFilter'>
                                                <div className='search'>
                                                    <div className='inputSvg'>
                                                        <input type="text" name="" id="" placeholder='search' value={keywordSearsh}onChange={(e)=>{setKeywordSearch(e.target.value)}}/>
                                                        {
                                                            keywordSearsh ? 
                                                            <svg onClick={()=>{setKeywordSearch("");getInvalideHotels("")}} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                                                <path d="M12.5 4.5L4.5 12.5M4.5 4.5L12.5 12.5" stroke=" #a3b0e4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                            </svg>
                                                            :
                                                            null
                                                        }                                                        
                                                    </div>
                                                    <div className='SearchBtn' onClick={()=>{getInvalideHotels()}}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="transparent">
                                                            <path stroke="white" d="M21 21L15.0001 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                        </svg>
                                                    </div>
                                                </div>
                                                 <div className='MultiSEelect'>
                                                    <Select    
                                                        options={GetValueVountry} 
                                                        closeMenuOnSelect={false}
                                                        isMulti
                                                        placeholder="Filter by country"
                                                        onChange={(e)=>{handleFilterCountry(e) }}
                                                    />
                                                </div>
                                                <div className='MultiSEelect'>
                                                    <Select    
                                                        options={reasons} 
                                                        closeMenuOnSelect={false}
                                                        isMulti={false}
                                                        placeholder="Filter by reason"
                                                        onChange={(e)=>{handleFilterReason(e) }}
                                                    />
                                                </div>
                                        </div>
                                    </div>
                                    <div className='w-100'>
                                        {
                                                data ?
                                                <p class="text-secondary fs-6 fw-normal font-family-Lato  m-0 pb-3">Showing {data.length} out of {countData} properties</p>
                                                :
                                                null
                                        }
                                        <div className='TableZone' >
                                            <div className="amenities_table">
                                                <Table
                                                    data={data ? data : [] }
                                                    columns={columns}
                                                    totalRows={data ? countData : 0}
                                                    noDataMessage={
                                                        <div style={{margin:"150px 0px",textAlign:"center"}} >
                                                            <img src="/assets/images/notfoundTbale.svg" alt="" style={{width:"300px"}} />
                                                            <h4 style={{fontWeight:"700"}}><strong>No properties</strong></h4>
                                                            <p className="h5">No properties meet your filters criteria</p>
                                                        </div>
                                                    }
                                                    handlePageChange={handlePageChange}
                                                    onChangeRowsPerPage={handlePerRowsChange}
                                                    paginationTotalRows={data ? countData : 0}
                                                    progressPending={isLoading}
                                                    pagination={true}
                                                    sortServer={true}
                                                    
                                                />
                                            </div>
                                        </div>
                                    </div>

                </div>
            </div>
    </>

  )
}


