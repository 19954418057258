import React from "react";
import Lottie from "react-lottie";
import preparingListAnimationData from "../../lotties/preparinglist.json";
import { separator } from "../../utilis/functions";

const preparingOptions = {
    loop: true,
    autoplay: true,
    animationData: preparingListAnimationData,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};

const PendingCatalog = (props) => {
    const { activeCatalog, catalog } = props;
    return (
        <>
            <div className="col-6">
                <h4>{activeCatalog?.name}</h4>
                <span
                    text="white"
                    className="badge rounded-pill text-white bg-warning"
                    style={{
                        fontWeight: 800,
                        fontSize: "13px",
                    }}
                >
                    STEP (1 of 2) : Preparing list
                </span>
            </div>
            <div
                className="col-6"
                style={{
                    textAlign: "right",
                    paddingTop: "5px",
                }}
            >
                {catalog?.inventory_source === "CONNECTOR" &&
                    catalog?.connector_rate != "" && (
                        <>
                            <span
                                style={{
                                    fontWeight: 600,
                                }}
                            >
                                Total hotels :
                                <span
                                    style={{
                                        color: "#8C9BEF",
                                        marginLeft: "5px",
                                        fontSize: "17px",
                                    }}
                                >
                                    {catalog?.connector_total_hotels
                                        ? separator(
                                              catalog?.connector_total_hotels
                                          )
                                        : 0}
                                </span>
                            </span>
                            <br />
                            <span
                                style={{
                                    fontWeight: 600,
                                }}
                            >
                                Rate :
                                <span
                                    style={{
                                        color: "#8C9BEF",
                                        marginLeft: "5px",
                                        fontSize: "17px",
                                    }}
                                >
                                    {catalog?.connector_rate
                                        ? separator(catalog?.connector_rate)
                                        : 0}
                                </span>
                            </span>
                        </>
                    )}
            </div>
            <div className="col-12 align-items-center">
                <Lottie options={preparingOptions} height={150} width={180} />
            </div>
        </>
    );
};
export default PendingCatalog;
