import {
    GET_HOTEL_RANKING_SUCCESS,
    GET_HOTEL_RANKING_LISTING,
    GET_HOTEL_RANKING_FAIL,
} from "../types";
import axios from "axios";

const PATH_URL = process.env.REACT_APP_BASE_URL;

export const getHotelRanking =
    (page, perPage, filter, sort_by, sort_order) => async (dispatch) => {
        dispatch({
            type: GET_HOTEL_RANKING_LISTING,
        });
        try {
            var params = new URLSearchParams();
            params.append("limit", perPage);
            params.append("offset", (page - 1) * perPage);
            params.append("search", filter);
            if (sort_by) {
                params.append("sort_by", sort_by);
            }
            if (sort_order) {
                params.append("sort_order", sort_order);
            }
            const res = await axios.get(PATH_URL + "/hotels-rankings", {
                params: params,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });
            dispatch({
                type: GET_HOTEL_RANKING_SUCCESS,
                payload: res.data.data,
                total: res.data.total,
            });
        } catch (e) {
            dispatch({
                type: GET_HOTEL_RANKING_FAIL,
                payload: console.log("Error in actions ", e),
            });
        }
    };
