import axios from "axios";
import { toast } from "react-toastify";
const url = process.env.REACT_APP_BASE_URL;

const ManageTeamService = {
    add: async (user) => {
        const result = await axios
            .put(url + "/company/user/add", user, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                toast.success("User added successfully");
                return {
                    data: res.data,
                    status: 200,
                };
            })
            .catch((error) => {
                toast.error(error.response.data.msg ?? "User add failed");
                return {
                    message: error.response.data.msg,
                    status: error.response.data.code,
                };
            });
        return result;
    },
    update: async (userId, company) => {
        const result = await axios
            .patch(url + "/company/user/edit/" + userId, company, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                toast.success("User updated successfully");
                return {
                    data: res.data,
                    status: 200,
                };
            })
            .catch((error) => {
                toast.error("User update failed");
                return {
                    message: error.response.data.message,
                    status: error.response.data.code,
                };
            });
        return result;
    },
    delete: async (userId) => {
        const result = await axios
            .delete(url + "/company/user/delete/" + userId, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                toast.success("user removed successfully");
                return {
                    data: res.data,
                    status: 200,
                };
            })
            .catch((error) => {
                toast.error("user removal failed");
                return {
                    message: error.response.data.message,
                    status: error.response.data.code,
                };
            });
        return result;
    },

    get: async () => {
        const result = await axios
            .get(url + "/company/users", {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                let result = {
                    users: res.data?.data,
                    status: 200,
                };
                return result;
            })
            .catch((error) => {
                return {
                    message: error.response.data.msg,
                    status: error.response.data.code,
                };
            });
        return result;
    },
};

export default ManageTeamService;
