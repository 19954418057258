/* eslint-disable import/no-anonymous-default-export */
import {
    SEARCH_HOTELS_SUCCESS,
    SEARCH_HOTELS_LISTING,
    SEARCH_HOTELS_FAIL,
    HOTEL_DETAILS_SUCCESS,
    HOTEL_DETAILS_FAIL,
    HOTEL_DETAILS_LISTING,
    GET_AUTO_COMPLETE_HOTEL_NAME_SUCCESS,
    GET_AUTO_COMPLETE_HOTEL_NAME_LISTING,
    GET_AUTO_COMPLETE_HOTEL_NAME_FAIL,
} from "../types";

const initialState = {
    hotelsList: [],
    hotelDetails: {},
    loadingDetails: false,
    loadingHotels: false,
    hotelNameList: [],
    loadingHotelName: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SEARCH_HOTELS_LISTING:
            return {
                ...state,
                loadingHotels: true,
                hotelsList: [],
            };
        case SEARCH_HOTELS_SUCCESS:
            return {
                ...state,
                hotelsList: action.payload,
                loadingHotels: false,
            };
        case SEARCH_HOTELS_FAIL:
            return {
                ...state,
                loadingHotels: false,
                hotelsList: [],
            };
        case HOTEL_DETAILS_LISTING:
            return {
                ...state,
                loadingDetails: true,
            };
        case HOTEL_DETAILS_SUCCESS:
            return {
                ...state,
                hotelDetails: action.payload,
                loadingDetails: false,
            };
        case HOTEL_DETAILS_FAIL:
            return {
                ...state,
                loadingDetails: false,
            };
        case GET_AUTO_COMPLETE_HOTEL_NAME_SUCCESS:
            return {
                ...state,
                hotelNameList: action.payload,
                loadingHotelName: false,
            };
        case GET_AUTO_COMPLETE_HOTEL_NAME_LISTING:
            return {
                ...state,
                hotelNameList: [],
                loadingHotelName: false,
            };
        case GET_AUTO_COMPLETE_HOTEL_NAME_FAIL:
            return {
                ...state,
                hotelNameList: [],
                loadingHotelName: false,
            };
        default:
            return state;
    }
}
