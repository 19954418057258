import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "../../../components/Select";
import InputAutoComplete from "./AutoComplete";
import {
    getComapareMapped,
    getComapareUnmapped,
    getOldHotels,
    getCompareMappingTotal,
} from "../../../redux/actions/PartnerReportAction";

const SearchForm = ({ filters, setFilters, selectedMapping, token }) => {
    const {
        searchFields,
        loadingCompareMappingTotal,
        loadingCompareMapped,
        loadingCompareUnmapped,
    } = useSelector((state) => ({
        searchFields: state.partnerReportReducer.searchFields ?? [],
        loadingCompareMappingTotal:
            state.partnerReportReducer.loadingCompareMappingTotal ?? [],
        loadingCompareMapped:
            state.partnerReportReducer.loadingCompareMapped ?? [],
        loadingCompareUnmapped:
            state.partnerReportReducer.loadingCompareUnmapped ?? [],
    }));
    const dispatch = useDispatch();

    return (
        <div className="card">
            <div className="card-header p-0 pb-2">
                <h4
                    style={{
                        marginLeft: "10px",
                        marginTop: "10px",
                        fontWeight: "500",
                    }}
                >
                    Search By :
                </h4>
            </div>
            <div className="card-body" style={{ padding: "10px" }}>
                <div className="row">
                    {searchFields?.map((e, index) => {
                        return (
                            <div
                                className="col-lg-3 col-md-12"
                                style={{
                                    margin: "0px",
                                }}
                                key={index}
                            >
                                {e?.autocomplete ? (
                                    <InputAutoComplete
                                        selectedMapping={selectedMapping}
                                        token={token}
                                        isClearable={true}
                                        name={e?.column}
                                        value={filters?.[e?.column]}
                                        filter={e?.column}
                                        placeholder={`Start typing for ${e?.name} ...`}
                                        onChange={(value) => {
                                            setFilters({
                                                ...filters,
                                                [e?.column]: value,
                                            });
                                        }}
                                    />
                                ) : (
                                    <Select
                                        options={e?.values?.map((v, i) => ({
                                            label: v,
                                            value: i,
                                        }))}
                                        name={e?.column}
                                        value={filters?.[e?.column]}
                                        placeholder={e?.name}
                                        isMulti
                                        isClearable={true}
                                        onChange={(element) => {
                                            setFilters({
                                                ...filters,
                                                [e?.column]: element,
                                            });
                                        }}
                                    />
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
            <div
                className="card-footer"
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    padding: "8px",
                }}
            >
                <button
                    className="btn waves-effect waves-light btn-rounded btn-warning text-white"
                    style={{ marginTop: "0px" }}
                    type="button"
                    onClick={async () => {
                        setFilters({
                            city: [],
                            country: [],
                            chain: [],
                        });
                        dispatch(
                            getCompareMappingTotal(
                                token,
                                selectedMapping?.value,
                                filters
                            )
                        );
                        dispatch(
                            getComapareMapped(
                                token,
                                selectedMapping?.value,
                                1,
                                10,
                                "",
                                filters
                            )
                        );
                        dispatch(
                            getComapareUnmapped(
                                token,
                                selectedMapping?.value,
                                1,
                                10,
                                "",
                                filters
                            )
                        );
                    }}
                >
                    Reset
                </button>
                &nbsp;
                <button
                    className="btn waves-effect waves-light btn-rounded btn-primary"
                    style={{ marginTop: "0px" }}
                    disabled={
                        loadingCompareMappingTotal ||
                        loadingCompareMapped ||
                        loadingCompareUnmapped
                    }
                    onClick={async () => {
                        dispatch(
                            getCompareMappingTotal(
                                token,
                                selectedMapping?.value,
                                filters
                            )
                        );
                        dispatch(
                            getComapareMapped(
                                token,
                                selectedMapping?.value,
                                1,
                                10,
                                "",
                                filters
                            )
                        );
                        dispatch(
                            getComapareUnmapped(
                                token,
                                selectedMapping?.value,
                                1,
                                10,
                                "",
                                filters
                            )
                        );
                        dispatch(
                            getOldHotels(
                                token,
                                selectedMapping?.value,
                                1,
                                10,
                                "",
                                filters
                            )
                        );
                    }}
                >
                    Search
                    {/*{(loadingCompareMappingTotal ||
                        loadingCompareMapped ||
                        loadingCompareUnmapped) && (
                        <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    )} */}
                </button>
            </div>
        </div>
    );
};
export default SearchForm;
