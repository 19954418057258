import React, { useEffect, useState } from "react";
import Table from "../../components/Table/TablePaginationFromApi";
import { useSelector, useDispatch } from "react-redux";
import { getOpportunitiesHotelListReport } from "../../redux/actions/PartnerReportAction";
import { UNMAPPED_REASON } from "../../utilis/const";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

const OpportunitiesHotelList = (props) => {
    const { token, filters } = props;
    const dispatch = useDispatch();
    const [perPage, setPerPage] = useState(10);
    const [filterText, setFilterText] = useState("");
    const [column, setColumn] = useState();
    const [sortDirection, setSortDirection] = useState();
    const {
        loadingOpportunitiesHotels,
        countOpportunitiesHotels,
        OpportunitiesHotelReportList,
    } = useSelector((state) => ({
        OpportunitiesHotelReportList:
            state.partnerReportReducer.OpportunitiesHotelReportList ?? [],
        loadingOpportunitiesHotels:
            state.partnerReportReducer.loadingOpportunitiesHotels,
        countOpportunitiesHotels:
            state.partnerReportReducer.countOpportunitiesHotels,
    }));
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    const renderTooltip = (name) => (props) => {
        return (
            <Tooltip id="button-tooltip" {...props}>
                {name}
            </Tooltip>
        );
    };

    const columns = [
        {
            id: "hotel_code",
            name: <div className="table-title">Partner Hotel Code</div>,
            //selector: (e) => e?.hotel_code,
            cell: (e) => {
                return <>{e?.hotel_code}</>;
            },
            sortable: true,
        },
        {
            id: "hotel_name",
            name: <div className="table-title">Name</div>,
            // selector: (e) => e?.hotel_name,
            cell: (e) => {
                return <>{e?.hotel_name}</>;
            },
            sortable: true,
        },

        {
            id: "city",
            name: <div className="table-title">City</div>,
            // selector: (e) => e?.city,
            cell: (e) => {
                return <>{e?.city}</>;
            },
            sortable: true,
        },
        {
            id: "country",
            name: <div className="table-title">Country</div>,
            // selector: (e) => e?.country,
            cell: (e) => {
                return <>{e?.country}</>;
            },
            sortable: true,
        },
        {
            id: "address",
            name: <div className="table-title">Address</div>,
            // selector: (e) => e?.address,
            cell: (e) => {
                return <>{e?.address}</>;
            },
            sortable: true,
        },

        {
            id: "latitude",
            name: <div className="table-title">Latitude</div>,
            // selector: (e) => e?.latitude,
            cell: (e) => {
                return <>{e?.latitude}</>;
            },
            sortable: true,
        },
        {
            id: "longitude",
            name: <div className="table-title">Longitude</div>,
            // selector: (e) => e?.longitude,
            cell: (e) => {
                return <>{e?.longitude}</>;
            },
            sortable: true,
        },
        {
            id: "unmapped_reason",
            name: <div className="table-title">Unmapped reason</div>,
            // selector: (e) => e?.longitude,
            cell: (e) => {
                return (
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip(
                            UNMAPPED_REASON.get(e?.unmapped_reason)
                        )}
                    >
                        <li>{e?.unmapped_reason}</li>
                    </OverlayTrigger>
                );
            },
            sortable: true,
        },
    ];

    useEffect(() => {
        dispatch(
            getOpportunitiesHotelListReport(token, 1, perPage, filterText, {
                hotel_id: filters?.hotel_id?.map((e) => e?.label) ?? [],
                cities: filters?.cities?.map((e) => e?.label) ?? [],
                countries: filters?.countries?.map((e) => e?.label) ?? [],
                hotel_name: filters?.hotel_name?.map((e) => e?.label) ?? [],
                partner_hotel_id:
                    filters?.partner_hotel_id?.map((e) => e?.label) ?? [],
                chains: filters?.chains?.map((e) => e?.label) ?? [],
                propertyType: filters?.propertyType?.map((e) => e?.label) ?? [],
                stars: filters?.stars?.map((e) => e?.label) ?? [],
            })
        );
    }, []);

    const handlePageChange = (page) => {
        dispatch(
            getOpportunitiesHotelListReport(
                token,
                page,
                perPage,
                filterText,
                {
                    hotel_id: filters?.hotel_id?.map((e) => e?.label) ?? [],
                    cities: filters?.cities?.map((e) => e?.label) ?? [],
                    countries: filters?.countries?.map((e) => e?.label) ?? [],
                    hotel_name: filters?.hotel_name?.map((e) => e?.label) ?? [],
                    partner_hotel_id:
                        filters?.partner_hotel_id?.map((e) => e?.label) ?? [],
                    chains: filters?.chains?.map((e) => e?.label) ?? [],
                    propertyType:
                        filters?.propertyType?.map((e) => e?.label) ?? [],
                    stars: filters?.stars?.map((e) => e?.label) ?? [],
                },
                column,
                sortDirection
            )
        );
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        dispatch(
            getOpportunitiesHotelListReport(
                token,
                page,
                newPerPage,
                filterText,
                {
                    hotel_id: filters?.hotel_id?.map((e) => e?.label) ?? [],
                    cities: filters?.cities?.map((e) => e?.label) ?? [],
                    countries: filters?.countries?.map((e) => e?.label) ?? [],
                    hotel_name: filters?.hotel_name?.map((e) => e?.label) ?? [],
                    partner_hotel_id:
                        filters?.partner_hotel_id?.map((e) => e?.label) ?? [],
                    chains: filters?.chains?.map((e) => e?.label) ?? [],
                    propertyType:
                        filters?.propertyType?.map((e) => e?.label) ?? [],
                    stars: filters?.stars?.map((e) => e?.label) ?? [],
                },
                column,
                sortDirection
            )
        );
        setPerPage(newPerPage);
    };

    const handleSort = async (c, sortD) => {
        dispatch(
            getOpportunitiesHotelListReport(
                token,
                1,
                perPage,
                filterText,
                {
                    hotel_id: filters?.hotel_id?.map((e) => e?.label) ?? [],
                    cities: filters?.cities?.map((e) => e?.label) ?? [],
                    countries: filters?.countries?.map((e) => e?.label) ?? [],
                    hotel_name: filters?.hotel_name?.map((e) => e?.label) ?? [],
                    partner_hotel_id:
                        filters?.partner_hotel_id?.map((e) => e?.label) ?? [],
                    chains: filters?.chains?.map((e) => e?.label) ?? [],
                    propertyType:
                        filters?.propertyType?.map((e) => e?.label) ?? [],
                    stars: filters?.stars?.map((e) => e?.label) ?? [],
                },
                c?.id,
                sortD
            )
        );
        setColumn(c?.id);
        setSortDirection(sortD);
    };

    return (
        <div style={{ marginTop: "15px" }}>
            <Table
                data={OpportunitiesHotelReportList ?? []}
                columns={columns}
                totalRows={countOpportunitiesHotels}
                handlePageChange={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                progressPending={loadingOpportunitiesHotels}
                onFilter={() => {
                    setResetPaginationToggle(true);

                    dispatch(
                        getOpportunitiesHotelListReport(
                            token,
                            1,
                            perPage,
                            filterText,
                            {
                                hotel_id:
                                    filters?.hotel_id?.map((e) => e?.label) ??
                                    [],
                                cities:
                                    filters?.cities?.map((e) => e?.label) ?? [],
                                countries:
                                    filters?.countries?.map((e) => e?.label) ??
                                    [],
                                hotel_name:
                                    filters?.hotel_name?.map((e) => e?.label) ??
                                    [],
                                partner_hotel_id:
                                    filters?.partner_hotel_id?.map(
                                        (e) => e?.label
                                    ) ?? [],
                                chains:
                                    filters?.chains?.map((e) => e?.label) ?? [],
                                propertyType:
                                    filters?.propertyType?.map(
                                        (e) => e?.label
                                    ) ?? [],
                                stars:
                                    filters?.stars?.map((e) => e?.label) ?? [],
                            }
                        )
                    );
                }}
                filterValue={filterText}
                handleClearFilter={() => {
                    setFilterText("");
                    setResetPaginationToggle(true);
                    dispatch(
                        getOpportunitiesHotelListReport(token, 1, perPage, "", {
                            hotel_id:
                                filters?.hotel_id?.map((e) => e?.label) ?? [],
                            cities: filters?.cities?.map((e) => e?.label) ?? [],
                            countries:
                                filters?.countries?.map((e) => e?.label) ?? [],
                            hotel_name:
                                filters?.hotel_name?.map((e) => e?.label) ?? [],
                            partner_hotel_id:
                                filters?.partner_hotel_id?.map(
                                    (e) => e?.label
                                ) ?? [],
                            chains: filters?.chains?.map((e) => e?.label) ?? [],
                            propertyType:
                                filters?.propertyType?.map((e) => e?.label) ??
                                [],
                            stars: filters?.stars?.map((e) => e?.label) ?? [],
                        })
                    );
                }}
                onChange={(e) => {
                    setFilterText(e.target.value);
                    if (e.target.value === "") {
                        setResetPaginationToggle(true);
                        dispatch(
                            getOpportunitiesHotelListReport(
                                token,
                                1,
                                perPage,
                                "",
                                {
                                    hotel_id:
                                        filters?.hotel_id?.map(
                                            (e) => e?.label
                                        ) ?? [],
                                    cities:
                                        filters?.cities?.map((e) => e?.label) ??
                                        [],
                                    countries:
                                        filters?.countries?.map(
                                            (e) => e?.label
                                        ) ?? [],
                                    hotel_name:
                                        filters?.hotel_name?.map(
                                            (e) => e?.label
                                        ) ?? [],
                                    partner_hotel_id:
                                        filters?.partner_hotel_id?.map(
                                            (e) => e?.label
                                        ) ?? [],
                                    chains:
                                        filters?.chains?.map((e) => e?.label) ??
                                        [],
                                    propertyType:
                                        filters?.propertyType?.map(
                                            (e) => e?.label
                                        ) ?? [],
                                    stars:
                                        filters?.stars?.map((e) => e?.label) ??
                                        [],
                                }
                            )
                        );
                    }
                }}
                filter={true}
                resetPaginationToggle={resetPaginationToggle}
                onSort={handleSort}
                sortServer={true}
            />
        </div>
    );
};

export default OpportunitiesHotelList;
