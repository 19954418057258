import axios from "axios";
const url = process.env.REACT_APP_BASE_URL;

const ModelsService = {
    addGiataCredentials: async (credentials) => {
        const result = await axios
            .post(url + `/giata/save-credentials`, credentials, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                return {
                    data: res.data,
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: error.response.data.msg,
                    status: error.response.data.code,
                };
            });
        return result;
    },
    runGiataMappingByPartner: async (key) => {
        const result = await axios
            .get(url + `/giata/mapping/${key}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                return {
                    data: res.data,
                    status: 200,
                };
            })
            .catch((error) => {
                console.log("Error", error);
                return {
                    message: error.response.data.msg,
                    status: error.response.data.code,
                };
            });
        return result;
    },
    logoutFromGiata: async () => {
        const result = await axios
            .get(url + `/giata/logout`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                return {
                    data: res.data,
                    status: 200,
                };
            })
            .catch((error) => {
                console.log("Error", error);
                return {
                    message: error.response.data.msg,
                    status: error.response.data.code,
                };
            });
        return result;
    },
};
export default ModelsService;
