import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getConnectors } from "../../../../redux/actions/ConnectorActions";
import ConnectorService from "../../../../services/ConnectorService";
import { Modal, Spinner } from "react-bootstrap";
import infoGif from "../../../../lotties/info.gif";
import { TRAVEL_GATE_FORM, TRAVEL_GATE_ID } from "../../../../utilis/const";

function Step2(props) {
    const {
        partner,
        selectedConnector,
        setSelectedConnector,
        next,
        prev,
        setShowForm,
        setCridentialsId,
        setIsPublic,
        setSelectedMethod,
        setIsTravelgateHub,
        handleClose,
        setFiles,
        setFormData,
        setSelectTravelgateConfig,
        hideBackButton,
    } = props;
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { directConnector, hubConnectors } = useSelector((state) => ({
        directConnector: state.connectorReducer.listConnectors?.find(
            (e) => parseInt(e?.id, 10) === parseInt(partner?.connector_id, 10)
        ),
        hubConnectors: state.connectorReducer.listConnectors?.filter(
            (e) => e?.is_direct === false
        ),
    }));

    const handleCheckCridentals = async () => {
        setLoading(true);
        const res = await ConnectorService.checkCridentials(
            selectedConnector?.id,
            partner?.id
        );
        if (res?.status === 200) {
            setShowForm(false);
            setCridentialsId(res?.data?.id);
            next();
        } else {
            await setShowForm(true);
            next();
        }
        setLoading(false);
    };

    useEffect(() => {
        dispatch(getConnectors());
    }, []);

    const hubConnectorsElements = hubConnectors?.map((e) => {
        return (
            <div className="col-6 col-md-4 col-lg-3">
                <div
                    className="integration-entity mx-auto"
                    style={{ marginBottom: "0px" }}
                >
                    <div
                        className="integration-entity-img-wrapper-connector"
                        style={{
                            transform:
                                selectedConnector?.id === e?.id
                                    ? "scale(1.2)"
                                    : "",
                        }}
                    >
                        <img
                            id={e.id}
                            className="cloudinary-image ls-is-cached lazyloaded round-img"
                            alt={e?.name}
                            src={e.logo}
                            width="100%"
                            height="100"
                            onClick={() => {
                                setIsPublic(false);
                                setSelectedConnector(e);
                                setSelectedMethod("Hub");
                                setFiles([]);
                                setFormData({});
                                setSelectTravelgateConfig(TRAVEL_GATE_FORM);
                                if (e?.id === TRAVEL_GATE_ID) {
                                    setIsTravelgateHub(true);
                                } else {
                                    setIsTravelgateHub(false);
                                }
                            }}
                            style={{
                                border:
                                    selectedConnector?.id === e?.id
                                        ? "2px solid rgb(96, 118, 232)"
                                        : "",
                            }}
                        />
                    </div>
                    <div className="integration-entity-name">{e?.name}</div>
                </div>
            </div>
        );
    });
    const directConnectorsElements = () => {
        return (
            <div className="col-12 col-md-12 col-lg-12">
                <div
                    className="integration-entity mx-auto"
                    style={{ marginBottom: "0px" }}
                >
                    <div
                        className="integration-entity-img-wrapper-connector"
                        style={{
                            transform:
                                selectedConnector?.id === directConnector?.id
                                    ? "scale(1.2)"
                                    : "",
                        }}
                    >
                        <img
                            id={directConnector?.id}
                            className="cloudinary-image ls-is-cached lazyloaded round-img"
                            alt={directConnector?.name}
                            src={directConnector?.logo}
                            width="100%"
                            height="100"
                            onClick={() => {
                                setIsPublic(false);
                                setSelectedConnector(directConnector);
                                setSelectedMethod("Direct");
                                setIsTravelgateHub(false);
                                setFiles([]);
                                setFormData({});
                                setSelectTravelgateConfig(TRAVEL_GATE_FORM);
                            }}
                            style={{
                                border:
                                    selectedConnector?.id ===
                                    directConnector?.id
                                        ? "2px solid rgb(96, 118, 232)"
                                        : "",
                            }}
                        />
                    </div>
                    <div className="integration-entity-name">
                        {directConnector?.name}
                    </div>
                </div>
            </div>
        );
    };
    const publicConnectorsElements = () => {
        return (
            <div className="col-12 col-md-12 col-lg-12">
                <div
                    className="integration-entity mx-auto"
                    style={{ marginBottom: "0px" }}
                >
                    <div
                        className="integration-entity-img-wrapper-connector"
                        style={{
                            transform:
                                selectedConnector?.id === partner?.id
                                    ? "scale(1.2)"
                                    : "",
                        }}
                    >
                        <img
                            id={partner.id}
                            className="cloudinary-image ls-is-cached lazyloaded round-img"
                            alt={partner?.name}
                            src={partner?.public_content_logo}
                            width="100%"
                            height="100"
                            onClick={() => {
                                setIsPublic(true);
                                setSelectedConnector(partner);
                                setSelectedMethod("Public");
                                setIsTravelgateHub(false);
                                setFiles([]);
                                setFormData({});
                                setSelectTravelgateConfig(TRAVEL_GATE_FORM);
                            }}
                            style={{
                                border:
                                    selectedConnector?.id === partner?.id
                                        ? "2px solid rgb(96, 118, 232)"
                                        : "",
                            }}
                        />
                    </div>
                    <div className="integration-entity-name">
                        {partner?.name}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div
                        className="alert custom-alert d-flex align-items-start justify-content-start"
                        style={{
                            color: "rgb(8 7 7)",
                            border: "3px solid #6076e8b0",
                            borderRadius: "5px",
                            marginBottom: "30px",
                            backgroundColor: "rgb(96 118 232 / 0%)",
                        }}
                    >
                        <i className="mr-1">
                            <img src={infoGif} height="60" width="60" />
                        </i>
                        <div style={{ marginTop: "7px" }}>
                            {(partner?.is_direct === 1 ||
                                partner?.is_direct === true) && (
                                <>
                                    <span
                                        style={{
                                            color: "rgb(95, 118, 232)",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Direct :
                                    </span>
                                    <span style={{ marginLeft: "13px" }}>
                                        connect through your partners API
                                        service.
                                    </span>

                                    <br />
                                </>
                            )}
                            {(partner?.has_public_content === 1 ||
                                partner?.has_public_content === true) && (
                                <>
                                    <span
                                        style={{
                                            color: "rgb(95, 118, 232)",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Public :
                                    </span>
                                    <span style={{ marginLeft: "11px" }}>
                                        connect to partners through Cupid’s
                                        catalog.
                                    </span>
                                    <br />
                                </>
                            )}
                            <span
                                style={{
                                    color: "rgb(95, 118, 232)",
                                    fontWeight: "bold",
                                }}
                            >
                                HUB :
                            </span>
                            <span style={{ marginLeft: "24px" }}>
                                connect to your partner through an API
                                aggregator.
                            </span>
                        </div>
                    </div>
                </div>
                {(partner?.is_direct === 1 || partner?.is_direct === true) && (
                    <div className="col-md-6">
                        <fieldset className="scheduler-border">
                            <legend className="scheduler-border">Direct</legend>
                            <div className="row">
                                {directConnectorsElements()}
                            </div>
                        </fieldset>
                    </div>
                )}

                {(partner?.has_public_content === 1 ||
                    partner?.has_public_content === true) && (
                    <div className="col-md-6">
                        <fieldset className="scheduler-border">
                            <legend className="scheduler-border">Public</legend>
                            <div className="row">
                                {publicConnectorsElements()}
                            </div>
                        </fieldset>
                    </div>
                )}
                <div className="col-md-12">
                    <fieldset className="scheduler-border">
                        <legend className="scheduler-border">HUB</legend>
                        <div className="row">{hubConnectorsElements}</div>
                    </fieldset>
                </div>
            </div>

            <Modal.Footer>
                {!hideBackButton && (
                    <button className="btn btn-warning" onClick={prev}>
                        <i className="fas fa-caret-left"></i> &nbsp;&nbsp; Back
                    </button>
                )}
                <button
                    type="button"
                    className="btn btn-light"
                    onClick={handleClose}
                >
                    Close
                </button>
                <button
                    className="btn btn-primary"
                    onClick={handleCheckCridentals}
                    disabled={loading}
                >
                    Next &nbsp;&nbsp;
                    <i className="fas fa-caret-right"></i>
                    {loading && (
                        <>
                            &nbsp;
                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        </>
                    )}
                </button>
            </Modal.Footer>
        </>
    );
}
export default Step2;
