import React from "react";
import ReactSelect from "react-select";
import { Controller } from "react-hook-form";

const Select = ({
    name,
    options,
    value,
    className,
    styles,
    isMulti,
    onChange,
    control,
    placeholder,
    isClearable,
    getOptionLabel,
    components,
    isOptionDisabled,
    isLoading,
    menuPlacement,
    formatOptionLabel,
}) => {
    let defaultValue;
    if (!Array.isArray(value) && (value || value == "")) {
        for (let { value: val, label } of options) {
            if (val == value) {
                defaultValue = { value, label };
                break;
            }
        }
    } else {
        defaultValue = value;
    }

    let props = {
        getOptionLabel,
        inputId: name + "-input",
        id: name,
        name,
        options,
        defaultValue: defaultValue || "",
        isClearable: isClearable ?? false,
        className,
        components,
        menuPlacement: "auto",
        isMulti: isMulti || false,
        styles: {
            ...styles,
            ...{
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
            },
            ...{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            },
        },
        theme: (theme) => ({
            ...theme,
            colors: {
                ...theme.colors,
                primary25: "#D3D7DB",
                primary: "#D3D7DB",
            },
        }),
        isOptionDisabled: isOptionDisabled,
    };

    if (menuPlacement) {
        props = { ...props, menuPlacement: menuPlacement };
    }
    if (formatOptionLabel) {
        props = { ...props, formatOptionLabel: formatOptionLabel };
    }

    if (control) {
        props.as = ReactSelect;
        props.control = control;
        return <Controller {...props} />;
    } else {
        return (
            <ReactSelect
                value={value}
                defaultValue={defaultValue}
                placeholder={placeholder ?? "Select..."}
                onChange={onChange}
                isSearchable={true}
                menuPortalTarget={document.body}
                isLoading={isLoading ?? false}
                {...props}
            />
        );
    }
};

export default Select;
