import React, { useEffect, useState } from "react";
import Table from "../../components/Table/Table";
import { useSelector, useDispatch } from "react-redux";
import {
    getConnectors,
    getCredentials,
} from "../../redux/actions/ConnectorActions";
import Lottie from "react-lottie";
import animationData from "../../lotties/rocket.json";
import AddConnector from "./Add-connector-steps/index";
import { getPartnerList } from "../../redux/actions/PartnerActions";
import ConnectorService from "../../services/ConnectorService";
import { toast } from "react-toastify";
import TableActions from "./TableActions";
import SchedulerModal from "./SchedulerModal";
import ConfirmAction from "../../components/ConfirmActionsModal";
import cronstrue from "cronstrue/i18n";
import { useHistory } from "react-router-dom";
import CatalogNoDataModal from "../../pages/Mapping/CatalogNoDataModal";
import {
    getGiataPartnersList,
    getCupidModels,
} from "../../redux/actions/ModelsActions";
import { getCatalogs } from "../../redux/actions/CatalogActions";
import { Helmet } from "react-helmet";
import { Modal, Spinner } from "react-bootstrap";
import Form from "@rjsf/core";
import { STATUS_PROCESSING, MANAGE_CONNECTORS } from "../../utilis/const";
import { hasPermission } from "../../utilis/functions";
import Select from "../../components/Select";
import { GIATA_MODEL } from "../../utilis/const";
import EditTravelgateCredentails from "./EditTravelgateCredentails";
import HubConfig from "./HubConfig";

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};
function ConnectorsList(props) {
    const [addConnector, setAddConnector] = useState(false);
    const [currentElement, setCurrentElement] = useState();
    const [showConfirmRemoveModal, setShowConfirmRemoveModal] = useState(false);
    const [showStopSchedulerModal, setShowStopSchedulerModal] = useState(false);
    const [showSchudlerModal, setShowSchudlerModal] = useState(false);
    const [showRunMappingModal, setShowRunMappingModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [loadingRemove, setLoadingRemove] = useState(false);
    const [loadingRunMapping, setLoadingRunMapping] = useState(false);
    const [loadingStopMapping, setLoadingStopMapping] = useState(false);
    const [connectorForm, setConnectorForm] = useState({});
    const [connectorUiSchema, setConnectorUiSchema] = useState({});
    const [dataCredential, setDataCredential] = useState({});
    const [editTravelgateCredentials, setEditTravelgateCredentials] =
        useState(false);

    const {
        cridentials,
        loadingCridentials,
        loading,
        activeCatalog,
        loadingCatalogs,
    } = useSelector((state) => ({
        cridentials: state?.connectorReducer?.listCridentials ?? [],
        loadingCridentials: state?.connectorReducer?.loadingCridentials,
        loading: state?.autoPilotReducer?.loading,
        activeCatalog: state.catalogReducer?.catalogs?.find(
            (e) => e.active === true
        ),
        loadingCatalogs: state.catalogReducer?.loading,
    }));
    const history = useHistory();

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getConnectors());
        dispatch(getCredentials());
        dispatch(getPartnerList());
        dispatch(getCatalogs());
        dispatch(getGiataPartnersList());
    }, []);

    const columns = React.useMemo(() => {
        const required = [
            {
                name: <div className="table-title">Partner</div>,
                selector: (e) => e?.partner_name,
                cell: (e) => {
                    return <>{e?.partner_name ?? ""}</>;
                },
                sortable: true,
            },
            {
                name: <div className="table-title">Connector</div>,
                selector: (e) => e?.connector_name,
                cell: (e) => {
                    return <>{e?.connector_name ?? ""}</>;
                },
                sortable: true,
            },

            {
                name: <div className="table-title">Scheduler</div>,
                selector: (e) => e?.crontab,
                cell: (e) => {
                    return (
                        <>
                            {e?.crontab != ""
                                ? cronstrue.toString(e?.crontab, {
                                      locale: "en",
                                  })
                                : ""}
                        </>
                    );
                },
                sortable: true,
            },
            {
                name: <div className="table-title">Model</div>,
                selector: (e) => e?.model,
                sortable: true,
                cell: (e) => {
                    return (
                        <span
                            className="logo-text"
                            style={{ fontSize: "18px" }}
                        >
                            {e?.model}
                        </span>
                    );
                },
            },
        ];
        let extra = [];
        if (hasPermission(MANAGE_CONNECTORS)) {
            extra.push({
                name: "",
                cell: (e) => {
                    return (
                        <>
                            <TableActions
                                element={e}
                                setShowConfirmModal={setShowConfirmRemoveModal}
                                setCurrentElement={setCurrentElement}
                                setShowSchudlerModal={setShowSchudlerModal}
                                setShowStopSchedulerModal={
                                    setShowStopSchedulerModal
                                }
                                setShowRunMappingModal={setShowRunMappingModal}
                                setShowEditModal={setShowEditModal}
                                setConnectorForm={setConnectorForm}
                                setConnectorUiSchema={setConnectorUiSchema}
                                setDataCredential={setDataCredential}
                            />
                        </>
                    );
                },
            });
        }

        return [...required, ...extra];
    }, [localStorage.getItem("role")]);
    const handleRemove = async () => {
        setLoadingRemove(true);
        try {
            const res = await ConnectorService.delete(currentElement?.id);
            if (res.status === 200) {
                dispatch(getCredentials());
                setShowConfirmRemoveModal(false);
                toast.success("Deleted successfully!");
                window.analytics.track("Remove credentials ", {
                    companyId: localStorage.getItem("companyId"),
                    userId: localStorage.getItem("userId"),
                    cridentialsId: currentElement.id,
                });
            } else {
                toast.error(res.error);
            }
            setLoadingRemove(false);
        } catch (e) {
            console.log(e);
            setLoadingRemove(false);
        }
    };
    const handleStopSChudeler = async () => {
        setLoadingStopMapping(true);
        try {
            const res = await ConnectorService.scheduler(
                currentElement?.id,
                ""
            );
            if (res.status === 200) {
                dispatch(getCredentials());
                setShowStopSchedulerModal(false);
                window.analytics.track("Stop scheduler", {
                    userId: localStorage.getItem("userId"),
                    companyId: localStorage.getItem("companyId"),
                    cridentialsId: currentElement.id,
                });
            } else {
                toast.error(res.error);
            }
            setLoadingStopMapping(false);
        } catch (e) {
            console.error(e);
            setLoadingStopMapping(false);
        }
    };

    const handleRunMapping = async () => {
        setLoadingRunMapping(true);
        try {
            let res = await ConnectorService.runMapping(currentElement.id);
            if (res.status === 200) {
                toast.success("Mapping has created!");
                setShowRunMappingModal(false);
                window.analytics.track("Run Mapping", {
                    cridentialsId: currentElement.id,
                    userId: localStorage.getItem("userId"),
                    companyId: localStorage.getItem("companyId"),
                });
            } else {
                toast.error(res.message);
                setShowRunMappingModal(false);
            }
            setLoadingRunMapping(false);
        } catch (e) {
            console.log("Error", e);
            setLoadingRunMapping(false);
        }
    };

    useEffect(() => {
        dispatch(getCupidModels());
    }, []);

    return (
        <>
            <Helmet>
                <title>Connectors</title>
            </Helmet>
            <div className="page-wrapper" style={{ display: "block" }}>
                <div className="page-breadcrumb">
                    <div className="row">
                        <div className="col-7 align-self-center">
                            <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
                                Manage your connectors
                            </h3>
                        </div>
                        <div className="col-12">
                            <p className="card-text">
                                <span className="sub-title-info ">
                                    <i className="fa  fa-info-circle"></i>
                                    &nbsp; Connectors allow you to automate the
                                    process of uploading your partner’s catalogs
                                    to your workspace. Here you can configure,
                                    manage and schedule your connectors.
                                </span>
                            </p>
                        </div>{" "}
                    </div>
                </div>
                {loading || loadingCridentials || loadingCatalogs ? (
                    <div className="text-center">
                        <div className="lds-ripple">
                            <div className="lds-pos"></div>
                            <div className="lds-pos"></div>
                        </div>
                    </div>
                ) : !activeCatalog ? (
                    <CatalogNoDataModal
                        title="Ready!"
                        text2="Your dedicated workspace is almost ready to go !"
                        text3="First, you’ll need to upload your hotel inventory."
                        buttonText="Upload inventory"
                        handleClick={async () => {
                            history.push("/catalogs-manager");
                        }}
                    />
                ) : activeCatalog?.mapping_process_status_id ==
                  STATUS_PROCESSING ? (
                    <div className="row">
                        <div className="col-12">
                            <CatalogNoDataModal
                                title="Almost ready!"
                                text="Your inventory is still processing."
                                hideButton={true}
                            />
                        </div>
                    </div>
                ) : cridentials?.length === 0 &&
                  hasPermission(MANAGE_CONNECTORS) ? (
                    <div style={{ textAlign: "center", marginTop: "5%" }}>
                        <Lottie options={defaultOptions} width={300} />
                        <button
                            className="modalBtn btn waves-effect waves-light btn-rounded btn-primary"
                            onClick={() => setAddConnector(true)}
                        >
                            Add connector
                        </button>
                    </div>
                ) : (
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                {(hasPermission(MANAGE_CONNECTORS)
                                    ? true
                                    : false) && (
                                    <HubConfig
                                        setEditTravelgateCredentials={
                                            setEditTravelgateCredentials
                                        }
                                    />
                                )}
                                <div className="card">
                                    <div className="card-body">
                                        <div className="d-flex align-items-center">
                                            <div className="ml-auto">
                                                {hasPermission(
                                                    MANAGE_CONNECTORS
                                                ) && (
                                                    <button
                                                        className="btn btn-info"
                                                        onClick={() => {
                                                            setAddConnector(
                                                                true
                                                            );
                                                        }}
                                                    >
                                                        Add connector
                                                    </button>
                                                )}
                                            </div>
                                        </div>

                                        <div className="table-responsive ">
                                            <Table
                                                data={cridentials}
                                                rowsPerPage={10}
                                                columns={columns}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {addConnector && (
                    <AddConnector
                        open={addConnector}
                        handleClose={() => setAddConnector(false)}
                    />
                )}
                {showConfirmRemoveModal && (
                    <ConfirmAction
                        show={showConfirmRemoveModal}
                        onClose={() => setShowConfirmRemoveModal(false)}
                        currentElement={currentElement}
                        titleMessage="Warning !"
                        subTitleMessage="Are you sure you want to delete this credentials?"
                        colorCode="danger"
                        handleClick={handleRemove}
                        buttonText="Confirm"
                        disabled={loadingRemove}
                    />
                )}
                {showStopSchedulerModal && (
                    <ConfirmAction
                        show={showStopSchedulerModal}
                        onClose={() => setShowStopSchedulerModal(false)}
                        currentElement={currentElement}
                        titleMessage="Warning !"
                        subTitleMessage="Are you sure you want to stop this scheduler?"
                        colorCode="danger"
                        handleClick={handleStopSChudeler}
                        buttonText="Confirm"
                        disabled={loadingStopMapping}
                    />
                )}
                {showRunMappingModal && (
                    <ConfirmAction
                        show={showRunMappingModal}
                        onClose={() => setShowRunMappingModal(false)}
                        currentElement={currentElement}
                        titleMessage="Action required !"
                        subTitleMessage="Are you sure you want to run mapping ?"
                        colorCode="success"
                        handleClick={handleRunMapping}
                        buttonText="Run mapping"
                        disabled={loadingRunMapping}
                    />
                )}
                {showSchudlerModal && (
                    <SchedulerModal
                        show={showSchudlerModal}
                        currentElement={currentElement}
                        onClose={() => setShowSchudlerModal(false)}
                    />
                )}
                {showEditModal && (
                    <UpdateCredentials
                        show={showEditModal}
                        currentElement={currentElement}
                        onClose={() => setShowEditModal(false)}
                        uiSchema={connectorUiSchema}
                        form={connectorForm}
                        dataCredential={dataCredential}
                    />
                )}
                {editTravelgateCredentials && (
                    <EditTravelgateCredentails
                        handleClose={() => setEditTravelgateCredentials(false)}
                        open={editTravelgateCredentials}
                    />
                )}
            </div>
        </>
    );
}
export default ConnectorsList;

function UpdateCredentials(props) {
    const { show, onClose, currentElement, uiSchema, form, dataCredential } =
        props;
    const dispatch = useDispatch();
    const { partnerKeys, cupidModelsList, loadingCupidModelsList } =
        useSelector((state) => ({
            partnerKeys: state.modelsReducer.giataPartnerList?.data?.map(
                (e) => ({
                    value: e?.key,
                    label: e?.name,
                })
            ),
            cupidModelsList: state.modelsReducer.cupidModelsList?.models
                ? [
                      ...state.modelsReducer.cupidModelsList?.models?.map(
                          (e) => ({
                              ...e,
                              value: e?.id,
                              label: e?.name,
                          })
                      ),
                      { value: GIATA_MODEL, label: "Giata" },
                  ]
                : [],
            loadingCupidModelsList: state.modelsReducer.loadingCupidModelsList,
        }));
    const [giataPartnerKey, setGiataPartnerKey] = useState(
        partnerKeys?.find(
            (e) => e?.value === dataCredential.data.giata_partner_key
        )
    );
    const [useManualMappings, setUseManualMapping] = useState(
        currentElement?.use_manual_mappings ?? false
    );

    const [connectorModel, setConnectorModel] = useState(
        currentElement?.model === GIATA_MODEL
            ? { value: GIATA_MODEL, label: "Giata" }
            : cupidModelsList?.find((e) => currentElement?.model === e?.label)
    );
    const [loadingUpdateCredentials, setLoadingUpdateCredentials] =
        useState(false);

    const handleSubmit = async (values) => {
        setLoadingUpdateCredentials(true);
        try {
            const res = await ConnectorService.update(
                currentElement?.id,
                JSON.stringify(values?.formData),
                giataPartnerKey?.value,
                connectorModel?.label,
                useManualMappings
            );
            if (res.status === 200) {
                //dispatch(getCredentials());
                setLoadingUpdateCredentials(false);
                onClose();
                await dispatch(getCredentials());
            } else {
                toast.error(res.error);
            }
            setLoadingUpdateCredentials(false);
        } catch (e) {
            console.error(e);
            setLoadingUpdateCredentials(false);
        }
    };

    return (
        <Modal
            show={show}
            onHide={onClose}
            dialogClassName="modal-50w modal-dialog modal-dialog-centered"
        >
            <div className="card mb-0 p-10">
                <Modal.Body>
                    <Form
                        schema={form}
                        uiSchema={uiSchema ? JSON.parse(uiSchema) : {}}
                        onChange={() => console.log("Handling Step")}
                        onSubmit={handleSubmit}
                        onError={() => console.log("Error")}
                    >
                        <div className="form-group field field-string">
                            <label className="control-label" for="root_ref_id">
                                Model
                            </label>
                            <Select
                                options={cupidModelsList}
                                name="connectorModel"
                                value={connectorModel}
                                placeholder="Model"
                                isClearable={false}
                                isMulti={false}
                                onChange={async (value) => {
                                    setConnectorModel(value);
                                    setGiataPartnerKey();
                                }}
                                isLoading={loadingCupidModelsList}
                            />
                        </div>

                        {connectorModel?.value === GIATA_MODEL ||
                            (connectorModel?.value !== GIATA_MODEL &&
                                connectorModel?.run_giata === true && (
                                    <div className="form-group field field-string">
                                        <label
                                            className="control-label"
                                            for="root_ref_id"
                                        >
                                            Partner key
                                        </label>
                                        <Select
                                            options={partnerKeys}
                                            name="giataPartnerKey"
                                            value={giataPartnerKey}
                                            placeholder="Partner key"
                                            isClearable={false}
                                            isMulti={false}
                                            onChange={async (value) => {
                                                setGiataPartnerKey(value);
                                            }}
                                        />
                                    </div>
                                ))}
                        <div
                            className="custom-control custom-checkbox"
                            style={{
                                marginRight: "35px",
                                marginBottom: "20px",
                            }}
                        >
                            <>
                                <input
                                    id={"usePreviousMapping"}
                                    checked={useManualMappings}
                                    onClick={() => {
                                        setUseManualMapping(!useManualMappings);
                                    }}
                                    type="checkbox"
                                    className="custom-control-input"
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    // id="customCheck1"
                                />
                                <label
                                    className="custom-control-label"
                                    for="usePreviousMapping"
                                >
                                    Use previous manual mapping
                                </label>
                            </>
                        </div>
                        <Modal.Footer>
                            <button
                                className="btn waves-effect waves-light btn-rounded btn-warning"
                                onClick={() => {
                                    onClose();
                                }}
                            >
                                Close
                            </button>
                            <button
                                type="submit"
                                disabled={loadingUpdateCredentials}
                                className="btn waves-effect waves-light btn-rounded btn-primary"
                                style={{
                                    marginLeft: "10px",
                                }}
                            >
                                Submit
                                {loadingUpdateCredentials && (
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                )}
                            </button>
                        </Modal.Footer>
                    </Form>
                </Modal.Body>
            </div>
        </Modal>
    );
}
// const UpdateConnectorModel = ({ dataCell }) => {
//     const [connectorModel, setConnectorModel] = useState(
//         dataCell?.model === GIATA_MODEL
//             ? { value: GIATA_MODEL, label: "Giata" }
//             : { value: CUPID_MODEL, label: "Cupid" }
//     );
//     return (
//         <div style={{ width: "100%" }}>
//             <Select
//                 options={[
//                     { value: CUPID_MODEL, label: "Cupid" },
//                     { value: GIATA_MODEL, label: "Giata" },
//                 ]}
//                 name="connectorModel"
//                 value={connectorModel}
//                 placeholder="Model"
//                 isClearable={false}
//                 isMulti={false}
//                 onChange={async (value) => {
//                     const res = await ConnectorService.updateModel(
//                         dataCell?.id,
//                         { ...dataCell, model: value.value }
//                     );
//                     if (res.status === 200) {
//                         setConnectorModel(value);
//                     } else {
//                         toast.error(res.message);
//                     }
//                 }}
//             />
//         </div>
//     );
// };
