import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { hasWhiteSpace } from "../utilis/functions";

function AddPartnerAttribute(props) {
    const {
        prev,
        next,
        setListAttributes,
        listAttributes,
        loadingSaveAttributes,
        hideButton,
    } = props;

    const [value, setValue] = useState();
    const [name, setName] = useState();

    return (
        <>
            <Modal.Body>
                <p>
                    {!hideButton && (
                        <h4
                            style={{
                                fontWeight: "500",
                                color: "#6076e8",
                                fontSize: "18px",
                            }}
                        >
                            Add custom informations about your partner :
                        </h4>
                    )}
                    <span
                        className="sub-title-info "
                        style={{ padding: "0px", fontSize: "16px" }}
                    >
                        Partner attributes can be used for customizing your
                        partners information and adapting it to your specific
                        use cases. This can be useful when handling mapping
                        outputs in your own systems. The Partner Attributes
                        added below will be added to the mapping report
                        downloadable zip files.
                        <br />
                        <span style={{ fontWeight: "500", color: "#333" }}>
                            However, please note that the only customized
                            attribute that will be exported directly to the
                            database is the
                            <span
                                style={{ color: "#6076e8", marginLeft: "4px" }}
                            >
                                PARTNER_ID
                            </span>
                            , and it needs to have that exact name in the
                            attribute name field:
                            <span
                                style={{ color: "#6076e8", marginLeft: "4px" }}
                            >
                                PARTNER_ID
                            </span>
                            .
                        </span>
                    </span>
                </p>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <div className="row" style={{ width: "90%" }}>
                        <div className="col-5">
                            <input
                                type="text"
                                className="form-control"
                                name="name"
                                id="text"
                                placeholder="Attribute name"
                                value={name}
                                onChange={(e) => {
                                    setName(e?.target?.value);
                                }}
                            />
                        </div>
                        <div className="col-5" style={{ paddingLeft: "0px" }}>
                            <input
                                type="text"
                                className="form-control"
                                name="value"
                                id="value"
                                placeholder="Attribute value "
                                value={value}
                                onChange={(e) => {
                                    setValue(e?.target?.value);
                                }}
                            />
                        </div>
                        <div className="col-2" style={{ paddingLeft: "0px" }}>
                            <button
                                className="btn w-100  btn-primary"
                                style={{
                                    padding: "0.325rem .75rem",
                                }}
                                onClick={() => {
                                    if (hasWhiteSpace(name)) {
                                        toast.error(
                                            "White space not allowed in attribute names "
                                        );
                                    } else {
                                        const list = [...listAttributes];
                                        setListAttributes([
                                            ...list,
                                            { name: name, value: value },
                                        ]);
                                        setValue("");
                                        setName("");
                                    }
                                }}
                            >
                                Add
                            </button>
                        </div>
                        {listAttributes?.length > 0 && (
                            <div
                                className="table-responsive attribute"
                                style={{
                                    marginTop: "20px",
                                    paddingLeft: "15px",
                                    paddingRight: "15px",
                                }}
                            >
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Attribute Name</th>
                                            <th scope="col">Attribute Value</th>
                                            <th scope="col">Remove</th>
                                        </tr>
                                    </thead>{" "}
                                    {listAttributes?.map((e, index) => {
                                        return (
                                            <>
                                                <tbody>
                                                    <tr>
                                                        {/* <th scope="row">1</th> */}
                                                        <td>{e?.name}</td>
                                                        <td>{e?.value}</td>
                                                        <td>
                                                            <button
                                                                className="btn "
                                                                onClick={() => {
                                                                    var array =
                                                                        [
                                                                            ...listAttributes,
                                                                        ];
                                                                    array.splice(
                                                                        index,
                                                                        1
                                                                    );
                                                                    setListAttributes(
                                                                        array
                                                                    );
                                                                }}
                                                            >
                                                                <i
                                                                    className="fas fa-times"
                                                                    style={{
                                                                        color: "red",
                                                                    }}
                                                                ></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </>
                                        );
                                    })}
                                </table>
                            </div>
                        )}
                    </div>
                </div>
            </Modal.Body>
            {hideButton ? (
                <></>
            ) : (
                <Modal.Footer>
                    <button
                        type="button"
                        className="btn btn-warning"
                        onClick={prev}
                    >
                        <i className="fas fa-caret-left"></i> &nbsp;&nbsp; Back
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary"
                        disabled={loadingSaveAttributes}
                        onClick={next}
                    >
                        Next &nbsp;&nbsp;
                        {loadingSaveAttributes && (
                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        )}
                        <i className="fas fa-caret-right"></i>
                    </button>
                </Modal.Footer>
            )}
        </>
    );
}
export default AddPartnerAttribute;
