import React, { useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import JsonLogo from "../../lotties/json_icon.png";
import { TRAVEL_GATE_FORM } from "../../utilis/const";

const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 6,
    borderRadius: 5,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
};

const focusedStyle = {
    borderColor: "#7f88ba",
};

const acceptStyle = {
    borderColor: "#7f88ba",
};

const rejectStyle = {
    borderColor: "#ff1744",
};

function UploadTravelgateConfig(props) {
    const { files, setFiles, setData, setSelectTravelgateConfig, setFormData } =
        props;

    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
        open,
    } = useDropzone({
        acceptedFiles: "application/json",
        onDrop: useCallback((acceptedFiles) => {
            const file = acceptedFiles[0];
            const reader = new FileReader();

            reader.onload = function (e) {
                const text = e.target.result;
                setFiles(acceptedFiles);
                setData(JSON.parse(text));
                setFormData(JSON.parse(text));
            };
            reader.readAsText(file);
            setSelectTravelgateConfig(TRAVEL_GATE_FORM);
        }, []),
    });

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isFocused, isDragAccept, isDragReject]
    );
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Remove
        </Tooltip>
    );
    const fileList = (
        <>
            {files?.map((file) => (
                <div className="text-center ">
                    <img
                        src={JsonLogo}
                        alt="CSV"
                        style={{ width: "70px", marginBottom: "10px" }}
                    />
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip}
                    >
                        <button
                            className="btn"
                            onClick={(e) => {
                                setFiles([]);
                            }}
                            style={{
                                paddingLeft: "0px",
                                // padding: "0px",
                                //marginBottom: "5%",
                            }}
                        >
                            <i
                                className="far fa-times-circle"
                                style={{
                                    color: "#776b6b",
                                    fontSize: "17px",
                                    marginBottom: "60px",
                                }}
                            ></i>
                        </button>
                    </OverlayTrigger>
                    <h3 style={{ fontWeight: "500" }}>
                        <i
                            className="fas fa-check"
                            style={{ color: "green", padding: "7px" }}
                        ></i>
                        {file.path}
                    </h3>
                    <h5
                        style={{
                            fontWeight: "800",
                            color: "rgb(74 76 80 / 74%)",
                        }}
                    >
                        {file?.size &&
                            (file?.size / (1024 * 1024)).toFixed(2) + "MB"}
                    </h5>
                </div>
            ))}
        </>
    );

    let inputProps = getInputProps();
    inputProps.name = "files";
    return (
        <>
            {files?.length == 0 && (
                <section>
                    <div>
                        <div
                            {...getRootProps({ style })}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <input {...getInputProps()} />
                            <p>
                                Drag and drop file here or click to select files
                            </p>
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={(e) => {
                                    e?.preventDefault();
                                    open();
                                }}
                            >
                                Open File Dialog
                            </button>
                        </div>
                    </div>
                </section>
            )}

            {fileList}

            <div style={{ marginTop: "10px" }}>
                <div className="home">
                    <button
                        className="btn text-link-button"
                        onClick={() => {
                            // Create new tag for download file
                            const anchor = document.createElement("a");
                            //anchor.download = name;
                            anchor.href =
                                "https://public-cupid.s3.us-east-2.amazonaws.com/samples/Travelgate_config_template.json";
                            anchor.dataset.downloadurl = [
                                "application/json",
                                anchor.download,
                            ].join(":");
                            anchor.target = "_blank";
                            anchor.download = "test.json";
                            anchor.click();
                            // Remove URL.createObjectURL. The browser should not save the reference to the file.
                            setTimeout(() => {
                                // For Firefox it is necessary to delay revoking the ObjectURL
                                URL.revokeObjectURL(
                                    "https://public-cupid.s3.us-east-2.amazonaws.com/samples/Travelgate_config_template.json"
                                );
                            }, 100);
                        }}
                    >
                        Download JSON template
                    </button>
                </div>
            </div>
        </>
    );
}

export default UploadTravelgateConfig;
