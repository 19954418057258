import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Table from "../../components/Table/Table";
import { Spinner } from "react-bootstrap";
import moment from "moment";
import {
    UPDATE_EXISTING_CATALOG,
    ADD_TO_CATALOG,
    GIATA_MODEL,
} from "../../utilis/const";
import fileDownload from "js-file-download";
import PartnerService from "../../services/PartnerService";
import { toast } from "react-toastify";
import { separator } from "../../utilis/functions";

const STATUS_PROCESSING = 1;
const statusBadgeVariant = (status) => {
    switch (status) {
        case 0:
            return {
                color: "warning",
                status: "Loading",
            };
        case 1:
            return {
                color: "info",
                status: "Processing",
            };
        case 2:
            return {
                color: "success",
                status: "Mapped",
            };
        case 3:
            return {
                color: "danger",
                status: "Failed",
            };
        default:
            return {
                color: "",
                status: "",
            };
    }
};
const actionBadgeVariant = (action) => {
    switch (action) {
        case ADD_TO_CATALOG:
            return {
                color: "#111111",
            };
        case UPDATE_EXISTING_CATALOG:
            return {
                color: "rgb(125 126 127)",
            };
        default:
            return {
                color: "",
                status: "",
            };
    }
};

const MappingDetailsModel = (props) => {
    const { handleClose, show, data } = props;

    const columns = [
        {
            name: <div className="table-title">Catalog</div>,
            sortable: true,
            selector: (e) => e?.name,
            cell: (e) => {
                return <>{e?.name}</>;
            },
        },
        {
            name: <div className="table-title">Total hotels</div>,
            sortable: true,
            selector: (e) => e?.total_hotels,
            cell: (e) => {
                return <>{e?.total_hotels ? separator(e?.total_hotels) : 0}</>;
            },
        },

        {
            name: <div className="table-title">Mapped</div>,
            sortable: true,
            selector: (e) => e?.mapped_hotels,
            cell: (e) => {
                const mapped_hotels = e?.mapped_hotels ?? 0;
                const valid_hotels = e?.valid_hotels ?? 0;
                const pourcentage =
                    valid_hotels != 0
                        ? (mapped_hotels / valid_hotels) * 100
                        : 0;
                return (
                    <>{`${pourcentage.toFixed(0)}% (${
                        e?.mapped_hotels ? separator(e?.mapped_hotels) : 0
                    })`}</>
                );
            },
        },
        {
            name: <div className="table-title">Opportunities</div>,
            sortable: true,
            selector: (e) => e?.unmapped_hotels,
            cell: (e) => {
                const unmapped_hotels = e?.unmapped_hotels ?? 0;
                const valid_hotels = e?.valid_hotels ?? 0;
                const pourcentage =
                    valid_hotels != 0
                        ? (unmapped_hotels / valid_hotels) * 100
                        : 0;
                return (
                    <>{`${pourcentage.toFixed(0)}% (${
                        e?.unmapped_hotels ? separator(e?.unmapped_hotels) : 0
                    })`}</>
                );
            },
        },
        {
            name: <div className="table-title">Model</div>,
            sortable: true,
            selector: (e) => e?.model,
            cell: (e) => {
                return (
                    <>
                        <span
                            className="logo-text"
                            style={{ fontSize: "15px" }}
                        >
                            {e?.model}
                        </span>
                    </>
                );
            },
        },
        {
            name: <div className="table-title">Status</div>,
            sortable: true,
            selector: (e) => e?.mapping_process_status_id,
            cell: (e) => {
                return (
                    <span
                        text="white"
                        className={`badge rounded-pill text-white bg-${
                            statusBadgeVariant(e?.mapping_process_status_id)
                                ?.color
                        }`}
                    >
                        {
                            statusBadgeVariant(e?.mapping_process_status_id)
                                ?.status
                        }
                    </span>
                );
            },
        },
        {
            name: <div className="table-title">Action</div>,
            sortable: true,
            selector: (e) => e?.action,
            cell: (e) => {
                return (
                    <span
                        text="white"
                        className={`badge rounded-pill text-white `}
                        style={{
                            backgroundColor:
                                actionBadgeVariant(e?.action)?.color ?? "",
                        }}
                    >
                        {e?.action?.toUpperCase()}
                    </span>
                );
            },
        },
        {
            name: <div className="table-title">Created</div>,
            sortable: true,
            selector: (e) => e?.created_at,
            cell: (e) => {
                return (
                    <>
                        {moment(new Date(e?.created_at)).format(
                            "DD/MM/YYYY HH:mm:ss"
                        )}
                    </>
                );
            },
        },
        {
            name: <div className="table-title">Download</div>,
            sortable: false,
            cell: (e) => {
                var rateProccessing =
                    e?.mapping_process_status_id === STATUS_PROCESSING
                        ? (
                              ((e?.parent?.sum_valid_hotels -
                                  e?.parent?.sum_mapped_hotels -
                                  e?.parent?.sum_unmapped_hotels) *
                                  1.6) /
                              1000 /
                              60
                          ).toFixed(0, 10)
                        : 0;
                return (
                    <>
                        {e?.parent?.mapping_process_status_id ===
                            STATUS_PROCESSING && (
                            <small
                                style={{
                                    color: "#21CA7F",
                                    fontWeight: "900",
                                    marginLeft: "5px",
                                }}
                            >
                                Estimated time ~ {rateProccessing} min
                            </small>
                        )}
                        {e?.mapping_process_status_id == 2 ? (
                            <DownloadCatalog dataCell={e} />
                        ) : (
                            e.mapping_process_status_id != 2 &&
                            e.mapping_process_status_id != 3 && (
                                <Spinner
                                    animation="border"
                                    variant="primary"
                                    size="sm"
                                />
                            )
                        )}
                    </>
                );
            },
        },
    ];
    return (
        <Modal
            show={show}
            onHide={handleClose}
            dialogClassName={"modal-90w modal-dialog modal-dialog-centered"}
        >
            <Modal.Header>
                <Modal.Title>Catalog history </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table data={data ?? []} columns={columns} filter={false} />
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleClose}
                >
                    Close
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default MappingDetailsModel;

function DownloadCatalog({ dataCell }) {
    const [pending, setPending] = useState(false);

    const handleDowload = (id) => async () => {
        setPending(true);
        try {
            let res = await PartnerService.dowloadPartnerCatalog(id, 1);
            if (res.status === 200) {
                fileDownload(res.data, res?.fileName);
                setPending(false);
                window.analytics.track("Download Mapping", {
                    userID: localStorage.getItem("userId"),
                    companyID: localStorage.getItem("companyId"),
                    companyName: localStorage.getItem("companyName"),
                    firstName: localStorage.getItem("firstName"),
                    lastName: localStorage.getItem("lastName"),
                    email: localStorage.getItem("email"),
                    catalogID: dataCell?.id,
                    partnerName: dataCell?.partner_name,
                    partnerID: dataCell?.partner_id,
                    catalogName: dataCell?.name,
                    mapped: dataCell?.mapped_hotels,
                    opportunities: dataCell?.unmapped_hotels,
                    possible: dataCell?.to_review_hotels,
                });
            } else {
                toast.error("Something went wrong while downloading this file");
                setPending(false);
            }
        } catch (err) {
            toast.error("Something went wrong while downloading this file");
            setPending(false);
        }
    };

    return (
        <>
            <button className="btn" onClick={handleDowload(dataCell?.id)}>
                {pending ? (
                    <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                ) : (
                    <i className="fas fa-download"></i>
                )}
            </button>
        </>
    );
}
