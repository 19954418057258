import React from "react";
import AsyncSelect from "react-select/async";
import SegmentService from "../../../services/SegmentService";

export default function InputAutoComplete({
    token,
    onChange,
    placeholder,
    name,
    styles,
    isClearable,
    value,
}) {
    const loadOptions = (inputValue, callback) => {
        if (inputValue?.length > 2) {
            SegmentService.searchHotelCities(
                encodeURIComponent(inputValue.trim())
            ).then((res) => {
                let data =
                    res?.data?.length > 0
                        ? [
                              ...res?.data?.map((e, index) => ({
                                  id: index + 1,
                                  name: e,
                              })),
                          ]
                        : [];
                callback(
                    data?.map((result) => {
                        return {
                            value: result?.name,
                            label: result.name,
                            inputValue: inputValue,
                        };
                    })
                );
            });
        }
    };

    let props = {
        inputId: name + "-asyncInput",
        id: name,
        isClearable: isClearable ?? false,
        styles: {
            ...styles,
            ...{
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
            },
        },
        theme: (theme) => ({
            ...theme,
            colors: {
                ...theme.colors,
                primary25: "#D3D7DB",
                primary: "#D3D7DB",
            },
        }),
    };
    return (
        <>
            <AsyncSelect
                value={value}
                //cacheOptions
                loadOptions={loadOptions}
                placeholder={placeholder ?? "Select..."}
                onChange={(property, value) => {
                    if (property?.value === 0) {
                        onChange({ value: 0, label: property.inputValue });
                    } else {
                        onChange(property);
                    }
                }}
                {...props}
                isMulti
            />
        </>
    );
}
