import React from "react";
import infoGif from "../lotties/info.gif";

// 0 to 200000 without message
//> 200000 message

function WaitingMessage(props) {
    const { number } = props;

    return (
        <>
            <div
                className="alert custom-alert d-flex align-items-start justify-content-start"
                style={{
                    color: "rgb(8 7 7)",
                    border: "3px solid #6076e8b0",
                    borderRadius: "5px",
                    marginBottom: "30px",
                    backgroundColor: "rgb(96 118 232 / 0%)",
                }}
            >
                <i className="mr-1">
                    <img src={infoGif} height="60" width="60" />
                </i>
                <div>
                    <p
                        style={{
                            marginTop: "0px",
                            fontSize: "18px",
                            marginBottom: "5px",
                        }}
                    >
                        {`You're uploading ${number - 1} properties.`}
                        <br />
                        {`Wow! that's a lot of inventory.`}&nbsp;
                        {`Don't worry it's only going to take you a few minutes with us.`}
                    </p>
                </div>
            </div>
        </>
    );
}
export default WaitingMessage;
