import React from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

function Card1() {
    const history = useHistory();
    const { partnerSteps } = useSelector((state) => ({
        partnerSteps: state.analyticsReducer.partnerSteps,
    }));
    return (
        <div
            className={`card boxShadow ${
                partnerSteps?.has_inventory ? `card-info-complete` : `card-info`
            }`}
        >
            <div
                className={`IconBadge card-home  ${
                    partnerSteps?.has_inventory
                        ? `card-home-icon-complete`
                        : `purple`
                }`}
                style={{ padding: "6px" }}
            >
                <span
                    style={{
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "31px",
                        marginLeft: "22px",
                    }}
                >
                    1
                </span>
            </div>
            <div
                className="card-body card-body-home"
                style={{ minHeight: "135px" }}
            >
                <h4
                    className="card-title cardTitle"
                    style={{ maxHeight: "48px" }}
                >
                    Upload your Inventory
                </h4>
                <p className="card-paragraph">
                    Upload your hotel inventory to your workspace.
                    <br />
                    {!partnerSteps?.has_inventory && (
                        <button
                            className="btn text-link-button"
                            onClick={() => {
                                window.open(
                                    "https://public-cupid.s3.us-east-2.amazonaws.com/samples/sample_inventory.csv",
                                    "_blank"
                                );
                            }}
                        >
                            Get sample inventory here
                        </button>
                    )}
                </p>
            </div>
            <div className="card-footer card-info-footer">
                {partnerSteps?.has_inventory ? (
                    <button
                        type="button"
                        disabled={true}
                        className="button-step-complete"
                    >
                        <i className="fas fa-check mr-6"></i>
                        This step is complete!
                    </button>
                ) : (
                    <Link
                        className="btn waves-effect waves-light btn-rounded btn-outline-primary"
                        onClick={() => {
                            history.push("catalogs-manager");
                        }}
                    >
                        Upload your inventory
                    </Link>
                )}
            </div>
        </div>
    );
}
export default Card1;
