import React from "react";
import Select from "../../../../components/Select";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getPartnerAttributes } from "../../../../redux/actions/PartnerActions";
import AddPartnerAttribute from "../../../../components/AddPartnerAttribute";

function ConnectorChoise(props) {
    const {
        loadingSaveAttributes,
        selectedOption,
        setSelectedOption,
        next,
        name,
        setName,
        setSelectedConnector,
        setIsPublic,
        showAddNewPartner,
        setShowAddNewPartner,
        partnerName,
        setPartnerName,
        loadingPartnerCreation,
        partner,
        showPartnerAttributes,
        setShowPartnerAttributes,
        listAttributes,
        setListAttributes,
        handleClose,
    } = props;
    const { partnerList } = useSelector((state) => ({
        partnerList: state.partnerReducer.partners,
    }));
    const dispatch = useDispatch();

    // handle onChange event of the dropdown
    const handleChange = (e) => {
        setSelectedOption(e);
        setIsPublic(false);
        setSelectedConnector(null);
        dispatch(getPartnerAttributes(e?.value));
    };

    return (
        <>
            <Modal.Body>
                <div className="row">
                    {partner === undefined && (
                        <div className="col-lg-6">
                            {showAddNewPartner ? (
                                <fieldset
                                    className="scheduler-border"
                                    style={{ minHeight: "220px" }}
                                >
                                    <legend className="scheduler-border">
                                        <h3 className="card-title">
                                            Add new partner
                                        </h3>
                                    </legend>

                                    <div className="form-group form-group-header">
                                        <label className="mr-sm-2">
                                            Add new partner
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Partner name"
                                            name="partner_name"
                                            value={partnerName ?? ""}
                                            onChange={(e) => {
                                                setPartnerName(e.target.value);
                                            }}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                        }}
                                    >
                                        Already exists ? &nbsp;
                                        <Link
                                            onClick={() => {
                                                setShowAddNewPartner(false);
                                                setPartnerName("");
                                                setListAttributes([]);
                                            }}
                                        >
                                            Select partner
                                        </Link>
                                    </div>
                                </fieldset>
                            ) : (
                                <fieldset
                                    className="scheduler-border"
                                    style={{ minHeight: "220px" }}
                                >
                                    <legend className="scheduler-border">
                                        <h3 className="card-title">
                                            Select existing partner
                                        </h3>
                                    </legend>

                                    <div className="form-group form-group-header">
                                        <label>Partner name </label>
                                        <Select
                                            placeholder="Select partner"
                                            value={selectedOption}
                                            options={partnerList?.map((e) => ({
                                                ...e,
                                                value: e?.id,
                                                label: e?.name,
                                            }))}
                                            isClearable={true}
                                            onChange={handleChange}
                                            name="connector-choise"
                                        />
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                        }}
                                    >
                                        Don't find it ?&nbsp;
                                        <Link
                                            onClick={() => {
                                                setShowAddNewPartner(true);
                                                setSelectedOption(null);
                                                setIsPublic(false);
                                                setSelectedConnector(null);
                                                setListAttributes([]);
                                            }}
                                        >
                                            Create new partner
                                        </Link>
                                    </div>
                                </fieldset>
                            )}
                        </div>
                    )}

                    {partner === undefined && (
                        <div
                            className="col-lg-12"
                            style={{ textAlign: "center" }}
                        >
                            <Link
                                className="text-middle"
                                onClick={() =>
                                    setShowPartnerAttributes(
                                        !showPartnerAttributes
                                    )
                                }
                            >
                                {showPartnerAttributes
                                    ? "Hide  advanced Settings"
                                    : "Show advanced Settings"}
                            </Link>
                        </div>
                    )}
                    {showPartnerAttributes && (
                        <div className="col-lg-12">
                            <AddPartnerAttribute
                                next={next}
                                listAttributes={listAttributes}
                                setListAttributes={setListAttributes}
                                loadingSaveAttributes={loadingSaveAttributes}
                                hideButton={true}
                            />
                        </div>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    className="btn btn-light"
                    onClick={handleClose}
                >
                    Close
                </button>
                <button
                    className="btn btn-primary"
                    onClick={next}
                    disabled={loadingPartnerCreation}
                >
                    Next &nbsp;&nbsp;
                    <i className="fas fa-caret-right"></i>
                    {loadingPartnerCreation && (
                        <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    )}
                </button>
            </Modal.Footer>{" "}
        </>
    );
}
export default ConnectorChoise;
