import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import LoginService from "../../services/LoginService";
import { toast } from "react-toastify";

const schema = yup.object().shape({
    email: yup
        .string()
        .email("Must be a valid email")
        .required("Email is required"),
});

function ResetPasswordForm(props) {
    const { setShowResetContent } = props;
    const [loading, setLoading] = useState(false);
    const [showMesssage, setShowMessage] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const handleSendEmail = async (values) => {
        setLoading(true);
        const res = await LoginService.forgotPassword(values);
        try {
            if (res.status === 200) {
                setLoading(false);
                setShowMessage(true);
            } else {
                setLoading(false);
                toast.error(res?.message);
            }
        } catch (err) {
            setLoading(false);
            toast.error("Server error");
        }
    };

    return (
        <>
            <h1 className="mb-0 fw-bold text-center">
                <button
                    className="btn"
                    onClick={() => {
                        setShowResetContent(false);
                    }}
                >
                    <i className="fas fa-arrow-left"></i>
                </button>
                Reset your password
            </h1>
            {showMesssage ? (
                <MessageAfterEmailsent />
            ) : (
                <>
                    <p
                        className="mb-6 text-center text-muted"
                        style={{ marginTop: "1rem" }}
                    >
                        Please enter your email address. We will send you an
                        email to reset your password.
                    </p>
                    <form
                        className="mb-6"
                        onSubmit={handleSubmit(handleSendEmail)}
                    >
                        <div className="form-group">
                            <input
                                type="email"
                                className="form-control2"
                                name="email"
                                id="email"
                                placeholder="name@address.com"
                                autoComplete="off"
                                {...register("email")}
                            />
                            {errors?.email && (
                                <small
                                    id="emailHelp"
                                    className="text-danger form-text"
                                >
                                    {errors?.email?.message}
                                </small>
                            )}
                        </div>
                        <button
                            className="btn2 w-100 btn-primary2"
                            type="submit"
                            disabled={loading}
                        >
                            Send Email
                            {loading && (
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            )}
                        </button>
                    </form>
                </>
            )}
        </>
    );
}
export default ResetPasswordForm;

function MessageAfterEmailsent(props) {
    return (
        <div
            style={{
                marginTop: "27px",
                border: "2px solid #6375e787",
                borderRadius: "14px",
                background: "rgb(99 117 231 / 14%)",
            }}
        >
            <p
                className="mb-6 text-center"
                style={{
                    marginTop: "1rem",
                    fontWeight: "bold",
                    color: "#333333",
                }}
            >
                We have sent an email to the address registered with this
                account containing further instructions to reset your password.
            </p>
        </div>
    );
}
