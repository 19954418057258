import axios from "axios";
const url = process.env.REACT_APP_BASE_URL;

const CatalogService = {
    dowloadCatalog: async (id) => {
        const result = await axios
            .get(url + `/catalogs/download/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                responseType: "blob",
            })
            .then((res) => {
                const re = new RegExp('filename=\\"([a-zA-Z0-9.\\-_]+)');
                return {
                    data: res.data,
                    fileName:
                        res.headers?.["content-disposition"].match(re)?.[1],
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: "Error while creating file ",
                    status: 500,
                };
            });
        return result;
    },

    uploadCatalog: async (data, opt) => {
        var path_url = "";
        path_url = "/catalogs/upload";
        const result = await axios
            .post(url + path_url, data, opt)
            .then((res) => {
                return {
                    data: res.data,
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: error.response.data.msg,
                    status: error.response.data.code,
                };
            });
        return result;
    },
    removeDuplicate: async (data) => {
        const result = await axios
            .post(url + "/catalogs/remove-dups", data, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                return {
                    data: res.data,
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: error.response.data.msg,
                    status: error.response.data.code,
                };
            });
        return result;
    },
    getInventoryConnectorCredentialsById: async (credentialsId) => {
        const result = await axios
            .get(
                url +
                    "/catalogs/get-connector-inv-credentials/" +
                    credentialsId,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "token"
                        )}`,
                    },
                }
            )
            .then((res) => {
                let result = {
                    data: res.data?.data,
                    credentials: res.data?.credentials,
                    status: 200,
                };
                return result;
            })
            .catch((error) => {
                return {
                    message: error.response.data.msg,
                    status: error.response.data.code,
                };
            });
        return result;
    },
    updateConnectorInventoryCredentials: async (
        credentialsId,
        credentials,
        updateInventoryDaily,
        catalogId
    ) => {
        const bodyFormData = new FormData();
        bodyFormData.append("credentials", credentials);
        bodyFormData.append("is_scheduled", updateInventoryDaily);
        bodyFormData.append("catalog_id", catalogId);

        const result = await axios
            .patch(
                url + "/catalogs/credentials/" + credentialsId,
                bodyFormData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${localStorage.getItem(
                            "token"
                        )}`,
                    },
                }
            )
            .then((res) => {
                return {
                    data: res.data,
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: error.response.data.msg,
                    status: error.response.data.code,
                };
            });
        return result;
    },
};

export default CatalogService;
