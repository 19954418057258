import {
    GET_CONNECTORS_SUCCESS,
    GET_CONNECTORS_LISTING,
    GET_CONNECTORS_FAIL,
    GET_CRIDENTIALS_SUCCESS,
    GET_CRIDENTIALS_LISTING,
    GET_CRIDENTIALS_FAIL,
    GET_CRIDENTIALS_BY_ID_LISTING,
    GET_CRIDENTIALS_BY_ID_SUCCESS,
    GET_CRIDENTIALS_BY_ID_FAIL,
    GET_TRAVELGATE_CRIDENTIALS_LISTING,
    GET_TRAVELGATE_CRIDENTIALS_SUCCESS,
    GET_TRAVELGATE_CRIDENTIALS_FAIL,
} from "../types";
import axios from "axios";

const connectorsUrl = process.env.REACT_APP_BASE_URL + "/connectors/";
const credentialsUrl = connectorsUrl + "credentials";

export const getConnectors = () => async (dispatch) => {
    dispatch({
        type: GET_CONNECTORS_LISTING,
    });
    try {
        const res = await axios.get(connectorsUrl, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        dispatch({
            type: GET_CONNECTORS_SUCCESS,
            payload: res.data?.data ?? [],
        });
    } catch (e) {
        dispatch({
            type: GET_CONNECTORS_FAIL,
            payload: console.log("Error in actions ", e),
        });
    }
};

export const getCredentials = () => async (dispatch) => {
    dispatch({
        type: GET_CRIDENTIALS_LISTING,
    });
    try {
        const res = await axios.get(credentialsUrl, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        dispatch({
            type: GET_CRIDENTIALS_SUCCESS,
            payload: res.data?.data ?? [],
        });
    } catch (e) {
        dispatch({
            type: GET_CRIDENTIALS_FAIL,
            payload: console.log("Error in actions ", e),
        });
    }
};

export const getCredentialsById = (credentialsId) => async (dispatch) => {
    dispatch({
        type: GET_CRIDENTIALS_BY_ID_LISTING,
    });
    try {
        const res = await axios.get(credentialsUrl + "/" + credentialsId, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        dispatch({
            type: GET_CRIDENTIALS_BY_ID_SUCCESS,
            payload: res.data ?? {},
        });
    } catch (e) {
        dispatch({
            type: GET_CRIDENTIALS_BY_ID_FAIL,
            payload: console.log("Error in actions ", e),
        });
    }
};

export const getTravelgateCredentials = () => async (dispatch) => {
    dispatch({
        type: GET_TRAVELGATE_CRIDENTIALS_LISTING,
    });
    try {
        const res = await axios.get(connectorsUrl + "travelgate-credentials", {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        dispatch({
            type: GET_TRAVELGATE_CRIDENTIALS_SUCCESS,
            payload: res?.data ?? {},
        });
    } catch (e) {
        dispatch({
            type: GET_TRAVELGATE_CRIDENTIALS_FAIL,
            payload: console.log("Error in actions ", e),
        });
    }
};
