import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import HotelMappingList from "./HotelMappingList";
import { getHotelDetails } from "../../redux/actions/ExploreMappingActions";

function HotelDetails() {
    const history = useHistory();
    const { hotelDetails, loadingDetails } = useSelector((state) => ({
        hotelDetails: state?.exploreMappingReducer?.hotelDetails,
        loadingDetails: state?.exploreMappingReducer?.loadingDetails,
    }));
    const { id } = useParams();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getHotelDetails(id));
    }, [id]);

    return (
        <div className="page-wrapper" style={{ display: "block" }}>
            <div className="page-breadcrumb">
                <div className="row">
                    <div className="col-7 align-self-center">
                        <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
                            <button
                                className="btn"
                                onClick={() => {
                                    history.push("/explore-mapping");
                                }}
                            >
                                <i className="fas fa-arrow-left"></i>
                            </button>
                            Explore mapping
                        </h3>
                    </div>
                </div>
            </div>
            {loadingDetails ? (
                <div className="text-center">
                    <div className="lds-ripple">
                        <div className="lds-pos"></div>
                        <div className="lds-pos"></div>
                    </div>
                </div>
            ) : (
                <div className="container-fluid">
                    <div
                        className="catalogBloc"
                        style={{
                            height: "auto",
                            border: "none",
                            padding: "0px",
                        }}
                    >
                        <div className="row">
                            <div
                                className="statsCatalog"
                                style={{ width: "98%" }}
                            >
                                <div className="statsRow">
                                    <div className="col-2 d-flex flex-column">
                                        <span className="title">
                                            <img
                                                src="../../assets/images/hotel-icon.png"
                                                alt="wrapkit"
                                                style={{
                                                    width: "17px",
                                                    marginBottom: "4px",
                                                }}
                                            />
                                            &nbsp; Inventory Hotel code
                                        </span>
                                        <span className="number">
                                            {hotelDetails?.hotel?.hotel_code}
                                        </span>
                                    </div>
                                    <div className="col-3 d-flex flex-column bordered">
                                        <span className="title">
                                            <img
                                                src="../../assets/images/hotel-icon.png"
                                                alt="wrapkit"
                                                style={{
                                                    width: "17px",
                                                    marginBottom: "4px",
                                                }}
                                            />
                                            &nbsp;Hotel name
                                        </span>
                                        <span className="number">
                                            {hotelDetails?.hotel?.hotel_name ??
                                                ""}
                                        </span>
                                    </div>
                                    <div className="col-4 d-flex flex-column bordered">
                                        <span className="title">
                                            <img
                                                src="../../assets/images/location.png"
                                                alt="wrapkit"
                                                style={{
                                                    width: "17px",
                                                    marginBottom: "4px",
                                                }}
                                            />
                                            &nbsp;Address
                                        </span>
                                        <span className="number">
                                            {hotelDetails?.hotel?.address ?? ""}
                                        </span>
                                    </div>
                                    <div className="col-2 d-flex flex-column bordered">
                                        <span className="title">
                                            <img
                                                src="../../assets/images/country.png"
                                                alt="wrapkit"
                                                style={{
                                                    width: "17px",
                                                    marginBottom: "4px",
                                                }}
                                            />
                                            &nbsp;Country
                                        </span>
                                        <span className="number">
                                            {hotelDetails?.hotel?.country ?? ""}
                                        </span>
                                    </div>
                                    <div className="col-3 d-flex flex-column bordered">
                                        <span className="title">
                                            <img
                                                src="../../assets/images/city.png"
                                                alt="wrapkit"
                                                style={{
                                                    width: "17px",
                                                    marginBottom: "4px",
                                                }}
                                            />
                                            &nbsp;City
                                        </span>
                                        <span className="number">
                                            {hotelDetails?.hotel?.city ?? ""}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {hotelDetails?.mapping?.length === 0 ||
                    hotelDetails?.mapping === null ? (
                        <div className="card mt-5">
                            <div className="empty text-center">
                                <i className="zoom-icon"></i>
                                <p>0 Mapping hotels</p>
                            </div>
                        </div>
                    ) : (
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div
                                            style={{
                                                paddingTop: "5px ",
                                            }}
                                        >
                                            <HotelMappingList
                                                data={hotelDetails?.mapping}
                                                hotel={hotelDetails?.hotel}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
export default HotelDetails;
