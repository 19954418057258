import React, { useState } from "react";
import PartnerService from "../../services/PartnerService";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import fileDownload from "js-file-download";
import { MenuItem, ControlledMenu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import { DELETE_MAPPING, EXPORT, MAP } from "../../utilis/const";
import { hasPermission } from "../../utilis/functions";

function DownloadCatalog({
    dataCell,
    setOpenMappingDestinationDialog,
    setCurrentElement,
    id,
    partner,
    setShowShareReport,
    setShowRerunMapping,
    status,
    affiliateId,
    hide,
    setIdToRemove,
    setShowConfirmRemoveModal,
    setPartnerIdToRemove,
    partnersPage,
    partnerId,
}) {
    const [pending, setPending] = useState(false);
    const [isOpen, setOpen] = useState();
    const ref = React.useRef(null);

    const handleDowload = () => async () => {
        setPending(true);
        try {
            let res = await PartnerService.dowloadPartnerCatalog(id);
            if (res.status === 200) {
                fileDownload(res.data, res?.fileName);
                setPending(false);
                window.analytics.track("Download Mapping", {
                    userID: localStorage.getItem("userId"),
                    companyID: localStorage.getItem("companyId"),
                    companyName: localStorage.getItem("companyName"),
                    firstName: localStorage.getItem("firstName"),
                    lastName: localStorage.getItem("lastName"),
                    email: localStorage.getItem("email"),
                    catalogID: dataCell?.id,
                    partnerName: dataCell?.partner_name,
                    partnerID: dataCell?.partner_id,
                    catalogName: dataCell?.name,
                    mapped: dataCell?.mapped_hotels,
                    opportunities: dataCell?.unmapped_hotels,
                    possible: dataCell?.to_review_hotels,
                });
            } else {
                toast.error("Something went wrong while downloading this file");
                setPending(false);
            }
        } catch (err) {
            toast.error("Something went wrong while downloading this file");
            setPending(false);
        }
    };
    return (
        <>
            <div
                ref={ref}
                className="btn"
                onClick={() => setOpen(true)}
                style={{ cursor: "pointer" }}
            >
                <i className="fas fa-ellipsis-v"></i>{" "}
            </div>
            <ControlledMenu
                state={isOpen ? "open" : pending ? "open" : "closed"}
                anchorRef={ref}
                onClose={() => setOpen(false)}
            >
                {status === 2 && (
                    <>
                        <MenuItem onClick={handleDowload()}>
                            Download Zip
                            {pending && (
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            )}
                        </MenuItem>
                        {hasPermission(EXPORT) && (
                            <MenuItem
                                onClick={() => {
                                    setOpenMappingDestinationDialog(true);
                                    setCurrentElement({
                                        ...dataCell,
                                        id: dataCell?.partner_catalog_id,
                                    });
                                }}
                            >
                                Export Data
                            </MenuItem>
                        )}
                    </>
                )}
                {hasPermission(MAP) && affiliateId === 0 && !hide && (
                    <MenuItem
                        onClick={() => {
                            setShowRerunMapping(true);
                            setCurrentElement(dataCell);
                        }}
                    >
                        Run Mapping
                    </MenuItem>
                )}
                {hasPermission(DELETE_MAPPING) && (
                    <MenuItem
                        onClick={() => {
                            setIdToRemove(id);
                            if (partnersPage) {
                                setPartnerIdToRemove(partnerId);
                            }
                            setShowConfirmRemoveModal(true);
                        }}
                    >
                        Delete
                    </MenuItem>
                )}
            </ControlledMenu>
        </>
    );
}
export default DownloadCatalog;
