import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { getMappingManualHistory } from "../../../redux/actions/ManualMappingActions";
const columns = [
    {
        name: "",
    },
    {
        name: "Hotel Code",
    },
    {
        name: "Hotel Name",
    },
    {
        name: "Address",
    },
    {
        name: "City",
    },
    {
        name: "Country",
    },
];
const ProccessedHotelsList = (props) => {
    const { partner_id,token } = props;
    const dispatch = useDispatch();

    const [filter, setFilter] = useState("");
    const [currentPage, setCurrentPage] = React.useState(0);

    const { hotelsHistory, loadingHotelsHistory, totalCount } = useSelector(
        (state) => ({
            hotelsHistory:
                state.manualMappingReducer?.hotelsHistory?.data?.hotels ?? [],
            loadingHotelsHistory:
                state.manualMappingReducer?.loadingHotelsHistory ?? false,
            totalCount: state.manualMappingReducer?.hotelsHistory?.data?.total
                ? Math.ceil(
                      state.manualMappingReducer?.hotelsHistory?.data?.total /
                          10
                  )
                : 0,
        })
    );
    const handlePageClick = (event) => {
        setCurrentPage(event?.selected);
        dispatch(
            getMappingManualHistory(partner_id, event?.selected, 10, filter,token)
        );
    };
    useEffect(() => {
        dispatch(getMappingManualHistory(partner_id, currentPage, 10, filter,token));
    }, []);

    const onFilter = () => {
        setCurrentPage(0);
        dispatch(getMappingManualHistory(partner_id, 0, 10, filter,token));
    };

    const handleClearFilter = () => {
        setFilter("");
        setCurrentPage(0);
        dispatch(getMappingManualHistory(partner_id, 0, 10, "",token));
    };
    const onChange = (e) => {
        setFilter(e.target.value);
        if (e.target.value === "") {
            setCurrentPage(0);
            dispatch(getMappingManualHistory(partner_id, 0, 10, "",token));
        }
    };
    return (
        <>
            <div
                className="row"
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "15px",
                }}
            >
                <div className="col-lg-4 col-md-4">
                    <div
                        className="card-partner-details"
                        style={{
                            marginBottom: "17px",
                            padding: "8px",
                            borderRadius: "8px",
                        }}
                    >
                        <div className="col-12">
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div>
                                    <span
                                        className="dot"
                                        style={{
                                            backgroundColor: "#5f76e8",
                                        }}
                                    ></span>
                                    <span
                                        style={{
                                            fontSize: "small",
                                        }}
                                    >
                                        &nbsp;Inventory hotels&nbsp;
                                    </span>
                                </div>
                                <div>
                                    <span
                                        className="dot"
                                        style={{
                                            backgroundColor: "#E8D15F",
                                        }}
                                    ></span>
                                    <span
                                        style={{
                                            fontSize: "small",
                                        }}
                                    >
                                        &nbsp; Partner hotels &nbsp;
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FilterComponent
                onFilter={onFilter}
                onClear={handleClearFilter}
                filterText={filter}
                onChange={onChange}
            />
            {loadingHotelsHistory ? (
                <>
                    <div
                        style={{
                            fontSize: "24px",
                            fontWeight: "700",
                            padding: "24px",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        Loading...
                    </div>
                </>
            ) : hotelsHistory?.length > 0 ? (
                <>
                    <div className="table-responsive mt-4">
                        <table className="table table-borderless text-nowrap align-middle mb-0">
                            <thead>
                                <tr>
                                    {columns?.map(function (column) {
                                        return (
                                            <th
                                                style={{
                                                    color: "rgba(0,0,0,0.87)",
                                                    fontSize: "16px",
                                                    fontWeight: "500",
                                                }}
                                            >
                                                {column.name}
                                            </th>
                                        );
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {hotelsHistory?.map((e, index) => {
                                    return <CollapseRow e={e} />;
                                })}
                            </tbody>
                        </table>
                        <div className="col-lg-12 col-md-12">
                            {hotelsHistory?.length > 0 && (
                                <div
                                    className="row"
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "center",
                                        paddingTop: "20px",
                                    }}
                                >
                                    <ReactPaginate
                                        className="react-pagination-div"
                                        breakClassName={"page-item"}
                                        breakLinkClassName={"page-link"}
                                        containerClassName={"pagination"}
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        previousClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        activeClassName={"active"}
                                        breakLabel="..."
                                        nextLabel=" >"
                                        onPageChange={handlePageClick}
                                        pageRangeDisplayed={5}
                                        pageCount={totalCount}
                                        // initialPage={
                                        //     currentPage
                                        // }
                                        forcePage={currentPage}
                                        previousLabel="<"
                                        renderOnZeroPageCount={null}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </>
            ) : (
                <div className="empty text-center">
                    <p>This attribute was not found</p>
                </div>
            )}
        </>
    );
};
export default ProccessedHotelsList;

const CollapseRow = ({ e }) => {
    const [showDetails, setShowDetails] = useState(false);
    return (
        <>
            <tr
                style={{
                    borderBottom: showDetails ? "1px solid #6c757d5c" : "0px",
                    backgroundColor: "#5f76e82e",
                    cursor: "pointer",
                }}
                onClick={() => {
                    setShowDetails(!showDetails);
                }}
            >
                <td
                    className="bg-transparent"
                    style={{
                        borderTopLeftRadius: "8px",
                        borderBottomLeftRadius: showDetails ? "0px" : "8px",
                        width: "7px",
                    }}
                >
                    <button
                        className="btn"
                        style={{
                            paddingLeft: "0px",
                            paddingRight: "0px",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                        }}
                        onClick={() => {
                            setShowDetails(!showDetails);
                        }}
                    >
                        {showDetails ? (
                            <i className="fas fa-chevron-down"></i>
                        ) : (
                            <i className="fas fa-chevron-right"></i>
                        )}
                    </button>
                </td>
                <td className="bg-transparent">
                    <div className="d-flex align-items-center gap-3">
                        {/* <span
                        className="dot"
                        style={{
                            backgroundColor:
                                "#5f76e8",
                            marginRight:
                                "5px",
                        }}
                    ></span> */}
                        <span>{e?.inventory_hotel_code}</span>
                    </div>
                </td>
                <td className="bg-transparent">{e?.inventory_hotel_name}</td>
                <td className="bg-transparent">{e?.inventory_address}</td>
                <td className="bg-transparent">{e?.inventory_city}</td>
                <td
                    className=" bg-transparent"
                    style={{
                        borderTopRightRadius: "8px",
                        borderBottomRightRadius: showDetails ? "0px" : "8px",
                    }}
                >
                    {e?.inventory_country}
                </td>
            </tr>
            {showDetails && (
                <tr
                    style={{
                        backgroundColor: "rgb(232 209 95 / 26%)",
                    }}
                >
                    <td
                        className="text-end rounded-end bg-transparent"
                        style={{
                            borderBottomLeftRadius: "8px",
                        }}
                    ></td>
                    <td className="rounded-start bg-transparent">
                        {/* <span
                    className="dot"
                    style={{
                        backgroundColor:
                            "#E8D15F",
                    }}
                ></span>{" "} */}
                        <span>{e?.catalog_hotel_code}</span>
                    </td>
                    <td className="bg-transparent">{e?.catalog_hotel_name}</td>
                    <td className="bg-transparent">{e?.catalog_address}</td>
                    <td className="bg-transparent">{e?.catalog_city}</td>
                    <td
                        className="bg-transparent"
                        style={{
                            borderBottomRightRadius: "8px",
                        }}
                    >
                        {e?.catalog_country}
                    </td>
                </tr>
            )}
            <tr>
                <td
                    colspan="6"
                    style={{
                        padding: "0.25rem",
                    }}
                ></td>
            </tr>
        </>
    );
};

const FilterComponent = (props) => {
    const { onFilter, onClear, filterText, onChange } = props;
    return (
        <div
            className="input-group"
            style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
            }}
        >
            <div id="search-autocomplete" className="form-outline">
                <input
                    type="text"
                    id="form1"
                    className="form-control"
                    placeholder="Search"
                    value={filterText}
                    onChange={onChange}
                    style={{ borderRadius: "5px 0px 0px 5px", height: "41px" }}
                />
            </div>
            <button
                type="button"
                className="btn btn-primary"
                onClick={onFilter}
                style={{
                    paddingLeft: "15px",
                    paddingRight: "15px",
                    borderRadius: "0px",
                }}
            >
                <i className="fas fa-search"></i>
            </button>
            <button
                type="button"
                className="btn btn-warning"
                onClick={onClear}
                style={{ borderRadius: "0px 5px 5px 0px" }}
            >
                <img
                    src="../../assets/images/reset.png"
                    alt="wrapkit"
                    style={{
                        width: " 20px",
                        marginBottom: "3px",
                        marginRight: "3px",
                    }}
                />
            </button>
        </div>
    );
};
