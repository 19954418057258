/* eslint-disable import/no-anonymous-default-export */
import {
    VERIFY_EMAIL_CODE_LISTING,
    VERIFY_EMAIL_CODE_SUCCESS,
    VERIFY_EMAIL_CODE_FAIL,
    VERIFY_EMAIL_SUCCESS, 
    VERIFY_EMAIL_LISTING,
    VERIFY_EMAIL_FAIL 
} from '../types';

const initialState = {
    loadingCode:false,
    loadingEmail:false
}

export default function(state = initialState, action){
    switch(action.type){
        case VERIFY_EMAIL_CODE_LISTING:
            return {
                ...state,
                loadingCode:true
            }   
        case VERIFY_EMAIL_CODE_SUCCESS:
            return {
                ...state,
                dataEmail:action.payload,
                loadingCode:false
            }   
        case VERIFY_EMAIL_CODE_FAIL:
            return {
                ...state,
                loadingCode:false
            }        
        case VERIFY_EMAIL_LISTING:
            return {
                ...state,
                loadingEmail:true
            }   
        case VERIFY_EMAIL_SUCCESS:
            return {
                ...state,
                dataCode:action.payload,
                loadingEmail:false
            }   
        case VERIFY_EMAIL_FAIL:
            return {
                ...state,
                loadingEmail:false,
                error :action.payload,
            }
        default: return state
    }

}