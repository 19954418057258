import React from "react";
import Table from "../../components/Table/TableWithoutHeaders";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

function SearchHotelResult(props) {
    const { showResults } = props;
    const history = useHistory();
    const { data, loadingHotels } = useSelector((state) => ({
        data: state.exploreMappingReducer.hotelsList ?? [],
        loadingHotels: state?.exploreMappingReducer?.loadingHotels,
        hotelDetails: state?.exploreMappingReducer?.hotelDetails,
    }));

    const columns = [
        {
            name: <div className="table-title">Inventory Hotel Code</div>,
            cell: (e) => {
                return <>{e?.hotel_code}</>;
            },
            selector: (e) => e?.hotel_code,
            sortable: true,
        },
        {
            name: <div className="table-title">Hotel Name</div>,
            cell: (e) => {
                return <>{e?.hotel_name}</>;
            },
            selector: (e) => e?.hotel_name,
            sortable: true,
        },
        {
            name: <div className="table-title">Address</div>,
            cell: (e) => {
                return <>{e?.address}</>;
            },
            selector: (e) => e?.address,
            sortable: true,
        },
        {
            name: <div className="table-title">City</div>,
            cell: (e) => {
                return <>{e?.city}</>;
            },
            selector: (e) => e?.city,
            sortable: true,
        },
        {
            name: <div className="table-title">Country</div>,
            cell: (e) => {
                return <>{e?.country}</>;
            },
            selector: (e) => e?.country,
            sortable: true,
        },
        {
            name: <div className="table-title">Mapping count</div>,
            cell: (e) => {
                return <>{e?.mapping_count}</>;
            },
            selector: (e) => e?.mapping_count,
            sortable: true,
        },
        {
            name: <div className="table-title">More details</div>,
            cell: (e) => {
                return (
                    <button
                        className="btn btn-rounded "
                        onClick={async () => {
                            // dispatch(getHotelDetails(e?.hotel_code));
                            history.push(
                                `/explore-mapping/details/${e?.hotel_code}`
                            );
                        }}
                    >
                        <i className="fas fa-plus"></i>
                    </button>
                );
            },
        },
    ];
    return (
        <>
            {loadingHotels ? (
                <div className="text-center">
                    <div className="lds-ripple">
                        <div className="lds-pos"></div>
                        <div className="lds-pos"></div>
                    </div>
                </div>
            ) : showResults && data?.length === 0 ? (
                <div className="card mt-5">
                    <div className="empty text-center">
                        <i className="zoom-icon"></i>
                        <p>
                            Sorry, we couldn&apos;t find any hotels for your
                            search criteria. Please check your information and
                            try again.
                        </p>
                    </div>
                </div>
            ) : data?.length > 0 ? (
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div
                                className="card-body"
                                style={{ padding: "20px" }}
                            >
                                <div
                                    className="container-fluid"
                                    style={{
                                        paddingLeft: "0px",
                                        paddingRight: "0px",
                                    }}
                                >
                                    <div
                                        style={{
                                            justifyContent: "flex-end",
                                            marginBottom: "10px",
                                            display: "flex",
                                        }}
                                    >
                                        <span className="font-weight-medium">
                                            <span
                                                text="white"
                                                className={`badge rounded-pill text-white bg-success`}
                                                style={{ fontSize: "15px" }}
                                            >
                                                {data?.length ?? 0} Hotels
                                                founds
                                            </span>
                                        </span>
                                    </div>
                                    <Table
                                        data={data}
                                        columns={columns}
                                        // onRowClicked={onRowClicked}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
}
export default SearchHotelResult;
