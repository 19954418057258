import React, { useState } from "react";
import { Spinner, Tooltip, OverlayTrigger } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getCredentialsById } from "../../redux/actions/ConnectorActions";
import ConnectorService from "../../services/ConnectorService";

function TableActions(props) {
    const {
        element,
        setShowConfirmModal,
        setCurrentElement,
        setShowSchudlerModal,
        setShowStopSchedulerModal,
        setShowRunMappingModal,
        setShowEditModal,
        setConnectorForm,
        setConnectorUiSchema,
        setDataCredential,
    } = props;
    const [loading, setLoading] = useState(false);
    const { loadingCridentialsById, connectors } = useSelector((state) => ({
        loadingCridentialsById: state?.connectorReducer?.loadingCridentialsById,
        connectors: state?.connectorReducer?.listConnectors ?? [],
    }));
    const handleRemove = () => {
        setShowConfirmModal(true);
        setCurrentElement(element);
    };

    const renderTooltip = (props = (text) => (
        <Tooltip id="button-tooltip" {...props}>
            {text}
        </Tooltip>
    ));

    const handleSchudler = () => {
        setCurrentElement(element);
        setShowSchudlerModal(true);
    };

    const handleStopSChudeler = () => {
        setCurrentElement(element);
        setShowStopSchedulerModal(true);
    };
    const handleRunMapping = () => {
        setCurrentElement(element);
        setShowRunMappingModal(true);
    };
    const handleEdit = async () => {
        setCurrentElement(element);
        const res = await ConnectorService.getCredentialsById(element?.id);
        if (res.status === 200) {
            let connector = await connectors?.find(
                (e) => e?.id === element?.connector_id
            );
            const parsedData = await testt(connector?.form, res.credentials);
            setConnectorForm(parsedData);
            setDataCredential(res.data);
            setConnectorUiSchema(connector?.ui_form_schema);
            setShowEditModal(true);
        }
    };

    const testt = async (form, dd) => {
        var parsedData = form ? JSON.parse(form) : {};
        var keys = Object.keys(parsedData["properties"]);
        keys?.forEach((e) => {
            const tt = Object.keys(parsedData["properties"])?.find(
                (d) => d === e
            );
            parsedData["properties"][e] = {
                ...parsedData["properties"][e],
                default: dd?.[tt],
            };
        });
        return parsedData;
    };
    return (
        <div className="btn-group" role="group">
            <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip("Run mapping")}
            >
                <button
                    type="button"
                    className="btn"
                    onClick={handleRunMapping}
                    disabled={loading}
                >
                    <i
                        className="far fa-play-circle"
                        style={{ color: "#21CA7F", fontSize: "20px" }}
                    ></i>
                    {loading && (
                        <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    )}
                </button>
            </OverlayTrigger>
            <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip("Scheduler")}
            >
                <button type="button" className="btn" onClick={handleSchudler}>
                    <i
                        className="fas fa-calendar"
                        style={{ color: "#6076e8", fontSize: "20px" }}
                    ></i>{" "}
                </button>
            </OverlayTrigger>
            {element?.crontab !== "" && (
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip("Stop scheduler")}
                >
                    <button
                        type="button"
                        className="btn"
                        onClick={handleStopSChudeler}
                    >
                        <i
                            className="far fa-stop-circle"
                            style={{ color: "#FF2851", fontSize: "20px" }}
                        ></i>
                    </button>
                </OverlayTrigger>
            )}
            <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip("Remove")}
            >
                <button type="button" className="btn" onClick={handleRemove}>
                    <i
                        className="far fa-times-circle"
                        style={{ color: "#FF2851", fontSize: "20px" }}
                    ></i>
                </button>
            </OverlayTrigger>
            {!element.public && (
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip("Edit")}
                >
                    <button type="button" className="btn" onClick={handleEdit}>
                        {loadingCridentialsById ? (
                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        ) : (
                            <i
                                className="far fa-edit"
                                style={{ color: "#FCB144", fontSize: "20px" }}
                            ></i>
                        )}
                    </button>
                </OverlayTrigger>
            )}
        </div>
    );
}
export default TableActions;
