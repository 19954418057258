import axios from "axios";
const url = process.env.REACT_APP_BASE_URL;

const ExploreMappingService = {
    reportMappingError: async (payload) => {
        const result = await axios
            .post(url + `/partner-catalogs/report-mapping-error`, payload, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                return {
                    data: res.data,
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: error.response.data.msg,
                    status: error.response.data.code,
                };
            });
        return result;
    },
    searchHotelName: async (name) => {
        const result = await axios
            .get(url + `/hotels/autocomplete`, {
                params: {
                    q: name,
                },
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                return {
                    data: res.data?.hotels,
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: error.response.data.message,
                    status: error.response.data.code,
                };
            });
        return result;
    },
};
export default ExploreMappingService;
