import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, Form, Card, Row, Col } from "react-bootstrap";
import Avatar from "react-avatar";
import { MenuItem, ControlledMenu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import moment from "moment";
import ConfirmAction from "../../components/ConfirmActionsModal";

import { Helmet } from "react-helmet";
import {
    getTeamData,
    updateTeamData,
    deleteTeamData,
    addTeamData,
} from "../../redux/actions/ManageTeamActions";
import {
    ROLE_ADMIN,
    ROLE_OWNER,
    ROLE_VIEWER,
    ROLE_OPERATOR,
} from "../../utilis/const";

const emailSchema = yup.object().shape({
    email: yup
        .string()
        .email("Must be a valid email")
        .required("Email is required"),
});

function ManageTeamScreen() {
    const role = localStorage.getItem("role");
    let [inviteModalOpen, setInviteModalOpen] = useState(false);

    const openInviteModal = () => {
        setInviteModalOpen(true);
    };
    const dispatch = useDispatch();
    const sendInvitation = async (data) => {
        await dispatch(
            addTeamData({
                email: data.email,
                role: data?.is_admin
                    ? ROLE_ADMIN
                    : data?.is_operator
                    ? ROLE_OPERATOR
                    : data?.is_viewer
                    ? ROLE_VIEWER
                    : "",
            })
        );
        dispatch(getTeamData());
        setInviteModalOpen(false);
    };

    return (
        <>
            <Helmet>
                <title>Manage Team</title>
            </Helmet>
            <div className="page-wrapper" style={{ display: "block" }}>
                <div className="page-breadcrumb">
                    <div className="row">
                        <div className="col-7 align-self-center">
                            <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
                                Manage Team
                            </h3>
                        </div>
                        <div className="col-12">
                            <p className="card-text">
                                <span className="sub-title-info ">
                                    <i className="fa  fa-info-circle"></i>
                                    &nbsp; Add, remove, and edit roles for
                                    people on your team.
                                </span>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="d-flex align-items-center">
                        {(role === ROLE_ADMIN || role === ROLE_OWNER) && (
                            <div className="ml-auto">
                                <div className="">
                                    <button
                                        className="btn btn-info"
                                        onClick={openInviteModal}
                                    >
                                        Invite someone to your team
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="col-12" style={{ padding: "0px" }}>
                        <hr />
                    </div>
                    <UsersList role={role} />
                </div>

                {inviteModalOpen && (
                    <InviteSomeoneModal
                        open={inviteModalOpen}
                        onClose={() => setInviteModalOpen(false)}
                        sendInvitation={sendInvitation}
                    />
                )}
            </div>
        </>
    );
}

function UsersList(props) {
    const dispatch = useDispatch();
    let [editModalOpen, setEditModalOpen] = useState(false);
    let [currentUser, setCurrentUser] = useState({});
    const [removeUserConfirmation, setRemoveUserConfirmation] = useState(false);

    const openEditModal = (user) => () => {
        setCurrentUser(user);
        setEditModalOpen(true);
    };

    const editUser = async (user) => {
        await dispatch(
            updateTeamData({
                id: user?.id,
                role: user?.is_admin
                    ? ROLE_ADMIN
                    : user?.is_operator
                    ? ROLE_OPERATOR
                    : user?.is_viewer
                    ? ROLE_VIEWER
                    : "",
                email: user?.email,
            })
        );
        dispatch(getTeamData());
        setEditModalOpen(false);
    };

    const data = useSelector((state) => {
        return {
            users: state.manageTeamReducer?.users ?? [],
            loading: state.manageTeamReducer?.loading,
            updating: state.manageTeamReducer?.updating,
        };
    });

    useEffect(() => {
        dispatch(getTeamData());
    }, []);

    const handleOpenRemoveDialog = (user) => () => {
        setCurrentUser(user);
        setRemoveUserConfirmation(true);
    };

    const removeUser = () => async () => {
        await dispatch(deleteTeamData(currentUser.id));
        await dispatch(getTeamData());
        setRemoveUserConfirmation(false);
    };

    return (
        <>
            <div className="row">
                {data?.loading ? (
                    <div className="text-center">
                        <div className="lds-ripple">
                            <div className="lds-pos"></div>
                            <div className="lds-pos"></div>
                        </div>
                    </div>
                ) : (
                    <>
                        {data.users.map((e) => (
                            <>
                                <div className="col-6 col-md-4 col-lg-4">
                                    <Card
                                        className="card-without-hover"
                                        onClick={() => {}}
                                    >
                                        <Row className="no-gutters">
                                            <Col md={2} lg={2}>
                                                <Avatar
                                                    name={
                                                        e?.first_name === "" &&
                                                        e?.last_name === ""
                                                            ? "User"
                                                            : `${
                                                                  e?.first_name ??
                                                                  "User"
                                                              } ${
                                                                  e?.last_name ??
                                                                  ""
                                                              }`
                                                    }
                                                    round={true}
                                                    size={60}
                                                    color="#222"
                                                    style={{
                                                        marginLeft: "17px",
                                                        marginTop: "30px",
                                                    }}
                                                />
                                            </Col>
                                            <Col>
                                                <Card.Body
                                                    style={{
                                                        padding:
                                                            "25px 10px 25px 25px",
                                                    }}
                                                >
                                                    <Card.Title
                                                        style={{
                                                            display: "flex",

                                                            justifyContent:
                                                                "space-between",
                                                            marginBottom: "0px",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                color: "#444",
                                                            }}
                                                        >
                                                            {e?.first_name ===
                                                                "" &&
                                                            e?.last_name ===
                                                                "" &&
                                                            !e?.verified_email ? (
                                                                <span
                                                                    className={`badge rounded-pill text-black bg-warning`}
                                                                    style={{
                                                                        color: "white",
                                                                        fontWeight:
                                                                            "600",
                                                                    }}
                                                                >
                                                                    Invite
                                                                    Pending
                                                                </span>
                                                            ) : (
                                                                `${
                                                                    e?.first_name ??
                                                                    "User"
                                                                } ${
                                                                    e?.last_name ??
                                                                    ""
                                                                }`
                                                            )}
                                                            <span
                                                                className={`badge rounded-pill text-black `}
                                                                style={{
                                                                    backgroundColor:
                                                                        "#f9fbfd",
                                                                    border: "1px solid #7c8798",
                                                                    fontWeight:
                                                                        "600",
                                                                    marginLeft:
                                                                        "7px",
                                                                }}
                                                            >
                                                                {e?.role}
                                                            </span>
                                                            <div
                                                                style={{
                                                                    color: "rgb(128, 134, 152)",
                                                                    font: '500 1rem / 16px Roboto, "Helvetica Neue", sans-serif',
                                                                    letterSpacing:
                                                                        "0.3px",
                                                                    fontWeight:
                                                                        "500",
                                                                    fontSize:
                                                                        "1rem",
                                                                    paddingTop:
                                                                        "5px",
                                                                }}
                                                            >
                                                                {e?.email}
                                                            </div>
                                                            {e?.verified_email ? (
                                                                <div
                                                                    style={{
                                                                        color: "rgb(128, 134, 152)",
                                                                        font: '500 1rem / 16px Roboto, "Helvetica Neue", sans-serif',
                                                                        letterSpacing:
                                                                            "0.3px",
                                                                        fontWeight:
                                                                            "500",
                                                                        fontSize:
                                                                            "1rem",
                                                                        paddingTop:
                                                                            "5px",
                                                                    }}
                                                                >
                                                                    Last active
                                                                    :&nbsp;
                                                                    {moment(
                                                                        new Date(
                                                                            e?.last_login
                                                                        )
                                                                    ).format(
                                                                        "DD/MM/YYYY"
                                                                    )}
                                                                </div>
                                                            ) : (
                                                                <div
                                                                    style={{
                                                                        color: "rgb(128, 134, 152)",

                                                                        paddingTop:
                                                                            "5px",
                                                                    }}
                                                                >
                                                                    &nbsp;
                                                                </div>
                                                            )}{" "}
                                                        </div>
                                                        {localStorage.getItem(
                                                            "role"
                                                        ) === ROLE_ADMIN ||
                                                        e?.role === "owner" ? (
                                                            <></>
                                                        ) : (
                                                            <div
                                                                onClick={(e) =>
                                                                    e.stopPropagation()
                                                                }
                                                            >
                                                                <ActionsMenu
                                                                    currentUser={
                                                                        e
                                                                    }
                                                                    handleEdit={
                                                                        openEditModal
                                                                    }
                                                                    handleRemove={
                                                                        handleOpenRemoveDialog
                                                                    }
                                                                    removeUser={
                                                                        removeUser
                                                                    }
                                                                    id={e?.id}
                                                                />
                                                            </div>
                                                        )}
                                                    </Card.Title>

                                                    {/* <Card.Text className="card-text-mapping-details"></Card.Text> */}
                                                </Card.Body>
                                            </Col>
                                        </Row>
                                    </Card>
                                </div>
                            </>
                        ))}
                    </>
                )}
            </div>
            {editModalOpen && (
                <UpdateUser
                    open={editModalOpen}
                    onClose={() => setEditModalOpen(false)}
                    currentUser={currentUser}
                    editUser={editUser}
                />
            )}
            {removeUserConfirmation && (
                <ConfirmAction
                    show={removeUserConfirmation}
                    onClose={() => setRemoveUserConfirmation(false)}
                    currentElement={currentUser}
                    titleMessage="Action required !"
                    subTitleMessage="Are you sure you want to delete this user?"
                    colorCode="danger"
                    handleClick={removeUser()}
                    buttonText="Confirm"
                />
            )}
        </>
    );
}

function Privileges({ register, currentUser, setValue }) {
    let [isAdminState, setIsAdminState] = useState(
        currentUser?.role === ROLE_ADMIN ? true : false
    );
    let [isOperatorState, setIsOperatorState] = useState(
        currentUser?.role === ROLE_OPERATOR ? true : false
    );
    let [isViewerState, setIsViewerState] = useState(
        currentUser?.role === ROLE_VIEWER ? true : false
    );

    const setAdmin = (val) => {
        setIsAdminState(val);
        setValue("is_admin", val);
    };
    const setOperator = (val) => {
        setIsOperatorState(val);
        setValue("is_operator", val);
    };
    const setViewer = (val) => {
        setIsViewerState(val);
        setValue("is_viewer", val);
    };

    return (
        <>
            <div>
                <label>
                    Choose what access you'd like this person to have:
                </label>
                <div
                    className={
                        (isAdminState ? "blocOptionActive" : "") +
                        " blocOption d-flex align-items-center mb-4"
                    }
                >
                    <div>
                        <h3>
                            <i className="fas fa-star"></i> Admin
                        </h3>
                        <div>
                            <input
                                id="admin_team_check"
                                type="checkbox"
                                {...register("is_admin")}
                                onChange={() => {
                                    setViewer(false);
                                    setOperator(false);
                                    setAdmin(true);
                                }}
                            />
                            <label className="ml-2" htmlFor="admin_team_check">
                                Access to all features as well as team and
                                account management
                            </label>
                        </div>
                    </div>
                </div>

                <div
                    className={
                        (isOperatorState ? "blocOptionActive" : "") +
                        " blocOption d-flex align-items-center mb-4"
                    }
                >
                    <div>
                        <h3>
                            <i className="fas fa-cogs"></i> Operator
                        </h3>
                        <div>
                            <input
                                id="dev_team_check"
                                type="checkbox"
                                {...register("is_operator")}
                                onChange={() => {
                                    setAdmin(false);
                                    setViewer(false);
                                    setOperator(true);
                                }}
                            />
                            <label className="ml-2" htmlFor="dev_team_check">
                                Access to all mapping features without access to
                                team and account management
                            </label>
                        </div>
                    </div>
                </div>

                <div
                    className={
                        (isViewerState ? "blocOptionActive" : "") +
                        " blocOption d-flex align-items-center mb-4"
                    }
                >
                    <div>
                        <h3>
                            <i className="fas fa-eye"></i> Viewer
                        </h3>
                        <div>
                            <input
                                id="finance_team_check"
                                type="checkbox"
                                {...register("is_viewer")}
                                onChange={() => {
                                    setAdmin(false);
                                    setOperator(false);
                                    setViewer(true);
                                }}
                            />
                            <label
                                htmlFor="finance_team_check"
                                className="ml-2"
                            >
                                View only access to all mapping related features{" "}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

function InviteSomeoneModal(props) {
    const { open, onClose, sendInvitation } = props;
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
    } = useForm({
        resolver: yupResolver(emailSchema),
    });

    return (
        <Modal
            show={open}
            onHide={onClose}
            dialogClassName="modal-50w modal-dialog modal-dialog-centered"
        >
            <div className="card mb-0">
                <Modal.Header>
                    <h4 className="modal-title" id="fullWidthModalLabel">
                        <i className="icon-user-follow"></i>
                        Invite someone new
                    </h4>
                </Modal.Header>
                <Modal.Body>
                    <div className="mt-3">
                        <div className="form-group">
                            <label htmlFor="email">Email address</label>
                            <input
                                {...register("email")}
                                type="email"
                                className="form-control"
                                placeholder="Email address"
                            />
                            {errors.email && (
                                <small className="text-danger form-text">
                                    {errors.email.message}
                                </small>
                            )}
                        </div>

                        {!getValues("is_admin") && !getValues("is_owner") && (
                            <Privileges
                                register={register}
                                setValue={setValue}
                            />
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        type="button"
                        className="btn btn-light"
                        onClick={onClose}
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleSubmit(sendInvitation)}
                    >
                        <i className="icon-paper-plane"></i>&nbsp;&nbsp; Send
                        invitation
                    </button>
                </Modal.Footer>
            </div>
        </Modal>
    );
}
function UpdateUser(props) {
    const { open, onClose, currentUser, editUser } = props;
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm({
        defaultValues:
            {
                ...currentUser,
                is_admin: currentUser?.role === ROLE_ADMIN ? true : false,
                is_operator: currentUser?.role === ROLE_OPERATOR ? true : false,
                is_viewer: currentUser?.role === ROLE_VIEWER ? true : false,
            } ?? {},
    });

    return (
        <Modal
            show={open}
            onHide={onClose}
            dialogClassName="modal-50w modal-dialog modal-dialog-centered"
        >
            <Form className="card mb-0">
                <Modal.Header>
                    <h4 className="modal-title" id="fullWidthModalLabel">
                        <i className="icon-user-follow"></i> Edit user
                    </h4>
                </Modal.Header>
                <Modal.Body>
                    <Privileges
                        register={register}
                        currentUser={currentUser}
                        setValue={setValue}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button variant="primary" onClick={handleSubmit(editUser)}>
                        <i className="icon-paper-plane"></i> Edit user
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}

function ActionsMenu({ handleEdit, handleRemove, currentUser, id }) {
    const [isOpen, setOpen] = useState();
    const ref = React.useRef(null);
    const role = localStorage.getItem("role");

    return (
        <>
            <div
                ref={ref}
                className="btn"
                onClick={() => setOpen(true)}
                style={{ cursor: "pointer" }}
            >
                <i className="fas fa-ellipsis-v"></i>{" "}
            </div>
            <ControlledMenu
                state={isOpen ? "open" : "closed"}
                anchorRef={ref}
                onClose={() => setOpen(false)}
            >
                {currentUser?.role !== "owner" &&
                    !currentUser?.verified_email && (
                        <MenuItem onClick={handleRemove(currentUser)}>
                            Remove access
                        </MenuItem>
                    )}
                {currentUser.role !== "owner" &&
                    (role === ROLE_ADMIN || role === ROLE_OWNER) && (
                        <MenuItem onClick={handleEdit(currentUser)}>
                            Edit Role
                        </MenuItem>
                    )}
            </ControlledMenu>
        </>
    );
}
export default ManageTeamScreen;
