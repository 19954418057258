import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Spinner, Tooltip, OverlayTrigger } from "react-bootstrap";
import Table from "../../components/Table/TablePaginationFromApi";
import Select from "../../components/Select";
import InputAutoComplete from "../../components/AutoComplete/ExploreMapping/HotelNameAutoComplete";
import { useDispatch, useSelector } from "react-redux";
import { getCountries } from "../../redux/actions/CountryActions";
import { useForm } from "react-hook-form";
import { getHotelList } from "../../redux/actions/HotelActions";
import { useHistory } from "react-router-dom";
import {
    getActiveCatalog,
    getActiveCatalogPeriodique,
} from "../../redux/actions/CatalogActions";
import { separator } from "../../utilis/functions";
import { ProgressBar } from "react-bootstrap";

const HotelsList = () => {
    const dispatch = useDispatch();

    const [filters, setFilters] = useState({});
    const { loadingActiveInventory, activeInventory } = useSelector(
        (state) => ({
            loadingActiveInventory:
                state.catalogReducer?.loadingActiveInventory ?? false,
            activeInventory:
                state.catalogReducer?.activeInventory !== null
                    ? {
                          ...state.catalogReducer?.activeInventory,
                          pourcentage: state.catalogReducer?.activeInventory
                              ?.static_content_total_hotels
                              ? ((state.catalogReducer?.activeInventory
                                    ?.static_content_unmapped_hotels +
                                    state.catalogReducer?.activeInventory
                                        ?.static_content_mapped_hotels) /
                                    state.catalogReducer?.activeInventory
                                        ?.static_content_total_hotels) *
                                100
                              : 0,
                      }
                    : {},
        })
    );

    useEffect(() => {
        const storedFilterValues = JSON.parse(
            localStorage.getItem("filterValues")
        );

        dispatch(getActiveCatalog());

        if (storedFilterValues) {
            setFilters(storedFilterValues);
            localStorage.removeItem("filterValues");
            dispatch(
                getHotelList(1, 10, {
                    ...storedFilterValues,
                    country: storedFilterValues?.country?.iso2,
                    hotel_name: storedFilterValues?.hotel_name?.label,
                })
            );
        } else {
            dispatch(
                getHotelList(1, 10, {
                    ...filters,
                    country: filters?.country?.iso2,
                    hotel_name: filters?.hotel_name?.label,
                })
            );
        }
    }, []);

    useEffect(() => {
        const refreshIntervalId = setInterval(() => {
            if (
                window.location.pathname === "/catalogs-manager/hotels-list" &&
                activeInventory?.static_content_process_status_id === 0
            ) {
                dispatch(getActiveCatalogPeriodique());
            }
        }, 5000);
        return () => clearInterval(refreshIntervalId);
    }, [activeInventory]);

    useEffect(() => {}, []);

    return (
        <>
            <Helmet>
                <title> Inventory</title>
            </Helmet>
            <div className="page-wrapper" style={{ display: "block" }}>
                <div className="page-breadcrumb">
                    <div className="row">
                        <div className="col-md-12">
                            <div
                                style={{
                                    color: "#141933",
                                    fontSize: "28px",
                                    fontFamily: "Lato",
                                    fontWeight: "700",
                                    lineHeight: "40px",
                                    wordWrap: "break-word",
                                }}
                            >
                                Properties
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid" style={{ overflow: "scroll" }}>
                    {loadingActiveInventory ? (
                        <div className="text-center">
                            <div className="lds-ripple">
                                <div className="lds-pos"></div>
                                <div className="lds-pos"></div>
                            </div>
                        </div>
                    ) : Object.keys(activeInventory).length === 0 ||
                      activeInventory?.id === 0 ||
                      activeInventory?.id === undefined ? (
                        <NoInventoryFound />
                    ) : activeInventory?.valid_hotels === 0 &&
                      activeInventory?.mapping_process_status_id === 2 ? (
                        <NoValidHotelsFound />
                    ) : activeInventory?.static_content_process_status_id ===
                          0 &&
                      activeInventory?.static_content_mapped_hotels <= 0 ? (
                        <LoadingStep1 />
                    ) : activeInventory?.static_content_process_status_id ===
                          0 &&
                      activeInventory?.static_content_mapped_hotels > 0 ? (
                        <LoadingStep2 activeInventory={activeInventory} />
                    ) : (
                        <HotelList filters={filters} setFilters={setFilters} />
                    )}
                </div>
            </div>
        </>
    );
};

const LoadingStep1 = () => {
    return (
        <>
            <div
                style={{
                    width: "100%",
                    height: "536px",
                    paddingTop: "40px",
                    paddingBottom: "40px",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "56px",
                    display: "inline-flex",
                }}
            >
                <div
                    style={{
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: 24,
                        display: "flex",
                    }}
                >
                    <div
                        style={{
                            width: "50%",
                            height: "120px",
                            position: "relative",
                        }}
                    >
                        <WaitingData />
                    </div>
                    <div
                        style={{
                            textAlign: "center",
                            color: "#141933",
                            fontSize: "15px",
                            fontFamily: "Lato",
                            fontWeight: "700",
                            lineHeight: "24px",
                            wordWrap: "break-word",
                        }}
                    >
                        We’re still gathering informations related to your
                        inventory.
                        <br />
                        This step may take several minutes
                    </div>
                </div>
                <div
                    style={{
                        //</div> width: "48px",
                        //  height: "48px",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "inline-flex",
                    }}
                >
                    <Spinner
                        animation="border"
                        style={{
                            color: "#6275E7",
                            width: "48px",
                            height: "48px",
                        }}
                    />
                </div>
            </div>
        </>
    );
};

const LoadingStep2 = ({ activeInventory }) => {
    return (
        <div
            style={{
                width: "100%",
                height: "641px",
                // paddingLeft: "40px",
                // paddingRight: "40px",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: "40px",
                display: "inline-flex",
            }}
        >
            <div
                style={{
                    alignSelf: "stretch",
                    height: "561px",
                    paddingTop: "40px",
                    paddingBottom: "40px",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "56px",
                    display: "flex",
                }}
            >
                <div
                    style={{
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: "24px",
                        display: "flex",
                    }}
                >
                    <div
                        style={{
                            width: "50%",
                            height: "120px",
                            position: "relative",
                        }}
                    >
                        <WaitingData />
                    </div>
                    <div
                        style={{
                            textAlign: "center",
                            color: "#141933",
                            fontSize: "15px",
                            fontFamily: "Lato",
                            fontWeight: "700",
                            lineHeight: "24px",
                            wordWrap: "break-word",
                        }}
                    >
                        We’re still gathering informations related to your
                        inventory. <br />
                        This step may take several minutes
                    </div>
                </div>
                <div
                    style={{
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "16px",
                        display: "flex",
                        width: "367px",
                    }}
                >
                    <div
                        style={{
                            textAlign: "center",
                            color: "#141933",
                            fontSize: "15px",
                            fontFamily: "Lato",
                            fontStyle: "italic",
                            fontWeight: "400",
                            lineHeight: "20px",
                            wordWrap: "break-word",
                        }}
                    >
                        Searching for the latest hotel information
                    </div>
                    <div
                        style={{
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "8px",
                            display: "flex",
                            width: "100%",
                        }}
                    >
                        <ProgressBar
                            style={{
                                height: "9px",
                                width: "100%",
                            }}
                            now={activeInventory?.pourcentage}
                            // label={`${activeInventory?.pourcentage?.toFixed(
                            //     0
                            // )}%`}
                        />

                        <div
                            style={{
                                textAlign: "center",
                                color: "#878CA8",
                                fontSize: "15px",
                                fontFamily: "Lato",
                                fontWeight: "400",
                                lineHeight: "20px",
                                wordWrap: "break-word",
                            }}
                        >
                            {`${activeInventory?.pourcentage?.toFixed(0)}%`}
                        </div>
                        {/* <div
                            style={{
                                height: "9px",
                                background: "#DFE3ED",
                                borderRadius: "32px",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "flex-start",
                                gap: "8px",
                                display: "flex",
                            }}
                        >
                            <div
                                style={{
                                    width: "367px",
                                    height: "9px",
                                    background: "#DFE3ED",
                                    borderRadius: "32px",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "flex-start",
                                    gap: "8px",
                                    display: "inline-flex",
                                }}
                            >
                                <div
                                    style={{
                                        width: "48px",
                                        height: "9px",
                                        background:
                                            "linear-gradient(90deg, #8971EA 0%, #6076E7 100%)",
                                        borderRadius: "100px",
                                    }}
                                />
                            </div>
                        </div>
                        <div
                            style={{
                                textAlign: "center",
                                color: "#878CA8",
                                fontSize: "15px",
                                fontFamily: "Lato",
                                fontWeight: "400",
                                lineHeight: "20px",
                                wordWrap: "break-word",
                            }}
                        >
                            10%
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

const HotelList = (props) => {
    const { filters, setFilters } = props;
    const dispatch = useDispatch();
    const history = useHistory();
    const [perPage, setPerPage] = useState(10);
    const [column, setColumn] = useState();
    const [sortDirection, setSortDirection] = useState();
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const { hotelList, loadingHotelList, totalHotels } = useSelector(
        (state) => ({
            hotelList: state.hotelsReducer.hotelList ?? [],
            loadingHotelList: state.hotelsReducer.loadingHotelList,
            totalHotels: state.hotelsReducer.totalHotels,
        })
    );

    const handlePageChange = (page) => {
        dispatch(
            getHotelList(
                page,
                perPage,
                {
                    ...filters,
                    country: filters?.country?.iso2,
                    hotel_name: filters?.hotel_name?.label,
                },
                column,
                sortDirection
            )
        );
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        dispatch(
            getHotelList(
                page,
                newPerPage,
                {
                    ...filters,
                    country: filters?.country?.iso2,
                    hotel_name: filters?.hotel_name?.label,
                },
                column,
                sortDirection
            )
        );
        setPerPage(newPerPage);
    };

    const handleSort = async (c, sortD) => {
        dispatch(
            getHotelList(
                1,
                perPage,
                {
                    ...filters,
                    country: filters?.country?.iso2,
                    hotel_name: filters?.hotel_name?.label,
                },
                c?.id,
                sortD
            )
        );
        setColumn(c?.id);
        setSortDirection(sortD);
    };

    const renderTooltip = (props = (text) => {
        return (
            <Tooltip
                id={`button-tooltip ${text}`}
                {...props}
                style={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "inline-flex",
                    color: "#F5F6FA",
                    fontSize: "15px",
                    fontFamily: "Lato",
                    fontWeight: "400",
                }}
            >
                {text}
            </Tooltip>
        );
    });
    const columns = [
        {
            name: <div className="new-table-title">Code</div>,
            selector: (e) => e?.hotel_code,
            cell: (e) => {
                return <span className="new-table-text">{e?.hotel_code}</span>;
            },
            sortable: true,
        },
        {
            name: <div className="new-table-title">Name</div>,
            selector: (e) => e?.hotel_name,
            cell: (e) => {
                return (
                    <span
                        className="new-table-text"
                        style={{ fontWeight: "bold" }}
                    >
                        {e?.hotel_name}
                    </span>
                );
            },
            sortable: true,
        },
        {
            name: <div className="new-table-title">Country</div>,
            selector: (e) => e?.country,
            cell: (e) => {
                return <span className="new-table-text">{e?.country}</span>;
            },
            sortable: true,
            width: "120px",
        },
        {
            name: <div className="new-table-title">City</div>,
            selector: (e) => e?.city,
            cell: (e) => {
                return <span className="new-table-text">{e?.city}</span>;
            },
            sortable: true,
        },
        {
            name: <div className="new-table-title">Address</div>,
            selector: (e) => e?.address,
            cell: (e) => {
                return <span className="new-table-text">{e?.address}</span>;
            },
            sortable: true,
        },
        {
            name: <div className="new-table-title"></div>,
            cell: (e) => {
                const tooltipText = e?.property_id
                    ? ""
                    : "Our database does not include this hotel's information";
                return (
                    <>
                        {tooltipText !== "" ? (
                            <OverlayTrigger
                                placement="top"
                                overlay={renderTooltip(tooltipText)}
                            >
                                <button
                                    className="btn"
                                    style={{
                                        color: "#6275E7",
                                        fontSize: "15px",
                                        fontWeight: "700",
                                        textDecoration: "underline",
                                        wordWrap: "break-word",
                                        cursor: "not-allowed",
                                        opacity: "0.45",
                                        padding: "0px",
                                    }}
                                    disabled={!e?.property_id}
                                >
                                    See Details
                                </button>
                            </OverlayTrigger>
                        ) : (
                            <button
                                className="btn"
                                onClick={() => {
                                    if (e?.property_id > 0) {
                                        history.push(
                                            `/hotels/${e?.property_id}`
                                        );
                                        localStorage.setItem(
                                            "filterValues",
                                            JSON.stringify(filters)
                                        );
                                    }
                                }}
                                style={{
                                    color: "#6275E7",
                                    fontSize: "15px",
                                    fontWeight: "700",
                                    textDecoration: "underline",
                                    wordWrap: "break-word",
                                    cursor: "pointer",
                                    padding: "0px",
                                }}
                            >
                                See Details
                            </button>
                        )}
                    </>
                );
            },
            sortable: false,
            width: "116px",
        },
    ];
    const handleSearch = () => {
        dispatch(
            getHotelList(1, perPage, {
                ...filters,
                country: filters?.country?.iso2,
                hotel_name: filters?.hotel_name?.label,
            })
        );
    };

    return (
        <>
            <div
                style={{
                    width: "100%",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    gap: "40px",
                    display: "inline-flex",
                }}
            >
                <div
                    style={{
                        alignSelf: "stretch",
                        height: "694px",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        gap: "32px",
                        display: "flex",
                    }}
                >
                    <SearchForm
                        handleSearch={handleSearch}
                        filters={filters}
                        setFilters={setFilters}
                    />
                    <div
                        style={{
                            alignSelf: "stretch",
                            //height: 610,
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            gap: "16px",
                            display: "flex",
                        }}
                    >
                        {!loadingHotelList && hotelList?.length <= 0 ? (
                            <NoRecords />
                        ) : (
                            <>
                                <div
                                    style={{
                                        color: "#141933",
                                        fontSize: "15px",
                                        fontFamily: "Lato",
                                        fontWeight: "700",
                                        wordWrap: "break-word",
                                    }}
                                >
                                    {separator(totalHotels)} Results
                                </div>
                                <div className="card" style={{ width: "100%" }}>
                                    <div className="amenities_table">
                                        <Table
                                            data={hotelList ?? []}
                                            columns={columns}
                                            totalRows={totalHotels}
                                            handlePageChange={handlePageChange}
                                            onChangeRowsPerPage={
                                                handlePerRowsChange
                                            }
                                            progressPending={loadingHotelList}
                                            onFilter={() => {
                                                setResetPaginationToggle(true);
                                                dispatch(
                                                    getHotelList(
                                                        1,
                                                        perPage,
                                                        filters
                                                    )
                                                );
                                            }}
                                            filter={false}
                                            resetPaginationToggle={
                                                resetPaginationToggle
                                            }
                                            onSort={handleSort}
                                            sortServer={true}
                                        />
                                    </div>
                                </div>
                                {/* <Table data={[]} columns={columns} /> */}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

const WaitingData = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="186"
            height="131"
            viewBox="0 0 186 131"
            fill="none"
        >
            <circle cx="95.8262" cy="60" r="60" fill="#EAEEF9" />
            <g filter="url(#filter0_d_144_4194)">
                <path
                    d="M115.92 97.8983H70.7671C68.8418 97.8983 67.2832 96.3397 67.2832 94.4144V32.4839C67.2832 30.5586 68.8418 29 70.7671 29H115.92C117.845 29 119.404 30.5586 119.404 32.4839V94.4144C119.404 96.3397 117.845 97.8983 115.92 97.8983Z"
                    fill="url(#paint0_linear_144_4194)"
                />
            </g>
            <path
                d="M99.2112 81.1898H87.476C86.1696 81.1898 85.1152 80.1355 85.1152 78.829C85.1152 77.5226 86.1696 76.4683 87.476 76.4683H99.2112C100.518 76.4683 101.572 77.5226 101.572 78.829C101.572 80.1355 100.518 81.1898 99.2112 81.1898Z"
                fill="#D5DDEA"
            />
            <path
                d="M104.597 86.3006H82.0668C81.3792 86.3006 80.8291 85.7505 80.8291 85.0629C80.8291 84.3753 81.3792 83.8252 82.0668 83.8252H104.597C105.285 83.8252 105.835 84.3753 105.835 85.0629C105.835 85.7276 105.285 86.3006 104.597 86.3006Z"
                fill="#D5DDEA"
            />
            <path
                d="M108.746 53.5708C108.746 58.2007 106.706 62.3493 103.474 65.1685C100.769 67.5293 97.2169 68.9503 93.3433 68.9503C89.4698 68.9503 85.9172 67.5293 83.2126 65.1456C79.9808 62.3264 77.9409 58.1778 77.9409 53.5479C77.9409 45.0445 84.8399 38.1455 93.3433 38.1455C101.847 38.1455 108.746 45.0674 108.746 53.5708Z"
                fill="#D9DEEA"
            />
            <g filter="url(#filter1_d_144_4194)">
                <path
                    d="M54.5854 87.2407H25.5454C23.6201 87.2407 22.0615 85.6821 22.0615 83.7568V43.1421C22.0615 41.2168 23.6201 39.6582 25.5454 39.6582H54.5854C56.5107 39.6582 58.0692 41.2168 58.0692 43.1421V83.7797C58.0692 85.705 56.5107 87.2407 54.5854 87.2407Z"
                    fill="url(#paint1_linear_144_4194)"
                />
            </g>
            <path
                d="M44.1107 75.7112H35.997C35.1031 75.7112 34.3696 74.9778 34.3696 74.0839C34.3696 73.19 35.1031 72.4565 35.997 72.4565H44.1107C45.0046 72.4565 45.7381 73.19 45.7381 74.0839C45.7381 74.9778 45.0046 75.7112 44.1107 75.7112Z"
                fill="#D5DDEA"
            />
            <path
                d="M47.8469 79.241H32.2841C31.8028 79.241 31.436 78.8514 31.436 78.393C31.436 77.9116 31.8257 77.5449 32.2841 77.5449H47.8469C48.3283 77.5449 48.695 77.9346 48.695 78.393C48.695 78.8514 48.3053 79.241 47.8469 79.241Z"
                fill="#D5DDEA"
            />
            <path
                d="M50.6889 56.6189C50.6889 59.8277 49.2679 62.6928 47.0446 64.641C45.1652 66.2683 42.7356 67.2539 40.0539 67.2539C37.3723 67.2539 34.9198 66.2683 33.0633 64.641C30.84 62.6928 29.4189 59.8277 29.4189 56.6189C29.4189 50.7513 34.1864 45.9839 40.0539 45.9839C45.9215 45.9839 50.6889 50.7513 50.6889 56.6189Z"
                fill="#D9DEEA"
            />
            <g filter="url(#filter2_d_144_4194)">
                <path
                    d="M160.454 87.2407H131.414C129.489 87.2407 127.93 85.6821 127.93 83.7568V43.1421C127.93 41.2168 129.489 39.6582 131.414 39.6582H160.454C162.379 39.6582 163.938 41.2168 163.938 43.1421V83.7797C163.938 85.705 162.379 87.2407 160.454 87.2407Z"
                    fill="url(#paint2_linear_144_4194)"
                />
            </g>
            <path
                d="M149.979 75.7112H141.866C140.972 75.7112 140.238 74.9778 140.238 74.0839C140.238 73.19 140.972 72.4565 141.866 72.4565H149.979C150.873 72.4565 151.607 73.19 151.607 74.0839C151.63 74.9778 150.896 75.7112 149.979 75.7112Z"
                fill="#D5DDEA"
            />
            <path
                d="M153.716 79.241H138.153C137.671 79.241 137.305 78.8514 137.305 78.393C137.305 77.9116 137.694 77.5449 138.153 77.5449H153.716C154.197 77.5449 154.564 77.9346 154.564 78.393C154.564 78.8514 154.197 79.241 153.716 79.241Z"
                fill="#D5DDEA"
            />
            <path
                d="M156.581 56.6189C156.581 59.8277 155.159 62.6928 152.936 64.641C151.057 66.2683 148.627 67.2539 145.946 67.2539C143.264 67.2539 140.811 66.2683 138.955 64.641C136.732 62.6928 135.311 59.8277 135.311 56.6189C135.311 50.7513 140.078 45.9839 145.946 45.9839C151.813 45.9839 156.581 50.7513 156.581 56.6189Z"
                fill="#D9DEEA"
            />
            <mask
                id="mask0_144_4194"
                // style="mask-type:alpha"
                maskUnits="userSpaceOnUse"
                x="135"
                y="46"
                width="22"
                height="22"
            >
                <circle cx="146.067" cy="56.6618" r="10.6325" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_144_4194)">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M152.105 51.7212C152.176 51.7212 152.234 51.7789 152.234 51.85V54.2173C152.234 54.2884 152.176 54.3461 152.105 54.3461C152.034 54.3461 151.976 54.2884 151.976 54.2173V51.85C151.976 51.7789 152.034 51.7212 152.105 51.7212Z"
                    fill="#C2C8D6"
                />
                <rect
                    x="151.145"
                    y="54.0254"
                    width="1.91935"
                    height="1.91935"
                    fill="#D5DDEA"
                />
                <g filter="url(#filter3_d_144_4194)">
                    <path
                        d="M147.946 53.5132L153.832 56.2583V67.3325H147.946V53.5132Z"
                        fill="url(#paint3_linear_144_4194)"
                    />
                </g>
                <rect
                    x="149.226"
                    y="57.0967"
                    width="1.40753"
                    height="1.02366"
                    rx="0.511828"
                    fill="#C2C8D6"
                />
                <rect
                    x="149.226"
                    y="59.0156"
                    width="1.40753"
                    height="1.15161"
                    rx="0.575806"
                    fill="#C2C8D6"
                />
                <rect
                    x="149.226"
                    y="60.9346"
                    width="1.40753"
                    height="1.15161"
                    rx="0.575806"
                    fill="#C2C8D6"
                />
                <rect
                    x="149.226"
                    y="62.9814"
                    width="1.40753"
                    height="1.15161"
                    rx="0.575806"
                    fill="#C2C8D6"
                />
                <rect
                    x="149.226"
                    y="64.9014"
                    width="1.40753"
                    height="1.15161"
                    rx="0.575806"
                    fill="#C2C8D6"
                />
                <rect
                    x="151.273"
                    y="57.0967"
                    width="1.27957"
                    height="1.02366"
                    rx="0.511828"
                    fill="#C2C8D6"
                />
                <rect
                    x="151.273"
                    y="59.0156"
                    width="1.27957"
                    height="1.15161"
                    rx="0.575806"
                    fill="#C2C8D6"
                />
                <rect
                    x="151.273"
                    y="60.9346"
                    width="1.27957"
                    height="1.15161"
                    rx="0.575806"
                    fill="#C2C8D6"
                />
                <rect
                    x="151.273"
                    y="62.9814"
                    width="1.27957"
                    height="1.15161"
                    rx="0.575806"
                    fill="#C2C8D6"
                />
                <rect
                    x="151.273"
                    y="64.9014"
                    width="1.27957"
                    height="1.15161"
                    rx="0.575806"
                    fill="#C2C8D6"
                />
                <g filter="url(#filter4_d_144_4194)">
                    <path
                        d="M142.828 49.0347H148.074V67.3325H142.828V49.0347Z"
                        fill="url(#paint4_linear_144_4194)"
                    />
                </g>
                <rect
                    x="143.724"
                    y="50.314"
                    width="3.45484"
                    height="0.895698"
                    rx="0.447849"
                    fill="#C2C8D6"
                />
                <rect
                    x="143.724"
                    y="52.2339"
                    width="3.45484"
                    height="0.767742"
                    rx="0.383871"
                    fill="#C2C8D6"
                />
                <rect
                    x="143.724"
                    y="54.1533"
                    width="3.45484"
                    height="0.767742"
                    rx="0.383871"
                    fill="#C2C8D6"
                />
                <rect
                    x="143.724"
                    y="56.0723"
                    width="3.45484"
                    height="0.767742"
                    rx="0.383871"
                    fill="#C2C8D6"
                />
                <rect
                    x="143.724"
                    y="57.9912"
                    width="3.45484"
                    height="0.767742"
                    rx="0.383871"
                    fill="#C2C8D6"
                />
                <rect
                    x="143.724"
                    y="59.9116"
                    width="3.45484"
                    height="0.767742"
                    rx="0.383871"
                    fill="#C2C8D6"
                />
                <rect
                    x="143.724"
                    y="61.8306"
                    width="3.45484"
                    height="0.767742"
                    rx="0.383871"
                    fill="#C2C8D6"
                />
                <rect
                    x="143.724"
                    y="63.75"
                    width="3.45484"
                    height="0.767742"
                    rx="0.383871"
                    fill="#C2C8D6"
                />
                <g filter="url(#filter5_d_144_4194)">
                    <path
                        d="M139.885 55.8164H145.643V67.3325H139.885V55.8164Z"
                        fill="url(#paint5_linear_144_4194)"
                    />
                </g>
                <rect
                    x="141.054"
                    y="56.8726"
                    width="1.34669"
                    height="1.12747"
                    rx="0.563733"
                    fill="#C2C8D6"
                />
                <rect
                    x="141.054"
                    y="58.8447"
                    width="1.34669"
                    height="1.12747"
                    rx="0.563733"
                    fill="#C2C8D6"
                />
                <rect
                    x="141.054"
                    y="60.8184"
                    width="1.34669"
                    height="1.12747"
                    rx="0.563733"
                    fill="#C2C8D6"
                />
                <rect
                    x="141.054"
                    y="62.791"
                    width="1.34669"
                    height="1.12747"
                    rx="0.563733"
                    fill="#C2C8D6"
                />
                <path
                    d="M141.42 66.5652C141.42 65.7879 142.051 65.1577 142.828 65.1577V67.333H141.42V66.5652Z"
                    fill="#C2C8D6"
                />
                <rect
                    x="143.09"
                    y="56.8726"
                    width="1.34669"
                    height="1.12747"
                    rx="0.563733"
                    fill="#C2C8D6"
                />
                <rect
                    x="143.09"
                    y="58.8447"
                    width="1.34669"
                    height="1.12747"
                    rx="0.563733"
                    fill="#C2C8D6"
                />
                <rect
                    x="143.09"
                    y="60.8184"
                    width="1.34669"
                    height="1.12747"
                    rx="0.563733"
                    fill="#C2C8D6"
                />
                <rect
                    x="143.09"
                    y="62.791"
                    width="1.34669"
                    height="1.12747"
                    rx="0.563733"
                    fill="#C2C8D6"
                />
                <path
                    d="M142.828 65.1577C143.605 65.1577 144.236 65.7879 144.236 66.5652V67.333H142.828V65.1577Z"
                    fill="#C2C8D6"
                />
            </g>
            <mask
                id="mask1_144_4194"
                // style="mask-type:alpha"
                maskUnits="userSpaceOnUse"
                x="29"
                y="46"
                width="22"
                height="22"
            >
                <circle cx="40.0358" cy="56.6618" r="10.6325" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask1_144_4194)">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M46.0742 51.7212C46.1453 51.7212 46.203 51.7789 46.203 51.85V54.2173C46.203 54.2884 46.1453 54.3461 46.0742 54.3461C46.003 54.3461 45.9453 54.2884 45.9453 54.2173V51.85C45.9453 51.7789 46.003 51.7212 46.0742 51.7212Z"
                    fill="#C2C8D6"
                />
                <rect
                    x="45.1143"
                    y="54.0254"
                    width="1.91935"
                    height="1.91935"
                    fill="#D5DDEA"
                />
                <g filter="url(#filter6_d_144_4194)">
                    <path
                        d="M41.9155 53.5132L47.8015 56.2583V67.3325H41.9155V53.5132Z"
                        fill="url(#paint6_linear_144_4194)"
                    />
                </g>
                <rect
                    x="43.1948"
                    y="57.0967"
                    width="1.40753"
                    height="1.02366"
                    rx="0.511828"
                    fill="#C2C8D6"
                />
                <rect
                    x="43.1948"
                    y="59.0156"
                    width="1.40753"
                    height="1.15161"
                    rx="0.575806"
                    fill="#C2C8D6"
                />
                <rect
                    x="43.1948"
                    y="60.9346"
                    width="1.40753"
                    height="1.15161"
                    rx="0.575806"
                    fill="#C2C8D6"
                />
                <rect
                    x="43.1948"
                    y="62.9814"
                    width="1.40753"
                    height="1.15161"
                    rx="0.575806"
                    fill="#C2C8D6"
                />
                <rect
                    x="43.1948"
                    y="64.9014"
                    width="1.40753"
                    height="1.15161"
                    rx="0.575806"
                    fill="#C2C8D6"
                />
                <rect
                    x="45.2422"
                    y="57.0967"
                    width="1.27957"
                    height="1.02366"
                    rx="0.511828"
                    fill="#C2C8D6"
                />
                <rect
                    x="45.2422"
                    y="59.0156"
                    width="1.27957"
                    height="1.15161"
                    rx="0.575806"
                    fill="#C2C8D6"
                />
                <rect
                    x="45.2422"
                    y="60.9346"
                    width="1.27957"
                    height="1.15161"
                    rx="0.575806"
                    fill="#C2C8D6"
                />
                <rect
                    x="45.2422"
                    y="62.9814"
                    width="1.27957"
                    height="1.15161"
                    rx="0.575806"
                    fill="#C2C8D6"
                />
                <rect
                    x="45.2422"
                    y="64.9014"
                    width="1.27957"
                    height="1.15161"
                    rx="0.575806"
                    fill="#C2C8D6"
                />
                <g filter="url(#filter7_d_144_4194)">
                    <path
                        d="M36.7974 49.0347H42.0436V67.3325H36.7974V49.0347Z"
                        fill="url(#paint7_linear_144_4194)"
                    />
                </g>
                <rect
                    x="37.6929"
                    y="50.314"
                    width="3.45484"
                    height="0.895698"
                    rx="0.447849"
                    fill="#C2C8D6"
                />
                <rect
                    x="37.6929"
                    y="52.2339"
                    width="3.45484"
                    height="0.767742"
                    rx="0.383871"
                    fill="#C2C8D6"
                />
                <rect
                    x="37.6929"
                    y="54.1533"
                    width="3.45484"
                    height="0.767742"
                    rx="0.383871"
                    fill="#C2C8D6"
                />
                <rect
                    x="37.6929"
                    y="56.0723"
                    width="3.45484"
                    height="0.767742"
                    rx="0.383871"
                    fill="#C2C8D6"
                />
                <rect
                    x="37.6929"
                    y="57.9912"
                    width="3.45484"
                    height="0.767742"
                    rx="0.383871"
                    fill="#C2C8D6"
                />
                <rect
                    x="37.6929"
                    y="59.9116"
                    width="3.45484"
                    height="0.767742"
                    rx="0.383871"
                    fill="#C2C8D6"
                />
                <rect
                    x="37.6929"
                    y="61.8306"
                    width="3.45484"
                    height="0.767742"
                    rx="0.383871"
                    fill="#C2C8D6"
                />
                <rect
                    x="37.6929"
                    y="63.75"
                    width="3.45484"
                    height="0.767742"
                    rx="0.383871"
                    fill="#C2C8D6"
                />
                <g filter="url(#filter8_d_144_4194)">
                    <path
                        d="M33.854 55.8164H39.6121V67.3325H33.854V55.8164Z"
                        fill="url(#paint8_linear_144_4194)"
                    />
                </g>
                <rect
                    x="35.0234"
                    y="56.8726"
                    width="1.34669"
                    height="1.12747"
                    rx="0.563733"
                    fill="#C2C8D6"
                />
                <rect
                    x="35.0234"
                    y="58.8447"
                    width="1.34669"
                    height="1.12747"
                    rx="0.563733"
                    fill="#C2C8D6"
                />
                <rect
                    x="35.0234"
                    y="60.8184"
                    width="1.34669"
                    height="1.12747"
                    rx="0.563733"
                    fill="#C2C8D6"
                />
                <rect
                    x="35.0234"
                    y="62.791"
                    width="1.34669"
                    height="1.12747"
                    rx="0.563733"
                    fill="#C2C8D6"
                />
                <path
                    d="M35.3896 66.5652C35.3896 65.7879 36.0198 65.1577 36.7972 65.1577V67.333H35.3896V66.5652Z"
                    fill="#C2C8D6"
                />
                <rect
                    x="37.0596"
                    y="56.8726"
                    width="1.34669"
                    height="1.12747"
                    rx="0.563733"
                    fill="#C2C8D6"
                />
                <rect
                    x="37.0596"
                    y="58.8447"
                    width="1.34669"
                    height="1.12747"
                    rx="0.563733"
                    fill="#C2C8D6"
                />
                <rect
                    x="37.0596"
                    y="60.8184"
                    width="1.34669"
                    height="1.12747"
                    rx="0.563733"
                    fill="#C2C8D6"
                />
                <rect
                    x="37.0596"
                    y="62.791"
                    width="1.34669"
                    height="1.12747"
                    rx="0.563733"
                    fill="#C2C8D6"
                />
                <path
                    d="M36.7974 65.1577C37.5747 65.1577 38.2049 65.7879 38.2049 66.5652V67.333H36.7974V65.1577Z"
                    fill="#C2C8D6"
                />
            </g>
            <mask
                id="mask2_144_4194"
                // style="mask-type:alpha"
                maskUnits="userSpaceOnUse"
                x="78"
                y="38"
                width="31"
                height="31"
            >
                <circle cx="93.4049" cy="53.4806" r="15.4039" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask2_144_4194)">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M102.153 46.3228C102.256 46.3228 102.339 46.4063 102.339 46.5094V49.9389C102.339 50.042 102.256 50.1256 102.153 50.1256C102.049 50.1256 101.966 50.042 101.966 49.9389V46.5094C101.966 46.4063 102.049 46.3228 102.153 46.3228Z"
                    fill="#C2C8D6"
                />
                <rect
                    x="100.762"
                    y="49.6606"
                    width="2.78067"
                    height="2.78067"
                    fill="#D5DDEA"
                />
                <g filter="url(#filter9_d_144_4194)">
                    <path
                        d="M96.1274 48.9194L104.655 52.8965V68.9403H96.1274V48.9194Z"
                        fill="url(#paint9_linear_144_4194)"
                    />
                </g>
                <rect
                    x="97.9814"
                    y="54.1104"
                    width="2.03916"
                    height="1.48302"
                    rx="0.741512"
                    fill="#C2C8D6"
                />
                <rect
                    x="97.9814"
                    y="56.8906"
                    width="2.03916"
                    height="1.6684"
                    rx="0.834201"
                    fill="#C2C8D6"
                />
                <rect
                    x="97.9814"
                    y="59.6709"
                    width="2.03916"
                    height="1.6684"
                    rx="0.834201"
                    fill="#C2C8D6"
                />
                <rect
                    x="97.9814"
                    y="62.6377"
                    width="2.03916"
                    height="1.6684"
                    rx="0.834201"
                    fill="#C2C8D6"
                />
                <rect
                    x="97.9814"
                    y="65.418"
                    width="2.03916"
                    height="1.6684"
                    rx="0.834201"
                    fill="#C2C8D6"
                />
                <rect
                    x="100.947"
                    y="54.1104"
                    width="1.85378"
                    height="1.48302"
                    rx="0.741512"
                    fill="#C2C8D6"
                />
                <rect
                    x="100.947"
                    y="56.8906"
                    width="1.85378"
                    height="1.6684"
                    rx="0.834201"
                    fill="#C2C8D6"
                />
                <rect
                    x="100.947"
                    y="59.6709"
                    width="1.85378"
                    height="1.6684"
                    rx="0.834201"
                    fill="#C2C8D6"
                />
                <rect
                    x="100.947"
                    y="62.6377"
                    width="1.85378"
                    height="1.6684"
                    rx="0.834201"
                    fill="#C2C8D6"
                />
                <rect
                    x="100.947"
                    y="65.418"
                    width="1.85378"
                    height="1.6684"
                    rx="0.834201"
                    fill="#C2C8D6"
                />
                <g filter="url(#filter10_d_144_4194)">
                    <path
                        d="M88.7124 42.4316H96.3129V68.9407H88.7124V42.4316Z"
                        fill="url(#paint10_linear_144_4194)"
                    />
                </g>
                <rect
                    x="90.0103"
                    y="44.2847"
                    width="5.00521"
                    height="1.29765"
                    rx="0.648823"
                    fill="#C2C8D6"
                />
                <rect
                    x="90.0103"
                    y="47.0659"
                    width="5.00521"
                    height="1.11227"
                    rx="0.556134"
                    fill="#C2C8D6"
                />
                <rect
                    x="90.0103"
                    y="49.8462"
                    width="5.00521"
                    height="1.11227"
                    rx="0.556134"
                    fill="#C2C8D6"
                />
                <rect
                    x="90.0103"
                    y="52.6274"
                    width="5.00521"
                    height="1.11227"
                    rx="0.556134"
                    fill="#C2C8D6"
                />
                <rect
                    x="90.0103"
                    y="55.4067"
                    width="5.00521"
                    height="1.11227"
                    rx="0.556134"
                    fill="#C2C8D6"
                />
                <rect
                    x="90.0103"
                    y="58.189"
                    width="5.00521"
                    height="1.11227"
                    rx="0.556134"
                    fill="#C2C8D6"
                />
                <rect
                    x="90.0103"
                    y="60.9688"
                    width="5.00521"
                    height="1.11227"
                    rx="0.556134"
                    fill="#C2C8D6"
                />
                <rect
                    x="90.0103"
                    y="63.75"
                    width="5.00521"
                    height="1.11227"
                    rx="0.556134"
                    fill="#C2C8D6"
                />
                <g filter="url(#filter11_d_144_4194)">
                    <path
                        d="M84.4487 52.2563H92.7907V68.9404H84.4487V52.2563Z"
                        fill="url(#paint11_linear_144_4194)"
                    />
                </g>
                <rect
                    x="86.1426"
                    y="53.7866"
                    width="1.95103"
                    height="1.63342"
                    rx="0.81671"
                    fill="#C2C8D6"
                />
                <rect
                    x="86.1426"
                    y="56.644"
                    width="1.95103"
                    height="1.63342"
                    rx="0.81671"
                    fill="#C2C8D6"
                />
                <rect
                    x="86.1426"
                    y="59.5029"
                    width="1.95103"
                    height="1.63342"
                    rx="0.81671"
                    fill="#C2C8D6"
                />
                <rect
                    x="86.1426"
                    y="62.3613"
                    width="1.95103"
                    height="1.63342"
                    rx="0.81671"
                    fill="#C2C8D6"
                />
                <path
                    d="M86.6729 67.79C86.6729 66.6855 87.5683 65.79 88.6729 65.79H88.712V68.9415H86.6729V67.79Z"
                    fill="#C2C8D6"
                />
                <rect
                    x="89.0923"
                    y="53.7866"
                    width="1.95103"
                    height="1.63342"
                    rx="0.81671"
                    fill="#C2C8D6"
                />
                <rect
                    x="89.0923"
                    y="56.644"
                    width="1.95103"
                    height="1.63342"
                    rx="0.81671"
                    fill="#C2C8D6"
                />
                <rect
                    x="89.0923"
                    y="59.5029"
                    width="1.95103"
                    height="1.63342"
                    rx="0.81671"
                    fill="#C2C8D6"
                />
                <rect
                    x="89.0923"
                    y="62.3613"
                    width="1.95103"
                    height="1.63342"
                    rx="0.81671"
                    fill="#C2C8D6"
                />
                <path
                    d="M88.7124 65.79H88.7516C89.8561 65.79 90.7516 66.6855 90.7516 67.79V68.9415H88.7124V65.79Z"
                    fill="#C2C8D6"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_144_4194"
                    x="45.2832"
                    y="18"
                    width="96.1206"
                    height="112.898"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="11" />
                    <feGaussianBlur stdDeviation="11" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_144_4194"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_144_4194"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter1_d_144_4194"
                    x="0.0615234"
                    y="28.6582"
                    width="80.0078"
                    height="91.5825"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="11" />
                    <feGaussianBlur stdDeviation="11" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_144_4194"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_144_4194"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter2_d_144_4194"
                    x="105.93"
                    y="28.6582"
                    width="80.0078"
                    height="91.5825"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="11" />
                    <feGaussianBlur stdDeviation="11" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_144_4194"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_144_4194"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter3_d_144_4194"
                    x="125.946"
                    y="42.5132"
                    width="49.8862"
                    height="57.8193"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="11" />
                    <feGaussianBlur stdDeviation="11" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_144_4194"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_144_4194"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter4_d_144_4194"
                    x="120.828"
                    y="38.0347"
                    width="49.2461"
                    height="62.2979"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="11" />
                    <feGaussianBlur stdDeviation="11" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_144_4194"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_144_4194"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter5_d_144_4194"
                    x="117.885"
                    y="44.8164"
                    width="49.7583"
                    height="55.5161"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="11" />
                    <feGaussianBlur stdDeviation="11" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_144_4194"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_144_4194"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter6_d_144_4194"
                    x="19.9155"
                    y="42.5132"
                    width="49.8862"
                    height="57.8193"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="11" />
                    <feGaussianBlur stdDeviation="11" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_144_4194"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_144_4194"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter7_d_144_4194"
                    x="14.7974"
                    y="38.0347"
                    width="49.2461"
                    height="62.2979"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="11" />
                    <feGaussianBlur stdDeviation="11" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_144_4194"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_144_4194"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter8_d_144_4194"
                    x="11.854"
                    y="44.8164"
                    width="49.7583"
                    height="55.5161"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="11" />
                    <feGaussianBlur stdDeviation="11" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_144_4194"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_144_4194"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter9_d_144_4194"
                    x="74.1274"
                    y="37.9194"
                    width="52.5273"
                    height="64.021"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="11" />
                    <feGaussianBlur stdDeviation="11" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_144_4194"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_144_4194"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter10_d_144_4194"
                    x="66.7124"
                    y="31.4316"
                    width="51.6006"
                    height="70.5093"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="11" />
                    <feGaussianBlur stdDeviation="11" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_144_4194"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_144_4194"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter11_d_144_4194"
                    x="62.4487"
                    y="41.2563"
                    width="52.3418"
                    height="60.6841"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="11" />
                    <feGaussianBlur stdDeviation="11" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_144_4194"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_144_4194"
                        result="shape"
                    />
                </filter>
                <linearGradient
                    id="paint0_linear_144_4194"
                    x1="93.3265"
                    y1="27.4063"
                    x2="93.3265"
                    y2="98.641"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#FDFEFF" />
                    <stop offset="0.9964" stop-color="#ECF0F5" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_144_4194"
                    x1="40.0536"
                    y1="38.5576"
                    x2="40.0536"
                    y2="87.7536"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#FDFEFF" />
                    <stop offset="0.9964" stop-color="#ECF0F5" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_144_4194"
                    x1="145.922"
                    y1="38.5576"
                    x2="145.922"
                    y2="87.7536"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#FDFEFF" />
                    <stop offset="0.9964" stop-color="#ECF0F5" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_144_4194"
                    x1="150.887"
                    y1="53.1935"
                    x2="150.887"
                    y2="67.4815"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#FDFEFF" />
                    <stop offset="0.9964" stop-color="#ECF0F5" />
                </linearGradient>
                <linearGradient
                    id="paint4_linear_144_4194"
                    x1="145.45"
                    y1="48.6114"
                    x2="145.45"
                    y2="67.5298"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#FDFEFF" />
                    <stop offset="0.9964" stop-color="#ECF0F5" />
                </linearGradient>
                <linearGradient
                    id="paint5_linear_144_4194"
                    x1="142.762"
                    y1="55.55"
                    x2="142.762"
                    y2="67.4567"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#FDFEFF" />
                    <stop offset="0.9964" stop-color="#ECF0F5" />
                </linearGradient>
                <linearGradient
                    id="paint6_linear_144_4194"
                    x1="44.8566"
                    y1="53.1935"
                    x2="44.8566"
                    y2="67.4815"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#FDFEFF" />
                    <stop offset="0.9964" stop-color="#ECF0F5" />
                </linearGradient>
                <linearGradient
                    id="paint7_linear_144_4194"
                    x1="39.4188"
                    y1="48.6114"
                    x2="39.4188"
                    y2="67.5298"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#FDFEFF" />
                    <stop offset="0.9964" stop-color="#ECF0F5" />
                </linearGradient>
                <linearGradient
                    id="paint8_linear_144_4194"
                    x1="36.7312"
                    y1="55.55"
                    x2="36.7312"
                    y2="67.4567"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#FDFEFF" />
                    <stop offset="0.9964" stop-color="#ECF0F5" />
                </linearGradient>
                <linearGradient
                    id="paint9_linear_144_4194"
                    x1="100.388"
                    y1="48.4563"
                    x2="100.388"
                    y2="69.1561"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#FDFEFF" />
                    <stop offset="0.9964" stop-color="#ECF0F5" />
                </linearGradient>
                <linearGradient
                    id="paint10_linear_144_4194"
                    x1="92.5102"
                    y1="41.8185"
                    x2="92.5102"
                    y2="69.2265"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#FDFEFF" />
                    <stop offset="0.9964" stop-color="#ECF0F5" />
                </linearGradient>
                <linearGradient
                    id="paint11_linear_144_4194"
                    x1="88.617"
                    y1="51.8704"
                    x2="88.617"
                    y2="69.1202"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#FDFEFF" />
                    <stop offset="0.9964" stop-color="#ECF0F5" />
                </linearGradient>
            </defs>
        </svg>
    );
};

const NoRecordSVG = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="300"
            height="200"
            viewBox="0 0 216 128"
            fill="none"
        >
            <g filter="url(#filter0_d_159_4976)">
                <path
                    d="M135.687 94.6123H81.1448C78.8192 94.6123 76.9365 92.7296 76.9365 90.404V15.596C76.9365 13.2704 78.8192 11.3877 81.1448 11.3877H135.687C138.012 11.3877 139.895 13.2704 139.895 15.596V90.404C139.895 92.7296 138.012 94.6123 135.687 94.6123Z"
                    fill="url(#paint0_linear_159_4976)"
                />
            </g>
            <path
                d="M115.504 74.4294H101.328C99.7501 74.4294 98.4766 73.1559 98.4766 71.5777C98.4766 69.9996 99.7501 68.7261 101.328 68.7261H115.504C117.082 68.7261 118.355 69.9996 118.355 71.5777C118.355 73.1559 117.082 74.4294 115.504 74.4294Z"
                fill="#D5DDEA"
            />
            <path
                d="M122.009 80.6029H94.7939C93.9633 80.6029 93.2988 79.9384 93.2988 79.1078C93.2988 78.2773 93.9633 77.6128 94.7939 77.6128H122.009C122.84 77.6128 123.504 78.2773 123.504 79.1078C123.504 79.9107 122.84 80.6029 122.009 80.6029Z"
                fill="#D5DDEA"
            />
            <path
                d="M127.021 41.0679C127.021 46.6605 124.557 51.6716 120.653 55.077C117.386 57.9287 113.095 59.6453 108.416 59.6453C103.737 59.6453 99.4453 57.9287 96.1784 55.0494C92.2746 51.644 89.8105 46.6328 89.8105 41.0402C89.8105 30.7686 98.1441 22.4351 108.416 22.4351C118.687 22.4351 127.021 30.7963 127.021 41.0679Z"
                fill="#D9DEEA"
            />
            <g filter="url(#filter1_d_159_4976)">
                <path
                    d="M61.5982 81.7387H26.5198C24.1942 81.7387 22.3115 79.856 22.3115 77.5304V28.4705C22.3115 26.1449 24.1942 24.2622 26.5198 24.2622H61.5982C63.9238 24.2622 65.8065 26.1449 65.8065 28.4705V77.5581C65.8065 79.8837 63.9238 81.7387 61.5982 81.7387Z"
                    fill="url(#paint1_linear_159_4976)"
                />
            </g>
            <path
                d="M48.9453 67.8123H39.1444C38.0647 67.8123 37.1787 66.9263 37.1787 65.8466C37.1787 64.7668 38.0647 63.8809 39.1444 63.8809H48.9453C50.0251 63.8809 50.911 64.7668 50.911 65.8466C50.911 66.9263 50.0251 67.8123 48.9453 67.8123Z"
                fill="#D5DDEA"
            />
            <path
                d="M53.4581 72.0756H34.6592C34.0777 72.0756 33.6348 71.605 33.6348 71.0512C33.6348 70.4698 34.1054 70.0269 34.6592 70.0269H53.4581C54.0395 70.0269 54.4824 70.4975 54.4824 71.0512C54.4824 71.605 54.0118 72.0756 53.4581 72.0756Z"
                fill="#D5DDEA"
            />
            <path
                d="M56.892 44.7487C56.892 48.6248 55.1754 52.0856 52.4899 54.4389C50.2196 56.4046 47.2849 57.5951 44.0456 57.5951C40.8063 57.5951 37.8439 56.4046 35.6013 54.4389C32.9158 52.0856 31.1992 48.6248 31.1992 44.7487C31.1992 37.6611 36.9579 31.9023 44.0456 31.9023C51.1333 31.9023 56.892 37.6611 56.892 44.7487Z"
                fill="#D9DEEA"
            />
            <g filter="url(#filter2_d_159_4976)">
                <path
                    d="M189.481 81.7387H154.403C152.077 81.7387 150.194 79.856 150.194 77.5304V28.4705C150.194 26.1449 152.077 24.2622 154.403 24.2622H189.481C191.807 24.2622 193.689 26.1449 193.689 28.4705V77.5581C193.689 79.8837 191.807 81.7387 189.481 81.7387Z"
                    fill="url(#paint2_linear_159_4976)"
                />
            </g>
            <path
                d="M176.828 67.8123H167.027C165.947 67.8123 165.062 66.9263 165.062 65.8466C165.062 64.7668 165.947 63.8809 167.027 63.8809H176.828C177.908 63.8809 178.794 64.7668 178.794 65.8466C178.822 66.9263 177.936 67.8123 176.828 67.8123Z"
                fill="#D5DDEA"
            />
            <path
                d="M181.341 72.0756H162.542C161.961 72.0756 161.518 71.605 161.518 71.0512C161.518 70.4698 161.988 70.0269 162.542 70.0269H181.341C181.922 70.0269 182.365 70.4975 182.365 71.0512C182.365 71.605 181.922 72.0756 181.341 72.0756Z"
                fill="#D5DDEA"
            />
            <path
                d="M184.802 44.7487C184.802 48.6248 183.086 52.0856 180.4 54.4389C178.13 56.4046 175.195 57.5951 171.956 57.5951C168.716 57.5951 165.754 56.4046 163.511 54.4389C160.826 52.0856 159.109 48.6248 159.109 44.7487C159.109 37.6611 164.868 31.9023 171.956 31.9023C179.043 31.9023 184.802 37.6611 184.802 44.7487Z"
                fill="#D9DEEA"
            />
            <mask
                id="mask0_159_4976"
                maskUnits="userSpaceOnUse"
                x="159"
                y="31"
                width="26"
                height="27"
            >
                <circle cx="172.102" cy="44.8014" r="12.8434" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_159_4976)">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M179.396 38.833C179.482 38.833 179.552 38.9027 179.552 38.9887V41.8481C179.552 41.934 179.482 42.0037 179.396 42.0037C179.31 42.0037 179.24 41.934 179.24 41.8481V38.9887C179.24 38.9027 179.31 38.833 179.396 38.833Z"
                    fill="#C2C8D6"
                />
                <rect
                    x="178.236"
                    y="41.6157"
                    width="2.31845"
                    height="2.31845"
                    fill="#D5DDEA"
                />
                <g filter="url(#filter3_d_159_4976)">
                    <path
                        d="M174.372 40.9976L181.482 44.3135V57.6904H174.372V40.9976Z"
                        fill="url(#paint3_linear_159_4976)"
                    />
                </g>
                <rect
                    x="175.918"
                    y="45.3257"
                    width="1.7002"
                    height="1.23651"
                    rx="0.618254"
                    fill="#C2C8D6"
                />
                <rect
                    x="175.918"
                    y="47.6431"
                    width="1.7002"
                    height="1.39107"
                    rx="0.695536"
                    fill="#C2C8D6"
                />
                <rect
                    x="175.918"
                    y="49.9614"
                    width="1.7002"
                    height="1.39107"
                    rx="0.695536"
                    fill="#C2C8D6"
                />
                <rect
                    x="175.918"
                    y="52.4355"
                    width="1.7002"
                    height="1.39107"
                    rx="0.695536"
                    fill="#C2C8D6"
                />
                <rect
                    x="175.918"
                    y="54.7529"
                    width="1.7002"
                    height="1.39107"
                    rx="0.695536"
                    fill="#C2C8D6"
                />
                <rect
                    x="178.391"
                    y="45.3257"
                    width="1.54564"
                    height="1.23651"
                    rx="0.618254"
                    fill="#C2C8D6"
                />
                <rect
                    x="178.391"
                    y="47.6431"
                    width="1.54564"
                    height="1.39107"
                    rx="0.695536"
                    fill="#C2C8D6"
                />
                <rect
                    x="178.391"
                    y="49.9614"
                    width="1.54564"
                    height="1.39107"
                    rx="0.695536"
                    fill="#C2C8D6"
                />
                <rect
                    x="178.391"
                    y="52.4355"
                    width="1.54564"
                    height="1.39107"
                    rx="0.695536"
                    fill="#C2C8D6"
                />
                <rect
                    x="178.391"
                    y="54.7529"
                    width="1.54564"
                    height="1.39107"
                    rx="0.695536"
                    fill="#C2C8D6"
                />
                <g filter="url(#filter4_d_159_4976)">
                    <path
                        d="M168.189 35.5879H174.527V57.6905H168.189V35.5879Z"
                        fill="url(#paint4_linear_159_4976)"
                    />
                </g>
                <rect
                    x="169.271"
                    y="37.1333"
                    width="4.17322"
                    height="1.08195"
                    rx="0.540973"
                    fill="#C2C8D6"
                />
                <rect
                    x="169.271"
                    y="39.4521"
                    width="4.17322"
                    height="0.927382"
                    rx="0.463691"
                    fill="#C2C8D6"
                />
                <rect
                    x="169.271"
                    y="41.77"
                    width="4.17322"
                    height="0.927382"
                    rx="0.463691"
                    fill="#C2C8D6"
                />
                <rect
                    x="169.271"
                    y="44.0894"
                    width="4.17322"
                    height="0.927382"
                    rx="0.463691"
                    fill="#C2C8D6"
                />
                <rect
                    x="169.271"
                    y="46.4072"
                    width="4.17322"
                    height="0.927382"
                    rx="0.463691"
                    fill="#C2C8D6"
                />
                <rect
                    x="169.271"
                    y="48.7261"
                    width="4.17322"
                    height="0.927382"
                    rx="0.463691"
                    fill="#C2C8D6"
                />
                <rect
                    x="169.271"
                    y="51.0444"
                    width="4.17322"
                    height="0.927382"
                    rx="0.463691"
                    fill="#C2C8D6"
                />
                <rect
                    x="169.271"
                    y="53.3623"
                    width="4.17322"
                    height="0.927382"
                    rx="0.463691"
                    fill="#C2C8D6"
                />
                <g filter="url(#filter5_d_159_4976)">
                    <path
                        d="M164.635 43.7803H171.59V57.691H164.635V43.7803Z"
                        fill="url(#paint5_linear_159_4976)"
                    />
                </g>
                <rect
                    x="166.047"
                    y="45.0557"
                    width="1.62672"
                    height="1.3619"
                    rx="0.680952"
                    fill="#C2C8D6"
                />
                <rect
                    x="166.047"
                    y="47.4375"
                    width="1.62672"
                    height="1.3619"
                    rx="0.680952"
                    fill="#C2C8D6"
                />
                <rect
                    x="166.047"
                    y="49.8218"
                    width="1.62672"
                    height="1.3619"
                    rx="0.680952"
                    fill="#C2C8D6"
                />
                <rect
                    x="166.047"
                    y="52.2061"
                    width="1.62672"
                    height="1.3619"
                    rx="0.680952"
                    fill="#C2C8D6"
                />
                <path
                    d="M166.489 56.7642C166.489 55.8252 167.25 55.064 168.189 55.064V57.6915H166.489V56.7642Z"
                    fill="#C2C8D6"
                />
                <rect
                    x="168.506"
                    y="45.0557"
                    width="1.62672"
                    height="1.3619"
                    rx="0.680952"
                    fill="#C2C8D6"
                />
                <rect
                    x="168.506"
                    y="47.4375"
                    width="1.62672"
                    height="1.3619"
                    rx="0.680952"
                    fill="#C2C8D6"
                />
                <rect
                    x="168.506"
                    y="49.8218"
                    width="1.62672"
                    height="1.3619"
                    rx="0.680952"
                    fill="#C2C8D6"
                />
                <rect
                    x="168.506"
                    y="52.2061"
                    width="1.62672"
                    height="1.3619"
                    rx="0.680952"
                    fill="#C2C8D6"
                />
                <path
                    d="M168.189 55.064C169.128 55.064 169.89 55.8252 169.89 56.7642V57.6915H168.189V55.064Z"
                    fill="#C2C8D6"
                />
            </g>
            <mask
                id="mask1_159_4976"
                maskUnits="userSpaceOnUse"
                x="31"
                y="31"
                width="26"
                height="27"
            >
                <circle cx="44.0231" cy="44.8014" r="12.8434" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask1_159_4976)">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M51.3168 38.833C51.4027 38.833 51.4724 38.9027 51.4724 38.9887V41.8481C51.4724 41.934 51.4027 42.0037 51.3168 42.0037C51.2308 42.0037 51.1611 41.934 51.1611 41.8481V38.9887C51.1611 38.9027 51.2308 38.833 51.3168 38.833Z"
                    fill="#C2C8D6"
                />
                <rect
                    x="50.1572"
                    y="41.6157"
                    width="2.31845"
                    height="2.31845"
                    fill="#D5DDEA"
                />
                <g filter="url(#filter6_d_159_4976)">
                    <path
                        d="M46.293 40.9976L53.4029 44.3135V57.6904H46.293V40.9976Z"
                        fill="url(#paint6_linear_159_4976)"
                    />
                </g>
                <rect
                    x="47.8389"
                    y="45.3257"
                    width="1.7002"
                    height="1.23651"
                    rx="0.618254"
                    fill="#C2C8D6"
                />
                <rect
                    x="47.8389"
                    y="47.6431"
                    width="1.7002"
                    height="1.39107"
                    rx="0.695536"
                    fill="#C2C8D6"
                />
                <rect
                    x="47.8389"
                    y="49.9614"
                    width="1.7002"
                    height="1.39107"
                    rx="0.695536"
                    fill="#C2C8D6"
                />
                <rect
                    x="47.8389"
                    y="52.4355"
                    width="1.7002"
                    height="1.39107"
                    rx="0.695536"
                    fill="#C2C8D6"
                />
                <rect
                    x="47.8389"
                    y="54.7529"
                    width="1.7002"
                    height="1.39107"
                    rx="0.695536"
                    fill="#C2C8D6"
                />
                <rect
                    x="50.3115"
                    y="45.3257"
                    width="1.54564"
                    height="1.23651"
                    rx="0.618254"
                    fill="#C2C8D6"
                />
                <rect
                    x="50.3115"
                    y="47.6431"
                    width="1.54564"
                    height="1.39107"
                    rx="0.695536"
                    fill="#C2C8D6"
                />
                <rect
                    x="50.3115"
                    y="49.9614"
                    width="1.54564"
                    height="1.39107"
                    rx="0.695536"
                    fill="#C2C8D6"
                />
                <rect
                    x="50.3115"
                    y="52.4355"
                    width="1.54564"
                    height="1.39107"
                    rx="0.695536"
                    fill="#C2C8D6"
                />
                <rect
                    x="50.3115"
                    y="54.7529"
                    width="1.54564"
                    height="1.39107"
                    rx="0.695536"
                    fill="#C2C8D6"
                />
                <g filter="url(#filter7_d_159_4976)">
                    <path
                        d="M40.1104 35.5879H46.4475V57.6905H40.1104V35.5879Z"
                        fill="url(#paint7_linear_159_4976)"
                    />
                </g>
                <rect
                    x="41.1924"
                    y="37.1333"
                    width="4.17322"
                    height="1.08195"
                    rx="0.540973"
                    fill="#C2C8D6"
                />
                <rect
                    x="41.1924"
                    y="39.4521"
                    width="4.17322"
                    height="0.927382"
                    rx="0.463691"
                    fill="#C2C8D6"
                />
                <rect
                    x="41.1924"
                    y="41.77"
                    width="4.17322"
                    height="0.927382"
                    rx="0.463691"
                    fill="#C2C8D6"
                />
                <rect
                    x="41.1924"
                    y="44.0894"
                    width="4.17322"
                    height="0.927382"
                    rx="0.463691"
                    fill="#C2C8D6"
                />
                <rect
                    x="41.1924"
                    y="46.4072"
                    width="4.17322"
                    height="0.927382"
                    rx="0.463691"
                    fill="#C2C8D6"
                />
                <rect
                    x="41.1924"
                    y="48.7261"
                    width="4.17322"
                    height="0.927382"
                    rx="0.463691"
                    fill="#C2C8D6"
                />
                <rect
                    x="41.1924"
                    y="51.0444"
                    width="4.17322"
                    height="0.927382"
                    rx="0.463691"
                    fill="#C2C8D6"
                />
                <rect
                    x="41.1924"
                    y="53.3623"
                    width="4.17322"
                    height="0.927382"
                    rx="0.463691"
                    fill="#C2C8D6"
                />
                <g filter="url(#filter8_d_159_4976)">
                    <path
                        d="M36.5557 43.7803H43.511V57.691H36.5557V43.7803Z"
                        fill="url(#paint8_linear_159_4976)"
                    />
                </g>
                <rect
                    x="37.9678"
                    y="45.0557"
                    width="1.62672"
                    height="1.3619"
                    rx="0.680952"
                    fill="#C2C8D6"
                />
                <rect
                    x="37.9678"
                    y="47.4375"
                    width="1.62672"
                    height="1.3619"
                    rx="0.680952"
                    fill="#C2C8D6"
                />
                <rect
                    x="37.9678"
                    y="49.8218"
                    width="1.62672"
                    height="1.3619"
                    rx="0.680952"
                    fill="#C2C8D6"
                />
                <rect
                    x="37.9678"
                    y="52.2061"
                    width="1.62672"
                    height="1.3619"
                    rx="0.680952"
                    fill="#C2C8D6"
                />
                <path
                    d="M38.4102 56.7642C38.4102 55.8252 39.1714 55.064 40.1104 55.064V57.6915H38.4102V56.7642Z"
                    fill="#C2C8D6"
                />
                <rect
                    x="40.4268"
                    y="45.0557"
                    width="1.62672"
                    height="1.3619"
                    rx="0.680952"
                    fill="#C2C8D6"
                />
                <rect
                    x="40.4268"
                    y="47.4375"
                    width="1.62672"
                    height="1.3619"
                    rx="0.680952"
                    fill="#C2C8D6"
                />
                <rect
                    x="40.4268"
                    y="49.8218"
                    width="1.62672"
                    height="1.3619"
                    rx="0.680952"
                    fill="#C2C8D6"
                />
                <rect
                    x="40.4268"
                    y="52.2061"
                    width="1.62672"
                    height="1.3619"
                    rx="0.680952"
                    fill="#C2C8D6"
                />
                <path
                    d="M40.1104 55.064C41.0493 55.064 41.8106 55.8252 41.8106 56.7642V57.6915H40.1104V55.064Z"
                    fill="#C2C8D6"
                />
            </g>
            <mask
                id="mask2_159_4976"
                maskUnits="userSpaceOnUse"
                x="89"
                y="22"
                width="39"
                height="38"
            >
                <circle cx="108.49" cy="40.9585" r="18.6069" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask2_159_4976)">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M119.057 32.312C119.181 32.312 119.282 32.413 119.282 32.5375V36.6801C119.282 36.8047 119.181 36.9056 119.057 36.9056C118.932 36.9056 118.831 36.8047 118.831 36.6801V32.5375C118.831 32.413 118.932 32.312 119.057 32.312Z"
                    fill="#C2C8D6"
                />
                <rect
                    x="117.378"
                    y="36.3438"
                    width="3.35887"
                    height="3.35887"
                    fill="#D5DDEA"
                />
                <g filter="url(#filter9_d_159_4976)">
                    <path
                        d="M111.779 35.4492L122.08 40.2532V59.6331H111.779V35.4492Z"
                        fill="url(#paint9_linear_159_4976)"
                    />
                </g>
                <rect
                    x="114.019"
                    y="41.7183"
                    width="2.46317"
                    height="1.7914"
                    rx="0.895698"
                    fill="#C2C8D6"
                />
                <rect
                    x="114.019"
                    y="45.0771"
                    width="2.46317"
                    height="2.01532"
                    rx="1.00766"
                    fill="#C2C8D6"
                />
                <rect
                    x="114.019"
                    y="48.4355"
                    width="2.46317"
                    height="2.01532"
                    rx="1.00766"
                    fill="#C2C8D6"
                />
                <rect
                    x="114.019"
                    y="52.0195"
                    width="2.46317"
                    height="2.01532"
                    rx="1.00766"
                    fill="#C2C8D6"
                />
                <rect
                    x="114.019"
                    y="55.3774"
                    width="2.46317"
                    height="2.01532"
                    rx="1.00766"
                    fill="#C2C8D6"
                />
                <rect
                    x="117.602"
                    y="41.7183"
                    width="2.23925"
                    height="1.7914"
                    rx="0.895698"
                    fill="#C2C8D6"
                />
                <rect
                    x="117.602"
                    y="45.0771"
                    width="2.23925"
                    height="2.01532"
                    rx="1.00766"
                    fill="#C2C8D6"
                />
                <rect
                    x="117.602"
                    y="48.4355"
                    width="2.23925"
                    height="2.01532"
                    rx="1.00766"
                    fill="#C2C8D6"
                />
                <rect
                    x="117.602"
                    y="52.0195"
                    width="2.23925"
                    height="2.01532"
                    rx="1.00766"
                    fill="#C2C8D6"
                />
                <rect
                    x="117.602"
                    y="55.3774"
                    width="2.23925"
                    height="2.01532"
                    rx="1.00766"
                    fill="#C2C8D6"
                />
                <g filter="url(#filter10_d_159_4976)">
                    <path
                        d="M102.822 27.6113H112.003V59.6325H102.822V27.6113Z"
                        fill="url(#paint10_linear_159_4976)"
                    />
                </g>
                <rect
                    x="104.39"
                    y="29.8491"
                    width="6.04596"
                    height="1.56747"
                    rx="0.783736"
                    fill="#C2C8D6"
                />
                <rect
                    x="104.39"
                    y="33.21"
                    width="6.04596"
                    height="1.34355"
                    rx="0.671774"
                    fill="#C2C8D6"
                />
                <rect
                    x="104.39"
                    y="36.5684"
                    width="6.04596"
                    height="1.34355"
                    rx="0.671774"
                    fill="#C2C8D6"
                />
                <rect
                    x="104.39"
                    y="39.9268"
                    width="6.04596"
                    height="1.34355"
                    rx="0.671774"
                    fill="#C2C8D6"
                />
                <rect
                    x="104.39"
                    y="43.2852"
                    width="6.04596"
                    height="1.34355"
                    rx="0.671774"
                    fill="#C2C8D6"
                />
                <rect
                    x="104.39"
                    y="46.646"
                    width="6.04596"
                    height="1.34355"
                    rx="0.671774"
                    fill="#C2C8D6"
                />
                <rect
                    x="104.39"
                    y="50.0034"
                    width="6.04596"
                    height="1.34355"
                    rx="0.671774"
                    fill="#C2C8D6"
                />
                <rect
                    x="104.39"
                    y="53.3623"
                    width="6.04596"
                    height="1.34355"
                    rx="0.671774"
                    fill="#C2C8D6"
                />
                <g filter="url(#filter11_d_159_4976)">
                    <path
                        d="M97.6719 39.4785H107.748V59.6317H97.6719V39.4785Z"
                        fill="url(#paint11_linear_159_4976)"
                    />
                </g>
                <rect
                    x="99.7178"
                    y="41.3291"
                    width="2.35671"
                    height="1.97306"
                    rx="0.986532"
                    fill="#C2C8D6"
                />
                <rect
                    x="99.7178"
                    y="44.7808"
                    width="2.35671"
                    height="1.97306"
                    rx="0.986532"
                    fill="#C2C8D6"
                />
                <rect
                    x="99.7178"
                    y="48.2329"
                    width="2.35671"
                    height="1.97306"
                    rx="0.986532"
                    fill="#C2C8D6"
                />
                <rect
                    x="99.7178"
                    y="51.687"
                    width="2.35671"
                    height="1.97306"
                    rx="0.986532"
                    fill="#C2C8D6"
                />
                <path
                    d="M100.358 57.8281C100.358 56.7236 101.254 55.8281 102.358 55.8281H102.822V59.6348H100.358V57.8281Z"
                    fill="#C2C8D6"
                />
                <rect
                    x="103.28"
                    y="41.3291"
                    width="2.35671"
                    height="1.97306"
                    rx="0.986532"
                    fill="#C2C8D6"
                />
                <rect
                    x="103.28"
                    y="44.7808"
                    width="2.35671"
                    height="1.97306"
                    rx="0.986532"
                    fill="#C2C8D6"
                />
                <rect
                    x="103.28"
                    y="48.2329"
                    width="2.35671"
                    height="1.97306"
                    rx="0.986532"
                    fill="#C2C8D6"
                />
                <rect
                    x="103.28"
                    y="51.687"
                    width="2.35671"
                    height="1.97306"
                    rx="0.986532"
                    fill="#C2C8D6"
                />
                <path
                    d="M102.822 55.8281H103.285C104.39 55.8281 105.285 56.7236 105.285 57.8281V59.6348H102.822V55.8281Z"
                    fill="#C2C8D6"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_159_4976"
                    x="54.9365"
                    y="0.387695"
                    width="106.958"
                    height="127.225"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="11" />
                    <feGaussianBlur stdDeviation="11" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_159_4976"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_159_4976"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter1_d_159_4976"
                    x="0.311523"
                    y="13.2622"
                    width="87.4951"
                    height="101.477"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="11" />
                    <feGaussianBlur stdDeviation="11" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_159_4976"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_159_4976"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter2_d_159_4976"
                    x="128.194"
                    y="13.2622"
                    width="87.4951"
                    height="101.477"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="11" />
                    <feGaussianBlur stdDeviation="11" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_159_4976"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_159_4976"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter3_d_159_4976"
                    x="152.372"
                    y="29.9976"
                    width="51.1104"
                    height="60.6929"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="11" />
                    <feGaussianBlur stdDeviation="11" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_159_4976"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_159_4976"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter4_d_159_4976"
                    x="146.189"
                    y="24.5879"
                    width="50.3369"
                    height="66.1025"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="11" />
                    <feGaussianBlur stdDeviation="11" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_159_4976"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_159_4976"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter5_d_159_4976"
                    x="142.635"
                    y="32.7803"
                    width="50.9551"
                    height="57.9106"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="11" />
                    <feGaussianBlur stdDeviation="11" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_159_4976"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_159_4976"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter6_d_159_4976"
                    x="24.293"
                    y="29.9976"
                    width="51.1104"
                    height="60.6929"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="11" />
                    <feGaussianBlur stdDeviation="11" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_159_4976"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_159_4976"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter7_d_159_4976"
                    x="18.1104"
                    y="24.5879"
                    width="50.3369"
                    height="66.1025"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="11" />
                    <feGaussianBlur stdDeviation="11" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_159_4976"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_159_4976"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter8_d_159_4976"
                    x="14.5557"
                    y="32.7803"
                    width="50.9551"
                    height="57.9106"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="11" />
                    <feGaussianBlur stdDeviation="11" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_159_4976"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_159_4976"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter9_d_159_4976"
                    x="89.7793"
                    y="24.4492"
                    width="54.3008"
                    height="68.1841"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="11" />
                    <feGaussianBlur stdDeviation="11" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_159_4976"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_159_4976"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter10_d_159_4976"
                    x="80.8223"
                    y="16.6113"
                    width="53.1807"
                    height="76.021"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="11" />
                    <feGaussianBlur stdDeviation="11" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_159_4976"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_159_4976"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter11_d_159_4976"
                    x="75.6719"
                    y="28.4785"
                    width="54.0762"
                    height="64.1533"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="11" />
                    <feGaussianBlur stdDeviation="11" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_159_4976"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_159_4976"
                        result="shape"
                    />
                </filter>
                <linearGradient
                    id="paint0_linear_159_4976"
                    x1="108.395"
                    y1="9.46263"
                    x2="108.395"
                    y2="95.5095"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#FDFEFF" />
                    <stop offset="0.9964" stop-color="#ECF0F5" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_159_4976"
                    x1="44.0448"
                    y1="22.9327"
                    x2="44.0448"
                    y2="82.3583"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#FDFEFF" />
                    <stop offset="0.9964" stop-color="#ECF0F5" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_159_4976"
                    x1="171.928"
                    y1="22.9327"
                    x2="171.928"
                    y2="82.3583"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#FDFEFF" />
                    <stop offset="0.9964" stop-color="#ECF0F5" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_159_4976"
                    x1="177.925"
                    y1="40.6114"
                    x2="177.925"
                    y2="57.8704"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#FDFEFF" />
                    <stop offset="0.9964" stop-color="#ECF0F5" />
                </linearGradient>
                <linearGradient
                    id="paint4_linear_159_4976"
                    x1="171.356"
                    y1="35.0766"
                    x2="171.356"
                    y2="57.9288"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#FDFEFF" />
                    <stop offset="0.9964" stop-color="#ECF0F5" />
                </linearGradient>
                <linearGradient
                    id="paint5_linear_159_4976"
                    x1="168.11"
                    y1="43.4585"
                    x2="168.11"
                    y2="57.841"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#FDFEFF" />
                    <stop offset="0.9964" stop-color="#ECF0F5" />
                </linearGradient>
                <linearGradient
                    id="paint6_linear_159_4976"
                    x1="49.8456"
                    y1="40.6114"
                    x2="49.8456"
                    y2="57.8704"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#FDFEFF" />
                    <stop offset="0.9964" stop-color="#ECF0F5" />
                </linearGradient>
                <linearGradient
                    id="paint7_linear_159_4976"
                    x1="43.2768"
                    y1="35.0766"
                    x2="43.2768"
                    y2="57.9288"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#FDFEFF" />
                    <stop offset="0.9964" stop-color="#ECF0F5" />
                </linearGradient>
                <linearGradient
                    id="paint8_linear_159_4976"
                    x1="40.0311"
                    y1="43.4585"
                    x2="40.0311"
                    y2="57.841"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#FDFEFF" />
                    <stop offset="0.9964" stop-color="#ECF0F5" />
                </linearGradient>
                <linearGradient
                    id="paint9_linear_159_4976"
                    x1="116.926"
                    y1="34.8898"
                    x2="116.926"
                    y2="59.8938"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#FDFEFF" />
                    <stop offset="0.9964" stop-color="#ECF0F5" />
                </linearGradient>
                <linearGradient
                    id="paint10_linear_159_4976"
                    x1="107.41"
                    y1="26.8706"
                    x2="107.41"
                    y2="59.9777"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#FDFEFF" />
                    <stop offset="0.9964" stop-color="#ECF0F5" />
                </linearGradient>
                <linearGradient
                    id="paint11_linear_159_4976"
                    x1="102.707"
                    y1="39.0124"
                    x2="102.707"
                    y2="59.849"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#FDFEFF" />
                    <stop offset="0.9964" stop-color="#ECF0F5" />
                </linearGradient>
            </defs>
        </svg>
    );
};

const NoRecords = () => {
    return (
        <div
            style={{
                width: "100%",
                height: "526px",
                padding: "16px",
                background: "white",
                justifyContent: "center",
                alignItems: "center",
                gap: "32px",
                display: "inline-flex",
            }}
        >
            <div
                style={{
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "inline-flex",
                }}
            >
                <NoRecordSVG />
                <div
                    style={{
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: "16px",
                        display: "flex",
                    }}
                >
                    <div
                        style={{
                            color: "#141933",
                            fontSize: "18px",
                            fontFamily: "Lato",
                            fontWeight: "700",
                            lineHeight: "24px",
                            wordWrap: "break-word",
                        }}
                    >
                        No Records
                    </div>
                    <div
                        style={{
                            width: "352px",
                            textAlign: "center",
                            color: "#505573",
                            fontSize: "15px",
                            fontFamily: "Lato",
                            fontStyle: "italic",
                            fontWeight: "400",
                            lineHeight: "20px",
                            wordWrap: "break-word",
                        }}
                    >
                        No properties meet your search requirements
                    </div>
                </div>
            </div>
        </div>
    );
};

const SearchForm = (props) => {
    const { handleSearch, filters, setFilters } = props;
    const dispatch = useDispatch();

    const { countries, loadingHotels } = useSelector((state) => ({
        countries: state?.countriesReducer?.countries ?? [],
        loadingHotels: state?.exploreMappingReducer?.loadingHotels,
    }));
    const { handleSubmit } = useForm();
    useEffect(() => {
        dispatch(getCountries());
    }, []);

    const inputValue = (name) => (e) => {
        if (e.target.value != "") {
            filters[name] = e.target.value;
        } else {
            filters[name] = null;
        }
        setFilters({ ...filters });
    };

    const getSelectedVal = (value) => {
        setFilters({ ...filters, hotel_name: value });
    };

    return (
        <form
            className="search-form"
            onSubmit={handleSubmit(handleSearch)}
            id="create-search-form"
            style={{ width: "100%" }}
        >
            <div style={{ display: "flex" }}>
                <div style={{ flex: 1, marginRight: "10px" }}>
                    <input
                        type="text"
                        className="form-control"
                        name="hotel_code"
                        placeholder="Inventory Hotel code"
                        autoComplete="off"
                        onChange={inputValue("hotel_code")}
                        style={{ borderRadius: "8px", width: "100%" }}
                    />
                </div>
                <div style={{ flex: 1, marginRight: "10px" }}>
                    <InputAutoComplete
                        isClearable={true}
                        value={filters?.hotel_name}
                        placeholder="Hotel name"
                        name="hotel_name"
                        onChange={getSelectedVal}
                        styles={{
                            control: (base) => ({
                                ...base,
                                height: 49,
                                minHeight: 49,
                                borderRadius: 8,
                            }),
                        }}
                    />
                </div>
                <div style={{ flex: 1, marginRight: "10px" }}>
                    <Select
                        options={countries?.map((e) => ({
                            iso2: e?.iso2,
                            value: e?.id,
                            label: e?.name,
                        }))}
                        name="country"
                        value={filters?.country}
                        placeholder="Country"
                        styles={{
                            control: (base) => ({
                                ...base,
                                height: 49,
                                minHeight: 49,
                                borderRadius: 8,
                            }),
                        }}
                        isClearable={true}
                        onChange={(e) => {
                            setFilters({ ...filters, country: e });
                        }}
                    />
                </div>
                <div style={{ flex: 1, marginRight: "10px" }}>
                    <input
                        value={filters?.city}
                        type="text"
                        className="form-control"
                        name="city"
                        placeholder="City"
                        autoComplete="off"
                        onChange={inputValue("city")}
                        style={{ borderRadius: "8px", width: "100%" }}
                    />
                </div>
                <div>
                    <button
                        disabled={loadingHotels}
                        type="submit"
                        className="btn btn-primary"
                        style={{
                            width: "49px",
                            height: "49px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                        >
                            <path
                                d="M17.5 17.5L12.5001 12.5M14.1667 8.33333C14.1667 11.555 11.555 14.1667 8.33333 14.1667C5.11167 14.1667 2.5 11.555 2.5 8.33333C2.5 5.11167 5.11167 2.5 8.33333 2.5C11.555 2.5 14.1667 5.11167 14.1667 8.33333Z"
                                stroke="#F5F6FA"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                        {loadingHotels && (
                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        )}
                    </button>
                </div>
            </div>
        </form>
    );
};

const NoValidHotelsFound = () => {
    const history = useHistory();
    return (
        <div
            style={{
                width: "100%",
                height: "425px",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "40px",
                display: "inline-flex",
            }}
        >
            <div
                style={{
                    width: "101.72px",
                    height: "95.72px",
                    position: "relative",
                }}
            >
                <NoValidHotelsSVG />
            </div>

            <div
                style={{
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "24px",
                    display: "flex",
                }}
            >
                <div
                    style={{
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: "16px",
                        display: "flex",
                    }}
                >
                    <div
                        style={{
                            color: "#141933",
                            fontSize: "18px",
                            fontFamily: "Lato",
                            fontWeight: "700",
                            lineHeight: "24px",
                            wordWrap: "break-word",
                        }}
                    >
                        No Valid Hotels
                    </div>
                    <div
                        style={{
                            width: "392px",
                            textAlign: "center",
                            color: "#505573",
                            fontSize: "15px",
                            fontFamily: "Lato",
                            fontStyle: "italic",
                            fontWeight: "400",
                            lineHeight: "20px",
                            wordWrap: "break-word",
                        }}
                    >
                        Your inventory does not contain any valid hotels.
                        Consider either cleaning your inventory or using a
                        different one.
                    </div>
                </div>
            </div>

            <button
                className="btn btn-primary"
                onClick={() => {
                    history.push("/catalogs-manager");
                }}
                style={{
                    height: "52px",
                    paddingLeft: "24px",
                    paddingRight: "24px",
                    paddingTop: "16px",
                    paddingBottom: "16px",
                    background:
                        "linear-gradient(0deg, #6275E7 0%, #6275E7 100%), linear-gradient(0deg,  0%,  100%)",
                    borderRadius: "8px",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "16px",
                    display: "inline-flex",
                }}
            >
                <UploadIcon />
                <div
                    style={{
                        color: "white",
                        fontSize: "15px",
                        fontFamily: "Lato",
                        fontWeight: "500",
                        wordWrap: "break-word",
                    }}
                >
                    Upload Inventory
                </div>
            </button>
        </div>
    );
};

const NoInventoryFound = () => {
    const history = useHistory();
    return (
        <div
            style={{
                width: "100%",
                height: "425px",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "40px",
                display: "inline-flex",
            }}
        >
            <div
                style={{
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "24px",
                    display: "flex",
                }}
            >
                <UploadSvg />
                <div
                    style={{
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: "16px",
                        display: "flex",
                    }}
                >
                    <div
                        style={{
                            color: "#141933",
                            fontSize: "18px",
                            fontFamily: "Lato",
                            fontWeight: "700",
                            lineHeight: "24px",
                            wordWrap: "break-word",
                        }}
                    >
                        No Inventory Uploaded
                    </div>
                    <div
                        style={{
                            width: "352px",
                            textAlign: "center",
                            color: "#505573",
                            fontSize: "15px",
                            fontFamily: "Lato",
                            fontStyle: "italic",
                            fontWeight: "400",
                            lineHeight: "20px",
                            wordWrap: "break-word",
                        }}
                    >
                        Upload your inventory, and we'll automatically gather
                        property-related information for you
                    </div>
                </div>
            </div>
            <button
                className="btn btn-primary"
                onClick={() => {
                    history.push("/catalogs-manager");
                }}
                style={{
                    height: "52px",
                    paddingLeft: "24px",
                    paddingRight: "24px",
                    paddingTop: "16px",
                    paddingBottom: "16px",
                    background:
                        "linear-gradient(0deg, #6275E7 0%, #6275E7 100%), linear-gradient(0deg,  0%,  100%)",
                    borderRadius: "8px",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "16px",
                    display: "inline-flex",
                }}
            >
                <UploadIcon />
                <div
                    style={{
                        color: "white",
                        fontSize: "15px",
                        fontFamily: "Lato",
                        fontWeight: "500",
                        wordWrap: "break-word",
                    }}
                >
                    Upload Inventory
                </div>
            </button>
        </div>
    );
};

const NoValidHotelsSVG = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="109"
            height="121"
            viewBox="0 0 109 121"
            fill="none"
        >
            <circle cx="59.0001" cy="48.4998" r="47.8602" fill="#EAEEF9" />
            <path
                d="M77.5083 22.4254H52.0134V12.75H85.4332C85.4947 12.75 85.5561 12.75 85.6175 12.75C90.8394 12.8733 95.0783 17.1871 95.0783 22.4254H84.0202"
                fill="#CCD4E2"
            />
            <g filter="url(#filter0_d_125_3035)">
                <path
                    d="M86.3546 12.75C84.3888 12.8733 82.5458 13.6744 81.2556 15.0302C81.1328 15.1535 81.0099 15.2767 80.8871 15.4616C79.7198 16.8174 78.8597 18.6046 78.8597 20.515V78.0746C78.8597 83.4361 74.5594 87.75 69.2147 87.75H22.2795V20.0836C22.4024 18.1115 23.1396 16.3244 24.4297 15.0302C25.8427 13.6128 27.7471 12.75 29.8973 12.75H77.1396H86.1089C86.1703 12.75 86.2318 12.75 86.3546 12.75Z"
                    fill="url(#paint0_linear_125_3035)"
                />
            </g>
            <path
                d="M69.5219 25.9382H55.5765C55.0851 25.9382 54.655 25.5068 54.655 25.0138C54.655 24.5207 55.0851 24.0894 55.5765 24.0894H69.5219C70.0134 24.0894 70.4434 24.5207 70.4434 25.0138C70.4434 25.5068 70.0748 25.9382 69.5219 25.9382Z"
                fill="#B3BFD3"
            />
            <path
                d="M49.003 25.9382H30.8187C30.3273 25.9382 29.8972 25.5068 29.8972 25.0138C29.8972 24.5207 30.3273 24.0894 30.8187 24.0894H49.0645C49.5559 24.0894 49.986 24.5207 49.986 25.0138C49.986 25.5068 49.5559 25.9382 49.003 25.9382Z"
                fill="#B3BFD3"
            />
            <path
                d="M60.6755 35.0588H42.5526C42.0611 35.0588 41.6311 34.6274 41.6311 34.1344C41.6311 33.6413 42.0611 33.21 42.5526 33.21H60.6755C61.1669 33.21 61.597 33.6413 61.597 34.1344C61.6584 34.6274 61.2284 35.0588 60.6755 35.0588Z"
                fill="#B3BFD3"
            />
            <path
                d="M36.7163 35.0588H30.8187C30.3273 35.0588 29.8972 34.6274 29.8972 34.1344C29.8972 33.6413 30.3273 33.21 30.8187 33.21H36.7163C37.2078 33.21 37.6378 33.6413 37.6378 34.1344C37.6378 34.6274 37.2078 35.0588 36.7163 35.0588Z"
                fill="#D4DBEC"
            />
            <path
                d="M69.5219 44.1799H58.4025C57.911 44.1799 57.481 43.7485 57.481 43.2555C57.481 42.7624 57.911 42.3311 58.4025 42.3311H69.5219C70.0134 42.3311 70.4434 42.7624 70.4434 43.2555C70.4434 43.7485 70.0748 44.1799 69.5219 44.1799Z"
                fill="#D4DBEC"
            />
            <path
                d="M53.0576 44.1799H30.8187C30.3273 44.1799 29.8972 43.7485 29.8972 43.2555C29.8972 42.7624 30.3273 42.3311 30.8187 42.3311H53.0576C53.5491 42.3311 53.9791 42.7624 53.9791 43.2555C53.9791 43.7485 53.6105 44.1799 53.0576 44.1799Z"
                fill="#B3BFD3"
            />
            <path
                d="M60.6756 53.3005H48.4503C47.9588 53.3005 47.5288 52.8691 47.5288 52.3761C47.5288 51.8831 47.9588 51.4517 48.4503 51.4517H60.6756C61.167 51.4517 61.5971 51.8831 61.5971 52.3761C61.6585 52.8691 61.2285 53.3005 60.6756 53.3005Z"
                fill="#B3BFD3"
            />
            <path
                d="M43.1054 53.3005H30.8187C30.3273 53.3005 29.8972 52.8691 29.8972 52.3761C29.8972 51.8831 30.3273 51.4517 30.8187 51.4517H43.1668C43.6583 51.4517 44.0883 51.8831 44.0883 52.3761C44.0883 52.8691 43.6583 53.3005 43.1054 53.3005Z"
                fill="#D4DBEC"
            />
            <path
                d="M69.5218 62.4216H60.9825C60.4911 62.4216 60.061 61.9902 60.061 61.4972C60.061 61.0041 60.4911 60.5728 60.9825 60.5728H69.5218C70.0132 60.5728 70.4433 61.0041 70.4433 61.4972C70.4433 61.9902 70.0747 62.4216 69.5218 62.4216Z"
                fill="#B3BFD3"
            />
            <path
                d="M55.4538 62.4216H41.1398C40.6483 62.4216 40.2183 61.9902 40.2183 61.4972C40.2183 61.0041 40.6483 60.5728 41.1398 60.5728H55.4538C55.9452 60.5728 56.3753 61.0041 56.3753 61.4972C56.3753 61.9902 55.9452 62.4216 55.4538 62.4216Z"
                fill="#D4DBEC"
            />
            <path
                d="M35.7948 62.4216H30.8187C30.3273 62.4216 29.8972 61.9902 29.8972 61.4972C29.8972 61.0041 30.3273 60.5728 30.8187 60.5728H35.7948C36.2863 60.5728 36.7163 61.0041 36.7163 61.4972C36.7163 61.9902 36.2863 62.4216 35.7948 62.4216Z"
                fill="#B3BFD3"
            />
            <path
                d="M60.6754 71.5422H52.1976C51.7062 71.5422 51.2761 71.1108 51.2761 70.6178C51.2761 70.1247 51.7062 69.6934 52.1976 69.6934H60.6754C61.1669 69.6934 61.5969 70.1247 61.5969 70.6178C61.6584 71.1108 61.2283 71.5422 60.6754 71.5422Z"
                fill="#B3BFD3"
            />
            <path
                d="M46.6071 71.5422H30.8187C30.3273 71.5422 29.8972 71.1108 29.8972 70.6178C29.8972 70.1247 30.3273 69.6934 30.8187 69.6934H46.6071C47.0986 69.6934 47.5286 70.1247 47.5286 70.6178C47.5286 71.1108 47.0986 71.5422 46.6071 71.5422Z"
                fill="#D4DBEC"
            />
            <path
                d="M48.5731 80.1084H60.9827C60.9827 82.2037 61.8427 84.1142 63.1943 85.4699C64.5458 86.8874 66.4502 87.6885 68.539 87.6885H12.696C10.6072 87.6885 8.70279 86.8257 7.35125 85.4699C5.99972 84.1142 5.13965 82.2037 5.13965 80.1084H41.8769C41.8769 80.1084 48.696 80.17 48.5731 80.1084Z"
                fill="#CCD4E2"
            />
            <defs>
                <filter
                    id="filter0_d_125_3035"
                    x="0.279541"
                    y="1.75"
                    width="108.075"
                    height="119"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="11" />
                    <feGaussianBlur stdDeviation="11" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_125_3035"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_125_3035"
                        result="shape"
                    />
                </filter>
                <linearGradient
                    id="paint0_linear_125_3035"
                    x1="54.2962"
                    y1="11.0152"
                    x2="54.2962"
                    y2="88.5585"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#FDFEFF" />
                    <stop offset="0.9964" stop-color="#ECF0F5" />
                </linearGradient>
            </defs>
        </svg>
    );
};

const UploadIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
        >
            <path
                d="M3.83341 13.8955C2.82843 13.2228 2.16675 12.0772 2.16675 10.777C2.16675 8.82404 3.65967 7.21976 5.56653 7.04316C5.95659 4.67046 8.01695 2.86035 10.5001 2.86035C12.9832 2.86035 15.0436 4.67046 15.4336 7.04316C17.3405 7.21976 18.8334 8.82404 18.8334 10.777C18.8334 12.0772 18.1717 13.2228 17.1667 13.8955M7.16675 13.6937L10.5001 10.3604M10.5001 10.3604L13.8334 13.6937M10.5001 10.3604V17.8604"
                stroke="#F5F6FA"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};

const UploadSvg = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="130"
            height="117"
            viewBox="0 0 130 117"
            fill="none"
        >
            <path
                d="M66 116.374C97.8674 116.374 123.66 90.4453 123.66 58.4536C123.66 26.4619 97.8674 0.625977 66 0.625977C34.1326 0.625977 8.33966 26.5551 8.33966 58.5469C8.33966 90.6319 34.2261 116.374 66 116.374Z"
                fill="#EAEEF9"
            />
            <g filter="url(#filter0_d_105_2699)">
                <path
                    d="M107.808 58.7098C107.808 67.1583 100.981 73.9907 92.4646 73.9907C92.3178 73.9907 91.0698 73.9907 81.8932 73.9907C75.5062 73.9907 65.3018 73.9907 49.0042 73.9907H41.2224C31.018 74.2111 22.8691 66.0563 22.8691 56.2119C22.8691 46.2941 31.0914 38.0659 41.4426 38.5801C50.3256 10.8101 90.9964 14.7038 94.4468 43.4289C102.082 44.3839 107.808 50.8489 107.808 58.7098Z"
                    fill="url(#paint0_linear_105_2699)"
                />
            </g>
            <path
                d="M92.4646 73.9905C100.907 73.9905 107.808 67.1582 107.808 58.7096C107.808 50.261 100.907 43.4287 92.4646 43.4287C84.0222 43.4287 77.1213 50.261 77.1213 58.7096C77.1213 67.1582 84.0222 73.9905 92.4646 73.9905Z"
                fill="url(#paint1_linear_105_2699)"
            />
            <path
                d="M67.4307 74.1376C82.4804 74.1376 94.7403 61.9423 94.7403 46.8818C94.7403 31.8213 82.4804 19.626 67.4307 19.626C52.3811 19.626 40.1211 31.8213 40.1211 46.8818C40.1211 61.9423 52.3076 74.1376 67.4307 74.1376Z"
                fill="url(#paint2_linear_105_2699)"
            />
            <path
                d="M107.026 28.6636C108.428 28.6636 109.643 27.4511 109.643 26.052C109.643 24.6529 108.428 23.4404 107.026 23.4404C105.624 23.4404 104.409 24.6529 104.409 26.052C104.409 27.4511 105.531 28.6636 107.026 28.6636Z"
                fill="#EAEEF9"
            />
            <path
                d="M64.3599 67.926L52.2832 80.1592C51.0252 81.4335 51.0252 83.4724 52.2832 84.7467C53.5411 86.021 55.5539 86.021 56.5603 84.7467L63.3535 77.8655L63.3535 96.2153C63.3535 97.9993 64.8631 99.2736 66.3727 99.2736C68.1338 99.2736 69.3918 97.7444 69.3918 96.2153L69.3918 77.8655L76.185 84.7467C77.443 86.021 79.4558 86.021 80.7137 84.7467C81.9717 83.4724 81.9717 81.4335 80.7138 80.4141L68.637 68.1809C67.6307 66.6517 65.6179 66.6517 64.3599 67.926Z"
                fill="url(#paint3_linear_105_2699)"
            />
            <defs>
                <filter
                    id="filter0_d_105_2699"
                    x="0.869141"
                    y="8.69238"
                    width="128.939"
                    height="98.3027"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="11" />
                    <feGaussianBlur stdDeviation="11" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_105_2699"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_105_2699"
                        result="shape"
                    />
                </filter>
                <linearGradient
                    id="paint0_linear_105_2699"
                    x1="65.3385"
                    y1="42.1323"
                    x2="65.3108"
                    y2="74.5804"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="white" />
                    <stop offset="0.9964" stop-color="#ECF0F5" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_105_2699"
                    x1="75.4886"
                    y1="40.1388"
                    x2="89.101"
                    y2="55.0159"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#BCCBE1" />
                    <stop offset="0.9942" stop-color="white" stop-opacity="0" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_105_2699"
                    x1="37.5638"
                    y1="34.3748"
                    x2="59.0054"
                    y2="42.7595"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#E2E8F0" />
                    <stop offset="0.9942" stop-color="white" stop-opacity="0" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_105_2699"
                    x1="80.4546"
                    y1="67.0001"
                    x2="52.2641"
                    y2="67.0923"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#B0BACC" />
                    <stop offset="1" stop-color="#969EAE" />
                </linearGradient>
            </defs>
        </svg>
    );
};
export default HotelsList;
