import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import {
    getPartnersInventoriesList,
    getPartnersInventoriesListPeriodique,
} from "../../redux/actions/PartnerActions";
import { Spinner, Card, Row, Col, Tooltip, OverlayTrigger } from "react-bootstrap";
import {
    getConnectors,
    getCredentials,
} from "../../redux/actions/ConnectorActions";
import {
    CREATE_INVENTORY,
    CREATE_CATALOG,
    STATUS_PROCESSING,
} from "../../utilis/const";
import { hasPermission, separator } from "../../utilis/functions";
import { useHistory } from "react-router-dom";
import { getCompletedSteps } from "../../redux/actions/AnalyticsActions";
import CatalogNoDataModal from "../Mapping/CatalogNoDataModal";
import moment from "moment";
import { MenuItem, ControlledMenu } from "@szhsin/react-menu";
import CSV_LOGO from "../../lotties/csv-upload.png";
import ChoiseConnectorCsv from "./AddPartnerInventory/index";
import ReactPaginate from "react-paginate";
import ConfirmAction from "../../components/ConfirmActionsModal";
import AddAttributes from "./AddAttributes";
import PartnerInventoryService from "../../services/PartnerInventoryService";
import { toast } from "react-toastify";
import fileDownload from "js-file-download";
import RunMappingModal from "../../components/RunMappingModal";

const spaceBetween = {
    display: "flex",
    justifyContent: "space-between",
};
const spanName = { color: "#5f76e8", fontWeight: "700" };
const hotelDetails = {
    color: "rgb(128, 134, 152)",
    font: '500 1rem / 16px Roboto, "Helvetica Neue", sans-serif',
    letterSpacing: "0.3px",
    fontWeight: "500",
    fontSize: "1rem",
    paddingTop: "5px",
};

const statusBadgeVariant = (status) => {
    switch (status) {
        case 1:
            return {
                color: "info",
                status: "Processing",
            };
        case 2:
            return {
                color: "success",
                status: "Done",
            };
        case 3:
            return {
                color: "danger",
                status: "Failed",
            };

        default:
            return {
                color: "",
                status: "",
            };
    }
};
function isFloat(number){return number % 1 !== 0}
const getPourcentage = (value,total)=>{
     if (total === 0) return 0;
     const pourcentage = (value /  total) * 100;
     if(isFloat(pourcentage)) 
        return pourcentage.toFixed(2);
     else return pourcentage
}
function PartnerInventoriesList(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const [filter, setFilter] = useState("");
    const [itemsPerPage, setItemsPerPage] = useState(12);
    const {
        partnersInventories,
        loading,
        loadingPartnerSteps,
        partnerSteps,
        loadingLastUpdateInventoriesPeriodique,
    } = useSelector((state) => ({
        partnersInventories: state.partnerReducer.partnersInventories ?? [],
        loadingLastUpdateInventoriesPeriodique:
            state.partnerReducer.loadingLastUpdateInventoriesPeriodique,
        loading: state.partnerReducer.loadingPartnersInventories ?? [],
        loadingAttributes: state.partnerReducer.loadingAttributes,
        loadingPartnerSteps: state.analyticsReducer.loadingPartnerSteps,
        partnerSteps: state.analyticsReducer.partnerSteps,
    }));
    const [openDialog, setOpenDialog] = useState(false);
    const [displayedData, setDisplayedData] = useState(partnersInventories);
    const [showConfirmRemoveModal, setShowConfirmRemoveModal] = useState(false);
    const [showAddAttributes, setShowAddAttributes] = useState(false);
    const [loadingRemove, setLoadingRemove] = useState(false);

    //Pagination
    const [currentElement, setCurrentElement] = useState();
    const [currentItems, setCurrentItems] = useState();
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [showRunMapping, setShowRunMapping] = useState(false);

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        setCurrentItems(displayedData.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(displayedData.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, filter, loading, displayedData]);
    useEffect(() => {
        dispatch(getPartnersInventoriesList());
        dispatch(getConnectors());
        dispatch(getCredentials());
        dispatch(getCompletedSteps());
    }, []);

    useEffect(() => {
        if (filter !== "") {
            const newData = partnersInventories.filter((element) => {
                let match = false;

                let stringValue = element?.partner_name?.toString() ?? "";
                match =
                    match ||
                    stringValue
                        ?.toLowerCase()
                        ?.search(filter?.toLowerCase()) !== -1;
                return match;
            });

            setDisplayedData(newData);
            setPageCount(0);
            setItemOffset(0);
        } else {
            setDisplayedData(partnersInventories);
        }
    }, [filter, loading, loadingLastUpdateInventoriesPeriodique]);

    const handlePageClick = (event) => {
        const newOffset =
            (event.selected * itemsPerPage) % displayedData.length;
        setItemOffset(newOffset);
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
        window.analytics.track("Add new partner inventory (click)", {
            userID: localStorage.getItem("userId"),
            companyID: localStorage.getItem("companyId"),
            companyName: localStorage.getItem("companyName"),
            firstName: localStorage.getItem("firstName"),
            lastName: localStorage.getItem("lastName"),
            email: localStorage.getItem("email"),
        });
    };
    const handleDelete = async () => {
        setLoadingRemove(true);
        try {
            let res = await PartnerInventoryService.deletePartnerInventories(
                currentElement?.partner_id
            );
            if (res.status === 200) {
                setLoadingRemove(false);
                setShowConfirmRemoveModal(false);
                dispatch(getPartnersInventoriesList());
            } else {
                toast.error(
                    "Something went wrong while deleting this partner inventory"
                );
                setShowConfirmRemoveModal(false);
                setLoadingRemove(false);
            }
        } catch (err) {
            toast.error(
                "Something went wrong while deleting this partner inventory"
            );
            setShowConfirmRemoveModal(false);
            setLoadingRemove(false);
        }
    };
    useEffect(() => {
        setInterval(() => {
            if (
                window.location?.pathname !== "/sign-in" &&
                window.location.pathname === "/partners-inventories"
            ) {
                dispatch(getPartnersInventoriesListPeriodique());
            }
        }, 10000);
    }, []);

    return (
        <>
            <Helmet>
                <title>Partners</title>
            </Helmet>
            <div className="page-wrapper" style={{ display: "block" }}>
                {loading || loadingPartnerSteps ? (
                    <div className="text-center">
                        <div className="lds-ripple">
                            <div className="lds-pos"></div>
                            <div className="lds-pos"></div>
                        </div>
                    </div>
                ) : partnerSteps?.has_inventory === false ? (
                    <CatalogNoDataModal
                        title="Ready!"
                        text2="Your dedicated workspace is almost ready to go !"
                        text3="First, you’ll need to upload your hotel inventory."
                        buttonText="Upload inventory"
                        handleClick={async () => {
                            history.push("/catalogs-manager");
                        }}
                        hideButton={
                            hasPermission(CREATE_INVENTORY) ? false : true
                        }
                    />
                ) : (
                    <>
                        <div className="page-breadcrumb">
                            <div
                                className="col-12 align-self-center"
                                style={{ padding: "0px" }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
                                        Partners content
                                    </h3>

                                    {hasPermission(CREATE_CATALOG) && (
                                        <button
                                            className="btn btn-info"
                                            onClick={handleOpenDialog}
                                            // ref={target}
                                        >
                                            Add new partner inventory
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <div
                                        className="card-body"
                                        style={{ padding: "0px" }}
                                    >
                                        <div className="search-input">
                                            <span className="hevo-web-icon search-icon hevo-search">
                                                <i className="fas fa-search"></i>
                                            </span>
                                            <input
                                                style={{ width: "90%" }}
                                                type="text"
                                                name="filter"
                                                value={filter}
                                                onChange={(e) =>
                                                    setFilter(e?.target?.value)
                                                }
                                                placeholder="Search Partner inventory"
                                                autoComplete="off"
                                                id="inte_input"
                                            />
                                        </div>
                                    </div>
                                </div>
                                {currentItems?.map((e, index) => {
                                    return (
                                        <div
                                            className="col-6 col-md-4 col-lg-4"
                                            key={index}
                                        >
                                            <Card
                                                className="card-destination"
                                                onClick={() =>
                                                    history.push(
                                                        `/partner-inventories/details/${e?.partner_id}`
                                                    )
                                                }
                                                style={{ cursor: "pointer" }}
                                            >
                                                <Row className="no-gutters">
                                                    <Col>
                                                        <Card.Body
                                                            style={{
                                                                padding: "18px",
                                                            }}
                                                        >
                                                            <Card.Title>
                                                                <div
                                                                    style={{
                                                                        ...spaceBetween,
                                                                    }}
                                                                >
                                                                    <span
                                                                        style={{...spanName}}
                                                                    >
                                                                        {e?.partner_name}
                                                                        <span
                                                                            text="white"
                                                                            className={`badge rounded-pill text-white bg-${statusBadgeVariant(e?.status)?.color }`}
                                                                            style={{
                                                                                fontWeight:"700",
                                                                                marginLeft: "4px",
                                                                            }}
                                                                        >
                                                                            {statusBadgeVariant( e.status)?.status}
                                                                        </span>
                                                                        { 
                                                                            e.status == 2 &&  e.cleaning_status == 1   ?
                                                                            <OverlayTrigger
                                                                                placement="top"
                                                                                delay={{ show: 250, hide: 400}}
                                                                                overlay={
                                                                                    <Tooltip id="button-tooltip">
                                                                                        Deep Address Clean +
                                                                                    </Tooltip>
                                                                                }
                                                                        >
                                                                            <span className="badge rounded-pill text-white bg-info" style={{fontWeight:"700",marginLeft: "4px",}}>
                                                                                 DCA +
                                                                                 ({ getPourcentage(e.total_cleaned_hotels2,e.total_hotels) }%)  
                                                                               
                                                                                <div class="spinner-border" style={{border:"0.15em solid white",borderRightColor:"transparent", margin:"0px 5px",width:"0.8rem",height:"0.8rem"}} role="status" ></div>
                                                                            </span>
                                                                        </OverlayTrigger>
                                                  
                                                                            :
                                                                            null
                                                                        }
                                                                        {e?.status ===
                                                                            STATUS_PROCESSING && (
                                                                            <Spinner
                                                                                animation="border"
                                                                                variant="primary"
                                                                                size="sm"
                                                                                style={{
                                                                                    marginLeft:
                                                                                        "4px",
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </span>

                                                                    <div
                                                                        onClick={(
                                                                            event
                                                                        ) => {
                                                                            event.stopPropagation();
                                                                            setCurrentElement(
                                                                                e
                                                                            );
                                                                        }}
                                                                    >
                                                                        <Download
                                                                            dataCell={
                                                                                e
                                                                            }
                                                                            setShowConfirmRemoveModal={
                                                                                setShowConfirmRemoveModal
                                                                            }
                                                                            setShowAddAttributes={
                                                                                setShowAddAttributes
                                                                            }
                                                                            setShowRunMapping={
                                                                                setShowRunMapping
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        ...spaceBetween,
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            color: "#444",
                                                                        }}
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                ...hotelDetails,
                                                                            }}
                                                                        >
                                                                            Total
                                                                            hotels
                                                                            :&nbsp;
                                                                            {e?.total_hotels
                                                                                ? separator(
                                                                                      e?.total_hotels
                                                                                  )
                                                                                : "--"}
                                                                        </div>
                                                                        <div
                                                                            style={{
                                                                                ...hotelDetails,
                                                                            }}
                                                                        >
                                                                            <span
                                                                                className="title"
                                                                                style={{
                                                                                    fontSize:
                                                                                        "15px",
                                                                                }}
                                                                            >
                                                                                <span
                                                                                    className="number"
                                                                                    style={{
                                                                                        fontWeight:
                                                                                            "bold",
                                                                                    }}
                                                                                >
                                                                                    Valid
                                                                                    :&nbsp;
                                                                                </span>

                                                                                {e?.valid_hotels
                                                                                    ? separator(
                                                                                          e?.valid_hotels
                                                                                      )
                                                                                    : 0}
                                                                            </span>
                                                                            &nbsp;|&nbsp;
                                                                            <span
                                                                                className="number"
                                                                                style={{
                                                                                    fontWeight:
                                                                                        "bold",
                                                                                }}
                                                                            >
                                                                                Invalid
                                                                                :&nbsp;
                                                                            </span>
                                                                            {e?.invalid_hotels
                                                                                ? separator(
                                                                                      e?.invalid_hotels
                                                                                  )
                                                                                : 0}
                                                                        </div>
                                                                        <div
                                                                            style={{
                                                                                ...hotelDetails,
                                                                            }}
                                                                        >
                                                                            Last
                                                                            update
                                                                            :&nbsp;
                                                                            {e?.created_at
                                                                                ? moment(
                                                                                      new Date(
                                                                                          e?.created_at
                                                                                      )
                                                                                  ).format(
                                                                                      "DD/MM/YYYY HH:mm"
                                                                                  )
                                                                                : "--"}
                                                                        </div>
                                                                    </div>

                                                                    <div
                                                                        className="integration-entity"
                                                                        style={{
                                                                            marginBottom:
                                                                                "0px",
                                                                        }}
                                                                    >
                                                                        <div
                                                                            className="integration-entity-img-wrapper-connector-inventory"
                                                                            style={{
                                                                                margin: "0px",
                                                                            }}
                                                                        >
                                                                            <img
                                                                                id={
                                                                                    "source_logo"
                                                                                }
                                                                                className="cloudinary-image ls-is-cached lazyloaded round-img"
                                                                                alt={
                                                                                    "source_logo"
                                                                                }
                                                                                src={
                                                                                    e?.connector_logo &&
                                                                                    e?.connector_id !==
                                                                                        0
                                                                                        ? e?.connector_logo
                                                                                        : CSV_LOGO
                                                                                }
                                                                                width="50"
                                                                                height="50"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Card.Title>
                                                        </Card.Body>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </div>
                                    );
                                })}

                                {currentItems?.length > 0 ? (
                                    <div
                                        className="row"
                                        style={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <ReactPaginate
                                            className="react-pagination-div"
                                            breakClassName={"page-item"}
                                            breakLinkClassName={"page-link"}
                                            containerClassName={"pagination"}
                                            pageClassName={"page-item"}
                                            pageLinkClassName={"page-link"}
                                            previousClassName={"page-item"}
                                            previousLinkClassName={"page-link"}
                                            nextClassName={"page-item"}
                                            nextLinkClassName={"page-link"}
                                            activeClassName={"active"}
                                            breakLabel="..."
                                            nextLabel=" >"
                                            onPageChange={handlePageClick}
                                            pageRangeDisplayed={5}
                                            pageCount={pageCount}
                                            previousLabel="<"
                                            renderOnZeroPageCount={null}
                                        />
                                    </div>
                                ) : currentItems?.length === 0 &&
                                  filter !== "" ? (
                                    <div
                                        className="card"
                                        style={{
                                            padding: "2rem",
                                            width: "100%",
                                        }}
                                    >
                                        <div className="empty text-center">
                                            <p>
                                                Sorry, we couldn&apos;t find any
                                                partner for your search
                                                criteria. Please check your
                                                information and try again.
                                            </p>
                                        </div>
                                    </div>
                                ) : !loading ? (
                                    <div className="col-lg-12 col-md-12">
                                        <div
                                            className="card"
                                            style={{
                                                padding: "2rem",
                                                width: "100%",
                                            }}
                                        >
                                            <div className="empty text-center">
                                                <p>No data to display.</p>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </div>
            {openDialog && (
                <ChoiseConnectorCsv
                    open={openDialog}
                    handleClose={() => {
                        setOpenDialog(false);
                        //  dispatch({ type: HIDE_DIALOG_ADD_NEW_CATALOG });
                        // dispatch({ type: CLEAR_PARTNER_HAS_CATALOG });
                    }}
                />
            )}

            {showConfirmRemoveModal && (
                <ConfirmAction
                    show={showConfirmRemoveModal}
                    onClose={() => setShowConfirmRemoveModal(false)}
                    currentElement={currentElement}
                    titleMessage="Warning !"
                    subTitleMessage="Are you sure you want to delete this partner inventory?"
                    colorCode="danger"
                    handleClick={handleDelete}
                    buttonText="Confirm"
                    disabled={loadingRemove}
                />
            )}

            {showAddAttributes && (
                <AddAttributes
                    show={showAddAttributes}
                    handleClose={() => setShowAddAttributes(false)}
                    currentElement={{ id: currentElement?.partner_id }}
                />
            )}
            {showRunMapping && (
                <RunMappingModal
                    open={showRunMapping}
                    handleClose={() => setShowRunMapping(false)}
                    currentElement={{
                        ...currentElement,
                        partner_inventory_id: Number(currentElement.id),
                    }}
                />
            )}
        </>
    );
}
export default PartnerInventoriesList;

const Download = (props) => {
    const {
        dataCell,
        setShowConfirmRemoveModal,
        setShowAddAttributes,
        setShowRunMapping,
    } = props;
    const [pending, setPending] = React.useState(false);
    const [isOpen, setOpen] = useState();
    const ref = React.useRef(null);

    const handleDowload = () => async () => {
        setPending(true);
        try {
            let res = await PartnerInventoryService.dowloadPartnerInventory(
                dataCell?.id
            );
            if (res.status === 200) {
                fileDownload(res.data, res?.fileName);
                setPending(false);
            } else {
                toast.error("Something went wrong while uploading this file");
                setPending(false);
            }
        } catch (err) {
            toast.error("Something went wrong while uploading this file");
            setPending(false);
        }
    };
    return (
        <>
            {dataCell?.status === 2 && (
                <>
                    <div
                        ref={ref}
                        className="btn"
                        onClick={() => setOpen(true)}
                        style={{
                            cursor: "pointer",
                            paddingBottom: "0px",
                            paddingTop: "0px",
                        }}
                    >
                        <i className="fas fa-ellipsis-v"></i>{" "}
                    </div>
                    <ControlledMenu
                        state={isOpen ? "open" : pending ? "open" : "closed"}
                        anchorRef={ref}
                        onClose={() => setOpen(false)}
                    >
                        <MenuItem onClick={handleDowload()} disbaled={pending}>
                            Download CSV
                            {pending && (
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            )}
                        </MenuItem>
                        <MenuItem onClick={() => setShowRunMapping(true)}>
                            Run mapping
                        </MenuItem>
                        <MenuItem onClick={() => setShowAddAttributes(true)}>
                            Manage partner attributes
                        </MenuItem>
                        <MenuItem
                            onClick={() => setShowConfirmRemoveModal(true)}
                        >
                            Delete
                        </MenuItem>
                    </ControlledMenu>
                </>
            )}
        </>
    );
};
