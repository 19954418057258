import React, { useEffect, useRef } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { TRAVELGATE_ID } from "../../utilis/const";
import { getTravelgateCredentials } from "../../redux/actions/ConnectorActions";
import { MenuItem, ControlledMenu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
const HubConfig = (props) => {
    const { setEditTravelgateCredentials } = props;
    const dispatch = useDispatch();
    const { hubConnectors, travelgateCredentials } = useSelector((state) => ({
        hubConnectors: state.connectorReducer.listConnectors?.filter(
            (e) => e?.is_direct === false
        ),

        travelgateCredentials: state.connectorReducer.travelgateCredentials,
    }));
    const renderTooltip = (text) => (props) =>
        (
            <Tooltip id="button-tooltip" {...props}>
                {text}
            </Tooltip>
        );
    useEffect(() => {
        dispatch(getTravelgateCredentials());
    }, []);

    return (
        <fieldset
            className="choose-destination"
            style={{ backgroundColor: "white" }}
        >
            <legend className="scheduler-border">Hub config</legend>
            <div className="row">
                {hubConnectors
                    ?.filter((e) => e?.id === TRAVELGATE_ID)
                    ?.map((e, index) => {
                        return (
                            <div
                                className="col-6 col-md-4 col-lg-4"
                                key={index}
                            >
                                <Card
                                    className="card-destination"
                                    onClick={() =>
                                        setEditTravelgateCredentials(true)
                                    }
                                    key={index}
                                >
                                    <Row className="no-gutters">
                                        <Col md={3} lg={3}>
                                            <Card.Img
                                                variant="top"
                                                style={{
                                                    width: "74px",
                                                    marginLeft: "12px",
                                                    marginTop: "6px",
                                                    marginBottom: "6px",
                                                    borderRadius: "50%",
                                                    // marginRight: "12px",
                                                }}
                                                src={e?.logo}
                                            />
                                        </Col>
                                        <Col>
                                            <Card.Body
                                                style={{
                                                    paddingRight: "0px",
                                                    paddingTop: "19px",
                                                    // paddingBottom: "25px",
                                                    paddingLeft: "4px",
                                                }}
                                            >
                                                <Card.Title
                                                    style={{
                                                        display: "flex",
                                                        marginBottom: "0px",
                                                        justifyContent:
                                                            "space-between",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            color: "#6876e8",
                                                        }}
                                                    >
                                                        {e?.name}
                                                        {travelgateCredentials?.password !==
                                                            "" &&
                                                            travelgateCredentials?.username !==
                                                                "" && (
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    delay={{
                                                                        show: 250,
                                                                        hide: 400,
                                                                    }}
                                                                    overlay={renderTooltip(
                                                                        "Already configured"
                                                                    )}
                                                                >
                                                                    <i
                                                                        className="fas fa-check-circle"
                                                                        style={{
                                                                            color: "#4fcb4ffa",
                                                                            marginLeft:
                                                                                "5px",
                                                                        }}
                                                                    ></i>
                                                                </OverlayTrigger>
                                                            )}
                                                    </div>
                                                    <div
                                                        onClick={(e) =>
                                                            e.stopPropagation()
                                                        }
                                                    >
                                                        <CustomMenu
                                                            configured={
                                                                travelgateCredentials?.password !==
                                                                    "" &&
                                                                travelgateCredentials?.username !==
                                                                    ""
                                                            }
                                                            setEditTravelgateCredentials={
                                                                setEditTravelgateCredentials
                                                            }
                                                        />
                                                    </div>
                                                </Card.Title>
                                                <Card.Text
                                                    style={{
                                                        marginTop: "0px",
                                                        color: "#808698",
                                                        font: "400 12px/16px Roboto,Helvetica Neue,sans-serif",
                                                        letterSpacing: ".3px",
                                                    }}
                                                >
                                                    {`Manage ${e?.name} Credentials`}
                                                </Card.Text>
                                            </Card.Body>
                                        </Col>
                                    </Row>
                                </Card>
                            </div>
                        );
                    })}
            </div>
        </fieldset>
    );
};

export default HubConfig;

function CustomMenu(props) {
    const { setEditTravelgateCredentials, configured } = props;
    const ref = useRef();
    const [isOpen, setOpen] = React.useState(false);
    return (
        <>
            <div
                ref={ref}
                className="btn"
                onClick={(event) => {
                    event.stopPropagation();
                    setOpen(true);
                }}
                style={{
                    cursor: "pointer",
                    justifyContent: "flex-end",
                    display: "flex",
                }}
            >
                <i className="fas fa-ellipsis-v"></i>{" "}
            </div>
            <ControlledMenu
                state={isOpen ? "open" : "closed"}
                anchorRef={ref}
                onClose={() => setOpen(false)}
            >
                <MenuItem
                    onClick={async (event) => {
                        setEditTravelgateCredentials(true);
                    }}
                >
                    {configured ? "Edit configuration" : "Configure"}
                </MenuItem>
            </ControlledMenu>
        </>
    );
}
